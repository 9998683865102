import * as React from 'react';
import styled from 'styled-components';
import InputMask from 'react-input-mask';

import images from '../../constants/images';

const GiTextInput = ({
  onChangeText = (str: string) => str,
  style,
  showCheckMark = false,
  mask,
  maskPlaceholder,
  lightTheme,
  ...rest
}: React.InputHTMLAttributes<any> & {
  style?: React.CSSProperties;
  onChangeText?: (str: string) => void;
  showCheckMark?: boolean;
  mask?: string;
  maskPlaceholder?: string;
  lightTheme?: boolean;
}) => {
  let InputContent = (
    <Input
      style={{ paddingRight: showCheckMark ? 48 : undefined }}
      onChange={(ev) => onChangeText(ev.target.value)}
      lightTheme={lightTheme}
      {...rest}
    />
  );

  if (mask) {
    InputContent = (
      <InputMask
        mask={mask}
        onChange={(ev) => onChangeText(ev.target.value)}
        maskPlaceholder={maskPlaceholder || ''}
        {...rest}
      >
        <Input
          style={{ paddingRight: showCheckMark ? 48 : undefined }}
          lightTheme={lightTheme}
        />
      </InputMask>
    );
  }

  return (
    <Main style={style} lightTheme={lightTheme}>
      {InputContent}
      {showCheckMark ? <CheckMark src={images.checkMarkGreen} /> : null}
    </Main>
  );
};

const CheckMark = styled.img`
  width: 14px;
  height: 14px;
  position: absolute;
  right: 22px;
  top: 18px;
  @media (max-width: ${(props) => props.theme.breakPoints.mobile}px) {
  }
`;

const Main = styled.div<{ lightTheme?: boolean }>`
  display: flex;
  box-sizing: border-box;
  position: relative;

  ${({ lightTheme, theme }) => {
    if (lightTheme) {
      return `background-color: ${theme.colors.white};
    box-shadow: 4px 4px 10px rgba(131, 123, 119, 0.25);`;
    }
    return `background-color: ${theme.colors.darkPuple};
    box-shadow: undefined;`;
  }}

  height: 46px;
  @media (max-width: ${(props) => props.theme.breakPoints.mobile}px) {
  }
`;

const Input = styled.input<{ lightTheme?: boolean }>`
  text-decoration: none;
  outline: none;
  height: 100%;
  font-size: 16px;
  padding: 10px 20px;
  box-sizing: border-box;
  width: 100%;
  background-color: transparent;
  border-radius: 2px;
  font-family: ${(props) => props.theme.fonts.GeneralSans};
  font-weight: normal;

  ${({ lightTheme, theme }) => {
    if (lightTheme) {
      return `border: 1px solid rgba(0, 0, 0, 0.1);
      color: ${theme.colors.black};
      :focus {border: 1px solid ${theme.colors.blue};}
      ::placeholder {}
    `;
    }
    return `border: 1px solid rgba(255, 255, 255, 0.5);
    color: ${theme.colors.whiteText};
    :focus {border: 1px solid rgba(255, 255, 255, 1);}
    ::placeholder {
        color: rgba(255, 255, 255);
        opacity: 0.5;
      }
     `;
  }}

  @media (max-width: ${(props) => props.theme.breakPoints.mobile}px) {
  }
`;

export default GiTextInput;
