export default {
  'SuccessfulRequestRecapSection.required': {
    en: 'Required',
    fr: 'Obligatoire',
  },
  'SuccessfulRequestRecapSection.unpaid': { en: 'Unpaid', fr: 'Non payé' },
  'SuccessfulRequestRecapSection.requestTotal': {
    en: 'Request Total',
    fr: 'Demande totale',
  },
  'SuccessfulRequestRecapSection.waiting': {
    en: "You'll receive a quote shortly.",
    fr: 'Vous recevrez un devis sous peu.',
  },
  'SuccessfulRequestRecapSection.quoteNotSent': {
    en: 'Quote not sent',
    fr: 'Citation non envoyée',
  },
  'SuccessfulRequestRecapSection.yourShare': {
    en: 'Your share',
    fr: 'Votre part',
  },
};
