import * as React from 'react';
import toast from 'react-hot-toast';
import styled from 'styled-components';

import colors from '../../constants/colors';
import i18n from '../../constants/i18n';
import { useBookingStore } from '../../stores/booking';
import GiButton from '../common/GiButton';
import { TextH2 } from '../common/Typography';

import BookingSummaryEventInfo from './BookingSummaryEventInfo';
import BookingSummaryFees from './BookingSummaryFees';

const BookingSummary = ({ onPressNext }: { onPressNext: () => void }) => {
  const [loading, setLoading] = React.useState(false);
  const { createBookingTransaction } = useBookingStore();

  const onPressConfirm = async () => {
    try {
      setLoading(true);
      await createBookingTransaction();
      onPressNext();
    } catch (e) {
      setLoading(false);
      const err = e as Error;
      toast.error(err.message);
    }
  };

  return (
    <Main>
      <TextH2
        style={{
          color: colors.whiteText,
          marginBottom: 48,
          alignSelf: 'center',
          textAlign: 'center',
        }}
      >
        {i18n('BookingSummary.title', 'Summary')}
      </TextH2>

      <BookingSummaryEventInfo />

      <BookingSummaryFees />

      <GiButton
        loading={loading}
        style={{ marginTop: 48, alignSelf: 'center' }}
        label={i18n('BookingSummary.confirm', 'Confirm')}
        onClick={onPressConfirm}
      />
    </Main>
  );
};

const Main = styled.div`
  display: flex;
  box-sizing: border-box;
  flex-direction: column;
  align-self: center;
  padding-bottom: 158px;
  width: 100%;
  @media (max-width: ${(props) => props.theme.breakPoints.mobile}px) {
    padding: 0px 20px;
  }
`;

export default BookingSummary;
