import frCA from 'date-fns/esm/locale/fr-CA/index.js';
import format from 'date-fns/format';
import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { Box } from 'react-native-kondo';
import styled from 'styled-components';
import images from '../../constants/images';
import { Review } from '../../shared';
import { useUiStore } from '../../stores/ui';
import { UseUserStore } from '../../stores/user';
import { GeneralRegularNormal } from '../common/Typography';

const ReviewCell = observer(({ review }: { review: Review }) => {
  const userStore = UseUserStore();
  const uiStore = useUiStore();
  return (
    <Main>
      <Row>
        <Box flexDirection="row">
          {Array.from({ length: review.rating }, (_, i) => i).map((_, i) => (
            <Star key={`star-${i}`} src={images.starFull} />
          ))}
        </Box>
        <GeneralRegularNormal
          style={{ color: '#777777', marginLeft: uiStore.isMobile ? 0 : 13 }}
        >
          {review.author.firstName} {review.author.lastName} —{' '}
          {format(new Date(review.createdAt), 'PPP', {
            locale: userStore?.browserLanguage === 'fr' ? frCA : undefined,
          })}
        </GeneralRegularNormal>
      </Row>
      <GeneralRegularNormal style={{ color: '#777777' }}>
        {review.text}
      </GeneralRegularNormal>
    </Main>
  );
});

const Row = styled.div`
  display: flex;
  margin-bottom: 8px;
  align-items: center;
  @media (max-width: ${(props) => props.theme.breakPoints.mobile}px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

const Main = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px 40px 24px 23px;
  box-shadow: 4px 4px 10px rgba(131, 123, 119, 0.25);
  border-radius: 5px;
  margin-bottom: 20px;
  @media (max-width: ${(props) => props.theme.breakPoints.mobile}px) {
  }
`;

const Star = styled.img`
  width: 18px;
  height: 18px;
  @media (max-width: ${(props) => props.theme.breakPoints.mobile}px) {
  }
`;

export default ReviewCell;
