import { observer } from 'mobx-react-lite';
import * as React from 'react';
import styled from 'styled-components';
import { useMessagingStore } from '../../stores/messaging';
import { useUiStore } from '../../stores/ui';
import CenteredClipLoader from '../common/CenteredClipLoader';
import ConversationListing from './ConversationListing';
import MessageScreen from './MessageScreen';

const ConversationScreen = observer(() => {
  const [loading, setLoading] = React.useState(true);
  const messageStore = useMessagingStore();
  const conversations = messageStore.conversations.slice();
  const currentConversation = messageStore.getCurrentConversation();
  const { isMobile, innerHeight, navBarHeight } = useUiStore();

  React.useEffect(() => {
    const fetchConversations = async () => {
      try {
        await messageStore.fetchConversations();
        const newCurrentConversation = conversations[0];
        if (!currentConversation && newCurrentConversation && !isMobile) {
          messageStore.setCurrentConversation(newCurrentConversation);
        }
        setLoading(false);
      } catch (e) {
        console.error(e);
      }
    };
    fetchConversations();

    return () => {
      messageStore.setCurrentConversation(null);
      messageStore.setCurrentOtherUser(null);
    };
  }, []);

  if (loading) {
    return <CenteredClipLoader />;
  }

  return (
    <Main
      style={{
        maxHeight: innerHeight - navBarHeight,
        height: '100%',
      }}
    >
      {isMobile && currentConversation ? null : <ConversationListing />}
      {currentConversation ? (
        <MessageScreen key={currentConversation.id} />
      ) : null}
    </Main>
  );
});

const Main = styled.div`
  display: flex;
  flex: 1;
  background-color: rgb(247, 249, 251);
  @media (max-width: ${(props) => props.theme.breakPoints.mobile}px) {
  }
`;

export default ConversationScreen;
