export default {
  'ArtistReminders.missingBankingInfo': {
    en: 'Remember to update your banking info to accept offers through GigLinked (Powered by Stripe)',
    fr: "N'oubliez pas de configurer vos informations bancaires afin d'accepter des offres sur GigLinked (Sécurisé par Stripe)",
  },
  'ArtistReminders.rememberToUpdateYourBankingInfo': {
    en: 'Remember to update your banking info to receive direct payments (Powered by Stripe)',
    fr: "N'oubliez pas de mettre à jour vos informations bancaires pour recevoir des paiements directs (alimenté par Stripe)",
  },
};
