import { observer } from 'mobx-react';
import ATag from '../../common/ATag';
import styled from 'styled-components';
import i18n from '../../../constants/i18n';
import { useNavigate } from 'react-router-dom';
import React from 'react';
import images from '../../../constants/images';
import urls from '../../../utils/urls';
import { useUiStore } from '../../../stores/ui';
import colors from '../../../constants/colors';
import { GeneralMediumSubtitle } from '../../common/Typography';

const NavBarDiscoverDropdown = observer(
  ({ onClose }: { onClose: () => void }) => {
    const [isDiscoverOpen, setIsDiscoverOpen] = React.useState(false);
    const { isTablet } = useUiStore();
    const navigate = useNavigate();

    const toggleDiscover = () => {
      setIsDiscoverOpen(!isDiscoverOpen);
    };

    return (
      <DropdownContainer>
        <GeneralMediumSubtitle
          onClick={toggleDiscover}
          style={{ marginRight: '1rem' }}
        >
          <ImageArrow
            style={{ marginRight: '1rem' }}
            src={
              isTablet
                ? images.navBarArrowWhiteImage
                : images.navBarArrowBlackImage
            }
            isDiscoverOpen={isDiscoverOpen}
            onClick={toggleDiscover}
          />
          {i18n('NavBarContent.discover', 'Discover')}
        </GeneralMediumSubtitle>
        {isDiscoverOpen && (
          <DropdownList>
            <DropdownItem
              onClick={() => {
                navigate('/about');
                onClose();
                toggleDiscover();
              }}
            >
              <GeneralMediumSubtitle>
                {i18n('NavBar.about', 'About')}
              </GeneralMediumSubtitle>
            </DropdownItem>
            <DropdownItem>
              <ATag target="_blank" href={urls.blogUrl}>
                <GeneralMediumSubtitle>
                  {i18n('PageLayoutFooter.blog', 'Blog')}
                </GeneralMediumSubtitle>
              </ATag>
            </DropdownItem>
            <DropdownItem
              onClick={() => {
                navigate('/howitworks');
                onClose();
                toggleDiscover();
              }}
            >
              <GeneralMediumSubtitle>
                {i18n('NavBarContent.howItWorks', 'How It Works')}
              </GeneralMediumSubtitle>
            </DropdownItem>
          </DropdownList>
        )}
      </DropdownContainer>
    );
  },
);

const DropdownContainer = styled.div`
  position: relative;
`;

const ImageArrow = styled.img<{ isDiscoverOpen: boolean }>`
  width: 1rem;
  height: 1rem;
  transform: ${({ isDiscoverOpen }) =>
    isDiscoverOpen ? 'rotate(180deg)' : 'rotate(0)'};
  transition: transform 0.3s ease;
`;

const DropdownList = styled.ul`
  position: absolute;
  top: 48px;
  left: 25px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 200px;
  background: ${colors.white};
  border-radius: 10px;
  padding: 1px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
  z-index: 999;
  @media (max-width: ${(props) => props.theme.breakPoints.tablet}px) {
    position: relative;
    top: 0;
    left: 0;
    margin-left: 40px;
    background: none;
  }
`;

const DropdownItem = styled.li`
  list-style-type: none;
  margin: 0 20px 5px 0;
  margin-bottom: 5px;
  margin-left: 20px;
  cursor: pointer;
  @media (max-width: ${(props) => props.theme.breakPoints.tablet}px) {
    margin: 15px 0 0;
  }
`;

export default NavBarDiscoverDropdown;
