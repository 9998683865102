import * as React from 'react';
import styled from 'styled-components';
import { format } from 'date-fns';

import colors from '../../constants/colors';
import {
  ActionType,
  Transaction,
  TransactionServiceType,
  TransactionStatus,
} from '../../shared';
import {
  GeneralRegularNormal,
  GeneralSemiBoldNormal,
  TextH3,
  TextH5,
} from '../common/Typography';
import { observer } from 'mobx-react';
import { frCA } from 'date-fns/locale';
import { UseUserStore } from '../../stores/user';
import i18n from '../../constants/i18n';
import RequestCellActionButton from './RequestCellActionButton';
import ATag from '../common/ATag';
import { useMessagingStore } from '../../stores/messaging';
import { formatTransactionStatus } from '../../utils/text';
import { useUiStore } from '../../stores/ui';
import { useNavigate } from 'react-router-dom';

export function getTransactionColorBasedOnStatus(status: TransactionStatus) {
  if (['quotePending', 'quoteSent', 'quoteDeclined'].includes(status)) {
    return '#faaa48';
  } else if (status === 'accepted') {
    return '#4361EE';
  }

  return 'gray';
}

const RequestCell = observer(
  ({
    transaction,
    onAction,
  }: {
    transaction: Transaction;
    onAction: ({
      action,
      transaction,
    }: {
      action: ActionType;
      transaction: Transaction;
    }) => void;
  }) => {
    const messagingStore = useMessagingStore();
    const navigate = useNavigate();
    const userStore = UseUserStore();
    const uiStore = useUiStore();
    const me = userStore.getMe();

    const serviceType: TransactionServiceType = transaction.serviceType;

    return (
      <Main>
        <DateContainer
          style={{
            backgroundColor: getTransactionColorBasedOnStatus(
              transaction.status,
            ),
          }}
        >
          <TextH5 style={{ color: colors.whiteText }}>
            {format(new Date(transaction.eventDate), 'LLL', {
              locale: userStore?.browserLanguage === 'fr' ? frCA : undefined,
            }).toUpperCase()}
          </TextH5>
          <TextH5 style={{ color: colors.whiteText }}>
            {new Date(transaction.eventDate).getDate()}
          </TextH5>
        </DateContainer>

        <InfoContainer>
          <GeneralRegularNormal
            style={{
              cursor: 'pointer',
              textDecoration: 'underline',
              position: uiStore.isMobile ? 'relative' : 'absolute',
              right: uiStore.isMobile ? undefined : 42,
            }}
          >
            <ATag href={`/requests/${transaction.id}`}>
              {i18n('RequestCell.viewDetail', 'View details')}
            </ATag>
          </GeneralRegularNormal>
          <GeneralSemiBoldNormal style={{ color: colors.black }}>
            Status: {formatTransactionStatus(transaction.status)}
          </GeneralSemiBoldNormal>
          <GeneralRegularNormal>
            {format(new Date(transaction.eventDate), 'E', {
              locale: userStore?.browserLanguage === 'fr' ? frCA : undefined,
            })}
            ,{' '}
            {format(new Date(transaction.startTime), 'p', {
              locale: userStore?.browserLanguage === 'fr' ? frCA : undefined,
            })}{' '}
            -{' '}
            {format(new Date(transaction.endTime), 'p', {
              locale: userStore?.browserLanguage === 'fr' ? frCA : undefined,
            })}{' '}
          </GeneralRegularNormal>
          {serviceType === 'act' ? (
            <TextH3 style={{ color: colors.black }}>
              {i18n('RequestCell.actBooking', 'Act')}:
              {' ' + transaction.act.name}
              {transaction.booker.id === me?.id ? (
                <>
                  <GeneralRegularNormal
                    style={{ marginLeft: 10, marginRight: 10 }}
                  >
                    {' - '}
                  </GeneralRegularNormal>
                  <GeneralRegularNormal
                    onClick={async () => {
                      await messagingStore.setCurrentOtherUser(
                        transaction.act.owner.user,
                      );
                      navigate('/conversations');
                    }}
                    style={{
                      textDecoration: 'underline',
                      cursor: 'pointer',
                    }}
                  >
                    {i18n('RequestCell.message', 'Send message')}
                  </GeneralRegularNormal>
                </>
              ) : null}
            </TextH3>
          ) : (
            <TextH3 style={{ color: colors.black }}>
              {i18n('RequestCell.artistBooking', 'Artist')}:{' '}
              {transaction.artist.name}
              {transaction.booker.id === me?.id ? (
                <>
                  <GeneralRegularNormal
                    style={{ marginLeft: 10, marginRight: 10 }}
                  >
                    {' - '}
                  </GeneralRegularNormal>
                  <GeneralRegularNormal
                    onClick={async () => {
                      await messagingStore.setCurrentOtherUser(
                        transaction.artist.user,
                      );
                      navigate('/conversations');
                    }}
                    style={{
                      textDecoration: 'underline',
                      cursor: 'pointer',
                    }}
                  >
                    {i18n('RequestCell.message', 'Send message')}
                  </GeneralRegularNormal>
                </>
              ) : null}
            </TextH3>
          )}

          {transaction.booker.id !== me?.id ? (
            <GeneralRegularNormal>
              {i18n('RequestCell.requestFrom', 'Request from')}{' '}
              {transaction.booker.firstName} {transaction.booker.lastName}
              <GeneralRegularNormal style={{ marginLeft: 10, marginRight: 10 }}>
                {' - '}
              </GeneralRegularNormal>
              <GeneralRegularNormal
                onClick={async () => {
                  // @ts-ignore
                  await messagingStore.setCurrentOtherUser(transaction.booker);
                  navigate('/conversations');
                }}
                style={{
                  textDecoration: 'underline',

                  cursor: 'pointer',
                }}
              >
                {i18n('RequestCell.message', 'Send message')}
              </GeneralRegularNormal>
            </GeneralRegularNormal>
          ) : null}

          <RequestCellActionButton
            transaction={transaction}
            onAction={onAction}
          />
        </InfoContainer>
      </Main>
    );
  },
);

const Main = styled.div`
  display: flex;
  box-sizing: border-box;
  box-shadow: 4px 4px 10px rgba(131, 123, 119, 0.25);
  background-color: white;
  border-radius: 2px;
  min-height: 220px;
  margin-bottom: 16px;
  overflow: hidden;
  padding-right: 16px;
  @media (max-width: ${(props) => props.theme.breakPoints.mobile}px) {
  }
`;

const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-left: 42px;
  padding-top: 12px;
  padding-bottom: 33px;
  @media (max-width: ${(props) => props.theme.breakPoints.mobile}px) {
  }
`;

const DateContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 80px;
  padding-top: 12px;
  align-items: center;
  @media (max-width: ${(props) => props.theme.breakPoints.mobile}px) {
  }
`;

export default RequestCell;
