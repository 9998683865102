import { observer } from 'mobx-react';
import * as React from 'react';
import styled from 'styled-components';
import i18n from '../../../../../constants/i18n';
import { InputLabel } from '../../../../common/Typography';
import GiglinkedButton from '../../../../common/GiglinkedButton';
import { UseUserStore } from '../../../../../stores/user';
import {
  CompanyTypeValues,
  SignUpStep,
  User,
  CompanyTypeEnums,
  COMPANY_TYPES,
} from '../../../../../shared';
import GiglinkedTextInput from '../../../../common/GiglinkedTextInput';
import GiAuthContainer from '../../../../common/GiAuthContainer';
import toast from 'react-hot-toast';
import GiEventsDropDown from '../../../../common/GiEventsDropDown';
import { Column, Row } from '../../../../common/Layout';
import { useUiStore } from '../../../../../stores/ui';

const RegistrationBookerTellAboutYourself = observer(() => {
  const userStore = UseUserStore();
  const { setSignUpStep } = userStore;
  const uiStore = useUiStore();

  const [firstName, setFirstName] = React.useState<string>('');
  const [lastName, setLastName] = React.useState<string>('');
  const [companyType, setCompanyType] = React.useState<
    CompanyTypeValues | undefined
  >(undefined);
  const [companyName, setCompanyName] = React.useState('');
  const [companyRole, setCompanyRole] = React.useState<string | undefined>(
    undefined,
  );

  const onSave = async (payload: Partial<User>) => {
    try {
      if (typeof payload.firstName === 'string' && !payload.firstName) {
        throw new Error(
          i18n(
            'SettingsScreen.errorFirstName',
            'Your first name cannot be blank.',
          ),
        );
      }

      if (typeof payload.lastName === 'string' && !payload.lastName) {
        throw new Error(
          i18n(
            'SettingsScreen.errorLastName',
            'Your last name cannot be blank.',
          ),
        );
      }
      if (typeof payload.lastName === 'string' && !payload.companyType) {
        throw new Error(
          i18n(
            'RegistrationBookerTellAboutYourself.errorOrganizationBookingDetails',
            "Please, let us know who you're booking for.",
          ),
        );
      }
      await userStore.editMe(payload);
      setSignUpStep(SignUpStep.RegistrationBookerLocation);
    } catch (e) {
      const err = e as Error;
      toast.error(err.message);
    }
  };

  const companyTypeTranslated = COMPANY_TYPES().find(
    (company) => company.value === companyType,
  )?.label;

  return (
    <GiAuthContainer
      title={i18n(
        'RegistrationBookerTellAboutYourself.title',
        'Tell us about yourself',
      )}
      editableContent={
        <>
          <MainBox>
            <Row
              style={{
                width: '100%',
                justifyContent: 'space-between',
                marginBottom: '1rem',
              }}
            >
              <Column style={{ width: uiStore.isMobile ? '100%' : '45%' }}>
                <InputLabel htmlFor="first-name">
                  {i18n('RegistrationController.firstName', 'First name')}
                </InputLabel>
                <GiglinkedTextInput
                  type="text"
                  id="first-name"
                  value={firstName}
                  onChange={(e: any) => setFirstName(e.target.value)}
                />
              </Column>

              <Column style={{ width: uiStore.isMobile ? '100%' : '45%' }}>
                <InputLabel htmlFor="last-name">
                  {i18n('RegistrationController.lastName', 'Last name')}
                </InputLabel>
                <GiglinkedTextInput
                  type="text"
                  id="last-name"
                  value={lastName}
                  onChange={(e: any) => setLastName(e.target.value)}
                />
              </Column>
            </Row>
            <InputLabel htmlFor="companyType">
              {i18n(
                'RegistrationController.whoBooking',
                'Who are you booking for?',
              )}
            </InputLabel>
            <GiEventsDropDown
              items={COMPANY_TYPES()}
              value={companyType ?? ''}
              onChange={(e: any) => setCompanyType(e.value)}
              style={{ marginBottom: '1rem' }}
              active
            />
            {companyType && companyType !== CompanyTypeEnums.ForMyself && (
              <>
                <InputLabel htmlFor="companyName">
                  {i18n('RegistrationBookerLocation.name', 'name')}
                  {` ${companyTypeTranslated}`.toLowerCase()}
                </InputLabel>
                <GiglinkedTextInput
                  value={companyName}
                  onChange={(e: any) => setCompanyName(e.target.value)}
                  style={{ marginBottom: '1rem' }}
                />
                <InputLabel htmlFor="companyRole">
                  {i18n('RegistrationController.role', 'Role (Optional)')}
                </InputLabel>
                <GiglinkedTextInput
                  type="text"
                  id="companyRole"
                  value={companyRole}
                  onChange={(e: any) => setCompanyRole(e.target.value)}
                />
              </>
            )}
          </MainBox>
          <BoxBottom>
            <GiglinkedButton
              style={{ border: 'none' }}
              label={i18n('RegistrationBookerTellAboutYourself.next', 'Next')}
              onClick={() =>
                onSave({
                  firstName,
                  lastName,
                  companyType,
                  companyName,
                  companyRole,
                })
              }
            />
          </BoxBottom>
        </>
      }
    />
  );
});

const MainBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 90%;
  @media (max-width: ${(props) => props.theme.breakPoints.mobile}px) {
    padding-left: 20px;
    padding-right: 20px;
  }
`;

const BoxBottom = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  height: 10%;
  @media (max-width: ${(props) => props.theme.breakPoints.mobile}px) {
    margin-right: 5px;
    margin-top: 0;
  }
`;

export default RegistrationBookerTellAboutYourself;
