import i18n from '../constants/i18n';

// ----------------------------------------------------TYPES--------------------------------------------------

export type MonthIndex = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;

export type ActionType =
  | 'sendQuote'
  | 'accept'
  | 'decline'
  | 'declineQuote'
  | 'cancel';

export type Providers = 'local' | 'google';

export type UserRole = 'normal' | 'admin';

export type TransactionStatus =
  | 'quotePending'
  | 'quoteSent'
  | 'quoteDeclined'
  | 'accepted'
  | 'expired'
  | 'declined'
  | 'refunded'
  | 'cancelled'
  | 'completed';

export type TransactionServiceType = 'artist' | 'act';

export type ModelTypeObj = 'act' | 'artist';

export type EstimatedAudienceValues =
  | '1-50'
  | '50-100'
  | '100-150'
  | '150-200'
  | '200-400'
  | '500+';

export type DresscodeValues =
  | 'none'
  | 'formal'
  | 'black tie'
  | 'business professional'
  | 'business casual'
  | 'casual';

export type EventTypeValues =
  | 'wedding'
  | 'hotel'
  | 'networking'
  | 'anniversary'
  | 'holiday party'
  | 'fundraiser'
  | 'funeral'
  | 'proposal'
  | 'cocktail'
  | 'house party'
  | 'bar mitzvah'
  | 'sporting event'
  | 'retirement'
  | 'for myself'
  | 'other';

export type EntityType = 'artist' | 'act';

export type Entity = Artist | Act;

export type StyleTypeValues =
  | 'dj'
  | 'classical'
  | 'jazz'
  | 'rock'
  | 'pop'
  | 'hip hop'
  | 'world music'
  | 'country'
  | 'lounge'
  | 'original compositions'
  | 'electronic'
  | 'traditional'
  | 'other';

export type EquipmentValues = 'provided on site' | 'provided by musician';

export type PerformanceTypeValues = 'background music' | 'concert';

export type EventStatus = 'open' | 'booked' | 'cancelled' | 'completed';

export type CompanyTypeValues =
  | 'retirement home'
  | 'hotel'
  | 'bar restaurant'
  | 'wedding'
  | 'for myself';

// --------------------------INTERFACE--------------------------

export interface Transaction {
  id: string;
  booker: User;
  artist: Artist;
  act: Act;
  review?: Review;
  stripe: {
    depositPaymentId: string;
    finalPaymentId: string;
    receiptUrl?: string;
  };
  status: TransactionStatus;
  serviceType: TransactionServiceType;
  priceData: {
    gstRate: number;
    qstRate: number;
    taxesRate: number;
    gigLinkedCutRate: number;

    performancePriceInCents: number;
    performancePriceFormatted: string;
    gigLinkedCutPriceInCents: number;
    gigLinkedCutPriceFormatted: string;
    subTotalPriceInCents: number;
    subTotalPriceFormatted: string;
    qstPriceInCents: number;
    qstPriceFormatted: string;
    gstPriceInCents: number;
    gstPriceFormatted: string;
    taxesPriceInCents: number;
    taxesPriceFormatted: string;
    finalTotalPriceInCents: number;
    finalTotalPriceFormatted: string;
    requiredDepositIncents: number;
    requiredDepositFormatted: string;
  };
  quoteData: {
    priceInCents: number;
    priceFormatted: string;
    didPayDeposit: boolean;
    amountPaidInCents: number;
    amountPaidFormatted: string;
    bookerQuoteDeclineMessage?: string;
  };
  expires: string;
  acceptedAt?: string;
  cancelledAt: string;
  completedAt: string;
  cancelledBy: User;
  seenBy: User[];
  startTime: string;
  endTime: string;
  eventDate: string;
  eventType: EventTypeValues;
  isOutDoor: boolean;
  isStageCovered: boolean;
  setupTime: number;
  message: string;
  dresscode: DresscodeValues;
  estimatedAudience: EstimatedAudienceValues;
  venueName: string;
  city: string;
  postalCode: string;
  province: string;
  country: string;
  locationAddress: string;
  contactFirstName: string;
  contactLastName: string;
  contactPhone: string;
  performerDeclineMessage?: string;
  payouts: Payout[];
}

export interface SignUpPayload {
  email: string;
  password: string;
  confirmPassword: string;
}

export interface ControllerSignUpData {
  stageName: string;
  actName: string;
  haveBand: boolean;
  isUser: boolean;
}

export interface User {
  id: string;
  email: string;
  password: string;
  provider: Providers;
  lastName: string;
  firstName: string;
  profilePicture: string;
  artist: Artist;
  companyType?: CompanyTypeValues;
  companyName?: string;
  companyRole?: string;
  role: UserRole;
  contactAddress: string;
  location: string;
  province: string;
  postalCode: string;
  contactPhone: string;
  stripe: {
    customerId?: string;
    paymentMethodId?: string;
  };
  transactions: Transaction[];
  conversations: Conversation[];
  forgetPasswordVerification: {
    isVerified: boolean;
    expireOn: string;
    key: string;
  };
}

export interface Act {
  id: string;
  name: string;
  profilePicture: string;
  bannerPicture: string;
  owner: Artist;
  location: string;
  createdBy: Artist;
  members: Artist[];
  pendingMembers: Artist[];
  songs: string[];
  gallery: string[];
  videos: YoutubeVideo[];
  nbOfReviews: number;
  priceTransportDriver: string;
  priceTransportPassenger: string;
  priceAdmin: string;
  priceMeetingFees: string;
  availability: Availabilities;
  bio: string;
  tags: Tag[];
  socialLinks: SocialLinks;
  reviews: Review[];
  model: ModelTypeObj;
  transactions: Transaction[];
  isListed: boolean;
  canBeBooked: boolean;
}

export interface Tag {
  id: string;
  label: string;
  artists: Artist[];
  acts: Act[];
  nbOfTimeSelected: number;
}

export interface YoutubeVideo {
  videoId: string;
  url: string;
  embedUrl: string;
  title: string;
  thumbnail: string;
}

export interface Artist {
  id: string;
  name: string;
  user: User;
  songs: string[];
  acts: Act[];
  pendingActInvites: Act[];
  title: string;
  location: string;
  gallery: string[];
  videos: YoutubeVideo[];
  nbOfReviews: number;
  profilePicture: string;
  bannerPicture: string;
  priceTransportDriver: string;
  priceTransportPassenger: string;
  priceAdmin: string;
  priceMeetingFees: string;
  availability: Availabilities;
  bio: string;
  tags: Tag[];
  socialLinks: SocialLinks;
  reviews: Review[];
  model: ModelTypeObj;
  stripe: {
    accountId: string;
    isAccountConnected: boolean;
  };
  transactions: Transaction[];
  payouts: Payout[];
  isListed: boolean;
  canBeBooked: boolean;
}

export interface Review {
  id: string;
  rating: number;
  author: User;
  artist?: Artist;
  act?: Act;
  transaction: Transaction;
  text: string;
  createdAt: string;
}

export interface SocialLinks {
  soundcloud: string;
  spotify: string;
  twitter: string;
  instagram: string;
  tiktok: string;
  youtube: string;
  facebook: string;
  bandcamp: string;
  personal: string;
}

export interface Availabilities {
  gigs: boolean;
  collaborations: boolean;
}

export interface Conversation {
  id: string;
  messages: Message[];
  users: User[];
  createdAt: string;
  lastUpdatedAt: string; // saved a date in the db.
}

export interface Message {
  id: string;
  text: string;
  user: User;
  createdAt: string;
  seenBy: User[];
  conversation: Conversation;
}

export interface BookingRequestPayload {
  eventType: EventTypeValues | CompanyTypeValues;
  startTime: string;
  endTime: string;
  eventDate: string;
  actId?: Act['id'];
  artistId?: Artist['id'];
  isOutDoor: boolean;
  isStageCovered: boolean;
  setupTime: number;
  message: string;
  locationAddress: string;
  estimatedAudience: EstimatedAudienceValues;
  venueName: string;
  city: string;
  postalCode: string;
  province: string;
  country: string;
  contactFirstName: string;
  contactLastName: string;
  contactPhone: string;
}

export interface Payout {
  amountInCents: number;
  amountFormatted: string;
  stripePayoutId: string;
  transaction: Transaction;
  artist: Artist;
  createdAt: Date;
}

export interface FormattedPayout {
  transactionId: Transaction['id'];
  transactionAcceptedAt: string;
  transactionCompletedAt?: string;
  status: 'unpaid' | 'paid' | 'error';
  amount: string;
  customer: string;
  payoutCreatedAt?: string;
}

export interface MusicianType {
  profilePicture: string;
  name: string;
  instrument: string;
  location: string;
  profileUrl: string;
}

export interface EventType {
  id: string;
  booker: User;
  artist?: Artist;
  transaction?: Transaction;
  status: EventStatus;
  acceptedAt?: Date;
  cancelledAt: Date;
  completedAt: Date;
  startTime: string;
  endTime: string;
  eventDate: string;
  name: string;
  companyName: string;
  contactAddress: string;
  city: string;
  postalCode: string;
  province: string;
  country: string;
  companyType: CompanyTypeValues;
  theme: string;
  estimatedBudget: number;
  estimatedAudience: EstimatedAudienceValues;
  isOutdoor: boolean;
  isStageCovered?: boolean;
  style: StyleTypeValues;
  withVocals: string;
  equipment: EquipmentValues;
  performanceType: PerformanceTypeValues;
  description: string;
  contactFirstName: string;
  contactLastName: string;
  contactPhone: string;
  proposals?: EventProposalType[];
  invitedArtists?: string[];
  createdAt: Date;
}

export interface EventProposalType {
  id: string;
  message: string;
  artist: Artist;
  proposalQuote: number;
  proposalGroupType: string;
  event: string;
  createdAt: Date;
}

export interface PaymentMethod {
  last4: string;
  brand?: string;
}

// ------------------------------------------------CONSTANTS----------------------------------------------------------

export const PROVINCE = [
  {
    label: 'QC',
    value: 'qc',
  },
];

export const COUNTRY = [
  {
    label: 'Canada',
    value: 'canada',
  },
];

export const EVENT_TYPES: Array<{ label: string; value: EventTypeValues }> = [
  {
    label: i18n('StepperRegistrationEventTypes.wedding', 'Wedding planner'),
    value: 'wedding',
  },
  {
    label: i18n('StepperRegistrationEventTypes.hotel', 'Hotel'),
    value: 'hotel',
  },
  {
    label: i18n('StepperRegistrationEventTypes.retirement', 'Retirement'),
    value: 'retirement',
  },
  {
    label: i18n('StepperRegistrationEventTypes.forMyself', 'For myself'),
    value: 'for myself',
  },
  {
    label: i18n('StepperRegistrationEventTypes.networking', 'Networking event'),
    value: 'networking',
  },
  {
    label: i18n('StepperRegistrationEventTypes.anniversary', 'Anniversary'),
    value: 'anniversary',
  },
  {
    label: i18n('StepperRegistrationEventTypes.holidayParty', 'Holiday Party'),
    value: 'holiday party',
  },
  {
    label: i18n('StepperRegistrationEventTypes.fundraiser', 'Fundraiser'),
    value: 'fundraiser',
  },
  {
    label: i18n('StepperRegistrationEventTypes.funeral', 'Funeral'),
    value: 'funeral',
  },
  {
    label: i18n('StepperRegistrationEventTypes.proposal', 'Marriage proposal'),
    value: 'proposal',
  },
  {
    label: i18n('StepperRegistrationEventTypes.cocktail', 'Cocktail'),
    value: 'cocktail',
  },
  {
    value: 'house party',
    label: i18n('StepperRegistrationEventTypes.houseParty', 'House party'),
  },
  {
    value: 'bar mitzvah',
    label: i18n('StepperRegistrationEventTypes.barMitzvah', 'Bar /Bat mitzvah'),
  },
  {
    value: 'sporting event',
    label: i18n(
      'StepperRegistrationEventTypes.sportingEvent',
      'Sporting event',
    ),
  },
  {
    label: i18n('StepperRegistrationEventTypes.other', 'Other'),
    value: 'other',
  },
];

export const GROUP_TYPES = () => [
  {
    value: 'solo',
    label: i18n('StepperRegistrationGroupTypeSelect.solo', 'Solo'),
  },
  {
    value: 'duo',
    label: i18n('StepperRegistrationGroupTypeSelect.duo', 'Duo'),
  },
  {
    value: 'trio',
    label: i18n('StepperRegistrationGroupTypeSelect.trio', 'Trio'),
  },
  {
    value: 'quartet',
    label: i18n('StepperRegistrationGroupTypeSelect.quartet', 'Quartet'),
  },
  {
    value: 'quintet',
    label: i18n('StepperRegistrationGroupTypeSelect.quintet', 'Quintet'),
  },
  {
    value: 'band',
    label: i18n('StepperRegistrationGroupTypeSelect.band', 'Band'),
  },
  {
    value: 'brass',
    label: i18n('StepperRegistrationGroupTypeSelect.brass', 'Brass'),
  },
  {
    value: 'strings',
    label: i18n('StepperRegistrationGroupTypeSelect.strings', 'Strings'),
  },
  {
    value: 'woodwind',
    label: i18n('StepperRegistrationGroupTypeSelect.woodwind', 'Woodwind'),
  },
  {
    value: 'ensemble',
    label: i18n('StepperRegistrationGroupTypeSelect.ensemble', 'Ensemble'),
  },
  {
    value: 'collective',
    label: i18n('StepperRegistrationGroupTypeSelect.collective', 'Collective'),
  },
  {
    value: 'big band',
    label: i18n('StepperRegistrationGroupTypeSelect.bigBand', 'Big band'),
  },
  {
    value: 'mariachi',
    label: i18n('StepperRegistrationGroupTypeSelect.mariachi', 'Mariachi'),
  },
  {
    value: 'other',
    label: i18n('StepperRegistrationGroupTypeSelect.other', 'Other'),
  },
  {
    value: 'unsure',
    label: i18n('StepperRegistrationGroupTypeSelect.unsure', 'Unsure'),
  },
];

export const INSTRUMENT_TYPES = [
  {
    value: 'guitar',
    label: i18n('StepperRegistrationInstruments.guitar', 'Guitar'),
  },
  {
    value: 'bass',
    label: i18n('StepperRegistrationInstruments.bass', 'Bass'),
  },
  {
    value: 'violin',
    label: i18n('StepperRegistrationInstruments.violin', 'Violin'),
  },
  {
    value: 'cello',
    label: i18n('StepperRegistrationInstruments.cello', 'Cello'),
  },
  {
    value: 'piano',
    label: i18n('StepperRegistrationInstruments.piano', 'Piano'),
  },
  {
    value: 'keys',
    label: i18n('StepperRegistrationInstruments.keys', 'Keys'),
  },
  {
    value: 'percussion',
    label: i18n('StepperRegistrationInstruments.percussion', 'Percussion'),
  },
  {
    value: 'drums',
    label: i18n('StepperRegistrationInstruments.drums', 'Drums'),
  },
  {
    value: 'harp',
    label: i18n('StepperRegistrationInstruments.harp', 'Harp'),
  },
  {
    value: 'saxophone',
    label: i18n('StepperRegistrationInstruments.saxophone', 'Saxophone'),
  },
  {
    value: 'trumpet',
    label: i18n('StepperRegistrationInstruments.trumpet', 'Trumpet'),
  },
  {
    value: 'viola',
    label: i18n('StepperRegistrationInstruments.viola', 'Viola'),
  },
];

export const PLAY_STYLES = () => [
  {
    value: 'dj',
    label: i18n('StepperRegistrationPlayStyles.dj', 'DJ'),
  },
  {
    value: 'classical',
    label: i18n('StepperRegistrationPlayStyles.classical', 'Classical'),
  },
  {
    value: 'jazz',
    label: i18n('StepperRegistrationPlayStyles.jazz', 'Jazz'),
  },
  {
    value: 'rock',
    label: i18n('StepperRegistrationPlayStyles.rock', 'Rock'),
  },
  {
    value: 'pop',
    label: i18n('StepperRegistrationPlayStyles.pop', 'Pop'),
  },
  {
    value: 'hip hop',
    label: i18n('StepperRegistrationPlayStyles.hipHop', 'Hip hip'),
  },
  {
    value: 'world music',
    label: i18n('StepperRegistrationPlayStyles.worldMusic', 'World music'),
  },
  {
    value: 'country',
    label: i18n('StepperRegistrationPlayStyles.country', 'Country'),
  },
  {
    value: 'lounge',
    label: i18n('StepperRegistrationPlayStyles.lounge', 'Lounge'),
  },
  {
    value: 'original compositions',
    label: i18n(
      'StepperRegistrationPlayStyles.originalCompositions',
      'Original compositions',
    ),
  },
  {
    value: 'electronic',
    label: i18n('StepperRegistrationPlayStyles.electronic', 'Electronic'),
  },
  {
    value: 'traditional',
    label: i18n('StepperRegistrationPlayStyles.traditional', 'Traditional'),
  },
  {
    value: 'other',
    label: i18n('StepperRegistrationPlayStyles.other', 'Other'),
  },
];

export const COMPANY_TYPES = () => [
  {
    label: i18n('SharedTypes.retirementHome', 'Retirement home'),
    value: 'retirement home',
  },
  {
    label: i18n('SharedTypes.hotel', 'Hotel'),
    value: 'hotel',
  },
  {
    label: i18n('SharedTypes.barRestaurant', 'Bar / Restaurant'),
    value: 'bar restaurant',
  },
  {
    label: i18n('SharedTypes.wedding', 'Wedding planner'),
    value: 'wedding',
  },
  {
    label: i18n('SharedTypes.forMyself', 'For myself'),
    value: 'for myself',
  },
];

export const ESTIMATED_AUDIENCE = () => [
  {
    label: '1-50',
    value: '1-50',
  },
  {
    label: '50-100',
    value: '50-100',
  },
  {
    label: '100-150',
    value: '100-150',
  },
  {
    label: '150-200',
    value: '150-200',
  },
  {
    label: '200-400',
    value: '200-400',
  },
  {
    label: '500+',
    value: '500+',
  },
];

export const DRESSCODE_TYPES: { label: string; value: DresscodeValues }[] = [
  {
    label: i18n('DresscodeTypes.none', 'None'),
    value: 'none',
  },
  {
    label: i18n('DresscodeTypes.formal', 'Formal'),
    value: 'formal',
  },
  {
    label: i18n('DresscodeTypes.blackTie', 'Black tie'),
    value: 'black tie',
  },
  {
    label: i18n('DresscodeTypes.businessProfessional', 'Business professional'),
    value: 'business professional',
  },
  {
    label: i18n('DresscodeTypes.businessCasual', 'Business casual'),
    value: 'business casual',
  },
  {
    label: i18n('DresscodeTypes.casual', 'Casual'),
    value: 'casual',
  },
];

export const EVENT_THEME = [
  {
    value: 'anniversary',
    label: i18n('EventTheme.anniversary', 'Anniversary'),
  },
  {
    value: 'open house',
    label: i18n('EventTheme.openHouse', 'Open house'),
  },
  {
    value: 'dance party',
    label: i18n('EventTheme.danceParty', 'Dance party'),
  },
  {
    value: 'funeral',
    label: i18n('EventTheme.funeral', 'Funeral'),
  },
];

export const STATUS_TYPES: { label: string; value: TransactionStatus }[] = [
  {
    label: i18n('StatusTypes.quotePending', 'Quote Pending'),
    value: 'quotePending',
  },
  {
    label: i18n('StatusTypes.quoteSent', 'Quote Sent'),
    value: 'quoteSent',
  },
  {
    label: i18n('StatusTypes.quoteDeclined', 'Quote Declined'),
    value: 'quoteDeclined',
  },
  {
    label: i18n('StatusTypes.accepted', 'Accepted'),
    value: 'accepted',
  },
  {
    label: i18n('StatusTypes.expired', 'Expired'),
    value: 'expired',
  },
  {
    label: i18n('StatusTypes.declined', 'Declined'),
    value: 'declined',
  },
  {
    label: i18n('StatusTypes.refunded', 'Refunded'),
    value: 'refunded',
  },
  {
    label: i18n('StatusTypes.cancelled', 'Cancelled'),
    value: 'cancelled',
  },
  {
    label: i18n('StatusTypes.completed', 'Completed'),
    value: 'completed',
  },
];

export const PROVINCES_TERRITORIES = () => [
  {
    value: 'QC',
    label: i18n('Provinces.quebec', 'Quebec'),
  },
  {
    value: 'AB',
    label: i18n('Provinces.alberta', 'Alberta'),
  },
  {
    value: 'BC',
    label: i18n('Provinces.britishColumbia', 'British Columbia'),
  },
  {
    value: 'SK',
    label: i18n('Provinces.saskatchewan', 'Saskatchewan'),
  },
  {
    value: 'MB',
    label: i18n('Provinces.manitoba', 'Manitoba'),
  },
  {
    value: 'ON',
    label: i18n('Provinces.ontario', 'Ontario'),
  },
  {
    value: 'NB',
    label: i18n('Provinces.newBrunswick', 'New Brunswick'),
  },
  {
    value: 'NL',
    label: i18n('Provinces.newfoundlandLanbrador', 'Newfoundland and Labrador'),
  },
  {
    value: 'NS',
    label: i18n('Provinces.novaScotia', 'Nova Scotia'),
  },
  {
    value: 'PE',
    label: i18n('Provinces.pei', 'PEI'),
  },
  {
    value: 'YT',
    label: i18n('Provinces.yukon', 'Yukon'),
  },
  {
    value: 'NT',
    label: i18n('Provinces.northwestTerritories', 'Northwest Territories'),
  },
  {
    value: 'Nunavut',
    label: i18n('Provinces.nunavut', 'Nunavut'),
  },
];

export const PROPOSAL_GROUP_TYPES = [
  {
    value: 'dj',
    label: i18n('SharedTypes.dj', 'DJ'),
  },
  {
    value: 'solo',
    label: i18n('SharedTypes.solo', 'Solo'),
  },
  {
    value: 'duo',
    label: i18n('SharedTypes.duo', 'Duo'),
  },
  {
    value: 'trio',
    label: i18n('SharedTypes.trio', 'Trio'),
  },
  {
    value: 'quartet',
    label: i18n('SharedTypes.quartet', 'Quartet'),
  },
  {
    value: 'ensemble',
    label: i18n('SharedTypes.ensemble', 'Ensemble'),
  },
];

// --------------------------ENUMS--------------------------

export enum SignUpStep {
  RegistrationUser = 'registrationUser',
  RegistrationMusicianOrClient = 'registrationMusicianOrClient',
  RegistrationMusicianSoloOrGroup = 'registrationMusicianSoloOrGroup',
  RegistrationBookerTellAboutYourself = 'registrationTellAboutYourself',
  RegistrationBookerLocation = 'registrationBookerLocation',
  RegistrationBookerWaysToBook = 'registrationBookersWaysToBook',
  StepperRegistrationGroupAbout = 'stepperRegistrationMusicianGroupAbout',
  StepperRegistrationSoloAbout = 'stepperRegistrationSoloAbout',
  StepperRegistrationGroupTypeSelect = 'stepperRegistrationCategorySelect',
  StepperRegistrationInstruments = 'stepperRegistrationInstruments',
  StepperRegistrationPlayStyles = 'stepperRegistrationPlayStyles',
  StepperRegistrationEventTypes = 'stepperRegistrationVenueLocations',
  StepperRegistrationMusicianUploadPicture = 'stepperRegistrationMusicianUploadPicture',
}

export enum EventStatusEnum {
  Open = 'open',
  Booked = 'booked',
  Cancelled = 'cancelled',
}

export enum DresscodeEnum {
  None = 'none',
  Formal = 'formal',
  BlackTie = 'black tie',
  BusinessProfessional = 'business professional',
  BusinessCasual = 'business casual',
  Casual = 'casual',
}

export enum CompanyTypeEnums {
  RetirementHome = 'retirement home',
  Hotel = 'hotel',
  BarRestaurant = 'bar restaurant',
  Wedding = 'wedding',
  ForMyself = 'for myself',
}
