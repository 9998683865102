import * as React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

const ATag = ({
  noOpacity,
  href = '',
  target,
  ...rest
}: any & { href?: string; target?: string; noOpacity: boolean }) => {
  if (
    href.includes('http') ||
    !href ||
    href.includes('mailto:') ||
    href.includes('tel:')
  ) {
    return (
      <ATagElm target={target} noOpacity={noOpacity} href={href} {...rest} />
    );
  }
  return <ALinkElm target={target} noOpacity={noOpacity} to={href} {...rest} />;
};

const ALinkElm = styled(Link)<{ noOpacity?: boolean }>`
  text-decoration: none;
  cursor: pointer;
  color: inherit;
  &:hover {
    color: inherit;
    text-decoration: none;
    cursor: pointer;
    opacity: ${(props) => (props.noOpacity ? 1 : 0.8)};
  }
`;

const ATagElm = styled.a<{ noOpacity?: boolean }>`
  text-decoration: none;
  cursor: pointer;
  color: inherit;
  &:hover {
    color: inherit;
    text-decoration: none;
    cursor: pointer;
    opacity: ${(props) => (props.noOpacity ? 1 : 0.8)};
  }
`;

export default ATag;
