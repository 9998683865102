import { observer } from 'mobx-react';
import * as React from 'react';
import styled from 'styled-components';
import colors from '../../constants/colors';
import i18n from '../../constants/i18n';
import images from '../../constants/images';
import {
  GeneralMediumLarge,
  GeneralSemiBoldTitle,
  TextH4,
} from '../common/Typography';
import { UseUserStore } from '../../stores/user';
import ATag from '../common/ATag';
import urls from '../../utils/urls';

const HowItWorksGetADemoSection = observer(() => {
  const userStore = UseUserStore();
  const isFrench = userStore?.browserLanguage === 'fr';
  return (
    <Main id="GET_DEMO_ID">
      <TitleBox>
        <GeneralSemiBoldTitle
          style={{
            fontWeight: 500,
          }}
        >
          {i18n('HowItWorksGetADemoSection.PictureTitle', 'Get a demo')}
        </GeneralSemiBoldTitle>
      </TitleBox>
      <ContentBox>
        <StyledContainer>
          <ImageComputer
            src={
              userStore?.browserLanguage === 'fr'
                ? images.howItWorksWhichAreYouComputerFrImage
                : images.howItWorksWhichAreYouComputerEnImage
            }
          />
        </StyledContainer>
        <StyledContainer>
          <TextH4>{i18n('HowItWorksGetADemoSection.description')}</TextH4>
          <ButtonsBox isFrench={isFrench}>
            <HowItWorksButton>
              <ATag target="_blank" href={urls.demoMusician}>
                <GeneralMediumLarge>
                  {i18n(
                    'HowItWorksGetADemoSection.ButtonMusician',
                    'I play or am involved with music',
                  )}
                </GeneralMediumLarge>
              </ATag>
            </HowItWorksButton>
            <HowItWorksButton>
              <ATag target="_blank" href={urls.demoRequestBooking}>
                <GeneralMediumLarge>
                  {i18n(
                    'HowItWorksGetADemoSection.ButtonClient',
                    "I'm interested in hiring a musician",
                  )}
                </GeneralMediumLarge>
              </ATag>
            </HowItWorksButton>
          </ButtonsBox>
        </StyledContainer>
      </ContentBox>
    </Main>
  );
});

const Main = styled.div`
  width: 1200px;
  height: 584px;
  display: flex;
  flex-direction: column;
  border-radius: 30px;
  background: ${colors.violet};
  background-image: ${colors.violetGradient};
  margin: 48px auto 65px;
  @media (max-width: ${(props) => props.theme.breakPoints.tablet}px) {
    width: 90%;
    margin: 48px auto 48px;
  @media (max-width: ${(props) => props.theme.breakPoints.mobile}px) {
    height: 100%;
    margin: 50px auto 65px;
  }
`;

const TitleBox = styled.div`
  box-sizing: border-box;
  margin-top: 70px;
  margin-left: 44px;
  @media (max-width: ${(props) => props.theme.breakPoints.tablet}px) {
    margin-top: 56px;
    margin-left: 30px;
  }
`;

const ContentBox = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  margin-top: 70px;
  @media (max-width: ${(props) => props.theme.breakPoints.tablet}px) {
    margin-top: 30px;
  }
  @media (max-width: ${(props) => props.theme.breakPoints.mobile}px) {
    flex-direction: column;
  }
`;

const StyledContainer = styled.div`
  height: 100%;
  width: 395px;
  display: flex;
  flex-direction: column;
  flex-wrap: no-wrap;
  justify-content: center;
  align-items: center;
  &:last-child {
    justify-content: start;
    align-items: flex-start;
  }
  @media (max-width: ${(props) => props.theme.breakPoints.tablet}px) {
    width: 90%;
    &:last-child {
      padding-right: 25px;
      padding-left: 15px;
      justify-content: center;
      align-items: flex-start;
    }
  }
  @media (max-width: ${(props) => props.theme.breakPoints.mobile}px) {
    width: 90%;
    margin: 0;
    height: 100%;
    align-items: center;
    &:last-child {
      padding: 0;
      justify-content: center;
      align-items: center;
    }
  }
`;

const ImageComputer = styled.img`
  object-fit: cover;
  width: 485px;
  height: 295px;
  @media (max-width: ${(props) => props.theme.breakPoints.tablet}px) {
    width: 90%;
  }
  @media (max-width: ${(props) => props.theme.breakPoints.mobile}px) {
    width: 100%;
    height: 100%;
  }
`;

const ButtonsBox = styled.div<{ isFrench: boolean }>`
  display: flex;
  width: ${(props) => (props.isFrench ? '370px' : '444px')};
  margin-top: 42px;
  flex-direction: column;
  justify-content: flex-start;
  @media (max-width: ${(props) => props.theme.breakPoints.tablet}px) {
    height: 100%;
    margin-top: 22px;
    width: 100%;
  }
  @media (max-width: ${(props) => props.theme.breakPoints.mobile}px) {
    justify-content: center;
    align-items: center;
    width: ${(props) => (props.isFrench ? '90%' : '100%')};
  }
`;

const HowItWorksButton = styled.button`
  color: ${colors.violet};
  border: 2px solid ${colors.yellow};
  background: ${colors.violet};
  background-image: ${colors.violetGradient};
  height: 100%;
  width: 100%;
  border-radius: 12px;
  margin-bottom: 20px;
  padding: 15px 30px;
  &:last-child {
    margin-bottom: 0;
  }
  @media (max-width: ${(props) => props.theme.breakPoints.tablet}px) {
    width: 100%;
    height: 100%;
    padding: 12px 20px;
    margin-left: auto;
    margin-right: auto;
  @media (max-width: ${(props) => props.theme.breakPoints.mobile}px) {
    width: 100%;
    padding: 10px 10px;
    margin: 0;
    margin-bottom: 20px;
    &:last-child {
      margin-bottom: 50px;
    }
  }
`;

export default HowItWorksGetADemoSection;
