export default {
  'EditActOverview.saved': { en: 'Saved', fr: 'Enregistrée' },
  'EditActOverview.deleteAct': { en: 'Act deleted!', fr: 'ACTIF SUPPRIMÉ!' },
  'EditActOverview.name': { en: 'Act Name', fr: "Nom d'acte" },
  'EditActOverview.location': { en: 'Location', fr: 'Emplacement' },
  'EditActOverview.stripeWarning': {
    en: 'The following member(s) did not setup their banking informations',
    fr: "Le (s) membre suivant n'a pas mis en place leurs informations bancaires",
  },
  'EditActOverview.about': { en: 'About', fr: 'Sur' },
  'EditActOverview.bioPlaceholder': {
    en: 'Write a bit about your act...',
    fr: 'Écrivez un peu sur votre acte ...',
  },
  'EditActOverview.viewPage': { en: 'View Page', fr: 'Voir page' },
  'EditActOverview.location(city,...)': {
    en: 'Location (city, province/state)',
    fr: 'Emplacement (Ville, province / État)',
  },
};
