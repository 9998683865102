import * as React from 'react';
import styled from 'styled-components';

const GiSeparator = ({ style }: { style?: React.CSSProperties }) => {
  return <Main style={style} />;
};

const Main = styled.div`
  display: flex;
  height: 1px;
  background-color: #e0e0e0;
  @media (max-width: ${(props) => props.theme.breakPoints.mobile}px) {
  }
`;

export default GiSeparator;
