export default {
  'FormattedPayoutsScreen.onceYouGetGigging': {
    en: 'Once you get gigging, your invoices will appear here!',
    fr: 'Une fois que vous aurez conduit, vos factures apparaîtront ici!',
  },
  'FormattedPayoutsScreen.transactions': {
    en: 'Transactions',
    fr: 'Transactions',
  },
};
