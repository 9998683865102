import * as React from 'react';
import styled from 'styled-components';
import colors from '../../constants/colors';
import i18n from '../../constants/i18n';
import urls from '../../utils/urls';
import ATag from '../common/ATag';
import { TextH7 } from '../common/Typography';

const SupportContactBox = () => {
  return (
    <ContactBox>
      <TextH7 style={{ color: colors.white }}>
        {i18n('SupportScreen.reachout', 'Reach out')}
      </TextH7>
      <ATag noOpacity={true} href={`mailto:${urls.email}`}>
        <TextH7 style={{ color: colors.white, textDecoration: 'underline' }}>
          {urls.email}
        </TextH7>
      </ATag>
      <ATag noOpacity={true} href={`tel:${urls.phoneNumber}`}>
        <TextH7 style={{ color: colors.white, textDecoration: 'underline' }}>
          {urls.phoneNumber}
        </TextH7>
      </ATag>
    </ContactBox>
  );
};

const ContactBox = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 30px 21px;
  box-sizing: border-box;
  height: 86px;
  background: #210154;
  border-radius: 2px;
  margin-top: 16px;
  @media (max-width: ${(props) => props.theme.breakPoints.mobile}px) {
    flex-direction: column;
    height: 150px;
  }
`;

export default SupportContactBox;
