/* eslint-disable react/prop-types */
import * as React from 'react';
import styled from 'styled-components';
import { GeneralRegularNormal } from './Typography';
import colors from '../../constants/colors';
import Dropdown from 'react-bootstrap/Dropdown';
import { shorten } from '../../utils/string';

// The forwardRef is important!!
// Dropdown needs access to the DOM node in order to position the Menu

// @ts-ignore
const CustomDropDownItem = React.forwardRef(({ children, onClick }, ref) => (
  <DropDownItemContainer
    // bootstrap stuff
    // @ts-ignore
    ref={ref}
    onClick={(e) => {
      // e.preventDefault();
      onClick(e);
    }}
  >
    <GeneralRegularNormal
      style={{
        color: colors.darkBlue,
      }}
    >
      {children}
    </GeneralRegularNormal>
  </DropDownItemContainer>
));

interface MapboxGeocode {
  bbox: Array<number>;
  center: Array<number>;
  geometry: { type: string; coordinates: Array<number> };
  id: string;
  place_name: string;
  matching_place_name: string;
  matching_text: string;
  place_type: Array<string>;
  text: string;
  type: string;
}

const GiLocationDropDown = ({
  items,
  onChange,
  style,
  isMobile,
}: {
  items: MapboxGeocode[];
  onChange: (item: MapboxGeocode, index: number) => void;
  style?: React.CSSProperties;
  isMobile: boolean;
}) => {
  return (
    <Column style={style}>
      <Dropdown
        style={{
          padding: 0,
          width: '100%',
          overflowY: 'auto',
          maxHeight: 300,
          border: `1px solid ${colors.buttonBorder}`,
          borderRadius: '10px',
          zIndex: 9,
        }}
      >
        {items?.map((item: MapboxGeocode, index) => (
          <Dropdown.Item
            key={item.id}
            onSelect={() => onChange(item, index)}
            as={CustomDropDownItem}
          >
            {isMobile
              ? shorten(item.place_name, 20)
              : shorten(item.place_name, 90)}
          </Dropdown.Item>
        ))}
      </Dropdown>
    </Column>
  );
};

const Column = styled.div`
  display: flex;
  flex-direction: column;
`;

const DropDownItemContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 35px;
  box-sizing: border-box;
  padding: 10px 20px;
  background-color: white;
  color: black;
  cursor: pointer;
  &:hover {
    background-color: lightgrey;
  }
`;

export default GiLocationDropDown;
