import { observer } from 'mobx-react-lite';
import * as React from 'react';
import styled from 'styled-components';
import metrics from '../../constants/metrics';
import { Artist } from '../../shared';
import { useUiStore } from '../../stores/ui';
import sexpressClient from '../../utils/sexpress';
import ProfileBannerSection from '../common/profile/ProfileBannerSection';

import PageLayout from '../common/profile/ProfilePageLayout';
import ArtistActSection from '../common/profile/ProfileActsOrMemberSection';
import ArtistBioSection from '../common/profile/ProfileBioSection';
import ArtistGallerySection from '../common/profile/ProfileGallerySection';
import ArtistPriceSection from '../common/profile/ProfilePriceSection';
import ArtistProfilePictureSection from '../common/profile/ProfilePictureSection';
import ArtistReviewSection from '../common/profile/ProfileReviewSection';
import ArtistSocialLinksSection from '../common/profile/ProfileSocialLinksSection';
import ArtistSpotifySection from '../common/profile/ProfileSpotifySection';
import ArtistTagSection from '../common/profile/ProfileTagSection';
import ArtistVideoSection from '../common/profile/ProfileVideoSection';
import { useLocation, useParams } from 'react-router-dom';
import toast from 'react-hot-toast';

const ArtistProfileScreen = observer(() => {
  const { id } = useParams();
  const location = useLocation();

  const [contentWidth, setContentWidth] = React.useState(0);
  const [contentHeight, setContentHeight] = React.useState(1900);
  const [scrollTop, setScrollTop] = React.useState(0);
  const [artist, setArtist] = React.useState<Artist | undefined>();
  const [initLoading, setInitLoading] = React.useState(true);
  const [bannerLoading, setBannerLoading] = React.useState(false);
  const [forceRefetch, setForceRefetch] = React.useState(1);

  const renderHeader = () => (
    <ProfileBannerSection
      entity={artist!}
      entityType="artist"
      setBannerLoading={setBannerLoading}
    />
  );
  const uiStore = useUiStore();
  const contentRef = React.useRef<any>();

  const fetchArtistData = React.useCallback(async () => {
    const artist = await sexpressClient.getArtist({
      artistId: id,
    });
    return artist;
  }, [location.pathname]);

  React.useEffect(() => {
    const fetchArtist = async () => {
      if (bannerLoading) {
        return;
      }
      try {
        setArtist(undefined);
        const artist = await fetchArtistData();
        setArtist(artist);
        setInitLoading(false);
      } catch (e) {
        console.error(e);
      }
    };
    fetchArtist();
  }, [location.pathname]);

  React.useEffect(() => {
    const fetchArtist = async () => {
      if (bannerLoading || initLoading) {
        return;
      }

      try {
        const artist = await fetchArtistData();
        setArtist(artist);
      } catch (e) {
        const err = e as Error;
        toast.error(err.message);
      }
    };
    fetchArtist();
  }, [bannerLoading, forceRefetch]);

  React.useEffect(() => {
    if (artist) {
      setContentWidth(contentRef.current!.clientWidth);
      setContentHeight(contentRef.current!.clientHeight);
    }
  }, [uiStore.innerWidth, uiStore.innerHeight, artist]);

  return (
    <PageLayout
      renderHeader={renderHeader}
      onScroll={setScrollTop}
      extraFooterPadding={uiStore.isMobile ? 170 : undefined}
      loading={typeof artist === 'undefined'}
      contentStyle={{ zIndex: 2 }}
    >
      <Content ref={contentRef}>
        <ArtistProfilePictureSection
          entity={artist!}
          entityType="artist"
          forceRefetch={() => setForceRefetch(new Date().getTime())}
        />
        <ArtistTagSection entity={artist!} />
        <ArtistBioSection entity={artist!} />
        <ArtistActSection entity={artist!} entityType="artist" />
        <ArtistSocialLinksSection entity={artist!} />
        <ArtistVideoSection entity={artist!} entityType="artist" />
        <ArtistSpotifySection entity={artist!} entityType="artist" />
        <ArtistGallerySection
          entity={artist!}
          entityType="artist"
          contentWidth={contentWidth}
        />
        <ArtistReviewSection entity={artist!} />
        <ArtistPriceSection
          entity={artist!}
          contentWidth={contentWidth}
          scrollTop={scrollTop}
          entityType="artist"
          contentHeight={contentHeight}
        />
      </Content>
    </PageLayout>
  );
});

const Content = styled.div`
  display: flex;
  max-width: ${metrics.maxContentWidth}px;
  flex-direction: column;
  width: 75%;
  @media (max-width: 1400px) {
    max-width: 600px;
  }
  @media (max-width: ${(props) => props.theme.breakPoints.mobile}px) {
    width: 100%;
    max-width: 100%;
  }
`;

export default ArtistProfileScreen;
