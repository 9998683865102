import * as React from 'react';
import styled from 'styled-components';
import colors from '../../constants/colors';
import i18n from '../../constants/i18n';
import { useUiStore } from '../../stores/ui';
import GiButton from '../common/GiButton';
import { GeneralRegularNormal, TextH2 } from '../common/Typography';

const BookingFinalMessage = () => {
  const uiStore = useUiStore();
  return (
    <Main>
      <TextH2
        style={{
          color: colors.whiteText,
          marginBottom: 36,
          alignSelf: 'center',
          textAlign: 'center',
        }}
      >
        {i18n(
          'BookingFinalMessage.thanks',
          'Thank you for submitting an offer!',
        )}
      </TextH2>

      <GeneralRegularNormal
        style={{
          color: colors.whiteText,
          alignSelf: 'center',
          textAlign: 'center',
          width: '90%',
          maxWidth: 358,
        }}
      >
        {i18n(
          'BookingFinalMessage.subTitle',
          'Your offer has been submitted to the artist and you will hear back from them shortly!',
        )}
      </GeneralRegularNormal>

      <GiButton
        label={i18n('BookingFinalMessage.close', 'Close')}
        style={{ alignSelf: 'center', marginTop: 48 }}
        onClick={uiStore.closePopup}
      />
    </Main>
  );
};

const Main = styled.div`
  display: flex;
  box-sizing: border-box;
  flex-direction: column;
  align-self: center;
  padding-bottom: 63px;
  width: 100%;
  max-width: 543px;
  @media (max-width: ${(props) => props.theme.breakPoints.mobile}px) {
    padding: 0px 20px;
  }
`;

export default BookingFinalMessage;
