export default {
  'BookingSummary.title': { en: 'Summary', fr: 'Résumé' },
  'BookingSummary.confirm': { en: 'Confirm', fr: 'Confirmer' },
  'BookingSummary.eventType': { en: 'Event Type', fr: "Type d'événement" },
  'BookingSummary.dateAndTime': { en: 'Date and Time', fr: "Date et l'heure" },
  'BookingSummary.setup': {
    en: 'hour(s) time for set up',
    fr: 'heure (s) heure pour la configuration',
  },
  'BookingSummary.location': { en: 'Location', fr: 'Emplacement' },
  'BookingSummary.outdoor': { en: 'Outdoors', fr: 'En plein air' },
  'BookingSummary.covered': { en: 'covered', fr: 'couverte' },
  'BookingSummary.notcovered': { en: 'uncovered', fr: 'découverte' },
  'BookingSummary.indoor': { en: 'Indoors', fr: "À l'intérieur" },
  'BookingSummary.audience': { en: 'Audience', fr: 'Spectatrices' },
  'BookingSummary.estimated': {
    en: 'Estimated Audience Size',
    fr: 'Taille du public estimé',
  },
  'BookingSummary.dessCode': { en: 'Dresscode', fr: 'Code vestimentaire' },
  'BookingSummary.additionalInformation': {
    en: 'Additional Information',
    fr: 'Informations Complémentaires',
  },
  'BookingSummary.contact': { en: 'Contact Information', fr: 'Coordonnées' },
};
