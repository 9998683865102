import * as React from 'react';
import styled from 'styled-components';
import { GeneralRegularNormal } from '../common/Typography';

const TruncatedBio = ({ bio, numLines }: { bio: string; numLines: number }) => {
  const bioRef = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    const bioElement = bioRef.current;
    if (bioElement) {
      const lineHeight = parseInt(
        window.getComputedStyle(bioElement).lineHeight,
      );
      bioElement.style.maxHeight = `${lineHeight * numLines}px`;
    }
  }, [bio]);

  return (
    <BioContainer ref={bioRef}>
      <GeneralRegularNormal>{bio}</GeneralRegularNormal>
    </BioContainer>
  );
};

const BioContainer = styled.div`
  overflow: hidden;
  position: relative;
`;

export default TruncatedBio;
