import { observer } from 'mobx-react';
import * as React from 'react';
import styled from 'styled-components';
import colors from '../../../constants/colors';
import i18n from '../../../constants/i18n';
import images from '../../../constants/images';
import { UseUserStore } from '../../../stores/user';
import { TextH5 } from '../../common/Typography';
import { SignUpStep } from '../../../shared';
import { useArtistStore } from '../../../stores/artist';
import GiAuthContainer from '../../common/GiAuthContainer';

const RegistrationMusicianOrClient = observer(() => {
  const userStore = UseUserStore();
  const { setSignUpStep } = userStore;
  const artistStore = useArtistStore();

  return (
    <GiAuthContainer
      title={i18n('RegistrationMusicianOrClient.areYou', 'Are you:')}
      editableContent={
        <BoxTwoButtons>
          <BoxButton
            onClick={async () => {
              setSignUpStep(SignUpStep.RegistrationMusicianSoloOrGroup);
              await artistStore.initArtist();
            }}
          >
            <Icon
              src={images.musicianLogo}
              style={{ filter: colors.musicianColorFilter }}
            />
            <TextBox>
              <TextH5 style={{ fontWeight: 500 }}>
                {i18n('RegistrationMusicianOrClient.musician', 'a musician')}
              </TextH5>
              <TextH5 style={{ fontWeight: 500 }}>
                {i18n('RegistrationMusicianOrClient.artist', '/ artist')}
              </TextH5>
            </TextBox>
          </BoxButton>
          <BoxButton
            onClick={() => {
              setSignUpStep(SignUpStep.RegistrationBookerTellAboutYourself);
            }}
          >
            {' '}
            <Icon
              src={images.bookerLogo}
              style={{ filter: colors.bookerColorFilter }}
            />
            <TextBox>
              <TextH5 style={{ fontWeight: 500 }}>
                {i18n('RegistrationMusicianOrClient.lookingFor', 'looking for')}
              </TextH5>
              <TextH5 style={{ fontWeight: 500 }}>
                {i18n('RegistrationMusicianOrClient.musicians', 'musicians')}
              </TextH5>
            </TextBox>
          </BoxButton>
        </BoxTwoButtons>
      }
    />
  );
});

const BoxTwoButtons = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-evenly;
  position: relative;
  @media (max-width: ${(props) => props.theme.breakPoints.mobile}px) {
    width: 300px;
    height: 300px;
    align-items: center;
  }
`;

const BoxButton = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  width: 200px;
  height: 150px;
  background: ${colors.white};
  justify-content: space-evenly;
  border: 2px solid ${colors.buttonBorder};
  border-radius: 10px;
  padding: 20px;
  margin: 10px;
  transition: 0.3s;
  z-index: 2;
  &:hover {
    transform: scale(1.02);
    transition: all 0.4s ease-out;
    cursor: pointer;
    text-decoration: none;
    border: 2px solid ${colors.red};
    box-shadow: -5px 5px ${colors.red};
  }
`;

const TextBox = styled.div`
  margin-top: 1rem;
  text-align: start;
`;

const Icon = styled.img`
  width: 50px;
  height: 50px;
`;

export default RegistrationMusicianOrClient;
