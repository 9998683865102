import { Transaction, User } from '../../../shared';
import { UseUserStore } from '../../../stores/user';

interface GetRequestDetailsQuoteSectionFormatedData {
  meIsAct: boolean;
  meIsArtist: boolean;
  meIsBooker: boolean;
  otherUser: User;
}

const useGetRequestDetailsQuoteFormattedData = (request: Transaction) => {
  const userStore = UseUserStore();
  const me = userStore.getMe()!;
  const { artist, act, booker, serviceType } = request;

  const meIsAct = !!act && me.id === act.owner.user.id;
  const meIsArtist = !!artist && me.id === artist.user.id;
  const meIsBooker = !meIsAct && !meIsArtist;

  const otherUser =
    meIsAct || meIsArtist
      ? booker
      : serviceType === 'act'
      ? act.owner.user
      : artist.user;

  return {
    meIsAct,
    meIsArtist,
    meIsBooker,
    otherUser,
  } as unknown as GetRequestDetailsQuoteSectionFormatedData;
};

export default useGetRequestDetailsQuoteFormattedData;
