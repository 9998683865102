import * as React from 'react';
import i18n from '../../constants/i18n';
import { Transaction } from '../../shared';
import sexpressClient from '../../utils/sexpress';
import GiButton from '../common/GiButton';

const RequestCancelButtonSection = ({
  request,
  fetchTransaction,
}: {
  request: Transaction;
  fetchTransaction: () => void;
}) => {
  const [isLoading, setIsLoading] = React.useState(false);

  const { id, status } = request;

  if (
    ['expired', 'declined', 'refunded', 'cancelled', 'completed'].includes(
      status,
    )
  ) {
    return null;
  }

  const onClickCancel = async () => {
    try {
      setIsLoading(true);
      await sexpressClient.updateTransactionState({
        action: 'cancel',
        transactionId: id,
      });

      fetchTransaction();
    } catch (e) {
      console.log(e);
    } finally {
      setIsLoading(false);
    }
  };
  return (
    <GiButton
      label={i18n('RequestCancelButtonSection.cancelRequest', 'Cancel Request')}
      inverted
      style={{
        alignSelf: 'center',
        width: '100%',
        marginBottom: 100,
        backgroundColor: 'rgba(0,0,0,0.1)',
        border: 'none',
      }}
      onClick={onClickCancel}
      loading={isLoading}
    />
  );
};

export default RequestCancelButtonSection;
