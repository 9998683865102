import { action } from 'mobx';
import { useStores } from '.';
import { Artist, Tag } from '../shared';
import user from './user';
import sexpressClient from '../utils/sexpress';
import toast from 'react-hot-toast';
import i18n from '../constants/i18n';

export interface ArtistStoreType {
  initArtist: () => Promise<Artist>;
  editMeArtist: (
    payload: Partial<Artist> & {
      croppingOptions?: {
        width?: number;
        height?: number;
        left?: number;
        top?: number;
        bannerImageB64?: string;
        profileImageB64?: string;
      };
    },
  ) => Promise<void>;
  fetchMeArtist: () => Promise<void>;
  editTags: (payload: Partial<Tag>) => Promise<Tag[]>;
  manuallyEditMeArtist: (payload: Partial<Artist>) => Artist;
  maybeShowBankingReminder: () => void;
}

class ArtistStore {
  @action
  initArtist: ArtistStoreType['initArtist'] = async () => {
    const artist = await sexpressClient.initArtist();
    return artist;
  };

  @action
  editMeArtist: ArtistStoreType['editMeArtist'] = async (payload) => {
    const meArtist = await sexpressClient.editMeArtist(payload);
    user.me!.artist = meArtist!;
    user.me!.profilePicture = meArtist!.profilePicture;
  };

  @action
  fetchMeArtist: ArtistStoreType['fetchMeArtist'] = async () => {
    const meArtist = await sexpressClient.getMeArtist();
    user.me!.artist = meArtist;
  };

  @action
  editTags: ArtistStoreType['editTags'] = async (payload) => {
    const updatedTags = await sexpressClient.editArtistTags(payload);
    user.me!.artist.tags = updatedTags;
    return updatedTags;
  };

  @action
  manuallyEditMeArtist: ArtistStoreType['manuallyEditMeArtist'] = (payload) => {
    const artistObj = user.getMe();
    user.me!.artist = {
      ...artistObj,
      ...payload,
    } as Artist;
    return user.me!.artist;
  };

  maybeShowBankingReminder: ArtistStoreType['maybeShowBankingReminder'] =
    () => {
      if (user?.me?.artist && !user.me.artist.stripe?.isAccountConnected) {
        toast.error(
          i18n(
            'ArtistReminders.rememberToUpdateYourBankingInfo',
            'Remember to update your banking info to receive direct payments (Powered by Stripe)',
          ),
          {
            duration: 6000,
            icon: '❗️',
          },
        );
      }
    };
}

export default new ArtistStore();

export function useArtistStore(): ArtistStoreType {
  const { artist } = useStores();
  return artist as ArtistStoreType;
}
