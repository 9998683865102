import * as React from 'react';
import { Box, Text } from 'react-native-kondo';
import colors from '../../constants/colors';

const NotFoundScreen = () => (
  <Box flex={1} alignItems="center" justifyContent="center" bg={colors.white}>
    <Text>404</Text>
  </Box>
);

export default NotFoundScreen;
