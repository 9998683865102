import * as React from 'react';
import styled from 'styled-components';
import colors from '../../constants/colors';
import i18n from '../../constants/i18n';
import { DRESSCODE_TYPES, ESTIMATED_AUDIENCE } from '../../shared';
import { useBookingStore } from '../../stores/booking';
import GiButton from '../common/GiButton';
import GiDropDown from '../common/GiDropDown';
import { TextH2, TextH3 } from '../common/Typography';

const BookingAudience = ({ onPressNext }: { onPressNext: () => void }) => {
  const bookingStore = useBookingStore();
  const [estimatedAudience, setEstimatedAudience] = React.useState<{
    label: string;
    value: string;
  }>(
    // @ts-ignore
    bookingStore.estimatedAudience
      ? ESTIMATED_AUDIENCE().find(
          (s) => s.value === bookingStore.estimatedAudience,
        )
      : ESTIMATED_AUDIENCE()[0],
  );

  const [dresscode, setDresscode] = React.useState<{
    label: string;
    value: string;
  }>(
    // @ts-ignore
    bookingStore.dresscode.length
      ? DRESSCODE_TYPES.find((s) => s.value === bookingStore.dresscode)
      : DRESSCODE_TYPES[0],
  );
  return (
    <Main>
      <TextH2
        style={{
          color: colors.whiteText,
          marginBottom: 36,
          alignSelf: 'center',
          textAlign: 'center',
        }}
      >
        {i18n('BookingAudience.title', 'Tell us about your audience')}
      </TextH2>

      <TextH3 style={{ color: colors.whiteText, marginBottom: 9 }}>
        {i18n('BookingAudience.estimatedAudience', 'Estimated Audience Size')}{' '}
      </TextH3>
      <GiDropDown
        style={{ marginBottom: 48 }}
        items={ESTIMATED_AUDIENCE()}
        value={estimatedAudience ? estimatedAudience.label : undefined}
        onChange={(ev) => {
          setEstimatedAudience(ev);
          bookingStore.setBookingData({
            estimatedAudience: ev.value,
          });
        }}
      />

      <TextH3 style={{ color: colors.whiteText, marginBottom: 9 }}>
        {i18n('BookingAbout.dresscode', 'Dresscode')}
      </TextH3>

      <GiDropDown
        items={DRESSCODE_TYPES}
        value={dresscode.label}
        onChange={(item) => {
          setDresscode(item);
          bookingStore.setBookingData({
            dresscode: item.value,
          });
        }}
      />

      <GiButton
        label={i18n('BookingEventSelction.next', 'Next')}
        style={{ alignSelf: 'center', marginTop: 36 }}
        onClick={onPressNext}
        disabled={!estimatedAudience || !dresscode}
      />
    </Main>
  );
};

const Main = styled.div`
  display: flex;
  box-sizing: border-box;
  flex-direction: column;
  align-self: center;
  padding-bottom: 158px;
  @media (max-width: ${(props) => props.theme.breakPoints.mobile}px) {
    padding: 0px 20px;
  }
`;

export default BookingAudience;
