import * as React from 'react';
import styled from 'styled-components';
import { ClipLoader } from 'react-spinners';
import { TextH7Button } from './Typography';
import colors from '../../constants/colors';

const GiglinkedButton = ({
  label,
  style,
  onClick,
  disabled,
  loading,
  warning,
  success,
  inverted,
}: {
  label: string;
  style?: React.CSSProperties;
  onClick?: () => void;
  disabled?: boolean;
  loading?: boolean;
  warning?: boolean;
  success?: boolean;
  inverted?: boolean;
}) => {
  return (
    <Container
      style={style}
      onClick={disabled || loading ? undefined : onClick}
      disabled={disabled}
      tabIndex={0}
      warning={warning}
      success={success}
      inverted={inverted}
    >
      {loading ? (
        <ClipLoader color={colors.lightGray} size={20} />
      ) : (
        <TextH7Button style={{ textAlign: 'center' }}>{label}</TextH7Button>
      )}
    </Container>
  );
};

function getBorderColor(props: any) {
  if (props.disabled) {
    return 'none';
  } else if (props.success) {
    return props.theme.colors.successGreen;
  } else if (props.warning) {
    return props.theme.colors.red;
  } else {
    return props.theme.colors.buttonTextColor;
  }
}

function getBackgroundColor(props: any) {
  if (props.disabled) return props.theme.colors.disabledLightGrey;
  else if (props.inverted) return props.theme.colors.buttonTextColor;
  else if (props.success) return props.theme.colors.white;
  else return 'transparent';
}

function getTextColor(props: any) {
  if (props.disabled) {
    return props.theme.colors.disabledDarkGrey;
  } else if (props.inverted) {
    return props.theme.colors.white;
  } else if (props.success) {
    return props.theme.colors.successGreen;
  } else if (props.warning) {
    return props.theme.colors.red;
  } else {
    return props.theme.colors.buttonTextColor;
  }
}

const Container = styled.div<{
  disabled?: boolean;
  warning?: boolean;
  success?: boolean;
  inverted?: boolean;
}>`
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 12px;
  padding: 10px;
  cursor: ${(props) => (props.disabled ? undefined : 'pointer')};
  border: 2px solid ${(props) => getBorderColor(props)};
  background-color: ${(props) => getBackgroundColor(props)};
  transition: opacity 0.2s ease;
  span {
    font-weight: 600;
    font-size: 1rem;
    color: ${(props) => getTextColor(props)};
  align-self: center;
  &:hover {
    opacity: ${(props) => (props.disabled ? 1 : 0.6)};
  }
`;

export default GiglinkedButton;
