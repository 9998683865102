import * as React from 'react';
import { Image } from 'react-native';
import images from '../../constants/images';
import { Box } from 'react-native-kondo';
import { TextH1 } from '../common/Typography';
import colors from '../../constants/colors';
import i18n from '../../constants/i18n';
import PerformerBannerSearch from './PerformerBannerSearch';
import { useUiStore } from '../../stores/ui';
import { observer } from 'mobx-react-lite';

const PerformerBanner = observer(
  ({
    keywords,
    location,
    onChangeKeywords,
    onChangeLocation,
    onPressSearch,
  }: {
    keywords: string;
    location: string;
    onChangeKeywords: (value: string) => void;
    onChangeLocation: (value: string) => void;
    onPressSearch: () => void;
  }) => {
    const { navBarHeight } = useUiStore();

    return (
      <Box
        style={{ width: '100%', height: 294 + navBarHeight }}
        alignItems="center"
        justifyContent="center"
        px={20}
      >
        <Image
          style={{
            width: '100%',
            height: 294 + navBarHeight,
            position: 'absolute',
            left: 0,
            right: 0,
            bottom: 0,
            top: 0,
          }}
          source={{ uri: images.homebanner }}
        />
        <TextH1
          style={{
            textAlign: 'center',
            color: colors.whiteText,
            zIndex: 2,
            marginBottom: 36,
            paddingTop: navBarHeight,
          }}
        >
          {i18n('PerformerBanner.FindaMusician', 'Find a Musician')}
        </TextH1>
        <PerformerBannerSearch
          keywords={keywords}
          location={location}
          onChangeKeywords={onChangeKeywords}
          onChangeLocation={onChangeLocation}
          onPressSearch={onPressSearch}
        />
      </Box>
    );
  },
);

export default PerformerBanner;
