import * as React from 'react';
import { observer } from 'mobx-react';
import styled from 'styled-components';
import HowItWorksAccordion from './howItWorksAccordion/HowItWorksAccordion';
import { GeneralMediumTitle } from '.././common/Typography';
import i18n from '../../constants/i18n';
import { useUiStore } from '../../stores/ui';

const HowItWorksAccordionSection = observer(() => {
  const uiStore = useUiStore();
  const accordionItems = [
    {
      title: i18n(
        'HowItWorksAccordionSection.AccordionPaymentsTitle',
        'How do payments work?',
      ),
      content: (
        <>
          {i18n(
            'HowItWorksAccordionSection.AccordionPaymentsDesciptionFirstParagraph',
            '',
          )}
          <DescriptionBreak />
          {i18n(
            'HowItWorksAccordionSection.AccordionPaymentsDesciptionSecondParagraph',
            '48 hours after the concert has been performed by the booked musician, we withdraw the remainder of the amount owed for the booking. This is to ensure that the request has been completed to both the satisfaction of the musician and the client.',
          )}
        </>
      ),
    },
    {
      title: i18n(
        'HowItWorksAccordionSection.AccordionRefundsTitle',
        'How do refunds work?',
      ),
      content: (
        <>
          {i18n(
            'HowItWorksAccordionSection.AccordionRefundsDesciptionFirstParagraph',
            '',
          )}
          <DescriptionBreak />
          {i18n(
            'HowItWorksAccordionSection.AccordionRefundsDesciptionSecondParagraph',
            '',
          )}
        </>
      ),
    },
    {
      title: i18n(
        'HowItWorksAccordionSection.AccordionTransactionsTitle',
        'How do you process transactions?',
      ),
      content: (
        <>
          {i18n(
            'HowItWorksAccordionSection.AccordionTransactionsDesciptionFirstParagraph',
            '',
          )}
          <DescriptionBreak />
          {i18n(
            'HowItWorksAccordionSection.AccordionTransactionsDesciptionSecondParagraph',
            '',
          )}
        </>
      ),
    },
    {
      title: i18n('HowItWorksAccordionSection.AccordionRegistrationTitle', ''),
      content: i18n(
        'HowItWorksAccordionSection.AccordionRegistrationDesciption',
        '',
      ),
    },
    {
      title: i18n(
        'HowItWorksAccordionSection.AccordionPaymentMethodsTitle',
        'What payment methods are accepted?',
      ),
      content: i18n(
        'HowItWorksAccordionSection.AccordionPaymentMethodsDesciption',
        '',
      ),
    },
  ];

  return (
    <Main>
      <GeneralMediumTitle style={{ marginBottom: uiStore.isMobile ? 40 : 70 }}>
        {i18n(
          'HowItWorksAccordionSection.AccordionTitle',
          'Frequently asked questions',
        )}
      </GeneralMediumTitle>
      <HowItWorksAccordion items={accordionItems} />
    </Main>
  );
});

const Main = styled.div`
  display: flex;
  flex-direction: column;
  margin: 40px auto 130px;
  width: 1200px;
  padding: 90px 2rem;
  @media (max-width: ${(props) => props.theme.breakPoints.tablet}px) {
    width: 90%;
    margin: 48px auto 130px auto;
  }
  @media (max-width: ${(props) => props.theme.breakPoints.mobile}px) {
    width: 90%;
    margin: 50px auto 50px auto;
    padding: 40px 10px;
  }
`;

const DescriptionBreak = styled.div`
  padding: 20px;
`;

export default HowItWorksAccordionSection;
