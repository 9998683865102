export default {
  'CreateEventScreen.stepOneDescription': {
    en: 'Step 1 of 4 - General information',
    fr: 'Étape 1 de 4 - Informations générales',
  },
  'CreateEventScreen.stepTwoDescription': {
    en: 'Step 2 of 4 - Event details',
    fr: "Étape 2 de 4 - Détails de l'événement",
  },
  'CreateEventScreen.stepThreeDescription': {
    en: 'Step 3 of 4 - Music details',
    fr: 'Étape 3 de 4 - Détails pour la musique',
  },
  'CreateEventScreen.stepFourescription': {
    en: 'Step 4 of 4 - Description',
    fr: 'Étape 4 de 4 - Description',
  },
  'CreateEventScreen.title': { en: 'New Event', fr: 'Nouvel événement' },
  'CreateEventScreen.successTitle': {
    en: 'Congratulations! Your event is created!',
    fr: 'Félicitations ! Votre événement est maintenant publié !',
  },
  'CreateEventScreen.goToDashboard': {
    en: 'Go to Dashboard',
    fr: 'Accéder au tableau de bord',
  },
  'CreateEventScreen.successSecondaryText': {
    en: 'It has been added to your Talent Call list on your dashboard. You can view and edit all your events in your dashboard.',
    fr: 'Vous pouvez désormais gérer cet événement dans votre tableau de bord.',
  },
  'CreateEventScreen.back': { en: 'Back', fr: 'Retour' },
  'CreateEventScreen.postEvent': {
    en: 'Post event',
    fr: 'Afficher mon evenement',
  },
  'CreateEventScreen.next': { en: 'Next', fr: 'Suivant' },
};
