export default {
  'EditArtistProfileOverview.availability': {
    en: 'Availability',
    fr: 'Disponibilité',
  },
  'EditArtistProfileOverview.availabilityGig': { en: 'Gigs', fr: 'Concerts' },
  'EditArtistProfileOverview.availabilityCollab': {
    en: 'Collaborations',
    fr: 'Collaborations',
  },
  'EditArtistProfileOverview.tag': { en: 'Tags', fr: 'Mots clés' },
  'EditArtistProfileOverview.yourTagsAreHowOtherUsers': {
    en: 'Your tags are how other users will find you. We recommend using tags related to musical genres, instruments, and event types you want to be booked for!',
    fr: "Vos balises sont la façon dont les autres utilisateurs vous trouveront. Nous vous recommandons d'utiliser des balises liées aux genres musicaux, aux instruments et aux types d'événements pour lesquels vous souhaitez être réservé!",
  },
  'EditArtistProfileOverview.saved': { en: 'Saved', fr: 'Enregistrée' },
  'EditArtistProfileOverview.name': { en: 'Stage Name', fr: 'Nom de scène' },
  'EditArtistProfileOverview.headline': { en: 'Headline', fr: 'Gros titre' },
  'EditArtistProfileOverview.thisWillAppearUnderYourName': {
    en: 'This will appear under your name on your profile and when you appear in search results. Just be yourself!',
    fr: 'Cela apparaîtra sous votre nom sur votre profil et lorsque vous appartenez aux résultats de la recherche. Sois toi-même!',
  },
  'EditArtistProfileOverview.location': { en: 'Location', fr: 'Emplacement' },
  'EditArtistProfileOverview.thisIsPurelyForInformationalPurposes': {
    en: 'This is purely for informational purposes only, when you receive a booking request, you have total control over what you quote for your services',
    fr: "Ceci est uniquement à des fins d'information uniquement, lorsque vous recevez une demande de réservation, vous avez un contrôle total sur ce que vous citez pour vos services",
  },
  'EditArtistProfileOverview.stripeWarning': {
    en: 'You need to setup your banking informations first',
    fr: "Vous devez d'abord configurer vos informations bancaires",
  },
  'EditArtistProfileOverview.about': { en: 'About', fr: 'Sur' },
  'EditArtistProfileOverview.tellUsAboutYourself': {
    en: 'Tell us about yourself! Need inspiration, click the "Guide button at the top of the page',
    fr: "Parlez nous de vous! Besoin d'inspiration, cliquez sur le bouton Guide en haut de la page",
  },
  'EditArtistProfileOverview.bioPlaceholder': {
    en: 'Write a bit about yourself....',
    fr: 'Écrivez un peu sur vous-même ...',
  },
  'EditArtistProfileOverview.dontForgetToSetupYourBankingInformation': {
    en: "Don't forget to setup your banking information!",
    fr: "N'oubliez pas de configurer vos informations bancaires!",
  },
  'EditArtistProfileOverview.searchKeywords': {
    en: 'Search Keywords',
    fr: 'Rechercher des mots-clés',
  },
  'EditArtistProfileOverview.location(city,...)': {
    en: 'Location (city, province/state)',
    fr: 'Emplacement (Ville, province / État)',
  },
};
