import * as React from 'react';
import styled from 'styled-components';
import InputMask from 'react-input-mask';
import images from '../../constants/images';

const GiglinkedTextInput = ({
  value,
  onChangeText = (str: string) => str,
  style,
  showCheckMark = false,
  mask,
  maskPlaceholder,
  ...rest
}: React.InputHTMLAttributes<any> & {
  value?: string | number | undefined;
  onChangeText?: (str: string) => void;
  style?: React.CSSProperties;
  showCheckMark?: boolean;
  mask?: string;
  maskPlaceholder?: string;
}) => {
  let InputContent = (
    <Input
      value={value}
      showCheckMark={showCheckMark}
      onChange={(ev) => onChangeText(ev.target.value)}
      {...rest}
    />
  );

  if (mask) {
    InputContent = (
      <InputMask
        value={value}
        mask={mask}
        onChange={(ev) => onChangeText(ev.target.value)}
        maskPlaceholder={maskPlaceholder || ''}
        {...rest}
      >
        <Input showCheckMark={showCheckMark} />
      </InputMask>
    );
  }

  return (
    <>
      <Main style={style}>
        {InputContent}
        {showCheckMark ? <CheckMark src={images.checkMarkGreen} /> : null}
      </Main>
    </>
  );
};
const Main = styled.div`
  display: flex;
  box-sizing: border-box;
  position: relative;
  background-color: ${(props) => props.theme.colors.white};
  border-radius: 10px;
  height: 44px;
`;

const CheckMark = styled.img`
  width: 14px;
  height: 14px;
  position: absolute;
  right: 22px;
  top: 18px;
  @media (max-width: ${(props) => props.theme.breakPoints.mobile}px) {
    right: 22px;
    top: 11px;
  }
`;

const Input = styled.input<{ showCheckMark?: boolean }>`
  text-decoration: none;
  outline: none;
  height: 44px;
  font-size: 16px;
  padding-left: 10px;
  box-sizing: border-box;
  width: 100%;
  background-color: transparent;
  border-radius: 10px;
  padding-right: ${(props) => (props.showCheckMark ? '48px' : '10px')};
  font-family: ${(props) => props.theme.fonts.GeneralSans};
  font-weight: normal;
  border: 1px solid ${(props) => props.theme.colors.buttonBorder};
  color: ${(props) => props.theme.colors.black};
  :focus {
    border: 1px solid ${(props) => props.theme.colors.buttonBorder};
    ::placeholder {
      color: ${(props) => props.theme.colors.buttonBorder};
    }
  }
`;

export default GiglinkedTextInput;
