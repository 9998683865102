import { observer } from 'mobx-react';
import * as React from 'react';
import { useUiStore } from '../../stores/ui';

const PrivacyPage = observer(() => {
  const uiStore = useUiStore();
  return (
    <div
      dangerouslySetInnerHTML={{ __html: html }}
      style={{ padding: 20, paddingTop: uiStore.isMobile ? 130 : 20 }}
    />
  );
});

export default PrivacyPage;

const html = `<html>

<head>
<meta http-equiv=Content-Type content="text/html; charset=utf-8">
<meta name=Generator content="Microsoft Word 15 (filtered)">
<style>
<!--
 /* Font Definitions */
 @font-face
	{font-family:Wingdings;
	panose-1:5 0 0 0 0 0 0 0 0 0;}
@font-face
	{font-family:"Cambria Math";
	panose-1:2 4 5 3 5 4 6 3 2 4;}
@font-face
	{font-family:Calibri;
	panose-1:2 15 5 2 2 2 4 3 2 4;}
@font-face
	{font-family:"Calibri Light";
	panose-1:2 15 3 2 2 2 4 3 2 4;}
 /* Style Definitions */
 p.MsoNormal, li.MsoNormal, div.MsoNormal
	{margin-top:0in;
	margin-right:0in;
	margin-bottom:12.0pt;
	margin-left:0in;
	text-align:justify;
	font-size:11.0pt;
	font-family:"Calibri",sans-serif;}
p.MsoBodyText, li.MsoBodyText, div.MsoBodyText
	{mso-style-link:"Body Text Char";
	margin-top:0in;
	margin-right:0in;
	margin-bottom:12.0pt;
	margin-left:0in;
	text-align:justify;
	font-size:11.0pt;
	font-family:"Calibri",sans-serif;}
a:link, span.MsoHyperlink
	{color:#0563C1;
	text-decoration:underline;}
span.BodyTextChar
	{mso-style-name:"Body Text Char";
	mso-style-link:"Body Text";}
p.Bullet1, li.Bullet1, div.Bullet1
	{mso-style-name:"Bullet 1";
	mso-style-link:"Bullet 1 Char";
	margin-top:0in;
	margin-right:0in;
	margin-bottom:12.0pt;
	margin-left:.5in;
	text-align:justify;
	text-indent:-.25in;
	font-size:11.0pt;
	font-family:"Calibri",sans-serif;}
span.Bullet1Char
	{mso-style-name:"Bullet 1 Char";
	mso-style-link:"Bullet 1";
	font-family:"Times New Roman",serif;}
p.Bullet2, li.Bullet2, div.Bullet2
	{mso-style-name:"Bullet 2";
	margin-top:0in;
	margin-right:0in;
	margin-bottom:12.0pt;
	margin-left:1.0in;
	text-align:justify;
	text-indent:-.25in;
	font-size:11.0pt;
	font-family:"Calibri",sans-serif;}
p.Bullet3, li.Bullet3, div.Bullet3
	{mso-style-name:"Bullet 3";
	margin-top:0in;
	margin-right:0in;
	margin-bottom:12.0pt;
	margin-left:1.5in;
	text-align:justify;
	text-indent:-.25in;
	font-size:11.0pt;
	font-family:"Calibri",sans-serif;}
p.Bullet4, li.Bullet4, div.Bullet4
	{mso-style-name:"Bullet 4";
	margin-top:0in;
	margin-right:0in;
	margin-bottom:12.0pt;
	margin-left:2.0in;
	text-align:justify;
	text-indent:-.25in;
	font-size:11.0pt;
	font-family:"Calibri",sans-serif;}
p.Bullet5, li.Bullet5, div.Bullet5
	{mso-style-name:"Bullet 5";
	margin-top:0in;
	margin-right:0in;
	margin-bottom:12.0pt;
	margin-left:2.5in;
	text-align:justify;
	text-indent:-.25in;
	font-size:11.0pt;
	font-family:"Calibri",sans-serif;}
p.Bullet6, li.Bullet6, div.Bullet6
	{mso-style-name:"Bullet 6";
	margin-top:0in;
	margin-right:0in;
	margin-bottom:12.0pt;
	margin-left:3.0in;
	text-align:justify;
	text-indent:-.25in;
	font-size:11.0pt;
	font-family:"Calibri",sans-serif;}
p.Bullet7, li.Bullet7, div.Bullet7
	{mso-style-name:"Bullet 7";
	margin-top:0in;
	margin-right:0in;
	margin-bottom:12.0pt;
	margin-left:3.5in;
	text-align:justify;
	text-indent:-.25in;
	font-size:11.0pt;
	font-family:"Calibri",sans-serif;}
p.Bullet8, li.Bullet8, div.Bullet8
	{mso-style-name:"Bullet 8";
	margin-top:0in;
	margin-right:0in;
	margin-bottom:12.0pt;
	margin-left:4.0in;
	text-align:justify;
	text-indent:-.25in;
	font-size:11.0pt;
	font-family:"Calibri",sans-serif;}
p.Bullet9, li.Bullet9, div.Bullet9
	{mso-style-name:"Bullet 9";
	margin-top:0in;
	margin-right:0in;
	margin-bottom:12.0pt;
	margin-left:4.5in;
	text-align:justify;
	text-indent:-.25in;
	font-size:11.0pt;
	font-family:"Calibri",sans-serif;}
span.apple-style-span
	{mso-style-name:apple-style-span;}
.MsoChpDefault
	{font-size:12.0pt;
	font-family:"Calibri",sans-serif;}
 /* Page Definitions */
 @page WordSection1
	{size:8.5in 11.0in;
	margin:1.0in 1.0in 1.0in 1.0in;}
div.WordSection1
	{page:WordSection1;}
 /* List Definitions */
 ol
	{margin-bottom:0in;}
ul
	{margin-bottom:0in;}
-->
</style>

</head>

<body lang=EN-US link="#0563C1" vlink="#954F72" style='word-wrap:break-word'>

<div class=WordSection1>

<p class=MsoNormal align=center style='text-align:center'><span
class=apple-style-span><b><span lang=EN-CA style='font-size:10.0pt;font-family:
"Calibri Light",sans-serif;color:black'>GIGLINKED – PRIVACY POLICY</span></b></span></p>

<p class=MsoNormal align=left style='text-align:left'><span
class=apple-style-span><span lang=EN-CA style='font-size:10.0pt;font-family:
"Calibri Light",sans-serif'>Last updated: <span style='color:black'>December
6th, 2021.</span></span></span></p>

<p class=MsoBodyText><b><span lang=EN-CA style='font-size:10.0pt;font-family:
"Calibri Light",sans-serif'>GigLinked </span></b><span lang=EN-CA
style='font-size:10.0pt;font-family:"Calibri Light",sans-serif'>(“<b>GigLinked</b>”,
“<b>we</b>”, “<b>us</b>” or “<b>our</b>”)<b> </b></span><span lang=EN-CA
style='font-size:10.0pt;font-family:"Calibri Light",sans-serif'>takes steps
intended to meet privacy principles and requirements with respect to personal
information under applicable privacy legislation. This Privacy Policy (the <b>Policy</b>)
applies to services offered by GigLinked, through our website https://www.giglinked.live
through our mobile application and through our website optimized for mobile
services (the “Platform”). GigLinked cares about your privacy and is committed
to protecting it by adhering to the Policy.</span></p>

<p class=MsoBodyText><span lang=EN-CA style='font-size:10.0pt;font-family:"Calibri Light",sans-serif'>This
policy should be read in conjunction with our Terms and Conditions of Use. Your
access and use of GigLinked’s website constitutes your consent to the
collection, use and disclosure of your personal information in accordance with
this Policy.</span></p>

<p class=MsoBodyText><span lang=EN-CA style='font-size:10.0pt;font-family:"Calibri Light",sans-serif'>&nbsp;</span></p>

<p class=MsoBodyText style='page-break-after:avoid'><b><span lang=EN-CA
style='font-size:10.0pt;font-family:"Calibri Light",sans-serif'>Collection of
personal information</span></b></p>

<p class=MsoBodyText><span lang=EN-CA style='font-size:10.0pt;font-family:"Calibri Light",sans-serif'>In
order to use certain features of our Platform, we may request that you provide
certain personal information. We only collect personal information that is
necessary to provide you with our different services. Where possible, we
indicate which fields are required and which fields are optional. </span></p>

<p class=MsoBodyText style='margin-left:.5in;text-indent:-.25in'><span
lang=EN-CA style='font-size:10.0pt;font-family:Symbol'>·<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-CA style='font-size:10.0pt;font-family:"Calibri Light",sans-serif'>When
you <b>sign up on the GigLinked Platform to become a member</b>, you will be
prompted to choose to register as a <b>Musician</b> if you are offering
performances or as a <b>Client </b>if you are booking performances from
Musicians. All members must provide us with certain information such as your
family name, first name, email address and password, in order to establish your
membership file. If you are registering as a Musician, you can add further
information to your profile<b>, </b>including your availabilities, the
geographic areas you are willing to take gigs in and your picture. The scope of
the information collected on your profile depends on what you have entered. If
you are registering as a Client, you will be prompted to enter where you would
like to book the performance, which may be your home address. </span></p>

<p class=MsoBodyText style='margin-left:.5in;text-indent:-.25in'><span
lang=EN-CA style='font-size:10.0pt;font-family:Symbol'>·<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-CA style='font-size:10.0pt;font-family:"Calibri Light",sans-serif'>If
you <b>connect your profile to a streaming website or a social media site</b>,
like YouTube or Spotify, you may opt to give us access to certain personal
information kept by these websites (see our section on <i>Third party streaming
websites</i> <i>and social media sites </i>for more information). </span></p>

<p class=MsoBodyText style='margin-left:.5in;text-indent:-.25in'><span
lang=EN-CA style='font-size:10.0pt;font-family:Symbol'>·<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-CA style='font-size:10.0pt;font-family:"Calibri Light",sans-serif'>Members
will be asked to <b>provide certain financial information to book a performance
or be paid for a performance</b>. The information you will have to provide
includes your family name, first name, email, and address, as well as your
credit card number or banking information. All payments are processed through
Stripe, a third-party platform that securely processes transactions. </span></p>

<p class=MsoBodyText style='margin-left:.5in;text-indent:-.25in'><span
lang=EN-CA style='font-size:10.0pt;font-family:Symbol'>·<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-CA style='font-size:10.0pt;font-family:"Calibri Light",sans-serif'>If
you subscribe to our <b>mailing list, </b>we will collect your name and email
address to send you emails about various news and promotions. Even after
subscribing to a newsletter or promotional communication from us, you may elect
to modify their preferences by following the “Unsubscribe” link provided in an
email or communication received.&nbsp; </span></p>

<p class=MsoBodyText style='margin-left:.5in;text-indent:-.25in'><span
lang=EN-CA style='font-size:10.0pt;font-family:Symbol'>·<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-CA style='font-size:10.0pt;font-family:"Calibri Light",sans-serif'>When
you <b>visit our site</b>, we may collect information about your visit through
cookies and similar technologies (see our section on <i>Cookies and other
tracking technologies</i><b> </b>for more information).<b> </b></span></p>

<p class=MsoBodyText><span lang=EN-CA style='font-size:10.0pt;font-family:"Calibri Light",sans-serif'>We
may collect other information about you, including information you communicated
to us in discussion forums, chat rooms, during dispute settlements or in
correspondence in which you exchange information through our Platform. </span></p>

<p class=Bullet1 style='margin-left:0in;text-indent:0in'><span lang=EN-CA
style='font-size:10.0pt'>Unless otherwise permitted or required by applicable
laws or regulations, we will retain your personal information only as long as
necessary to fulfill the purposes for which it was collected, including for the
purpose of satisfying legal, accounting or auditing requirements. </span></p>

<p class=MsoBodyText style='page-break-after:avoid'><b><span lang=EN-CA
style='font-size:10.0pt;font-family:"Calibri Light",sans-serif'>Use of personal
information</span></b></p>

<p class=MsoBodyText style='page-break-after:avoid'><span lang=EN-CA
style='font-size:10.0pt;font-family:"Calibri Light",sans-serif'>We collect
information about you to be able to give you access to our Platform and thereby
allow you to benefit from a secure, effective and personalized experience. You agree
that GigLinked use such information to:</span></p>

<p class=MsoBodyText style='margin-left:.5in;text-indent:-.25in;page-break-after:
avoid'><span lang=EN-CA style='font-size:10.0pt;font-family:Symbol'>·<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-CA style='font-size:10.0pt;font-family:"Calibri Light",sans-serif'>grant
you access to our Platform and process payments associated with the booking of
performances; </span></p>

<p class=MsoBodyText style='margin-left:.5in;text-indent:-.25in;page-break-after:
avoid'><span lang=EN-CA style='font-size:10.0pt;font-family:Symbol'>·<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-CA style='font-size:10.0pt;font-family:"Calibri Light",sans-serif'>enforce
our&nbsp;Terms and Conditions of Use and </span><span lang=EN-CA
style='font-size:10.0pt;font-family:"Calibri Light",sans-serif'>meet legal,
regulatory, insurance and processing requirements, and otherwise with your
consent, or as permitted or required by law. </span></p>

<p class=MsoBodyText style='margin-left:.5in;text-indent:-.25in;page-break-after:
avoid'><span lang=EN-CA style='font-size:10.0pt;font-family:Symbol'>·<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-CA style='font-size:10.0pt;font-family:"Calibri Light",sans-serif'>contact
you to resolve problems affecting your account or our Platform or for other
reasons authorized by law;</span></p>

<p class=MsoBodyText style='margin-left:.5in;text-indent:-.25in;page-break-after:
avoid'><span lang=EN-CA style='font-size:10.0pt;font-family:Symbol'>·<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-CA style='font-size:10.0pt;font-family:"Calibri Light",sans-serif'>contact
you to inform you of our services, send you niche marketing products, give you
access to our updated services and promotional offers and personalize and
improve our services, content and advertisements;</span></p>

<p class=MsoBodyText style='margin-left:.5in;text-indent:-.25in;page-break-after:
avoid'><span lang=EN-CA style='font-size:10.0pt;font-family:Symbol'>·<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-CA style='font-size:10.0pt;font-family:"Calibri Light",sans-serif'>prevent,
detect and, eventually, investigate fraud and security breaches, activities
that may prove to be prohibited or illegal;</span></p>

<p class=MsoBodyText style='page-break-after:avoid'><b><span lang=EN-CA
style='font-size:10.0pt;font-family:"Calibri Light",sans-serif'>Disclosure</span></b><span
lang=EN-CA style='font-size:10.0pt;font-family:"Calibri Light",sans-serif'> <b>of
personal information</b></span></p>

<p class=Bullet1 style='margin-left:0in;text-indent:0in'><span lang=EN-CA
style='font-size:10.0pt;font-family:"Calibri Light",sans-serif'>GigLinked will
only disclose your personal information with your consent or if it has legal
authority to do so, such as in the following contexts:</span></p>

<p class=Bullet1><span lang=EN-CA style='font-size:10.0pt;font-family:Symbol'>·<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-CA style='font-size:10.0pt;font-family:"Calibri Light",sans-serif'>In
connection with the <b>booking of performances</b>, we share your profile with
members of GigLinked. If you are a Musician, your profile will be made public
to everyone. If you are a Client, your profile will be made available to the
Musician to whom you are submitting a booking request. Note that banking and
credit card information is not shared with other members, as payments are
processed directly through GigLinked and its third-party service provider. </span></p>

<p class=Bullet1><span lang=EN-CA style='font-size:10.0pt;font-family:Symbol'>·<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-CA style='font-size:10.0pt;font-family:"Calibri Light",sans-serif'>We
may use and disclose personal information in the context of a <b>business
transaction</b>, in connection with the proposed or actual financing,
securitization, insuring, sale, assignment, or other disposal of all or part of
GigLinked, or our business or assets, for the purposes of evaluating and/or
performing the proposed transaction. Assignees or successors of GigLinked, or
our business or assets, may use and disclose your personal information for
similar purposes as those described in this Policy.</span></p>

<p class=Bullet1><span lang=EN-CA style='font-size:10.0pt;font-family:Symbol'>·<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-CA style='font-size:10.0pt;font-family:"Calibri Light",sans-serif'>We
may be asked to disclose your personal information to <b>meet certain legal,
regulatory, insurance, and audit requirements</b>, enforce our Terms and
Conditions of Use, respond to claims to the effect that some content breaches
the law or the rights of a third person, including intellectual property rights
or ensure everyone’s safety and security, and as otherwise with your consent,
or as permitted or required by law (including as required by applicable
Canadian and foreign laws applicable to us or our service providers). </span></p>

<p class=Bullet1><span lang=EN-CA style='font-size:10.0pt;font-family:Symbol'>·<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-CA style='font-size:10.0pt;font-family:"Calibri Light",sans-serif'>We
may share our data, including personal information about you, with <b>third-party
service providers</b> that perform services on our behalf such as data hosting
and processing payments. To the extent that these entities may have access to
your information, they will treat it at least as protectively as they treat
information they obtain from their other users. These third-party service
providers shall follow privacy practices no less protective of all users than
our practices described in this document, to the extent allowed by applicable
law. </span></p>

<p class=Bullet1 style='margin-left:0in;text-indent:0in'><span lang=EN-CA
style='font-size:10.0pt;font-family:"Calibri Light",sans-serif'>We do not sell,
rent or lease your personal information to third persons for marketing purposes
without your explicit consent. </span></p>

<p class=MsoBodyText style='page-break-after:avoid'><b><span lang=EN-CA
style='font-size:10.0pt;font-family:"Calibri Light",sans-serif'>Ensuring the
security of your personal information</span></b></p>

<p class=Bullet1 style='margin-left:0in;text-indent:0in'><span lang=EN-CA
style='font-size:10.0pt;font-family:"Calibri Light",sans-serif'>We take steps
to protect the personal information in our custody or control using physical,
electronic, or procedural security measures that are appropriate having regard
to the nature and sensitivity of the information. This includes firewalls,
information filtering and encryption. We take reasonable measures to limit
access to this data to authorized employees and contractors who need to know
that information in order to perform, develop or improve our services.
Furthermore, the information we collected remains stored in servers located in
Canada. </span></p>

<p class=Bullet1 style='margin-left:0in;text-indent:0in'><span lang=EN-CA
style='font-size:10.0pt;font-family:"Calibri Light",sans-serif'>Please note
that the safety and security of your information is also your responsibility.
Your password is an essential tool to ensure the protection of your account. We
advise you to create a strong password with letters, numbers and special
characters, and not give it to anyone. If you lose control of your password,
you could also lose the control of your personal information and will be liable
for legally binding actions taken in your name. If the security or use of your
password is compromised, for any reason whatsoever, you must notify GigLinked
immediately and create a new password. </span></p>

<p class=Bullet1 style='margin-left:0in;text-indent:0in'><span lang=EN-CA
style='font-size:10.0pt;font-family:"Calibri Light",sans-serif'>Despite all our
efforts to preserve the confidentiality of your personal information, third
persons may access information housed on our GigLinked’s servers or our
systems’ security may otherwise be breached. In such a case, we cannot be held
liable for any loss, corruption or unauthorized disclosure of information that
would be beyond our reasonable control.<b> </b></span></p>

<p class=MsoBodyText style='page-break-after:avoid'><b><span lang=EN-CA
style='font-size:10.0pt;font-family:"Calibri Light",sans-serif'>Third party
streaming websites and social medias sites</span></b></p>

<p class=MsoBodyText style='page-break-after:avoid'><span lang=EN-CA
style='font-size:10.0pt;font-family:"Calibri Light",sans-serif'>As a member,
you can opt to use a social media account, such as Facebook, Google Plus or
Instagram (the <b>Social Media Sites</b>) to sign up on our Platform. If you
choose to do so, GigLinked may have access to information the Social Media Site
has on you, depending on the privacy settings of your social media account.
This information may for example include your photo and list of “friends”.</span></p>

<p class=MsoBodyText style='page-break-after:avoid'><span lang=EN-CA
style='font-size:10.0pt;font-family:"Calibri Light",sans-serif'>As a Musician,
you can also opt to connect your GigLinked profile to various streaming
websites like YouTube and Spotify (the <b>Streaming Sites</b>). The personal
information to which we have access varies depending on the Streaming Site and
is defined by the privacy protection parameters which you chose for these sites
and by the nature of your consent. It might include your name, email address,
photos, names and photos of your “friends” and other personal information you
provided on the Social Media Sites. By creating a link between an account
managed by a third party like YouTube or Spotify and your GigLinked account and
by authorizing GigLinked to access such information, you authorize GigLinked to
collect, use and protect and safeguard the information coming from these
Streaming Sites in compliance with the provisions of this Policy.</span></p>

<p class=MsoBodyText style='page-break-after:avoid'><b><span lang=EN-CA
style='font-size:10.0pt;font-family:"Calibri Light",sans-serif'>Cookies and
other tracking technologies </span></b></p>

<p class=Bullet1 style='margin-left:0in;text-indent:0in'><span lang=EN-CA
style='font-size:10.0pt;font-family:"Calibri Light",sans-serif'>When you browse
our site or use our services, we automatically collect information that is sent
to us via your computer, your mobile device or, more generally, whatever device
you use. The information thus collected may, in particular, include your IP
address, your geolocation data, as well as anonymous information collected as a
result of our cookies and our web trackers such as the date and time of your
visit, the pages you visit, the features you use, the referring URL you came
from and which one you go to after, clickstream behaviour (i.e. the pages you
view, the links you click, and other actions you take in connection with our
Sites), your browser type and selected language. </span></p>

<p class=Bullet1 style='margin-left:0in;text-indent:0in'><span lang=EN-CA
style='font-size:10.0pt;font-family:"Calibri Light",sans-serif'>Cookies are
small text files that are stored on your computer’s hard drive and that help us
analyze traffic on our Sites’ pages, personalize our services, the content on
our site and their advertising and gauge the effectiveness of our promotional
campaigns. Our service providers may also download cookies on to your computer
to post advertising messages and, on our behalf or on behalf of our advertising
partners, gauge the effectiveness of our advertising messages and compile
anonymous data on their use by visitors to our Sites. The cookies placed by our
server are readable only by us, and cookies cannot access, read or modify any
other data on a computer.</span></p>

<p class=Bullet1 style='margin-left:0in;text-indent:0in'><span lang=EN-CA
style='font-size:10.0pt;font-family:"Calibri Light",sans-serif'>Many web
browsers allow you to manage your preferences. You can set your browser to
refuse cookies or delete certain cookies. You may be able to manage other
technologies in the same way that you manage cookies using your browser’s
preference. However, in such a case, you might have problems browsing our site
or using some of their functions.</span></p>

<p class=Bullet1 style='margin-left:0in;text-indent:0in'><span lang=EN-CA
style='font-size:10.0pt;font-family:"Calibri Light",sans-serif'>Finally, when
you access our mobile-optimized site, we might receive data on your location
and on your mobile device, including a unique identifier of your device. We can
then use this information to provide you with services, such as advertisement,
research results and other personalized content, taking your location into
account. You can use the adjustment menus on most mobile devices to activate or
deactivate the location services at your convenience.</span></p>

<p class=MsoBodyText style='page-break-after:avoid'><b><span lang=EN-CA
style='font-size:10.0pt;font-family:"Calibri Light",sans-serif'>Links to other
sites</span></b></p>

<p class=MsoNormal><span lang=EN-CA style='font-size:10.0pt;font-family:"Calibri Light",sans-serif'>We
may offer links to other websites controlled by other entities. We are not
responsible for, nor do we accept any liability for how such other entities
collect, use, and disclose personal information. You should consult the privacy
policies of these other websites before providing any personal information.</span></p>

<p class=MsoBodyText style='page-break-after:avoid'><b><span lang=EN-CA
style='font-size:10.0pt;font-family:"Calibri Light",sans-serif'>Accessing,
correcting, and deleting your information</span></b></p>

<p class=MsoNormal><span lang=EN-CA style='font-size:10.0pt;font-family:"Calibri Light",sans-serif'>It
is important that the personal information we hold about you is accurate and
current. Please keep us informed if your personal information changes. You may
request access to and correct the personal information that we hold about you.
If you want to review, verify, correct, or withdraw consent to the use of your
personal information you may also send us an email at<b> </b></span><span
lang=EN-CA><a href="mailto:info@giglinked.live"><b><span style='font-size:10.0pt;
font-family:"Calibri Light",sans-serif'>info@giglinked.live</span></b></a></span><b><span
lang=EN-CA style='font-size:10.0pt;font-family:"Calibri Light",sans-serif'> </span></b><span
lang=EN-CA style='font-size:10.0pt;font-family:"Calibri Light",sans-serif'>to
request access to, correct, or delete any personal information that you have
provided to us. </span></p>

<p class=MsoNormal><span lang=EN-CA style='font-size:10.0pt;font-family:"Calibri Light",sans-serif'>We
may request specific information from you to help us confirm your identity and
your right to access, and to provide you with the personal information that we
hold about you or make your requested changes. Applicable law may allow or
require us to refuse to provide you with access to some or all of the personal
information that we hold about you, or we may have destroyed, erased, or made
your personal information anonymous in accordance with our record retention
obligations and practices. If we cannot provide you with access to your
personal information, we will inform you of the reasons why, subject to any
legal or regulatory restrictions. We will provide access to your personal
information, subject to exceptions set out in applicable privacy legislation.</span></p>

<p class=MsoNormal style='margin-bottom:0in;text-autospace:none'><span
lang=EN-CA style='font-size:10.0pt;font-family:"Arial",sans-serif;color:black;
background:white'>Where you have provided your consent to the collection, use,
and transfer of your personal information, you may have the legal right to
withdraw your consent under certain circumstances. To withdraw your consent, if
applicable, contact us at info@giglinked.live. Please note that if you withdraw
your consent, we may not be able to provide you with a particular product or
service. We will explain the impact to you at the time to help you with your
decision.</span></p>

<p class=MsoNormal style='margin-bottom:0in;text-autospace:none'><b><u><span
lang=EN-CA style='font-size:10.0pt;font-family:"Arial",sans-serif;background:
white'><span style='text-decoration:none'>&nbsp;</span></span></u></b></p>

<p class=MsoBodyText style='page-break-after:avoid'><b><span lang=EN-CA
style='font-size:10.0pt;font-family:"Calibri Light",sans-serif'>Changes to this
Privacy Policy</span></b></p>

<p class=Bullet1 style='margin-left:0in;text-indent:0in'><span lang=EN-CA
style='font-size:10.0pt;font-family:"Calibri Light",sans-serif'>This Policy
effective as of the last updated date above and may be revised from time to
time. If we intend to use or disclose personal information for purposes
materially different than those described in this Policy, we will make
reasonable efforts to notify you of this, including by posting the revised
Policy on our site. Your continued provision of personal information or use of
our Platform following any changes to this Policy constitutes your acceptance
of any such changes. </span></p>

<p class=MsoBodyText style='page-break-after:avoid'><b><span lang=EN-CA
style='font-size:10.0pt;font-family:"Calibri Light",sans-serif'>Contact us</span></b></p>

<p class=Bullet1 style='margin-left:0in;text-indent:0in'><span lang=EN-CA
style='font-size:10.0pt;font-family:"Calibri Light",sans-serif'>If you have any
questions or concerns about the Policy, or if you wish to make a complaint,
please contact us at info@giglinked.live.</span></p>

<p class=MsoNormal><span lang=EN-CA>&nbsp;</span></p>

</div>

</body>

</html>
`;
