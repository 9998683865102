import * as React from 'react';
import { observer } from 'mobx-react';
import styled from 'styled-components';
import colors from '../../constants/colors';
import { useUiStore } from '../../stores/ui';
import BookingPayment from './BookingPayment';
import { Transaction } from '../../shared';
import BookingProgressBar from '../booking/BookingProgressBar';
import BookingUserInfo from './BookingUserInfo';
import { UseUserStore } from '../../stores/user';
import { COUNTRY, PROVINCE } from '../../shared';
import BookingRecap from './BookingRecap';

export interface EditDataTransactionPayload {
  firstName: string;
  lastName: string;
  phoneNumber: string;
  locationAddress: string;
  city: string;
  postalCode: string;
  province: { value: string; label: string };
  country: { value: string; label: string };
  stripeCardToken: string;
}

const CompleteTransactionController = observer(
  ({
    transaction,
    onComplete,
  }: {
    transaction: Transaction;
    onComplete: () => void;
  }) => {
    const user = UseUserStore();

    const [transactionData, setTransactionData] = React.useState({
      firstName: user.me?.firstName ?? '',
      lastName: user.me?.lastName ?? '',
      phoneNumber: '',
      locationAddress: '',
      city: '',
      postalCode: '',
      province: PROVINCE[0],
      country: COUNTRY[0],
      stripeCardToken: '',
    });

    const editData = (payload: Partial<EditDataTransactionPayload>) => {
      setTransactionData({ ...transactionData, ...payload });
    };

    const [index, setIndex] = React.useState(0);
    const uiStore = useUiStore();
    const width = uiStore.isMobile
      ? uiStore.innerWidth
      : uiStore.innerWidth * 0.61;

    const onPressNext = () => {
      setIndex(index + 1);
    };

    const onPressBack = () => {
      if (index >= 1) {
        setIndex(index - 1);
      }
    };

    const renderContent = () => {
      return [
        <BookingUserInfo
          key="BookingUserInfo"
          onPressNext={onPressNext}
          editData={editData}
          data={transactionData}
        />,
        <BookingPayment
          key="BookingPayment"
          onPressNext={onPressNext}
          editData={editData}
        />,
        <BookingRecap
          key="BookingRecap"
          transaction={transaction}
          data={transactionData}
          onComplete={onComplete}
        />,
      ][index];
    };

    return (
      <Main width={width} height={uiStore.innerHeight}>
        <BookingProgressBar
          currentIndex={index}
          numberOfScreens={2}
          onPressBack={index === 0 ? undefined : onPressBack}
        />
        <ContentContainer>{renderContent()}</ContentContainer>
      </Main>
    );
  },
);

const Main = styled.div<{ width: number; height: number }>`
  display: flex;
  flex-direction: column;
  width: ${(props) => props.width}px;
  align-items: center;
  border-radius: 5px;
  background-color: ${colors.lightPurple};
  @media (max-width: ${(props) => props.theme.breakPoints.mobile}px) {
    min-height: ${(props) => props.height}px;
  }
`;

const ContentContainer = styled.div`
  width: 100%;
  max-width: 600px;
  box-sizing: border-box;

  @media (max-width: ${(props) => props.theme.breakPoints.mobile}px) {
    padding-bottom: 20px;
  }
`;

export default CompleteTransactionController;
