import * as React from 'react';
import styled from 'styled-components';
import { Conversation } from '../../shared';
import { Box } from 'react-native-kondo';
import {
  GeneralRegularNormal,
  GeneralRegularSmall,
  GeneralSemiBoldNormal,
} from '../common/Typography';
import images from '../../constants/images';
import { shorten } from '../../utils/string';
import {
  formatDateForConversationCell,
  getOtherUserFromConversation,
} from './messageUtils';
import { observer } from 'mobx-react';
import { useMessagingStore } from '../../stores/messaging';
import ATag from '../common/ATag';
import { replaceProfileImage } from '../../utils/media';

const ConversationTile = observer(
  ({
    style = {},
    conversation,
    index,
  }: {
    style?: React.CSSProperties;
    conversation: Conversation;
    index: number;
  }) => {
    const messageStore = useMessagingStore();
    const otherUser = getOtherUserFromConversation({ conversation });
    const selectedConversation = messageStore.getCurrentConversation();

    const onClick = () => {
      messageStore.setCurrentConversation(conversation);
    };

    const otherUserProfilePicture = otherUser.artist ? (
      <ATag href={`/artists/${otherUser.artist.id}`}>
        <Image src={replaceProfileImage(otherUser.profilePicture)} />
      </ATag>
    ) : (
      <Image src={replaceProfileImage(otherUser.profilePicture)} />
    );

    return (
      <Container
        style={{
          ...style,
        }}
        onClick={onClick}
        first={index === 0}
        backgroundColor={
          selectedConversation?.id === conversation.id
            ? 'rgba(67, 97, 238, 0.1)'
            : 'white'
        }
      >
        <Box flexDirection="row">
          {otherUserProfilePicture}

          <Box flexDirection="column" ml={20} alignSelf="flex-start">
            <GeneralSemiBoldNormal style={{ color: 'black' }}>
              {otherUser.firstName} {otherUser.lastName}
            </GeneralSemiBoldNormal>
            <GeneralRegularNormal
              style={{
                color: 'rgba(0, 0, 0, 0.75)',
              }}
            >
              {shorten(conversation.messages[0].text, 29)}
            </GeneralRegularNormal>
          </Box>
        </Box>
        <Box flexDirection="row" alignSelf="flex-start" alignItems="center">
          <GeneralRegularSmall style={{ marginTop: 2 }}>
            {formatDateForConversationCell(conversation.messages[0].createdAt)}
          </GeneralRegularSmall>
          <Chevron src={images.chevron} />
        </Box>
      </Container>
    );
  },
);

const Container = styled.div<{ first: boolean; backgroundColor: string }>`
  width: 403px;
  height: 84px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 18px 40px 20px 40px;
  border: 0px solid rgba(0, 0, 0, 0.25);
  border-bottom-width: 1px;
  background-color: ${(props) => props.backgroundColor};
  border-top-width: ${(props) => (props.first ? 1 : 0)}px;
  cursor: pointer;
  &:hover {
    background-color: rgba(67, 97, 238, 0.1);
  }
  @media (max-width: ${(props) => props.theme.breakPoints.mobile}px) {
    width: ${window.innerWidth}px;
  }
`;

const Chevron = styled.img`
  height: 8px;
  width: 5px;
  object-fit: contain;
  margin-left: 8px;
`;

const Image = styled.img`
  width: 40px;
  height: 40px;
  border-radius: 40px;
  border: 2px solid #e0e0e0;
  box-sizing: border-box;
`;

export default ConversationTile;
