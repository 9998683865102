import { observer } from 'mobx-react-lite';
import * as React from 'react';
import styled from 'styled-components';
import colors from '../../constants/colors';
import i18n from '../../constants/i18n';
import { TransactionStatus } from '../../shared';
import { useUiStore } from '../../stores/ui';
import { TextH3 } from '../common/Typography';

const RequestStatusBarSelector = observer(
  ({
    status,
    onChangeStatus,
  }: {
    status: TransactionStatus;
    onChangeStatus: (newStatus: TransactionStatus) => void;
  }) => {
    const uiStore = useUiStore();
    const [translateX, setTranslateX] = React.useState(0);
    const ref1 = React.useRef<any>();
    const ref2 = React.useRef<any>();
    const ref3 = React.useRef<any>();

    React.useEffect(() => {
      if (status === 'quotePending' || status === 'quoteSent') {
        setTranslateX(0);
      } else if (status === 'accepted') {
        setTranslateX(ref2?.current?.offsetLeft - 10);
      } else {
        setTranslateX(ref3?.current?.offsetLeft - 10);
      }
    }, [status, uiStore.innerWidth]);

    return (
      <Main>
        <SelectorBox translationX={translateX} />

        <TextH3
          onClick={() => onChangeStatus('quotePending')}
          ref={ref1}
          style={{
            color: status === 'quotePending' ? colors.white : colors.black,
            textAlign: 'center',
            cursor: 'pointer',
            width: uiStore.isMobile ? 100 : 150,
            zIndex: 2,
          }}
        >
          {i18n('RequestStatusBarSelector.pending', 'Pending')}
        </TextH3>

        <TextH3
          onClick={() => onChangeStatus('accepted')}
          ref={ref2}
          style={{
            color: status === 'accepted' ? colors.white : colors.black,
            textAlign: 'center',
            width: uiStore.isMobile ? 100 : 150,
            cursor: 'pointer',
            zIndex: 2,
            transition: 'color 0.2s',
          }}
        >
          {i18n('RequestStatusBarSelector.accepted', 'Accepted')}
        </TextH3>
        <TextH3
          onClick={() => onChangeStatus('declined')}
          ref={ref3}
          style={{
            color: status === 'declined' ? colors.white : colors.black,
            textAlign: 'center',
            width: uiStore.isMobile ? 100 : 150,
            cursor: 'pointer',
            zIndex: 2,
          }}
        >
          {i18n('RequestStatusBarSelector.past', 'Past')}
        </TextH3>
      </Main>
    );
  },
);

const Main = styled.div`
  display: flex;
  border: 1px solid ${colors.borderColor};
  justify-content: space-between;
  padding: 10px;
  border-radius: 10px;
  position: relative;
  box-sizing: border-box;
  margin-bottom: 34px;
  background-color: white;
  box-shadow: 4px 4px 10px rgba(131, 123, 119, 0.25);
`;

const SelectorBox = styled.div<{ translationX: any }>`
  display: flex;
  position: absolute;
  left: 10px;
  top: 0px;
  bottom: 0px;
  background-color: ${colors.lightPurple};
  width: 150px;
  border-radius: 10px;
  transition: transform 0.3s;
  transform: ${(props) => `translateX(${props.translationX}px)`};
  @media (max-width: ${(props) => props.theme.breakPoints.mobile}px) {
    width: 100px;
  }
`;

export default RequestStatusBarSelector;
