import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { toast } from 'react-hot-toast';
import { Box } from 'react-native-kondo';
import styled from 'styled-components';
import i18n from '../../constants/i18n';
import { PROVINCES_TERRITORIES, PaymentMethod, User } from '../../shared';
import { useUiStore } from '../../stores/ui';
import { UseUserStore } from '../../stores/user';
import sexpressClient from '../../utils/sexpress';
import CenteredClipLoader from '../common/CenteredClipLoader';
import GiButton from '../common/GiButton';
import PageLayoutFooter from '../common/PageLayoutFooter';
import {
  GeneralRegularSmall,
  InputLabel,
  TextH1,
  TextH6,
} from '../common/Typography';
import SettingProfilePicture from './SettingProfilePicture';
import GiglinkedButton from '../common/GiglinkedButton';
import GiglinkedTextInput from '../common/GiglinkedTextInput';
import GiEventsDropDown from '../common/GiEventsDropDown';
import GiglinkedLocationAutocomplete from '../common/GiglinkedLocationAutocomplete';
import AddBillingMethodModal from './AddBillingMethodModal';
import { Column, Row } from '../common/Layout';
import images from '../../constants/images';
import AddBankingInformationModal from './AddBankingInformationModal';
import RemoveMethodModal from './RemoveMethodModal';
import StripeSecurePayments from './StripeSecurePayments';

const SettingsScreen = observer(() => {
  const user = UseUserStore();
  const me = user.getMe();
  const uiStore = useUiStore();

  const [loading, setLoading] = React.useState(false);
  const [firstName, setFirstName] = React.useState(me?.firstName);
  const [lastName, setLastName] = React.useState(me?.lastName);
  const [contactAddress, setContactAddress] = React.useState(
    me?.contactAddress,
  );
  const [location, setLocation] = React.useState(me?.location);
  const [province, setProvince] = React.useState(me?.province ?? 'QC');
  const [postalCode, setPostalCode] = React.useState(me?.postalCode);
  const [paymentInfo, setPaymentInfo] = React.useState<PaymentMethod | null>(
    null,
  );

  if (!me) {
    return <CenteredClipLoader />;
  }

  const isArtist = me?.artist;
  const hasPaymentInfo = paymentInfo?.last4;
  const paymentEndsMessage = isArtist
    ? i18n(
        'SettingsScreen.bankAccountEnds',
        'Direct to local bank - Account ending in ... ',
      ) + paymentInfo?.last4
    : i18n('SettingsScreen.creditCardEnds', 'Credit card ends in ... ') +
      paymentInfo?.last4;

  const retrieveStripeAccountDetails = async () => {
    const response = await sexpressClient.retrieveStripeAccountDetails({
      userId: me!.id,
    });
    if (response?.brand && response?.last4) setPaymentInfo(response);
  };

  const checkIfStripeAccountCreationIsValid = async () => {
    const response = await sexpressClient.checkIfStripeAccountCreationIsValid();
    if (response?.last4) {
      setPaymentInfo(response);
    }
    if (response?.error) {
      toast.error(response.error.prodMessage);
    }
  };

  React.useEffect(() => {
    if (isArtist) {
      checkIfStripeAccountCreationIsValid();
    } else retrieveStripeAccountDetails();
  }, []);

  const onSave = async (payload: Partial<User>) => {
    try {
      if (typeof payload.firstName === 'string' && !payload.firstName.length) {
        throw new Error(
          i18n(
            'SettingsScreen.errorFirstName',
            'Your first name cannot be blank.',
          ),
        );
      }

      if (typeof payload.lastName === 'string' && !payload.lastName.length) {
        throw new Error(
          i18n(
            'SettingsScreen.errorFirstName',
            'Your last name cannot be blank.',
          ),
        );
      }

      await user.editMe(payload);
      toast.success(i18n('SettingsScreen.saved', 'Saved'));
    } catch (e) {
      const err = e as Error;
      toast.error(err.message);
    }
  };

  const onClickSendEmail = async () => {
    try {
      setLoading(true);
      await sexpressClient.sendForgotPasswordEmail({ email: me.email });
      toast.success(i18n('SettingsScreen.emailSent!', 'Email sent!'));
    } catch (e) {
      const err = e as Error;
      toast.error(err.message);
    } finally {
      setLoading(false);
    }
  };

  const PaymentMethodRow = ({
    iconSrc,
    altText,
    message,
    onRemove,
  }: {
    iconSrc: string;
    altText: string;
    message: string;
    onRemove: () => void;
  }) => (
    <Row>
      <Icon src={iconSrc} alt={altText} />
      <TextH6 style={{ fontWeight: 400, margin: '1rem' }}>{message}</TextH6>
      {!isArtist && <DeleteIcon src={images.closeBlue} onClick={onRemove} />}
    </Row>
  );

  const AddMethodButton = ({
    label,
    onClick,
  }: {
    label: string;
    onClick: () => void;
  }) => (
    <GiglinkedButton
      label={label}
      style={{ width: 250, marginBottom: '1rem' }}
      onClick={onClick}
    />
  );

  return (
    <Main>
      <TextH1 style={{ alignSelf: 'center', marginBottom: 36 }}>
        {i18n('SettingsScreen.title', 'Settings')}
      </TextH1>

      <Content>
        <Box flexDirection={uiStore.isMobile ? 'column' : 'row'}>
          <SettingProfilePicture />
          <Box flex={1} ml={uiStore.isMobile ? 0 : 25}>
            <TextH6>{i18n('SettingsScreen.firstName', 'First name')}</TextH6>
            <GiglinkedTextInput
              type="text"
              value={firstName}
              onChangeText={setFirstName}
              style={{ marginBottom: 12 }}
            />
            <TextH6>{i18n('SettingsScreen.lastName', 'Last name')}</TextH6>
            <GiglinkedTextInput
              type="text"
              value={lastName}
              onChangeText={setLastName}
            />
          </Box>
        </Box>

        <TextH6>{i18n('SettingsScreen.email', 'EMAIL')}</TextH6>
        <GiglinkedTextInput
          value={me?.email}
          style={{ marginBottom: 12 }}
          disabled
        />

        <TextH6 style={{ marginBottom: 12 }}>
          {i18n('SettingsScreen.password', 'PASSWORD')}
        </TextH6>
        <GiButton
          label={i18n('SettingsScreen.resetPassword', 'Reset password')}
          style={{
            width: 250,
            marginBottom: '2rem',
          }}
          onClick={onClickSendEmail}
          loading={loading}
        />
        <TextH6 style={{ marginBottom: 12 }}>
          {i18n('SettingsScreen.billingPayments', 'BILLING & PAYMENTS')}
        </TextH6>

        {hasPaymentInfo ? (
          <>
            <PaymentMethodRow
              iconSrc={
                isArtist ? images.bankPaymentIcon : images.visaPaymentIcon
              }
              altText="payment icon"
              message={paymentEndsMessage}
              onRemove={() => {
                uiStore.openPopup({
                  popupDisableTapOut: true,
                  renderContent: () => (
                    <RemoveMethodModal setPaymentInfo={setPaymentInfo} />
                  ),
                });
              }}
            />
            {isArtist && (
              <GeneralRegularSmall style={{ marginBottom: '1rem' }}>
                {i18n(
                  'SettingsScreen.artistRemoveBankingData',
                  'Contact us if you would like to remove your banking payment data.',
                )}
              </GeneralRegularSmall>
            )}
          </>
        ) : (
          <AddMethodButton
            label={
              isArtist
                ? i18n('SettingsScreen.addBanking', 'Add banking information')
                : i18n('SettingsScreen.addPayment', 'Add a payment method')
            }
            onClick={() => {
              uiStore.openPopup({
                popupDisableTapOut: true,
                renderContent: () =>
                  isArtist ? (
                    <AddBankingInformationModal />
                  ) : (
                    <AddBillingMethodModal setPaymentInfo={setPaymentInfo} />
                  ),
              });
            }}
          />
        )}

        <StripeSecurePayments
          style={{ placeSelf: 'flex-start', marginBottom: '2rem' }}
        />

        <TextH6 style={{ marginBottom: 12 }}>
          {i18n('SettingsScreen.address', 'ADDRESS')}
        </TextH6>
        <Row>
          <Column style={{ width: '50%', marginRight: '1rem' }}>
            <InputLabel htmlFor="contactAddress">
              {i18n(
                'RegistrationBookerLocation.contactAddress',
                'Contact address',
              )}
            </InputLabel>
            <GiglinkedTextInput
              id="contactAddress"
              value={contactAddress}
              onChange={(e: any) => setContactAddress(e.target.value)}
              style={{ marginBottom: '1rem' }}
            />
          </Column>
          <Column style={{ width: '50%', marginLeft: '1rem' }}>
            <InputLabel htmlFor="location">
              {i18n('RegistrationBookerLocation.city', 'City')}
            </InputLabel>
            <GiglinkedLocationAutocomplete
              type="text"
              id="location"
              location={location!}
              setLocation={(ev) => setLocation(ev)}
              style={{ zIndex: 9 }}
            />
          </Column>
        </Row>
        <Row>
          <Column style={{ width: '50%', marginRight: '1rem' }}>
            <InputLabel htmlFor="province">
              {i18n('RegistrationBookerLocation.province', 'Province')}
            </InputLabel>
            <GiEventsDropDown
              items={PROVINCES_TERRITORIES()}
              value={province}
              onChange={(ev) => setProvince(ev.value)}
              style={{
                width: '100%',
                marginBottom: '1rem',
              }}
              active
            />
          </Column>
          <Column style={{ width: '50%', marginLeft: '1rem' }}>
            <InputLabel htmlFor="postalCode">
              {i18n('RegistrationBookerLocation.postalCode', 'Postal code')}
            </InputLabel>
            <GiglinkedTextInput
              id="postalCode"
              placeholder="A1B 2C3"
              mask="a9a 9a9"
              value={postalCode}
              onChangeText={(ev) => setPostalCode(ev)}
              style={{ marginBottom: '1rem' }}
            />
          </Column>
        </Row>
        <GiglinkedButton
          label={i18n('SettingsScreen.saveChanges', 'Save changes')}
          style={{
            width: 250,
          }}
          onClick={() => {
            onSave({
              firstName,
              lastName,
              contactAddress,
              location,
              province,
              postalCode,
            });
          }}
        />
      </Content>

      <PageLayoutFooter />
    </Main>
  );
});

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-self: center;
  width: 100%;
  min-height: 50vh;
  max-width: 1000px;
  padding: 24px;
`;

const Main = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  box-sizing: border-box;
  padding-top: 64px;
  flex: 1;

  @media (max-width: ${(props) => props.theme.breakPoints.mobile}px) {
    padding-top: 24px;
  }
`;

const Icon = styled.img`
  width: 30px;
`;

const DeleteIcon = styled.img`
  cursor: pointer;
  margin-left: 8px;
`;

export default SettingsScreen;
