/* eslint-disable react/prop-types */
import * as React from 'react';
import styled from 'styled-components';

import { GeneralSemiBoldNormal, GeneralMediumLarge } from './Typography';
import colors from '../../constants/colors';
import Dropdown from 'react-bootstrap/Dropdown';
import images from '../../constants/images';
import ATag from './ATag';

// The forwardRef is important!!
// Dropdown needs access to the DOM node in order to position the Menu
// @ts-ignore
const CustomToggle = React.forwardRef<any>(
  // @ts-ignore
  ({ children, onClick, disabled, hideDownArrow }, ref) => (
    <ToggleContainer
      // bootstrap stuff
      // @ts-ignore
      ref={ref}
      disabled={disabled}
      onClick={(e) => {
        if (!disabled) {
          e.preventDefault();
          onClick(e);
        }
      }}
    >
      <GeneralSemiBoldNormal
        style={{
          color: colors.whiteText,
          marginRight: hideDownArrow ? 0 : 8,
        }}
      >
        {children}
      </GeneralSemiBoldNormal>
      {hideDownArrow ? null : <DownArrow src={images.downBlueTriangle} />}
    </ToggleContainer>
  ),
);

// The forwardRef is important!!
// Dropdown needs access to the DOM node in order to position the Menu

// @ts-ignore
const CustomDropDownItem = React.forwardRef(({ label, href, onClick }, ref) => {
  const text = (
    <GeneralMediumLarge
      style={{
        color: colors.black,
        fontSize: 16,
      }}
    >
      {label}
    </GeneralMediumLarge>
  );

  return (
    <DropDownItemContainer
      // bootstrap stuff
      // @ts-ignore
      ref={ref}
    >
      {href ? (
        <ATag href={href} noOpacity={true}>
          {text}
        </ATag>
      ) : (
        <FullSizeDiv onClick={onClick}>{text}</FullSizeDiv>
      )}
    </DropDownItemContainer>
  );
});

interface BaseLinksDropDownOption {
  label: string;
}

interface ToDropDownOption extends BaseLinksDropDownOption {
  onClick: () => void;
  href?: never;
}

interface HrefDropDownOption extends BaseLinksDropDownOption {
  href: string;
  onClick?: never;
}

export type LinksDropDownOption = HrefDropDownOption | ToDropDownOption;

const GiLinksDropDown = ({
  label,
  items,
  disabled,
  hideDownArrow,
  style,
}: {
  label: React.ReactNode;
  items?: LinksDropDownOption[];
  disabled?: boolean;
  hideDownArrow?: boolean;
  style?: React.CSSProperties;
}) => {
  return (
    <Column style={style}>
      <Dropdown role="menu" drop="down">
        <Dropdown.Toggle
          variant="success"
          id="dropdown-basic"
          as={CustomToggle}
          disabled={disabled}
          // @ts-ignore
          hideDownArrow={hideDownArrow}
        >
          {label}
        </Dropdown.Toggle>

        <Dropdown.Menu
          style={{
            width: 168,
            backgroundColor: colors.grey,
          }}
        >
          {items &&
            items.map((item, index) => (
              <div key={item.label}>
                <Dropdown.Item
                  //@ts-ignore
                  label={item.label}
                  href={item.href}
                  onClick={item.onClick}
                  as={CustomDropDownItem}
                ></Dropdown.Item>
                {index < items.length - 1 && <Separator />}
              </div>
            ))}
        </Dropdown.Menu>
      </Dropdown>
    </Column>
  );
};

const Column = styled.div`
  display: flex;
  flex-direction: column;
`;

const ToggleContainer = styled.div<{ disabled?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 2px;
  box-sizing: border-box;
  padding: 10px 20px;
  height: 46px;
  font-size: 16px;
  cursor: ${(props) => (props.disabled ? undefined : 'pointer')};
`;

const DropDownItemContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 46px;
  box-sizing: border-box;
  background-color: ${({ theme }) => theme.colors.grey};
  cursor: pointer;
  &:hover {
    background-color: rgba(0, 0, 0, 0.25);
  }
`;

const DownArrow = styled.img`
  width: 10px;
  height: 10px;
  object-fit: contain;
`;

const Separator = styled.div`
  height: 1px;
  width: calc(100% - 50px);
  margin-left: 25px;
  background-color: #e0e0e0;
`;

const FullSizeDiv = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
`;

export default GiLinksDropDown;
