import { observer } from 'mobx-react';
import * as React from 'react';
import styled from 'styled-components';
import i18n from '../../constants/i18n';
import images from '../../constants/images';
import { useUiStore } from '../../stores/ui';
import { UseUserStore } from '../../stores/user';
import { TextH2 } from '../common/Typography';
import EditProfileNavbar from '../common/EditNavbar';
import { useNavigate } from 'react-router-dom';

const EditArtistProfileLayout = observer(
  ({
    children,
    title = i18n('EditArtistProfileLayout.title', 'Edit your Profile'),
  }: {
    children: any;
    title?: string;
  }) => {
    const uiStore = useUiStore();
    const userStore = UseUserStore();
    const me = userStore.getMe();
    const navigate = useNavigate();

    React.useEffect(() => {
      const maybeFetchData = async () => {
        if (!me || !me.artist) {
          await userStore.fetchMe();
        }
      };
      maybeFetchData();
    }, []);

    if (!me || !me.artist) {
      return <div />;
    }

    return (
      <Main>
        <Row>
          <EditProfileNavbar
            navBarItems={[
              {
                label: i18n('EditProfileNavbar.overview', 'OVERVIEW'),
                route: '/me/artist/overview',
              },
              {
                label: i18n('EditProfileNavbar.audio', 'AUDIO'),
                route: '/me/artist/songs',
              },
              {
                label: i18n('EditProfileNavbar.videos', 'VIDEOS'),
                route: '/me/artist/videos',
              },
              {
                label: i18n('EditProfileNavbar.gallery', 'GALLERY'),
                route: '/me/artist/gallery',
              },
              {
                label: i18n('EditProfileNavbar.pricing', 'PRICING'),
                route: '/me/artist/pricing',
              },
              {
                label: i18n('EditProfileNavbar.acts', 'ACTS'),
                route: '/me/artist/acts',
                hasNotification: me.artist.pendingActInvites.length >= 1,
              },
            ]}
          />
          <Scroller
            style={{
              height: uiStore.isMobile ? undefined : uiStore.innerHeight,
            }}
          >
            <Content>
              <CloseButtonContainer>
                <CloseButton
                  src={images.closeIcon}
                  onClick={() => navigate(`/artists/${me.artist.id}`)}
                />
              </CloseButtonContainer>
              <TextH2
                style={{
                  marginBottom: 24,
                  alignSelf: 'center',
                  width: '100%',
                  maxWidth: 800,
                }}
              >
                {title}
              </TextH2>
              <MainContent>{children}</MainContent>
            </Content>
          </Scroller>
        </Row>
      </Main>
    );
  },
);

const CloseButtonContainer = styled.div`
  display: flex;
  position: absolute;
  top: 140px;
  right: 40px;
  cursor: pointer;
  @media (max-width: ${(props) => props.theme.breakPoints.mobile}px) {
    top: 0px;
    position: relative;
    align-self: flex-end;
    right: 0%;
  }
`;

const CloseButton = styled.img`
  width: 31.61px;
  height: 20px;
  object-fit: contain;
  filter: invert(1);
`;

const Main = styled.div`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  flex: 1;
  background-color: white;
  @media (max-width: ${(props) => props.theme.breakPoints.mobile}px) {
    position: relative;
  }
`;

const Scroller = styled.div`
  overflow-y: auto;
  width: 100%;
  background-color: white;
  @media (max-width: ${(props) => props.theme.breakPoints.mobile}px) {
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: white;
  padding-top: 64px;
  padding-bottom: 64px;
  padding-left: 21px;
  padding-right: 21px;
  box-sizing: border-box;
  @media (max-width: ${(props) => props.theme.breakPoints.mobile}px) {
    padding-top: 24px;
    padding-bottom: 120px;
  }
`;

const Row = styled.div`
  display: flex;
  box-sizing: border-box;
  @media (max-width: ${(props) => props.theme.breakPoints.mobile}px) {
    overflow-y: hidden;
    flex-direction: column;
  }
`;

const MainContent = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 800px;
  width: 100%;
  align-self: center;
  @media (max-width: ${(props) => props.theme.breakPoints.mobile}px) {
  }
`;

export default EditArtistProfileLayout;
