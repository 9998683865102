import * as React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import NotFoundScreen from './404/NotFoundScreen';
import LandingPage from './landing/LandingPage';
import AboutPage from './about/AboutPage';
import HowItWorksPage from './howItWorks/HowItWorksPage';
import ArtistProfileScreen from './artistProfile/ArtistProfileScreen';
import EditArtistProfileOverview from './editArtistProfile/overview/EditArtistProfileOverview';
import EditArtistProfileVideos from './editArtistProfile/videos/EditArtistProfileVideos';
import EditArtistProfileSongs from './editArtistProfile/songs/EditArtistProfileSongs';
import EditArtistProfileGallery from './editArtistProfile/gallery/EditArtistProfileGallery';
import PrivacyPage from './legal/PrivacyPage';
import EditArtistActScreen from './editArtistProfile/acts/EditArtistActScreen';
import EditActOverview from './editAct/overview/EditActOverview';
import EditActSongs from './editAct/songs/EditActSongs';
import EditActVideos from './editAct/videos/EditActVideos';
import EditActGallery from './editAct/gallery/EditActGallery';
import ActProfileScreen from './actProfile/ActProfileScreen';
import EditActMembers from './editAct/members/EditActMembers';
import SettingsScreen from './settings/SettingsScreen';
import SupportScreen from './support/SupportScreen';
import RequestScreen from './request/RequestScreen';
import PerformerPage from './performer/PerformerPage';
import ConversationScreen from './inbox/ConversationScreen';
import FormattedPayoutsScreen from './formattedPayouts';
import EditActPricing from './editAct/pricing/EditActPricing';
import EditArtistPricing from './editArtistProfile/pricing/EditArtistPricing';
import TermsAndConditionPage from './legal/TermsAndConditionPage';
import RequestDetailsScreen from './requestDetailsScreen';
import Data from './layout/Data';
import PerformerRouteAuthCheck from './auth/PerformerRouteAuthCheck';
import UserRouteAuthCheck from './auth/UserRouteAuthCheck';
import DashboardLanding from './dashboard/DashboardLanding';
import CreateEventScreen from './events/CreateEventScreen';
import EventScreen from './events/EventScreen';
import InvoicePage from './transaction/InvoicePage';
import EventsList from './events/EventsList';
import BankingStripeReturn from './bankingStripe';

const Router = () => {
  return (
    <BrowserRouter>
      <Routes>
        {/* Legal */}
        <Route path="/privacy" element={<PrivacyPage />} />
        <Route path="/terms" element={<TermsAndConditionPage />} />
        <Route path="/invoice/:id" element={<InvoicePage />} />

        <Route element={<Data />}>
          {/* -- Performer access routes -- */}
          <Route element={<PerformerRouteAuthCheck />}>
            {/* Events */}
            <Route exact path="/events" element={<EventsList />} />

            {/*Transactions*/}
            <Route
              exact
              path="/transactions"
              element={<FormattedPayoutsScreen />}
            />
            <Route
              exact
              path="/billing/stripe"
              element={<BankingStripeReturn />}
            />

            {/* EDIT ARTIST */}
            <Route
              path="/me/artist/overview"
              element={<EditArtistProfileOverview />}
            />
            <Route
              path="/me/artist/videos"
              element={<EditArtistProfileVideos />}
            />
            <Route
              path="/me/artist/songs"
              element={<EditArtistProfileSongs />}
            />
            <Route
              path="/me/artist/gallery"
              element={<EditArtistProfileGallery />}
            />
            <Route path="/me/artist/pricing" element={<EditArtistPricing />} />
            <Route path="/me/artist/acts" element={<EditArtistActScreen />} />

            {/* EDIT ACTS */}
            <Route path="/me/acts/:id/overview" element={<EditActOverview />} />
            <Route path="/me/acts/:id/songs" element={<EditActSongs />} />
            <Route path="/me/acts/:id/videos" element={<EditActVideos />} />
            <Route path="/me/acts/:id/gallery" element={<EditActGallery />} />
            <Route path="/me/acts/:id/members" element={<EditActMembers />} />
            <Route path="/me/acts/:id/pricing" element={<EditActPricing />} />
          </Route>

          {/* -- User access routes -- */}
          <Route element={<UserRouteAuthCheck />}>
            {/* Request/transactions */}
            <Route path="/requests/:id" element={<RequestDetailsScreen />} />
            <Route path="/requests" element={<RequestScreen />} />

            {/* inbox */}
            <Route path="/conversations" element={<ConversationScreen />} />

            {/* Settings */}
            <Route path="/settings" element={<SettingsScreen />} />
            <Route path="/dashboard" element={<DashboardLanding />} />
            <Route path="/events/new" element={<CreateEventScreen />} />
            <Route path="/events/:id" element={<EventScreen />} />
          </Route>

          {/* -- Public access routes -- */}
          <Route path="/" element={<LandingPage />} />
          <Route path="/about" element={<AboutPage />} />
          <Route path="/howitworks" element={<HowItWorksPage />} />
          <Route
            path="/performers"
            element={<PerformerPage />}
            key="PerformerPage"
          />
          <Route path="/artists/:id" element={<ArtistProfileScreen />} />
          <Route path="/acts/:id" element={<ActProfileScreen />} />

          {/* Support */}
          <Route path="/support" element={<SupportScreen />} />

          <Route path="*" element={<NotFoundScreen />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

export default Router;
