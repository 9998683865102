import { observer } from 'mobx-react';
import * as React from 'react';
import { EventProposalType, Tag } from '../../shared';
import styled from 'styled-components';
import i18n from '../../constants/i18n';
import images from '../../constants/images';
import GiglinkedButton from '../common/GiglinkedButton';
import { Column, Row } from '../common/Layout';
import { useUiStore } from '../../stores/ui';
import { GeneralRegularNormal } from '../common/Typography';
import { useEventStore } from '../../stores/event';
import CancelEventModal from '../common/CancelEventModal';
import { getEventStatus } from '../../utils/eventUtils';
import { Dropdown } from 'react-bootstrap';
import HireMusicianModal from '../common/hireMusician/HireMusicianModal';
import { addDollarSign, capitalize } from '../../utils/string';
import { isArrayEmpty } from '../../utils/arrays';
import TruncatedBio from './TruncatedBio';
import colors from '../../constants/colors';
import { replaceProfileImage } from '../../utils/media';
import { useMessagingStore } from '../../stores/messaging';
import { useNavigate } from 'react-router-dom';

const EventProposals = observer(
  ({
    eventProposals,
  }: {
    eventProposals: Array<EventProposalType> | undefined;
  }) => {
    const uiStore = useUiStore();
    const eventStore = useEventStore();
    const messagingStore = useMessagingStore();
    const navigate = useNavigate();

    const { currentEvent } = eventStore;

    const sortedEventProposals = eventProposals?.sort((proposal) =>
      currentEvent!.artist?.id === proposal.artist.id ? -1 : 1,
    );

    const [isExpandedId, setIsExpandedId] = React.useState<boolean>(false);

    return (
      <Main>
        {sortedEventProposals?.map((proposal: EventProposalType) => {
          const musicianHasBeenHiredForEvent =
            currentEvent!.artist?.id === proposal.artist.id;

          const currentEventStatus = getEventStatus(currentEvent);
          const eventIsDone = currentEventStatus === 'completed';

          return (
            <ProposalContainer key={proposal.id}>
              <StyledRow>
                <VideoContainer>
                  <iframe
                    width="224px"
                    height="168px"
                    src={
                      isArrayEmpty(proposal.artist.videos)
                        ? undefined
                        : `${proposal.artist.videos[0]?.embedUrl}`
                    }
                    title="YouTube video player"
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                  />
                </VideoContainer>
                <ContentContainer>
                  <Row
                    style={{
                      justifyContent: 'space-between',
                      marginTop: uiStore.isMobile ? '1rem' : 0,
                    }}
                  >
                    {!uiStore.isMobile && (
                      <div
                        style={{ width: '10%', cursor: 'pointer' }}
                        onClick={() => {
                          const newTab = window.open(
                            `/artists/${proposal.artist.id}`,
                            '_blank',
                          );
                          newTab!.opener = null;
                        }}
                      >
                        <img
                          style={{
                            height: '74px',
                            width: '74px',
                            borderRadius: '50%',
                          }}
                          src={replaceProfileImage(
                            proposal.artist.profilePicture,
                          )}
                          alt="artist profile picture"
                        />
                      </div>
                    )}

                    <Column
                      style={{
                        width: '50%',
                        marginLeft: uiStore.isMobile ? '0' : '1rem',
                        cursor: 'pointer',
                      }}
                      onClick={() => {
                        const newTab = window.open(
                          `/artists/${proposal.artist.id}`,
                          '_blank',
                        );
                        newTab!.opener = null;
                      }}
                    >
                      <GeneralRegularNormal style={{ fontWeight: 500 }}>
                        {proposal.artist.name
                          ? capitalize(proposal.artist.name)
                          : proposal.artist.user.firstName +
                            ' ' +
                            proposal.artist.user.lastName}
                      </GeneralRegularNormal>

                      <GeneralRegularNormal style={{ color: '#606060' }}>
                        {proposal.artist.location}
                      </GeneralRegularNormal>
                    </Column>
                    <Column style={{ width: '20%' }}>
                      <GeneralRegularNormal
                        style={{
                          fontWeight: 500,
                        }}
                      >
                        {addDollarSign(String(proposal.proposalQuote))}
                      </GeneralRegularNormal>

                      <GeneralRegularNormal
                        style={{ color: '#606060', fontWeight: 500 }}
                      >
                        {i18n('EventProposal.quote', 'Quote')}
                      </GeneralRegularNormal>
                    </Column>
                    <div
                      style={{
                        width: '30%',
                        display: 'flex',
                        justifyContent: 'end',
                        alignSelf: 'baseline',
                      }}
                    >
                      <GiglinkedButton
                        label={
                          musicianHasBeenHiredForEvent
                            ? i18n('EventProposal.message', 'Message')
                            : i18n('EventProposal.hire', 'Hire me')
                        }
                        onClick={
                          musicianHasBeenHiredForEvent
                            ? async () => {
                                await messagingStore.setCurrentOtherUser(
                                  proposal.artist.user,
                                );
                                navigate('/conversations');
                              }
                            : () =>
                                uiStore.openPopup({
                                  popupDisableTapOut: true,
                                  renderContent: () => (
                                    <HireMusicianModal proposal={proposal} />
                                  ),
                                })
                        }
                        disabled={eventIsDone && Boolean(currentEvent?.artist)}
                      />
                      {!eventIsDone && (
                        <Dropdown>
                          <Dropdown.Toggle
                            variant="none"
                            style={{ margin: '0 1rem', height: '100%' }}
                          >
                            <img
                              src={images.moreVerticalIcon}
                              alt="comment image"
                            />
                          </Dropdown.Toggle>
                          <Dropdown.Menu>
                            <Dropdown.Item
                              onClick={() => {
                                uiStore.openPopup({
                                  popupDisableTapOut: true,
                                  renderContent: () => (
                                    <CancelEventModal proposal={proposal} />
                                  ),
                                });
                              }}
                              style={{
                                color: colors.red,
                                fontWeight: 600,
                              }}
                            >
                              {i18n(
                                'EventProposal.cancelBooking',
                                'Cancel booking',
                              )}
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      )}
                    </div>
                  </Row>
                  <Row style={{ flexWrap: 'wrap' }}>
                    {proposal.artist.tags
                      .slice(0, 6)
                      .map((tag: Partial<Tag>) => (
                        <TagContainer key={tag.id}>{tag.label}</TagContainer>
                      ))}
                  </Row>
                  {proposal.artist.bio && isExpandedId ? (
                    <>
                      <GeneralRegularNormal>
                        {proposal.artist.bio}
                      </GeneralRegularNormal>
                      <GeneralRegularNormal
                        onClick={() => setIsExpandedId(false)}
                        style={{
                          color: colors.buttonTextColor,
                          textDecoration: 'underline',
                          marginTop: 3,
                          cursor: 'pointer',
                        }}
                      >
                        {i18n('MusicianEventCard.lessDetails', 'Less details')}
                      </GeneralRegularNormal>
                    </>
                  ) : (
                    <>
                      {' '}
                      <TruncatedBio bio={proposal.artist.bio} numLines={3} />
                      <GeneralRegularNormal
                        onClick={() => setIsExpandedId(true)}
                        style={{
                          color: colors.buttonTextColor,
                          textDecoration: 'underline',
                          cursor: 'pointer',
                        }}
                      >
                        {i18n('MusicianEventCard.moreDetails', 'More details')}
                      </GeneralRegularNormal>
                    </>
                  )}
                </ContentContainer>
              </StyledRow>

              {proposal.message && (
                <CommentContainer>
                  <img
                    src={images.detailsIcon}
                    style={{ marginRight: '1rem' }}
                    alt="comment image"
                  />
                  {proposal.message}
                </CommentContainer>
              )}
            </ProposalContainer>
          );
        })}
        <div style={{ height: '2rem' }} />
      </Main>
    );
  },
);

const Main = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
  align-self: center;
  width: 1000px;
  @media (max-width: ${(props) => props.theme.breakPoints.mobile}px) {
    width: 100%;
    margin: 0;
    padding: 1rem;
  }
`;

const ProposalContainer = styled.div`
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid #d9d9d9;
  padding: 2rem;
  &:hover {
    background: #f2f1ff;
  }
`;

const StyledRow = styled.div`
  display: flex;
  flex-direction: row;
  @media (max-width: ${(props) => props.theme.breakPoints.mobile}px) {
    flex-direction: column;
  }
`;

const VideoContainer = styled.div`
  width: 225px;
  height: 169px;
  border;radius: 8px
  `;

const ContentContainer = styled.div`
margin-left: 2rem;
width: 100%;
@media (max-width: ${(props) => props.theme.breakPoints.mobile}px) {
  margin 0;
}
`;

const CommentContainer = styled.div`
  padding: 20px;
  border-radius: 25px;
  border: 1px;
  border: 1px solid ${(props) => props.theme.colors.buttonBorder};
  font-family: ${(props) => props.theme.fonts.GeneralSans};
  margin-top: 1rem;
`;

const TagContainer = styled.div`
  display: flex;
  align-items: center;
  height: 30px;
  padding: 4px 10px 4px 10px;
  border-radius: 24px;
  border: 1px solid ${(props) => props.theme.colors.buttonBorder};
  margin: 8px 8px 8px 0;
  color: #606060;
`;

export default EventProposals;
