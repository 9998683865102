import * as React from 'react';
import styled from 'styled-components';
import images from '../../../constants/images';
import ATag from '../ATag';
import { Entity } from '../../../shared';

const ProfileSocialLinksSection = ({ entity }: { entity: Entity }) => {
  const links = Object.keys(entity.socialLinks)
    .map((key: any) => ({
      name: key,
      // @ts-ignore
      url: entity.socialLinks[key],
    }))
    .filter((link) => link.url.length);

  if (!links.length) {
    return null;
  }

  return (
    <Main>
      {links.map((link) => (
        <ATag target="_blank" key={link.name} href={link.url}>
          <Icon
            // @ts-ignore
            src={images[link.name]}
          />
        </ATag>
      ))}
    </Main>
  );
};

const Icon = styled.img`
  width: 32px;
  height: 32px;
  object-fit: contain;
  @media (max-width: ${(props) => props.theme.breakPoints.mobile}px) {
  }
`;

const Main = styled.div`
  display: flex;
  margin-bottom: 60px;
  height: 68px;
  align-items: center;
  justify-content: space-evenly;
  background-color: #f2f3f4;
  @media (max-width: ${(props) => props.theme.breakPoints.mobile}px) {
    justify-content: space-evenly;
    margin-bottom: 50px;
    flex-wrap: wrap;
  }
`;

export default ProfileSocialLinksSection;
