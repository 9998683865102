export default {
  'ForgotPassword.emailSent!': { en: 'Email sent!', fr: 'Email envoyé!' },
  'ForgotPassword.forgotYourPassword?': {
    en: 'Forgot your password?',
    fr: 'Mot de passe oublié?',
  },
  'ForgotPassword.checkYourInbox': {
    en: 'Check your inbox and follow instructions to reset your password',
    fr: 'Vérifiez votre boîte de réception et suivez les instructions pour réinitialiser votre mot de passe',
  },
  'ForgotPassword.subtitle': {
    en: "Enter your email address and we'll send you instructions on how to reset your password.",
    fr: 'Entrez votre adresse e-mail et nous vous enverrons des instructions sur la façon de réinitialiser votre mot de passe.',
  },
  'ForgotPassword.email': { en: 'Email', fr: 'E-mail' },
  'ForgotPassword.instructions': {
    en: 'Send Reset Instructions',
    fr: 'Envoyer des instructions de réinitialisation',
  },
};
