import * as React from 'react';
import styled from 'styled-components';
import i18n from '../../constants/i18n';
import { useUiStore } from '../../stores/ui';
import { EventType, PROPOSAL_GROUP_TYPES, PaymentMethod } from '../../shared';
import { observer } from 'mobx-react';
import toast from 'react-hot-toast';
import {
  GeneralRegularSmall,
  InputLabel,
  TextH4,
  TextH5,
} from '../common/Typography';
import GiglinkedButton from '../common/GiglinkedButton';
import images from '../../constants/images';
import GiglinkedTextInput from '../common/GiglinkedTextInput';
import { useEventProposalStore } from '../../stores/eventProposal';
import SuccessModal from '../common/SuccessModal';
import colors from '../../constants/colors';
import { useEventStore } from '../../stores/event';
import Form from 'react-bootstrap/Form';
import { Column, Row } from '../common/Layout';
import {
  addDollarSign,
  isStringEmptyUndefinedOrNull,
} from '../../utils/string';
import { useNavigate } from 'react-router-dom';
import sexpressClient from '../../utils/sexpress';

const ApplyToEventModal = observer(
  ({ event }: { event: Partial<EventType> }) => {
    const uiStore = useUiStore();
    const eventProposalStore = useEventProposalStore();
    const eventStore = useEventStore();
    const navigate = useNavigate();
    const { initEventProposal } = eventProposalStore;
    const { setCurrentEventTab, setEvents, fetchEvents } = eventStore;

    const [eventProposal, setEventProposal] = React.useState({
      proposalQuote: '',
      message: '',
      proposalGroupType: '',
    });

    const [paymentInfo, setPaymentInfo] = React.useState<PaymentMethod | null>(
      null,
    );

    const checkIfStripeAccountCreationIsValid = async () => {
      const response =
        await sexpressClient.checkIfStripeAccountCreationIsValid();
      if (response?.last4) {
        setPaymentInfo(response);
      }
    };

    React.useEffect(() => {
      checkIfStripeAccountCreationIsValid();
    }, []);

    const applyToEvent = async () => {
      try {
        if (event.id) {
          const { message, proposalQuote, proposalGroupType } = eventProposal;
          const trimmedProposalQuote: string = proposalQuote.replace(
            /^0+/,
            (match) => match.replace(/^0+/, ''),
          );
          await initEventProposal({
            message,
            proposalQuote: Number(trimmedProposalQuote),
            proposalGroupType,
            id: event.id,
          });
          const res = await fetchEvents();
          const { events } = res;
          setEvents(events);
        }

        uiStore.openPopup({
          popupDisableTapOut: true,
          renderContent: () => (
            <SuccessModal
              title={i18n(
                'ApplyToEventModal.successTitle',
                'Congratulations, your profile has been sent to the organizer!',
              )}
              secondaryText={i18n(
                'ApplyToEventModal.successSecondaryText',
                'You will receive a confirmation email if you are selected for the gig. Be sure to regularly check your inbox and spam folder.',
              )}
              navigate={generateNavigation(true)}
            />
          ),
        });
      } catch (e) {
        const err = e as Error;
        toast.error(err.message);
      }
    };

    const generateNavigation = (isPaymentInfoConfigured: boolean) => {
      return (
        <div
          style={{
            display: 'flex',
            flexDirection: uiStore.isMobile ? 'column' : 'row',
          }}
        >
          <GiglinkedButton
            label={i18n(
              isPaymentInfoConfigured
                ? 'ApplyToEventModal.goToDashboard'
                : 'ApplyToEventModal.applyAnyway',
              isPaymentInfoConfigured ? 'Go to Dashboard' : 'Apply anyway',
            )}
            onClick={() => {
              if (isPaymentInfoConfigured) {
                navigate('/events');
                setCurrentEventTab('applied');
                uiStore.closePopup();
              } else {
                applyToEvent();
              }
            }}
            style={{ border: 'none' }}
          />
          {isPaymentInfoConfigured && (
            <GiglinkedButton
              label={i18n(
                'ApplyToEventModal.keepBrowsing',
                'Keep browsing gigs',
              )}
              onClick={() => {
                navigate('/events');
                setCurrentEventTab('open');
                uiStore.closePopup();
              }}
            />
          )}
          {!isPaymentInfoConfigured && (
            <GiglinkedButton
              label={i18n(
                'ApplyToEventModal.editBankingInformation',
                'Edit Banking Information',
              )}
              onClick={() => {
                navigate('/settings');
                uiStore.closePopup();
              }}
            />
          )}
        </div>
      );
    };

    const isPaymentInfoConfigured = Boolean(paymentInfo?.last4);

    return (
      <Main>
        <Close src={images.closeIconBlack} onClick={uiStore.closePopup} />
        <TextH4 style={{ marginBottom: '1rem', color: colors.black }}>
          {i18n('ApplyToEventModal.title', 'Application for: ')} {event.name}
        </TextH4>
        <TextH5 style={{ fontWeight: 400 }}>
          {i18n(
            'ApplyToEventModal.subTitle',
            'Fill in a few words about your proposal.',
          )}
        </TextH5>

        <Column style={{ textAlign: 'left' }}>
          <InputLabel>
            {i18n('ApplyToEventModal.clientBudget', "Client's budget: ")}
            {addDollarSign(String(event.estimatedBudget))}
          </InputLabel>
          <GeneralRegularSmall
            style={{ color: colors.tableTextColor, margin: '0 1rem 1rem' }}
          >
            {i18n(
              'ApplyToEventModal.budgetSupportingText',
              'Although the client has provided their budget, you have the freedom to set your own price. However, consider that staying reasonably close to the client’s budget increases the likelihood of being hired.”',
            )}
          </GeneralRegularSmall>
          <InputLabel>
            {i18n('ApplyToEventModal.applyingAs', 'Applying as: ')}
          </InputLabel>
          <Row>
            <Form style={{ width: '100%', margin: '0 0.5rem' }}>
              {PROPOSAL_GROUP_TYPES.map((proposalGroupType) => (
                <Form.Check
                  key={proposalGroupType.value}
                  inline
                  label={proposalGroupType.label}
                  name="group1"
                  type={'radio'}
                  onClick={() =>
                    setEventProposal({
                      ...eventProposal,
                      proposalGroupType: proposalGroupType.value,
                    })
                  }
                  style={{
                    width: '30%',
                    fontFamily: 'General Sans',
                    fontSize: '14px',
                    marginBottom: '1rem',
                  }}
                />
              ))}
            </Form>
          </Row>
          <InputLabel htmlFor="proposalQuote">
            {i18n('ApplyToEventModal.quote', 'Your Quote')}
          </InputLabel>
          <GiglinkedTextInput
            id="proposalQuote"
            style={{ width: '100%' }}
            type="number"
            value={eventProposal.proposalQuote}
            onChangeText={(ev) =>
              setEventProposal({ ...eventProposal, proposalQuote: ev })
            }
            placeholder="$"
          />
          <GeneralRegularSmall
            style={{ color: colors.tableTextColor, margin: '0 1rem 1rem' }}
          >
            <li>
              {i18n(
                'ApplyToEventModal.quoteSupportingDescOne',
                'The quote should include taxes, if applicable.',
              )}
            </li>
            <li>
              {i18n(
                'ApplyToEventModal.quoteSupportingDescTwo',
                'This will only be visible to the event organizer and not to other musicians.',
              )}
            </li>
            <li>
              {i18n(
                'ApplyToEventModal.quoteSupportingDescThree',
                'This quote cannot be changed in the future.',
              )}
            </li>
          </GeneralRegularSmall>
          <InputLabel htmlFor="message">
            {i18n(
              'ApplyToEventModal.sendMessage',
              'Send a message to the organizer',
            )}
          </InputLabel>
          <MultilineTextArea
            value={eventProposal.message}
            onChange={(ev) =>
              setEventProposal({ ...eventProposal, message: ev.target.value })
            }
            id="message"
            cols={40}
            rows={5}
            style={{
              width: '100%',
            }}
          />
          <GeneralRegularSmall
            style={{ color: colors.tableTextColor, margin: '0 1rem' }}
          >
            {i18n(
              'ApplyToEventModal.customMessageSupportingDesc',
              'Sending a personalized message will increase your chances of being selected for the gig.',
            )}
          </GeneralRegularSmall>
        </Column>
        <Row style={{ marginTop: '1rem' }}>
          <GiglinkedButton
            label={i18n('ApplyToEventModal.cancel', 'Cancel')}
            onClick={async () => {
              uiStore.closePopup();
            }}
            style={{ border: 'none' }}
          />
          <GiglinkedButton
            label={i18n('ApplyToEventModal.apply', 'Apply')}
            disabled={
              !eventProposal.proposalQuote ||
              isStringEmptyUndefinedOrNull(eventProposal.proposalGroupType)
            }
            onClick={async () => {
              if (isPaymentInfoConfigured) applyToEvent();
              else {
                uiStore.openPopup({
                  popupDisableTapOut: true,
                  renderContent: () => (
                    <SuccessModal
                      title={i18n(
                        'ApplyToEventModal.noPaymentInfoConfigured',
                        'To ensure your payments are received without delays, enter your banking information before applying to gigs.',
                      )}
                      navigate={generateNavigation(false)}
                    />
                  ),
                });
              }
            }}
          />
        </Row>
      </Main>
    );
  },
);

const Main = styled.div`
  display: flex;
  flex-direction: column;
  padding: 3rem;
  max-width: 627px;
  border-radius: 10px;
  background-color: white;
  align-items: center;
  text-align: center;
  @media (max-width: ${(props) => props.theme.breakPoints.mobile}px) {
    max-width: 600px;
  }
`;

const Close = styled.img`
  cursor: pointer;
  margin-left: auto;
  object-fit: contain;
  height: 12px;
`;

const MultilineTextArea = styled.textarea`
  width: 100%;
  border: 1px solid ${(props) => props.theme.colors.buttonBorder};
  border-radius: 10px;
  padding-left: 10px;
`;

export default ApplyToEventModal;
