import { observer } from 'mobx-react';
import * as React from 'react';
import styled from 'styled-components';
import i18n from '../../../../../../constants/i18n';
import SettingProfilePicture from '../../../../../settings/SettingProfilePicture';
import GiAuthContainer from '../../../../../common/GiAuthContainer';
import GiglinkedButton from '../../../../../common/GiglinkedButton';
import { ProgressBar } from 'react-bootstrap';

interface Props {
  progress: number;
  next: () => void;
}
const StepperRegistrationMusicianUploadPicture = observer(
  ({ progress, next }: Props) => {
    return (
      <GiAuthContainer
        title={i18n(
          'RegistrationMusicianUploadPicture.title',
          'Upload profile picture',
        )}
        secondaryText={i18n(
          'RegistrationMusicianUploadPicture.description',
          'Profiles with profile pictures appear earlier on the search page',
        )}
        editableContent={
          <>
            <MainBox>
              <UploadBox>
                <SettingProfilePicture />
              </UploadBox>
            </MainBox>
            <BoxBottom>
              <ProgressBar
                animated
                striped
                style={{ width: '100%' }}
                min={0}
                max={6}
                now={progress}
                variant="success"
              />

              <GiglinkedButton
                style={{
                  border: 'none',
                }}
                label={i18n('RegistrationStepper.next', 'Next')}
                onClick={next}
              />
            </BoxBottom>
          </>
        }
      />
    );
  },
);

const MainBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 90%;
  @media (max-width: ${(props) => props.theme.breakPoints.mobile}px) {
    padding-left: 20px;
    padding-right: 20px;
  }
`;

const UploadBox = styled.div`
  display: flex;
  justify-content: center;
  border-radius: 50%;
  width: 200px;
  height: 200px;
`;

const BoxBottom = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 10%;
  @media (max-width: ${(props) => props.theme.breakPoints.mobile}px) {
    margin-right: 5px;
    margin-top: 0;
  }
`;

export default StepperRegistrationMusicianUploadPicture;
