export default {
  'ApplyToEventModal.title': {
    en: 'Application for: ',
    fr: 'Candidature pour:',
  },
  'ApplyToEventModal.subTitle': {
    en: 'Fill in a few words about your proposal.',
    fr: 'Remplissez quelques mots à propos de votre proposition.',
  },
  'ApplyToEventModal.quote': { en: 'Your Quote', fr: 'Votre devis' },
  'ApplyToEventModal.sendMessage': {
    en: 'Send a message to the organizer',
    fr: "Envoyer un message à l'organisateur",
  },
  'ApplyToEventModal.cancel': { en: 'Cancel', fr: 'Annuler' },
  'ApplyToEventModal.apply': { en: 'Apply', fr: 'Appliquer' },
  'ApplyToEventModal.customMessageSupportingDesc': {
    en: 'Sending a personalized message will increase your chances of being selected for the gig.',
    fr: "En envoyant un message personnalisé, vous augmentez vos chances d'être sélectionné pour le concert.",
  },
  'ApplyToEventModal.successTitle': {
    en: 'Congratulations, your profile has been sent to the organizer!',
    fr: "Félicitations, votre profil a été envoyé à l'organisateur !",
  },
  'ApplyToEventModal.goToDashboard': {
    en: 'Go to Dashboard',
    fr: 'Accéder au tableau de bord',
  },
  'ApplyToEventModal.clientBudget': {
    en: "Client's budget: ",
    fr: 'Budget du client:',
  },
  'ApplyToEventModal.budgetSupportingText': {
    en: 'Although the client has provided their budget, you have the freedom to set your own price. However, consider that staying reasonably close to the client’s budget increases the likelihood of being hired.',
    fr: "Bien que le client ait indiqué son budget, vous êtes libre de fixer votre propre prix. Toutefois, sachez qu'en restant raisonnablement proche du budget du client, vous augmentez vos chances d'être embauché.",
  },
  'ApplyToEventModal.applyingAs': {
    en: 'Applying as: ',
    fr: 'Vous postulez en tant que :',
  },
  'ApplyToEventModal.quoteSupportingDescOne': {
    en: 'The quote should include taxes, if applicable.',
    fr: 'Le devis doit inclure les taxes, le cas échéant.',
  },
  'ApplyToEventModal.quoteSupportingDescTwo': {
    en: 'This will only be visible to the event organizer and not to other musicians.',
    fr: "Cette information ne sera visible que par l'organisateur de l'événement et non par les autres musiciens.",
  },
  'ApplyToEventModal.quoteSupportingDescThree': {
    en: 'This quote cannot be changed in the future.',
    fr: "Ce devis ne peut être modifié à l'avenir.",
  },
  'ApplyToEventModal.successSecondaryText': {
    en: 'You will receive a confirmation email if you are selected for the gig. Be sure to regularly check your inbox and spam folder.',
    fr: 'Vous recevrez un courriel de confirmation si vous êtes sélectionné pour le concert. Veillez à vérifier régulièrement votre boîte de réception et votre dossier de courrier indésirable.',
  },
  'ApplyToEventModal.keepBrowsing': {
    en: 'Keep browsing gigs',
    fr: 'Continuer à parcourir les gigs',
  },
  'ApplyToEventModal.editBankingInformation': {
    en: 'Edit Banking Information',
    fr: 'Modifier les informations bancaires',
  },
  'ApplyToEventModal.noPaymentInfoConfigured': {
    en: 'To ensure your payments are received without delays, enter your banking information before applying to gigs.',
    fr: "Pour éviter les délais de vos paiements, saisissez vos informations bancaires avant d'appliquer à des gigs.",
  },
};
