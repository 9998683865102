import * as React from 'react';
import styled from 'styled-components';
import config from '../../config';
import GiLocationDropDown from './GiLocationDropDown';
import { useUiStore } from '../../stores/ui';
import toast from 'react-hot-toast';

const GiglinkedLocationAutocomplete = ({
  location,
  setLocation,
  style,
  ...rest
}: React.InputHTMLAttributes<any> & {
  location: string;
  setLocation: (location: string) => void;
  style?: React.CSSProperties;
}) => {
  const [autocompleteSuggestions, setAutocompleteSuggestions] = React.useState(
    [],
  );
  const uiStore = useUiStore();

  // Create a reference to the input element
  const inputRef = React.useRef<HTMLInputElement | null>(null);

  const handleInputChange = async (query: string) => {
    try {
      const url = `https://api.mapbox.com/geocoding/v5/mapbox.places/${query}.json?types=place&country=ca&access_token=${config.mapboxAccessToken}`;
      const response = await fetch(url);
      const data = await response.json();

      if (inputRef.current && query === inputRef.current.value) {
        setAutocompleteSuggestions(data.features);
      }
    } catch (e) {
      const err = e as Error;
      toast.error(err.message);
    }
  };

  return (
    <Main style={style}>
      <Input
        ref={inputRef}
        onChange={(ev) => {
          const query = ev.target.value;
          setLocation(query);
          handleInputChange(query);
        }}
        value={location}
        {...rest}
      />

      {autocompleteSuggestions?.length > 0 && (
        <GiLocationDropDown
          items={autocompleteSuggestions}
          onChange={(ev: any) => {
            setLocation(ev.text);
            setAutocompleteSuggestions([]);
          }}
          isMobile={uiStore.isMobile}
        />
      )}
    </Main>
  );
};

const Main = styled.div`
  box-sizing: border-box;
  margin-bottom: 1rem;
  ${({ theme }) => {
    return `background-color: ${theme.colors.white};
      border-radius: 10px;`;
  }}}
  height: 44px;
  @media (max-width: ${(props) => props.theme.breakPoints.mobile}px) {
    margin-bottom: 10px;
  }
`;

const Input = styled.input`
  text-decoration: none;
  outline: none;
  height: 100%;
  font-size: 16px;
  padding-left: 10px;
  box-sizing: border-box;
  width: 100%;
  background-color: transparent;
  border-radius: 10px;
  font-family: ${(props) => props.theme.fonts.GeneralSans};
  font-weight: normal;

  ${({ theme }) => {
    return `border: 1px solid ${theme.colors.buttonBorder};
      color: ${theme.colors.black};
      :focus {border: 1px solid ${theme.colors.blue};  }
      ::placeholder {}
    `;
  }}
`;

export default GiglinkedLocationAutocomplete;
