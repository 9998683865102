import { observer } from 'mobx-react';
import * as React from 'react';
import styled from 'styled-components';
import i18n from '../../constants/i18n';
import { Transaction } from '../../shared';
import sexpressClient from '../../utils/sexpress';
import toast from 'react-hot-toast';
import { GeneralRegularNormal } from '../common/Typography';
import { isArrayEmpty } from '../../utils/arrays';
import InvoicesTable from './InvoicesTable';
import { useUiStore } from '../../stores/ui';
import InvoicesCard from './InvoicesCard';

const Invoices = observer(() => {
  const uiStore = useUiStore();

  const [transactions, setTransactions] = React.useState<Transaction[]>([]);

  const fetchTransactions = React.useCallback(async () => {
    try {
      let resTransactions: Transaction[] = [];

      setTransactions([]);

      const promiseRes = await Promise.all([
        sexpressClient.getTransactions({
          status: 'accepted',
        }),
        sexpressClient.getTransactions({
          status: 'completed',
        }),
      ]);
      promiseRes.forEach((res) => {
        resTransactions = [
          ...resTransactions,
          ...res.userTransactions,
          // ...res.artistTransactions,
          // ...res.actTransactions,
        ];
      });

      setTransactions(resTransactions);
    } catch (e) {
      const err = e as Error;
      toast.error(err.message);
    }
  }, []);

  React.useEffect(() => {
    fetchTransactions();
  }, []);

  const getComponent = () => {
    if (isArrayEmpty(transactions)) {
      return (
        <EmptyContainer>
          <GeneralRegularNormal>
            {i18n('Invoices.noInvoices', 'You have no invoices yet')}
          </GeneralRegularNormal>
        </EmptyContainer>
      );
    } else if (uiStore.isMobile) {
      return <InvoicesCard transactions={transactions} />;
    } else {
      return <InvoicesTable transactions={transactions} />;
    }
  };
  return getComponent();
});

const EmptyContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-self: center;
  align-items: center;
  background: ${(props) => props.theme.colors.cardColor};
  border-radius: 20px;
  height: 190px;
  margin: 3rem 0;
  width: 1000px;
  @media (max-width: ${(props) => props.theme.breakPoints.mobile}px) {
    width: 100%;
  }
`;

export default Invoices;
