export default {
  'ResetPassword.resetYourPassword': {
    en: 'Reset your password',
    fr: 'Réinitialisez votre mot de passe',
  },
  'ResetPassword.resetYourPasswordDescription': {
    en: 'Enter your new password and confirm it',
    fr: 'Entrez votre nouveau mot de passe et confirmez-le',
  },
  'ResetPassword.newPassword': {
    en: 'New password',
    fr: 'Nouveau mot de passe',
  },
  'ResetPassword.confirmNewPassword': {
    en: 'Confirm new password',
    fr: 'Confirmer nouveau mot de passe',
  },
};
