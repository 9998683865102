export default {
  'RequestCell.actBooking': { en: 'Act', fr: 'Loi' },
  'RequestCell.message': { en: 'Send message', fr: 'Envoyer le message' },
  'RequestCell.artistBooking': { en: 'Artist', fr: 'Artiste' },
  'RequestCell.requestFrom': { en: 'Request from', fr: 'Demande de' },
  'RequestCell.viewDetail': { en: 'View details', fr: 'Voir les détails' },
  'RequestCell.cancel': { en: 'Cancel', fr: 'Annuler' },
  'RequestCell.accept': { en: 'Accept', fr: 'Accepter' },
  'RequestCell.decline': { en: 'Decline', fr: 'Déclin' },
  'RequestCell.ownerText': {
    en: 'Only the act owner can accept this request.',
    fr: "Seul le propriétaire de l'ACT peut accepter cette demande.",
  },
};
