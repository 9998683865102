import * as React from 'react';
import { Box } from 'react-native-kondo';
import styled from 'styled-components';
import colors from '../../constants/colors';
import i18n from '../../constants/i18n';
import { UseUserStore } from '../../stores/user';
import GiButton from '../common/GiButton';
import PageLayoutFooter from '../common/PageLayoutFooter';
import { GeneralRegularNormal, TextH1 } from '../common/Typography';
import SupportContactBox from './SupportContactBox';
import SupportSubjectDropDown, {
  SupportSubjectDropDownItem,
} from './SupportSubjectDropDown';

const SupportScreen = () => {
  const user = UseUserStore();
  const me = user.getMe();
  const [name, setName] = React.useState(
    me?.firstName && me?.lastName ? `${me?.firstName} ${me?.lastName}` : '',
  );

  const [email, setEmail] = React.useState(me?.email ?? '');
  const [message, setMessage] = React.useState('');
  const [subject, setSubject] = React.useState<SupportSubjectDropDownItem>();

  return (
    <Main>
      <Box mb={36}>
        <TextH1 style={{ alignSelf: 'center', marginBottom: 24 }}>
          {i18n('SupportScreen.title', 'Support')}
        </TextH1>
        <GeneralRegularNormal
          style={{
            textAlign: 'center',
            alignSelf: 'center',
            color: colors.black,
            maxWidth: 450,
          }}
        >
          {i18n(
            'SupportScreen.text',
            'For any questions or concerns, fill in the contact form below and we will get back to you as soon as possible.',
          )}
        </GeneralRegularNormal>
      </Box>

      <Content>
        <InputSupport
          placeholder={i18n('SupportScreen.placeholderName', 'Name')}
          value={name}
          onChange={(ev) => setName(ev.target.value)}
        />
        <InputSupport
          placeholder={i18n('SupportScreen.placeholderEmail', 'Email')}
          value={email}
          onChange={(ev) => setEmail(ev.target.value)}
        />

        <SupportSubjectDropDown
          onChange={setSubject}
          value={subject?.label}
          items={[
            {
              label: i18n('SupportScreen.Technical', 'Technical Support'),
              value: 'tech',
            },
            {
              label: i18n('SupportScreen.transactions', 'Transactions'),
              value: 'transactions',
            },
            {
              label: i18n('SupportScreen.booking', 'Booking'),
              value: 'booking',
            },
            {
              label: i18n('SupportScreen.other', 'Other'),
              value: 'other',
            },
          ]}
        />

        <TextAreaSupport
          placeholder={i18n('SupportScreen.placeholderMessage', 'Message')}
          value={message}
          onChange={(ev) => setMessage(ev.target.value)}
        />
        <GiButton
          label={i18n('SupportScreen.sendButton', 'Send')}
          style={{ alignSelf: 'center' }}
        />
        <SupportContactBox />
      </Content>

      <PageLayoutFooter />
    </Main>
  );
};

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-self: center;
  width: 100%;
  min-height: 50vh;
  max-width: 600px;
  padding-left: 24px;
  padding-right: 24px;
  padding-bottom: 24px;
  box-sizing: border-box;
  @media (max-width: ${(props) => props.theme.breakPoints.mobile}px) {
  }
`;

const Main = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-sizing: border-box;
  padding-top: 64px;
  flex: 1;
  @media (max-width: ${(props) => props.theme.breakPoints.mobile}px) {
    padding-top: 24px;
  }
`;

const InputSupport = styled.input`
  padding: 10px 21px;
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  box-shadow: 4px 4px 10px rgba(131, 123, 119, 0.25);
  border-radius: 2px;
  margin-bottom: 16px;
`;

const TextAreaSupport = styled.textarea`
  padding: 10px 21px;
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.1);
  height: 165px;
  box-sizing: border-box;
  box-shadow: 4px 4px 10px rgba(131, 123, 119, 0.25);
  border-radius: 2px;
  margin-bottom: 16px;
  resize: none;
`;

export default SupportScreen;
