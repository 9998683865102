export default {
  'RegistrationMusicianSoloOrGroup.profileFor': {
    en: 'Who is this profile for',
    fr: 'Ce profil est pour',
  },
  'RegistrationMusicianSoloOrGroup.underTitle': {
    en: 'If you select "a group," an individual profile will be created by default. You can edit this later.',
    fr: 'Si vous sélectionnez "un groupe", un profil individuel sera créé par défaut. Vous pourrez le modifier ultérieurement.',
  },
  'RegistrationMusicianSoloOrGroup.meButton': { en: 'Just me', fr: 'Moi' },
  'RegistrationMusicianSoloOrGroup.groupButton': {
    en: 'A group',
    fr: 'Un groupe',
  },
  'RegistrationMusicianSoloOrGroup.next': { en: 'Next', fr: 'Suivant' },
};
