export default {
  'LandingPhoneSection.title': {
    en: 'Become a beta-tester!',
    fr: 'Vous voulez devenir un bêta-testeur?',
  },
  'LandingPhoneSection.subtitle': {
    en: 'Join your fellow musicians, event planners, and venues in making live music more sustainable for everyone!',
    fr: "Rejoignez vos collègues musiciens, planificateurs d'événements et sites pour faire de la musique en direct plus durable pour tout le monde!",
  },
  'LandingPhoneSection.buttonLabel': {
    en: 'become a beta tester',
    fr: 'devenir un testeur bêta',
  },
};
