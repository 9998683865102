import { format } from 'date-fns';
import { frCA } from 'date-fns/locale';
import * as React from 'react';
import { Box } from 'react-native-kondo';
import styled from 'styled-components';
import colors from '../../constants/colors';
import i18n, { getDeviceLangage } from '../../constants/i18n';
import { useBookingStore } from '../../stores/booking';
import GiButton from '../common/GiButton';
import GiDropDown from '../common/GiDropDown';
import GiIncrementer from '../common/GiIncrementer';
import { GeneralRegularNormal, TextH2, TextH3 } from '../common/Typography';
import Calendar from './Calendar';

const year = new Date().getFullYear();
const month = new Date().getMonth();
const day = new Date().getDate();
const START_TIMES = Array.from({ length: 24 }, (_, i) => ({
  label: format(new Date(year, month, day, i), 'p', {
    locale: getDeviceLangage() === 'fr' ? frCA : undefined,
  }),
  value: new Date(year, month, day, i).toString(),
}));

const BookingDateSelection = ({ onPressNext }: { onPressNext: () => void }) => {
  const bookingStore = useBookingStore();
  const [selectedDate, setSelectedDate] = React.useState<Date | undefined>(
    bookingStore.date,
  );
  const [startDate, setStartDate] = React.useState<Date | undefined>(
    bookingStore.startTime,
  );
  const [endDate, setEndDate] = React.useState<Date | undefined>(
    bookingStore.endTime,
  );

  let END_TIMES: any = [];

  if (startDate) {
    END_TIMES = Array.from({ length: 20 }, (_, i) => ({
      label: format(
        new Date(year, month, day, i + 1 + startDate!.getHours()),
        'p',
        {
          locale: getDeviceLangage() === 'fr' ? frCA : undefined,
        },
      ),
      value: new Date(
        year,
        month,
        day,
        i + 1 + startDate!.getHours(),
      ).toString(),
    }));
  }

  return (
    <Main>
      <TextH2
        style={{
          color: colors.whiteText,
          marginBottom: 36,
          alignSelf: 'center',
          textAlign: 'center',
        }}
      >
        {i18n('BookingDateSelection.whenIsYourEvent', 'When is your event')}
      </TextH2>

      <Calendar
        onChangeSelectedDates={(dates) => {
          setSelectedDate(dates[0]);
          bookingStore.setBookingData({
            date: dates[0],
          });
        }}
      />

      <Box flexDirection="row" my={48}>
        <GiDropDown
          style={{ flex: 1 }}
          label={i18n('BookingDateSelection.start', 'Start')}
          items={START_TIMES}
          disabled={!selectedDate}
          onChange={(item) => {
            setStartDate(new Date(item.value));
            setEndDate(undefined);
            bookingStore.setBookingData({
              startTime: new Date(item.value),
            });
          }}
          value={
            startDate
              ? format(startDate, 'p', {
                  locale: getDeviceLangage() === 'fr' ? frCA : undefined,
                })
              : undefined
          }
        />
        <Box width={42} />
        <GiDropDown
          style={{ flex: 1 }}
          label={i18n('BookingDateSelection.end', 'End')}
          items={END_TIMES}
          disabled={!startDate}
          onChange={(item) => {
            setEndDate(new Date(item.value));
            bookingStore.setBookingData({
              endTime: new Date(item.value),
            });
          }}
          value={
            endDate
              ? format(endDate, 'p', {
                  locale: getDeviceLangage() === 'fr' ? frCA : undefined,
                })
              : undefined
          }
        />
      </Box>

      <TextH3 style={{ color: colors.whiteText }}>
        {i18n('BookingDateSelection.available', 'Available Time for Set Up')}
      </TextH3>
      <GeneralRegularNormal
        style={{ color: colors.whiteText, marginTop: 16, maxWidth: 500 }}
      >
        {i18n(
          'BookingDateSelection.availablesub',
          'Will the artist be able to come earlier to set up? If so, please indicate up to how many hours earlier they may arrive.',
        )}
      </GeneralRegularNormal>

      <div style={{ display: 'flex', marginTop: 16 }}>
        <GiIncrementer
          style={{}}
          onChangeValue={(val) =>
            bookingStore.setBookingData({
              setupTime: val,
            })
          }
        />
        <Box width={42} />
        <div style={{ display: 'flex', flex: 1 }} />
      </div>

      <GiButton
        label={i18n('BookingDateSelection.next', 'Next')}
        style={{ alignSelf: 'center', marginTop: 36 }}
        disabled={!selectedDate || !startDate || !endDate}
        onClick={onPressNext}
      />
    </Main>
  );
};

const Main = styled.div`
  display: flex;
  box-sizing: border-box;
  flex-direction: column;
  align-self: center;

  padding-bottom: 63px;
  @media (max-width: ${(props) => props.theme.breakPoints.mobile}px) {
    padding: 0px 20px 40px 20px;
  }
`;

export default BookingDateSelection;
