import * as React from 'react';
import { observer } from 'mobx-react';
import styled from 'styled-components';
import LandingTopSection from './LandingTopSection';
import LandingFooter from './LandingFooter';
import { useNavigate, useSearchParams } from 'react-router-dom';
import delay from '../../utils/delay';
import LandingTabularSection from './LandingTabularSection';
import LandingPopularMusiciansSection from './LandingPopularMusiciansSection';
import HowItWorksGetADemoSection from '../howItWorks/HowItWorksGetADemoSection';
import HowItWorksAccordionSection from '../howItWorks/HowItWorksAccordionSection';
import HowItWorksOrgSection from '../howItWorks/HowItWorksOrgSection';

const LandingPage = observer(() => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  React.useEffect(() => {
    const maybeOpenSigninPopup = async () => {
      const performerLogin = searchParams.get('performerLogin');
      const userLogin = searchParams.get('userLogin');

      if (performerLogin || userLogin) {
        await delay(250);
        navigate(`/signin`);
      }
    };
    maybeOpenSigninPopup();
  }, []);

  return (
    <Main>
      <LandingTopSection />
      <LandingTabularSection />
      <LandingPopularMusiciansSection />
      <HowItWorksGetADemoSection />
      <HowItWorksOrgSection />
      <HowItWorksAccordionSection />
      <LandingFooter />
    </Main>
  );
});

const Main = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  @media (max-width: ${(props) => props.theme.breakPoints.tablet}px) {
    width: 100%;
  }
  @media (max-width: ${(props) => props.theme.breakPoints.mobile}px) {
    width: 100%;
  }
`;

export default LandingPage;
