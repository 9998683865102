export default {
  'ProfileActsOrMemberSection.associated': {
    en: 'Associated Acts',
    fr: 'Actes associés',
  },
  'ProfileActsOrMemberSection.members': { en: 'Members', fr: 'Membres' },
  'ProfileActsOrMemberSection.create Act': {
    en: 'Create Act',
    fr: 'Créer un acte',
  },
};
