import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { InputLabel } from '../../common/Typography';
import i18n from '../../../constants/i18n';
import Form from 'react-bootstrap/Form';
import { EventType, PLAY_STYLES } from '../../../shared';
import styled from 'styled-components';
import GiEventsDropDown from '../../common/GiEventsDropDown';

const EventDetailsStepThree = observer(
  ({
    event,
    setEvent,
  }: {
    event: Partial<EventType>;
    setEvent: React.Dispatch<React.SetStateAction<any>>;
  }) => {
    return (
      <>
        <InputLabel htmlFor="style">
          {i18n('EventDetailsStepThree.style', 'Style of music')}
        </InputLabel>
        <GiEventsDropDown
          items={PLAY_STYLES()}
          value={event.style}
          onChange={(ev) =>
            setEvent({
              ...event,
              style: ev.value,
            })
          }
          style={{
            width: '100%',
            marginBottom: '1rem',
          }}
          active
        />
        <InputLabel htmlFor="withVocals">
          {i18n('EventDetailsStepThree.withVocals', 'With vocals?')}
        </InputLabel>
        <CheckStyleContainer>
          <Form style={{ width: '100%' }}>
            <Form.Check
              inline
              label={i18n('EventDetailsStepThree.yes', 'Yes')}
              name="group1"
              type={'radio'}
              checked={event.withVocals === 'yes'}
              onClick={() =>
                setEvent({
                  ...event,
                  withVocals: 'yes',
                })
              }
              style={{
                width: '30%',
                fontFamily: 'General Sans',
                fontSize: '1rem',
              }}
            />
            <Form.Check
              inline
              label={i18n('EventDetailsStepThree.no', 'No')}
              name="group1"
              type={'radio'}
              checked={event.withVocals === 'no'}
              onClick={() =>
                setEvent({
                  ...event,
                  withVocals: 'no',
                })
              }
              style={{
                width: '30%',
                fontFamily: 'General Sans',
                fontSize: '1rem',
              }}
            />
            <Form.Check
              inline
              label={i18n(
                'EventDetailsStepThree.noPreference',
                'No preference',
              )}
              name="group1"
              type={'radio'}
              checked={event.withVocals === 'no preference'}
              onClick={() => {
                setEvent({ ...event, withVocals: 'no preference' });
              }}
              style={{
                width: '30%',
                fontFamily: 'General Sans',
                fontSize: '1rem',
              }}
            />
          </Form>
        </CheckStyleContainer>

        <InputLabel htmlFor="equipment">
          {i18n('EventDetailsStepThree.equipment', 'Equipment')}
        </InputLabel>
        <CheckStyleContainer>
          <Form>
            <Form.Check
              checked={event.equipment === 'provided on site'}
              inline
              type="switch"
              id="provided on site"
              label={
                event.equipment === 'provided on site'
                  ? i18n(
                      'EventDetailsStepThree.providedOnSite',
                      'Provided on site',
                    )
                  : i18n(
                      'EventDetailsStepThree.providedByMusician',
                      'Provided by musician',
                    )
              }
              onClick={() =>
                setEvent({
                  ...event,
                  equipment:
                    event.equipment === 'provided on site'
                      ? 'provided by musician'
                      : 'provided on site',
                })
              }
              style={{
                fontFamily: 'General Sans',
                fontSize: '1rem',
              }}
            />
          </Form>
        </CheckStyleContainer>

        <InputLabel htmlFor="performanceType">
          {i18n('EventDetailsStepThree.performanceType', 'Performance type')}
        </InputLabel>
        <CheckStyleContainer>
          <Form style={{ width: '100%' }}>
            <Form.Check
              inline
              defaultChecked
              label={i18n('EventDetailsStepThree.concert', 'Concert')}
              checked={event.performanceType === 'concert'}
              name="group1"
              type={'radio'}
              onClick={() => setEvent({ ...event, performanceType: 'concert' })}
              style={{
                width: '40%',
                fontFamily: 'General Sans',
                fontSize: '1rem',
              }}
            />
            <Form.Check
              inline
              label={i18n(
                'EventDetailsStepThree.backgroundMusic',
                'Background music',
              )}
              checked={event.performanceType === 'background music'}
              name="group1"
              type={'radio'}
              onClick={() =>
                setEvent({ ...event, performanceType: 'background music' })
              }
              style={{
                width: '40%',
                fontFamily: 'General Sans',
                fontSize: '1rem',
              }}
            />
          </Form>
        </CheckStyleContainer>
      </>
    );
  },
);

const CheckStyleContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  margin: 0 0.5rem;
  font-size: 16px;
  margin-bottom: 1rem;
  font-family: ${(props) => props.theme.fonts.GeneralSans};
`;

export default EventDetailsStepThree;
