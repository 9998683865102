import React, { useState, useEffect, ReactNode } from 'react';
import { StyleSheet } from 'react-native';
import styled from 'styled-components';
import config from '../../config';
import { uploadFile } from '../../utils/media';
import { UIStoreType } from '../../stores/ui';
import toast from 'react-hot-toast';

const Input = styled.input`
  width: 1px;
  height: 1px;
  opacity: 0;
  position: absolute;
  overflow: hidden;
  z-index: -1;
`;

const Label = styled.label`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
`;

interface P {
  children: ReactNode;
  style?: React.CSSProperties;
  onChangePicture: (url: string) => void;
  id?: any;
  picture?: string;
  disabled?: boolean;
  doNotShow?: boolean;
  placeholderComponent?: React.ReactNode;
  disableDelete?: boolean;
  ui?: UIStoreType;
  label?: string;
  toolTip?: string;
  outputType?: 'base64';
  onLoadingStateChange?: (isLoading: boolean) => void;
}

const ImagePickerHandler: React.FC<P> = (props: P) => {
  const [data, setData] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const id = `${new Date().getTime()}${Math.random()}`;

  useEffect(() => {
    _updatePicture();
  }, []);

  useEffect(() => {
    if (props.picture !== undefined && props.picture !== null) {
      _updatePicture();
    }
  }, [props.picture]);

  const _updatePicture = () => {
    if (props.doNotShow) {
      return;
    }

    if (props.picture) {
      const imageData = props.picture;
      if (imageData && typeof imageData !== 'string') {
        const reader = new FileReader();

        reader.onloadstart = () => setLoading(true);

        reader.onload = (event2: any) => {
          setData(event2.target.result);
          setLoading(false);
          if (props.onLoadingStateChange) {
            props.onLoadingStateChange(false);
          }
        };

        reader.readAsDataURL(imageData);
      } else {
        setData(imageData);
      }
    }
  };

  const _handleFileChange = (event: any) => {
    const { target } = event;
    const { files } = target;

    if (files && files[0]) {
      const reader = new FileReader();
      reader.onloadstart = () => {
        setLoading(true);
        if (props.onLoadingStateChange) {
          props.onLoadingStateChange(true);
        }
      };

      reader.onload = async (event2: any) => {
        // max 10mb
        if (files[0].size >= 6534037) {
          setLoading(false);
          if (props.onLoadingStateChange) {
            props.onLoadingStateChange(false);
          }
        } else {
          // @ts-ignore
          try {
            // @ts-ignore
            let extension = undefined;
            // @ts-ignore
            if (reader.result.includes('data:image/gif')) {
              extension = 'gif';
            }

            if (props.outputType === 'base64') {
              props.onChangePicture(reader.result as string);
            } else {
              const res = await uploadFile({ file: files[0] });

              if (res) {
                const url = res.replace(
                  'https://giglinked.s3.ca-central-1.amazonaws.com',
                  config.mediaBaseUrl,
                );
                props.onChangePicture(url);
              }

              setData(props.doNotShow ? null : res);
              setLoading(false);
            }

            if (props.onLoadingStateChange) {
              props.onLoadingStateChange(false);
            }
          } catch (e) {
            const err = e as Error;
            toast.error(err.message);
          }
        }
      };

      reader.readAsDataURL(files[0]);
    }
  };

  const _handlePreviewClick = () => {
    if (!data) {
      return;
    }
  };

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        position: 'relative',
      }}
    >
      <Input
        id={id}
        type="file"
        accept="image/jpg,image/png,image/JPEG"
        // capture="camera"
        onChange={_handleFileChange}
        disabled={props.disabled}
      />
      <Label
        htmlFor={id}
        style={
          props.disabled
            ? {
                cursor: 'normal',
              }
            : {
                cursor: 'pointer',
              }
        }
      />
      {props.children}
    </div>
  );
};

const styles = StyleSheet.create({
  main: {
    height: 50,
    width: 50,
    borderRadius: 50 / 2,

    'box-shadow': '0px 4px 4px rgba(0, 0, 0, 0.25)',

    borderWidth: 2,
    backgroundColor: '#f8f8f8',
    borderColor: '#fbfafa',
  },
  deleteButton: {
    position: 'absolute',
    right: 0,
    top: 0,
    cursor: 'pointer',
  },
});

export default ImagePickerHandler;
