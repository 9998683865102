import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { Box } from 'react-native-kondo';
import styled from 'styled-components';

import images from '../../constants/images';
import { UseUserStore } from '../../stores/user';
import ImagePickerHandler from '../common/ImagePickerHandler';
import toast from 'react-hot-toast';
import { replaceProfileImage } from '../../utils/media';

const SettingProfilePicture = observer(() => {
  const user = UseUserStore();
  const me = user.getMe();
  const [loadingProfilePicture, setLoadingProfilePicture] =
    React.useState(false);

  return (
    <Box style={{ alignSelf: 'center' }}>
      {me && (
        <ProfilePicture
          src={replaceProfileImage(me.profilePicture!)}
          style={{ opacity: loadingProfilePicture ? 0.8 : 1 }}
        />
      )}
      <EditProfilePictureButton setLoading={setLoadingProfilePicture} />
    </Box>
  );
});

const ProfilePicture = styled.img`
  width: 175px;
  height: 175px;
  border-radius: 87.5px;
  border: 1px solid #ffffff;
  box-sizing: border-box;
  object-fit: cover;
  @media (max-width: ${(props) => props.theme.breakPoints.mobile}px) {
    width: 112px;
    height: 112px;
    border-radius: 56px;
    margin-bottom: 20px;
  }
`;

const EditProfilePictureButton = ({
  setLoading,
}: {
  setLoading: (isLoading: boolean) => void;
}) => {
  const userStore = UseUserStore();

  const onChangePicture = async (picture: string) => {
    try {
      await userStore.editMe({
        profilePicture: picture,
      });
    } catch (e) {
      const err = e as Error;
      toast.error(err.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <EditProfilePictureContainer>
      <ImagePickerHandler
        onChangePicture={onChangePicture}
        onLoadingStateChange={() => setLoading(true)}
      >
        <EditProfilePictureIcon src={images.cameraIcon} />
      </ImagePickerHandler>
    </EditProfilePictureContainer>
  );
};

const EditProfilePictureIcon = styled.img`
  height: 22px;
  width: 22px;

  @media (max-width: ${(props) => props.theme.breakPoints.mobile}px) {
    width: 18px;
    height: 18px;
    border-radius: 9px;
  }
`;

const EditProfilePictureContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 37px;
  width: 37px;
  border-radius: 18.5px;
  position: absolute;
  left: 130px;
  top: 125px;
  cursor: pointer;
  background: linear-gradient(0deg, #f2f3f4, #f2f3f4), #000000;
  &:hover {
    filter: brightness(115%);
  }
  @media (max-width: ${(props) => props.theme.breakPoints.mobile}px) {
    width: 28px;
    height: 28px;
    left: 85px;
    top: 75px;
    border-radius: 14px;
  }
`;

export default SettingProfilePicture;
