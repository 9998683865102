import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { Box } from 'react-native-kondo';
import styled from 'styled-components';
import i18n from '../../../constants/i18n';
import { useUiStore } from '../../../stores/ui';
import ATag from '../ATag';
import { isEntityOwner } from './profileUtils';
import { TextH2, GeneralRegularNormal } from '../Typography';
import { Entity, EntityType } from '../../../shared';

const ProfileGallerySection = observer(
  ({
    entity,
    entityType,
    contentWidth,
  }: {
    entity: Entity;
    contentWidth: number;
    entityType: EntityType;
  }) => {
    const uiStore = useUiStore();
    const ratio = 0.5608;
    const imageWidth = contentWidth / 2 - 13;
    const imageHeight = imageWidth * ratio;

    if (!entity.gallery || !entity.gallery.length) {
      return null;
    }

    const isOwner = isEntityOwner({ entity, entityType });

    let href = `/me/artist/gallery`;
    if (entityType === 'act') {
      href = `/me/acts/${entity.id}/gallery`;
    }

    return (
      <Main>
        <Box
          style={{
            marginBottom: 15,
            justifyContent: 'space-between',
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          <TextH2>{i18n('ArtistGallerySection.title', 'Gallery')}</TextH2>
          {isOwner ? (
            <ATag href={href}>
              <GeneralRegularNormal
                style={{ textDecoration: 'underline', color: '#4361EE' }}
              >
                {i18n('ArtistGallerySection.editPhotos', 'Edit Photos')}
              </GeneralRegularNormal>
            </ATag>
          ) : null}
        </Box>

        <Row>
          {entity.gallery.slice(0, 4).map((url: string, index: number) => (
            <Picture
              key={`${url}-${index}`}
              src={url}
              style={{
                width: uiStore.isMobile ? '46%' : imageWidth,
                height: uiStore.isMobile ? undefined : imageHeight,
              }}
              onClick={() =>
                uiStore.openGalleryViewer({
                  pictures: entity.gallery,
                })
              }
            />
          ))}
        </Row>
      </Main>
    );
  },
);

const Main = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 80px;
  @media (max-width: ${(props) => props.theme.breakPoints.mobile}px) {
    margin-bottom: 50px;
  }
`;

const Row = styled.div`
  display: flex;
  flex-wrap: wrap;
  @media (max-width: ${(props) => props.theme.breakPoints.mobile}px) {
  }
`;

const Picture = styled.img`
  margin-right: 12px;
  margin-top: 12px;
  object-fit: cover;
  cursor: pointer;

  &:hover {
    filter: brightness(125%);
  }

  @media (max-width: ${(props) => props.theme.breakPoints.mobile}px) {
  }
`;

export default ProfileGallerySection;
