export default {
  'AboutJambassadorsSection.Team': {
    en: 'Meet the jambassadors',
    fr: 'Rencontrer le jambassadors',
  },
  'AboutJambassadorsSection.Description': {
    en: 'We get by with a little help from our friends.',
    fr: 'We get by with a little help from our friends.',
  },
  'AboutJambassadorsSection.description': {
    en: 'We get by with a little help from our friends.',
    fr: "Nous nous en sortons avec un peu d'aide de nos amis.",
  },
};
