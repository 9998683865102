import { observer } from 'mobx-react';
import * as React from 'react';
import sexpressClient from '../../utils/sexpress';
import toast from 'react-hot-toast';
import styled from 'styled-components';
import { GeneralMediumLarge, GeneralRegularNormal } from '../common/Typography';
import images from '../../constants/images';
import i18n from '../../constants/i18n';
import GiglinkedMusicianAutocomplete from '../common/GiglinkedMusicianAutocomplete';
import colors from '../../constants/colors';
import GiglinkedButton from '../common/GiglinkedButton';
import { useNavigate } from 'react-router-dom';
import { useUiStore } from '../../stores/ui';
import EditEventModal from './EditEventModal';
import EventProposals from '../eventProposals/EventProposals';
import PageLayoutFooter from '../common/PageLayoutFooter';
import { useEventStore } from '../../stores/event';
import { getEventColor, getEventStatus } from '../../utils/eventUtils';
import { UseUserStore } from '../../stores/user';
import { format } from 'date-fns';
import { EventProposalType, EventType } from '../../shared';
import { capitalize } from '../../utils/string';
import { isArrayEmpty } from '../../utils/arrays';
import { useEventProposalStore } from '../../stores/eventProposal';

const EventScreen = observer(() => {
  const eventProposalStore = useEventProposalStore();
  const navigate = useNavigate();
  const uiStore = useUiStore();
  const eventStore = useEventStore();
  const userStore = UseUserStore();
  const isFrench = userStore?.browserLanguage === 'fr';
  const { currentEvent } = eventStore;
  const url = window.location.pathname;
  const eventId = url.substring(url.lastIndexOf('/') + 1) as EventType['id'];
  const currentEventStatus = getEventStatus(currentEvent);
  const eventIsDone = currentEventStatus === 'completed';
  const [eventProposals, setEventProposals] = React.useState<
    Array<EventProposalType> | undefined
  >([]);

  React.useEffect(() => {
    const fetchMeEvent = async () => {
      if (eventId) {
        try {
          const res = await sexpressClient.fetchMeEvent({
            eventId,
          });

          eventStore.setCurrentEvent(res);
        } catch (e) {
          const err = e as Error;
          toast.error(err.message);
        }
      }
    };
    fetchMeEvent();
  }, []);

  React.useEffect(() => {
    const fetchMeEventProposals = async () => {
      try {
        const res = await eventProposalStore.fetchEventProposals({
          eventId,
        });

        setEventProposals(res.eventProposals);
      } catch (e) {
        const err = e as Error;
        toast.error(err.message);
      }
    };
    fetchMeEventProposals();
  }, [location.pathname]);

  return (
    <Main>
      {currentEvent && (
        <>
          <TopBanner>
            <TopColumn>
              <Title>{capitalize(currentEvent?.name)}</Title>
              <GeneralMediumLarge style={{ marginBottom: '0.5rem' }}>
                {currentEvent?.style ? currentEvent?.style + ' • ' : ''}
                {currentEvent?.theme ? currentEvent?.theme + ' • ' : ''}
                {format(
                  new Date(currentEvent!.eventDate),
                  isFrench ? 'y.dd.MM' : 'y.MM.dd',
                )}{' '}
                • {format(new Date(currentEvent!.startTime), 'hh:mm a')}
              </GeneralMediumLarge>
              <StatusContainer>
                <div
                  style={{
                    height: '1rem',
                    width: '1rem',
                    borderRadius: '50%',
                    background: getEventColor(currentEvent),
                    marginRight: '0.5rem',
                  }}
                />
                <GeneralMediumLarge>
                  {currentEventStatus && capitalize(currentEventStatus)}
                </GeneralMediumLarge>
              </StatusContainer>
            </TopColumn>
            <TopColumn
              style={{
                display: !uiStore.isMobile && !eventIsDone ? 'block' : 'none',
              }}
            >
              <ViewEditEventBtn
                onClick={() => {
                  uiStore.openPopup({
                    popupDisableTapOut: true,
                    renderContent: () => <EditEventModal />,
                  });
                }}
              >
                <img
                  src={images.editPen}
                  style={{
                    filter: 'invert(1)',
                    marginRight: '0.5rem',
                    height: '1.5rem',
                  }}
                />
                {i18n('EventScreen.editMeEvent', 'View/Edit event')}
              </ViewEditEventBtn>
            </TopColumn>
          </TopBanner>
          <MidSection>
            <GeneralRegularNormal
              style={{
                fontWeight: 600,
                color: colors.buttonTextColor,
                cursor: 'pointer',
              }}
              onClick={() => navigate(`/dashboard`)}
            >
              <img src={images.arrowLeft} style={{ height: '1rem' }} />
              {i18n('EventScreen.backToBooking', 'Back to bookings')}
            </GeneralRegularNormal>

            <GeneralMediumLarge
              style={{
                cursor: 'pointer',
                fontWeight: 600,
                color: colors.buttonTextColor,
                textAlign: 'right',
                display: uiStore.isMobile && !eventIsDone ? 'block' : 'none',
              }}
              onClick={() => {
                uiStore.openPopup({
                  popupDisableTapOut: true,
                  renderContent: () => <EditEventModal />,
                });
              }}
            >
              <img
                src={images.editPen}
                style={{
                  filter:
                    'invert(26%) sepia(42%) saturate(2076%) hue-rotate(224deg) brightness(93%) contrast(88%)',
                  marginRight: '0.5rem',
                }}
              />
              {i18n('EventScreen.editMeEvent', 'View/Edit event')}
            </GeneralMediumLarge>
          </MidSection>

          <MidSection>
            <GiglinkedMusicianAutocomplete />
          </MidSection>

          <EventProposals eventProposals={eventProposals} />

          {isArrayEmpty(eventProposals) && (
            <EmptyContainer>
              <GeneralRegularNormal>
                {i18n('EventScreen.noProposals', 'You have no proposals yet')}
              </GeneralRegularNormal>
              <GiglinkedButton
                style={{ maxWidth: '250px', marginTop: '1rem' }}
                label={i18n('EventScreen.browseMusicians', 'Browse musicians')}
                onClick={() => navigate(`/performers`)}
                disabled={false}
              />
            </EmptyContainer>
          )}
        </>
      )}
      <PageLayoutFooter />
    </Main>
  );
});

const Main = styled.div`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  flex: 1;
`;

const TopBanner = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  height: 178px;
  background: radial-gradient(
    77.01% 120% at 100% 50%,
    ${(props) => props.theme.colors.tabOrange} 0%,
    ${(props) => props.theme.colors.buttonTextColor} 82.6%
  );
  @media (max-width: ${(props) => props.theme.breakPoints.mobile}px) {
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
  }
`;

const TopColumn = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 80px;
  @media (max-width: ${(props) => props.theme.breakPoints.mobile}px) {
    padding: 1rem;
  }
`;

const ViewEditEventBtn = styled.span`
  font-size: 18px;
  font-family: ${(props) => props.theme.fonts.GeneralSans};
  font-weight: 500;
  color: ${(props) => props.theme.colors.whiteText};
  line-height: 24px;
  letter-spacing: 0em;
  cursor: pointer;
  &:hover {
    opacity: 0.8;
  }
  @media (max-width: ${(props) => props.theme.breakPoints.mobile}px) {
    font-size: 16px;
  }
`;

const Title = styled.div`
  font-size: 32px;
  margin-bottom: 0.5rem;
  font-weight: 500;
  color: ${(props) => props.theme.colors.white};
  font-family: ${(props) => props.theme.fonts.GeneralSans};
  @media (max-width: ${(props) => props.theme.breakPoints.mobile}px) {
    font-size: 24px;
  }
`;

const StatusContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 0.5rem;
`;

const MidSection = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 2rem;
  align-self: center;
  width: 1000px;
  @media (max-width: ${(props) => props.theme.breakPoints.mobile}px) {
    width: 100%;
    flex-direction: row;
    margin: 0;
    padding: 1rem;
    justify-content: space-between;
  }
`;

const EmptyContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-self: center;
  align-items: center;
  background: ${(props) => props.theme.colors.cardColor};
  border-radius: 20px;
  height: 190px;
  margin: 3rem 0;
  width: 1000px;
  @media (max-width: ${(props) => props.theme.breakPoints.mobile}px) {
    width: 100%;
  }
`;

export default EventScreen;
