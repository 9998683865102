import { observer } from 'mobx-react';
import * as React from 'react';
import { Box } from 'react-native-kondo';
import styled from 'styled-components';
import colors from '../../constants/colors';
import i18n from '../../constants/i18n';
import images from '../../constants/images';
import { useUiStore } from '../../stores/ui';
import { logUserSearchProfiles } from '../../utils/amplitude';
import { GeneralSemiBoldSmall } from '../common/Typography';

const PerformerBannerSearch = observer(
  ({
    keywords,
    location,
    onChangeKeywords,
    onChangeLocation,
    onPressSearch,
  }: {
    keywords: string;
    location: string;
    onChangeKeywords: (value: string) => void;
    onChangeLocation: (value: string) => void;
    onPressSearch: () => void;
  }) => {
    const uiStore = useUiStore();

    const [isSearchFocused, setIsSearchFocused] = React.useState(false);

    const ENTER_KEY = 'Enter';

    const onKeyEnterPressed = (event: KeyboardEvent) => {
      if (event.key === ENTER_KEY && isSearchFocused) {
        onPressSearch();
      }
    };

    React.useEffect(() => {
      document.addEventListener('keydown', onKeyEnterPressed);
      return () => document.removeEventListener('keydown', onKeyEnterPressed);
    }, [onKeyEnterPressed]);

    return (
      <Main>
        <LeftSection>
          <InputContainer
            label={i18n('PerformerBannerSearch.inputATitle', 'Keywords')}
            placeholder={'Ex: Rock Band, Guitar'}
            value={keywords}
            onChangeText={onChangeKeywords}
            onFocus={() => setIsSearchFocused(true)}
            onBlur={() => setIsSearchFocused(false)}
          />
          {uiStore.isMobile ? (
            <Box height={10} />
          ) : (
            <Box height={54} width={1} bg={'rgba(0, 0, 0, 0.25)'} mx={20} />
          )}
          <InputContainer
            label={i18n('PerformerBannerSearch.location', 'Location')}
            placeholder={'Ex: Montreal'}
            value={location}
            onChangeText={onChangeLocation}
            img={images.mapPin}
            onFocus={() => setIsSearchFocused(true)}
            onBlur={() => setIsSearchFocused(false)}
          />
        </LeftSection>
        <SearchButton
          onPressSearch={() => {
            logUserSearchProfiles({ keywords, location });
            onPressSearch();
          }}
        />
      </Main>
    );
  },
);

const SearchButton = ({ onPressSearch }: { onPressSearch: () => void }) => {
  return (
    <SearchButtonContainer onClick={onPressSearch}>
      <img src={images.searchIcon} />
    </SearchButtonContainer>
  );
};

const InputContainer = ({
  label,
  placeholder,
  value,
  img,
  onChangeText = () => null,
  onFocus,
  onBlur,
}: {
  label: string;
  placeholder: string;
  value: string;
  img?: string;
  onChangeText?: (value: string) => void;
  onFocus?: () => void;
  onBlur?: () => void;
}) => {
  return (
    <Box flex={1}>
      <GeneralSemiBoldSmall style={{ color: colors.black }}>
        {label}
      </GeneralSemiBoldSmall>
      <Box flexDirection="row" alignItems="center">
        {img ? (
          <img src={img} style={{ width: 19, height: 19, marginRight: 3 }} />
        ) : null}
        <Input
          placeholder={placeholder}
          value={value}
          onChange={(e) => onChangeText(e.target.value)}
          onFocus={onFocus}
          onBlur={onBlur}
        />
      </Box>
    </Box>
  );
};

const Input = styled.input`
  outline: none;
  border: none;
  @media (max-width: ${(props) => props.theme.breakPoints.mobile}px) {
  }
`;

const SearchButtonContainer = styled.div`
  display: flex;
  width: 58px;
  background-color: #df2647;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  &:hover {
    filter: brightness(115%);
  }
  @media (max-width: ${(props) => props.theme.breakPoints.mobile}px) {
  }
`;

const Main = styled.div`
  display: flex;
  width: 100%;
  max-width: 547px;
  min-height: 82px;
  align-self: center;
  z-index: 2;
  border-radius: 5px;
  overflow: hidden;
  background-color: white;
  @media (max-width: ${(props) => props.theme.breakPoints.mobile}px) {
  }
`;

const LeftSection = styled.div`
  display: flex;
  padding: 14px 20px;
  background-color: white;
  box-sizing: border-box;
  flex: 1;
  @media (max-width: ${(props) => props.theme.breakPoints.mobile}px) {
    flex-direction: column;
  }
`;

export default PerformerBannerSearch;
