import { observer } from 'mobx-react';
import * as React from 'react';
import { Box } from 'react-native-kondo';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';
import colors from '../../constants/colors';
import { useUiStore } from '../../stores/ui';
import ATag from './ATag';
import { TextH5 } from './Typography';

interface NavBarItem {
  label: string;
  route: string;
  hasNotification?: boolean;
}

const EditNavbar = ({ navBarItems }: { navBarItems: NavBarItem[] }) => {
  return (
    <Main>
      {navBarItems.map((navBarItem) => (
        <NavButton key={navBarItem.label} {...navBarItem} />
      ))}
    </Main>
  );
};

const NavButton = observer(({ label, route, hasNotification }: NavBarItem) => {
  const location = useLocation();
  const isSelected = location.pathname === route;
  const uiStore = useUiStore();
  return (
    <ATag href={route}>
      <ButtonContainer isActive={isSelected}>
        {uiStore.isMobile ? null : isSelected ? (
          <Box width={5} bg="#4361EE" mr={10} />
        ) : (
          <AnimatedDot />
        )}
        <TextH5
          style={{
            color: isSelected ? '#4361EE' : colors.black,
            fontSize: uiStore.isMobile ? 14 : 16,
            position: 'relative',
          }}
        >
          {label}
          {hasNotification && uiStore.isMobile ? <Dot /> : null}
        </TextH5>
      </ButtonContainer>
      {hasNotification && !uiStore.isMobile ? <Dot /> : null}
    </ATag>
  );
});

const AnimatedDot = styled.div`
  display: flex;
  box-sizing: border-box;
  cursor: pointer;
  margin-bottom: 36px;
  transition: opacity 0.9s ease;
  transition: transform 0.4s ease;
  background-color: #8ca0f8;
  width: 5px;
  height: 12px;
  position: absolute;
  left: -10px;
  top: 6px;
  opacity: 0;
  filter: brightness(115%);
  @media (max-width: ${(props) => props.theme.breakPoints.mobile}px) {
  }
`;

const ButtonContainer = styled.div<{ isActive: boolean }>`
  display: flex;
  box-sizing: border-box;
  cursor: pointer;
  margin-bottom: 36px;
  transition: transform 0.4s ease;

  &:hover {
    filter: brightness(115%);
    transform: ${(props) => (props.isActive ? '' : 'scale(1.01, 1.01)')};
    transform: ${(props) => (props.isActive ? '' : 'translateX(6px)')};
    span {
      color: #4361ee !important;
    }
    div {
      opacity: 1;
      transform: ${(props) => (props.isActive ? '' : 'translateX(-3px)')};
    }
  }
  @media (max-width: ${(props) => props.theme.breakPoints.mobile}px) {
    margin-bottom: 0px;
    &:hover {
      filter: brightness(115%);
      transform: ${(props) => (props.isActive ? '' : 'scale(1, 1)')};
      transform: ${(props) => (props.isActive ? '' : 'translateX(0px)')};
      span {
        color: #4361ee !important;
      }
      div {
        opacity: 1;
        transform: ${(props) => (props.isActive ? '' : 'translateX(0px)')};
      }
    }
  }
`;

const Main = styled.div`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  width: 234px;
  padding-top: 64px;
  padding-left: 50px;
  background-color: #f2f3f4;
  @media (max-width: ${(props) => props.theme.breakPoints.mobile}px) {
    flex-direction: row;
    justify-content: space-around;
    position: fixed;
    bottom: 0px;
    right: 0px;
    left: 0px;
    width: 100%;
    z-index: 1;
    padding-top: 30px;
    padding-bottom: 38px;
    padding-left: 0px;
  }
`;

const Dot = styled.span`
  position: absolute;
  margin-top: -50px;
  margin-left: 65px;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background-color: ${colors.red};
  opacity: 1 !important;
  @media (max-width: ${(props) => props.theme.breakPoints.mobile}px) {
    margin-top: -5px;
    margin-left: 0px;
  }
`;

export default EditNavbar;
