export default {
  'AddBankingInformationModal.bankingAdded': {
    en: "Success! Redirecting you to Stripe's secure page.",
    fr: 'Succès! Nous vous redirigeons vers la page sécurisée de Stripe.',
  },
  'AddBankingInformationModal.bankingInformation': {
    en: 'Banking Information',
    fr: 'Informations bancaires',
  },
  'AddBankingInformationModal.firstName': { en: 'First name', fr: 'Prénom' },
  'AddBankingInformationModal.lastName': {
    en: 'Last name',
    fr: 'Nom de famille',
  },
  'AddBankingInformationModal.transitNumber': {
    en: 'Transit Number (1)',
    fr: 'Numéro de transit (1)',
  },
  'AddBankingInformationModal.institutionNumber': {
    en: 'Institution Number (2)',
    fr: "Numéro de l'institution (2)",
  },
  'AddBankingInformationModal.accountNumber': {
    en: 'Account Number (3)',
    fr: 'Numéro de compte (3)',
  },
  'AddBankingInformationModal.cancel': { en: 'Cancel', fr: 'Annuler' },
};
