import { action, observable, toJS } from 'mobx';
import { create, persist } from 'mobx-persist';
import { useStores } from '.';
import { SignUpPayload, User, SignUpStep } from '../shared';
import { setAmplitudeUserIdentity } from '../utils/amplitude';
import { authGoogle, AuthResponse, refreshAuth, signup } from '../utils/api';
import sexpressClient from '../utils/sexpress';
import artist from './artist';
import messaging from './messaging';
import navigation from './navigation';

export interface UserStore {
  accessToken?: string;
  refreshToken?: string;
  loginWithGoogle: ({ accessToken }: { accessToken: string }) => Promise<void>;
  signup: (payload: SignUpPayload) => Promise<void>;
  refreshAuth: () => Promise<void>;
  browserLanguage: 'fr' | 'en';
  signUpStep: SignUpStep;
  setBrowerLanguage: (language: UserStore['browserLanguage']) => void;
  setSignUpStep: (signUpMode: UserStore['signUpStep']) => void;
  me?: User;
  fetchMe: () => Promise<void>;
  getMe: () => UserStore['me'];
  logOut: () => void;
  signin: (payload: { email: string; password: string }) => Promise<void>;
  editMe: (payload: Partial<User>) => Promise<void>;
  shapeUser: (payload: Partial<AuthResponse> & Partial<User>) => void;
  postAuthActions: () => Promise<void>;
}

class UserStr implements UserStore {
  @persist @observable browserLanguage: UserStore['browserLanguage'] =
    navigator && navigator.language && navigator.language.includes('fr')
      ? 'fr'
      : 'en';
  @persist @observable accessToken: UserStore['accessToken'];
  @persist @observable refreshToken: UserStore['refreshToken'];
  @observable me: UserStore['me'];
  @persist @observable signUpStep: UserStore['signUpStep'] =
    SignUpStep.RegistrationUser;

  getMe: UserStore['getMe'] = () => toJS(this.me);

  @action
  setBrowerLanguage: UserStore['setBrowerLanguage'] = (language) => {
    this.browserLanguage = language;
  };

  @action
  setSignUpStep: UserStore['setSignUpStep'] = (signUpMode) => {
    this.signUpStep = signUpMode;
  };

  @action
  refreshAuth: UserStore['refreshAuth'] = async () => {
    const res = await refreshAuth({
      refreshToken: this.refreshToken!,
    });
    this.shapeUser(res);
  };

  @action
  signup: UserStore['signup'] = async (payload) => {
    const res = await signup(payload);
    this.shapeUser(res);
    await this.postAuthActions();
  };

  @action
  signin: UserStore['signin'] = async (payload) => {
    const res = await sexpressClient.loginLocal(payload);
    this.shapeUser(res);
    await this.postAuthActions();
  };

  @action
  loginWithGoogle: UserStore['loginWithGoogle'] = async (payload) => {
    const res = await authGoogle(payload);
    this.shapeUser(res);
    await this.postAuthActions();
  };

  @action
  postAuthActions = async () => {
    await this.fetchMe();
    this.conversationsRoutine(); // TODO: re-evaluate the need to consistently be fetching
    // artist.maybeShowBankingReminder(); // TODO: re-evaluate and see if this is necessary at all
    setAmplitudeUserIdentity(this.me);
  };

  @action
  conversationsRoutine = async () => {
    try {
      await messaging.fetchConversations();
      setTimeout(this.conversationsRoutine, 1000 * 10);
    } catch (e) {
      //
    }
  };

  @action
  fetchMe: UserStore['fetchMe'] = async () => {
    const me = await sexpressClient.getMe();
    this.shapeUser(me);
    if (me.artist) {
      await artist.fetchMeArtist();
    }
  };

  @action
  shapeUser: UserStore['shapeUser'] = (payload) => {
    Object.keys(payload).forEach((key) => {
      if (key === 'accessToken') {
        this.accessToken = payload[key];
        window.localStorage.setItem('at', payload[key]!);
      } else if (key === 'refreshToken') {
        this.refreshToken = payload[key];
        window.localStorage.setItem('rft', payload[key]!);
      } else {
        if (!this.me) {
          this.me = {} as User;
        }
        // @ts-ignore
        this.me[key] = payload[key as keyof typeof payload];
      }
    });
  };

  @action
  editMe: UserStore['editMe'] = async (payload) => {
    this.shapeUser(payload);
    await sexpressClient.editMeUser(payload);
    this.fetchMe();
  };

  @action
  logOut: UserStore['logOut'] = () => {
    navigation.navigate?.('/');
    this.accessToken = undefined;
    this.refreshToken = undefined;
    this.me = undefined;
    window.localStorage.removeItem('at');
    window.localStorage.removeItem('rft');
  };
}

const hydrate = create({
  storage: localStorage,
});

const UserSaved = new UserStr();
hydrate('user', UserSaved);
export default UserSaved;

export function UseUserStore(): UserStore {
  const { user } = useStores();
  return user as UserStore;
}
