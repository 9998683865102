import * as React from 'react';
import styled from 'styled-components';
import i18n from '../../constants/i18n';
import { useUiStore } from '../../stores/ui';
import EventDetailsStepOne from './createEventSteps/EventDetailsStepOne';
import { EventType } from '../../shared';
import EventDetailsStepTwo from './createEventSteps/EventDetailsStepTwo';
import EventDetailsStepThree from './createEventSteps/EventDetailsStepThree';
import EventDetailsStepFour from './createEventSteps/EventDetailsStepFour';
import { observer } from 'mobx-react';
import { useEventStore } from '../../stores/event';
import toast from 'react-hot-toast';
import DeleteEventModal from './DeleteEventModal';
import GiglinkedButton from '../common/GiglinkedButton';
import images from '../../constants/images';
import { GeneralSemiBoldNormal, TextH4 } from '../common/Typography';
import colors from '../../constants/colors';

const EditEventModal = observer(() => {
  const uiStore = useUiStore();
  const eventStore = useEventStore();
  const { currentEvent } = eventStore;

  const [updatedEvent, setUpdatedEvent] = React.useState<EventType | undefined>(
    currentEvent,
  );

  return (
    <Main>
      <Close src={images.closeIconBlack} onClick={uiStore.closePopup} />
      <TextH4
        style={{
          marginBottom: '1rem',
          color: colors.black,
          alignSelf: 'center',
        }}
      >
        {i18n('EditEventModal.title', 'Edit event')}
      </TextH4>
      {updatedEvent && (
        <>
          <EventDetailsStepOne
            event={updatedEvent}
            setEvent={setUpdatedEvent}
          />

          <EventDetailsStepTwo
            event={updatedEvent}
            setEvent={setUpdatedEvent}
          />

          <EventDetailsStepThree
            event={updatedEvent}
            setEvent={setUpdatedEvent}
          />

          <EventDetailsStepFour
            event={updatedEvent}
            setEvent={setUpdatedEvent}
          />

          <GeneralSemiBoldNormal
            onClick={async () => {
              uiStore.closePopup();
              uiStore.openPopup({
                popupDisableTapOut: true,
                renderContent: () => <DeleteEventModal event={updatedEvent} />,
              });
            }}
            style={{ color: colors.red, cursor: 'pointer' }}
          >
            {i18n('EditEventModal.delete', 'Delete event')}
          </GeneralSemiBoldNormal>
        </>
      )}
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          width: '100%',
          marginTop: '1rem 0',
        }}
      >
        <GiglinkedButton
          label={i18n('EditEventModal.cancel', 'Cancel')}
          onClick={async () => {
            uiStore.closePopup();
          }}
          style={{ width: '110px', border: 'none', margin: '1rem' }}
        />
        <GiglinkedButton
          label={i18n('EditEventModal.save', 'Save')}
          onClick={async () => {
            try {
              if (currentEvent?.id) {
                delete updatedEvent?.invitedArtists;
                const res = await eventStore.editMeEvent({
                  ...updatedEvent,
                  eventId: currentEvent.id,
                });
                eventStore.setCurrentEvent(res);
                uiStore.closePopup();
                toast.success(
                  i18n(
                    'EditEventModal.eventSaveSuccessMessage',
                    'The event has been saved',
                  ),
                );
              }
            } catch (e) {
              const err = e as Error;
              toast.error(err.message);
            }
          }}
          style={{ width: '110px', margin: '1rem' }}
        />
      </div>
    </Main>
  );
});

const Main = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1rem 2rem;
  max-width: 800px;
  border-radius: 10px;
  background-color: white;
  @media (max-width: ${(props) => props.theme.breakPoints.mobile}px) {
    min-width: 100%;
    padding: 0.5rem;
  }
`;

const Close = styled.img`
  cursor: pointer;
  margin-left: auto;
  object-fit: contain;
  height: 12px;
`;

export default EditEventModal;
