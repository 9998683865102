import * as React from 'react';
import styled from 'styled-components';
import colors from '../../constants/colors';

const GiSelectableButton = ({
  label,
  style,
  onClick,
  selected,
}: {
  label: string;
  style?: React.CSSProperties;
  onClick: () => void;
  selected: boolean;
}) => {
  return (
    <StyledButton
      style={style}
      onClick={onClick}
      tabIndex={0}
      selected={selected}
    >
      {label}
    </StyledButton>
  );
};

const StyledButton = styled.button<{ selected: boolean }>`
  width: 110px;
  height: 40px;
  font-size: 14px;
  font-weight: 400;
  text-align: left;
  border-radius: 30px;
  text-align: center;
  margin: 5px;
  border: 1px solid
    ${(props) => (props.selected ? colors.yellow : colors.buttonBorder)};
  background: ${(props) =>
    props.selected ? colors.backgroundYellow : colors.white};
  color: ${(props) => (props.selected ? colors.yellow : colors.violet)};
  @media (max-width: ${(props) => props.theme.breakPoints.mobile}px) {
    font-size: 12px;
  }
  &:hover {
    border: 1px solid ${colors.yellow};
    color: ${colors.yellow};
    background: ${colors.white};
    @media (max-width: ${(props) => props.theme.breakPoints.mobile}px) {
      border: 1px solid
        ${(props) => (props.selected ? colors.yellow : colors.buttonBorder)};
      background: ${(props) =>
        props.selected ? colors.backgroundYellow : colors.white};
      color: ${(props) => (props.selected ? colors.yellow : colors.violet)};
    }
  }
`;

export default GiSelectableButton;
