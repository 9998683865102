import { observer } from 'mobx-react';
import * as React from 'react';
import { Box } from 'react-native-kondo';
import styled from 'styled-components';
import colors from '../../../constants/colors';
import i18n from '../../../constants/i18n';
import images from '../../../constants/images';
import { useUiStore } from '../../../stores/ui';
import { shorten } from '../../../utils/string';
import ATag from '../ATag';
import { isEntityOwner } from './profileUtils';
import { GeneralRegularNormal, TextH2, TextH3, TextH6 } from '../Typography';
import { Entity, EntityType } from '../../../shared';

const ProfileVideoSection = observer(
  ({ entity, entityType }: { entity: Entity; entityType: EntityType }) => {
    const [index, setIndex] = React.useState(0);
    const [autoPlay, setAutoPlay] = React.useState('');
    const uiStore = useUiStore();
    const scrollSpeed = React.useRef(2);
    const over = React.useRef(false);
    const scrollContainer = React.useRef<any>();

    const scrollRight = () => {
      const node = scrollContainer.current;
      if (over.current && node) {
        // @ts-ignore
        node.scrollLeft = node.scrollLeft + scrollSpeed.current;
        setTimeout(scrollRight, 1);
      }
    };

    const scrollLeft = () => {
      const node = scrollContainer.current;
      if (over.current && node) {
        // @ts-ignore
        node.scrollLeft = node.scrollLeft - scrollSpeed.current;
        setTimeout(scrollLeft, 1);
      }
    };

    const onMouseEnter = (func: any) => {
      if (!over.current) {
        over.current = true;
        func();
      }
    };

    const { videos } = entity;

    if (!videos || !videos.length) {
      return null;
    }

    const isOwner = isEntityOwner({
      entity,
      entityType,
    });

    let href = `/me/artist/videos`;
    if (entityType === 'act') {
      href = `/me/acts/${entity.id}/videos`;
    }

    return (
      <Main>
        <Box
          style={{
            marginBottom: 15,
            justifyContent: 'space-between',
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          <TextH2>{i18n('ArtistVideoSection.title', 'Videos')}</TextH2>
          {isOwner ? (
            <ATag href={href}>
              <GeneralRegularNormal
                style={{ textDecoration: 'underline', color: '#4361EE' }}
              >
                {i18n('ArtistVideoSection.editVideos', 'Edit Videos')}
              </GeneralRegularNormal>
            </ATag>
          ) : null}
        </Box>

        {videos[index] ? (
          <>
            <iframe
              width="100%"
              height={uiStore.innerHeight * (uiStore.isMobile ? 0.5 : 0.6)}
              src={`${videos[index].embedUrl}${autoPlay}`}
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            />
            <TitleSection>
              <TextH3 style={{ color: colors.black }}>
                {uiStore.isMobile
                  ? shorten(videos[index].title, 50)
                  : videos[index].title}
              </TextH3>
            </TitleSection>
          </>
        ) : null}
        <VideoRowContainer>
          <VideoRow ref={(ref: any) => (scrollContainer.current = ref)}>
            {videos.map((video: any, i: number) => (
              <VideoCell
                key={`${i}-video-${video.videoId}`}
                video={video}
                onClick={() => {
                  setIndex(i);
                  setAutoPlay('?autoplay=1');
                }}
                isSelected={i === index}
              />
            ))}
          </VideoRow>
          <ArrowContainer
            right={false}
            onMouseEnter={() => onMouseEnter(scrollLeft)}
            onMouseDown={() => {
              scrollSpeed.current = 10;
            }}
            onMouseUp={() => {
              scrollSpeed.current = 0;
            }}
            onMouseLeave={() => {
              over.current = false;
              scrollSpeed.current = 2;
            }}
          >
            <Arrow src={images.smallVideoLeftArrow} />
          </ArrowContainer>
          <ArrowContainer
            right={true}
            onMouseEnter={() => onMouseEnter(scrollRight)}
            onMouseDown={() => {
              scrollSpeed.current = 10;
            }}
            onMouseUp={() => {
              scrollSpeed.current = 0;
            }}
            onMouseLeave={() => {
              over.current = false;
              scrollSpeed.current = 2;
            }}
          >
            <Arrow src={images.smallVideoRightArrow} />
          </ArrowContainer>
        </VideoRowContainer>
      </Main>
    );
  },
);

const Arrow = styled.img`
  width: 11px;
  height: 11px;
`;

const ArrowContainer = styled.div<{ right?: boolean }>`
  display: flex;
  box-sizing: border-box;
  background-color: rgb(224, 224, 224);
  cursor: pointer;
  position: absolute;
  justify-content: center;
  align-items: center;
  right: ${(props) => (props.right ? 0 : '')};
  width: 20px;
  height: 118px;
  &:hover {
    filter: brightness(90%);
  }
  @media (max-width: ${(props) => props.theme.breakPoints.mobile}px) {
    display: none;
  }
`;

const VideoCell = ({
  video,
  onClick,
  isSelected,
}: {
  video: any;
  onClick: () => void;
  isSelected: boolean;
}) => {
  return (
    <VideoCellContainer onClick={onClick}>
      <VideoThumbnail src={video.thumbnail} isSelected={isSelected} />
      <TextH6 style={{ marginTop: 16 }}>{shorten(video.title, 25)}</TextH6>
      <PlayButton src={images.playButton} />
    </VideoCellContainer>
  );
};

const PlayButton = styled.img`
  width: 56px;
  height: 56px;
  position: absolute;
  top: 31px;
  left: 75px;
  cursor: pointer;
`;

const VideoRowContainer = styled.div`
  position: relative;
  display: flex;
  box-sizing: border-box;
  background-color: rgb(242, 243, 244);
  @media (max-width: ${(props) => props.theme.breakPoints.mobile}px) {
  }
`;

const VideoRow = styled.div`
  display: flex;
  box-sizing: border-box;
  overflow-x: auto;
  margin-left: 20px;
  margin-right: 20px;
  background-color: rgb(242, 243, 244);
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
    scrollbar-width: none;
  }
  @media (max-width: ${(props) => props.theme.breakPoints.mobile}px) {
    margin-left: 0px;
    margin-right: 0px;
    padding-left: 20px;
  }
`;

const VideoThumbnail = styled.img<{ isSelected: boolean }>`
  width: 209px;
  height: 118px;
  border-width: ${(props) => (props.isSelected ? 5 : 0)}px;
  border-color: rgb(69, 102, 234);
  border-style: solid;
  box-sizing: border-box;
  cursor: pointer;
  filter: ${(props) =>
    props.isSelected ? 'brightness(100%)' : 'brightness(60%)'};
  @media (max-width: ${(props) => props.theme.breakPoints.mobile}px) {
  }
`;

const VideoCellContainer = styled.div`
  width: 209px;
  display: flex;
  flex-direction: column;
  background-color: rgb(242, 243, 244);
  position: relative;
  margin-right: 10px;
  box-sizing: border-box;
  padding-bottom: 24px;

  @media (max-width: ${(props) => props.theme.breakPoints.mobile}px) {
  }
`;

const Main = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 80px;
  @media (max-width: ${(props) => props.theme.breakPoints.mobile}px) {
    margin-bottom: 50px;
  }
`;

const TitleSection = styled.div`
  display: flex;
  flex-direction: column;
  height: 66px;
  box-sizing: border-box;
  padding-left: 20px;
  padding-right: 20px;
  justify-content: center;
  background-color: rgb(242, 243, 244);
  @media (max-width: ${(props) => props.theme.breakPoints.mobile}px) {
  }
`;

export default ProfileVideoSection;
