import * as React from 'react';
import { Box } from 'react-native-kondo';
import styled from 'styled-components';
import colors from '../../constants/colors';
import { GeneralRegularNormal, TextH5, TextH7 } from '../common/Typography';
import { shorten as short, addCommaAndSpace } from '../../utils/string';
import { Entity, Tag } from '../../shared';
import { replaceProfileImage } from '../../utils/media';

export const calculateMaxTagSpace = ({
  tags,
  shortenValue = 0,
}: {
  tags: Entity['tags'];
  shortenValue?: number;
}) => {
  let tagsToShow = tags.slice(0, 3);
  let totalLengthTag = 0;

  tagsToShow.forEach((tag) => {
    totalLengthTag += short(tag.label, shortenValue).length;
  });

  if (totalLengthTag > 23) {
    tagsToShow = tags.slice(0, 2);
    totalLengthTag = 0;
  }
  return tagsToShow;
};

export const getShortenTagsToShow = (tag: Tag, shortenValue: number) => {
  return shortenValue ? short(tag.label, shortenValue) : tag.label;
};

const PerformerCell = ({ performer }: { performer: Entity }) => {
  const shortenValue = 10;
  const tagsToShow = calculateMaxTagSpace({
    tags: performer.tags,
    shortenValue,
  });
  return (
    <MainPerformerCellContainer>
      <Image src={replaceProfileImage(performer.profilePicture)} />
      <InfoSection>
        <TextH7 style={{ color: colors.black, marginBottom: 0 }}>
          {short(performer.name, 22)}
        </TextH7>
        <GeneralRegularNormal>{performer.location}</GeneralRegularNormal>
        {
          <Box flexDirection="row">
            {tagsToShow.slice(0, 3).map((tag, index) => (
              <TextH5 key={tag.id}>
                {addCommaAndSpace(index)}
                {getShortenTagsToShow(tag, shortenValue)}
              </TextH5>
            ))}
          </Box>
        }
      </InfoSection>
    </MainPerformerCellContainer>
  );
};

const MainPerformerCellContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
`;

const InfoSection = styled.div`
  display: flex;
  flex-direction: column;
  &:hover {
    opacity: 0.5;
  }
`;

const Image = styled.img`
  height: 300px;
  width: 300px;
  margin-bottom: 13px;
  object-fit: cover;
  border-radius: 5%;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  &:hover {
    border-top-left-radius: 5%;
    border-bottom-left-radius: 5%;
    animation-duration: 0.25s;
    border-left: 8px solid ${colors.red};
    box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
  }
`;

export default PerformerCell;
