import { observer } from 'mobx-react';
import * as React from 'react';
import styled from 'styled-components';
import colors from '../../../constants/colors';
import i18n from '../../../constants/i18n';
import { UseUserStore } from '../../../stores/user';
import { GeneralRegularSmall, InputLabel } from '../../common/Typography';
import { SignUpStep } from '../../../shared';
import { validateEmail, validatePassword } from '../../../utils/validator';
import { Box } from 'react-native-kondo';
import GiglinkedTextInput from '../../common/GiglinkedTextInput';
import GiglinkedButton from '../../common/GiglinkedButton';
import { GoogleAuthButton } from '../../common/SocialButton';
import { subToNewsLetter } from '../../../utils/api';
import { logUserSignUp } from '../../../utils/amplitude';
import { useNavigate } from 'react-router-dom';
import GiAuthContainer from '../../common/GiAuthContainer';
import GiSeparator from '../../common/GiSeparator';
import { useUiStore } from '../../../stores/ui';
import toast from 'react-hot-toast';

const RegistrationUser = observer(() => {
  const userStore = UseUserStore();
  const navigate = useNavigate();
  const { setSignUpStep } = userStore;
  const uiStore = useUiStore();
  const [loading, setLoading] = React.useState(false);
  const [email, setEmail] = React.useState('');
  const [password, setPassword] = React.useState('');
  const [confirmPassword, setConfirmPassword] = React.useState('');

  const validations = {
    email: validateEmail(email),
    password: validatePassword(password),
    confirmPassword:
      validatePassword(confirmPassword) && confirmPassword === password,
  };

  const me = userStore.getMe();
  const isLoggedIn = !!me;

  const onClickSignUp = async () => {
    try {
      if (isLoggedIn) {
        throw new Error(
          i18n(
            'RegistrationUser.userLoggedIn',
            'You are logged in! Log out and try again.',
          ),
        );
      }
      setLoading(true);
      await userStore.signup({
        email,
        password,
        confirmPassword,
      });
      await onSignupCompleted();
    } catch (e) {
      const err = e as Error;
      toast.error(err.message);
    } finally {
      setLoading(false);
    }
  };

  const onSignupCompleted = async () => {
    logUserSignUp();
    if (userStore.me! && userStore.me!.artist) {
      navigate(`/artists/${userStore.me!.artist.id}`);
      setSignUpStep(SignUpStep.RegistrationUser);
    } else {
      await subToNewsLetter({ email: userStore.me!.email }); // we decided that newsletter sign up happens automatically
      setSignUpStep(SignUpStep.RegistrationMusicianOrClient);
    }
  };

  return (
    <GiAuthContainer
      title={i18n('RegistrationController.title', 'Welcome to GigLinked!')}
      secondaryText={i18n(
        'RegistrationController.createYourAccount',
        'Create your account',
      )}
      tertiaryText={
        <>
          {i18n('RegistrationController.haveAccount', 'Have an account? ')}
          <StyledATag href={'/signin'} style={{ marginLeft: '1rem' }}>
            {i18n('RegistrationController.login', 'Log in')}
          </StyledATag>
        </>
      }
      editableContent={
        <>
          <Box style={{ width: '100%' }}>
            <InputLabel htmlFor="email">
              {i18n('RegistrationController.email', 'Email')}
            </InputLabel>
            <GiglinkedTextInput
              type="email"
              value={email}
              onChangeText={setEmail}
              showCheckMark={validations.email}
            />

            <InputLabel htmlFor="sign-up-password">
              {i18n('RegistrationController.password', 'Password')}
            </InputLabel>
            <GiglinkedTextInput
              type="password"
              value={password}
              onChangeText={setPassword}
              showCheckMark={validations.password}
            />

            <InputLabel htmlFor="sign-up-password">
              {i18n(
                'RegistrationController.confirmPassword',
                'Confirm Password',
              )}
            </InputLabel>
            <GiglinkedTextInput
              type="password"
              value={confirmPassword}
              onChangeText={setConfirmPassword}
              showCheckMark={validations.confirmPassword}
            />
          </Box>
          {!uiStore.isMobile && (
            <GeneralRegularSmall>
              {i18n(
                'RegistrationController.termsAndConditionsDesc',
                "By clicking Create account, you agree to GigLinked's Terms and Conditions and confirm you have read our Privacy Policy. You may receive offers, news and updates from us.",
              )}
            </GeneralRegularSmall>
          )}

          <Box style={{ marginTop: '1rem', width: '100%' }}>
            <GiglinkedButton
              style={{ width: '100%' }}
              onClick={onClickSignUp}
              label={i18n(
                'RegistrationController.buttonCreateAccount',
                'Create account',
              )}
              loading={loading}
              disabled={
                !validations.email ||
                !validations.password ||
                !validations.confirmPassword
              }
            />
            <GiSeparator style={{ margin: '1rem 0' }} />
            <GoogleAuthButton onLogin={onSignupCompleted} signUp={true} />
          </Box>
        </>
      }
    />
  );
});

const StyledATag = styled.a`
  display: flex;
  justify-content: flex-start;
  text-decoration: underline;
  color: ${colors.white};
  @media (max-width: ${(props) => props.theme.breakPoints.mobile}px) {
    font-size: 12px;
    align-items: center;
  }
  &:hover {
    cursor: pointer;
  }
`;

export default RegistrationUser;
