export default {
  'EventScreen.open': { en: 'Open', fr: 'Open' },
  'EventScreen.recommended': { en: 'Recommended', fr: 'Recommandé' },
  'EventScreen.editMeEvent': {
    en: 'View/Edit event',
    fr: "Modifier l'événement",
  },
  'EventScreen.backToBooking': {
    en: 'Back to bookings',
    fr: 'Retour au tableau de bord',
  },
  'EventScreen.noProposals': {
    en: 'You have no proposals yet',
    fr: "Vous n'avez pas encore de propositions",
  },
  'EventScreen.browseMusicians': {
    en: 'Browse musicians',
    fr: 'Parcourir les musiciens',
  },
};
