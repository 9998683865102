import { observer } from 'mobx-react';
import * as React from 'react';
import { SignUpStep } from '../../../../shared';
import { UseUserStore } from '../../../../stores/user';
import RegistrationMusicianOrClient from '../RegistrationMusicianOrClient';
import RegistrationMusicianSoloOrGroup from './registrationMusician/RegistrationMusicianSoloOrGroup';
import RegistrationBookerTellAboutYourself from './registrationBooker/RegistrationBookerTellAboutYourself';
import RegistrationBookerWaysToBook from './registrationBooker/RegistrationBookerWaysToBook';
import RegistrationStepper from './registrationMusician/RegistrationStepper';
import RegistrationUser from '../RegistrationUser';
import RegistrationBookerLocation from './registrationBooker/RegistrationBookerLocation';

const RegistrationController = observer(() => {
  const userStore = UseUserStore();
  const { signUpStep } = userStore;

  const getRenderedContent = () => {
    switch (signUpStep) {
      case SignUpStep.RegistrationUser:
        return <RegistrationUser />;
      case SignUpStep.RegistrationMusicianOrClient:
        return <RegistrationMusicianOrClient />;
      case SignUpStep.RegistrationMusicianSoloOrGroup:
        return <RegistrationMusicianSoloOrGroup />;
      case SignUpStep.RegistrationBookerTellAboutYourself:
        return <RegistrationBookerTellAboutYourself />;
      case SignUpStep.RegistrationBookerLocation:
        return <RegistrationBookerLocation />;
      case SignUpStep.RegistrationBookerWaysToBook:
        return <RegistrationBookerWaysToBook />;
      default:
        return <RegistrationStepper />;
    }
  };

  return getRenderedContent();
});

export default RegistrationController;
