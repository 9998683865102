export default {
  'StepperRegistrationEventTypes.wedding': {
    en: 'Wedding planner',
    fr: 'Planificateur de mariages',
  },
  'StepperRegistrationEventTypes.networking': {
    en: 'Networking event',
    fr: 'vénement de réseautage',
  },
  'StepperRegistrationEventTypes.anniversary': {
    en: 'Anniversary',
    fr: 'Anniversaire',
  },
  'StepperRegistrationEventTypes.holidayParty': {
    en: 'Holiday party',
    fr: 'Fête thématique',
  },
  'StepperRegistrationEventTypes.fundraiser': {
    en: 'Fundraiser',
    fr: 'Collecte de fonds',
  },
  'StepperRegistrationEventTypes.funerals': {
    en: 'Funerals',
    fr: 'Funérailles',
  },
  'StepperRegistrationEventTypes.marriageProposal': {
    en: 'Marriage proposal',
    fr: 'Demande en mariage',
  },
  'StepperRegistrationEventTypes.cocktail': { en: 'Cocktail', fr: 'Cocktail' },
  'StepperRegistrationEventTypes.houseParty': {
    en: 'House party',
    fr: 'Fête privée',
  },
  'StepperRegistrationEventTypes.barMitzvah': {
    en: 'Bar / Bat Mitzvah',
    fr: 'Bar / Bat Mitzvah(s)',
  },
  'StepperRegistrationEventTypes.sportingEvent': {
    en: 'Sporting event',
    fr: 'Événement sportif',
  },
  'StepperRegistrationEventTypes.other': { en: 'Other', fr: 'Autre' },
  'StepperRegistrationEventTypes.title': {
    en: 'Select the types of events you want to play:',
    fr: 'Sélectionnez les événements que vous souhaitez jouer:',
  },
};
