import { action, observable } from 'mobx';
import { useStores } from '.';
import {
  Act,
  Artist,
  DresscodeValues,
  EstimatedAudienceValues,
  EventTypeValues,
  DRESSCODE_TYPES,
  EVENT_TYPES,
} from '../shared';
import sexpressClient from '../utils/sexpress';

export interface BookingStoreType {
  eventType: EventTypeValues;
  date?: Date;
  startTime?: Date;
  endTime?: Date;
  isOutDoor?: boolean;
  isStageCovered?: boolean;
  setupTime: number;
  message: string;
  dresscode: DresscodeValues;
  creditCardData?: any;
  artistId?: Artist['id'];
  actId?: Act['id'];
  locationAddress: string;
  estimatedAudience: EstimatedAudienceValues;
  venueName: string;
  city: string;
  postalCode: string;
  province: string;
  country: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;

  setBookingData: (payload: Partial<BookingStoreType>) => void;
  createBookingTransaction: () => Promise<void>;
  resetBookingData: () => void;
}

class BookingStore {
  @observable eventType: BookingStoreType['eventType'] = EVENT_TYPES[0].value;
  @observable date: BookingStoreType['date'];
  @observable startTime: BookingStoreType['startTime'];
  @observable endTime: BookingStoreType['endTime'];
  @observable isOutDoor: BookingStoreType['isOutDoor'];
  @observable isStageCovered: BookingStoreType['isStageCovered'];
  @observable setupTime: BookingStoreType['setupTime'] = 0;
  @observable message: BookingStoreType['message'] = '';
  @observable dresscode: BookingStoreType['dresscode'] =
    DRESSCODE_TYPES[0].value;
  @observable creditCardData: BookingStoreType['creditCardData'];
  @observable locationAddress: BookingStoreType['locationAddress'] = '';
  @observable estimatedAudience: BookingStoreType['estimatedAudience'] = '1-50';
  @observable venueName: BookingStoreType['venueName'] = '';
  @observable city: BookingStoreType['city'] = '';
  @observable postalCode: BookingStoreType['postalCode'] = '';
  @observable province: BookingStoreType['province'] = 'qc';
  @observable country: BookingStoreType['country'] = 'canada';
  @observable firstName: BookingStoreType['firstName'] = '';
  @observable lastName: BookingStoreType['lastName'] = '';
  @observable phoneNumber: BookingStoreType['phoneNumber'] = '';
  @observable artistId: BookingStoreType['artistId'];
  @observable actId: BookingStoreType['actId'];

  @action
  createBookingTransaction: BookingStoreType['createBookingTransaction'] = async () => {
    await sexpressClient.createBookingTransaction({
      endTime: this.endTime!.toISOString(),
      eventDate: this.date!.toISOString(),
      isOutDoor: this.isOutDoor!,
      isStageCovered: this.isStageCovered!,
      message: this.message,
      setupTime: this.setupTime,
      startTime: this.startTime!.toISOString(),
      actId: this.actId,
      artistId: this.artistId,
      locationAddress: this.locationAddress,
      estimatedAudience: this.estimatedAudience,
      venueName: this.venueName,
      city: this.city,
      postalCode: this.postalCode,
      province: this.province,
      country: this.country,
      contactFirstName: this.firstName,
      contactLastName: this.lastName,
      contactPhone: this.phoneNumber,
      eventType: this.eventType,
    });
  };

  @action
  setBookingData: BookingStoreType['setBookingData'] = (payload) => {
    Object.keys(payload).forEach((key) => {
      // @ts-ignore
      this[key] = payload[key];
    });
  };

  @action
  resetBookingData: BookingStoreType['resetBookingData'] = () => {
    this.eventType = EVENT_TYPES[0].value;
    this.date = undefined;
    this.startTime = undefined;
    this.endTime = undefined;
    this.isOutDoor = undefined;
    this.isStageCovered = undefined;
    this.setupTime = 0;
    this.message = '';
    this.dresscode = DRESSCODE_TYPES[0].value;
    this.creditCardData = undefined;
    this.locationAddress = '';
    this.estimatedAudience = '1-50';
    this.venueName = '';
    this.city = '';
    this.postalCode = '';
    this.province = 'qc';
    this.country = 'canada';
    this.firstName = '';
    this.lastName = '';
    this.phoneNumber = '';
  };
}

export default new BookingStore();

export function useBookingStore(): BookingStoreType {
  const { booking } = useStores();
  return booking as BookingStoreType;
}
