import * as React from 'react';
import styled from 'styled-components';
import colors from '../../constants/colors';
import i18n from '../../constants/i18n';
import { useUiStore } from '../../stores/ui';
import GiButton from './GiButton';

import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import { GeneralRegularNormal } from './Typography';

const ModalImageCropper = ({
  imageUrl,
  onPressSave,
  aspect,
}: {
  imageUrl: string;
  onPressSave: (imageB64: string) => void;
  aspect?: number;
}) => {
  const uiStore = useUiStore();
  const [crop, setCrop] = React.useState<any>({ aspect: aspect || 1 });
  const [image, setImage] = React.useState<any>(imageUrl);

  const getCroppedImg = async () => {
    try {
      const canvas = document.createElement('canvas');
      const scaleX = image!.naturalWidth / image!.width;
      const scaleY = image!.naturalHeight / image!.height;
      canvas.width = crop.width;
      canvas.height = crop.height;
      const ctx = canvas.getContext('2d');

      ctx!.drawImage(
        image!,
        crop.x * scaleX,
        crop.y * scaleY,
        crop.width * scaleX,
        crop.height * scaleY,
        0,
        0,
        crop.width,
        crop.height,
      );

      const base64Image = canvas.toDataURL('image/jpeg', 1);
      return base64Image;
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <Main>
      <ReactCrop
        src={imageUrl}
        onImageLoaded={setImage}
        crop={crop}
        onChange={setCrop}
        ruleOfThirds
        crossorigin={'anonymous'}
      />

      <GeneralRegularNormal
        style={{
          marginTop: 20,
          marginBottom: 20,
          color: colors.black,
          fontWeight: 500,
        }}
      >
        {i18n(
          'ModalImageCropper.pressAndDragToSelectArea',
          'Press and drag to select area',
        )}
      </GeneralRegularNormal>
      <GiButton
        label={i18n('ModalImageCropper.save', 'Save')}
        onClick={async () => {
          const result = await getCroppedImg();
          if (result) {
            onPressSave(result);
          }
          uiStore.closePopup();
        }}
        disabled={!image}
      />
    </Main>
  );
};

const Main = styled.div`
  display: flex;
  flex-direction: column;
  margin: 20px 0px;
  padding: 20px;
  max-width: 800px;
  align-self: center;
  align-items: center;
  border: 2px solid ${colors.lightPurple};
  border-radius: 10px;
  background-color: white;
  @media (max-width: ${(props) => props.theme.breakPoints.mobile}px) {
    max-width: 600px;
  }
`;

export default ModalImageCropper;
