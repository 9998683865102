export default {
  'AddBillingMethodModal.cardAdded': {
    en: 'You added a new card',
    fr: 'Vous avez ajouté une nouvelle carte',
  },
  'AddBillingMethodModal.selectPayment': {
    en: 'Select the payment method',
    fr: 'Sélectionner le mode de paiement',
  },
  'AddBillingMethodModal.cardInformation': {
    en: 'Card Information',
    fr: 'Informations sur la carte',
  },
  'AddBillingMethodModal.EXP': { en: 'EXP', fr: 'EXP' },
  'AddBillingMethodModal.expPlaceholder': { en: 'MM/YY', fr: 'MM/AA' },
  'AddBillingMethodModal.CVC': { en: 'CVC', fr: 'CVC' },
  'AddBillingMethodModal.nameOnTheCard': {
    en: 'Name on the card',
    fr: 'Nom sur la carte',
  },
  'AddBillingMethodModal.nameOnTheCardPlaceholder': {
    en: 'Enter your name',
    fr: 'Entrez votre nom',
  },
  'AddBillingMethodModal.cancel': { en: 'Cancel', fr: 'Annuler' },
  'AddBillingMethodModal.continue': { en: 'Continue', fr: 'Continuer' },
};
