import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { Box } from 'react-native-kondo';
import styled from 'styled-components';
import i18n from '../../constants/i18n';
import images from '../../constants/images';
import { useUiStore } from '../../stores/ui';
import { GeneralRegularNormal } from '../common/Typography';
import GiTextInput from '../common/GiGoTextInput';
import urls from '../../utils/urls';
import ATag from '../common/ATag';
import { UseUserStore } from '../../stores/user';
import * as api from '../../utils/api';
import colors from '../../constants/colors';
import { useNavigate } from 'react-router-dom';

const LandingFooter = observer(() => {
  const uiStore = useUiStore();
  const userStore = UseUserStore();

  const [email, setEmail] = React.useState('');
  const [placeholder, setPlaceholder] = React.useState(
    i18n('LandingFooter.placeholder', 'Email'),
  );

  const onPressSub = async () => {
    if (email.length) {
      try {
        await api.subToNewsLetter({
          email,
        });
        setPlaceholder(
          i18n(
            'LandingFooter.placeholderSuccess',
            'Welcome to the GigLinked community!',
          ),
        );
        setEmail('');
      } catch (e) {
        console.log(e);
      }
    }
  };

  const Contact = () => {
    return (
      <VerticalContainer>
        <GeneralRegular style={{ color: colors.yellow, cursor: 'default' }}>
          {i18n('LandingFooter.contacts', 'Contacts')}
        </GeneralRegular>
        <GeneralRegular>{urls.email}</GeneralRegular>
      </VerticalContainer>
    );
  };

  const QuickLinks = () => {
    const navigate = useNavigate();

    return (
      <VerticalContainer>
        <GeneralRegular style={{ color: colors.yellow, cursor: 'default' }}>
          {i18n('LandingFooter.quickLinks', 'Quick links')}
        </GeneralRegular>

        <GeneralRegular
          onClick={() => {
            navigate('/about');
          }}
        >
          {i18n('NavBarContent.about', 'About')}
        </GeneralRegular>
        <GeneralRegular
          onClick={() => {
            navigate('/howitworks');
          }}
        >
          {i18n('NavBar.howItWorks', 'How it works')}
        </GeneralRegular>

        <GeneralRegular
          onClick={() => {
            navigate(
              `/performers?page=${encodeURIComponent(1)}&keywords=&location=`,
            );
          }}
        >
          {i18n('NavBar.browseMusicians', 'Browse musicians')}
        </GeneralRegular>

        <ATag target="_blank" href={urls.blogUrl}>
          <GeneralRegular>
            {i18n('PageLayoutFooter.blog', 'Blog')}
          </GeneralRegular>
        </ATag>
      </VerticalContainer>
    );
  };

  const FollowUs = () => {
    return (
      <VerticalContainer>
        <GeneralRegular style={{ color: colors.yellow, cursor: 'default' }}>
          {i18n('LandingFooter.followUs', 'Follow us')}
        </GeneralRegular>
        <Box flexDirection="row">
          <ATag target="_blank" href={urls.instagram}>
            <SocialLogo src={images.socialInsta} />
          </ATag>
          <ATag target="_blank" href={urls.facebook}>
            <SocialLogo src={images.socialFb} />
          </ATag>
          <ATag target="_blank" href={urls.twitter}>
            <SocialLogo src={images.socialTwitter} />
          </ATag>
          <ATag target="_blank" href={urls.linkedin}>
            <SocialLogo src={images.socialLinkedIn} />
          </ATag>
        </Box>
      </VerticalContainer>
    );
  };

  return (
    <Main>
      <FooterConteiner>
        <TopContainer>
          <Logo
            src={
              uiStore.isMobile
                ? images.giglinkedLogoWhite
                : uiStore.isTablet
                ? images.giglinkedRedNoteLogo
                : images.giglinkedLogoWhite
            }
          />
          <Contact />
          <QuickLinks />
          <FollowUs />
          <NewsVerticalContainer>
            <GeneralRegular style={{ color: colors.yellow, cursor: 'default' }}>
              {i18n('LandingFooter.newsLetter', 'Sign up for our newsletter')}
            </GeneralRegular>
            <GiTextInput
              placeholder={placeholder}
              placeholderColor="rgba(0, 0, 0, 0.6)"
              actionColor={'#000000'}
              onClickGo={onPressSub}
              onChangeText={setEmail}
              value={email}
            />
          </NewsVerticalContainer>
        </TopContainer>
        <SubFooter>
          <GeneralRegularNormal
            style={{
              marginBottom: uiStore.isMobile ? 10 : 0,
              color: colors.whiteText,
            }}
          >
            © GigLinked {new Date().getFullYear()}
          </GeneralRegularNormal>
          <ATag target="_blank" href={'/terms'}>
            <GeneralRegularNormal
              style={{
                textDecoration: 'underline',
                marginBottom: uiStore.isMobile ? 10 : 0,
                color: colors.whiteText,
              }}
            >
              {i18n('LandingFooter.terms', 'Terms and Conditions')}
            </GeneralRegularNormal>
          </ATag>
          <ATag target="_blank" href={'/privacy'}>
            <GeneralRegularNormal
              style={{
                textDecoration: 'underline',
                marginBottom: uiStore.isMobile ? 10 : 0,
                color: colors.whiteText,
              }}
            >
              {i18n('LandingFooter.policy', 'Privacy Policy')}
            </GeneralRegularNormal>
          </ATag>
          <GeneralRegularNormal
            style={{ color: colors.whiteText, cursor: 'pointer' }}
            onClick={() =>
              userStore.setBrowerLanguage(
                userStore?.browserLanguage === 'fr' ? 'en' : 'fr',
              )
            }
          >
            {userStore?.browserLanguage === 'fr' ? 'English' : 'Français'}
          </GeneralRegularNormal>
        </SubFooter>
      </FooterConteiner>
    </Main>
  );
});

const SocialLogo = styled.img`
  width: 15px;
  height: 15px;
  margin-right: 10px;
  filter: invert(99%) sepia(0%) saturate(2%) hue-rotate(38deg) brightness(116%)
    contrast(100%);
`;

const Logo = styled.img`
  width: 154px;
  height: 36px;
  @media (max-width: ${(props) => props.theme.breakPoints.tablet}px) {
    margin-top: 8px;
    height: 39px;
    width: 39px;
  }
  @media (max-width: ${(props) => props.theme.breakPoints.mobile}px) {
    width: 154px;
    height: 36px;
    margin-bottom: 30px;
  }
`;

const VerticalContainer = styled.div`
  display: flex;
  flex-direction: column;
  @media (max-width: ${(props) => props.theme.breakPoints.mobile}px) {
    align-items: center;
    margin-bottom: 39px;
  }
`;

const NewsVerticalContainer = styled.div`
width: 328px;
display: flex;
flex-direction: column;
@media (max-width: ${(props) => props.theme.breakPoints.tablet}px) {
  width: 30%;
}
@media (max-width: ${(props) => props.theme.breakPoints.mobile}px) {
  width: 100%;
  align-items: center;
  margin-bottom: 39px;
`;

const TopContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 70px;
  @media (max-width: ${(props) => props.theme.breakPoints.mobile}px) {
    flex-direction: column;
    align-items: center;
    margin-bottom: 0px;
  }
`;

const Main = styled.div`
  display: flex;
  justify-content: space-around;
  width: 100%;
  background: linear-gradient(332.33deg, #100029 21.79%, #210154 100%);
`;

const FooterConteiner = styled.div`
  width: 100%;
  max-width: 1440px;
  padding: 55px 80px 23px;
  display: flex;
  flex-direction: column;
  @media (max-width: ${(props) => props.theme.breakPoints.tablet}px) {
    padding: 55px 30px 0;
  }
`;

const SubFooter = styled.div`
  display: flex;
  flex-direction: row;
  border-width: 1px 0px 0px 0px;
  border-color: ${(props) => props.theme.colors.whiteText};
  border-style: solid;
  padding: 10px 0px 0px 0px;
  justify-content: space-between;
  @media (max-width: ${(props) => props.theme.breakPoints.mobile}px) {
    flex-direction: column;
    padding: 10px 25px 50px 25px;
    justify-content: flex-start;
  }
`;

const GeneralRegular = styled.span`
  font-size: 18px;
  font-family: ${(props) => props.theme.fonts.GeneralSans};
  font-weight: 400;
  color: ${(props) => props.theme.colors.whiteText};
  margin-bottom: 20px;
  cursor: pointer;
`;

export default LandingFooter;
