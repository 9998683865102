/* eslint-disable react/prop-types */
import * as React from 'react';
import styled from 'styled-components';

import { GeneralRegularNormal } from './Typography';
import Dropdown from 'react-bootstrap/Dropdown';
import images from '../../constants/images';
import i18n from '../../constants/i18n';
import { Column } from './Layout';

// The forwardRef is important!!
// Dropdown needs access to the DOM node in order to position the Menu
// @ts-ignore
const CustomToggle = React.forwardRef<any>(
  // @ts-ignore
  ({ children, onClick, disabled, active }, ref) => (
    <ToggleContainer
      // bootstrap stuff
      // @ts-ignore
      ref={ref}
      disabled={disabled}
      active={active}
      onClick={(e) => {
        if (!disabled) {
          e.preventDefault();
          onClick(e);
        }
      }}
    >
      <GeneralRegularNormal
        style={{
          textTransform: 'capitalize',
        }}
      >
        {children ? children : i18n('SharedTypes.select', 'Select')}
      </GeneralRegularNormal>
      <DownArrow src={images.downArrow} />
    </ToggleContainer>
  ),
);

// The forwardRef is important!!
// Dropdown needs access to the DOM node in order to position the Menu

// @ts-ignore
const CustomDropDownItem = React.forwardRef(({ children, onClick }, ref) => (
  <DropDownItemContainer
    // bootstrap stuff
    // @ts-ignore
    ref={ref}
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}
    style={{ width: '100%' }}
  >
    <GeneralRegularNormal>{children}</GeneralRegularNormal>
  </DropDownItemContainer>
));

interface Item {
  label: string;
  value: any;
}

const GiEventsDropDown = ({
  value,
  items,
  onChange,
  style,
  disabled,
  active,
}: {
  value?: string;
  items?: Item[];
  disabled?: boolean;
  onChange?: (item: Item, index: number) => void;
  style?: React.CSSProperties;
  active?: boolean;
}) => {
  return (
    <Column style={style}>
      <Dropdown role="menu">
        <Dropdown.Toggle
          id="dropdown-basic"
          as={CustomToggle}
          disabled={disabled}
          active={active}
        >
          {items?.find((item) => item.value === value)?.label}
        </Dropdown.Toggle>

        <Dropdown.Menu
          style={{
            backgroundColor: 'rgb(11, 0, 33)',
            marginTop: -5,
            padding: 0,
            width: '100%',
            overflowY: 'auto',
            maxHeight: 300,
          }}
        >
          {items?.map((item, index) => (
            <Dropdown.Item
              key={item.value}
              onSelect={onChange ? () => onChange(item, index) : undefined}
              as={CustomDropDownItem}
            >
              {item.label}
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
      </Dropdown>
    </Column>
  );
};

const ToggleContainer = styled.div<{ disabled?: boolean; active?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 2px;
  box-sizing: border-box;
  padding: 10px;
  cursor: ${(props) => (props.disabled ? undefined : 'pointer')};
  background: ${(props) =>
    props.active
      ? props.theme.colors.white
      : props.disabled
      ? '#0e00238d'
      : '#0e0023'};
  border: 1px solid ${(props) => props.theme.colors.buttonBorder};
  height: 44px;
  font-size: 16px;
  border-radius: 10px;
  color: ${(props) => props.theme.colors.black};
`;

const DropDownItemContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 35px;
  box-sizing: border-box;
  padding: 10px;
  background-color: ${(props) => props.theme.colors.white};
  cursor: pointer;
  color: ${(props) => props.theme.colors.black};

  &:hover {
    background-color: ${(props) => props.theme.colors.greyInput};
  }
  &:hover > span {
    color: ${(props) => props.theme.colors.black};
  }
  &:active {
    background-color: ${(props) => props.theme.colors.white};
  }
`;

const DownArrow = styled.img`
  object-fit: contain;
`;

export default GiEventsDropDown;
