import { observer } from 'mobx-react';
import * as React from 'react';
import i18n from '../../../constants/i18n';
import { Artist } from '../../../shared';
import { useArtistStore } from '../../../stores/artist';
import { UseUserStore } from '../../../stores/user';
import EditableTextInput from '../../common/EditableTextInput';
import EditArtistProfileLayout from '../EditArtistProfileLayout';
import EditArtistOverviewSocial from './EditArtistOverviewSocial';
import EditArtistOverviewTag from './EditArtistOverviewTag';
import EditArtistProfileOverviewAvailability from './EditArtistProfileOverviewAvailability';
import toast from 'react-hot-toast';

const EditArtistProfileOverview = observer(() => {
  const artistStore = useArtistStore();
  const userStore = UseUserStore();
  const me = userStore.getMe();

  const onSave = async (payload: Partial<Artist>) => {
    try {
      await artistStore.editMeArtist(payload);
      toast.success(i18n('EditArtistProfileOverview.saved', 'Saved'));
    } catch (e) {
      const err = e as Error;
      toast.error(err.message);
    }
  };

  if (!me || !me.artist) {
    return <div />;
  }

  return (
    <EditArtistProfileLayout>
      <EditableTextInput
        value={me?.artist.name}
        label={i18n('EditArtistProfileOverview.name', 'Stage Name')}
        onSave={(name) => onSave({ name })}
      />
      <EditableTextInput
        value={me?.artist.title}
        label={i18n('EditArtistProfileOverview.headline', 'Headline')}
        toolTip={i18n(
          'EditArtistProfileOverview.thisWillAppearUnderYourName',
          'This will appear under your name on your profile and when you appear in search results. Just be yourself!',
        )}
        onSave={(title) => onSave({ title })}
      />
      <EditableTextInput
        value={me?.artist.location}
        label={i18n(
          'EditArtistProfileOverview.location(city,...)',
          'Location (city, province/state)',
        )}
        onSave={(location) => onSave({ location })}
      />
      <EditArtistProfileOverviewAvailability />
      <EditArtistOverviewTag />
      <EditableTextInput
        value={me?.artist.bio}
        textArea
        label={i18n('EditArtistProfileOverview.about', 'About')}
        toolTip={i18n(
          'EditArtistProfileOverview.tellUsAboutYourself',
          'Tell us about yourself! Need inspiration, click the "Guide button at the top of the page',
        )}
        onSave={(bio) => onSave({ bio })}
        placeholder={i18n(
          'EditArtistProfileOverview.bioPlaceholder',
          'Write a bit about yourself....',
        )}
      />
      <EditArtistOverviewSocial />
    </EditArtistProfileLayout>
  );
});

export default EditArtistProfileOverview;
