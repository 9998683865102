import * as React from 'react';
import styled from 'styled-components';
import colors from '../../constants/colors';
import { GeneralRegularNormal } from './Typography';

const GiIncrementer = ({
  value = 0,
  style = {},
  onChangeValue = () => null,
}: {
  value?: number;
  style?: React.CSSProperties;
  onChangeValue?: (value: number) => void;
}) => {
  const [val, setVal] = React.useState(value);

  React.useEffect(() => {
    onChangeValue(val);
  }, [val]);

  return (
    <Main style={{ backgroundColor: colors.darkPuple, ...style }}>
      <GeneralRegularNormal
        style={{ color: colors.whiteText, fontSize: 20, cursor: 'pointer' }}
        onClick={() => setVal(val <= 0 ? 0 : val - 1)}
      >
        -
      </GeneralRegularNormal>
      <GeneralRegularNormal style={{ color: colors.whiteText }}>
        {val}
      </GeneralRegularNormal>
      <GeneralRegularNormal
        onClick={() => setVal(val + 1)}
        style={{ color: colors.whiteText, fontSize: 20, cursor: 'pointer' }}
      >
        +
      </GeneralRegularNormal>
    </Main>
  );
};

const Main = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex: 1;
  padding: 10px 15px;
  border-radius: 2px;
`;

export default GiIncrementer;
