import * as React from 'react';
import styled from 'styled-components';
import { Box } from 'react-native-kondo';

import { useNavigate } from 'react-router-dom';
import images from '../../../constants/images';
import { GeneralRegularNormal } from '../../common/Typography';
import i18n from '../../../constants/i18n';
import colors from '../../../constants/colors';
import { observer } from 'mobx-react';
import { UseUserStore } from '../../../stores/user';
import { useUiStore } from '../../../stores/ui';
import { useLocation } from 'react-router-dom';
import { useMessagingStore } from '../../../stores/messaging';
import NavButton from './NavButton';
import UserSection from './UserSection';

const NavigationDrawer = observer(
  ({ isOpen, onClose }: { isOpen: boolean; onClose: () => void }) => {
    const navigate = useNavigate();
    const location = useLocation();

    const userStore = UseUserStore();
    const uiStore = useUiStore();
    const messageStore = useMessagingStore();

    React.useEffect(() => {
      if (uiStore.isMobile) {
        onClose();
      }
    }, [location.pathname]);

    const me = userStore.getMe();

    const isLoggedIn = !!me;
    const isPerformer = userStore.getMe()?.artist;

    const hideComponent =
      location.pathname.includes('/resetPassword') || !uiStore.isMobile;

    if (hideComponent) {
      return null;
    }
    const hideRequests = !isPerformer;
    const hideMessages = !isLoggedIn;
    const hideBilling = !isPerformer;
    const hideSettings = !isLoggedIn;
    const hideSupport = false;
    const hideLogOut = !isLoggedIn;

    return (
      <Main isOpen={isOpen}>
        <Box justifyContent="space-between" flexDirection="row">
          <GigLinkedLogo
            src={images.giglinkedLogoWhite}
            onClick={() => navigate('/')}
          />
          <Close src={images.closeIcon} onClick={onClose} />
        </Box>
        <UserSection onCloseDrawer={onClose} />

        <NavButton
          label={
            isPerformer
              ? i18n('NavigationDrawer.gigs', 'Gigs')
              : i18n('NavigationDrawer.createEvent', 'Create event')
          }
          onClick={() =>
            isPerformer ? navigate(`/events`) : navigate(`/events/new`)
          }
        />

        <NavButton
          label={i18n('NavigationDrawer.browseMusicians', 'Browse Musicians')}
          onClick={() => {
            onClose();
            navigate('/performers');
          }}
        />

        <NavButton
          label={i18n('NavigationDrawer.requests', 'Requests')}
          hide={hideRequests}
          onClick={() => {
            onClose();
            navigate('/requests');
          }}
        />
        <NavButton
          label={i18n('NavigationDrawer.messages', 'Messages')}
          notificationCount={messageStore.numberOfUnSeenMessages}
          hide={hideMessages}
          onClick={() => {
            onClose();
            navigate('/conversations');
          }}
        />
        <NavButton
          label={i18n('NavigationDrawer.transactions', 'Transactions')}
          hide={hideBilling}
          onClick={() => {
            onClose();
            navigate('/transactions');
          }}
        />
        <NavButton
          label={i18n('NavigationDrawer.settings', 'Settings')}
          hide={hideSettings}
          onClick={() => {
            onClose();
            navigate('/settings');
          }}
        />
        <NavButton
          label={i18n('NavigationDrawer.support', 'Support')}
          hide={hideSupport}
          onClick={() => {
            onClose();
            navigate('/support');
          }}
        />

        <GeneralRegularNormal
          onClick={userStore.logOut}
          style={{
            textDecoration: 'underline',
            color: colors.whiteText,
            marginTop: 50,
            cursor: 'pointer',
            display: hideLogOut ? 'none' : undefined,
          }}
        >
          {i18n('NavigationDrawer.logout', 'Log Out')}
        </GeneralRegularNormal>
      </Main>
    );
  },
);

const Close = styled.img`
  width: 30px;
  height: 28px;
  cursor: pointer;
`;

const GigLinkedLogo = styled.img`
  width: 200px;
  height: 46.08px;
  margin-bottom: 40px;
  cursor: pointer;
  @media (max-width: ${(props) => props.theme.breakPoints.mobile}px) {
    margin-bottom: 30px;
    width: 175px;
    height: 40px;
  }
`;

const Main = styled.div<{ isOpen: boolean }>`
  display: flex;
  flex-direction: column;
  padding-top: 49px;
  padding-left: 50px;
  padding-right: 50px;
  padding-bottom: 49px;
  background: linear-gradient(180deg, #210154 0%, #100029 40.41%);
  box-shadow: 4px 4px 12px rgba(131, 123, 119, 0.25);
  box-sizing: border-box;
  position: fixed;
  left: 0;
  bottom: 0;
  top: 0;
  overflow-y: auto;

  @media (max-width: ${(props) => props.theme.breakPoints.mobile}px) {
    justify-content: space-evenly;
    transition: transform 0.3s ease;
    z-index: 11;
    transform: ${(props) =>
      props.isOpen ? 'translate(0,0)' : 'translate(100%,0)'};
    padding-top: 25px;
    padding-left: 25px;
    padding-right: 25px;
    padding-bottom: 25px;
    right: 0;
    width: 100%;
  }
`;

export default NavigationDrawer;
