import { observer } from 'mobx-react-lite';
import * as React from 'react';
import styled from 'styled-components';
import colors from '../../../constants/colors';
import i18n from '../../../constants/i18n';
import { Transaction } from '../../../shared';
import { useUiStore } from '../../../stores/ui';
import GiButton from '../../common/GiButton';
import { GeneralRegularNormal, TextH2 } from '../../common/Typography';
import useGetRequestDetailsQuoteFormattedData from './useGetRequestDetailsQuoteFormattedData';
import toast from 'react-hot-toast';
import sexpressClient from '../../../utils/sexpress';

const DeclinePopup = observer(
  ({
    request,
    fetchTransaction,
  }: {
    request: Transaction;
    fetchTransaction: () => void;
  }) => {
    const { isMobile, innerHeight, innerWidth, closePopup } = useUiStore();

    const [isLoading, setIsLoading] = React.useState(false);
    const [message, setMessage] = React.useState('');

    const { id } = request;
    const { meIsAct, meIsArtist } =
      useGetRequestDetailsQuoteFormattedData(request);

    const width = isMobile ? innerWidth : innerWidth * 0.61;

    const pageContent = {
      firstText: i18n(
        'DeclinePopup.decliningThePerformer',
        "Declining the performer's quote",
      ),
      secondText: i18n(
        'DeclinePopup.youCanAddAMessage',
        "You can add a message explaining why you're declining the quote",
      ),
      firstButtonText: i18n('DeclinePopup.declineQuote', 'Decline Quote'),
      firstButtonOnClick: async () => {
        try {
          setIsLoading(true);
          await sexpressClient.updateTransactionState({
            action: 'declineQuote',
            transactionId: id,
            payload: { bookerQuoteDeclineMessage: message },
          });

          fetchTransaction();

          toast.success(i18n('DeclinePopup.quoteDeclined!', 'Quote Declined!'));
        } catch (e) {
          console.log(e);
        } finally {
          setIsLoading(false);
          closePopup();
        }
      },
    };

    if (meIsArtist || meIsAct) {
      pageContent.firstText = i18n(
        'DeclinePopup.decliningThePerformer',
        'Declining the request',
      );
      pageContent.secondText = i18n(
        'DeclinePopup.youCanAddAMessage',
        "You can add a message explaining why you're declining the request",
      );
      pageContent.firstButtonText = i18n(
        'DeclinePopup.declineRequest',
        'Decline Request',
      );
      pageContent.firstButtonOnClick = async () => {
        try {
          setIsLoading(true);
          await sexpressClient.updateTransactionState({
            action: 'decline',
            transactionId: id,
            payload: { performerDeclineMessage: message },
          });

          fetchTransaction();

          toast.success(
            i18n('BookingAbout.requestDeclined!', 'Request Declined!'),
          );
        } catch (e) {
          console.log(e);
        } finally {
          setIsLoading(false);
          closePopup();
        }
      };
    }

    const { firstText, secondText, firstButtonText, firstButtonOnClick } =
      pageContent;

    return (
      <Main width={width} height={innerHeight}>
        <ContentContainer>
          <TextH2
            style={{
              color: colors.whiteText,
              marginBottom: 36,
              alignSelf: 'center',
              textAlign: 'center',
            }}
          >
            {firstText}
          </TextH2>

          <GeneralRegularNormal
            style={{
              color: colors.whiteText,
              marginBottom: 36,
              alignSelf: 'center',
              textAlign: 'center',
              width: '90%',
            }}
          >
            {secondText}
          </GeneralRegularNormal>

          <TextArea
            onChange={(e) => {
              setMessage(e.target.value);
            }}
            value={message}
            placeholder="Message"
            style={{ marginBottom: 48 }}
          />

          <ButtonsContainer>
            <GiButton
              label={firstButtonText}
              style={{ marginBottom: 12 }}
              onClick={firstButtonOnClick}
              loading={isLoading}
            />

            <GiButton
              label={i18n('DeclinePopup.cancel', 'Cancel')}
              onClick={closePopup}
            />
          </ButtonsContainer>
        </ContentContainer>
      </Main>
    );
  },
);

const Main = styled.div<{ width: number; height: number }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: ${(props) => props.width}px;
  align-items: center;
  border-radius: 5px;
  background-color: ${colors.lightPurple};
  @media (max-width: ${(props) => props.theme.breakPoints.mobile}px) {
    min-height: ${(props) => props.height}px;
  }
`;

const ContentContainer = styled.div`
  display: flex;
  box-sizing: border-box;
  flex-direction: column;
  align-self: center;
  //padding-bottom: 63px;
  padding: 40px 20px;
  width: 100%;
  max-width: 600px;
  @media (max-width: ${(props) => props.theme.breakPoints.mobile}px) {
    padding: 20px 20px 20px 20px;
  }
`;

const TextArea = styled.textarea`
  display: flex;
  box-sizing: border-box;
  background-color: #0e0023;
  font-size: 16px;
  font-family: ${(props) => props.theme.fonts.GeneralSans};
  font-weight: 400;
  min-height: 229px;
  padding: 20px;
  resize: none;
  color: ${(props) => props.theme.colors.whiteText};
  ::placeholder {
    color: ${(props) => props.theme.colors.whiteText};
    opacity: 0.6;
  }
  text-decoration: none;
  outline: none;
  border-width: 0px;
  @media (max-width: ${(props) => props.theme.breakPoints.mobile}px) {
  }
`;

const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-left: 20px;
  padding-right: 20px;
  @media (max-width: ${(props) => props.theme.breakPoints.mobile}px) {
    flex-direction: column;
    align-items: center;
  }
`;

export default DeclinePopup;
