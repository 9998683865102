export default {
  'StepperRegistrationGroupAbout.title': {
    en: 'About your group',
    fr: 'À propos de votre groupe',
  },
  'StepperRegistrationGroupAbout.description': {
    en: "If your group does not have a stage name, you can enter your name followed by 'band' or 'duo'",
    fr: "Si votre groupe n'as pas de nom de scène, vous pouvez entrer votre nom suivi de 'band' ou 'duo'",
  },
  'StepperRegistrationGroupAbout.groupStageName': {
    en: 'Stage name',
    fr: 'Nom de scène',
  },
  'StepperRegistrationGroupAbout.city': { en: 'City', fr: 'Ville' },
  'StepperRegistrationGroupAbout.next': { en: 'Next', fr: 'Suivante' },
};
