import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { Box } from 'react-native-kondo';
import styled from 'styled-components';
import colors from '../../../constants/colors';
import i18n from '../../../constants/i18n';
import { useUiStore } from '../../../stores/ui';
import { UseUserStore } from '../../../stores/user';
import ATag from '../../common/ATag';
import { GeneralRegularNormal, TextH3 } from '../../common/Typography';
import { replaceProfileImage } from '../../../utils/media';

const UserSection = observer(
  ({ onCloseDrawer }: { onCloseDrawer: () => void }) => {
    const userStore = UseUserStore();
    const uiStore = useUiStore();

    if (!userStore.me) {
      return null;
    }

    return (
      <UserSectionContainer>
        <ProfilePicture
          src={replaceProfileImage(userStore.me.profilePicture)}
          referrerPolicy="no-referrer"
        />
        <Box style={{ flex: 1 }}>
          <TextH3 style={{ marginBottom: 6 }}>
            {userStore.me.firstName} {userStore.me.lastName}
          </TextH3>
          {userStore.me.artist ? (
            <ATag
              target="_blank"
              href={`/artists/${userStore.me!.artist.id}`}
              onClick={uiStore.isMobile ? onCloseDrawer : undefined}
            >
              <GeneralRegularNormal
                style={{
                  textDecoration: 'underline',
                  color: colors.whiteText,
                  cursor: 'pointer',
                }}
              >
                {i18n('UserSection.viewProfile', 'View Profile')}
              </GeneralRegularNormal>
            </ATag>
          ) : null}
        </Box>
      </UserSectionContainer>
    );
  },
);

const UserSectionContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 50px;
  @media (max-width: ${(props) => props.theme.breakPoints.mobile}px) {
    margin-bottom: 20px;
  }
`;

const ProfilePicture = styled.img`
  width: 76px;
  height: 76px;
  border-radius: 50%;
  margin-right: 17px;
  object-fit: cover;
  @media (max-width: ${(props) => props.theme.breakPoints.mobile}px) {
    width: 50px;
    height: 50px;
    border-radius: 50%;
  }
`;

export default UserSection;
