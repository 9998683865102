import AboutJambassadorsSection from './AboutJambassadorsSection';
import AboutPrinciplesSection from './AboutPrinciplesSection';
import AboutTeamSection from './AboutTeamSection';
import AboutTopSection from './AboutTopSection';
import ActMembersListing from './ActMembersListing';
import ActProfileScreen from './ActProfileScreen';
import AddBankingInformationModal from './AddBankingInformationModal';
import AddBillingMethodModal from './AddBillingMethodModal';
import AddItemTextInput from './AddItemTextInput';
import ApplyToEventModal from './ApplyToEventModal';
import ArtistBioSection from './ArtistBioSection';
import ArtistGallerySection from './ArtistGallerySection';
import ArtistPriceSection from './ArtistPriceSection';
import ArtistProfilePictureSection from './ArtistProfilePictureSection';
import ArtistReminders from './ArtistReminders';
import ArtistReviewSection from './ArtistReviewSection';
import ArtistSpotifySection from './ArtistSpotifySection';
import ArtistVideoSection from './ArtistVideoSection';
import BankingInfo from './BankingInfo';
import BookingAbout from './BookingAbout';
import BookingAudience from './BookingAudience';
import BookingContact from './BookingContact';
import BookingDateSelection from './BookingDateSelection';
import BookingEventSelction from './BookingEventSelction';
import BookingFinalMessage from './BookingFinalMessage';
import BookingLocationSelection from './BookingLocationSelection';
import BookingPayment from './BookingPayment';
import BookingRecap from './BookingRecap';
import BookingSummary from './BookingSummary';
import BookingSummaryFees from './BookingSummaryFees';
import BookingUserInfo from './BookingUserInfo';
import CalendarScreen from './CalendarScreen';
import CancelEventModal from './CancelEventModal';
import CardPaymentData from './CardPaymentData';
import CheckPaymentData from './CheckPaymentData';
import CreateEventScreen from './CreateEventScreen';
import DashboardLanding from './DashboardLanding';
import DeclinePopup from './DeclinePopup';
import DeleteEventModal from './DeleteEventModal';
import EditActGallery from './EditActGallery';
import EditActMembers from './EditActMembers';
import EditActMembersListing from './EditActMembersListing';
import EditActOverview from './EditActOverview';
import EditActOverviewSocial from './EditActOverviewSocial';
import EditActPricing from './EditActPricing';
import EditActSearchArtistBox from './EditActSearchArtistBox';
import EditActSongs from './EditActSongs';
import EditActVideos from './EditActVideos';
import EditActsOverviewTag from './EditActsOverviewTag';
import EditActsProfileLayout from './EditActsProfileLayout';
import EditArtistActCell from './EditArtistActCell';
import EditArtistActScreen from './EditArtistActScreen';
import EditArtistOverviewSocial from './EditArtistOverviewSocial';
import EditArtistOverviewTag from './EditArtistOverviewTag';
import EditArtistOverviewTagInput from './EditArtistOverviewTagInput';
import EditArtistPricing from './EditArtistPricing';
import EditArtistProfileGallery from './EditArtistProfileGallery';
import EditArtistProfileLayout from './EditArtistProfileLayout';
import EditArtistProfileOverview from './EditArtistProfileOverview';
import EditArtistProfileSongs from './EditArtistProfileSongs';
import EditArtistProfileVideos from './EditArtistProfileVideos';
import EditEventModal from './EditEventModal';
import EditProfileNavbar from './EditProfileNavbar';
import EditableTextInput from './EditableTextInput';
import EventData from './EventData';
import EventDetailsStepFour from './EventDetailsStepFour';
import EventDetailsStepOne from './EventDetailsStepOne';
import EventDetailsStepThree from './EventDetailsStepThree';
import EventDetailsStepTwo from './EventDetailsStepTwo';
import EventProposal from './EventProposal';
import EventScreen from './EventScreen';
import EventStatus from './EventStatus';
import EventsCard from './EventsCard';
import EventsList from './EventsList';
import EventsTable from './EventsTable';
import ForgotPassword from './ForgotPassword';
import FormattedPayoutScreen from './FormattedPayoutScreen';
import FormattedPayoutsRow from './FormattedPayoutsRow';
import FormattedPayoutsScreen from './FormattedPayoutsScreen';
import FrictionlessBooking from './FrictionlessBooking';
import GiTextInput from './GiTextInput';
import GiglinkedMusicianAutocomplete from './GiglinkedMusicianAutocomplete';
import HireMusicianModal from './HireMusicianModal';
import HowItWorksAccordionSection from './HowItWorksAccordionSection';
import HowItWorksGetADemoSection from './HowItWorksGetADemoSection';
import HowItWorksStepsSection from './HowItWorksStepsSection';
import HowItWorksTopSection from './HowItWorksTopSection';
import InvoicePage from './InvoicePage';
import Invoices from './Invoices';
import InvoicesCard from './InvoicesCard';
import InvoicesTable from './InvoicesTable';
import LandingCollaboratorsSection from './LandingCollaboratorsSection';
import LandingFooter from './LandingFooter';
import LandingPhoneSection from './LandingPhoneSection';
import LandingPopularMusiciansSection from './LandingPopularMusiciansSection';
import LandingTabularSection from './LandingTabularSection';
import LandingTopSection from './LandingTopSection';
import LeftNavigationBar from './LeftNavigationBar';
import ModalImageCropper from './ModalImageCropper';
import MusicianEventCard from './MusicianEventCard';
import MusicianNewEventCard from './MusicianNewEventCard';
import NavBar from './NavBar';
import NavBarContent from './NavBarContent';
import NavigationDrawer from './NavigationDrawer';
import PageLayoutFooter from './PageLayoutFooter';
import PaymentPref from './PaymentPref';
import PerformerBanner from './PerformerBanner';
import PerformerBannerSearch from './PerformerBannerSearch';
import PerformerPage from './PerformerPage';
import PerformerTagSection from './PerformerTagSection';
import PriceData from './PriceData';
import ProfileActsOrMemberSection from './ProfileActsOrMemberSection';
import RegistrationBookerLocation from './RegistrationBookerLocation';
import RegistrationBookerTellAboutYourself from './RegistrationBookerTellAboutYourself';
import RegistrationBookerWaysToBook from './RegistrationBookerWaysToBook';
import RegistrationController from './RegistrationController';
import RegistrationMusicianOrClient from './RegistrationMusicianOrClient';
import RegistrationMusicianSoloOrGroup from './RegistrationMusicianSoloOrGroup';
import RegistrationMusicianUploadPicture from './RegistrationMusicianUploadPicture';
import RegistrationStepper from './RegistrationStepper';
import RegistrationUser from './RegistrationUser';
import RemoveMethodModal from './RemoveMethodModal';
import RequestCancelButtonSection from './RequestCancelButtonSection';
import RequestCaracteristicsSection from './RequestCaracteristicsSection';
import RequestCell from './RequestCell';
import RequestDetailsQuoteSection from './RequestDetailsQuoteSection';
import RequestDetailsReviewSection from './RequestDetailsReviewSection';
import RequestDetailsScreen from './RequestDetailsScreen';
import RequestListing from './RequestListing';
import RequestScreen from './RequestScreen';
import RequestStatusBarSelector from './RequestStatusBarSelector';
import ResetPassword from './ResetPassword';
import SettingsScreen from './SettingsScreen';
import SharedTypes from './SharedTypes';
import SignInController from './SignInController';
import SignInPage from './SignInPage';
import SocialButton from './SocialButton';
import StepperRegistrationEventTypes from './StepperRegistrationEventTypes';
import StepperRegistrationGroupAbout from './StepperRegistrationGroupAbout';
import StepperRegistrationGroupTypeSelect from './StepperRegistrationGroupTypeSelect';
import StepperRegistrationInstruments from './StepperRegistrationInstruments';
import StepperRegistrationPlayStyles from './StepperRegistrationPlayStyles';
import StepperRegistrationSoloAbout from './StepperRegistrationSoloAbout';
import StripeSecurePayments from './StripeSecurePayments';
import SuccessfulRequestRecapSection from './SuccessfulRequestRecapSection';
import SupportScreen from './SupportScreen';
import SupportSubjectDropDown from './SupportSubjectDropDown';
import TopSection from './TopSection';
import UserSection from './UserSection';
import Validator from './Validator';
import useGetDropDownsOptions from './useGetDropDownsOptions';
const translations = {
  ...AboutJambassadorsSection,
  ...AboutPrinciplesSection,
  ...AboutTeamSection,
  ...AboutTopSection,
  ...ActMembersListing,
  ...ActProfileScreen,
  ...AddBankingInformationModal,
  ...AddBillingMethodModal,
  ...AddItemTextInput,
  ...ApplyToEventModal,
  ...ArtistBioSection,
  ...ArtistGallerySection,
  ...ArtistPriceSection,
  ...ArtistProfilePictureSection,
  ...ArtistReminders,
  ...ArtistReviewSection,
  ...ArtistSpotifySection,
  ...ArtistVideoSection,
  ...BankingInfo,
  ...BookingAbout,
  ...BookingAudience,
  ...BookingContact,
  ...BookingDateSelection,
  ...BookingEventSelction,
  ...BookingFinalMessage,
  ...BookingLocationSelection,
  ...BookingPayment,
  ...BookingRecap,
  ...BookingSummary,
  ...BookingSummaryFees,
  ...BookingUserInfo,
  ...CalendarScreen,
  ...CancelEventModal,
  ...CardPaymentData,
  ...CheckPaymentData,
  ...CreateEventScreen,
  ...DashboardLanding,
  ...DeclinePopup,
  ...DeleteEventModal,
  ...EditActGallery,
  ...EditActMembers,
  ...EditActMembersListing,
  ...EditActOverview,
  ...EditActOverviewSocial,
  ...EditActPricing,
  ...EditActSearchArtistBox,
  ...EditActSongs,
  ...EditActVideos,
  ...EditActsOverviewTag,
  ...EditActsProfileLayout,
  ...EditArtistActCell,
  ...EditArtistActScreen,
  ...EditArtistOverviewSocial,
  ...EditArtistOverviewTag,
  ...EditArtistOverviewTagInput,
  ...EditArtistPricing,
  ...EditArtistProfileGallery,
  ...EditArtistProfileLayout,
  ...EditArtistProfileOverview,
  ...EditArtistProfileSongs,
  ...EditArtistProfileVideos,
  ...EditEventModal,
  ...EditProfileNavbar,
  ...EditableTextInput,
  ...EventData,
  ...EventDetailsStepFour,
  ...EventDetailsStepOne,
  ...EventDetailsStepThree,
  ...EventDetailsStepTwo,
  ...EventProposal,
  ...EventScreen,
  ...EventStatus,
  ...EventsCard,
  ...EventsList,
  ...EventsTable,
  ...ForgotPassword,
  ...FormattedPayoutScreen,
  ...FormattedPayoutsRow,
  ...FormattedPayoutsScreen,
  ...FrictionlessBooking,
  ...GiTextInput,
  ...GiglinkedMusicianAutocomplete,
  ...HireMusicianModal,
  ...HowItWorksAccordionSection,
  ...HowItWorksGetADemoSection,
  ...HowItWorksStepsSection,
  ...HowItWorksTopSection,
  ...InvoicePage,
  ...Invoices,
  ...InvoicesCard,
  ...InvoicesTable,
  ...LandingCollaboratorsSection,
  ...LandingFooter,
  ...LandingPhoneSection,
  ...LandingPopularMusiciansSection,
  ...LandingTabularSection,
  ...LandingTopSection,
  ...LeftNavigationBar,
  ...ModalImageCropper,
  ...MusicianEventCard,
  ...MusicianNewEventCard,
  ...NavBar,
  ...NavBarContent,
  ...NavigationDrawer,
  ...PageLayoutFooter,
  ...PaymentPref,
  ...PerformerBanner,
  ...PerformerBannerSearch,
  ...PerformerPage,
  ...PerformerTagSection,
  ...PriceData,
  ...ProfileActsOrMemberSection,
  ...RegistrationBookerLocation,
  ...RegistrationBookerTellAboutYourself,
  ...RegistrationBookerWaysToBook,
  ...RegistrationController,
  ...RegistrationMusicianOrClient,
  ...RegistrationMusicianSoloOrGroup,
  ...RegistrationMusicianUploadPicture,
  ...RegistrationStepper,
  ...RegistrationUser,
  ...RemoveMethodModal,
  ...RequestCancelButtonSection,
  ...RequestCaracteristicsSection,
  ...RequestCell,
  ...RequestDetailsQuoteSection,
  ...RequestDetailsReviewSection,
  ...RequestDetailsScreen,
  ...RequestListing,
  ...RequestScreen,
  ...RequestStatusBarSelector,
  ...ResetPassword,
  ...SettingsScreen,
  ...SharedTypes,
  ...SignInController,
  ...SignInPage,
  ...SocialButton,
  ...StepperRegistrationEventTypes,
  ...StepperRegistrationGroupAbout,
  ...StepperRegistrationGroupTypeSelect,
  ...StepperRegistrationInstruments,
  ...StepperRegistrationPlayStyles,
  ...StepperRegistrationSoloAbout,
  ...StripeSecurePayments,
  ...SuccessfulRequestRecapSection,
  ...SupportScreen,
  ...SupportSubjectDropDown,
  ...TopSection,
  ...UserSection,
  ...Validator,
  ...useGetDropDownsOptions,
};
export default translations;
