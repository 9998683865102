import * as React from 'react';
import { Box } from 'react-native-kondo';
import { IoIosArrowForward, IoIosArrowBack } from 'react-icons/io';
import { GeneralRegularNormal, TextH3, TextH4 } from '../common/Typography';
import colors from '../../constants/colors';
import { getDeviceLangage } from '../../constants/i18n';

import { format } from 'date-fns';

import { enCA, frCA } from 'date-fns/locale';

interface P {
  onChangeSelectedDates?: (selectedDates: Date[]) => void;
  selectedDate?: Date;
  style?: React.CSSProperties;
  disabled?: boolean;
}

const isSameCalendarDay = (date1: Date, date2: Date) => {
  return (
    date1.getDate() === date2.getDate() &&
    date1.getFullYear() === date2.getFullYear() &&
    date1.getMonth() === date2.getMonth()
  );
};

export default class Calendar extends React.Component<
  P,
  {
    selectedDates: Date[];
    currentMonthIndex: number;
    firstDay: Date;
    index: number;
    init: boolean;
    calendarData: any[];
  }
> {
  state = {
    currentMonthIndex: 0,
    index: 0,
    calenderIndex: 0,
    selectedDates: this.props.selectedDate
      ? [new Date(this.props.selectedDate.toString())]
      : [],
    firstDay: new Date(),
    init: true,
    calendarData: [],
  };
  _calenderData: any = [];

  componentDidMount() {
    this._calculateCalenderData();

    // if (this.props.getRef) {
    //   this.props.getRef(this);
    // }
  }

  _calculateCalenderData = () => {
    const currentDate = new Date();
    let firstDay = new Date();
    const currentMonthIndex = currentDate.getMonth();
    const calender = [];

    while (currentDate.getDate() !== 1) {
      currentDate.setDate(currentDate.getDate() - 1);
    }

    firstDay = new Date(currentDate);

    while (currentDate.getDay() !== 0) {
      currentDate.setDate(currentDate.getDate() - 1);
    }

    const monthsToAdd = 12;
    for (let i = 0; i < monthsToAdd; i++) {
      const newDate = new Date(
        firstDay.getFullYear(),
        currentMonthIndex + i,
        1,
      );
      const year = newDate.getFullYear();

      let month = currentMonthIndex + i;

      if (month >= 12) {
        month = month - 12;
      }

      calender.push(this._getMonthData({ month, year }));
    }

    this._calenderData = calender;

    this.setState({
      currentMonthIndex,
      firstDay,
      init: false,
      calendarData: calender,
    });
  };

  _getMonthData = ({ month, year }: { month: number; year: number }) => {
    const monthData: Date[] = [];
    const firstDayOfTheMonth = new Date(year, month, 1);

    while (firstDayOfTheMonth.getDay() !== 0) {
      firstDayOfTheMonth.setDate(firstDayOfTheMonth.getDate() - 1);
    }

    const dayToAdd = 35;

    for (let i = 0; i < dayToAdd; i++) {
      const day = new Date(firstDayOfTheMonth.toString());
      day.setDate(day.getDate() + i);
      monthData.push(day);
    }

    const lastDayOfTheMonth = new Date(monthData[monthData.length - 1]);

    while (lastDayOfTheMonth.getMonth() === monthData[15].getMonth()) {
      lastDayOfTheMonth.setDate(lastDayOfTheMonth.getDate() + 1);
      const day = new Date(lastDayOfTheMonth.toString());
      monthData.push(day);
    }

    while (monthData.length !== 42) {
      lastDayOfTheMonth.setDate(lastDayOfTheMonth.getDate() + 1);
      const day = new Date(lastDayOfTheMonth.toString());
      monthData.push(day);
    }

    return monthData;
  };

  _handleDateSelection = (date: Date) => {
    const nbOfDateToSelect = 1;
    let selectedDates: Date[] = [...this.state.selectedDates];

    if (nbOfDateToSelect === 1) {
      selectedDates = [date];
    } else {
      const index = selectedDates.findIndex((s) => s === date);
      if (index === -1) {
        selectedDates.push(date);
      } else {
        selectedDates.splice(index, 1);
      }
    }

    this.setState({ selectedDates }, () => {
      if (this.props.onChangeSelectedDates) {
        this.props.onChangeSelectedDates(selectedDates);
      }
    });
  };

  _getDaysOfTheWeekLetters = () => {
    if (getDeviceLangage() === 'fr') {
      return ['DIM', 'LUN', 'MAR', 'MER', 'JEU', 'VEN', 'SAM'];
    } else {
      return ['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT'];
    }
  };

  _renderDaysRow = () => {
    return (
      <Box pb={8}>
        <Box
          // px={metrics.padding}
          justifyContent="space-between"
          flexDirection="row"
        >
          {this._getDaysOfTheWeekLetters().map((day, i) => (
            <DayLetter key={`${day}-${i}-days`} dayLetter={day} />
          ))}
        </Box>
      </Box>
    );
  };

  _renderCalender = ({ item }: { item: any }) => {
    let offset = 0;
    const max = 7;

    const dateToCheck = new Date(item[0]);

    while (dateToCheck.getDate() !== 1) {
      dateToCheck.setDate(dateToCheck.getDate() + 1);
    }

    const month = dateToCheck.getMonth();
    const today = new Date();

    const nbOfRow = item.length > 35 ? 6 : 5;

    return (
      <Box bg="rgb(13,0,33)">
        {Array.from({ length: nbOfRow }, (v, i) => (
          <Box
            key={`${i}-roww`}
            flexDirection="row"
            justifyContent="space-between"
            mb={4}
          >
            {item.slice(offset, offset + max).map((date: Date, ii: number) => {
              if (offset + ii === offset + max - 1) {
                offset = offset + max;
              }

              return (
                <DayItem
                  // @ts-ignore
                  selected={this.state.selectedDates.find((ss) =>
                    isSameCalendarDay(ss, date),
                  )}
                  // To whom ever may maintain this.
                  // I am so sorry.
                  // -Vince
                  // @ts-ignore

                  onPress={() => this._handleDateSelection(date)}
                  disabled={this.props.disabled}
                  key={date.toString()}
                  date={date.getDate()}
                  notPartOfTheMonth={
                    date.getMonth() !== month ||
                    (date.getMonth() === today.getMonth() &&
                      date.getFullYear() === today.getFullYear() &&
                      date.getDate() < today.getDate())
                  }
                />
              );
            })}
          </Box>
        ))}
      </Box>
    );
  };

  _onPressBack = () => {
    const { index } = this.state;
    if (index > 0) {
      this.setState({ index: index - 1 });
    }
  };

  _onPressNext = () => {
    const { index } = this.state;
    if (index < this._calenderData.length - 1) {
      this.setState({ index: index + 1 });
    }
  };

  _renderMonthRow = () => {
    const { firstDay, index } = this.state;

    const newDate = new Date(
      firstDay.getFullYear(),
      firstDay.getMonth() + index,
      1,
    );

    const canScrollBack = this.state.index > 0;
    const canScrollNext = this.state.index < this._calenderData.length - 1;

    return (
      <Box
        flexDirection="row"
        justifyContent="center"
        alignItems="center"
        // px={metrics.padding}
        pb={20}
      >
        <IoIosArrowBack
          size={20}
          style={{
            marginRight: 0,
            cursor: 'pointer',
            opacity: canScrollBack ? 1 : 0.3,
          }}
          color={colors.whiteText}
          onClick={this._onPressBack}
        />
        <TextH3 style={{ width: 180, textAlign: 'center' }}>
          {format(new Date(newDate), 'MMMM yyyy', {
            locale: getDeviceLangage() === 'fr' ? frCA : enCA,
          })}
        </TextH3>
        <IoIosArrowForward
          size={20}
          style={{
            marginLeft: 0,
            cursor: 'pointer',
            opacity: canScrollNext ? 1 : 0.3,
          }}
          color={colors.whiteText}
          onClick={this._onPressNext}
        />
      </Box>
    );
  };

  render() {
    if (this.state.init) {
      return <div />;
    }

    let style: any = { padding: 20, borderRadius: 5 };
    if (this.props.style) {
      style = { ...this.props.style };
    }

    if (this.props.disabled) {
      style['opacity'] = 0.3;
    }

    return (
      <Box>
        {this._renderMonthRow()}
        <Box bg={'rgb(13,0,33)'} style={style}>
          {this._renderDaysRow()}

          {this._renderCalender({
            item: this.state.calendarData[this.state.index],
          })}
        </Box>
      </Box>
    );
  }
}

const DayLetter = ({ dayLetter }: { dayLetter: string }) => (
  <Box width={40} height={40} justifyContent="center" alignItems="center">
    <GeneralRegularNormal style={{ color: 'rgba(255, 255, 255, 0.75)' }}>
      {dayLetter.toUpperCase()}
    </GeneralRegularNormal>
  </Box>
);

const DayItem = ({
  date,
  selected = false,
  notAvailable = false,
  notPartOfTheMonth = false,
  onPress,
  canSelectedEveryDate,
  disabled,
}: {
  disabled?: boolean;
  date: number;
  selected?: boolean;
  notAvailable?: boolean;
  notPartOfTheMonth?: boolean;
  onPress?: () => void;
  canSelectedEveryDate?: boolean;
}) => {
  let fontColor = colors.whiteText;

  if (notPartOfTheMonth || notAvailable) {
    fontColor = 'rgba(255, 255, 255, 0.25)';
  }

  if (selected) {
    fontColor = colors.whiteText;
    if ((notPartOfTheMonth || notAvailable) && canSelectedEveryDate) {
      fontColor = 'red';
    }
  }

  let borderColor = 'transparent';

  if (notPartOfTheMonth || notAvailable) {
    borderColor = 'transparent';
  }

  return (
    <Box
      onClick={
        canSelectedEveryDate
          ? notPartOfTheMonth
            ? null
            : onPress
          : notPartOfTheMonth || notAvailable || disabled
          ? null
          : onPress
      }
      style={{
        justifyContent: 'center',
        alignItems: 'center',
        width: 40,
        height: 40,
        borderRadius: 40 / 2,
        backgroundColor:
          selected && !notAvailable ? 'rgb(74,96,230)' : 'transparent',
        borderWidth: 1,
        borderColor,
        // @ts-ignore
        cursor: canSelectedEveryDate
          ? notPartOfTheMonth
            ? undefined
            : 'pointer'
          : notPartOfTheMonth || notAvailable
          ? undefined
          : 'pointer',
      }}
    >
      <TextH4 style={{ color: fontColor, textAlign: 'center' }}>{date}</TextH4>
      {notAvailable && !notPartOfTheMonth ? (
        <Box
          bg={'red'}
          height={30}
          width={1}
          style={{
            alignSelf: 'center',
            position: 'absolute',
            top: 5,
            right: 0,
            left: 20,
            bottom: 0,
            transform: [{ rotate: '45deg' }],
          }}
        />
      ) : null}
    </Box>
  );
};
