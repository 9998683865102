import { observer } from 'mobx-react';
import * as React from 'react';
import styled from 'styled-components';
import i18n from '../../constants/i18n';
import { useNavigate } from 'react-router-dom';
import MusicianCard from '../common/profile/MusicianCard';
import { POPULAR_MUSICIANS } from '../../constants/musicians';
import ATag from '../common/ATag';
import GiButtonLanding from '../common/GiButtonLanding';
import { GeneralMediumTitle } from '../common/Typography';
import { MusicianType } from '../../shared';

const LandingPopularMusiciansSection = observer(() => {
  const navigate = useNavigate();
  return (
    <Main>
      <TitleBox>
        <GeneralMediumTitle>
          {i18n('LandingPopularMusiciansSection.Title', 'For your next event')}
        </GeneralMediumTitle>
      </TitleBox>
      <StyledMusiciansContainer>
        {POPULAR_MUSICIANS.map((musician: MusicianType) => (
          <ATag
            noOpacity={true}
            target="_blank"
            key={musician.name}
            href={musician.profileUrl}
          >
            <MusicianCard
              style={{ marginTop: '50px' }}
              key={musician.name}
              musician={musician}
            />
          </ATag>
        ))}
      </StyledMusiciansContainer>
      <BoxButton>
        <GiButtonLanding
          label={i18n(
            'LandingPopularMusiciansSection.ButtonAllMusicians',
            'Browse all musicians',
          )}
          onClick={() =>
            navigate(
              `/performers?page=${encodeURIComponent(1)}&keywords=&location=`,
            )
          }
          style={{ minWidth: '260px' }}
        />
      </BoxButton>
    </Main>
  );
});

const Main = styled.div`
  width: 1440px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media (max-width: ${(props) => props.theme.breakPoints.tablet}px) {
    width: 100%;
    height: 100%;
  }
`;

const TitleBox = styled.div`
  width: 1112px;
  margin-top: 120px;
  margin-bottom: 50px;
  @media (max-width: ${(props) => props.theme.breakPoints.tablet}px) {
    width: 90%;
    margin-top: 70px;
    margin-bottom: 10px;
  }
`;

const StyledMusiciansContainer = styled.div`
  width: 1112px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  @media (max-width: ${(props) => props.theme.breakPoints.tablet}px) {
    width: 90%;
  }
  @media (max-width: ${(props) => props.theme.breakPoints.mobile}px) {
    margin-bottom: 30px;
  }
`;

const BoxButton = styled.div`
  margin-top: 79px;
`;

export default LandingPopularMusiciansSection;
