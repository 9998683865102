export default {
  'SupportScreen.submit': { en: 'Submit', fr: 'Soumettre' },
  'SupportScreen.savingYourStripeAccount...': {
    en: 'Saving your Stripe account...',
    fr: 'Sauver votre compte Stripe ...',
  },
  'SupportScreen.yourAccountIsNowLinkedWithYourBank!': {
    en: 'Your account is now linked with your bank!',
    fr: 'Votre compte est désormais lié à votre banque!',
  },
  'SupportScreen.goToProfile': { en: 'Go to profile', fr: 'Aller au profil' },
  'SupportScreen.reachout': { en: 'Reach out', fr: 'Atteindre' },
  'SupportScreen.title': { en: 'Support', fr: 'Soutien' },
  'SupportScreen.text': {
    en: 'For any questions or concerns, fill in the contact form below and we will get back to you as soon as possible.',
    fr: 'Pour toute question ou préoccupation, remplissez le formulaire de contact ci-dessous et nous vous répondrons dès que possible.',
  },
  'SupportScreen.placeholderName': { en: 'Name', fr: 'Nom' },
  'SupportScreen.placeholderEmail': { en: 'Email', fr: 'E-mail' },
  'SupportScreen.Technical': {
    en: 'Technical Support',
    fr: 'Soutien technique',
  },
  'SupportScreen.booking': { en: 'Booking', fr: 'Réservation' },
  'SupportScreen.other': { en: 'Other', fr: 'Autre' },
  'SupportScreen.placeholderMessage': { en: 'Message', fr: 'Message' },
  'SupportScreen.sendButton': { en: 'Send', fr: 'Envoyer' },
  'SupportScreen.transactions': { en: 'Transactions', fr: 'Transactions' },
  'SupportScreen.yourAccountIsNotFinishedConfiguring!': {
    en: 'Your bank account is not entirely finished configuring, go to profile and finalize the configuration!',
    fr: "La configuration de votre compte bancaire n'est pas entièrement terminée, allez dans le profil et finalisez la configuration!",
  },
};
