export default {
  'EventsList.title': {
    en: 'Find your next Gig',
    fr: 'Trouver votre prochaine Gig',
  },
  'EventsList.apply': { en: 'Apply', fr: 'Appliquer' },
  'EventsList.date': { en: 'Date:', fr: 'Date:' },
  'EventsList.proposals': { en: 'Proposals:', fr: 'Propositions:' },
  'EventsList.userAlreadyAppliedWarning': {
    en: 'You have already applied for this event.',
    fr: 'Vous avez déjà posé votre candidature pour cet événement.',
  },
  'EventsList.posted': { en: 'Posted:', fr: 'Affiché:' },
  'EventsList.unknown': { en: 'Unknown', fr: 'Inconnu' },
  'EventsList.lessDetails': { en: 'Less details', fr: 'Moins de détails' },
  'EventsList.moreDetails': { en: 'More details', fr: 'Plus de détails' },
  'EventsList.open': { en: 'Open gigs', fr: 'Concerts ouverts' },
};
