export default {
  'ArtistPriceSection.makeOffer': {
    en: 'Make an Offer',
    fr: 'Faire une offre',
  },
  'ArtistPriceSection.leaveTip': {
    en: 'Leave a Tip',
    fr: 'Laisser un pourboire',
  },
  'ArtistPriceSection.Message': { en: 'Message', fr: 'Message' },
  'ArtistPriceSection.pleaseSignUpToMakeAnOffer': {
    en: 'Please sign up to make an offer',
    fr: 'Veuillez vous inscrire pour faire une offre',
  },
  'ArtistPriceSection.pleaseSignUpToEnableMessages': {
    en: 'Please sign up to enable messages',
    fr: 'Veuillez vous inscrire pour activer les messages',
  },
  'ArtistPriceSection.pleaseLogInToMakeAnOffer': {
    en: 'Please log in to make an offer',
    fr: 'Veuillez vous connecter pour faire une offre',
  },
  'ArtistPriceSection.pleaseLogInToEnableMessages': {
    en: 'Please log in to enable messages',
    fr: 'Veuillez vous connecter pour activer les messages',
  },
};
