import { observer } from 'mobx-react';
import * as React from 'react';
import i18n from '../../../constants/i18n';
import { useActStore } from '../../../stores/act';
import { UseUserStore } from '../../../stores/user';
import { TextH6 } from '../../common/Typography';
import AddItemTextInput from '../../common/AddItemTextInput';
import EditArtistProfileLayout from '../EditArtistProfileLayout';
import ActCell from './ActCell';
import toast from 'react-hot-toast';
import { Act } from '../../../shared';
import sexpressClient from '../../../utils/sexpress';
import { useArtistStore } from '../../../stores/artist';
import { addRightToolTip } from '../../common/ToolTip';

const EditArtistActScreen = observer(() => {
  const actStore = useActStore();
  const userStore = UseUserStore();
  const artistStore = useArtistStore();
  const me = userStore.getMe();
  const [loadingCreateAct, setLoadingCreateAct] = React.useState(false);

  const onCreateAct = async (payload: { name: string }) => {
    if (loadingCreateAct) {
      return;
    }
    try {
      setLoadingCreateAct(true);
      await actStore.initAct(payload);
    } catch (e) {
      toast.error(i18n('EditArtistActScreen.error', 'Something went wrong'));
    } finally {
      setLoadingCreateAct(false);
    }
  };

  const onAcceptInvite = async ({ act }: { act: Act }) => {
    try {
      const updatedArtistChunk = await sexpressClient.acceptActInvite({
        actId: act.id,
      });
      artistStore.manuallyEditMeArtist(updatedArtistChunk);
      toast.success(i18n('EditArtistActScreen.success', 'Act joined!'));
    } catch (e) {
      toast.error(i18n('EditArtistActScreen.error', 'Something went wrong'));
    }
  };

  const onLeaveAct = async ({ act }: { act: Act }) => {
    try {
      const updatedArtistChunk = await sexpressClient.leaveAct({
        actId: act.id,
      });
      artistStore.manuallyEditMeArtist(updatedArtistChunk);
      toast.success(i18n('EditArtistActScreen.leave', 'Act Left!'));
    } catch (e) {
      toast.error(i18n('EditArtistActScreen.error', 'Something went wrong'));
    }
  };

  if (!me || !me.artist) {
    return <div />;
  }

  return (
    <EditArtistProfileLayout
      title={i18n('EditArtistActScreen.title', 'Your Acts')}
    >
      {addRightToolTip({
        leftNode: (
          <TextH6 style={{ marginBottom: 24 }}>
            {i18n('EditArtistActScreen.createActTitle', 'Create a new Act')}
          </TextH6>
        ),
        toolTip: i18n(
          'EditArtistActScreen.toCreateANewAct',
          'To create a new act, simply enter the name of youy band, or ensemble.',
        ),
      })}
      <AddItemTextInput
        style={{ marginBottom: 26 }}
        loading={loadingCreateAct}
        placeholder={i18n('EditArtistActScreen.placeholderCreate', 'Act Name')}
        label={i18n('EditArtistActScreen.createAct', 'Create')}
        onSave={(actName) =>
          onCreateAct({
            name: actName,
          })
        }
      />
      {me.artist.acts.map((act) => (
        <ActCell
          key={act.id}
          act={act}
          userIsOwner={act.owner.id === me.artist.id}
          onAcceptInvite={onAcceptInvite}
          onLeaveAct={onLeaveAct}
        />
      ))}
      {me.artist.pendingActInvites.map((act) => (
        <ActCell
          key={act.id}
          act={act}
          isPending
          onAcceptInvite={onAcceptInvite}
          onLeaveAct={onLeaveAct}
        />
      ))}
    </EditArtistProfileLayout>
  );
});

export default EditArtistActScreen;
