export default {
  'AboutPrinciplesSection.corePrinciples': {
    en: 'Core Principles',
    fr: 'Principes fondamentaux',
  },
  'AboutPrinciplesSection.secondaryText': {
    en: 'The rhythm and melody to our awesomeness.',
    fr: 'Le rythme et la mélodie de notre génialité.',
  },
  'AboutPrinciplesSection.oneLove': { en: 'One Love', fr: 'Un amour' },
  'AboutPrinciplesSection.oneLoveDesc': {
    en: "We're united with our community through the love of music, creativity, and entrepreneurship.",
    fr: "Nous sommes unis à notre communauté par l'amour de la musique, la créativité et l'esprit d'entreprise.",
  },
  'AboutPrinciplesSection.sitDownBeHumble': {
    en: 'Sit Down, Be Humble',
    fr: 'Asseyez-vous, soyez humble',
  },
  'AboutPrinciplesSection.sitDownBeHumbleDesc': {
    en: "We're radically open-minded and take pride in being lifelong learners.",
    fr: "Nous sommes radicalement ouverts d'esprit et sommes fiers d'être des apprenants permanents.",
  },
  'AboutPrinciplesSection.whatWonderfulWorld': {
    en: 'What a wonderful world',
    fr: 'Quel monde merveilleux',
  },
  'AboutPrinciplesSection.whatWonderfulWorldDesc': {
    en: "We're driven by optimism and the belief that simplicity is the key to improvement.",
    fr: "Nous sommes des collaborateurs dans l'âme, et nous nous efforçons d'être toujours en phase grâce à la transparence et à la franchise.",
  },
  'AboutPrinciplesSection.comeTogether': {
    en: 'Come Together',
    fr: 'Venir ensemble',
  },
  'AboutPrinciplesSection.comeTogetherDesc': {
    en: "We're collaborators at heart, and strive to always be in sync through transparency and candour.",
    fr: "Nous sommes animés par l'optimisme et la conviction que la simplicité est la clé de l'amélioration.",
  },
};
