import { observer } from 'mobx-react';
import * as React from 'react';
import i18n from '../../../constants/i18n';
import { Act } from '../../../shared';
import ATag from '../../common/ATag';
import GiButton from '../../common/GiButton';
import EditableTextInput from '../../common/EditableTextInput';
import EditActsProfileLayout from '../EditActsProfileLayout';
import EditActsOverviewSocial from './EditActOverviewSocial';
import EditActsOverviewTag from './EditActOverviewTag';
import EditArtistProfileOverviewAvailability from './EditActOverviewAvailability';
import { useActStore } from '../../../stores/act';
import { GeneralRegularNormal, TextH6 } from '../../common/Typography';
import colors from '../../../constants/colors';
import { Box } from 'react-native-kondo';
import sexpressClient from '../../../utils/sexpress';
import { UseUserStore } from '../../../stores/user';
import { useArtistStore } from '../../../stores/artist';
import toast from 'react-hot-toast';
import { IoMdWarning } from 'react-icons/io';
import { useNavigate } from 'react-router-dom';

const EditActOverview = observer(() => {
  const actStore = useActStore();
  const act = actStore.getCurrentEditAct();
  const navigate = useNavigate();
  const userStore = UseUserStore();
  const me = userStore.getMe();
  const artistStore = useArtistStore();

  const onSave = async (payload: Partial<Act>) => {
    try {
      await actStore.editMeAct({ ...payload, actId: act!.id });
      toast.success(i18n('EditActOverview.saved', 'Saved'));
    } catch (e) {
      const err = e as Error;
      toast.error(err.message);
    }
  };

  const onDeleteAct = async () => {
    try {
      const updatedArtist = await sexpressClient.deleteAct({
        actId: act!.id,
      });
      artistStore.manuallyEditMeArtist(updatedArtist);
      toast.success(i18n('EditActOverview.deleteAct', 'Act deleted!'));
      navigate(`/artists/${me!.artist.id}`);
    } catch (e) {
      const err = e as Error;
      toast.error(err.message);
    }
  };

  if (!act) {
    return <EditActsProfileLayout />;
  }

  const unconnectedAccounts = act.members.filter(
    (member) => !member.stripe.isAccountConnected,
  );

  return (
    <EditActsProfileLayout>
      <EditableTextInput
        value={act!.name}
        label={i18n('EditActOverview.name', 'Act Name')}
        onSave={(name) => onSave({ name })}
        style={{ marginBottom: 24 }}
      />
      <EditableTextInput
        style={{ marginBottom: 24 }}
        value={act!.location}
        label={i18n(
          'EditActOverview.location(city,...)',
          'Location (city, province/state)',
        )}
        onSave={(location) => onSave({ location })}
      />
      <EditArtistProfileOverviewAvailability />
      {unconnectedAccounts.length > 0 ? (
        <Box mb={5} flexDirection="row" alignItems="center">
          <IoMdWarning size={24} color={colors.error} />
          <TextH6 style={{ marginLeft: 5 }}>
            {i18n(
              'EditActOverview.stripeWarning',
              'The following member(s) did not setup their banking informations',
            )}
          </TextH6>
        </Box>
      ) : undefined}
      {unconnectedAccounts.length > 0 ? (
        <Box mb={24} pl={30}>
          {unconnectedAccounts.map((member) => (
            <TextH6 key={String(member.id)} style={{ color: colors.error }}>
              - {member.name}
            </TextH6>
          ))}
        </Box>
      ) : undefined}
      <EditActsOverviewTag />
      <EditableTextInput
        style={{ marginBottom: 24 }}
        value={act!.bio}
        textArea
        label={i18n('EditActOverview.about', 'About')}
        onSave={(bio) => onSave({ bio })}
        placeholder={i18n(
          'EditActOverview.bioPlaceholder',
          'Write a bit about your act...',
        )}
      />
      <EditActsOverviewSocial />
      <Box
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
        mt={30}
      >
        <ATag style={{ maxWidth: 202, width: '100%' }} href={`/acts/${act.id}`}>
          <GiButton label={i18n('EditActOverview.viewPage', 'View Page')} />
        </ATag>
        <GeneralRegularNormal
          onClick={onDeleteAct}
          style={{
            color: colors.red,
            textDecoration: 'underline',
            alignSelf: 'flex-end',
            cursor: 'pointer',
          }}
        >
          {i18n('EditActOverview.deleteAct', 'Delete Act')}
        </GeneralRegularNormal>
      </Box>
    </EditActsProfileLayout>
  );
});

export default EditActOverview;
