export default {
  'LandingPopularMusiciansSection.Title': {
    en: 'For your next event',
    fr: 'Pour votre prochain événement',
  },
  'LandingPopularMusiciansSection.ButtonAllMusicians': {
    en: 'Browse all musicians',
    fr: 'Parcourir tous les musiciens',
  },
};
