export default {
  'EditActPricing.saved': { en: 'Saved', fr: 'Enregistrée' },
  'EditActPricing.titlebank': { en: 'Banking', fr: 'Bancaire' },
  'EditActPricing.stripeWarning': {
    en: "Act owner must setup its banking information. People won't be able to send request to your act otherwise.",
    fr: "Le propriétaire de l'ACT doit configurer ses informations bancaires. Les gens ne pourront pas envoyer de demande à votre acte autrement.",
  },
  'EditActPricing.stripeWarningButton': { en: 'Connect', fr: 'Relier' },
  'EditActPricing.stripeDone': {
    en: 'Act owner bank account is connected  ✅',
    fr: 'Le compte bancaire du propriétaire ACT est connecté ✅',
  },
  'EditActPricing.title': { en: 'Gig Fees', fr: 'Frais de concert' },
  'EditActPricing.subtext': {
    en: 'Gig fees are used to give the customer an idea of how much a gig may cost before submitting a request to book.',
    fr: "Les frais de concert sont utilisés pour donner au client une idée du montant qu'un concert peut coûter avant de soumettre une demande de réservation.",
  },
  'EditActPricing.errorInvalidNumber': {
    en: 'Invalid number.',
    fr: 'Numéro invalide.',
  },
  'EditActPricing.transportDriver': {
    en: 'Transport (driver)',
    fr: 'Transport (conducteur)',
  },
  'EditActPricing.transportPassenger': {
    en: 'Transport (passenger)',
    fr: 'Transport (passager)',
  },
  'EditActPricing.adminfees': {
    en: 'Administrative fees',
    fr: 'Taxes administratives',
  },
  'EditActPricing.meetingFees': { en: 'Meeting fees', fr: 'Frais de réunion' },
  'EditActPricing.inOrderToReceiveDirectPayment': {
    en: 'In order to receive direct payment, act owner must setup its banking information',
    fr: "Afin de recevoir un paiement direct, le propriétaire de l'ACT doit configurer ses informations bancaires",
  },
};
