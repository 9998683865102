import querystring from 'querystring';
import { BAD_REQUEST, EXPIRED_TOKEN } from './restCodes';
import config from '../config';
import stores from '../stores';
import { Artist, User, Act, SignUpPayload } from '../shared';

const BASE_HEADERS = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
  'Cache-Control': 'no-cache',
};

export interface AuthResponse {
  tokenType: string;
  accessToken: string;
  refreshToken: string;
  expires: number;
}

// ----------------Act

export function initAct(): Promise<Act> {
  return post('/me/artist/act');
}

export function editMeAct(payload: Partial<Act> & { actId: Act['id'] }) {
  return put('/me/artist/act', payload);
}

// -----------------------------

// ----------------Artist

export function initArtist(): Promise<Artist> {
  return post('/me/artist');
}

export function editMeArtist(payload: Partial<Artist>) {
  return put('/me/artist', payload);
}

// -----------------------------

// ----------------Mailchimp

export function subToNewsLetter(payload: { email: string }) {
  return post('/mailchimp', payload);
}

// -----------------------------

// -------------Auth-------------

export function authGoogle(payload: {
  accessToken: string;
}): Promise<AuthResponse> {
  return post('/authGoogle', payload);
}

export function refreshAuth(payload: {
  refreshToken: string;
}): Promise<AuthResponse> {
  return post('/authRefresh', payload);
}

export function login(payload: any) {
  // TODO: type this
  return post('/login', payload);
}

export function signup(payload: SignUpPayload) {
  return post('/signup', payload);
}

// -----------------------------

export function getMe(): Promise<User> {
  return get('/me');
}

function get(url: string, params = {}) {
  const queryString = '?' + querystring.stringify(params);
  return _fetch(config.apiURL + url + queryString, {
    method: 'GET',
    headers: getHeaders(),
  });
}

function post(url: string, data = {}) {
  return _fetch(config.apiURL + url, {
    method: 'POST',
    headers: getHeaders(),
    body: JSON.stringify(data),
  });
}

function put(url: string, data = {}) {
  return _fetch(config.apiURL + url, {
    method: 'PUT',
    headers: getHeaders(),
    body: JSON.stringify(data),
  });
}

// @ts-ignore
async function _fetch(url: string, options: any) {
  try {
    const res = await fetch(url, options);
    if (res.status >= BAD_REQUEST) {
      if (res.status === EXPIRED_TOKEN) {
        await stores.user.refreshAuth();
        options.headers = getHeaders();
        return _fetch(url, options);
      } else {
        const resJson = await res.json();
        throw resJson.error;
      }
    }

    return res.json();
  } catch (errorObject) {
    throw new Error(errorObject as string);
  }
}

export function getHeaders() {
  const accessToken = window.localStorage.getItem('at');

  if (accessToken) {
    return {
      ...BASE_HEADERS,
      authorization: `Bearer ${accessToken}`,
    };
  }
  return BASE_HEADERS;
}

export const sexpressConfig = {
  baseURL: config.apiURL,
  getHeaders: getHeaders,
  onError: async (status: number, error: Error, retry: any) => {
    if (status === EXPIRED_TOKEN) {
      await stores.user.refreshAuth();
      const res = await retry();
      return res;
    } else {
      throw error;
    }
  },
};

export function uploadMediaToAWS(
  presignedURL: string,
  file: any,
): Promise<XMLHttpRequest> {
  return new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();

    xhr.open('PUT', presignedURL);
    xhr.onreadystatechange = () => {
      if (xhr.readyState === 4) {
        resolve(xhr);
      } else if (xhr.status !== 200) {
        console.warn(xhr.response);
        reject(xhr.status);
      }
    };

    xhr.setRequestHeader('Content-Type', 'multipart/form-data');

    xhr.send(file);
  });
}
