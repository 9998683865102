import { observer } from 'mobx-react-lite';
import * as React from 'react';
import styled from 'styled-components';
import i18n from '../../constants/i18n';
import { useUiStore } from '../../stores/ui';
import {
  GeneralRegularLarge,
  GeneralRegularNormal,
} from '../common/Typography';
import { UseUserStore } from '../../stores/user';
import colors from '../../constants/colors';
import ATag from './ATag';
import urls from '../../utils/urls';
import images from '../../constants/images';

const PageLayoutFooter = observer(
  ({
    extraFooterPadding,
    style,
  }: {
    extraFooterPadding?: number;
    style?: React.CSSProperties;
  }) => {
    const uiStore = useUiStore();
    const userStore = UseUserStore();

    return (
      <Main style={{ ...style, paddingBottom: extraFooterPadding }}>
        <TopContainer>
          <RowContact>
            <Contact
              label={i18n('PageLayoutFooter.aboutus', 'About Us')}
              url={urls.aboutUrl}
            />
            <GeneralRegularLarge
              style={{
                fontSize: 18,
                color: colors.black,
                marginLeft: 5,
                marginRight: 5,
              }}
            >
              /
            </GeneralRegularLarge>
            <Contact
              label={i18n('PageLayoutFooter.blog', 'Blog')}
              url={urls.blogUrl}
            />
            <GeneralRegularLarge
              style={{
                fontSize: 18,
                color: colors.black,
                marginLeft: 5,
                marginRight: 5,
              }}
            >
              /
            </GeneralRegularLarge>
            <Contact
              label={i18n('PageLayoutFooter.support', 'Support')}
              url={urls.blogUrl}
            />
          </RowContact>

          <SocialRow>
            <Social icon={images.instagram} url={urls.instagram} />
            <Social icon={images.facebook} url={urls.facebook} />
            <Social icon={images.twitter} url={urls.twitter} />
            <Social icon={images.socialLinkedIn} url={urls.linkedin} />
          </SocialRow>
        </TopContainer>
        <SubFooter>
          <GeneralRegularNormal
            style={{ marginBottom: uiStore.isMobile ? 10 : 0 }}
          >
            © GigLinked {new Date().getFullYear()}
          </GeneralRegularNormal>
          <ATag target="_blank" href={'/terms'}>
            <GeneralRegularNormal
              style={{
                textDecoration: 'underline',
                marginBottom: uiStore.isMobile ? 10 : 0,
              }}
            >
              {i18n('LandingFooter.terms', 'Terms and Conditions')}
            </GeneralRegularNormal>
          </ATag>
          <ATag target="_blank" href={'/privacy'}>
            <GeneralRegularNormal
              style={{
                textDecoration: 'underline',
                marginBottom: uiStore.isMobile ? 10 : 0,
              }}
            >
              {i18n('LandingFooter.policy', 'Privacy Policy')}
            </GeneralRegularNormal>
          </ATag>
          <GeneralRegularNormal
            style={{ cursor: 'pointer', marginRight: '3rem' }}
            onClick={() =>
              userStore.setBrowerLanguage(
                userStore?.browserLanguage === 'fr' ? 'en' : 'fr',
              )
            }
          >
            {userStore?.browserLanguage === 'fr' ? 'English' : 'Français'}
          </GeneralRegularNormal>
        </SubFooter>
      </Main>
    );
  },
);

const Contact = ({ label, url }: { label: string; url: string }) => {
  return (
    <ATag target="_blank" href={url}>
      <GeneralRegularLarge style={{ fontSize: 18, color: colors.black }}>
        {label}
      </GeneralRegularLarge>
    </ATag>
  );
};

const Social = ({ icon, url }: { icon: string; url: string }) => {
  return (
    <ATag target="_blank" href={url}>
      <SocialIcon src={icon} />
    </ATag>
  );
};

const Main = styled.div`
  display: flex;
  flex-direction: column;
  padding: 26px 52px 26px 52px;
  box-sizing: border-box;
  background-color: ${(props) => props.theme.colors.grey};
  @media (max-width: ${(props) => props.theme.breakPoints.mobile}px) {
    padding: 40px 0px 25px 0px;
  }
`;

const SocialIcon = styled.img`
  width: 16px;
  height: 16px;
  object-fit: contain;
  margin-right: 10px;
`;

const SocialRow = styled.div`
  display: flex;
  @media (max-width: ${(props) => props.theme.breakPoints.mobile}px) {
  }
`;

const RowContact = styled.div`
  display: flex;
  @media (max-width: ${(props) => props.theme.breakPoints.mobile}px) {
    margin-bottom: 29px;
  }
`;

const TopContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  align-items: center;
  box-sizing: border-box;
  @media (max-width: ${(props) => props.theme.breakPoints.mobile}px) {
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 20px;
    padding: 0px 25px 0px 25px;
  }
`;

const SubFooter = styled.div`
  display: flex;
  flex-direction: row;
  border-width: 1px 0px 0px 0px;
  border-color: ${(props) => props.theme.colors.black};
  border-style: solid;
  padding: 10px 0px 0px 0px;
  justify-content: space-between;
  box-sizing: border-box;
  @media (max-width: ${(props) => props.theme.breakPoints.mobile}px) {
    flex-direction: column;
    padding: 19px 25px 0px 25px;
    justify-content: flex-start;
  }
`;

export default PageLayoutFooter;
