import { observer } from 'mobx-react';
import * as React from 'react';
import styled from 'styled-components';

import i18n from '../../../constants/i18n';
import { Artist } from '../../../shared';
import { useArtistStore } from '../../../stores/artist';
import { UseUserStore } from '../../../stores/user';
import EditArtistProfileLayout from '../EditArtistProfileLayout';
import images from '../../../constants/images';
import GiDraggableList from '../../common/GiDraggableList';
import sexpressClient from '../../../utils/sexpress';
import GiSimpleButton from '../../common/GiSimpleButton';
import ImagePickerHandler from '../../common/ImagePickerHandler';
import EditGalleryCell from '../../common/EditGalleryCell';
import toast from 'react-hot-toast';
import { isArrayEmpty } from '../../../utils/arrays';

const EditArtistProfileGallery = observer(() => {
  const artistStore = useArtistStore();
  const userStore = UseUserStore();
  const me = userStore.getMe();
  const [loading, setLoading] = React.useState(false);
  const [loadingMedia, setLoadingMedia] = React.useState(false);
  const [selectedPictures, setSelectedPictures] = React.useState<string[]>([]);

  const onSave = async (payload: Partial<Artist>) => {
    try {
      await artistStore.editMeArtist(payload);
    } catch (e) {
      const err = e as Error;
      toast.error(err.message);
    } finally {
      setLoading(false);
      setLoadingMedia(false);
    }
  };

  const handleReorder = async (gallery: Artist['gallery']) => {
    try {
      await sexpressClient.editMeArtistReorderGallery({
        gallery,
      });
      await artistStore.fetchMeArtist();
    } catch (e) {
      const err = e as Error;
      toast.error(err.message);
    }
  };

  const onSelectImage = (url: string) => {
    const newSelectedPictures = [...selectedPictures];
    const index = newSelectedPictures.findIndex((s) => s === url);
    if (index === -1) {
      newSelectedPictures.push(url);
    } else {
      newSelectedPictures.splice(index, 1);
    }
    setSelectedPictures(newSelectedPictures);
  };

  if (!me || !me.artist) {
    return <div />;
  }

  return (
    <EditArtistProfileLayout>
      <Main>
        <ButtonRow>
          <ImagePickerHandler
            onChangePicture={(data) => onSave({ gallery: [data] })}
            onLoadingStateChange={loadingMedia ? undefined : setLoadingMedia}
          >
            <GiSimpleButton
              label={i18n('EditArtistProfileGallery.upload', 'Upload')}
              loading={loadingMedia}
              icon={images.cameraIcon}
              style={{ width: 99.25 }}
            />
          </ImagePickerHandler>
          <GiSimpleButton
            style={{
              marginLeft: 5,
              backgroundColor: isArrayEmpty(selectedPictures)
                ? undefined
                : '#4361EE',
            }}
            textColor={isArrayEmpty(selectedPictures) ? undefined : 'white'}
            label={i18n('EditArtistProfileGallery.delete', 'Delete')}
            disabled={isArrayEmpty(selectedPictures)}
            loading={loading}
            onClick={() => {
              setLoading(true);
              onSave({
                gallery: selectedPictures,
              });
            }}
          />
        </ButtonRow>

        <GiDraggableList<string>
          data={me.artist.gallery}
          onReorder={handleReorder}
          //   direction="horizontal"
          renderItem={(url) => (
            <EditGalleryCell
              src={url}
              isSelected={selectedPictures.includes(url)}
              onSelect={onSelectImage}
            />
          )}
        />
      </Main>
    </EditArtistProfileLayout>
  );
});

const ButtonRow = styled.div`
  display: flex;
  position: absolute;
  top: 0px;
  right: 0px;
  margin-top: -55px;
  @media (max-width: ${(props) => props.theme.breakPoints.mobile}px) {
    margin-top: -20px;
    justify-content: space-between;
    width: 100%;
  }
`;

const Main = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 800px;
  position: relative;
  width: 100%;
  align-self: center;
  box-sizing: border-box;
  @media (max-width: ${(props) => props.theme.breakPoints.mobile}px) {
    padding-top: 30px;
  }
`;

export default EditArtistProfileGallery;
