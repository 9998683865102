import * as React from 'react';
import styled from 'styled-components';
import colors from '../../constants/colors';
import i18n from '../../constants/i18n';
import { Transaction } from '../../shared';
import { useUiStore } from '../../stores/ui';
import sexpressClient from '../../utils/sexpress';
import GiButton from '../common/GiButton';
import {
  GeneralRegularNormal,
  GeneralSemiBoldNormal,
  TextH2,
} from '../common/Typography';
import { EditDataTransactionPayload } from './CompleteTransactionController';
import toast from 'react-hot-toast';

const BookingRecap = ({
  transaction,
  data,
  onComplete = () => null,
}: {
  transaction: Transaction;
  data: EditDataTransactionPayload;
  onComplete: () => void;
}) => {
  const [loading, setLoading] = React.useState(false);
  const { country, province, ...rest } = data;
  const uiStore = useUiStore();

  const onPressPay = async () => {
    try {
      setLoading(true);
      await sexpressClient.updateTransactionState({
        action: 'accept',
        transactionId: transaction.id,
        payload: {
          country: country.value,
          province: province.value,
          ...rest,
        },
      });
      await onComplete();
      uiStore.closePopup();
    } catch (e) {
      setLoading(false);
      const err = e as Error;
      toast.error(err.message);
    }
  };

  return (
    <Main>
      <TextH2
        style={{
          color: colors.whiteText,
          marginBottom: 36,
          alignSelf: 'center',
          textAlign: 'center',
        }}
      >
        {i18n('BookingRecap.title', 'Summary')}
      </TextH2>
      <MainContainer>
        <FeeBlock
          label={i18n('BookingRecap.deposit', 'Deposit Amount (tx in.)')}
          message={`${transaction.priceData.requiredDepositFormatted} $`}
          withSeparator
        />
        <FeeBlock
          label={i18n('BookingRecap.quote', 'Quote (deposit in.)')}
          message={`${transaction.priceData.performancePriceFormatted} $`}
        />
        <FeeBlock
          label={i18n('BookingRecap.Giglinkedfees', 'GigLinked Fees')}
          message={`${transaction.priceData.gigLinkedCutPriceFormatted} $`}
          withSeparator
        />
        <FeeBlock
          label={i18n('BookingRecap.subtotal', 'Subtotal')}
          message={`${transaction.priceData.subTotalPriceFormatted} $`}
        />
        <FeeBlock
          label={i18n('BookingRecap.gst', 'GST')}
          message={`${transaction.priceData.gstPriceFormatted} $`}
        />
        <FeeBlock
          label={i18n('BookingRecap.qst', 'QST')}
          message={`${transaction.priceData.qstPriceFormatted} $`}
          withSeparator
        />
        <FeeBlock
          label={i18n('BookingRecap.total', 'Total')}
          message={`${transaction.priceData.finalTotalPriceFormatted} $`}
          textStyle={{ fontSize: 22 }}
        />
      </MainContainer>

      <GeneralSemiBoldNormal
        style={{
          color: colors.whiteText,
          marginTop: 20,
        }}
      >
        {i18n(
          'BookingRecap.recapText',
          'The deposit amount will be charge right now and the remaining amount will be charge after the event completion.',
        )}
      </GeneralSemiBoldNormal>

      <GiButton
        style={{ marginTop: 30, alignSelf: 'center' }}
        label={i18n('BookingRecap.pay', 'Confirm and Pay')}
        onClick={onPressPay}
        loading={loading}
      />
    </Main>
  );
};

const FeeBlock = ({
  label,
  message,
  textStyle = {},
  withSeparator,
}: {
  label: string;
  message: string;
  textStyle?: React.CSSProperties;
  withSeparator?: boolean;
}) => {
  return (
    <>
      <Content>
        <GeneralSemiBoldNormal
          style={{
            width: '40%',
            marginRight: 15,
            ...textStyle,
          }}
        >
          {label}
        </GeneralSemiBoldNormal>
        <GeneralRegularNormal
          style={{
            width: '60%',
            color: colors.whiteText,
            whiteSpace: 'pre-line',
            textAlign: 'right',
            ...textStyle,
          }}
        >
          {message}
        </GeneralRegularNormal>
      </Content>

      {withSeparator && <Separator />}
    </>
  );
};

const MainContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.darkBlue};
  padding: 30px 25px;
`;

const Content = styled.div`
  display: flex;
  flex-direction: row;
`;

const Separator = styled.div`
  display: flex;
  width: 100%;
  height: 1px;
  margin-top: 22px;
  margin-bottom: 22px;
  background-color: rgba(255, 255, 255, 0.25);
`;

const Main = styled.div`
  display: flex;
  box-sizing: border-box;
  flex-direction: column;
  align-self: center;
  padding-bottom: 63px;
  @media (max-width: ${(props) => props.theme.breakPoints.mobile}px) {
    padding: 0px 20px 40px 20px;
  }
`;

export default BookingRecap;
