import { Entity, EntityType } from '../../../shared';
import UserSaved from '../../../stores/user';

export function isEntityOwner({
  entity,
  entityType,
}: {
  entity: Entity;
  entityType: EntityType;
}) {
  const userStore = UserSaved;
  let isOwner = false;

  if (entityType === 'artist') {
    isOwner = userStore.me?.artist?.id === entity.id;
  } else if (entityType === 'act') {
    // @ts-ignore
    isOwner = userStore.me?.artist?.id === entity.owner.id;
  }
  return isOwner;
}
