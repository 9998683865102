import { observer } from 'mobx-react';
import * as React from 'react';
import i18n from '../../../constants/i18n';
import { Act } from '../../../shared';
import { UseUserStore } from '../../../stores/user';
import EditableTextInput from '../../common/EditableTextInput';
import toast from 'react-hot-toast';
import colors from '../../../constants/colors';
import { GeneralRegularNormal, TextH3, TextH6 } from '../../common/Typography';
import { useActStore } from '../../../stores/act';
import EditActsProfileLayout from '../EditActsProfileLayout';
import { IoMdWarning } from 'react-icons/io';
import { Box } from 'react-native-kondo';
import GiButton from '../../common/GiButton';
import styled from 'styled-components';
import AddBankingInformationModal from '../../settings/AddBankingInformationModal';
import { useUiStore } from '../../../stores/ui';
import StripeSecurePayments from '../../settings/StripeSecurePayments';

const EditActPricing = observer(() => {
  const actStore = useActStore();
  const userStore = UseUserStore();
  const uiStore = useUiStore();
  const act = actStore.getCurrentEditAct();
  const me = userStore.getMe();

  const onSave = async (payload: Partial<Act>) => {
    try {
      await actStore.editMeAct({ ...payload, actId: act!.id });
      toast.success(i18n('EditActPricing.saved', 'Saved'));
    } catch (e) {
      const err = e as Error;
      toast.error(err.message);
    }
  };

  if (!me || !act) {
    return <div />;
  }

  return (
    <EditActsProfileLayout>
      <BankinRow>
        <TextH3 style={{ marginRight: 12, color: colors.black }}>
          {i18n('EditActPricing.titlebank', 'Banking')}
        </TextH3>
        <StripeSecurePayments
          style={{ justifyContent: 'center', margin: '1rem' }}
        />
      </BankinRow>

      {!act.canBeBooked ? (
        <Box mb={24}>
          <Box
            mb={12}
            flexDirection="row"
            alignItems="center"
            style={{
              // @ts-ignore
              cursor: 'pointer',
            }}
            onClick={() =>
              uiStore.openPopup({
                popupDisableTapOut: true,
                renderContent: () => <AddBankingInformationModal />,
              })
            }
          >
            <IoMdWarning size={24} color={colors.error} />
            <TextH6 style={{ textDecoration: 'underline', marginLeft: 5 }}>
              {i18n(
                'EditActPricing.inOrderToReceiveDirectPayment',
                'In order to receive direct payment, act owner must setup its banking information',
              )}
            </TextH6>
          </Box>
          <GiButton
            style={{ height: 36 }}
            onClick={() =>
              uiStore.openPopup({
                popupDisableTapOut: true,
                renderContent: () => <AddBankingInformationModal />,
              })
            }
            label={i18n('EditActPricing.stripeWarningButton', 'Connect')}
          />
        </Box>
      ) : (
        <Box mb={24}>
          <TextH6 style={{ marginBottom: 12 }}>
            {i18n(
              'EditActPricing.stripeDone',
              'Act owner bank account is connected  ✅',
            )}
          </TextH6>
        </Box>
      )}
      <TextH3 style={{ marginBottom: 16, color: colors.black }}>
        {i18n('EditActPricing.title', 'Gig Fees')}
      </TextH3>
      <GeneralRegularNormal style={{ maxWidth: 556, marginBottom: 16 }}>
        {i18n(
          'EditActPricing.subtext',
          'Gig fees are used to give the customer an idea of how much a gig may cost before submitting a request to book.',
        )}
      </GeneralRegularNormal>

      <EditableTextInput
        style={{ marginBottom: 24 }}
        value={act.priceTransportDriver}
        label={i18n('EditActPricing.transportDriver', 'Transport (driver)')}
        type="number"
        onSave={(priceTransportDriver) => {
          if (Number(priceTransportDriver) >= 0) {
            onSave({ priceTransportDriver });
          } else {
            toast.error(
              i18n('EditActPricing.errorInvalidNumber', 'Invalid number.'),
            );
          }
        }}
      />
      <EditableTextInput
        style={{ marginBottom: 24 }}
        value={act.priceTransportPassenger}
        label={i18n(
          'EditActPricing.transportPassenger',
          'Transport (passenger)',
        )}
        type="number"
        onSave={(priceTransportPassenger) => {
          if (Number(priceTransportPassenger) >= 0) {
            onSave({ priceTransportPassenger });
          } else {
            toast.error(
              i18n('EditActPricing.errorInvalidNumber', 'Invalid number.'),
            );
          }
        }}
      />
      <EditableTextInput
        style={{ marginBottom: 24 }}
        value={act.priceAdmin}
        label={i18n('EditActPricing.adminfees', 'Administrative fees')}
        type="number"
        onSave={(priceAdmin) => {
          if (Number(priceAdmin) >= 0) {
            onSave({ priceAdmin });
          } else {
            toast.error(
              i18n('EditActPricing.errorInvalidNumber', 'Invalid number.'),
            );
          }
        }}
      />
      <EditableTextInput
        style={{ marginBottom: 24 }}
        value={act.priceMeetingFees}
        label={i18n('EditActPricing.meetingFees', 'Meeting fees')}
        type="number"
        onSave={(priceMeetingFees) => {
          if (Number(priceMeetingFees) >= 0) {
            onSave({ priceMeetingFees });
          } else {
            toast.error(
              i18n('EditActPricing.errorInvalidNumber', 'Invalid number.'),
            );
          }
        }}
      />
    </EditActsProfileLayout>
  );
});

const BankinRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 16px;
`;

export default EditActPricing;
