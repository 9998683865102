import * as React from 'react';
import styled from 'styled-components';
import GoogleLogin from 'react-google-login';
import config from '../../config';
import { UseUserStore } from '../../stores/user';
import { ClipLoader } from 'react-spinners';
import colors from '../../constants/colors';
import images from '../../constants/images';
import i18n from '../../constants/i18n';
import toast from 'react-hot-toast';
import GiglinkedButton from './GiglinkedButton';

const GoogleAuthButton = ({
  onLogin = () => null,
  signUp = false,
}: {
  onLogin: () => void;
  signUp?: boolean;
}) => {
  const [loading, setLoading] = React.useState(false);
  const userStore = UseUserStore();

  const me = userStore.getMe();
  const isLoggedIn = !!me;

  const onLoginWithGoogle = async (response: {
    accessToken: string;
    error: any;
  }) => {
    if (response.error) {
      if (
        response.error !== 'idpiframe_initialization_failed' &&
        response.error !== 'popup_closed_by_user'
      ) {
        toast.error(response.error);
      }
    } else {
      try {
        if (isLoggedIn) {
          throw new Error(
            i18n(
              'RegistrationUser.userLoggedIn',
              'You are logged in! Log out and try again.',
            ),
          );
        }
        setLoading(true);
        await userStore.loginWithGoogle({ accessToken: response.accessToken });
        onLogin();
        // router.replace('/profile');
      } catch (e) {
        const err = e as Error;
        toast.error(err.message);
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <GoogleLogin
      clientId={config.googleClientId}
      render={(renderProps) => (
        <BaseButton
          img={images.googleLoginButton}
          onClick={renderProps.onClick}
          loading={loading}
          signUp={signUp}
        />
      )}
      buttonText="Login"
      // @ts-ignore
      onSuccess={onLoginWithGoogle}
      // @ts-ignore
      onFailure={onLoginWithGoogle}
      cookiePolicy={'single_host_origin'}
    />
  );
};

const BaseButton = ({
  img,
  onClick,
  loading,
  signUp,
}: {
  img: string;
  onClick?: any;
  loading?: boolean;
  signUp?: boolean;
}) => {
  return (
    <BaseButtonContainer onClick={onClick}>
      {loading ? (
        <LoadingContainer>
          <ClipLoader color={colors.black} size={24} />
        </LoadingContainer>
      ) : (
        <>
          <GiglinkedButton
            label={
              signUp
                ? i18n(
                    'SocialButton.googleSignup',
                    'Create account with Google',
                  )
                : i18n('SocialButton.googleSignin', 'Sign in with Google')
            }
            style={{ color: colors.buttonTextColor, border: 'none' }}
          />
          <Logo src={img} alt="" />
        </>
      )}
    </BaseButtonContainer>
  );
};

const BaseButtonContainer = styled.div`
  height: 52px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  cursor: pointer;
  transition-timing-function: ease-in;
  transition: 0.2s;
  text-align: center;
  border-radius: 12px;
  border: 2px solid ${colors.buttonTextColor};
  background-color: ${(props) => props.theme.colors.white};
  padding: 10px;
  &:hover {
    cursor: pointer;
    text-decoration: none;
    opacity: 0.6;
    transition-timing-function: ease-in;
    transition-timing-function: ease-out;
    transition: 0.25s;
  }
  @media (max-width: ${(props) => props.theme.breakPoints.mobile}px) {
    align-items: center;
  }
`;

const Logo = styled.img`
  width: 24px;
  height: 24px;
  margin-left: 10px;
  margin-top: auto;
  margin-bottom: auto;
`;

const LoadingContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex: 1;
`;

export { GoogleAuthButton };
