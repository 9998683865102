export default {
  'NavBarContent.browseMusicians': {
    en: 'Browse Musicians',
    fr: 'Parcourir les musiciens',
  },
  'NavBarContent.myProfile': { en: 'My profile', fr: 'Mon profil' },
  'NavBarContent.about': { en: 'About', fr: 'À propos' },
  'NavBarContent.signup': { en: 'Sign up', fr: "S'inscrire" },
  'NavBarContent.discover': { en: 'Discover', fr: 'Découvrir' },
  'NavBarContent.howItWorks': {
    en: 'How It Works',
    fr: 'Comment ça fonctionne',
  },
};
