export default {
  'BookingContact.title': { en: 'Contact Information', fr: 'Coordonnées' },
  'BookingContact.subTitle': {
    en: 'This information will only be seen by the artist once the gig is confirmed.',
    fr: "Ces informations ne seront vues par l'artiste qu'une fois le concert confirmé.",
  },
  'BookingContact.firstName': { en: 'First name', fr: 'Prénom' },
  'BookingContact.lastName': { en: 'Last name', fr: 'Nom de famille' },
  'BookingContact.phone': { en: 'Phone Number', fr: 'Numéro de téléphone' },
  'BookingContact.phoneDetails': {
    en: 'Please provide a phone number that the artist can use to contact you at the gig.',
    fr: "Veuillez fournir un numéro de téléphone que l'artiste peut utiliser pour vous contacter au concert.",
  },
};
