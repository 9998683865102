import amplitude from 'amplitude-js';
import config from '../../config';
import { UserStore } from '../../stores/user';
import { ANALYTICS_EVENTS } from './AnalyticsEvents';

function logAmplitudeAnalytics(event: string, properties?: any) {
  amplitude.getInstance().logEvent(event, properties);
}

export function initAmplitudeAnalytics() {
  amplitude.getInstance().init(config.amplitudeAPIKey);
}

export function setAmplitudeUserIdentity(user: UserStore['me']) {
  if (user?.id) {
    amplitude.getInstance().setUserId(user.id);
  }

  const userProperties: {
    artistId?: string;
    email?: string;
    userSetArtistBankingInfo?: string;
    firstName?: string;
    lastName?: string;
  } = {};

  if (user?.artist?.id) {
    userProperties.artistId = user.artist.id;
  }
  userProperties.userSetArtistBankingInfo = user?.artist?.stripe?.accountId
    ? 'true'
    : 'false';
  if (user?.email) {
    userProperties.email = user.email;
  }
  if (user?.firstName) {
    userProperties.firstName = user.firstName;
  }
  if (user?.lastName) {
    userProperties.lastName = user.lastName;
  }

  amplitude.getInstance().setUserProperties(userProperties);
}

export function logUserSignUp() {
  logAmplitudeAnalytics(ANALYTICS_EVENTS.SIGNUP);
}

export function logWebsiteVisit() {
  logAmplitudeAnalytics(ANALYTICS_EVENTS.WEBSITE_VISIT);
}

export function logUserSearchProfiles(properties: {
  keywords: string;
  location: string;
}) {
  logAmplitudeAnalytics(ANALYTICS_EVENTS.USER_SEARCH_PROFILES, properties);
}

export function logUserClickMakeAnOffer() {
  logAmplitudeAnalytics(ANALYTICS_EVENTS.USER_CLICK_MAKE_AN_OFFER);
}

export function logUserClickedSendMessage() {
  logAmplitudeAnalytics(ANALYTICS_EVENTS.USER_CLICK_SEND_MESSAGE);
}

export function logPerformerAddedProfileTag(properties: { tagLabel: string }) {
  logAmplitudeAnalytics(ANALYTICS_EVENTS.PERFORMER_ADD_TAG, properties);
}
