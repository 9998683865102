/* eslint-disable */

import i18n from '../constants/i18n';

export function validateEmail(email: string) {
  // eslint-disable-next-line
  const re =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  if (!re.test(email)) {
    return false;
    // i18n('Validator.invalidEmail', 'Invalid email format.');
  } else return true;
}

export function validatePassword(password: string) {
  let isValid = true;
  let error = '';
  // eslint-disable-next-line
  const regex = /\d/;
  //   if (!password.match(regex)) {
  //     isValid = false;
  //     error = 'Invalid password.';
  //   }

  if (!password || !password.length) {
    isValid = false;
    error = i18n('Validator.invalidPassword', 'Invalid password.');
  }

  if (password.length < 8) {
    isValid = false;
    error = i18n(
      'Validator.invalidPasswordLength',
      'Password must have at least 8 characters.',
    );
  }

  if (!isValid) {
    return false;
  } else {
    return true;
  }
}
