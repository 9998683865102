import * as React from 'react';
import styled from 'styled-components';

import { Transaction, TransactionServiceType, ActionType } from '../../shared';
import { GeneralRegularNormal } from '../common/Typography';
import { observer } from 'mobx-react';
import { UseUserStore } from '../../stores/user';
import i18n from '../../constants/i18n';
import GiButton from '../common/GiButton';
import { Box } from 'react-native-kondo';

const RequestCellActionButton = observer(
  ({
    transaction,
    onAction,
  }: {
    transaction: Transaction;
    onAction: ({
      action,
      transaction,
    }: {
      action: ActionType;
      transaction: Transaction;
    }) => void;
  }) => {
    const userStore = UseUserStore();
    const me = userStore.getMe();

    const artist = me?.artist;
    let isOwner = false;
    const isBooker = transaction.booker.id === me?.id;
    const serviceType: TransactionServiceType = transaction.serviceType;
    if (serviceType === 'act' && artist) {
      if (artist.id === transaction.act?.owner?.id) {
        isOwner = true;
      }
    } else if (serviceType === 'artist' && !isBooker) {
      isOwner = true;
    }

    const renderPendingButtonsBasedOnUser = () => {
      if (isBooker) {
        return (
          <ButtonRow>
            <GiButton
              label={i18n('RequestCell.cancel', 'Cancel')}
              style={{ marginRight: 8, height: 26 }}
              onClick={() => onAction({ action: 'cancel', transaction })}
            />
          </ButtonRow>
        );
      } else if (isOwner) {
        return (
          <ButtonRow>
            <GiButton
              label={i18n('RequestCell.accept', 'Accept')}
              style={{ marginRight: 8, height: 26 }}
              onClick={() => onAction({ action: 'accept', transaction })}
              disabled={!isOwner}
            />
            <GiButton
              label={i18n('RequestCell.decline', 'Decline')}
              onClick={() => onAction({ action: 'decline', transaction })}
              disabled={!isOwner}
              style={{ height: 26 }}
            />
          </ButtonRow>
        );
      } else {
        return (
          <Box>
            <GeneralRegularNormal style={{ color: 'gray' }}>
              {i18n(
                'RequestCell.ownerText',
                'Only the act owner can accept this request.',
              )}
            </GeneralRegularNormal>
          </Box>
        );
      }
    };

    if (
      ['quotePending', 'quoteSent', 'quoteDeclined'].includes(
        transaction.status,
      )
    ) {
      return renderPendingButtonsBasedOnUser();
    }

    return null;
  },
);

const ButtonRow = styled.div`
  display: flex;

  @media (max-width: ${(props) => props.theme.breakPoints.mobile}px) {
  }
`;

export default RequestCellActionButton;
