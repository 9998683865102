import { observer } from 'mobx-react';
import * as React from 'react';
import i18n from '../../constants/i18n';
import colors from '../../constants/colors';
import { useNavigate } from 'react-router-dom';
import { Transaction } from '../../shared';
import { UseUserStore } from '../../stores/user';
import { format } from 'date-fns';
import { Column, Row } from '../common/Layout';
import GiglinkedButton from '../common/GiglinkedButton';
import { GeneralRegularNormal, TextH7 } from '../common/Typography';
import { capitalize } from '../../utils/string';

const InvoicesCard = observer(
  ({ transactions }: { transactions: Array<Transaction> }) => {
    const navigate = useNavigate();
    const userStore = UseUserStore();
    const isFrench = userStore?.browserLanguage === 'fr';

    return (
      <Column
        style={{
          width: '100%',
          margin: '1rem',
        }}
      >
        {transactions.map((transaction) => (
          <div
            key={transaction.id}
            style={{ borderBottom: '1px solid #D9D9D9' }}
          >
            <Row
              style={{ justifyContent: 'space-between', alignItems: 'center' }}
            >
              <TextH7 style={{ fontWeight: 500 }}>
                {transaction.eventType.length
                  ? capitalize(transaction.eventType)
                  : 'No name'}
              </TextH7>
              <GiglinkedButton
                label={i18n('InvoicesCard.invoice', 'Invoice')}
                onClick={
                  transaction.stripe?.receiptUrl
                    ? () => window.open(transaction.stripe.receiptUrl, '_blank')
                    : () => navigate(`/invoice/${transaction.id}`)
                }
                style={{
                  fontWeight: 600,
                  color: colors.buttonTextColor,
                  border: 'none',
                }}
              />
            </Row>
            <Row style={{ marginBottom: '0.5rem' }}>
              <GeneralRegularNormal style={{ width: '25%' }}>
                {i18n('InvoicesCard.date', 'Date')}
              </GeneralRegularNormal>
              {format(
                new Date(transaction!.eventDate),
                isFrench ? 'y.dd.MM' : 'y.MM.dd',
              )}
            </Row>
            <Row style={{ marginBottom: '0.5rem' }}>
              <GeneralRegularNormal style={{ width: '25%' }}>
                {' '}
                {i18n('InvoicesCard.amount', 'Amount')}
              </GeneralRegularNormal>
              ${transaction.priceData.subTotalPriceFormatted}
            </Row>
          </div>
        ))}
      </Column>
    );
  },
);

export default InvoicesCard;
