export default {
  'EditArtistPricing.saved': { en: 'Saved', fr: 'Enregistrée' },
  'EditArtistPricing.titlebank': { en: 'Banking', fr: 'Bancaire' },
  'EditArtistPricing.stripeWarning': {
    en: "You need to setup your banking information. People won't be able to send you request you until you do.",
    fr: "Vous devez configurer vos informations bancaires. Les gens ne pourront pas vous envoyer vous demander jusqu'à ce que vous le fassiez.",
  },
  'EditArtistPricing.stripeWarningButton': { en: 'Connect', fr: 'Relier' },
  'EditArtistPricing.stripeDone': {
    en: 'Your account is connected with your bank  ✅',
    fr: 'Votre compte est connecté à votre banque ✅',
  },
  'EditArtistPricing.title': { en: 'Gig Fees', fr: 'Frais de concert' },
  'EditArtistPricing.subtext': {
    en: 'Gig fees are used to give the customer an idea of how much a gig may cost before submitting a request to book.',
    fr: "Les frais de concert sont utilisés pour donner au client une idée du montant qu'un concert peut coûter avant de soumettre une demande de réservation.",
  },
  'EditArtistPricing.errorInvalidNumber': {
    en: 'Invalid number.',
    fr: 'Numéro invalide.',
  },
  'EditArtistPricing.forInformationalPurposesOnly': {
    en: 'For informational purposes only. Musician fees are used to give the booker an idea of what additional costs you may charge, and what goes into your pricing. Please note, when you receive a booking request, you can charge the amount you want, the information found below will have no impact on this.',
    fr: "À des fins d'information uniquement. Les frais de musicien sont utilisés pour donner au booker une idée des coûts supplémentaires que vous pouvez facturer et de ce qui va dans vos prix. Veuillez noter que lorsque vous recevez une demande de réservation, vous pouvez facturer le montant que vous souhaitez, les informations trouvées ci-dessous n'auront aucun impact à ce sujet.",
  },
  'EditArtistPricing.transportDriver': {
    en: 'Transport (driver)',
    fr: 'Transport (conducteur)',
  },
  'EditArtistPricing.transportPassenger': {
    en: 'Transport (passenger)',
    fr: 'Transport (passager)',
  },
  'EditArtistPricing.adminfees': {
    en: 'Administrative fees',
    fr: 'Taxes administratives',
  },
  'EditArtistPricing.meetingFees': {
    en: 'Meeting fees',
    fr: 'Frais de réunion',
  },
  'EditArtistPricing.inOrderToReceiveDirectPayment': {
    en: 'In order to receive direct payment, setup your banking info',
    fr: 'Afin de recevoir un paiement direct, configurez vos informations bancaires',
  },
};
