import { observer } from 'mobx-react';
import * as React from 'react';

import i18n from '../../../constants/i18n';
import { TextH6 } from '../../common/Typography';
import EditActsProfileLayout from '../EditActsProfileLayout';

import { useActStore } from '../../../stores/act';
import EditActSearchArtistBox from './EditActSearchArtistBox';
import EditActMembersListing from './EditActMembersListing';

const EditActMembers = observer(() => {
  const actStore = useActStore();
  const act = actStore.getCurrentEditAct();

  if (!act) {
    return <EditActsProfileLayout />;
  }

  return (
    <EditActsProfileLayout>
      <TextH6 style={{ marginBottom: 24 }}>
        {i18n('EditActMembers.inviteTitle', 'Invite artists to your act')}
      </TextH6>
      <EditActSearchArtistBox />
      <EditActMembersListing />
    </EditActsProfileLayout>
  );
});

export default EditActMembers;
