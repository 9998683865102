import * as React from 'react';
import { Box } from 'react-native-kondo';
import Tag from '../GiTag';
import { Entity } from '../../../shared';

const ProfileTagSection = ({ entity }: { entity: Entity }) => {
  if (!entity.tags || !entity.tags.length) {
    return null;
  }

  return (
    <Box flexDirection="row" flexWrap="wrap" mb={25}>
      {entity.tags.map((tag: any) => (
        <Tag key={tag.id} tag={tag} style={{ marginRight: 3, marginTop: 3 }} />
      ))}
    </Box>
  );
};

export default ProfileTagSection;
