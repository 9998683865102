import colors from '../constants/colors';
import { EventStatusEnum, EventType } from '../shared';
import { isBefore, isPast } from 'date-fns';

export const getEventStatus = (event: EventType | undefined) => {
  if (event) {
    const isEventFinished = isPast(new Date(event.eventDate));
    if (isEventFinished) return 'completed';
    else return event.status;
  }
};

export const getEventColor = (event: EventType | undefined) => {
  const isEventFinished = isBefore(new Date(event!.eventDate), new Date());
  if (isEventFinished) return colors.tableTextColor;
  else if (event?.status === EventStatusEnum.Open) return '#00CD21';
  else if (event?.status === EventStatusEnum.Booked) return '#A59CEE';
};
