import * as React from 'react';
import styled, { keyframes } from 'styled-components';
import { ClipLoader } from 'react-spinners';
import PerformerListing from './PerformerListing';
import PerformerBanner from './PerformerBanner';
import sexpressClient from '../../utils/sexpress';
import { Act, Artist, Tag } from '../../shared';
import { fadeIn } from 'react-animations';
import { Box } from 'react-native-kondo';
import colors from '../../constants/colors';
import PageLayoutFooter from '../common/PageLayoutFooter';
import { TextH2 } from '../common/Typography';
import i18n from '../../constants/i18n';
import { useNavigate, useSearchParams } from 'react-router-dom';
import HowItWorksGetADemoSection from '../howItWorks/HowItWorksGetADemoSection';

const fadeInAnimation = keyframes`${fadeIn}`;

const PerformerPage = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const [keywords, setKeywords] = React.useState('');
  const [location, setLocation] = React.useState('');
  const [loading, setLoading] = React.useState(false);
  const [currentPage, setCurrentPage] = React.useState(1);
  const [totalPages, setTotalPages] = React.useState(1);
  const [selectedTagIds, setSelectedTagIds] = React.useState<Tag['id'][]>([]);

  const [performers, setPerformers] = React.useState<(Artist & Act)[]>([]);

  const fetchPerformers = React.useCallback(
    async (
      payload: {
        keywords?: string;
        location?: string;
        page?: number;
        tagIds?: string[];
      } = {},
    ) => {
      if (loading) {
        return;
      }
      try {
        setLoading(true);
        const res = await sexpressClient.getPerformers(payload);
        setPerformers(res.performers);
        setTotalPages(res.totalPages);
      } catch (e) {
        console.log(e);
      } finally {
        setLoading(false);
      }
    },
    [],
  );

  const onPressSearch = () => {
    navigate(
      `/performers?page=${encodeURIComponent(1)}&keywords=${encodeURIComponent(
        keywords,
      )}&location=${encodeURIComponent(location)}${selectedTagIds
        .map((tagId) => `&tagId=${encodeURIComponent(tagId)}`)
        .join('')}`,
    );
  };

  const onChangeSelectedPage = (newPage: number) => {
    navigate(
      `/performers?page=${encodeURIComponent(
        newPage,
      )}&keywords=${encodeURIComponent(keywords)}&location=${encodeURIComponent(
        location,
      )}${selectedTagIds
        .map((tagId) => `&tagId=${encodeURIComponent(tagId)}`)
        .join('')}`,
    );

    window.scrollTo({
      top: 0,
    });
  };

  React.useEffect(() => {
    const keywords = (searchParams.get('keywords') ?? '').trim();
    const location = (searchParams.get('location') ?? '').trim();
    const page = parseInt(searchParams.get('page') ?? '1');
    const tagIds = searchParams.getAll('tagId') ?? [];

    setKeywords(keywords);
    setLocation(location);
    setCurrentPage(page);
    setSelectedTagIds(tagIds);

    fetchPerformers({
      keywords,
      location,
      page,
      tagIds,
    });
  }, [searchParams]);

  return (
    <Main>
      <PerformerBanner
        keywords={keywords}
        location={location}
        onChangeKeywords={setKeywords}
        onChangeLocation={setLocation}
        onPressSearch={onPressSearch}
      />

      <Content>
        <TextBox>
          <TextH2 style={{ margin: 0 }}>
            {i18n('PerformerPage.Title', 'Musicians and Acts')}
          </TextH2>
        </TextBox>

        {loading ? (
          <Box style={{ alignSelf: 'center', marginTop: 60, minHeight: 400 }}>
            <ClipLoader color={colors.purple} size={48} />
          </Box>
        ) : (
          <PerformerListing
            performers={performers}
            currentPage={currentPage}
            totalPages={totalPages}
            onChangeSelectedPage={onChangeSelectedPage}
          />
        )}
      </Content>
      <HowItWorksGetADemoSection />
      <PageLayoutFooter />
    </Main>
  );
};

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-self: center;
  width: 80%;
  box-sizing: border-box;
  padding-bottom: 24px;
  padding-top: 64px;
  animation: 400ms ${fadeInAnimation} ease-in;
  @media (max-width: ${(props) => props.theme.breakPoints.tablet}px) {
    width: 90%;
  }
  @media (max-width: ${(props) => props.theme.breakPoints.mobile}px) {
    animation: none;
  }
`;
const TextBox = styled.div`
  display: flex;
  width: 80%;
  padding-left: 61px;
  @media (max-width: ${(props) => props.theme.breakPoints.tablet}px) {
    padding-left: 45px;
    width: 90%;
  }
  @media (max-width: ${(props) => props.theme.breakPoints.mobile}px) {
    width: 100%;
    padding-left: 0;
    justify-content: center;
  }
`;

const Main = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  box-sizing: border-box;
`;

export default PerformerPage;
