export default {
  'RequestCaracteristicsSection.outdoors': {
    en: 'Outdoors',
    fr: 'En plein air',
  },
  'RequestCaracteristicsSection.indoors': {
    en: 'Indoors',
    fr: "À l'intérieur",
  },
  'RequestCaracteristicsSection.covered': { en: 'Covered', fr: 'Couverte' },
  'RequestCaracteristicsSection.uncovored': {
    en: 'Uncovered',
    fr: 'Découverte',
  },
  'RequestCaracteristicsSection.requestStatus': {
    en: 'REQUEST STATUS',
    fr: 'Statut de demande',
  },
  'RequestCaracteristicsSection.declineReason': {
    en: 'Decline Reason',
    fr: 'Refuser la raison',
  },
  'RequestCaracteristicsSection.bookerQuoteDeclinedMessage': {
    en: "Performer's Request Declined Message",
    fr: "La demande de l'interprète a refusé le message",
  },
  'RequestCaracteristicsSection.venueName': {
    en: 'VENUE NAME',
    fr: 'NOM DE LA PLACE',
  },
  'RequestCaracteristicsSection.eventType': {
    en: 'EVENT TYPE',
    fr: "TYPE D'ÉVÉNEMENT",
  },
  'RequestCaracteristicsSection.venueInformation': {
    en: 'VENUE INFORMATION',
    fr: 'Informations sur le lieu',
  },
  'RequestCaracteristicsSection.additionalSetUpTime': {
    en: 'ADDITIONAL SET UP TIME',
    fr: 'Temps de configuration supplémentaire',
  },
  'RequestCaracteristicsSection.dresscode': {
    en: 'DRESSCODE',
    fr: 'CODE VESTIMENTAIRE',
  },
  'RequestCaracteristicsSection.estimatedAudienceSize': {
    en: 'ESTIMATED AUDIENCE SIZE',
    fr: 'Taille du public estimé',
  },
  'RequestCaracteristicsSection.organizersFullname': {
    en: "ORGANIZER'S FULLNAME",
    fr: "Nom complet de l'organisateur",
  },
  'RequestCaracteristicsSection.organizersPhone': {
    en: "ORGANIZER'S PHONE",
    fr: "Téléphone de l'organisateur",
  },
  'RequestCaracteristicsSection.messageFromTheOrganizer': {
    en: 'MESSAGE FROM THE ORGANIZER',
    fr: "Message de l'organisateur",
  },
};
