export default {
  'PaymentPref.errorWrongInfo': {
    en: 'There was an error with the following number : ',
    fr: 'Il y a eu une erreur avec le numéro suivant:',
  },
  'PaymentPref.errorExpiryYear': {
    en: "Your card's expiration year is invalid.",
    fr: "L'année d'expiration de votre carte n'est pas valide.",
  },
  'PaymentPref.errorExpiryMonth': {
    en: "Your card's expiration month is invalid.",
    fr: "Le mois d'expiration de votre carte n'est pas valide.",
  },
  'PaymentPref.errorCVC': {
    en: "Your card's security code is invalid.",
    fr: "Le code de sécurité de votre carte n'est pas valide.",
  },
  'PaymentPref.errorCardNumber': {
    en: 'Your card number is incorrect.',
    fr: 'Votre numéro de carte est incorrect.',
  },
  'PaymentPref.errorGeneral': {
    en: 'Invalid credit card.',
    fr: 'Carte de crédit invalide.',
  },
};
