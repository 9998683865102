export default {
  'BankingInfo.bankingInformation': {
    en: 'Banking Information',
    fr: 'Information bancaire',
  },
  'BankingInfo.enterYourBankingInformation': {
    en: 'Enter your banking information to link your account. We use this information in order to link your bank account to Stripe. None of it is saved on our servers.',
    fr: "Entrez vos informations bancaires pour lier votre compte. Nous utilisons ces informations afin de relier votre compte bancaire à Stripe. Rien de tout cela n'est sauvé sur nos serveurs.",
  },
  'BankingInfo.firstName': { en: 'First Name', fr: 'Prénom' },
  'BankingInfo.lastName': { en: 'Last Name', fr: 'Nom de famille' },
  'BankingInfo.transitNumber': {
    en: 'Transit Number',
    fr: 'Numéro de transit',
  },
  'BankingInfo.institutionNumber': {
    en: 'Institution Number',
    fr: "Numéro d'institution",
  },
  'BankingInfo.accountNumber': { en: 'Account Number', fr: 'Numéro de compte' },
};
