import * as React from 'react';
import styled from 'styled-components';
import colors from '../../constants/colors';
import { TextH3 } from './Typography';
import images from '../../constants/images';

const GiCreateEventContainer = ({
  title,
  secondaryText,
  editableContent,
}: {
  title: () => React.ReactNode;
  secondaryText?: () => React.ReactNode;
  editableContent: React.ReactElement;
}) => {
  return (
    <Main>
      <TitleBox>
        <Title>
          <StyledTextH2>{title}</StyledTextH2>

          <TextH3 style={{ fontWeight: 400, color: colors.violet }}>
            {secondaryText}
          </TextH3>
        </Title>
        <img src={images.createEventBanner} />
      </TitleBox>

      <ContentBox>{editableContent}</ContentBox>
    </Main>
  );
};

const Main = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: row;
  padding: 40px;
  background: #f0f1fc;
  @media (max-width: ${(props) => props.theme.breakPoints.mobile}px) {
    flex-direction: column;
    padding: 20px;
  }
`;

const TitleBox = styled.div`
  width: 40%;
  height: 100%;
  display: flex;
  flex-direction: column;
  @media (max-width: ${(props) => props.theme.breakPoints.mobile}px) {
    width: 100%;
    height: 20%;
  }
`;

const Title = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 430px;
  @media (max-width: ${(props) => props.theme.breakPoints.mobile}px) {
    align-items: center;
  }
`;

const ContentBox = styled.div`
  width: 60%;
  border-radius: 30px;
  z-index: 3;
  padding: 30px;
  background: ${colors.white};
  @media (max-width: ${(props) => props.theme.breakPoints.tablet}px) {
    width: 91%;
  }
  @media (max-width: ${(props) => props.theme.breakPoints.mobile}px) {
    height: 80%;
    width: 100%;
    padding: 1rem;
    padding-bottom: 4rem;
  }
`;

const StyledTextH2 = styled.h2`
  font-size: 40px;
  font-family: ${(props) => props.theme.fonts.GeneralSans};
  font-weight: 600;
  text-align: left;
  color: ${(props) => props.theme.colors.violet};
  @media (max-width: ${(props) => props.theme.breakPoints.mobile}px) {
    font-size: 24px;
    text-align: center;
    margin-top: 8px;
  }
`;

export default GiCreateEventContainer;
