import * as React from 'react';
import { observer } from 'mobx-react-lite';
import ImageGallery from 'react-image-gallery';
import 'react-image-gallery/styles/css/image-gallery.css';
import { Box } from 'react-native-kondo';
import styled from 'styled-components';
import { useUiStore } from '../../stores/ui';
import imgs from '../../constants/images';

const GalleryViewer = observer(() => {
  const uiStore = useUiStore();

  if (!uiStore.isGalleryViewerShown) {
    return null;
  }

  const images = uiStore.galleryViewerPictures.slice().map((url) => ({
    original: url,
    thumbnail: url,
    originalHeight: uiStore.isMobile ? undefined : 1024,
    originalWidth: uiStore.isMobile ? undefined : 1024,
  }));

  return (
    <Main>
      <Mask onClick={uiStore.closeGalleryViewer} />
      <Box
        style={{
          height: uiStore.isMobile ? uiStore.innerHeight : 1024,
          width: uiStore.isMobile ? uiStore.innerWidth : 1024,
          justifyContent: uiStore.isMobile ? 'center' : 'flex-start',
        }}
      >
        <ImageGallery
          useBrowserFullscreen
          showPlayButton={false}
          showFullscreenButton={false}
          items={images}
        />
      </Box>
      {uiStore.isMobile ? (
        <CloseButton
          src={imgs.closeIcon}
          onClick={uiStore.closeGalleryViewer}
        />
      ) : null}
    </Main>
  );
});

const Main = styled.div`
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  justify-content: center;
  align-items: center;
  @media (max-width: ${(props) => props.theme.breakPoints.mobile}px) {
  }
`;

const CloseButton = styled.img`
  position: fixed;
  top: 25px;
  right: 25px;
  width: 25px;
  height: 25px;
  @media (max-width: ${(props) => props.theme.breakPoints.mobile}px) {
  }
`;

const Mask = styled.div`
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.85);
  @media (max-width: ${(props) => props.theme.breakPoints.mobile}px) {
  }
`;

export default GalleryViewer;
