import React, { Dispatch, SetStateAction } from 'react';
import { UseUserStore } from '../../stores/user';
import LandingDrawer from './LandingDrawer';
import NavigationDrawer from './navigationDrawer/NavigationDrawer';

const DynamicDrawer = ({
  isDrawerShown,
  setIsDrawerShown,
}: {
  isDrawerShown: boolean;
  setIsDrawerShown: Dispatch<SetStateAction<boolean>>;
}) => {
  const userStore = UseUserStore();

  const isUserLoggedIn = !!userStore.me?.id;

  if (isUserLoggedIn) {
    return (
      <NavigationDrawer
        isOpen={isDrawerShown}
        onClose={() => setIsDrawerShown(false)}
      />
    );
  }

  return (
    <LandingDrawer
      isOpen={isDrawerShown}
      onClose={() => setIsDrawerShown(false)}
    />
  );
};

export default DynamicDrawer;
