import * as React from 'react';
import styled from 'styled-components';
import colors from '../../constants/colors';
import i18n from '../../constants/i18n';
import { useBookingStore } from '../../stores/booking';
import GiButton from '../common/GiButton';
import { addRightToolTip } from '../common/ToolTip';
import { GeneralRegularNormal, TextH2 } from '../common/Typography';
import { isStringEmptyUndefinedOrNull } from '../../utils/string';

const BookingAbout = ({ onPressNext }: { onPressNext: () => void }) => {
  const bookingStore = useBookingStore();

  const [about, setAbout] = React.useState<string>(bookingStore.message);
  return (
    <Main>
      {addRightToolTip({
        leftNode: (
          <TextH2
            style={{
              color: colors.whiteText,
              marginBottom: 36,
              alignSelf: 'center',
              textAlign: 'center',
            }}
          >
            {i18n('BookingAbout.title', 'Tell us a bit more about your event')}
          </TextH2>
        ),
        toolTip: i18n(
          'BookingAbout.beAsSpecificAsPossible',
          'Be as specific as possible, this increases the likelihood of a successful booking. What we recommend you include: Introduce yourself! Tell them why you are hosting this event, Equipment needed (if applicable), Specific song requests (if applicable), what your expectations are for length of sets, are you offering food or drink? Don’t worry if you forget some details, once your request is sent, you’ll be able to message the artist to add further detail.',
        ),
        mainStyle: { alignSelf: 'center' },
      })}

      <GeneralRegularNormal
        style={{
          color: colors.whiteText,
          marginBottom: 36,
          alignSelf: 'center',
          textAlign: 'center',
          width: '90%',
        }}
      >
        {i18n(
          'BookingAbout.subTitle',
          'Be as specific as possible. Artists are more likely to accept offers when they have more information',
        )}
      </GeneralRegularNormal>

      <TextArea
        onChange={(e) => {
          setAbout(e.target.value);
          bookingStore.setBookingData({
            message: e.target.value,
          });
        }}
        value={about}
        placeholder="Message"
        style={{ marginBottom: 48 }}
      />

      <GiButton
        label={i18n('BookingAbout.next', 'Next')}
        style={{ alignSelf: 'center', marginTop: 48 }}
        disabled={isStringEmptyUndefinedOrNull(about)}
        onClick={onPressNext}
      />
    </Main>
  );
};

const TextArea = styled.textarea`
  display: flex;
  box-sizing: border-box;
  background-color: #0e0023;
  font-size: 16px;
  font-family: ${(props) => props.theme.fonts.GeneralSans};
  font-weight: 400;
  min-height: 229px;
  padding: 20px;
  resize: none;
  color: ${(props) => props.theme.colors.whiteText};
  ::placeholder {
    color: ${(props) => props.theme.colors.whiteText};
    opacity: 0.6;
  }
  text-decoration: none;
  outline: none;
  border-width: 0px;
  @media (max-width: ${(props) => props.theme.breakPoints.mobile}px) {
  }
`;

const Main = styled.div`
  display: flex;
  box-sizing: border-box;
  flex-direction: column;
  align-self: center;
  padding-bottom: 63px;
  width: 100%;
  max-width: 543px;
  @media (max-width: ${(props) => props.theme.breakPoints.mobile}px) {
    padding: 0px 20px 40px 20px;
  }
`;

export default BookingAbout;
