import * as React from 'react';
import styled from 'styled-components';
import ReactTooltip from 'react-tooltip';
import { TextH6 } from './Typography';
import colors from '../../constants/colors';

const ToolTip = ({ text, mainStyle }: { text: string; mainStyle?: any }) => {
  return (
    <div style={mainStyle}>
      <ColoredCircle data-tip={text}>
        <TextH6 style={{ color: colors.white, margin: 0 }}>?</TextH6>
      </ColoredCircle>
      <ReactTooltip effect="solid" multiline className="opaquetooltip" />
    </div>
  );
};

export const addRightToolTip = ({
  leftNode,
  toolTip,
  mainStyle,
}: {
  leftNode: React.ReactNode;
  toolTip: string;
  mainStyle?: React.CSSProperties;
}) => {
  return (
    <LabelSectionMain style={mainStyle}>
      {leftNode}
      <ToolTip text={toolTip} mainStyle={{ marginLeft: 4, marginTop: 1 }} />
    </LabelSectionMain>
  );
};

const ColoredCircle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.colors.buttonBorder};
  cursor: pointer;
`;

const LabelSectionMain = styled.div`
  display: flex;
  flex-direction: row;
`;

export default ToolTip;
