export default {
  'BookingFinalMessage.thanks': {
    en: 'Thank you for submitting an offer!',
    fr: "Merci d'avoir soumis une offre!",
  },
  'BookingFinalMessage.subTitle': {
    en: 'Your offer has been submitted to the artist and you will hear back from them shortly!',
    fr: "Votre offre a été soumise à l'artiste et vous entendrez de leurs nouvelles sous peu!",
  },
  'BookingFinalMessage.close': { en: 'Close', fr: 'Terminer' },
};
