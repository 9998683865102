export default {
  'RegistrationBookerWaysToBook.title': {
    en: 'Your account is created!',
    fr: 'Votre compte est créé!',
  },
  'RegistrationBookerWaysToBook.firstParagraphDescription': {
    en: "Congratulations! You're one step closer to finding the perfect musicians for your events.",
    fr: 'Félicitations ! Vous êtes sur le point de trouver les musiciens parfaits pour vos événements.',
  },
  'RegistrationBookerWaysToBook.secondParagraphDescription': {
    en: 'Post your event details now and receive applications from professional musicians, or go to your dashboard for other options.',
    fr: "Publiez dès maintenant les détails de votre événement et recevez des candidatures de musiciens professionnels, ou accédez à votre tableau de bord pour d'autres options.",
  },
  'RegistrationBookerWaysToBook.postEvent': {
    en: 'I want to post an event',
    fr: 'Je souhaite publier un événement',
  },
  'RegistrationBookerWaysToBook.goToDashboard': {
    en: 'Take me to the dashboard',
    fr: 'Emmenez-moi au tableau de bord',
  },
};
