// tslint:disable-next-line:no-var-requires
// @ts-ignore
import io from 'socket.io-client/dist/socket.io';
import config from '../config';
import user from '../stores/user';

const BASE_URL = config.apiURL;

let socket: any = null;

export function connect(onConnectCb?: any) {
  socket = io(BASE_URL, {
    query: { token: user.accessToken },
    transports: ['websocket'], // you need to explicitly tell it to use websockets
  });

  if (onConnectCb) {
    socket.on('connect', onConnectCb);
  }
}

export function disconnect() {
  socket.disconnect();
}

export function sendData(channelId: string, data: any) {
  socket.emit(channelId, data);
}

export function getData(channelId: string, cb: any) {
  socket.on(channelId, (data: any) => {
    cb(data);
  });
}

export default { sendData, getData, connect, disconnect };
