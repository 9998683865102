import * as React from 'react';
import styled from 'styled-components';
import { observer } from 'mobx-react';
import { useUiStore } from '../../stores/ui';
import LandingFooter from '../landing/LandingFooter';
import HowItWorksTopSection from './HowItWorksTopSection';
import HowItWorksStepsSection from './HowItWorksStepsSection';
import HowItWorksGetADemoSection from './HowItWorksGetADemoSection';
import HowItWorksAccordionSection from './HowItWorksAccordionSection';
import HowItWorksOrgSection from './HowItWorksOrgSection';

const HowItWorksPage = observer(() => {
  const { navBarHeight, isMobile } = useUiStore();

  return (
    <Main navBarHeight={navBarHeight} isMobile={isMobile}>
      <HowItWorksTopSection />
      <HowItWorksStepsSection />
      <HowItWorksGetADemoSection />
      <HowItWorksOrgSection />
      <HowItWorksAccordionSection />
      <LandingFooter />
    </Main>
  );
});

const Main = styled.div<{
  navBarHeight: number;
  isMobile: boolean;
}>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export default HowItWorksPage;
