export default {
  'BookingDateSelection.whenIsYourEvent': {
    en: 'When is your event',
    fr: 'Quand est votre événement',
  },
  'BookingDateSelection.start': { en: 'Start', fr: 'Démarrer' },
  'BookingDateSelection.end': { en: 'End', fr: 'Finir' },
  'BookingDateSelection.available': {
    en: 'Available Time for Set Up',
    fr: 'Temps disponible pour la configuration',
  },
  'BookingDateSelection.availablesub': {
    en: 'Will the artist be able to come earlier to set up? If so, please indicate up to how many hours earlier they may arrive.',
    fr: "L'artiste pourra-t-il venir plus tôt pour s'installer? Si oui, veuillez indiquer jusqu'à combien d'heures plus tôt, ils peuvent arriver.",
  },
  'BookingDateSelection.next': { en: 'Next', fr: 'Suivante' },
};
