import { observer } from 'mobx-react';
import * as React from 'react';
import { Outlet, Navigate, useLocation } from 'react-router-dom';
import { UseUserStore } from '../../stores/user';

const UserRouteAuthCheck = observer(() => {
  const location = useLocation();
  const { getMe } = UseUserStore();
  const me = getMe();

  if (!me) {
    return (
      <Navigate
        to="/?userLogin=1&performerLogin=1"
        state={{ from: location }}
        replace
      />
    );
  }

  return <Outlet />;
});

export default UserRouteAuthCheck;
