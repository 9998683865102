export default {
  'useGetDropDownsOptions.myProfile': { en: 'My profile', fr: 'Mon profil' },
  'useGetDropDownsOptions.search': { en: 'Browse', fr: 'Chercher' },
  'useGetDropDownsOptions.create': { en: 'Create', fr: 'Crée' },
  'useGetDropDownsOptions.requests': { en: 'Requests', fr: 'Demandes' },
  'useGetDropDownsOptions.messages': { en: 'Messages', fr: 'Messages' },
  'useGetDropDownsOptions.settings': { en: 'Settings', fr: 'Réglages' },
  'useGetDropDownsOptions.support': { en: 'Support', fr: 'Soutien' },
  'useGetDropDownsOptions.logOut': { en: 'Log Out', fr: 'Se déconnecter' },
  'useGetDropDownsOptions.forMusicians': {
    en: 'For musicians',
    fr: 'Pour les musiciens',
  },
  'useGetDropDownsOptions.forYourEvent': {
    en: 'For your event',
    fr: 'Pour votre événement',
  },
  'useGetDropDownsOptions.browse': { en: 'Browse', fr: 'Parcourir' },
  'useGetDropDownsOptions.transactions': {
    en: 'Transactions',
    fr: 'Transactions',
  },
};
