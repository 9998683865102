import * as React from 'react';
import styled from 'styled-components';
import { Transaction } from '../../../shared';

import { observer } from 'mobx-react-lite';
import QuoteLabel from './QuoteLabel';
import OtherUserText from './OtherUserText';
import CurrencyInputSection from './CurrencyInputSection';
import FirstButton from './FirstButton';
import SecondButton from './SecondButton';
import SendMessageSectionn from './SendMessageSection';

const RequestDetailsQuoteSection = observer(
  ({
    request,
    fetchTransaction,
  }: {
    request: Transaction;
    fetchTransaction: () => void;
  }) => {
    const { status, quoteData } = request;

    const [price, setPrice] = React.useState(
      status !== 'quotePending' ? `${quoteData.priceFormatted}` : '',
    );

    React.useEffect(() => {
      if (status !== 'quotePending') {
        setPrice(`${quoteData.priceFormatted}`);
      }
    }, []);

    const requestIsSuccessful = ['accepted', 'completed'].includes(status);

    if (requestIsSuccessful) {
      return (
        <Main>
          <LeftContent>
            <OtherUserText request={request} />
          </LeftContent>

          <RightContent>
            <SendMessageSectionn request={request} />
          </RightContent>
        </Main>
      );
    }

    return (
      <Main>
        <LeftContent>
          <QuoteLabel request={request} />
          <CurrencyInputSection
            request={request}
            price={price}
            setPrice={setPrice}
          />
          <OtherUserText request={request} />
        </LeftContent>

        <RightContent>
          <div>
            <FirstButton
              request={request}
              price={price}
              fetchTransaction={fetchTransaction}
            />
            <SecondButton
              request={request}
              fetchTransaction={fetchTransaction}
            />
          </div>
          <SendMessageSectionn request={request} />
        </RightContent>
      </Main>
    );
  },
);

const Main = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: ${({ theme }) => theme.colors.white};
  border: 0.5px solid rgba(0, 0, 0, 0.1);
  border-radius: 2px;
  padding: 20px 36px;
  margin-bottom: 36px;

  box-shadow: 4px 4px 10px rgba(131, 123, 119, 0.25);
  box-sizing: border-box;

  @media (max-width: 375px) {
    padding: 10px 18px;
  }
`;

const LeftContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 56%;
  box-sizing: border-box;
`;

const RightContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 44%;
  justify-content: space-between;
  align-items: flex-end;
  box-sizing: border-box;
`;

export default RequestDetailsQuoteSection;
