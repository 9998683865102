import * as React from 'react';
import { Box } from 'react-native-kondo';
import styled, { keyframes } from 'styled-components';
import { fadeIn } from 'react-animations';
import i18n from '../../../constants/i18n';
import ATag from '../ATag';
import { GeneralRegularNormal, TextH2 } from '../Typography';
import { isEntityOwner } from './profileUtils';
import { Entity, EntityType } from '../../../shared';

const fadeInAnimation = keyframes`${fadeIn}`;

const ProfileSpotifySection = ({
  entity,
  entityType,
}: {
  entity: Entity;
  entityType: EntityType;
}) => {
  if (!entity.songs || !entity.songs.length) {
    return null;
  }

  const isOwner = isEntityOwner({
    entity,
    entityType,
  });

  let href = `/me/artist/songs`;
  if (entityType === 'act') {
    href = `/me/acts/${entity.id}/songs`;
  }

  return (
    <Main>
      <Box
        style={{
          marginBottom: 15,
          justifyContent: 'space-between',
          flexDirection: 'row',
          alignItems: 'center',
        }}
      >
        <TextH2>
          {i18n('ArtistSpotifySection.title', 'Songs / Repertoire')}
        </TextH2>
        {isOwner ? (
          <ATag href={href}>
            <GeneralRegularNormal
              style={{ textDecoration: 'underline', color: '#4361EE' }}
            >
              {i18n('ArtistSpotifySection.editSongs', 'Edit Songs')}
            </GeneralRegularNormal>
          </ATag>
        ) : null}
      </Box>
      {entity.songs.map((url) => (
        <SongContainer key={url}>
          <iframe
            src={url}
            width="100%"
            height="80"
            frameBorder="0"
            allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
          />
        </SongContainer>
      ))}
    </Main>
  );
};

const SongContainer = styled.div`
  animation: 1s ${fadeInAnimation};

  display: flex;
  flex-direction: column;
`;

const Main = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 80px;
  @media (max-width: ${(props) => props.theme.breakPoints.mobile}px) {
    margin-bottom: 50px;
  }
`;

export default ProfileSpotifySection;
