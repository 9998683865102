import styled from 'styled-components';

export const fonts = {
  GeneralSans: `'General Sans', sans-serif`,
};

export const GeneralRegularSmall = styled.span`
  font-size: 12px;
  font-family: ${(props) => props.theme.fonts.GeneralSans};
  font-weight: 400;
  color: ${(props) => props.theme.colors.black};
`;

export const GeneralRegularNormal = styled.span`
  font-size: 16px;
  font-family: ${(props) => props.theme.fonts.GeneralSans};
  font-weight: 400;
  color: ${(props) => props.theme.colors.black};
  line-height: 22px;
  letter-spacing: 0em;
`;

export const GeneralSemiBoldNormal = styled.span`
  font-size: 16px;
  font-family: ${(props) => props.theme.fonts.GeneralSans};
  font-weight: 600;
  color: ${(props) => props.theme.colors.whiteText};
`;

export const GeneralSemiBoldTitle = styled.span`
  font-size: 46px;
  font-family: ${(props) => props.theme.fonts.GeneralSans};
  font-weight: 600;
  color: ${(props) => props.theme.colors.whiteText};
  @media (max-width: ${(props) => props.theme.breakPoints.tablet}px) {
    font-size: 38px;
  }
  @media (max-width: ${(props) => props.theme.breakPoints.mobile}px) {
    font-size: 32px;
  }
`;

export const GeneralMediumTitle = styled.span`
  font-size: 46px;
  font-family: ${(props) => props.theme.fonts.GeneralSans};
  font-weight: 500;
  color: ${(props) => props.theme.colors.black};
  @media (max-width: ${(props) => props.theme.breakPoints.tablet}px) {
    font-size: 38px;
  }
  @media (max-width: ${(props) => props.theme.breakPoints.mobile}px) {
    font-size: 32px;
  }
`;

export const GeneralSemiBoldMainTitle = styled.span`
  font-size: 64px;
  line-height: 110%;
  font-family: ${(props) => props.theme.fonts.GeneralSans};
  font-weight: 600;
  color: ${(props) => props.theme.colors.whiteText};
  @media (max-width: ${(props) => props.theme.breakPoints.tablet}px) {
    font-size: 56px;
  }
  @media (max-width: ${(props) => props.theme.breakPoints.mobile}px) {
    font-size: 32px;
  }
`;

export const GeneralSemiBoldLarge = styled.span`
  font-size: 24px;
  font-family: ${(props) => props.theme.fonts.GeneralSans};
  font-weight: 600;
  color: ${(props) => props.theme.colors.white};
  @media (max-width: ${(props) => props.theme.breakPoints.mobile}px) {
    font-size: 18px;
  }
`;

export const GeneralSemiBoldSubtitle = styled.span`
  font-size: 36px;
  font-family: ${(props) => props.theme.fonts.GeneralSans};
  font-weight: 600;
  color: ${(props) => props.theme.colors.black};
`;

export const GeneralRegularLarge = styled.span`
  font-size: 20px;
  font-family: ${(props) => props.theme.fonts.GeneralSans};
  font-weight: 400;
  color: ${(props) => props.theme.colors.whiteText};
`;

export const GeneralMediumLarge = styled.span`
  font-size: 18px;
  font-family: ${(props) => props.theme.fonts.GeneralSans};
  font-weight: 500;
  color: ${(props) => props.theme.colors.whiteText};
  line-height: 24px;
  letter-spacing: 0em;
  @media (max-width: ${(props) => props.theme.breakPoints.mobile}px) {
    font-size: 16px;
  }
`;

export const GeneralMediumSubtitle = styled.span`
  font-size: 18px;
  font-family: ${(props) => props.theme.fonts.GeneralSans};
  font-weight: 500;
  color: ${(props) => props.theme.colors.black};
  margin: 0 1rem;
  &:hover {
    cursor: pointer;
    opacity: 0.8;
  }
  @media (max-width: ${(props) => props.theme.breakPoints.tablet}px) {
    font-size: 16px;
    margin: 0;
  }
  @media (max-width: ${(props) => props.theme.breakPoints.mobile}px) {
    color: ${(props) => props.theme.colors.white};
    font-size: 28px;
    margin: 0;
    margin-top: 32px;
  }
`;

export const TextH1 = styled.h1`
  font-size: 42px;
  font-family: ${(props) => props.theme.fonts.GeneralSans};
  font-weight: 600;
  color: ${(props) => props.theme.colors.black};
  @media (max-width: ${(props) => props.theme.breakPoints.mobile}px) {
    font-size: 28px;
  }
`;

export const TextH2 = styled.h2`
  font-size: 28px;
  font-family: ${(props) => props.theme.fonts.GeneralSans};
  font-weight: 600;
  color: ${(props) => props.theme.colors.black};
  @media (max-width: ${(props) => props.theme.breakPoints.mobile}px) {
    font-size: 24px;
  }
`;

export const TextH3 = styled.h3`
  font-size: 20px;
  font-family: ${(props) => props.theme.fonts.GeneralSans};
  font-weight: 600;
  color: ${(props) => props.theme.colors.whiteText};
  @media (max-width: ${(props) => props.theme.breakPoints.mobile}px) {
    font-size: 16px;
  }
`;

export const TextH4 = styled.h4`
  font-size: 24px;
  font-family: ${(props) => props.theme.fonts.GeneralSans};
  font-weight: 400;
  color: ${(props) => props.theme.colors.whiteText};
  line-height: 32px;
  letter-spacing: 0em;
  @media (max-width: ${(props) => props.theme.breakPoints.mobile}px) {
    font-size: 18px;
  }
`;

export const TextH4Title = styled.h4`
  font-size: 24px;
  font-family: ${(props) => props.theme.fonts.GeneralSans};
  font-weight: 400;
  color: ${(props) => props.theme.colors.black};
  margin-top: 30px;
  margin-bottom: 10px;
  @media (max-width: ${(props) => props.theme.breakPoints.mobile}px) {
    font-size: 18px;
    margin-top: 20px;
    margin-bottom: 5px;
  }
`;

export const TextH5 = styled.h5`
  font-size: 16px;
  font-family: ${(props) => props.theme.fonts.GeneralSans};
  font-weight: 600;
  color: ${(props) => props.theme.colors.black};
  line-height: 22px;
  letter-spacing: 0em;
  @media (max-width: ${(props) => props.theme.breakPoints.mobile}px) {
    font-size: 16px;
  }
`;

export const TextH6 = styled.h6`
  font-size: 14px;
  font-family: ${(props) => props.theme.fonts.GeneralSans};
  font-weight: 500;
  color: ${(props) => props.theme.colors.black};
  @media (max-width: ${(props) => props.theme.breakPoints.mobile}px) {
  }
`;

export const TextH7 = styled.span`
  font-size: 18px;
  font-family: ${(props) => props.theme.fonts.GeneralSans};
  font-weight: 600;
  color: ${(props) => props.theme.colors.black};
`;

export const TextH7Button = styled.span`
  font-size: 18px;
  font-family: ${(props) => props.theme.fonts.GeneralSans};
  font-weight: 500;
  color: ${(props) => props.theme.colors.black};
  @media (max-width: ${(props) => props.theme.breakPoints.mobile}px) {
    font-size: 14px;
  }
`;

export const GeneralSemiBoldSmall = styled.span`
  font-size: 14px;
  font-family: ${(props) => props.theme.fonts.GeneralSans};
  font-weight: 600;
  color: ${(props) => props.theme.colors.whiteText};
`;

export const InputLabel = styled.label`
  font-size: 14px;
  font-family: ${(props) => props.theme.fonts.GeneralSans};
  font-weight: 400;
  color: ${(props) => props.theme.colors.black};
  margin-left: 8px;
  margin-bottom: 0;
`;
