import { observer } from 'mobx-react';
import * as React from 'react';
import styled from 'styled-components';
import i18n from '../../../../../../constants/i18n';
import GiAuthContainer from '../../../../../common/GiAuthContainer';
import GiSelectableButton from '../../../../../common/GiSelectableButton';
import GiglinkedButton from '../../../../../common/GiglinkedButton';
import { ProgressBar } from 'react-bootstrap';
import { GROUP_TYPES } from '../../../../../../shared';

interface GroupTypeProps {
  tags: Array<string>;
  setTags: (tags: Array<string>) => void;
  progress: number;
  next: () => void;
}

const StepperRegistrationGroupTypeSelect = observer(
  ({ tags, setTags, progress, next }: GroupTypeProps) => {
    return (
      <GiAuthContainer
        title={i18n('StepperRegistrationGroupTypeSelect.title', 'Bookable as:')}
        secondaryText={i18n(
          'StepperRegistrationGroupTypeSelect.description',
          'Choose all that apply. You can add more later.',
        )}
        editableContent={
          <>
            <MainBox>
              <ButtonsBox>
                {GROUP_TYPES().map((groupType) => (
                  <GiSelectableButton
                    key={groupType.value}
                    selected={tags.includes(groupType.value)}
                    onClick={() => {
                      const updatedTags = tags.includes(groupType.value)
                        ? tags.filter((tag) => tag !== groupType.value)
                        : [...tags, groupType.value];
                      setTags(updatedTags);
                    }}
                    label={groupType.label}
                  />
                ))}
              </ButtonsBox>
            </MainBox>

            <BoxBottom>
              <ProgressBar
                animated
                striped
                style={{ width: '100%' }}
                min={0}
                max={6}
                now={progress}
                variant="success"
              />

              <GiglinkedButton
                style={{
                  border: 'none',
                }}
                label={i18n('RegistrationStepper.next', 'Next')}
                onClick={next}
              />
            </BoxBottom>
          </>
        }
      />
    );
  },
);

const MainBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 90%;
`;

const BoxBottom = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 10%;
  @media (max-width: ${(props) => props.theme.breakPoints.mobile}px) {
    margin-right: 5px;
    margin-top: 0;
  }
`;

const ButtonsBox = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: space-evenly;
  @media (max-width: ${(props) => props.theme.breakPoints.mobile}px) {
    width: 100%;
  }
`;

export default StepperRegistrationGroupTypeSelect;
