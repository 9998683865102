import * as React from 'react';
import styled from 'styled-components';
import colors from '../../constants/colors';
import { TextH5 } from '../common/Typography';

interface Props {
  label: string;
  labelStyle?: React.CSSProperties;
  mainStyle?: React.CSSProperties;
  containerStyle?: React.CSSProperties;
}

const ColoredTab = ({
  label,
  labelStyle,
  mainStyle,
  containerStyle,
}: Props) => {
  const { sendMessageBlue, white } = colors;

  return (
    <Main style={mainStyle}>
      <Container
        style={{ backgroundColor: sendMessageBlue, ...containerStyle }}
      >
        <TextH5 style={{ color: white, ...labelStyle }}>{label}</TextH5>
      </Container>
    </Main>
  );
};

const Main = styled.div`
  display: flex;
`;

const Container = styled.div`
  display: flex;
  flex-direction: row;
  height: 34px;
  justify-content: center;
  align-items: center;
  background-color: ${({ color }) => color};
`;

export default ColoredTab;
