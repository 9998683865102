import * as React from 'react';
import { useState } from 'react';
import { observer } from 'mobx-react';
import styled from 'styled-components';
import { HowItWorksAccordionData } from './HowItWorksAccordionTypes';
import colors from '../../../constants/colors';
import images from '../../../constants/images';
import { Collapse } from 'react-collapse';
import { GeneralRegularNormal, TextH4Title } from '../../common/Typography';

const HowItWorksAccordionItem = observer(
  ({ data }: { data: HowItWorksAccordionData }) => {
    const [isOpened, setIsOpened] = useState(false);
    const btnOnClick = () => {
      setIsOpened(!isOpened);
    };

    return (
      <AccordionItemLi>
        <AccordionItemBtn onClick={btnOnClick}>
          <TextH4Title>{data.title}</TextH4Title>
          <Image
            src={
              isOpened
                ? images.howItWorksAccordionMinusImage
                : images.howItWorksAccordionPlusImage
            }
          />
        </AccordionItemBtn>
        <Collapse isOpened={isOpened}>
          <AccordionItemContent>
            <GeneralRegularNormal>{data.content}</GeneralRegularNormal>
          </AccordionItemContent>
        </Collapse>
      </AccordionItemLi>
    );
  },
);
const AccordionItemLi = styled.li`
  border: 0;
`;

const AccordionItemBtn = styled.button`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  border: 0;
  background: none;
  padding: 0;
  cursor: pointer;
  border-bottom: 1px solid ${colors.violet};
  font-size: 24px;
  font-family: ${(props) => props.theme.fonts.GeneralSans};
  &:hover {
    transform: translate(-5px,0);
    -webkit-transform: translate(-5px,0);
    -o-transform: translate(-5px,0);
    -moz-transform: translate(-5px,0);
    transition: transform 0.2s;
  }
  @media (max-width: ${(props) => props.theme.breakPoints.mobile}px) {
    text-align: left;
    font-size: 18px;

`;

const AccordionItemContent = styled.div`
  width: 600px;
  padding: 20px 0;
  @media (max-width: ${(props) => props.theme.breakPoints.mobile}px) {
    width: 100%;
  }
`;

const Image = styled.img`
  weight: 35px;
  height: 35px;
  @media (max-width: ${(props) => props.theme.breakPoints.tablet}px) {
    weight: 24px;
    height: 24px;
    margin-left: 20px;
  }
`;

export default HowItWorksAccordionItem;
