import * as React from 'react';
import styled from 'styled-components';
import { useUiStore } from '../../stores/ui';
import { observer } from 'mobx-react';
import { useNavigate } from 'react-router-dom';
import { TextH4, TextH5 } from './Typography';
import GiglinkedButton from './GiglinkedButton';
import images from '../../constants/images';
import colors from '../../constants/colors';
import sexpressClient from '../../utils/sexpress';
import toast from 'react-hot-toast';
import i18n from '../../constants/i18n';
import { useEventStore } from '../../stores/event';
import { EventProposalType } from '../../shared';

const CancelEventModal = observer(
  ({ proposal }: { proposal: EventProposalType }) => {
    const uiStore = useUiStore();
    const navigate = useNavigate();
    const eventStore = useEventStore();
    const { currentEvent } = eventStore;
    return (
      <Main>
        <Close src={images.closeIconBlack} onClick={uiStore.closePopup} />
        <TextH4 style={{ color: colors.black }}>
          {i18n(
            'CancelEventModal.title',
            'Are you sure you want to cancel this booking?',
          )}
        </TextH4>
        <TextH5 style={{ fontWeight: 400 }}>
          {i18n(
            'CancelEventModal.subTitle',
            'Cancelling this booking will alert the musician about the cancelation and you will need to find another musician for your event.',
          )}
        </TextH5>
        <div
          style={{ display: 'flex', flexDirection: 'row', marginTop: '1rem' }}
        >
          <GiglinkedButton
            label={i18n('CancelEventModal.noKeep', 'No, keep it')}
            onClick={async () => {
              uiStore.closePopup();
            }}
          />
          <GiglinkedButton
            label={i18n('CancelEventModal.cancelBooking', 'Cancel booking')}
            onClick={async () => {
              try {
                const res = await sexpressClient.cancelEventBookingTransaction({
                  eventId: proposal.event,
                  transactionId: currentEvent!.transaction!.id,
                });
                eventStore.setCurrentEvent(res);
                navigate('/dashboard');
                uiStore.closePopup();
              } catch (e) {
                const err = e as Error;
                toast.error(err.message);
              }
            }}
            warning
            style={{ border: 'none' }}
          />
        </div>
      </Main>
    );
  },
);

const Main = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 2rem 4rem;
  max-width: 627px;
  height: 338px;
  border-radius: 10px;
  background-color: white;
  align-items: center;
  text-align: center;
  border: 1px solid #a59cee;
  @media (max-width: ${(props) => props.theme.breakPoints.mobile}px) {
    max-width: 600px;
  }
`;

const Close = styled.img`
  cursor: pointer;
  margin-left: auto;
  object-fit: contain;
  height: 12px;
`;

export default CancelEventModal;
