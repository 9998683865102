import { Provider } from 'mobx-react';
import * as React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import stores from '../stores';
import { initAmplitudeAnalytics, logWebsiteVisit } from '../utils/amplitude';
import Router from './Router';

class App extends React.Component {
  async componentDidMount() {
    initAmplitudeAnalytics();
    logWebsiteVisit();
  }

  render() {
    return (
      <Provider {...stores}>
        <Router />
      </Provider>
    );
  }
}

export default App;
