export default {
  'LandingTopSection.title': {
    en: 'Discover the bridge between musicians and music lovers',
    fr: 'Découvrez le pont entre musiciens et amateurs de musique',
  },
  'LandingTopSection.text': {
    en: 'Sign up for our newsletter today and be the first to know when GigLinked is launched',
    fr: "Inscrivez-vous à notre newsletter aujourd'hui et soyez le premier à savoir quand Giglinked est lancé",
  },
  'LandingTopSection.placeholder': { en: 'Email', fr: 'Courriel' },
  'LandingTopSection.button': {
    en: 'About GigLinked',
    fr: 'À propos de Giglinked',
  },
  'LandingTopSection.placeholderSuccess': {
    en: 'Welcome to the GigLinked community!',
    fr: 'Bienvenue à la communauté Giglinked!',
  },
  'LandingTopSection.mainTagLine': {
    en: 'The Musicians Marketplace.',
    fr: 'Le marketplace des musicien.ne.s.',
  },
  'LandingTopSection.secondaryTagLine': {
    en: 'Unforgettable music for corporate gatherings, private events, and weddings. Hire skilled and diverse musicians in just a few clicks.',
    fr: 'De la musique inoubliable pour les réunions corporatives, les événements privés et les mariages. Engagez des musiciens habiles et diversifiés en quelques clics.',
  },
  'LandingTopSection.signupForFreeButton': {
    en: 'Sign up for free',
    fr: "S'inscrire gratuitement",
  },
  'LandingTopSection.getDemo': { en: 'Get a demo', fr: 'Obtenir une démo' },
};
