import giglinkedLogoWhite from './images/giglinkedLogoWhite.png';
import landingPageGirl from './images/landingPageGirl.jpg';
import leftBlueTriangle from './images/leftBlueTriangle.png';
import hamburgerButton from './images/hamburgerNav.png';
import hamburgerButtonDark from './images/hamburgerDark.png';
import checkMark from './images/checkMark.svg';
import checkMarkBlack from './images/checkMarkBlack.png';
import gigLinkedPhone from './images/gigLinkedPhone.png';
import wave from './images/wave.svg';
import linesLeft from './images/GigLinked_Beta-Wave.svg';
import giglinkedLogoBlack from './images/GigLinked_Logo_Black.svg';
import socialFb from './images/Social_Facebook.svg';
import socialInsta from './images/Social_Insta.svg';
import socialLinkedIn from './images/Social_Linkedin.svg';
import socialTwitter from './images/Social_Twitter.svg';
import closeIcon from './images/Close.png';
import closeIconBlack from './images/CloseBlack.png';
import soundcloud from './images/soundcloud.png';
import spotify from './images/spotify.png';
import personal from './images/personal.png';
import twitter from './images/twitter.png';
import instagram from './images/instagram.png';
import tiktok from './images/tiktok.png';
import youtube from './images/youtube.png';
import facebook from './images/facebook.png';
import starEmpty from './images/starEmpty.svg';
import starFull from './images/starFull.svg';
import smallVideoLeftArrow from './images/smallVideoLeftArrow.svg';
import smallVideoRightArrow from './images/smallVideoRightArrow.svg';
import playButton from './images/playButton.svg';
import poweredByStripeBlack from './images/poweredByStripeBlack.svg';
import whiteDownArrow from './images/whiteDownArrow.svg';
import checkMarkWhite from './images/checkMarkWhite.svg';
import googleLoginButton from './images/google_logo.svg';
import facebookLoginButton from './images/facebookLoginButton.svg';
import fineCheckMark from './images/fineCheckMark.svg';
import checkMarkGreen from './images/checkMarkGreen.svg';
import editPen from './images/editPen.svg';
import closeBlue from './images/CloseBlue.svg';
import downloadIcon from './images/downloadIcon.svg';
import soundcloudblue from './images/soundcloudblue.svg';
import bandcamp from './images/bandcamp.svg';
import bankInfo from './images/bankInfo.png';
import cameraIcon from './images/camera.svg';
import signupBandImage from './images/GigLinked_Illustrations_band.svg';
import signupSoloImage from './images/GigLinked_Illustrations_solo-artist.svg';
import signupMusicianImage from './images/GigLinked_i-am-musician-2.svg';
import signupLookingForMusicianImage from './images/GigLinked_Illustrations_looking-for-musician.svg';
import mapPin from './images/map-pin.svg';
import homebanner from './images/homebanner.jpg';
import searchIcon from './images/searchIcon.svg';
import paperPlane from './images/paperPlane.svg';
import chevron from './images/chevron.png';
import newConversation from './images/NewConversation.png';
import quebecLogo from './images/quebecLogo.png';
import canadaLogo from './images/canadaLogo.png';
import communautiqueLogo from './images/communautiqueLogo.png';
import downBlueTriangle from './images/downBlueTriangle.png';
import globeImageBlack from './images/globe_black.png';
import globeImageWhite from './images/globe_white.png';
import giglinkedBlackNoteLogo from './images/giglinked_symbol-black.png';
import giglinkedRedNoteLogo from './images/giglinked_symbol-red.png';
import founderImage from './images/founder.svg';
import howItWorksStepsFirstImage from './images/Step1_giglinked-svg.png';
import howItWorksStepsSecondImage from './images/Step2_giglinked-svg.png';
import howItWorksStepsThirdImage from './images/Step3_giglinked.png';
import howItWorksAccordionMinusImage from './images/accordion_minus.png';
import howItWorksAccordionPlusImage from './images/accordion_plus.png';
import howItWorksWhichAreYouDemoLogo from './images/Rectanglemusic.png';
import howItWorksWhichAreYouComputerEnImage from './images/computerEn.png';
import howItWorksWhichAreYouComputerFrImage from './images/computerFr.png';
import landingTabularPageImage from './images/landingTabularPageImage.png';
import tmuLogo from './images/tmuLogo.svg';
import chartwellLogo from './images/chartwellLogo.svg';
import collisionLogo from './images/collisionLogo.svg';
import zuLogo from './images/zuLogo.svg';
import musicianCardAvatar from './images/Avatar_blue.png';
import registrationMusicianSoloImage from './images/giglinked_i-am-musician-2.png';
import registrationMusicianGroupImage from './images/giglinked_band.png';
import stepperRegistrationMusicianGroupAboutPlusIcon from './images/black_plus.png';
import navBarArrowBlackImage from './images/down_arrow.png';
import navBarArrowWhiteImage from './images/down_arrow_white.png';
import backgroundBlue from './images/bg_blue.jpg';
import heroA from './images/heroA.jpg';
import heroB from './images/heroB.jpg';
import heroC from './images/heroC.jpg';
import heroD from './images/heroD.jpg';
import musicianLogo from './images/musician.svg';
import bookerLogo from './images/booker.svg';
import browseMusiciansBadge from './images/browseMusiciansBadge.svg';
import createEventBanner from './images/createEventBanner.svg';
import arrowLeft from './images/chevron-left.svg';
import success from './images/success.svg';
import detailsIcon from './images/detailsIcon.svg';
import downArrow from './images/chevron-down.svg';
import postEvent from './images/post-event.svg';
import browseMusicians from './images/browse-musicians.svg';
import retirementIconBlack from './images/retirementIconBlack.svg';
import retirementIconWhite from './images/retirementIconWhite.svg';
import dateIcon from './images/dateIcon.svg';
import timeIcon from './images/timeIcon.svg';
import locationIcon from './images/locationIcon.svg';
import groupTypeIcon from './images/groupTypeIcon.svg';
import equipmentIcon from './images/equipmentIcon.svg';
import styleIcon from './images/styleIcon.svg';
import visaPaymentIcon from './images/visa.svg';
import cardIcon from './images/credit_card.svg';
import checkIcon from './images/check.svg';
import bankPaymentIcon from './images/account_balance.svg';
import securePaymentsIcon from './images/securePaymentsIcon.svg';
import arrivalIcon from './images/arrivalIcon.svg';
import companyIcon from './images/companyIcon.svg';
import outdoorIcon from './images/outdoorIcon.svg';
import withVocalsIcon from './images/withVocalsIcon.svg';
import moreVerticalIcon from './images/moreVerticalIcon.svg';

export default {
  searchIcon,
  paperPlane,
  homebanner,
  mapPin,
  signupMusicianImage,
  signupLookingForMusicianImage,
  signupBandImage,
  signupSoloImage,
  bandcamp,
  bankInfo,
  cameraIcon,
  soundcloudblue,
  closeBlue,
  downloadIcon,
  editPen,
  checkMarkGreen,
  googleLoginButton,
  facebookLoginButton,
  fineCheckMark,
  giglinkedLogoWhite,
  landingPageGirl,
  leftBlueTriangle,
  hamburgerButton,
  checkMark,
  checkMarkBlack,
  gigLinkedPhone,
  wave,
  linesLeft,
  giglinkedLogoBlack,
  socialFb,
  socialInsta,
  socialLinkedIn,
  socialTwitter,
  closeIcon,
  closeIconBlack,
  soundcloud,
  spotify,
  personal,
  twitter,
  instagram,
  tiktok,
  youtube,
  facebook,
  starEmpty,
  starFull,
  smallVideoLeftArrow,
  smallVideoRightArrow,
  playButton,
  poweredByStripeBlack,
  whiteDownArrow,
  checkMarkWhite,
  chevron,
  newConversation,
  quebecLogo,
  canadaLogo,
  communautiqueLogo,
  downBlueTriangle,
  globeImageBlack,
  globeImageWhite,
  giglinkedBlackNoteLogo,
  giglinkedRedNoteLogo,
  founderImage,
  howItWorksStepsFirstImage,
  howItWorksStepsSecondImage,
  howItWorksStepsThirdImage,
  howItWorksAccordionMinusImage,
  howItWorksAccordionPlusImage,
  howItWorksWhichAreYouDemoLogo,
  howItWorksWhichAreYouComputerEnImage,
  howItWorksWhichAreYouComputerFrImage,
  hamburgerButtonDark,
  landingTabularPageImage,
  tmuLogo,
  chartwellLogo,
  collisionLogo,
  zuLogo,
  musicianCardAvatar,
  registrationMusicianSoloImage,
  registrationMusicianGroupImage,
  stepperRegistrationMusicianGroupAboutPlusIcon,
  navBarArrowBlackImage,
  navBarArrowWhiteImage,
  backgroundBlue,
  heroA,
  heroB,
  heroC,
  heroD,
  musicianLogo,
  bookerLogo,
  browseMusiciansBadge,
  createEventBanner,
  arrowLeft,
  success,
  detailsIcon,
  downArrow,
  browseMusicians,
  postEvent,
  retirementIconBlack,
  retirementIconWhite,
  dateIcon,
  timeIcon,
  locationIcon,
  groupTypeIcon,
  equipmentIcon,
  styleIcon,
  visaPaymentIcon,
  bankPaymentIcon,
  cardIcon,
  checkIcon,
  securePaymentsIcon,
  arrivalIcon,
  companyIcon,
  outdoorIcon,
  withVocalsIcon,
  moreVerticalIcon,
};
