import * as React from 'react';
import styled from 'styled-components';
import colors from '../../constants/colors';
import i18n from '../../constants/i18n';
import { useBookingStore } from '../../stores/booking';
import GiButton from '../common/GiButton';
import GiTextInput from '../common/GiTextInput';
import { GeneralRegularNormal, TextH2, TextH3 } from '../common/Typography';

const BookingContactInfo = ({ onPressNext }: { onPressNext: () => void }) => {
  const bookingStore = useBookingStore();
  const [firstName, setFirstName] = React.useState<string>(
    bookingStore.firstName ?? '',
  );
  const [lastName, setLastName] = React.useState<string>(
    bookingStore.lastName ?? '',
  );
  const [phoneNumber, setPhoneNumber] = React.useState<string>(
    bookingStore.phoneNumber ?? '',
  );

  return (
    <Main>
      <TextH2
        style={{
          color: colors.whiteText,
          marginBottom: 36,
          alignSelf: 'center',
          textAlign: 'center',
        }}
      >
        {i18n('BookingContact.title', 'Contact Information')}
      </TextH2>

      <GeneralRegularNormal style={{ color: 'white', marginBottom: 48 }}>
        {i18n(
          'BookingContact.subTitle',
          'This information will only be seen by the artist once the gig is confirmed.',
        )}
      </GeneralRegularNormal>

      <TextH3 style={{ color: colors.whiteText, marginBottom: 16 }}>
        {i18n('BookingContact.firstName', 'First name')}
      </TextH3>
      <GiTextInput
        value={firstName}
        placeholder="Jon"
        onChangeText={(txt) => {
          setFirstName(txt);
          bookingStore.setBookingData({ firstName: txt });
        }}
        style={{ marginBottom: 48 }}
      />
      <TextH3 style={{ color: colors.whiteText, marginBottom: 16 }}>
        {i18n('BookingContact.lastName', 'Last name')}
      </TextH3>
      <GiTextInput
        value={lastName}
        placeholder="Doe"
        onChangeText={(txt) => {
          setLastName(txt);
          bookingStore.setBookingData({ lastName: txt });
        }}
        style={{ marginBottom: 48 }}
      />
      <TextH3 style={{ color: colors.whiteText, marginBottom: 16 }}>
        {i18n('BookingContact.phone', 'Phone Number')}
      </TextH3>
      <GeneralRegularNormal style={{ color: 'white', marginBottom: 8 }}>
        {i18n(
          'BookingContact.phoneDetails',
          'Please provide a phone number that the artist can use to contact you at the gig.',
        )}
      </GeneralRegularNormal>
      <GiTextInput
        value={phoneNumber}
        placeholder="(555) 555-5555"
        mask="(999) 999-9999"
        onChangeText={(txt) => {
          setPhoneNumber(txt);
          bookingStore.setBookingData({ phoneNumber: txt });
        }}
        style={{ marginBottom: 48 }}
      />

      <GiButton
        label={i18n('BookingEventSelction.next', 'Next')}
        style={{ alignSelf: 'center', marginTop: 36 }}
        onClick={onPressNext}
        disabled={!firstName || !lastName || !phoneNumber}
      />
    </Main>
  );
};

const Main = styled.div`
  display: flex;
  box-sizing: border-box;
  flex-direction: column;
  align-self: center;
  padding-bottom: 158px;
  @media (max-width: ${(props) => props.theme.breakPoints.mobile}px) {
    padding: 0px 20px;
  }
`;

export default BookingContactInfo;
