import * as React from 'react';
import styled from 'styled-components';
import colors from '../../../constants/colors';
import { shorten } from '../../../utils/string';
import { TextH7 } from '../Typography';
import { MusicianType } from '../../../shared';
import { replaceProfileImage } from '../../../utils/media';

const MusicianCard = ({
  musician,
  style,
}: {
  musician: MusicianType;
  style?: React.CSSProperties;
}) => {
  return (
    <MainMusicianCardContainer style={style}>
      <Image src={replaceProfileImage(musician.profilePicture!)} />
      <InfoSection>
        <TextH7 style={{ fontWeight: 500, marginBottom: 0 }}>
          {shorten(musician.name, 22)}
        </TextH7>
        <TextH5Medium>{musician.location}</TextH5Medium>
        <TextH5Medium>{musician.instrument}</TextH5Medium>
      </InfoSection>
    </MainMusicianCardContainer>
  );
};

const MainMusicianCardContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
`;

const InfoSection = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 10px;
`;

const Image = styled.img`
  height: 300px;
  width: 300px;
  margin-bottom: 13px;
  margin-left: 10px;
  margin-right: 10px;
  object-fit: cover;
  border-radius: 5%;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  &:hover {
    border-top-left-radius: 5%;
    border-bottom-left-radius: 5%;
    animation-duration: 0.25s;
    border-left: 8px solid ${colors.red};
    box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
  }
`;

const TextH5Medium = styled.h5`
  font-size: 16px;
  font-family: ${(props) => props.theme.fonts.GeneralSans};
  font-weight: 500;
  color: ${(props) => props.theme.colors.darkGreyText};
  margin-bottom: 3px;
`;

export default MusicianCard;
