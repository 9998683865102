import * as React from 'react';
import styled from 'styled-components';
import colors from '../../constants/colors';
import i18n from '../../constants/i18n';
import { Transaction } from '../../shared';
import {
  formatTransactionDresscode,
  formatTransactionEstimatedAudience,
  formatTransactionEventType,
  formatTransactionStatus,
} from '../../utils/text';
import { GeneralRegularNormal, TextH4, TextH5 } from '../common/Typography';

const RequestCaracteristicsSection = ({
  request,
}: {
  request: Transaction;
}) => {
  const {
    status,
    eventType,
    quoteData,
    performerDeclineMessage,
    isOutDoor,
    isStageCovered,
    setupTime,
    dresscode,
    estimatedAudience,
    message,
    venueName,
    contactFirstName,
    contactLastName,
    contactPhone,
  } = request;

  const outdoorsText = isOutDoor
    ? i18n('RequestCaracteristicsSection.outdoors', 'Outdoors')
    : i18n('RequestCaracteristicsSection.indoors', 'Indoors');

  const coveredText = isStageCovered
    ? i18n('RequestCaracteristicsSection.covered', 'Covered')
    : i18n('RequestCaracteristicsSection.uncovored', 'Uncovered');

  const venueInformationValue = `${outdoorsText} - ${coveredText}`;

  const RequestCaracteristic = ({
    label,
    value,
  }: {
    label: string;
    value: string;
  }) => {
    return (
      <RequestCaracteristicContainer>
        <TextH5 style={{ marginBottom: 8 }}>{label}</TextH5>
        <TextH4 style={{ color: colors.black }}>{value}</TextH4>
      </RequestCaracteristicContainer>
    );
  };

  const RequestCaracteristicTextArea = ({
    label,
    value,
  }: {
    label: string;
    value: string;
  }) => {
    return (
      <RequestCaracteristicContainer>
        <TextH5 style={{ marginBottom: 8 }}>{label}</TextH5>
        <TextArea value={value} readOnly />
      </RequestCaracteristicContainer>
    );
  };

  return (
    <>
      <RequestCaracteristic
        label={i18n(
          'RequestCaracteristicsSection.requestStatus',
          'REQUEST STATUS',
        )}
        value={formatTransactionStatus(status)}
      />
      {status === 'quoteDeclined' && (
        <RequestCaracteristicTextArea
          label={i18n(
            'RequestCaracteristicsSection.declineReason',
            'Decline Reason',
          )}
          value={quoteData!.bookerQuoteDeclineMessage!}
        />
      )}
      {status === 'declined' && (
        <RequestCaracteristicTextArea
          label={i18n(
            'RequestCaracteristicsSection.bookerQuoteDeclinedMessage',
            "Performer's Request Declined Message",
          )}
          value={performerDeclineMessage!}
        />
      )}
      {venueName && (
        <RequestCaracteristic
          label={i18n('RequestCaracteristicsSection.venueName', 'VENUE NAME')}
          value={venueName}
        />
      )}

      <RequestCaracteristic
        label={i18n('RequestCaracteristicsSection.eventType', 'EVENT TYPE')}
        value={formatTransactionEventType(eventType)}
      />
      <RequestCaracteristic
        label={i18n(
          'RequestCaracteristicsSection.venueInformation',
          'VENUE INFORMATION',
        )}
        value={venueInformationValue}
      />
      <RequestCaracteristic
        label={i18n(
          'RequestCaracteristicsSection.additionalSetUpTime',
          'ADDITIONAL SET UP TIME',
        )}
        value={`${setupTime} hour${setupTime > 1 ? 's' : ''}`}
      />
      <RequestCaracteristic
        label={i18n('RequestCaracteristicsSection.dresscode', 'DRESSCODE')}
        value={dresscode ? formatTransactionDresscode(dresscode) : 'none'}
      />
      <RequestCaracteristic
        label={i18n(
          'RequestCaracteristicsSection.estimatedAudienceSize',
          'ESTIMATED AUDIENCE SIZE',
        )}
        value={formatTransactionEstimatedAudience(estimatedAudience)}
      />
      <RequestCaracteristic
        label={i18n(
          'RequestCaracteristicsSection.organizersFullname',
          "ORGANIZER'S FULLNAME",
        )}
        value={`${contactFirstName} ${contactLastName}`}
      />
      <RequestCaracteristic
        label={i18n(
          'RequestCaracteristicsSection.organizersPhone',
          "ORGANIZER'S PHONE",
        )}
        value={contactPhone}
      />
      <MessageContainer>
        <TextH5 style={{ marginBottom: 8 }}>
          {i18n(
            'RequestCaracteristicsSection.messageFromTheOrganizer',
            'MESSAGE FROM THE ORGANIZER',
          )}
        </TextH5>
        <GeneralRegularNormal style={{ color: colors.black }}>
          {message}
        </GeneralRegularNormal>
      </MessageContainer>
    </>
  );
};

const RequestCaracteristicContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 36px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.25);
`;

const MessageContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 36px;
`;

const TextArea = styled.textarea`
  padding: 12px 12px;
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  box-shadow: 4px 4px 10px rgba(131, 123, 119, 0.25);
  border-radius: 2px;
  resize: none;
`;

export default RequestCaracteristicsSection;
