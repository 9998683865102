import {
  DresscodeValues,
  DRESSCODE_TYPES,
  EstimatedAudienceValues,
  ESTIMATED_AUDIENCE,
  EventTypeValues,
  EVENT_TYPES,
  STATUS_TYPES,
  TransactionStatus,
} from '../shared';

export function formatError(e: Error) {
  return e.toString().replace(new RegExp('Error:', 'g'), '');
}

export function formatTransactionEventType(eventType: EventTypeValues) {
  const formattedEventType = EVENT_TYPES.find(
    (eventTypeType) => eventTypeType.value === eventType,
  )!.label;

  return formattedEventType;
}

export function formatTransactionEstimatedAudience(
  estimatedAudience: EstimatedAudienceValues,
) {
  const formattedEstimatedAudience = ESTIMATED_AUDIENCE().find(
    (estimatedAudienceType) =>
      estimatedAudienceType.value === estimatedAudience,
  )!.label;

  return formattedEstimatedAudience;
}

export function formatTransactionDresscode(dressCode: DresscodeValues) {
  const formattedDresscode = DRESSCODE_TYPES.find(
    (dresscodeType) => dresscodeType.value === dressCode,
  )!.label;

  return formattedDresscode;
}

export function formatTransactionStatus(status: TransactionStatus) {
  const formattedStatus = STATUS_TYPES.find(
    (statusType) => statusType.value === status,
  )!.label;

  return formattedStatus;
}
