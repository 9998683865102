import * as React from 'react';
import styled from 'styled-components';
import { observer } from 'mobx-react';
import { useUiStore } from '../../stores/ui';
import { useNavigate, useSearchParams } from 'react-router-dom';
import delay from '../../utils/delay';
import LandingFooter from '../landing/LandingFooter';
import AboutTopSection from './AboutTopSection';
// import AboutTeamSection from './AboutTeamSection';
import AboutPrinciplesSection from './AboutPrinciplesSection';
// import AboutJambassadorsSection from './AboutJamBassadorsSection';
import HowItWorksGetADemoSection from '../howItWorks/HowItWorksGetADemoSection';

const AboutPage = observer(() => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const uiStore = useUiStore();

  const maybeOpenSigninPopup = async () => {
    const performerLogin = searchParams.get('performerLogin');
    const userLogin = searchParams.get('userLogin');

    if (performerLogin || userLogin) {
      await delay(250);
      navigate(`/signin`);
    }
  };
  React.useEffect(() => {
    maybeOpenSigninPopup();
  }, []);

  return (
    <Main navBarHeight={uiStore.navBarHeight} isMobile={uiStore.isMobile}>
      <AboutTopSection />
      {/* <AboutTeamSection /> // TODO: bring back with the updated team info  */}
      <AboutPrinciplesSection />
      {/* <AboutJambassadorsSection /> */}
      <HowItWorksGetADemoSection />
      <LandingFooter />
    </Main>
  );
});

const Main = styled.div<{
  navBarHeight: number;
  isMobile: boolean;
}>`
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-top: ${(props) => (props.isMobile ? props.navBarHeight : '0')}px;
`;

export default AboutPage;
