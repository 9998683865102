import { observer } from 'mobx-react';
import * as React from 'react';
import styled from 'styled-components';

import colors from '../../constants/colors';
import i18n from '../../constants/i18n';
import { YoutubeVideo } from '../../shared';
import { GeneralRegularNormal, TextH3 } from './Typography';

import { Box } from 'react-native-kondo';
import images from '../../constants/images';
import { useUiStore } from '../../stores/ui';
import { shorten } from '../../utils/string';

const EditYoutubeVideoCell = observer(
  ({
    video,
    onClickRemove,
  }: {
    video: YoutubeVideo;
    onClickRemove: () => void;
  }) => {
    const uiStore = useUiStore();
    return (
      <YoutubeCellContainer>
        <Box
          height={103}
          // @ts-ignore
          width={uiStore.isMobile ? '80%' : 185}
          mr={uiStore.isMobile ? 0 : 24}
        >
          <YoutubeThumbnail src={video.thumbnail} />
        </Box>

        <Box flex={1}>
          <TextH3
            style={{
              marginBottom: 6,
              color: colors.black,
              marginTop: uiStore.isMobile ? 10 : 0,
            }}
          >
            {uiStore.isMobile ? shorten(video.title, 30) : video.title}
          </TextH3>
          <GeneralRegularNormal
            onClick={onClickRemove}
            style={{
              textDecoration: 'underline',

              color: '#4361EE',
              // @ts-ignore
              cursor: 'pointer',
            }}
          >
            {i18n('EditActVideos.delete', 'Remove')}
          </GeneralRegularNormal>
        </Box>
        <DragIcon src={images.hamburgerButton} />
      </YoutubeCellContainer>
    );
  },
);

const YoutubeThumbnail = styled.img`
  object-fit: cover;
  height: 100%;
  width: 100%;
`;

const YoutubeCellContainer = styled.div`
  display: flex;
  min-height: 143px;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  position: relative;
  padding: 20px 24px 20px 16px;
  background-color: #f2f3f4;
  margin-bottom: 24px;
  @media (max-width: ${(props) => props.theme.breakPoints.mobile}px) {
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 10px 14px 10px 6px;
  }
`;

const DragIcon = styled.img`
  height: 18px;
  width: 18px;
  object-fit: contain;
  margin-left: 24px;
  cursor: move; /* fallback if grab cursor is unsupported */
  cursor: grab;
  filter: invert(39%) sepia(100%) saturate(4242%) hue-rotate(223deg)
    brightness(98%) contrast(90%);
  @media (max-width: ${(props) => props.theme.breakPoints.mobile}px) {
    position: absolute;
    bottom: 50%;
    right: 15px;
  }
`;

export default EditYoutubeVideoCell;
