import * as React from 'react';
import { ViewStyle } from 'react-native';
import { Box } from 'react-native-kondo';
import styled from 'styled-components';
import colors from '../../constants/colors';
import i18n from '../../constants/i18n';
import { GeneralMediumLarge } from './Typography';

const GiGoTextInput = ({
  backgroundColor,
  onClickGo,
  mainStyle = {},
  placeholderColor,
  actionColor,
  onChangeText,
  ...rest
}: React.InputHTMLAttributes<any> & {
  mainStyle?: ViewStyle;
  backgroundColor?: string;
  onClickGo?: () => void;
  placeholderColor?: string;
  actionColor?: string;
  onChangeText?: (str: string) => void;
}) => {
  return (
    <Box
      style={{
        borderRadius: 5,
        flexDirection: 'row',
        alignItems: 'center',
        height: 43,
        backgroundColor: backgroundColor || '#F2F3F4',
        overflow: 'hidden',
        ...mainStyle,
      }}
    >
      <TextInput
        {...rest}
        placeholderColor={placeholderColor}
        actionColor={actionColor}
        onChange={(ev) => {
          if (onChangeText) {
            onChangeText(ev.target.value);
          }
        }}
      />
      <Box
        style={{ height: '60%', width: 1, marginHorizontal: 5 }}
        bg={actionColor || colors.white}
      />
      <GoButton onClick={onClickGo}>
        <GeneralMediumLarge style={{ color: actionColor || colors.white }}>
          {i18n('GiTextInput.go', 'Go')}
        </GeneralMediumLarge>
      </GoButton>
    </Box>
  );
};

const TextInput = styled.input<{
  placeholderColor: string | undefined;
  actionColor: string | undefined;
}>`
  font-size: 18px;
  font-family: ${(props) => props.theme.fonts.GeneralSans};
  font-weight: 500;
  color: ${(props) => props.actionColor || props.theme.colors.white};
  height: 100%;
  ::placeholder {
    color: ${(props) => props.placeholderColor || props.theme.colors.whiteText};
    opacity: 0.6;
  }
  width: 100%;
  text-decoration: none;
  outline: none;
  border-width: 0px;
  padding-left: 27px;
  background-color: transparent;
`;

const GoButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  min-width: 80px;
  cursor: pointer;
  background-color: transparent;
  margin-right: 5px;
  &:hover {
    opacity: 0.8;
  }
`;

export default GiGoTextInput;
