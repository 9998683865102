import * as React from 'react';
import styled from 'styled-components';

const GiglinkedButtonSignUpIn = ({
  label,
  style,
  inverted,
  onClick,
  labelColor,
  borderColor,
}: {
  label: string;
  style?: React.CSSProperties;
  inverted?: boolean;
  onClick?: () => void;
  labelColor?: string;
  borderColor?: string;
}) => {
  return (
    <Container
      style={style}
      inverted={inverted}
      onClick={onClick}
      borderColor={borderColor}
    >
      <GeneralSemiBold inverted={inverted} labelColor={labelColor}>
        {label}
      </GeneralSemiBold>
    </Container>
  );
};

const Container = styled.div<{
  inverted?: boolean;
  borderColor?: string;
}>`
  width: 104px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${(props) =>
    props.inverted ? 'none' : props.theme.colors.white};
  border-radius: 12px;
  cursor: pointer;
  transition: opacity 0.2s ease;
  border: 2px solid
    ${(props) =>
      props.borderColor ? props.borderColor : props.theme.colors.violetText};
  &:hover {
    opacity: 0.8;
  }
  @media (max-width: ${(props) => props.theme.breakPoints.tablet}px) {
    width: 162px;
    border: 2px solid
      ${(props) =>
        props.borderColor ? props.borderColor : props.theme.colors.violetText};
  }
`;

const GeneralSemiBold = styled.span<{
  inverted?: boolean;
  labelColor?: string;
}>`
  font-size: 16px;
  font-family: ${(props) => props.theme.fonts.GeneralSans};
  font-weight: 600;
  color: ${(props) =>
    props.labelColor ? props.labelColor : props.theme.colors.violetText};
`;

export default GiglinkedButtonSignUpIn;
