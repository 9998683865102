import { observer } from 'mobx-react';
import * as React from 'react';
import i18n from '../../../constants/i18n';
import { Artist } from '../../../shared';
import { useArtistStore } from '../../../stores/artist';
import { UseUserStore } from '../../../stores/user';
import { IoMdWarning } from 'react-icons/io';
import GiButton from '../../common/GiButton';
import EditableTextInput from '../../common/EditableTextInput';
import EditArtistProfileLayout from '../EditArtistProfileLayout';
import toast from 'react-hot-toast';
import { Box } from 'react-native-kondo';
import colors from '../../../constants/colors';
import { GeneralRegularNormal, TextH3, TextH6 } from '../../common/Typography';
import styled from 'styled-components';
import AddBankingInformationModal from '../../settings/AddBankingInformationModal';
import { useUiStore } from '../../../stores/ui';
import StripeSecurePayments from '../../settings/StripeSecurePayments';

const EditArtistPricing = observer(() => {
  const artistStore = useArtistStore();
  const userStore = UseUserStore();
  const me = userStore.getMe();
  const uiStore = useUiStore();

  const onSave = async (payload: Partial<Artist>) => {
    try {
      await artistStore.editMeArtist(payload);
      toast.success(i18n('EditArtistPricing.saved', 'Saved'));
    } catch (e) {
      const err = e as Error;
      toast.error(err.message);
    }
  };

  if (!me || !me.artist) {
    return <div />;
  }

  return (
    <EditArtistProfileLayout>
      <BankinRow>
        <TextH3 style={{ marginRight: 12, color: colors.black }}>
          {i18n('EditArtistPricing.titlebank', 'Banking')}
        </TextH3>
        <StripeSecurePayments
          style={{ justifyContent: 'center', margin: '1rem' }}
        />
      </BankinRow>
      {!me.artist.stripe.isAccountConnected ? (
        <Box mb={24}>
          <Box
            mb={12}
            flexDirection="row"
            alignItems="center"
            style={{
              // @ts-ignore
              cursor: 'pointer',
            }}
            onClick={() =>
              uiStore.openPopup({
                popupDisableTapOut: true,
                renderContent: () => <AddBankingInformationModal />,
              })
            }
          >
            <IoMdWarning size={24} color={colors.error} />
            <TextH6 style={{ textDecoration: 'underline', marginLeft: 5 }}>
              {i18n(
                'EditArtistPricing.inOrderToReceiveDirectPayment',
                'In order to receive direct payment, setup your banking info',
              )}
            </TextH6>
          </Box>
          <GiButton
            style={{ height: 36 }}
            onClick={() =>
              uiStore.openPopup({
                popupDisableTapOut: true,
                renderContent: () => <AddBankingInformationModal />,
              })
            }
            label={i18n('EditArtistPricing.stripeWarningButton', 'Connect')}
          />
        </Box>
      ) : (
        <Box mb={24}>
          <TextH6 style={{ marginBottom: 12 }}>
            {i18n(
              'EditArtistPricing.stripeDone',
              'Your account is connected with your bank  ✅',
            )}
          </TextH6>
        </Box>
      )}
      <TextH3 style={{ marginBottom: 16, color: colors.black }}>
        {i18n('EditArtistPricing.title', 'Gig Fees')}
      </TextH3>
      <GeneralRegularNormal style={{ maxWidth: 556, marginBottom: 16 }}>
        {i18n(
          'EditArtistPricing.subtext',
          'Gig fees are used to give the customer an idea of how much a gig may cost before submitting a request to book.',
        )}
      </GeneralRegularNormal>
      <EditableTextInput
        style={{ marginBottom: 24 }}
        value={me.artist.priceTransportDriver}
        label={i18n('EditArtistPricing.transportDriver', 'Transport (driver)')}
        type="number"
        onSave={(priceTransportDriver) => {
          if (Number(priceTransportDriver) >= 0) {
            onSave({ priceTransportDriver });
          } else {
            toast.error(
              i18n('EditArtistPricing.errorInvalidNumber', 'Invalid number.'),
            );
          }
        }}
      />
      <EditableTextInput
        style={{ marginBottom: 24 }}
        value={me.artist.priceTransportPassenger}
        label={i18n(
          'EditArtistPricing.transportPassenger',
          'Transport (passenger)',
        )}
        type="number"
        onSave={(priceTransportPassenger) => {
          if (Number(priceTransportPassenger) >= 0) {
            onSave({ priceTransportPassenger });
          } else {
            toast.error(
              i18n('EditArtistPricing.errorInvalidNumber', 'Invalid number.'),
            );
          }
        }}
      />
      <EditableTextInput
        style={{ marginBottom: 24 }}
        value={me.artist.priceAdmin}
        label={i18n('EditArtistPricing.adminfees', 'Administrative fees')}
        type="number"
        onSave={(priceAdmin) => {
          if (Number(priceAdmin) >= 0) {
            onSave({ priceAdmin });
          } else {
            toast.error(
              i18n('EditArtistPricing.errorInvalidNumber', 'Invalid number.'),
            );
          }
        }}
      />
      <EditableTextInput
        style={{ marginBottom: 24 }}
        value={me.artist.priceMeetingFees}
        label={i18n('EditArtistPricing.meetingFees', 'Meeting fees')}
        type="number"
        onSave={(priceMeetingFees) => {
          if (Number(priceMeetingFees) >= 0) {
            onSave({ priceMeetingFees });
          } else {
            toast.error(
              i18n('EditArtistPricing.errorInvalidNumber', 'Invalid number.'),
            );
          }
        }}
      />
    </EditArtistProfileLayout>
  );
});

const BankinRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 16px;
`;

export default EditArtistPricing;
