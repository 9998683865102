import { action } from 'mobx';
import { useStores } from '.';
import sexpressClient from '../utils/sexpress';
import { EventProposalType, EventType } from '../shared';

export interface EventProposalStoreType {
  initEventProposal: (
    payload: Partial<EventProposalType>,
  ) => Promise<EventProposalType>;
  fetchEventProposals: (payload?: {
    page?: number;
    limit?: number;
    eventId: EventType['id'];
  }) => Promise<{ eventProposals: EventProposalType[]; totalPages: number }>;
  withdrawEventProposal: (payload?: {
    eventId: EventType['id'];
    eventProposalId: EventProposalType['id'];
  }) => void;
}

class EventProposalStore {
  @action
  initEventProposal: EventProposalStoreType['initEventProposal'] = async (
    payload,
  ) => {
    // @ts-ignore
    return await sexpressClient.initEventProposal(payload);
  };

  @action
  fetchEventProposals: EventProposalStoreType['fetchEventProposals'] = async (
    payload,
  ) => {
    // @ts-ignore
    return await sexpressClient.fetchEventProposals(payload);
  };

  @action
  withdrawEventProposal: EventProposalStoreType['withdrawEventProposal'] = async (
    payload,
  ) => {
    // @ts-ignore
    return await sexpressClient.withdrawEventProposal(payload);
  };
}

export default new EventProposalStore();

export function useEventProposalStore(): EventProposalStoreType {
  const { eventProposal } = useStores();
  return eventProposal as EventProposalStoreType;
}
