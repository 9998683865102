import * as React from 'react';
import { Box } from 'react-native-kondo';
import styled from 'styled-components';
import colors from '../../constants/colors';
import i18n from '../../constants/i18n';
import { COUNTRY, PROVINCE } from '../../shared';
import GiButton from '../common/GiButton';
import GiDropDown from '../common/GiDropDown';
import GiTextInput from '../common/GiTextInput';
import { TextH2, TextH3 } from '../common/Typography';
import { EditDataTransactionPayload } from './CompleteTransactionController';

const BookingUserInfo = ({
  onPressNext,
  editData,
  data,
}: {
  data: EditDataTransactionPayload;
  onPressNext: () => void;
  editData: (payload: Partial<EditDataTransactionPayload>) => void;
}) => {
  return (
    <Main>
      <TextH2
        style={{
          color: colors.whiteText,
          marginBottom: 36,
          alignSelf: 'center',
          textAlign: 'center',
        }}
      >
        {i18n('BookingUserInfo.title', 'Payment preferences')}
      </TextH2>

      <TextH3 style={{ color: colors.whiteText, marginBottom: 24 }}>
        {i18n('BookingUserInfo.userinfo', 'Booker informations')}
      </TextH3>

      <TextH3
        style={{
          color: colors.whiteText,
          marginBottom: 14,
        }}
      >
        {i18n('BookingUserInfo.firstName', 'First name')}
      </TextH3>

      <GiTextInput
        placeholder={i18n(
          'BookingUserInfo.placeholderfirstName',
          'Enter your first name',
        )}
        style={{ marginBottom: 20 }}
        value={data.firstName}
        onChangeText={(firstName) => editData({ firstName })}
      />

      <TextH3
        style={{
          color: colors.whiteText,
          marginBottom: 14,
        }}
      >
        {i18n('BookingUserInfo.lastName', 'Last name')}
      </TextH3>

      <GiTextInput
        placeholder={i18n(
          'BookingUserInfo.placeholderLastName',
          'Enter your last name',
        )}
        style={{ marginBottom: 20 }}
        value={data.lastName}
        onChangeText={(lastName) => editData({ lastName })}
      />

      <TextH3
        style={{
          color: colors.whiteText,
          marginBottom: 14,
        }}
      >
        {i18n('BookingUserInfo.phone', 'Phone number')}
      </TextH3>

      <GiTextInput
        placeholder={i18n(
          'BookingUserInfo.placeholderPhone',
          'Enter your phone number',
        )}
        style={{ marginBottom: 20 }}
        value={data.phoneNumber}
        onChangeText={(phoneNumber) => editData({ phoneNumber })}
        mask={'(999) 999-9999'}
      />

      <TextH3
        style={{ color: colors.whiteText, marginBottom: 24, marginTop: 15 }}
      >
        {i18n('BookingUserInfo.billingAddress', 'Billing Address')}
      </TextH3>

      <TextH3 style={{ color: colors.whiteText, marginBottom: 16 }}>
        {i18n('BookingUserInfo.address', 'Address')}
      </TextH3>
      <GiTextInput
        value={data.locationAddress}
        placeholder="123 Address Street"
        onChangeText={(locationAddress) => {
          editData({ locationAddress });
        }}
        style={{ marginBottom: 48 }}
      />

      <Box flexDirection="row">
        <Box flex={1}>
          <TextH3 style={{ color: colors.whiteText, marginBottom: 16 }}>
            {i18n('BookingUserInfo.city', 'City')}
          </TextH3>
          <GiTextInput
            value={data.city}
            placeholder="Montreal"
            onChangeText={(city) => {
              editData({ city });
            }}
            style={{ marginBottom: 48 }}
          />
        </Box>
        <Box width={20} />
        <Box flex={1}>
          <TextH3 style={{ color: colors.whiteText, marginBottom: 16 }}>
            {i18n('BookingUserInfo.postalCode', 'Postal Code')}
          </TextH3>
          <GiTextInput
            value={data.postalCode}
            placeholder="A1B 2C3"
            mask="a9a 9a9"
            onChangeText={(postalCode) => {
              editData({ postalCode });
            }}
            style={{ marginBottom: 48 }}
          />
        </Box>
      </Box>

      <Box flexDirection="row">
        <Box flex={1}>
          <GiDropDown
            label={i18n('BookingUserInfo.province', 'Province')}
            style={{ marginBottom: 48, width: '100%' }}
            items={PROVINCE}
            value={data.province.label}
            onChange={(province) => {
              editData({ province });
            }}
          />
        </Box>
        <Box width={20} />

        <Box flex={1}>
          <GiDropDown
            label={i18n('BookingUserInfo.country', 'Country')}
            style={{ marginBottom: 48, width: '100%' }}
            items={COUNTRY}
            value={data.country.label}
            onChange={(country) => {
              editData({ country });
            }}
          />
        </Box>
      </Box>

      <GiButton
        label={i18n('BookingUserInfo.next', 'Next')}
        style={{ alignSelf: 'center', marginTop: 48 }}
        disabled={
          !data.firstName ||
          !data.lastName ||
          !data.phoneNumber ||
          !data.postalCode ||
          !data.locationAddress ||
          !data.city
        }
        onClick={onPressNext}
      />
    </Main>
  );
};

const Main = styled.div`
  display: flex;
  box-sizing: border-box;
  flex-direction: column;
  align-self: center;
  padding-bottom: 63px;
  @media (max-width: ${(props) => props.theme.breakPoints.mobile}px) {
    padding: 0px 20px 40px 20px;
  }
`;

export default BookingUserInfo;
