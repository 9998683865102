export default {
  'PriceData.priceDetails': { en: 'Price details', fr: 'Détails du prix' },
  'PriceData.giglinkedFee': {
    en: 'Giglinked fee (15%):',
    fr: 'Frais GigLinked (15%):',
  },
  'PriceData.subtotal': { en: 'Subtotal:', fr: 'Sous-total:' },
  'PriceData.gst': { en: 'GST(5%):', fr: 'GST(5%):' },
  'PriceData.qst': { en: 'QST(9,975%):', fr: 'QST(9,975%):' },
  'PriceData.totalTax': { en: 'Total tax:', fr: 'Taxe totale :' },
  'PriceData.total': { en: 'Total:', fr: 'Total:' },
  'PriceData.deposit': { en: 'Deposit (50%):', fr: 'Dépôt (50%):' },
  'PriceData.amountDue': { en: 'Amount due:', fr: 'Montant dû' },
  'PriceData.paymentDue': { en: 'Payment due:', fr: 'Paiement dû:' },
  'PriceData.learnMore': {
    en: 'Learn more about Giglinked`s refund policy',
    fr: 'En savoir plus sur la politique de remboursement de Giglinked',
  },
  'PriceData.quote': { en: 'Quote: ', fr: 'Devis: ' },
  'PriceData.taxOnlyChargedOnFee': {
    en: 'Tax is only charged on the GigLinked fee.',
    fr: "La taxe n'est prélevée que sur la redevance GigLinked.",
  },
};
