import { observer } from 'mobx-react-lite';
import * as React from 'react';
import styled from 'styled-components';
import { useUiStore } from '../../stores/ui';

const ANIMATION_TIME = 200;

const Popup = observer(() => {
  const [isShown, setIsShown] = React.useState(false);
  const [opacity, setOpacity] = React.useState(0);
  const uiStore = useUiStore();

  React.useEffect(() => {
    if (uiStore.isPopupShown) {
      setIsShown(true);
      setTimeout(() => setOpacity(1), ANIMATION_TIME);
    } else {
      setOpacity(0);
      setTimeout(() => setIsShown(false), ANIMATION_TIME);
    }
  }, [uiStore.isPopupShown]);

  if (!isShown) {
    return null;
  }

  return (
    <Main opacity={opacity}>
      <Mask
        onClick={uiStore.popupDisableTapOut ? undefined : uiStore.closePopup}
      />
      <Content>{uiStore.popupRenderContent()}</Content>
    </Main>
  );
});

const Main = styled.div<{ opacity: number }>`
  display: flex;
  position: fixed;
  flex-direction: column;
  align-items: center;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  transition: opacity ${ANIMATION_TIME / 1000}s ease;
  opacity: ${(props) => props.opacity};
  overflow-y: auto;
  overflow-x: hidden;
  @media (max-width: ${(props) => props.theme.breakPoints.mobile}px) {
  }
`;

const Mask = styled.div`
  display: flex;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
  background-color: rgba(0, 0, 0, 0.4);
  @media (max-width: ${(props) => props.theme.breakPoints.mobile}px) {
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  z-index: 2;
  box-sizing: border-box;
  margin: auto;
  @media (max-width: ${(props) => props.theme.breakPoints.mobile}px) {
  }
`;

export default Popup;
