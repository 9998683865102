export default {
  'BookingAudience.title': {
    en: 'Tell us about your audience',
    fr: 'Parlez-nous de votre public',
  },
  'BookingAudience.estimatedAudience': {
    en: 'Estimated Audience Size',
    fr: 'Taille du public estimé',
  },
};
