import * as React from 'react';
import styled from 'styled-components';
import i18n from '../../constants/i18n';
import { useUiStore } from '../../stores/ui';
import { observer } from 'mobx-react';
import { InputLabel, TextH4 } from '../common/Typography';
import GiglinkedButton from '../common/GiglinkedButton';
import images from '../../constants/images';
import colors from '../../constants/colors';
import { Column, Row } from '../common/Layout';
import toast from 'react-hot-toast';
import stripe from 'stripe-client';
import config from '../../config';
import { EditDataTransactionPayload } from '../transaction/CompleteTransactionController';
import { COUNTRY, PROVINCE, PaymentMethod } from '../../shared';
import { UseUserStore } from '../../stores/user';
import GiglinkedTextInput from '../common/GiglinkedTextInput';
import { Box } from 'react-native-kondo';
import sexpressClient from '../../utils/sexpress';
import { isEmptyObject } from '../../utils/object';

const stripeClient = stripe(config.stripePublishableKey);

const AddBillingMethodModal = observer(
  ({
    setPaymentInfo,
  }: {
    setPaymentInfo: (paymentInfo: null | PaymentMethod) => void;
  }) => {
    const uiStore = useUiStore();
    const user = UseUserStore();
    const me = user.getMe();

    const [cardExpirationDate, setCardExpirationDate] = React.useState('');
    const [cardNumber, setCardNumber] = React.useState('');
    const [cardCVC, setCardCVC] = React.useState('');
    const [cardName, setCardName] = React.useState(
      `${me?.firstName} ${me?.lastName}`,
    );
    const [transactionData, setTransactionData] = React.useState({
      firstName: me?.firstName ?? '',
      lastName: me?.lastName ?? '',
      phoneNumber: me?.contactPhone ?? '',
      locationAddress: me?.contactAddress ?? '',
      city: me?.location ?? '',
      postalCode: me?.postalCode ?? '',
      province: PROVINCE[0],
      country: COUNTRY[0],
      stripeCardToken: '',
    });

    const editData = (payload: Partial<EditDataTransactionPayload>) => {
      setTransactionData({ ...transactionData, ...payload });
    };

    const retrieveCardToken = async () => {
      const response = await sexpressClient.retrieveStripeAccountDetails({
        userId: me!.id,
      });
      if (response && !isEmptyObject(response)) setPaymentInfo(response);
    };

    const validateAndGenerateStripeToken = async () => {
      const exps = cardExpirationDate.split('/');

      const information = {
        card: {
          currency: 'cad',
          number: cardNumber.replaceAll(' ', ''),
          exp_month: exps[0],
          exp_year: exps[1],
          cvc: cardCVC,
          name: cardName,
          object: 'card',
        },
      };

      try {
        const data = await stripeClient
          .createToken(information)
          .then((j: any) => j.json());

        if (data.error) {
          throw data.error.code;
        }

        editData({
          stripeCardToken: data.id as string,
        });

        await sexpressClient.createCustomerAndPaymentMethod({
          stripeBankAccountToken: data.id,
        });

        retrieveCardToken();

        uiStore.closePopup();

        toast.success(
          i18n('AddBillingMethodModal.cardAdded', 'You added a new card'),
        );
      } catch (e) {
        let errorMsg = '';

        if (e === 'invalid_expiry_year') {
          errorMsg = i18n(
            'PaymentPref.errorExpiryYear',
            "Your card's expiration year is invalid.",
          );
        } else if (e === 'invalid_expiry_month') {
          errorMsg = i18n(
            'PaymentPref.errorExpiryMonth',
            "Your card's expiration month is invalid.",
          );
        } else if (e === 'invalid_cvc') {
          errorMsg = i18n(
            'PaymentPref.errorCVC',
            "Your card's security code is invalid.",
          );
        } else if (e === 'incorrect_number') {
          errorMsg = i18n(
            'PaymentPref.errorCardNumber',
            'Your card number is incorrect.',
          );
        } else {
          if (e) errorMsg = String(e);
          else
            errorMsg = i18n('PaymentPref.errorGeneral', 'Invalid credit card.');
        }
        toast.error(errorMsg);
      }
    };

    return (
      <Main>
        <Close src={images.closeIconBlack} onClick={uiStore.closePopup} />

        <TextH4 style={{ marginBottom: '1rem', color: colors.black }}>
          {i18n(
            'AddBillingMethodModal.selectPayment',
            'Select the payment method',
          )}
        </TextH4>
        <Box>
          <InputLabel htmlFor="cardNumber">
            {i18n('AddBillingMethodModal.cardInformation', 'Card Information')}
          </InputLabel>
          <GiglinkedTextInput
            placeholder="0000 0000 0000 0000"
            value={cardNumber}
            onChangeText={setCardNumber}
            mask="9999 9999 9999 9999"
            style={{ marginBottom: '1rem' }}
          />
          <Row style={{ marginBottom: '1rem' }}>
            <Column style={{ marginRight: '1rem' }}>
              <InputLabel htmlFor="cardExpirationDate">
                {i18n('AddBillingMethodModal.EXP', 'EXP')}
              </InputLabel>
              <GiglinkedTextInput
                placeholder={i18n(
                  'AddBillingMethodModal.expPlaceholder',
                  'MM/YY',
                )}
                value={cardExpirationDate}
                onChangeText={setCardExpirationDate}
                mask="99/99"
              />
            </Column>
            <Column style={{ marginLeft: '1rem' }}>
              <InputLabel htmlFor="cardCVC">
                {i18n('AddBillingMethodModal.CVC', 'CVC')}
              </InputLabel>
              <GiglinkedTextInput
                placeholder={'000'}
                mask="999"
                value={cardCVC}
                onChangeText={setCardCVC}
              />
            </Column>
          </Row>

          <InputLabel htmlFor="cardName">
            {i18n('AddBillingMethodModal.nameOnTheCard', 'Name on the card')}
          </InputLabel>
          <GiglinkedTextInput
            placeholder={i18n(
              'AddBillingMethodModal.nameOnTheCardPlaceholder',
              'Enter your name',
            )}
            style={{ marginBottom: 20 }}
            value={cardName}
            onChangeText={setCardName}
          />
        </Box>
        <Row style={{ marginTop: '1rem' }}>
          <GiglinkedButton
            label={i18n('AddBillingMethodModal.cancel', 'Cancel')}
            onClick={() => {
              uiStore.closePopup();
            }}
            style={{ border: 'none' }}
          />
          <GiglinkedButton
            label={i18n('AddBillingMethodModal.continue', 'Continue')}
            disabled={
              !cardName || !cardNumber || !cardExpirationDate || !cardCVC
            }
            onClick={validateAndGenerateStripeToken}
          />
        </Row>
      </Main>
    );
  },
);

const Main = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1rem 2rem;
  max-width: 627px;
  border-radius: 10px;
  background-color: white;
  align-items: center;
  @media (max-width: ${(props) => props.theme.breakPoints.mobile}px) {
    max-width: 600px;
  }
`;

const Close = styled.img`
  cursor: pointer;
  margin-left: auto;
  object-fit: contain;
  height: 12px;
`;

export default AddBillingMethodModal;
