import { observer } from 'mobx-react';
import * as React from 'react';
import debounce from 'lodash.debounce';

import i18n from '../../../constants/i18n';
import { Artist } from '../../../shared';
import { TextH3 } from '../../common/Typography';
import AddItemTextInput from '../../common/AddItemTextInput';

import sexpressClient from '../../../utils/sexpress';

import { useActStore } from '../../../stores/act';
import styled from 'styled-components';
import colors from '../../../constants/colors';
import toast from 'react-hot-toast';
import { replaceProfileImage } from '../../../utils/media';

const SearchArtistCell = ({ artist }: { artist: Artist }) => (
  <SearchCellContainer>
    <ArtistPicture src={replaceProfileImage(artist.profilePicture)} />
    <TextH3 style={{ color: colors.black }}>{artist.name}</TextH3>
  </SearchCellContainer>
);

const EditActSearchArtistBox = observer(() => {
  const actStore = useActStore();
  const act = actStore.getCurrentEditAct();
  const [value, setValue] = React.useState('');
  const [artists, setArtists] = React.useState<Artist[]>([]);
  const [selectedArtist, setSelectedArtist] = React.useState<
    Artist | undefined
  >();
  const [loading, setLoading] = React.useState(false);

  const onInviteArtist = async ({ email }: { email: string }) => {
    if (loading) {
      return;
    }
    try {
      setLoading(true);
      const updatedAct = await sexpressClient.inviteArtistToAct({
        actId: act!.id!,
        invitedArtistId: selectedArtist ? selectedArtist.id : undefined,
        invitedEmail: email,
      });
      // @ts-ignore
      actStore.setCurrentEditAct({
        ...act,
        ...updatedAct,
      });
      toast.success(
        i18n('EditActSearchArtistBox.inviteArtistSuccess', 'Artist Invited!'),
      );
    } catch (e) {
      const err = e as Error;
      if (err?.message?.includes('not found.')) {
        toast.error(
          i18n(
            'EditActSearchArtistBox.inviteArtistErr2',
            'User does not exists.',
          ),
        );
      } else {
        toast.error(
          i18n(
            'EditActSearchArtistBox.inviteArtistErr',
            'You already invited this artist.',
          ),
        );
      }
    } finally {
      setLoading(false);
    }
  };

  const fetchArtists = React.useCallback(
    debounce(async ({ searchTerm }) => {
      if (searchTerm.length && !selectedArtist) {
        const res = await sexpressClient.getArtists({
          searchTerm,
        });
        setArtists(res.artists);
      } else {
        setArtists([]);
      }
    }, 300),
    [],
  );

  return (
    <AddItemTextInput<Artist>
      label={i18n('EditActSearchArtistBox.inviteArtist', 'Invite')}
      style={{ marginBottom: 26 }}
      onChangeText={(searchTerm) => {
        fetchArtists({ searchTerm });
        setValue(searchTerm);
      }}
      placeholder={i18n(
        'EditActSearchArtistBox.inviteArtistplaceholder',
        'Stage name or email address',
      )}
      renderItem={(artist) => <SearchArtistCell artist={artist} />}
      data={artists.slice(0, 5)}
      value={value}
      loading={loading}
      onSelectItem={(item) => {
        setSelectedArtist(item);
        setValue(item.name);
        setArtists([]);
      }}
      onSave={(email) => onInviteArtist({ email })}
    />
  );
});

const ArtistPicture = styled.img`
  width: 58px;
  height: 58px;
  border-radius: 50%;
  border: 1px solid #e0e0e0;
  margin-right: 24px;
  object-fit: cover;
`;

const SearchCellContainer = styled.div`
  height: 75px;
  padding-left: 15px;
  padding-right: 15px;
  display: flex;
  border-bottom: 1px solid #e0e0e0;
  align-items: center;
  cursor: pointer;
`;

export default EditActSearchArtistBox;
