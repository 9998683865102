import * as React from 'react';
import { Box } from 'react-native-kondo';
import styled from 'styled-components';
import { IoIosCalendar, IoIosList } from 'react-icons/io';
import i18n from '../../constants/i18n';
import { TransactionStatus } from '../../shared';
import { TextH1 } from '../common/Typography';
import RequestListing from './RequestListing';
import CalendarScreen from './CalendarScreen';
import colors from '../../constants/colors';

const RequestScreen = () => {
  const [currentStatus, setCurrentStatus] =
    React.useState<TransactionStatus>('quotePending');

  const [mode, setMode] = React.useState<'list' | 'calendar'>('list');

  return (
    <Main>
      <Box
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
        style={{ maxWidth: 1000, alignSelf: 'center', width: '100%' }}
        mb={36}
        px={24}
      >
        <Box />

        <Box width={150}>
          <TextH1>{i18n('RequestScreen.title', 'Requests')}</TextH1>
        </Box>

        {mode === 'list' ? (
          <IoIosCalendar
            size={32}
            style={{
              cursor: 'pointer',
            }}
            onClick={() => setMode('calendar')}
            color={colors.lightPurple}
          />
        ) : (
          <IoIosList
            size={32}
            style={{
              cursor: 'pointer',
            }}
            onClick={() => setMode('list')}
            color={colors.lightPurple}
          />
        )}
      </Box>

      <Content>
        {mode === 'list' ? (
          <RequestListing
            status={currentStatus}
            setCurrentStatus={setCurrentStatus}
          />
        ) : (
          <CalendarScreen />
        )}
      </Content>
    </Main>
  );
};

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-self: center;
  width: 100%;
  max-width: 1000px;
  padding-left: 24px;
  padding-right: 24px;
  padding-bottom: 24px;
  box-sizing: border-box;

  @media (max-width: ${(props) => props.theme.breakPoints.mobile}px) {
  }
`;

const Main = styled.div`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  padding-top: 64px;
  flex: 1;

  @media (max-width: ${(props) => props.theme.breakPoints.mobile}px) {
    padding-top: 24px;
  }
`;

export default RequestScreen;
