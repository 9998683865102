import { useStores } from '.';

export interface NavigationStoreType {
  navigate?: (route: string) => void;
  setNavigate: (fn: NavigationStoreType['navigate']) => void;
}

class NavigationStore {
  navigate: NavigationStoreType['navigate'] = undefined;

  setNavigate: NavigationStoreType['setNavigate'] = (fn) => {
    this.navigate = fn;
  };
}

export default new NavigationStore();

export function useNavigationStore(): NavigationStoreType {
  const { navigation } = useStores();
  return navigation as NavigationStoreType;
}
