import { observer } from 'mobx-react';
import * as React from 'react';
import NavBarDiscoverDropdown from './NavBarDiscoverDropdown';
import i18n from '../../../constants/i18n';
import { useNavigate } from 'react-router-dom';
import { UseUserStore } from '../../../stores/user';
import { GeneralMediumSubtitle } from '../../common/Typography';

const NavBarContentView = observer(({ onClose }: { onClose: () => void }) => {
  const navigate = useNavigate();
  const userStore = UseUserStore();
  const isPerformer = userStore.getMe()?.artist;

  return (
    <>
      <NavBarDiscoverDropdown onClose={onClose} />
      <GeneralMediumSubtitle
        style={{ marginRight: '1rem' }}
        onClick={() => {
          navigate(
            `/performers?page=${encodeURIComponent(1)}&keywords=&location=`,
          );
          onClose();
        }}
      >
        {i18n('NavBar.browseMusicians', 'Browse Musicians')}
      </GeneralMediumSubtitle>
      <GeneralMediumSubtitle
        style={{ marginRight: '1rem' }}
        onClick={() =>
          isPerformer ? navigate(`/events`) : navigate(`/events/new`)
        }
      >
        {isPerformer
          ? i18n('NavigationDrawer.gigs', 'Gigs')
          : i18n('NavigationDrawer.createEvent', 'Create event')}
      </GeneralMediumSubtitle>
    </>
  );
});

export default NavBarContentView;
