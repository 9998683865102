import { observer } from 'mobx-react';
import * as React from 'react';
import { useEventStore } from '../../stores/event';
import toast from 'react-hot-toast';
import { EventType } from '../../shared';
import styled from 'styled-components';
import i18n from '../../constants/i18n';
import colors from '../../constants/colors';
import { UseUserStore } from '../../stores/user';
import PageLayoutFooter from '../common/PageLayoutFooter';
import { isArrayEmpty } from '../../utils/arrays';
import MusicianEventCard from './MusicianEventCard';
import MusicianNewEventCard from './MusicianNewEventCard';

interface TabData {
  id: string;
  text: string;
}

const EventsList = observer(() => {
  const eventStore = useEventStore();
  const userStore = UseUserStore();
  const me = userStore.getMe();

  const {
    events,
    currentEventTab,
    setCurrentEventTab,
    fetchEvents,
    setEvents,
  } = eventStore;

  React.useEffect(() => {
    setCurrentEventTab('open');
  }, []);

  React.useEffect(() => {
    const fetchMeEvents = async () => {
      try {
        const res = await fetchEvents();
        const { events } = res;
        setEvents(events);
      } catch (e) {
        const err = e as Error;
        toast.error(err.message);
      }
    };
    fetchMeEvents();
  }, [location.pathname]);

  const appliedToEvents = events?.filter((event: EventType) =>
    event.proposals?.some((proposal) => proposal.artist.id === me?.artist.id),
  );

  const openEvents = events?.filter(
    (event: EventType) =>
      isArrayEmpty(event.proposals) ||
      event.proposals?.some((proposal) => proposal.artist.id !== me?.artist.id),
  );

  return (
    <Main>
      <TopBanner>
        <Title>{i18n('EventsList.title', 'Find your next Gig')}</Title>
      </TopBanner>
      <TabularContainer>
        {[
          {
            id: 'open',
            text: i18n('EventsList.open', 'Open gigs'),
          },
          {
            id: 'applied',
            text: i18n('DashboardLanding.applications', 'Applications'),
          },
        ].map((tab: TabData) => (
          <StyledBox
            key={tab.id}
            selected={currentEventTab === tab.id}
            onClick={() => setCurrentEventTab(tab.id)}
          >
            <TabularH4Title selected={currentEventTab === tab.id}>
              {tab.text}
            </TabularH4Title>
          </StyledBox>
        ))}
      </TabularContainer>
      {currentEventTab === 'open' &&
        openEvents?.map((event: EventType) => (
          <MusicianNewEventCard key={event.id} event={event} />
        ))}
      {currentEventTab === 'applied' &&
        appliedToEvents?.map((event: EventType) => (
          <MusicianEventCard key={event.id} event={event} />
        ))}
      <PageLayoutFooter
        style={
          isArrayEmpty(events)
            ? {
                bottom: 0,
                position: 'absolute',
                width: '100%',
              }
            : { marginTop: '30px' }
        }
      />
    </Main>
  );
});

const Main = styled.div`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  flex: 1;
`;

const TopBanner = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  height: 178px;
  padding: 0 160px;
  background: radial-gradient(
    77.01% 120% at 100% 50%,
    #36eded 0%,
    #a84781 82.6%
  );
  @media (max-width: ${(props) => props.theme.breakPoints.mobile}px) {
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    padding: 0 80px;
  }
`;

const TabularContainer = styled.div`
  display: flex;
  align-self: center;
  margin-bottom: 1rem;
  @media (max-width: ${(props) => props.theme.breakPoints.mobile}px) {
    width: 100%;
    padding: 0 1rem;
    justify-content: center;
  }
`;

const StyledBox = styled.div<{ selected: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  box-sizing: border-box;
  width: 250px;
  height: 68px;
  border-bottom: ${(props) =>
    props.selected ? `2px solid ${colors.tabOrange}` : `2px solid transparent`};
  justify-content: center;
  &:hover {
    transform: translate(0, -5px);
    -webkit-transform: translate(0, -5px);
    -o-transform: translate(0, -5px);
    -moz-transform: translate(0, -5px);
    transition: transform 0.2s;
  }
  @media (max-width: ${(props) => props.theme.breakPoints.mobile}px) {
    width: 130px;
  }
`;

const TabularH4Title = styled.h4<{ selected: boolean }>`
  cursor: pointer;
  margin-bottom: 0;
  font-size: 18px;
  font-family: ${(props) => props.theme.fonts.GeneralSans};
  font-weight: 500;
  align-item: center;
  color: ${(props) => (props.selected ? colors.violet : colors.tableTextColor)};
  @media (max-width: ${(props) => props.theme.breakPoints.mobile}px) {
    font-size: 14px;
    margin-left: 7px;
  }
`;

const Title = styled.div`
  width: 50%;
  font-size: 32px;
  margin-bottom: 0.5rem;
  font-weight: 500;
  color: ${(props) => props.theme.colors.white};
  font-family: ${(props) => props.theme.fonts.GeneralSans};
  @media (max-width: ${(props) => props.theme.breakPoints.mobile}px) {
    font-size: 24px;
  }
`;

export default EventsList;
