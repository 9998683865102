import * as React from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

const reorder = (list: any, startIndex: number, endIndex: number) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
};

const getListStyle = () => ({
  width: '100%',
});

const getListStyleHorizontal = () => ({
  display: 'flex',

  overflow: 'hidden',
  justifyContent: 'space-between',
});

function GiDraggableList<Type>({
  data,
  renderItem,
  onReorder,
  direction = 'vertical',
}: {
  data: Type[];
  renderItem: (item: Type, index: number) => React.ReactNode;
  onReorder?: (newList: Type[]) => void;
  direction?: 'horizontal' | 'vertical';
}) {
  const assembleData = (data: Type[]) => {
    return data.map((i, index) => ({
      item: i,
      id: `${new Date().getTime()}${index}`,
    }));
  };

  const [dataToRender, setDataToRender] = React.useState<
    { id: string; item: Type }[]
  >(assembleData(data));

  React.useEffect(() => {
    setDataToRender(assembleData(data));
  }, [data]);

  const onDragEnd = (result: any) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const items = reorder(
      dataToRender,
      result.source.index,
      result.destination.index,
    );

    setDataToRender(items as any);

    if (onReorder) {
      onReorder(items.map((element: any) => element.item));
    }
  };

  const styleToUse =
    direction === 'horizontal' ? getListStyleHorizontal : getListStyle;

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="droppable" direction={direction}>
        {(provided) => (
          <div
            {...provided.droppableProps}
            ref={provided.innerRef}
            style={{
              flexWrap: direction === 'horizontal' ? 'wrap' : 'nowrap',

              ...styleToUse(),
            }}
          >
            {dataToRender.map((element, index) => (
              <Draggable
                key={element.id}
                draggableId={element.id}
                index={index}
              >
                {(provided, snapshot) => (
                  <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    style={{
                      ...provided.draggableProps.style,
                      opacity: snapshot.isDragging ? 0.7 : 1,
                    }}
                  >
                    {renderItem(element.item, index)}
                  </div>
                )}
              </Draggable>
            ))}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
}

export default GiDraggableList;
