export default {
  'RequestDetailsQuoteSection.updateQuote': {
    en: 'Update Quote',
    fr: 'Mettre à jour le devis',
  },
  'RequestDetailsQuoteSection.sendQuote': {
    en: 'Send Quote',
    fr: 'Envoyer un devis',
  },
  'RequestDetailsQuoteSection.quoteUpdated!': {
    en: 'Quote Updated!',
    fr: 'Citation mise à jour!',
  },
  'RequestDetailsQuoteSection.quoteSent!': {
    en: 'Quote Sent!',
    fr: 'Citation envoyée!',
  },
  'RequestDetailsQuoteSection.accept': { en: 'Accept', fr: 'Accepter' },
  'RequestDetailsQuoteSection.requestedBy': {
    en: 'Requested By',
    fr: 'Demandé par',
  },
  'RequestDetailsQuoteSection.musician': { en: 'Musician', fr: 'Musicienne' },
  'RequestDetailsQuoteSection.enterAQuote': {
    en: 'Enter a quote',
    fr: 'Entrez un devis',
  },
  'RequestDetailsQuoteSection.quote': { en: 'Quote', fr: 'Devis' },
  'RequestDetailsQuoteSection.decline': { en: 'Decline', fr: 'Déclin' },
  'RequestDetailsQuoteSection.send': { en: 'Send', fr: 'Envoyer' },
  'RequestDetailsQuoteSection.message': { en: 'Message', fr: 'Message' },
  'RequestDetailsQuoteSection.sendMessage': {
    en: 'Send Message',
    fr: 'Envoyer le message',
  },
};
