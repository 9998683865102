import * as React from 'react';
import { Box } from 'react-native-kondo';
import styled from 'styled-components';
import i18n from '../../../constants/i18n';
import { TextH2, GeneralRegularNormal } from '../Typography';
import ReviewCell from '../../review/ReviewCell';
import { Entity } from '../../../shared';

const ProfileReviewSection = ({ entity }: { entity: Entity }) => {
  if (!entity.nbOfReviews) {
    return null;
  }

  return (
    <Main>
      <Box flexDirection="row" alignItems="center" mb={15}>
        <TextH2 style={{ marginRight: 8 }}>
          {i18n('ArtistReviewSection.title', 'Reviews')}
        </TextH2>
        <GeneralRegularNormal style={{ color: '#777777' }}>
          {entity.nbOfReviews}
        </GeneralRegularNormal>
      </Box>
      {entity.reviews.map((review: any) => (
        <ReviewCell key={review.id} review={review} />
      ))}
      {/**
       * TODO: Add pagination for reviews
       <LoadMoreContainer>
         <TextButton>
           {i18n('ArtistReviewSection.loadMore', 'Load More')}
         </TextButton>
       </LoadMoreContainer>
       */}
    </Main>
  );
};

// const LoadMoreContainer = styled.div`
//   display: flex;
//   flex-direction: column;
//   justify-content: center;
//   align-items: center;
//   height: 60px;
//   background: #f2f3f4;
//   border-radius: 5px;
//   cursor: pointer;
//   &:hover {
//     filter: brightness(102%);
//   }
//   @media (max-width: ${(props) => props.theme.breakPoints.mobile}px) {
//   }
// `;

const Main = styled.div`
  display: flex;
  flex-direction: column;
  @media (max-width: ${(props) => props.theme.breakPoints.mobile}px) {
  }
`;

export default ProfileReviewSection;
