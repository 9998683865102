import * as React from 'react';
import styled from 'styled-components';
import { ClipLoader } from 'react-spinners';

import { GeneralSemiBoldNormal } from './Typography';
import colors from '../../constants/colors';

const GiButton = ({
  label,
  style,
  inverted,
  onClick,
  disabled,
  grayDisabled,
  loading,
}: {
  label: string;
  style?: React.CSSProperties;
  inverted?: boolean;
  onClick?: () => void;
  disabled?: boolean;
  grayDisabled?: boolean;
  loading?: boolean;
}) => {
  return (
    <Container
      style={style}
      inverted={inverted}
      onClick={disabled || loading ? undefined : onClick}
      disabled={disabled}
      grayDisabled={grayDisabled}
      tabIndex={0}
    >
      {loading ? (
        <ClipLoader color={colors.whiteText} size={20} />
      ) : (
        <GeneralSemiBoldNormal style={{ textAlign: 'center' }}>
          {label}
        </GeneralSemiBoldNormal>
      )}
    </Container>
  );
};

const Container = styled.div<{
  inverted?: boolean;
  disabled?: boolean;
  grayDisabled?: boolean;
}>`
  max-width: 200px;
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${(props) =>
    props.inverted ? 'white' : props.theme.colors.red};
  border-radius: 6px;
  cursor: ${(props) => (props.disabled ? undefined : 'pointer')};
  background-color: ${(props) =>
    props.disabled
      ? props.grayDisabled
        ? 'rgba(32,32,32,0.3)'
        : 'rgba(67, 97, 238,0.3)'
      : undefined};
  transition: opacity 0.2s ease;
  border: 1px solid
    ${(props) =>
      props.disabled
        ? props.grayDisabled
          ? 'none'
          : 'rgba(67, 97, 238,0.3)'
        : props.theme.colors.red};
  span {
    color: ${(props) =>
      props.inverted
        ? props.theme.colors.red
        : props.disabled
        ? props.grayDisabled
          ? 'rgba(32,32,32,0.3)'
          : 'rgba(255,255,255,0.3)'
        : props.theme.colors.white};
  }
  &:hover {
    filter: ${(props) => (props.disabled ? undefined : 'brightness(115%)')};
    background-color: ${(props) =>
      props.disabled ? undefined : props.theme.colors.red};
    span {
      color: ${(props) =>
        props.disabled
          ? props.grayDisabled
            ? 'rgba(32,32,32,0.3)'
            : 'rgba(255,255,255,0.3)'
          : props.theme.colors.white};
    }
  }
`;

export default GiButton;
