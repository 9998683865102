import { observer } from 'mobx-react';
import * as React from 'react';
import styled from 'styled-components';
import i18n from '../../constants/i18n';
import { useUiStore } from '../../stores/ui';
import {
  GeneralRegularLarge,
  TextH1,
  TextH5,
  TextH6,
} from '../common/Typography';
import { Box } from 'react-native-kondo';
import colors from '../../constants/colors';

const AboutPrinciplesSection = observer(() => {
  const uiStore = useUiStore();

  return (
    <Main>
      <StyledContainer>
        <Box style={{ width: uiStore.isMobile ? '100%' : '300px' }}>
          <TextH1>
            {i18n('AboutPrinciplesSection.corePrinciples', 'Core Principles')}
          </TextH1>
        </Box>
      </StyledContainer>

      <StyledContainer>
        <Box style={{ width: uiStore.isMobile ? '100%' : '600px' }}>
          <GeneralRegularLarge style={{ color: colors.black }}>
            {i18n(
              'AboutPrinciplesSection.secondaryText',
              'The rhythm and melody to our awesomeness.',
            )}
          </GeneralRegularLarge>
        </Box>
      </StyledContainer>

      <StyledContainer style={{ paddingBottom: '48px' }}>
        <StyledBox>
          <Box
            style={{ display: 'flex', flexDirection: 'column', width: '100%' }}
          >
            <TextH5>
              {i18n('AboutPrinciplesSection.oneLove', 'One Love')}
            </TextH5>
            <TextH6 style={{ paddingTop: 12 }}>
              {i18n(
                'AboutPrinciplesSection.oneLoveDesc',
                "We're united with our community through the love of music, creativity, and entrepreneurship.",
              )}
            </TextH6>
          </Box>
          <Box style={{ display: 'flex', flexDirection: 'column' }}>
            <TextH5 style={{ paddingTop: 24 }}>
              {i18n(
                'AboutPrinciplesSection.sitDownBeHumble',
                'Sit Down, Be Humble',
              )}
            </TextH5>
            <TextH6 style={{ paddingTop: 12 }}>
              {i18n(
                'AboutPrinciplesSection.sitDownBeHumbleDesc',
                "We're radically open-minded and take pride in being lifelong learners.",
              )}
            </TextH6>
          </Box>
        </StyledBox>

        <StyledBox>
          <Box
            style={{ display: 'flex', flexDirection: 'column', width: '100%' }}
          >
            <TextH5 style={{ paddingTop: 24 }}>
              {i18n('AboutPrinciplesSection.comeTogether', 'Come Together')}
            </TextH5>
            <TextH6 style={{ paddingTop: 12 }}>
              {i18n(
                'AboutPrinciplesSection.comeTogetherDesc',
                "We're collaborators at heart, and strive to always be in sync through transparency and candour.",
              )}
            </TextH6>
          </Box>

          <Box style={{ display: 'flex', flexDirection: 'column' }}>
            <TextH5 style={{ paddingTop: 24 }}>
              {i18n(
                'AboutPrinciplesSection.whatWonderfulWorld',
                'What a wonderful world',
              )}
            </TextH5>
            <TextH6 style={{ paddingTop: 12 }}>
              {i18n(
                'AboutPrinciplesSection.whatWonderfulWorldDesc',
                "We're driven by optimism and the belief that simplicity is the key to improvement.",
              )}
            </TextH6>
          </Box>
        </StyledBox>
      </StyledContainer>
    </Main>
  );
});

const Main = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  background: ${colors.lightGray};
`;

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 48px;
  padding-bottom: 0;
  @media (max-width: ${(props) => props.theme.breakPoints.mobile}px) {
    padding: 24px;
  }
`;

const StyledBox = styled.div`
  display: flex;
  flex-direction: row;
  width: 50%;
  @media (max-width: ${(props) => props.theme.breakPoints.mobile}px) {
    flex-direction: column;
    width: 100%;
  }
`;

export default AboutPrinciplesSection;
