import * as React from 'react';
import { IoIosArrowBack } from 'react-icons/io';
import { Box } from 'react-native-kondo';
import styled from 'styled-components';
import colors from '../../constants/colors';
import images from '../../constants/images';
import { useUiStore } from '../../stores/ui';

const BookingProgressBar = ({
  currentIndex,
  numberOfScreens,
  onPressBack,
}: {
  currentIndex: number;
  numberOfScreens: number;
  onPressBack?: () => void;
}) => {
  const uiStore = useUiStore();
  return (
    <Main>
      {onPressBack ? (
        <IoIosArrowBack
          size={24}
          style={{
            cursor: 'pointer',
          }}
          color={colors.whiteText}
          onClick={onPressBack}
        />
      ) : (
        <Box />
      )}
      <Box flexDirection="row" alignItems="center">
        {Array.from({ length: numberOfScreens }, (_, i) => i).map((i) => (
          <Dot key={`dot-${i}`} isSelected={currentIndex === i} />
        ))}
      </Box>
      <Close src={images.closeIcon} onClick={uiStore.closePopup} />
    </Main>
  );
};

const Dot = ({ isSelected }: { isSelected: boolean }) => {
  return (
    <Box
      mx={7}
      style={{
        height: 6,
        width: 6,
        borderRadius: 3,
        backgroundColor: isSelected ? '#5EC8D6' : 'rgba(255, 255, 255, 0.25)',
      }}
    />
  );
};

const Main = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 48px;
  box-sizing: border-box;
  @media (max-width: ${(props) => props.theme.breakPoints.mobile}px) {
    padding: 22px;
    padding-top: 38px;
  }
`;

const Close = styled.img`
  width: 31px;
  height: 20px;
  object-fit: contain;
  cursor: pointer;
`;

export default BookingProgressBar;
