export default {
  'HowItWorksTopSection.Title': {
    en: 'Frictionless Booking',
    fr: 'Vos réservations, simplifiées',
  },
  'HowItWorksTopSection.TitleDesctiption': {
    en: 'We make it easy to book live musicians from discovery to direct deposit.',
    fr: 'Nous facilitons la réservation de musique live, de la découverte au dépôt direct.',
  },
};
