import * as React from 'react';
import styled from 'styled-components';
import images from '../../../constants/images';

const StarsSection = ({
  selectedRating,
  setSelectedRating,
}: {
  selectedRating: number;
  setSelectedRating: any;
}) => {
  const [hoverRating, setHoverRating] = React.useState(0);

  const arrayOfFive = [0, 1, 2, 3, 4];

  const InteractiveStar = ({
    selectedRating,
    hoverRating,
    index,
    onMouseEnter,
    onMouseLeave,
  }: {
    selectedRating: number;
    hoverRating: number;
    index: number;
    onMouseEnter: () => void;
    onMouseLeave: () => void;
  }) => {
    let src = images.starEmpty;

    if (selectedRating >= index) {
      src = images.starFull;
    }

    if (hoverRating) {
      src = images.starEmpty;
      if (hoverRating >= index) {
        src = images.starFull;
      }
    }

    return (
      <Star
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        onClick={() => {
          setSelectedRating(index);
          setHoverRating(0);
        }}
        src={src}
      />
    );
  };

  return (
    <div style={{ marginBottom: 24 }}>
      {arrayOfFive.map((_, index) => (
        <InteractiveStar
          key={`star-${index + 1}`}
          selectedRating={selectedRating}
          hoverRating={hoverRating}
          onMouseEnter={() => setHoverRating(index + 1)}
          onMouseLeave={() => setHoverRating(0)}
          index={index + 1}
        />
      ))}
    </div>
  );
};

const Star = styled.img`
  width: 37px;
  height: 25px;
  padding-right: 12px;
  cursor: pointer;

  @media (max-width: ${(props) => props.theme.breakPoints.mobile}px) {
  }
`;

export default StarsSection;
