import * as React from 'react';
import { Box } from 'react-native-kondo';
import colors from '../../constants/colors';
import { observer } from 'mobx-react';
import { useMessagingStore } from '../../stores/messaging';
import { TextH2 } from '../common/Typography';
import styled from 'styled-components';
import { getOtherUserFromConversation } from './messageUtils';
import { useUiStore } from '../../stores/ui';
import { IoIosArrowBack } from 'react-icons/io';
import ATag from '../common/ATag';
import { replaceProfileImage } from '../../utils/media';

const MessageScreenHeader = observer(() => {
  const messagingStore = useMessagingStore();
  const uiStore = useUiStore();
  const conversation = messagingStore.getCurrentConversation();
  if (!conversation) {
    return null;
  }

  const otherUser = getOtherUserFromConversation({ conversation });

  const onMobilePressBack = () => {
    messagingStore.setCurrentConversation(null);
    messagingStore.setCurrentOtherUser(null);
  };

  const otherUserProfilePicture = otherUser.artist ? (
    <ATag href={`/artists/${otherUser.artist.id}`}>
      <FaceIcon src={replaceProfileImage(otherUser.profilePicture)} />
    </ATag>
  ) : (
    <FaceIcon src={replaceProfileImage(otherUser.profilePicture)} />
  );

  return (
    <Box
      flexDirection="row"
      pl={uiStore.isMobile ? 5 : 70}
      alignItems="center"
      bg={colors.lightBlue}
      height={uiStore.isMobile ? 60 : 103}
      pt={uiStore.isMobile ? 5 : 19}
      pb={uiStore.isMobile ? 5 : 10}
      style={{
        zIndex: 2,
        // @ts-ignore
        'box-shadow': '0px 4px 10px rgba(131, 123, 119, 0.25)',
        borderBottom: '1px solid rgba(0, 0, 0, 0.25)',
      }}
    >
      {uiStore.isMobile && (
        <IoIosArrowBack
          size={32}
          style={{
            cursor: 'pointer',
            marginRight: 5,
          }}
          color={colors.black}
          onClick={onMobilePressBack}
        />
      )}
      {otherUserProfilePicture}
      <Box ml={uiStore.isMobile ? 10 : 20}>
        <TextH2>
          {otherUser.firstName} {otherUser.lastName}
        </TextH2>
      </Box>
    </Box>
  );
});

const FaceIcon = styled.img`
  width: 73px;
  height: 73px;
  border-radius: 36.5px;
  border: 2px solid #f0ebe4;
  @media (max-width: ${(props) => props.theme.breakPoints.mobile}px) {
    width: 50px;
    height: 50px;
    border-radius: 25px;
  }
`;

export default MessageScreenHeader;
