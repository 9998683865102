import { observer } from 'mobx-react';
import * as React from 'react';
import styled from 'styled-components';
import i18n from '../../../../../constants/i18n';
import { useNavigate } from 'react-router-dom';
import { Box } from 'react-native-kondo';
import GiglinkedButton from '../../../../common/GiglinkedButton';
import GiAuthContainer from '../../../../common/GiAuthContainer';

const RegistrationBookerWaysToBook = observer(() => {
  const navigate = useNavigate();

  return (
    <GiAuthContainer
      title={i18n(
        'RegistrationBookerWaysToBook.title',
        'Your account is created!',
      )}
      editableContent={
        <>
          <Box>
            <StyledTextH7>
              {i18n(
                'RegistrationBookerWaysToBook.firstParagraphDescription',
                "Congratulations! You're one step closer to finding the perfect musicians for your events.",
              )}
            </StyledTextH7>
            <StyledTextH7>
              {i18n(
                'RegistrationBookerWaysToBook.secondParagraphDescription',
                'Post your event details now and receive applications from professional musicians, or go to your dashboard for other options.',
              )}
            </StyledTextH7>
          </Box>

          <GiglinkedButton
            label={i18n(
              'RegistrationBookerWaysToBook.postEvent',
              'I want to post an event',
            )}
            onClick={() => navigate(`/events/new`)}
            style={{ width: '100%', marginTop: '2rem', marginBottom: '1rem' }}
          />
          <GiglinkedButton
            label={i18n(
              'RegistrationBookerWaysToBook.goToDashboard',
              'Take me to the dashboard',
            )}
            onClick={() => navigate(`/dashboard`)}
            style={{ width: '100%' }}
          />
        </>
      }
    />
  );
});

const StyledTextH7 = styled.span`
  font-size: 18px;
  font-weight: 400;
  font-family: ${(props) => props.theme.fonts.GeneralSans};
  &:nth-child(2) {
    margin-top: 18px;
    margin-bottom: 18px;
  }
  &:nth-child(3) {
    margin-bottom: 65px;
  }
`;

export default RegistrationBookerWaysToBook;
