import * as React from 'react';
import { Box } from 'react-native-kondo';
import styled from 'styled-components';
import colors from '../../constants/colors';
import i18n from '../../constants/i18n';
import { EVENT_TYPES } from '../../shared';
import { useBookingStore } from '../../stores/booking';
import GiButton from '../common/GiButton';
import GiDropDown from '../common/GiDropDown';
import { TextH2 } from '../common/Typography';

const BookingEventSelction = ({ onPressNext }: { onPressNext: () => void }) => {
  const bookingStore = useBookingStore();
  const [selectedEventType, setSelectedEventType] = React.useState<{
    label: string;
    value: string;
    // @ts-ignore
  }>(EVENT_TYPES.find((event) => event.value === bookingStore.eventType));
  return (
    <Main>
      <TextH2
        style={{
          color: colors.whiteText,
          marginBottom: 36,
          alignSelf: 'center',
          textAlign: 'center',
        }}
      >
        {i18n(
          'BookingEventSelction.pleaseSelect',
          'Please select your event type',
        )}
      </TextH2>
      <Box px={10}>
        <GiDropDown
          items={EVENT_TYPES}
          value={selectedEventType ? selectedEventType.label : undefined}
          onChange={(ev) => {
            setSelectedEventType(ev);
            bookingStore.setBookingData({
              eventType: ev.value,
            });
          }}
        />
      </Box>

      <GiButton
        label={i18n('BookingEventSelction.next', 'Next')}
        style={{ alignSelf: 'center', marginTop: 36 }}
        onClick={onPressNext}
        disabled={!selectedEventType}
      />
    </Main>
  );
};

const Main = styled.div`
  display: flex;
  box-sizing: border-box;
  flex-direction: column;
  align-self: center;
  padding-bottom: 158px;
  @media (max-width: ${(props) => props.theme.breakPoints.mobile}px) {
    padding: 0px 20px;
  }
`;

export default BookingEventSelction;
