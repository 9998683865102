/* eslint-disable react/prop-types */
import * as React from 'react';
import styled from 'styled-components';

import { GeneralRegularNormal, TextH3 } from './Typography';
import colors from '../../constants/colors';
import Dropdown from 'react-bootstrap/Dropdown';
import images from '../../constants/images';

// The forwardRef is important!!
// Dropdown needs access to the DOM node in order to position the Menu
// @ts-ignore
const CustomToggle = React.forwardRef<any>(
  // @ts-ignore
  ({ children, onClick, disabled }, ref) => (
    <ToggleContainer
      // bootstrap stuff
      // @ts-ignore
      ref={ref}
      disabled={disabled}
      onClick={(e) => {
        if (!disabled) {
          e.preventDefault();
          onClick(e);
        }
      }}
    >
      <GeneralRegularNormal
        style={{
          color: children ? colors.whiteText : 'rgba(255, 255, 255, 0.5)',
          // marginLeft: 20,
        }}
      >
        {children ? children : 'Select'}
      </GeneralRegularNormal>
      <DownArrow src={images.whiteDownArrow} />
    </ToggleContainer>
  ),
);

// The forwardRef is important!!
// Dropdown needs access to the DOM node in order to position the Menu

// @ts-ignore
const CustomDropDownItem = React.forwardRef(({ children, onClick }, ref) => (
  <DropDownItemContainer
    // bootstrap stuff
    // @ts-ignore
    ref={ref}
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}
    style={{ width: '100%' }}
  >
    <GeneralRegularNormal
      style={{
        color: colors.whiteText,
      }}
    >
      {children}
    </GeneralRegularNormal>
  </DropDownItemContainer>
));

interface Item {
  label: string;
  value: any;
}

const GiDropDown = ({
  value,
  items,
  onChange,
  style,
  label,
  disabled,
}: {
  label?: string;
  value?: string;
  items?: Item[];
  disabled?: boolean;
  onChange?: (item: Item, index: number) => void;
  style?: React.CSSProperties;
}) => {
  return (
    <Column style={style}>
      {label ? <TextH3 style={{ marginBottom: 9 }}>{label}</TextH3> : null}
      <Dropdown role="menu">
        <Dropdown.Toggle
          variant="success"
          id="dropdown-basic"
          as={CustomToggle}
          disabled={disabled}
        >
          {value}
        </Dropdown.Toggle>

        <Dropdown.Menu
          style={{
            backgroundColor: 'rgb(11, 0, 33)',
            marginTop: -5,
            padding: 0,
            width: '100%',
            overflowY: 'auto',

            maxHeight: 300,
          }}
        >
          {items &&
            items.map((item, index) => (
              <Dropdown.Item
                key={item.label}
                onSelect={onChange ? () => onChange(item, index) : undefined}
                as={CustomDropDownItem}
              >
                {item.label}
              </Dropdown.Item>
            ))}
        </Dropdown.Menu>
      </Dropdown>
    </Column>
  );
};

const Column = styled.div`
  display: flex;
  flex-direction: column;
`;

const ToggleContainer = styled.div<{ disabled?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 2px;
  box-sizing: border-box;
  padding: 10px 20px;
  cursor: ${(props) => (props.disabled ? undefined : 'pointer')};
  background: ${(props) => (props.disabled ? '#0e00238d' : '#0e0023')};
  height: 46px;
  font-size: 16px;
`;

const DropDownItemContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 35px;
  box-sizing: border-box;
  padding: 10px 20px;
  background-color: rgb(11, 0, 33);
  cursor: pointer;

  &:hover {
    background-color: rgb(42, 48, 131);
  }
  &:hover > span {
    color: white;
  }
  &:active {
    background-color: rgb(42, 48, 131);
  }
`;

const DownArrow = styled.img`
  width: 15px;
  height: 15px;
  object-fit: contain;
`;

export default GiDropDown;
