export default {
  'DeleteEventModal.title': {
    en: 'Do you really want to delete event?',
    fr: "Voulez-vous vraiment supprimer l'événement?",
  },
  'DeleteEventModal.subTitle': {
    en: 'This action will permanently delete all information and proposals from musicians.',
    fr: 'Cette action supprimera définitivement toutes les informations et propositions des musiciens.',
  },
  'DeleteEventModal.delete': { en: 'Yes, delete', fr: 'Oui, supprimer' },
  'DeleteEventModal.eventDeleteSucessMessage': {
    en: 'The event has been deleted',
    fr: "L'événement a été supprimé",
  },
  'DeleteEventModal.cancel': { en: 'Cancel', fr: 'Annuler' },
};
