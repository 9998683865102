import * as React from 'react';
import { Box } from 'react-native-kondo';
import { ClipLoader } from 'react-spinners';
import styled from 'styled-components';
import { colors } from '../../constants/colors';
import i18n from '../../constants/i18n';
import { fonts, GeneralRegularNormal } from './Typography';

function AddItemTextInput<DataType>({
  style,
  loading,
  onSave,
  error,
  setError = () => null,
  label = '',
  data,
  onSelectItem,
  renderItem,
  onChangeText = () => null,
  value,
  loadingDropDown,
  ...rest
}: {
  value?: string;
  style?: React.CSSProperties;
  loading?: boolean;
  onSave?: (newValue: string) => void;
  error?: string;
  loadingDropDown?: boolean;
  setError?: (str: string) => void;
  label?: string;
  data?: DataType[];
  renderItem?: (item: DataType, index: number) => React.ReactNode;
  onSelectItem?: (selectedItem: DataType, index: number) => void;
  onChangeText?: (value: string) => void;
} & React.InputHTMLAttributes<any>) {
  const [isInEditMode, setIsInEditMode] = React.useState(false);
  const [newValue, setNewValue] = React.useState<string>(value || '');
  const inputRef = React.useRef<any>();
  const [isFocus, setIsFocus] = React.useState(false);

  const onPressSave = () => {
    setIsInEditMode(false);
    if (onSave && newValue.length) {
      onSave(newValue);
    }
    setIsFocus(false);
    setNewValue('');
  };

  const onCancel = () => {
    setIsInEditMode(false);
    setNewValue('');
    setIsFocus(false);
  };

  React.useEffect(() => {
    onChangeText(newValue);
  }, [newValue]);

  return (
    <>
      <Main style={style}>
        <Input
          ref={inputRef}
          disabled={loading}
          {...rest}
          value={typeof value !== 'undefined' ? value : newValue}
          onKeyDown={(ev) => {
            if (isFocus) {
              if (ev.key === 'Enter') {
                onPressSave();
              } else if (ev.key === 'Escape') {
                onCancel();
              }
            }
          }}
          onFocus={() => setIsFocus(true)}
          onBlur={() => setIsFocus(false)}
          style={{
            height: 37,
            overflow: isInEditMode ? undefined : 'hidden',
            opacity: loading ? 0.6 : 1,
          }}
          onChange={(e: any) => {
            setNewValue(e.target.value);
            if (error) {
              setError('');
            }
          }}
        />
        <Box width={12} />
        <Button
          onClick={loading ? undefined : onPressSave}
          backgroundColor={newValue.length ? '#4361EE' : '#f2f3f4'}
        >
          {loading ? (
            <ClipLoader color={colors.black} size={20} />
          ) : (
            <GeneralRegularNormal
              style={{
                color: newValue.length ? colors.whiteText : colors.black,
              }}
            >
              {label}
            </GeneralRegularNormal>
          )}
        </Button>
        {error && error.length ? (
          <GeneralRegularNormal
            style={{ position: 'absolute', bottom: 0, left: 0, color: 'red' }}
          >
            {i18n('AddItemTextInput.error', 'Your url is not valid.')}
          </GeneralRegularNormal>
        ) : null}
        {data && data.length ? (
          <DropDownSection
            data={data}
            renderItem={renderItem}
            onSelectItem={onSelectItem}
            loadingDropDown={loadingDropDown}
          />
        ) : null}
      </Main>
    </>
  );
}

function DropDownSection<DataType>({
  renderItem = () => <div />,
  data = [],
  onSelectItem = () => null,
  loadingDropDown,
}: {
  data?: DataType[];
  renderItem?: (item: DataType, index: number) => React.ReactNode;
  onSelectItem?: (selectedItem: DataType, index: number) => void;
  loadingDropDown?: boolean;
}) {
  const [dropdownId] = React.useState(
    `${new Date().getTime()}${Math.random()}`,
  );
  return (
    <DropDownContainer style={{ opacity: loadingDropDown ? 0.6 : 1 }}>
      {data.map((item, index) => (
        <div
          key={`DropDownItem-${index}${dropdownId}`}
          onClick={() =>
            loadingDropDown ? undefined : onSelectItem(item, index)
          }
        >
          {renderItem(item, index)}
        </div>
      ))}
    </DropDownContainer>
  );
}

const Button = styled.div<{ backgroundColor: string }>`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 37px;
  min-width: 92px;
  padding-right: 9px;
  padding-left: 9px;
  box-sizing: border-box;
  background-color: ${(props) => props.backgroundColor};
  border-radius: 2px;
  cursor: pointer;
  &:hover {
    opacity: 0.6;
  }
  @media (max-width: ${(props) => props.theme.breakPoints.mobile}px) {
    min-width: 20px;
  }
`;

const Input = styled.input`
  text-decoration: none;
  outline: none;
  flex: 1;
  font-size: 16px;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.75);
  height: 37px;
  font-family: ${fonts.GeneralSans};
  background-color: transparent;
  border-radius: 2px;
  border: 1px solid #e0e0e0;
  padding-left: 12px;
  padding-right: 60px;
  box-sizing: border-box;
  :focus {
    border: 1px solid #5ec8d6;
  }
  @media (max-width: ${(props) => props.theme.breakPoints.mobile}px) {
  }
`;

const Main = styled.div`
  display: flex;
  box-sizing: border-box;
  padding-bottom: 24px;
  position: relative;
  @media (max-width: ${(props) => props.theme.breakPoints.mobile}px) {
  }
`;

const DropDownContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 37px;
  left: 0px;
  right: 104px;
  background: #ffffff;
  border: 1px solid #e0e0e0;
  box-sizing: border-box;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
  z-index: 2;
  @media (max-width: ${(props) => props.theme.breakPoints.mobile}px) {
    right: 70px;
  }
`;

export default AddItemTextInput;
