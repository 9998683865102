import * as React from 'react';
import colors from '../../../constants/colors';
import i18n from '../../../constants/i18n';
import { Transaction } from '../../../shared';
import { GeneralSemiBoldNormal } from '../../common/Typography';
import useGetRequestDetailsQuoteFormattedData from './useGetRequestDetailsQuoteFormattedData';

const QuoteLabel = ({ request }: { request: Transaction }) => {
  const { status } = request;
  const { meIsAct, meIsArtist } =
    useGetRequestDetailsQuoteFormattedData(request);

  const getQuoteLabel = () => {
    // TODO: Add all combinations for the list of ( (Entity|Booker) ⊗ TransactionStatus)
    // Valid and useful example
    if (
      (meIsAct || meIsArtist) &&
      ['quotePending', 'quoteSent'].includes(status)
    ) {
      return i18n('RequestDetailsQuoteSection.enterAQuote', 'Enter a quote');
    }

    // Use a combination as a default one
    return i18n('RequestDetailsQuoteSection.quote', 'Quote');
  };

  return (
    <GeneralSemiBoldNormal style={{ color: colors.black }}>
      {getQuoteLabel()}
    </GeneralSemiBoldNormal>
  );
};

export default QuoteLabel;
