import { observer } from 'mobx-react';
import * as React from 'react';
import { Box } from 'react-native-kondo';
import styled from 'styled-components';
import colors from '../../constants/colors';
import i18n from '../../constants/i18n';
import { GeneralRegularLarge, TextH1 } from '../common/Typography';
import { useUiStore } from '../../stores/ui';

const AboutTopSection = observer(() => {
  const uiStore = useUiStore();

  return (
    <Main>
      <StyledContainer>
        <Box style={{ width: uiStore.isMobile ? '100%' : '300px' }}>
          <TextH1>{i18n('AboutTopSection.header', 'About GigLinked')}</TextH1>
        </Box>
      </StyledContainer>

      <StyledContainer>
        <Box style={{ width: uiStore.isMobile ? '100%' : '600px' }}>
          <GeneralRegularLarge style={{ color: colors.black }}>
            {i18n(
              'AboutTopSection.secondaryText',
              'GigLinked connects artists and humans for the sake of spreading joy through live music.',
            )}
          </GeneralRegularLarge>
        </Box>
      </StyledContainer>

      <StyledContainer>
        {i18n('AboutTopSection.about.left', '')}
      </StyledContainer>
      <StyledContainer style={{ paddingTop: 0 }}>
        {i18n('AboutTopSection.about.right', '')}
      </StyledContainer>
    </Main>
  );
});

const Main = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  padding: 48px;
  @media (max-width: ${(props) => props.theme.breakPoints.mobile}px) {
    padding: 24px;
  }
`;

const StyledContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  padding-bottom: 48px;
  @media (max-width: ${(props) => props.theme.breakPoints.mobile}px) {
    flex-direction: column;
    padding-bottom: 24px;
  }
`;

export default AboutTopSection;
