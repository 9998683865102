import { observer } from 'mobx-react-lite';
import * as React from 'react';
import styled from 'styled-components';
import { UseUserStore } from '../../stores/user';
import CenteredClipLoader from '../common/CenteredClipLoader';
import GiCreateEventContainer from '../common/GiCreateEventContainer';
import i18n from '../../constants/i18n';
import GiglinkedButton from '../common/GiglinkedButton';
import EventDetailsStepOne from './createEventSteps/EventDetailsStepOne';
import EventDetailsStepTwo from './createEventSteps/EventDetailsStepTwo';
import EventDetailsStepThree from './createEventSteps/EventDetailsStepThree';
import EventDetailsStepFour from './createEventSteps/EventDetailsStepFour';
import { useUiStore } from '../../stores/ui';
import { EventType } from '../../shared';
import toast from 'react-hot-toast';
import { useEventStore } from '../../stores/event';
import SuccessModal from '../common/SuccessModal';
import { isStringEmptyUndefinedOrNull } from '../../utils/string';
import { useNavigate } from 'react-router-dom';

const CreateEventScreen = observer(() => {
  const user = UseUserStore();
  const me = user.getMe();
  const { innerHeight } = useUiStore();
  const uiStore = useUiStore();
  const eventStore = useEventStore();
  const navigate = useNavigate();

  const [step, setStep] = React.useState(1);
  const [description, setDescription] = React.useState(
    i18n(
      'CreateEventScreen.stepOneDescription',
      'Step 1 of 4 - General information',
    ),
  );

  const [event, setEvent] = React.useState<Partial<EventType>>({
    name: '',
    companyType: me?.companyType,
    companyName: me?.companyName,
    contactAddress: me?.contactAddress,
    contactPhone: me?.contactPhone,
    city: me?.location,
    province: me?.province ?? 'QC',
    postalCode: me?.postalCode,
    country: 'Canada',
    theme: '',
    eventDate: '',
    startTime: '',
    endTime: '',
    estimatedBudget: undefined,
    estimatedAudience: undefined,
    style: undefined,
    withVocals: 'no',
    equipment: 'provided by musician',
    performanceType: 'concert',
    description: '',
    isOutdoor: false,
    isStageCovered: false,
  });

  if (!me) {
    return <CenteredClipLoader />;
  }

  const setContent = (newStep: number) => {
    switch (newStep) {
      case 1:
        setDescription(
          i18n(
            'CreateEventScreen.stepOneDescription',
            'Step 1 of 4 - General information',
          ),
        );
        break;
      case 2:
        setDescription(
          i18n(
            'CreateEventScreen.stepTwoDescription',
            'Step 2 of 4 - Event details',
          ),
        );
        break;
      case 3:
        setDescription(
          i18n(
            'CreateEventScreen.stepThreeDescription',
            'Step 3 of 4 - Music details',
          ),
        );
        break;
      case 4:
        setDescription(
          i18n(
            'CreateEventScreen.stepFourescription',
            'Step 4 of 4 - Description',
          ),
        );
        break;
      default:
        return <></>;
    }
  };

  const onClickCreateEvent = async () => {
    try {
      await eventStore.initMeEvent(event);
      uiStore.openPopup({
        popupDisableTapOut: true,
        renderContent: () => (
          <SuccessModal
            title={i18n(
              'CreateEventScreen.successTitle',
              'Congratulations! Your event is created!',
            )}
            secondaryText={i18n(
              'CreateEventScreen.successSecondaryText',
              'It has been added to your Talent Call list on your dashboard. You can view and edit all your events in your dashboard.',
            )}
            navigate={
              <GiglinkedButton
                label={i18n(
                  'CreateEventScreen.goToDashboard',
                  'Go to Dashboard',
                )}
                onClick={() => {
                  navigate('/dashboard');
                  uiStore.closePopup();
                }}
              />
            }
          />
        ),
      });
    } catch (e) {
      const err = e as Error;
      toast.error(err.message);
    }
  };

  const checkIfNextButtonShouldBeDisabled = () => {
    switch (step) {
      case 1:
        return [
          event.name,
          event.eventDate,
          event.theme,
          event.estimatedAudience,
          event.contactPhone,
        ].some((item) => isStringEmptyUndefinedOrNull(item));
      case 2:
        return [
          event.companyType,
          event.contactAddress,
          event.city,
          event.province,
          event.postalCode,
        ].some((item) => isStringEmptyUndefinedOrNull(item));
      case 3:
        return isStringEmptyUndefinedOrNull(event.style);
      case 4:
        return isStringEmptyUndefinedOrNull(event.estimatedBudget);
      default:
        break;
    }
  };
  return (
    <GiCreateEventContainer
      title={i18n('CreateEventScreen.title', 'New Event')}
      secondaryText={description}
      editableContent={
        <ContentBox innerHeight={innerHeight}>
          <BoxTop innerHeight={innerHeight}>
            {1 === step && (
              <EventDetailsStepOne event={event} setEvent={setEvent} />
            )}
            {2 === step && (
              <EventDetailsStepTwo event={event} setEvent={setEvent} />
            )}
            {3 === step && (
              <EventDetailsStepThree event={event} setEvent={setEvent} />
            )}
            {4 === step && (
              <EventDetailsStepFour event={event} setEvent={setEvent} />
            )}
          </BoxTop>

          <BoxBottom>
            <GiglinkedButton
              style={{
                border: 'none',
                opacity: step === 1 ? 0 : 1,
              }}
              onClick={() => {
                let newStep: number;

                if (step > 1) {
                  newStep = step - 1;
                  setStep(newStep);
                } else {
                  newStep = 1;
                  setStep(newStep);
                }
                setContent(newStep);
              }}
              label={i18n('CreateEventScreen.back', 'Back')}
            />
            <GiglinkedButton
              style={{
                border: 'none',
              }}
              label={
                4 === step
                  ? i18n('CreateEventScreen.postEvent', 'Post event')
                  : i18n('CreateEventScreen.next', 'Next')
              }
              onClick={() => {
                let newStep: number;
                if (step < 4) {
                  newStep = step + 1;
                  setStep(newStep);
                  setContent(newStep);
                } else onClickCreateEvent();
              }}
              disabled={checkIfNextButtonShouldBeDisabled()}
            />
          </BoxBottom>
        </ContentBox>
      }
    />
  );
});

const ContentBox = styled.div<{ innerHeight: number }>`
  height: ${(props) => props.innerHeight - 196}px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @media (max-width: ${(props) => props.theme.breakPoints.mobile}px) {
    height: 100%;
  }
`;

const BoxTop = styled.div<{ innerHeight: number }>`
  height: ${(props) => props.innerHeight - 240}px;
  overflow-y: auto;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  place-items: flex-start;
`;

const BoxBottom = styled.div`
  height: 44px;
  display: flex;
  flex-direction: row;
  align-items: center;
  align-self: center;
  @media (max-width: ${(props) => props.theme.breakPoints.mobile}px) {
    margin-right: 5px;
    margin-top: 0;
  }
`;

export default CreateEventScreen;
