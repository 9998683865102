import * as React from 'react';
import styled from 'styled-components';
import { Transaction } from '../../../shared';
import CurrencyInput from '../../common/CurrencyInput';
import { fonts } from '../../common/Typography';
import useGetRequestDetailsQuoteFormattedData from './useGetRequestDetailsQuoteFormattedData';

const CurrencyInputSection = ({
  request,
  price,
  setPrice,
}: {
  request: Transaction;
  price: string;
  setPrice: React.Dispatch<React.SetStateAction<string>>;
}) => {
  const { status, quoteData } = request;
  const { meIsBooker } = useGetRequestDetailsQuoteFormattedData(request);

  const disableCurrencyInput =
    !['quotePending', 'quoteSent', 'quoteDeclined'].includes(status) ||
    meIsBooker;

  return (
    <>
      <CurrencyInput
        style={{
          border: '0px',
          outline: 'none',
          fontSize: 24,
          fontFamily: fonts.GeneralSans,
        }}
        value={price}
        disabled={disableCurrencyInput}
        placeholder={
          quoteData?.priceFormatted ? `$${quoteData?.priceFormatted}` : '$0.00'
        }
        onChangeText={(value) => setPrice(value)}
      />
      <Separator />
    </>
  );
};

const Separator = styled.div`
  display: flex;
  width: 100%;
  height: 1px;
  margin-top: 11px;
  margin-bottom: 8px;
  background-color: rgba(0, 0, 0, 0.25);
`;

export default CurrencyInputSection;
