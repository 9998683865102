import { observer } from 'mobx-react';
import * as React from 'react';
import styled, { keyframes } from 'styled-components';
import { fadeIn } from 'react-animations';

import colors from '../../../constants/colors';
import i18n from '../../../constants/i18n';
import { Act, Artist } from '../../../shared';
import { GeneralRegularNormal, TextH6 } from '../../common/Typography';
import AddItemTextInput from '../../common/AddItemTextInput';
import EditActsProfileLayout from '../EditActsProfileLayout';
import { Box } from 'react-native-kondo';
import images from '../../../constants/images';
import GiDraggableList from '../../common/GiDraggableList';
import sexpressClient from '../../../utils/sexpress';
import { useActStore } from '../../../stores/act';
import { UseUserStore } from '../../../stores/user';
import toast from 'react-hot-toast';

const fadeInAnimation = keyframes`${fadeIn}`;

const EditActSongs = observer(() => {
  const actStore = useActStore();
  const act = actStore.getCurrentEditAct();
  const { getMe } = UseUserStore();
  const me = getMe();
  const [error, setError] = React.useState<string>();
  const [loading, setLoading] = React.useState(false);

  const onSave = async (payload: Partial<Artist>) => {
    try {
      setError('');
      setLoading(true);
      await actStore.editMeAct({ ...payload, actId: act!.id });
    } catch (e) {
      setError('err');
    } finally {
      setLoading(false);
    }
  };

  const handleReorder = async (songs: Act['songs']) => {
    try {
      const updatedAct = await sexpressClient.editMeActReorderSongs({
        songs,
        actId: act!.id,
      });
      actStore.setCurrentEditAct(updatedAct);
    } catch (e) {
      const err = e as Error;
      toast.error(err.message);
    }
  };

  if (!me || !act) {
    return <EditActsProfileLayout />;
  }

  return (
    <EditActsProfileLayout>
      <TextH6 style={{ marginBottom: 24 }}>
        {i18n('EditActSongs.addSpotify', 'Add a Spotify song')}
      </TextH6>
      <AddItemTextInput
        error={error}
        style={{ marginBottom: 26 }}
        setError={setError}
        loading={loading}
        label={i18n('AddItemTextInput.addSong', 'Add Song')}
        onSave={(url) =>
          onSave({
            songs: [url],
          })
        }
      />

      <GiDraggableList<string>
        data={act!.songs}
        onReorder={handleReorder}
        renderItem={(url) => (
          <SongContainer
            style={{
              opacity: loading ? 0.6 : 1,
            }}
          >
            <iframe
              src={url}
              width="100%"
              height="80"
              frameBorder="0"
              allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
            />
            <Box
              flexDirection="row"
              alignItems="center"
              justifyContent="space-between"
              mt={8}
            >
              <DragIcon src={images.hamburgerButton} />

              <GeneralRegularNormal
                onClick={() =>
                  onSave({
                    songs: [url],
                  })
                }
                style={{
                  textDecoration: 'underline',

                  marginRight: 24,
                  color: colors.whiteText,
                  // @ts-ignore
                  cursor: 'pointer',
                }}
              >
                {i18n('EditActSongs.delete', 'Remove')}
              </GeneralRegularNormal>
            </Box>
          </SongContainer>
        )}
      />
    </EditActsProfileLayout>
  );
});

const SongContainer = styled.div`
  /* animation: 1s ${fadeInAnimation}; */
  padding-bottom: 8px;
  display: flex;
  flex-direction: column;
  margin-bottom: 12px;
  background-image: linear-gradient(315deg, #000000 0%, #414141 74%);
  background-color: #000000;
  border-radius: 2px;
`;

const DragIcon = styled.img`
  height: 18px;
  width: 18px;
  object-fit: contain;
  margin-left: 24px;
  cursor: move; /* fallback if grab cursor is unsupported */
  cursor: grab;
`;

export default EditActSongs;
