import * as React from 'react';
import styled from 'styled-components';
import { observer } from 'mobx-react';
import { TextH6, TextH7 } from '../Typography';
import colors from '../../../constants/colors';
import i18n from '../../../constants/i18n';
import { useEventStore } from '../../../stores/event';
import { EventProposalType } from '../../../shared';
import { Row } from '../Layout';
import { addDollarSign, capitalize } from '../../../utils/string';
import { addDays, format } from 'date-fns';
import ToolTip from '../ToolTip';
import {
  CANADA_GST,
  GIGLINKED_CUT_PERCENT,
  QUEBEC_QST,
} from '../../../constants/price';

const PriceData = observer(
  ({
    proposal,
    currentTab,
  }: {
    proposal: EventProposalType;
    currentTab: string;
  }) => {
    const eventStore = useEventStore();
    const { currentEvent } = eventStore;

    const proposalQuote = proposal.proposalQuote;

    // Calculate the Giglinked fee.
    const giglinkedFee = proposalQuote * GIGLINKED_CUT_PERCENT;

    // Calculate the subtotal by adding the Giglinked fee to the proposal quote.
    const subtotal = proposalQuote + giglinkedFee;

    // Calculate GST and QST based on the giglinked fee.
    const gst = giglinkedFee * CANADA_GST;
    const qst = giglinkedFee * QUEBEC_QST;
    // Calculate the total tax and total amounts.
    const totalTax = gst + qst;
    const total = subtotal + totalTax;

    // Format all monetary values to 2 decimal places as strings for display or further processing.
    const formattedGiglinkedFee = giglinkedFee.toFixed(2);
    const formattedSubtotal = subtotal.toFixed(2);
    const formattedGst = gst.toFixed(2);
    const formattedQst = qst.toFixed(2);
    const formattedTotalTax = totalTax.toFixed(2);
    const formattedTotal = total.toFixed(2);

    return (
      <>
        <TextH7 style={{ color: colors.violet, marginBottom: '1rem' }}>
          {i18n('PriceData.priceDetails', 'Price details')}
        </TextH7>
        <Row style={{ justifyContent: 'space-between' }}>
          <TextH6>
            {' '}
            {i18n('PriceData.quote', 'Quote: ')}
            {capitalize(proposal.artist.name)}
          </TextH6>
          <TextH6>{addDollarSign(String(proposal.proposalQuote))}</TextH6>
        </Row>

        <Row style={{ justifyContent: 'space-between' }}>
          <TextH6 style={{ fontWeight: 400 }}>
            {i18n('PriceData.giglinkedFee', 'Giglinked fee (15%):')}
          </TextH6>
          <TextH6 style={{ fontWeight: 400 }}>
            {addDollarSign(formattedGiglinkedFee)}
          </TextH6>
        </Row>

        <Row style={{ justifyContent: 'space-between', marginBottom: '1rem' }}>
          <TextH6>{i18n('PriceData.subtotal', 'Subtotal:')}</TextH6>
          <TextH6>{addDollarSign(formattedSubtotal)}</TextH6>
        </Row>

        <Row style={{ justifyContent: 'space-between' }}>
          <TextH6 style={{ fontWeight: 400 }}>
            {i18n('PriceData.gst', 'GST(5%):')}
          </TextH6>
          <TextH6 style={{ fontWeight: 400 }}>
            {addDollarSign(formattedGst)}
          </TextH6>
        </Row>

        <Row style={{ justifyContent: 'space-between' }}>
          <TextH6 style={{ fontWeight: 400 }}>
            {i18n('PriceData.qst', 'QST(9,975%):')}
          </TextH6>
          <TextH6 style={{ fontWeight: 400 }}>
            {addDollarSign(formattedQst)}
          </TextH6>
        </Row>
        <Row style={{ marginBottom: '1rem', justifyContent: 'space-between' }}>
          <Row>
            <TextH6>{i18n('PriceData.totalTax', 'Total tax:')}</TextH6>
            <ToolTip
              text={i18n(
                'PriceData.taxOnlyChargedOnFee',
                'Tax is only charged on the giglinked fee.',
              )}
              mainStyle={{
                zIndex: 9,
              }}
            />
          </Row>
          <TextH6>{addDollarSign(formattedTotalTax)}</TextH6>
        </Row>

        <Row style={{ justifyContent: 'space-between' }}>
          <TextH6>{i18n('PriceData.total', 'Total:')}</TextH6>
          <TextH6>{addDollarSign(formattedTotal)}</TextH6>
        </Row>

        <Divider />

        <Row style={{ justifyContent: 'space-between' }}>
          <TextH6 style={{ color: colors.buttonTextColor, fontSize: '20px' }}>
            {i18n('PriceData.amountDue', 'Amount due:')}
          </TextH6>
          <TextH6 style={{ color: colors.buttonTextColor, fontSize: '20px' }}>
            {currentTab === 'check' ? '$0' : addDollarSign(formattedTotal)}
          </TextH6>
        </Row>

        <Row style={{ justifyContent: 'space-between' }}>
          <TextH6 style={{ fontWeight: 400 }}>
            {i18n('PriceData.paymentDue', 'Payment due:')}
          </TextH6>
          <TextH6 style={{ fontWeight: 400 }}>
            {currentTab === 'check'
              ? format(
                  new Date(
                    addDays(new Date(currentEvent!.eventDate), 45).toString(),
                  ),
                  'PPP',
                )
              : format(
                  new Date(
                    addDays(new Date(currentEvent!.eventDate), 2).toString(),
                  ),
                  'PPP',
                )}
          </TextH6>
        </Row>

        <Divider />

        <TextH6
          style={{
            fontWeight: 400,
            textDecoration: 'underline',
            cursor: 'pointer',
          }}
        >
          {i18n(
            'PriceData.learnMore',
            'Learn more about Giglinked`s refund policy',
          )}
        </TextH6>
      </>
    );
  },
);

const Divider = styled.div`
  height: 1px;
  width: 100%;
  background: ${colors.buttonBorder};
  margin: 1rem 0;
`;

export default PriceData;
