import { observer } from 'mobx-react-lite';
import * as React from 'react';
import styled from 'styled-components';

import images from '../../constants/images';
import { Row } from '../common/Layout';
import { GeneralRegularSmall } from '../common/Typography';
import i18n from '../../constants/i18n';

const StripeSecurePayments = observer(
  ({ style }: { style?: React.CSSProperties }) => {
    return (
      <Row style={style}>
        <SecurePaymentsImage src={images.securePaymentsIcon} />
        <GeneralRegularSmall style={{ color: '#797979', fontSize: '10px' }}>
          {i18n('StripeSecurePayments.securePayment', 'Secure paymnents')}
        </GeneralRegularSmall>
        <StripeImg src={images.poweredByStripeBlack} />
      </Row>
    );
  },
);

const SecurePaymentsImage = styled.img`
  padding-right: 0.5rem;
`;

const StripeImg = styled.img`
  width: 120px;
  height: 1rem;
  align-self: center;
`;

export default StripeSecurePayments;
