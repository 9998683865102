import { observer } from 'mobx-react';
import * as React from 'react';
import Table from 'react-bootstrap/Table';
import { EventType } from '../../shared';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import i18n from '../../constants/i18n';
import toast from 'react-hot-toast';
import { useEventStore } from '../../stores/event';
import { UseUserStore } from '../../stores/user';
import { format } from 'date-fns';
import { getEventStatus } from '../../utils/eventUtils';
import { GeneralRegularNormal } from '../common/Typography';
import { isArrayEmpty } from '../../utils/arrays';
import { replaceProfileImage } from '../../utils/media';
import { addDollarSign } from '../../utils/string';

const EventsTable = observer(() => {
  const navigate = useNavigate();
  const eventStore = useEventStore();
  const userStore = UseUserStore();
  const isFrench = userStore?.browserLanguage === 'fr';

  const [events, setEvents] = React.useState<Array<EventType>>([]);

  React.useEffect(() => {
    const fetchMeEvents = async () => {
      try {
        const res = await eventStore.fetchMeEvents();

        setEvents(res.events);
      } catch (e) {
        const err = e as Error;
        toast.error(err.message);
      }
    };
    fetchMeEvents();
  }, [location.pathname]);

  return isArrayEmpty(events) ? (
    <EmptyContainer>
      <GeneralRegularNormal>
        {i18n(
          'EventsTable.noEvents',
          "Once you've posted your first event, it will appear here!",
        )}
      </GeneralRegularNormal>
    </EmptyContainer>
  ) : (
    <Table hover>
      <thead>
        <tr>
          <HeaderColumn>
            {i18n('EventsTable.eventName', 'Event name')}
          </HeaderColumn>
          <HeaderColumn>{i18n('EventsTable.date', 'Date')}</HeaderColumn>
          <HeaderColumn>{i18n('EventsTable.time', 'Time')}</HeaderColumn>
          <HeaderColumn>{i18n('EventsTable.theme', 'Theme')}</HeaderColumn>
          <HeaderColumn>
            {i18n('EventsTable.musicalStyle', 'Musical style')}
          </HeaderColumn>
          <HeaderColumn>
            {i18n('EventsTable.musician', 'Musician')}
          </HeaderColumn>
          <HeaderColumn>{i18n('EventsTable.budget', 'Budget')}</HeaderColumn>
          <HeaderColumn style={{ textAlign: 'center' }}>
            {i18n('EventsTable.proposals', 'Proposals')}
          </HeaderColumn>
          <HeaderColumn style={{ textAlign: 'right' }}>
            {i18n('EventsTable.status', 'Status')}
          </HeaderColumn>
          <HeaderColumn></HeaderColumn>
        </tr>
      </thead>
      <tbody>
        {events.map((event: EventType) => {
          return (
            <TableRow
              key={event.id}
              onClick={() => navigate(`/events/${event.id}`)}
            >
              <TableColumn>
                {event.name}
                {event.companyName ? ` - ${event.companyName}` : ''}
              </TableColumn>
              <TableColumn>
                {' '}
                {format(
                  new Date(event.eventDate),
                  isFrench ? 'y.dd.MM' : 'y.MM.dd',
                )}
              </TableColumn>
              <TableColumn>
                {format(new Date(event.startTime), 'hh:mm a')}
              </TableColumn>
              <TableColumn>{event.theme}</TableColumn>
              <TableColumn>{event.style}</TableColumn>

              <TableColumn style={{ textAlign: 'center' }}>
                {event.artist ? (
                  <img
                    src={replaceProfileImage(event.artist?.profilePicture)}
                    style={{
                      width: '40px',
                      height: '40px',
                      borderRadius: '50%',
                    }}
                  />
                ) : (
                  <EmptyMusician />
                )}
              </TableColumn>
              <TableColumn style={{ textAlign: 'center' }}>
                {addDollarSign(event.estimatedBudget.toString())}
              </TableColumn>
              <TableColumn style={{ textAlign: 'center' }}>
                {event.proposals?.length}
              </TableColumn>

              <TableColumn style={{ textAlign: 'right' }}>
                {getEventStatus(event)}
              </TableColumn>
              <TableColumn></TableColumn>
            </TableRow>
          );
        })}
      </tbody>
    </Table>
  );
});

const HeaderColumn = styled.th`
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 400;
  color: ${(props) => props.theme.colors.tableTextColor};
  font-family: ${(props) => props.theme.fonts.GeneralSans};
`;

const TableRow = styled.tr`
  cursor: pointer;
  height: 63px;
  font-family: ${(props) => props.theme.fonts.GeneralSans};
`;

const TableColumn = styled.td`
  text-transform: capitalize;
  font-size: 14px;
  font-weight: 500;
`;

const EmptyMusician = styled.img`
  border: 2px dashed #d9d9d9;
  width: 40px;
  height: 40px;
  border-radius: 50%;
`;

const EmptyContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-self: center;
  align-items: center;
  background: ${(props) => props.theme.colors.cardColor};
  border-radius: 20px;
  height: 190px;
  margin: 3rem 0;
  width: 1000px;
  @media (max-width: ${(props) => props.theme.breakPoints.mobile}px) {
    width: 100%;
  }
`;

export default EventsTable;
