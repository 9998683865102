import * as React from 'react';
import styled from 'styled-components';
import i18n from '../../constants/i18n';
import { useUiStore } from '../../stores/ui';
import { observer } from 'mobx-react';
import { InputLabel, TextH4 } from '../common/Typography';
import GiglinkedButton from '../common/GiglinkedButton';
import images from '../../constants/images';
import colors from '../../constants/colors';
import { Column, Row } from '../common/Layout';
import toast from 'react-hot-toast';
import stripe from 'stripe-client';
import config from '../../config';
import { UseUserStore } from '../../stores/user';
import { Box } from 'react-native-kondo';
import sexpressClient from '../../utils/sexpress';
import GiglinkedTextInput from '../common/GiglinkedTextInput';
import StripeSecurePayments from './StripeSecurePayments';

const stripeClient = stripe(config.stripePublishableKey);

const AddBankingInformationModal = observer(() => {
  const uiStore = useUiStore();
  const user = UseUserStore();
  const me = user.getMe();

  const [transit, setTransit] = React.useState('');
  const [institution, setInstitution] = React.useState('');
  const [account, setAccount] = React.useState('');
  const [firstName, setFirstName] = React.useState(me?.firstName ?? '');
  const [lastName, setLastName] = React.useState(me?.lastName ?? '');
  const [loading, setLoading] = React.useState(false);

  const validateAndGenerateStripeToken = async () => {
    setLoading(true);

    const information = {
      bank_account: {
        country: 'CA',
        currency: 'cad',
        account_holder_name: `${firstName} ${lastName}`,
        account_holder_type: 'individual',
        routing_number: `${transit}${institution}`,
        account_number: account,
      },
    };

    try {
      const data = await stripeClient
        .createToken(information)
        .then((j: any) => j.json());

      if (data.error) {
        throw data.error;
      }

      await sexpressClient.updateOrCreateStripeCustomerAndAccount({
        stripeBankAccountToken: data.id,
      });
      setLoading(false);
      uiStore.closePopup();

      toast.success(
        i18n(
          'AddBankingInformationModal.bankingAdded',
          "Success! Redirecting you to Stripe's secure page",
        ),
      );

      const res = await sexpressClient.generateStripeAccountOnboardingURL();
      window.location.href = res.url;
    } catch (e) {
      const err = e as any;
      setLoading(false);
      let errorMsg = '';
      if (err.type === 'invalid_request_error') {
        errorMsg = `${i18n(
          'PaymentPref.errorWrongInfo',
          'There was an error with the following number : ',
        )} ${
          err.param === 'bank_account[account_number]'
            ? 'Account number'
            : 'Transit and/or Institution number'
        }`;
      }
      toast.error(errorMsg || err.message);
    }
  };

  return (
    <Main>
      <Close src={images.closeIconBlack} onClick={uiStore.closePopup} />

      <TextH4 style={{ marginBottom: '1rem', color: colors.black }}>
        {i18n(
          'AddBankingInformationModal.bankingInformation',
          'Banking Information',
        )}
      </TextH4>
      <Box>
        <StripeSecurePayments
          style={{ justifyContent: 'center', marginBottom: '1rem' }}
        />
        <Row style={{ marginBottom: '1rem' }}>
          <Column style={{ marginRight: '1rem', width: '50%' }}>
            <InputLabel htmlFor="firstName">
              {i18n('AddBankingInformationModal.firstName', 'First name')}
            </InputLabel>
            <GiglinkedTextInput
              value={firstName}
              onChangeText={(value) => {
                setFirstName(value);
              }}
            />
          </Column>
          <Column style={{ marginLeft: '1rem', width: '50%' }}>
            <InputLabel htmlFor="lastName">
              {i18n('AddBankingInformationModal.lastName', 'Last name')}
            </InputLabel>
            <GiglinkedTextInput
              value={lastName}
              onChangeText={(value) => {
                setLastName(value);
              }}
            />
          </Column>
        </Row>
        <InputLabel htmlFor="transitNumber">
          {i18n(
            'AddBankingInformationModal.transitNumber',
            'Transit Number (1)',
          )}
        </InputLabel>
        <GiglinkedTextInput
          value={transit}
          onChangeText={(value) => {
            setTransit(value);
          }}
          style={{ marginBottom: '1rem' }}
        />

        <InputLabel htmlFor="institutionNumber">
          {i18n(
            'AddBankingInformationModal.institutionNumber',
            'Institution Number (2)',
          )}
        </InputLabel>
        <GiglinkedTextInput
          value={institution}
          onChangeText={(value) => {
            setInstitution(value);
          }}
          style={{ marginBottom: '1rem' }}
        />
        <InputLabel htmlFor="accountNumber">
          {i18n(
            'AddBankingInformationModal.accountNumber',
            'Account Number (3)',
          )}
        </InputLabel>
        <GiglinkedTextInput
          value={account}
          onChangeText={(value) => {
            setAccount(value);
          }}
          style={{ marginBottom: '1rem' }}
        />

        <CheckTemplateImg src={images.bankInfo} />
      </Box>
      <Row style={{ marginTop: '1rem' }}>
        <GiglinkedButton
          label={i18n('AddBankingInformationModal.cancel', 'Cancel')}
          onClick={() => {
            uiStore.closePopup();
          }}
          style={{ border: 'none' }}
        />
        <GiglinkedButton
          onClick={validateAndGenerateStripeToken}
          label={i18n('SupportScreen.submit', 'Submit')}
          loading={loading}
          disabled={
            !transit || !institution || !account || !firstName || !lastName
          }
        />
      </Row>
    </Main>
  );
});

const Main = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1rem 2rem;
  max-width: 627px;
  border-radius: 10px;
  background-color: white;
  align-items: center;
  @media (max-width: ${(props) => props.theme.breakPoints.mobile}px) {
    max-width: 600px;
  }
`;

const Close = styled.img`
  cursor: pointer;
  margin-left: auto;
  object-fit: contain;
  height: 12px;
`;

const CheckTemplateImg = styled.img`
  margin-top: 16px;
  width: 100%;
`;

export default AddBankingInformationModal;
