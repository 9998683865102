export default {
  'SharedTypes.select': { en: 'Select', fr: 'Sélectionner' },
  'SharedTypes.wedding': {
    en: 'Wedding planner',
    fr: 'Planificateur de mariages',
  },
  'SharedTypes.networking': {
    en: 'Networking event',
    fr: 'vénement de réseautage',
  },
  'SharedTypes.anniversary': { en: 'Anniversary', fr: 'Anniversaire' },
  'SharedTypes.holidayparty': { en: 'Holiday party', fr: 'Fête thématique' },
  'SharedTypes.fundraiser': { en: 'Fundraiser', fr: 'Collecte de fonds' },
  'SharedTypes.funerals': { en: 'Funerals', fr: 'Funérailles' },
  'SharedTypes.proposal': { en: 'Marriage proposal', fr: 'Demande en mariage' },
  'SharedTypes.cocktail': { en: 'Cocktail', fr: 'Cocktail' },
  'SharedTypes.houseparty': { en: 'House party', fr: 'Fête privée' },
  'SharedTypes.barmitzvah': {
    en: 'Bar / Bat Mitzvah',
    fr: 'Bar / Bat Mitzvah(s)',
  },
  'SharedTypes.sportingevent': {
    en: 'Sporting event',
    fr: 'Événement sportif',
  },
  'SharedTypes.none': { en: 'None', fr: 'Aucine' },
  'SharedTypes.formal': { en: 'Formal', fr: 'Formel' },
  'SharedTypes.blacktie': { en: 'Black tie', fr: 'Professionnel' },
  'SharedTypes.businesscasual': {
    en: 'Business casual',
    fr: 'Professionnel décontracté',
  },
  'SharedTypes.casual': { en: 'Casual', fr: 'Décontracté' },
  'SharedTypes.openhouse': { en: 'Open house', fr: 'Journée portes ouvertes' },
  'SharedTypes.danceparty': { en: 'Dance party', fr: 'Soirée dansante' },
  'SharedTypes.funeral': { en: 'Funeral', fr: 'Funérailles' },
  'SharedTypes.quintet': { en: 'Quintet', fr: 'Quintet' },
  'SharedTypes.band': { en: 'Band', fr: 'Band' },
  'SharedTypes.duo': { en: 'Duo', fr: 'Duo' },
  'SharedTypes.bigband': { en: 'Big band', fr: 'Big band' },
  'SharedTypes.brass': { en: 'Brass band', fr: 'Orchestre de cuivres' },
  'SharedTypes.trio': { en: 'Trio', fr: 'Trio' },
  'SharedTypes.mariachi': { en: 'Mariachi', fr: 'Mariachi' },
  'SharedTypes.strings': { en: 'String ensemble', fr: 'Ensemble à cordes' },
  'SharedTypes.ensemble': { en: 'Ensemble', fr: 'Ensemble' },
  'SharedTypes.quartet': { en: 'Quartet', fr: 'Quatuor' },
  'SharedTypes.woodwind': { en: 'Woodwind ensemble', fr: 'Ensemble de bois' },
  'SharedTypes.collective': { en: 'Collective', fr: 'Collectif' },
  'SharedTypes.other': { en: 'Other', fr: 'Autre' },
  'SharedTypes.unsure': { en: 'Unsure', fr: 'Pas sûr' },
  'SharedTypes.dj': { en: 'DJ', fr: 'DJ' },
  'SharedTypes.rock': { en: 'Rock', fr: 'Rock' },
  'SharedTypes.classical': { en: 'Classical', fr: 'Classique' },
  'SharedTypes.jazz': { en: 'Jazz', fr: 'Jazz' },
  'SharedTypes.lounge': { en: 'Lounge', fr: 'Lounge' },
  'SharedTypes.originalcompositions': {
    en: 'Original compositions',
    fr: 'Compositions originales',
  },
  'SharedTypes.worldmusic': { en: 'World Music', fr: 'Musique du monde' },
  'SharedTypes.pop': { en: 'Pop', fr: 'Pop' },
  'SharedTypes.dance': { en: 'Dance', fr: 'Dance' },
  'SharedTypes.electronic': { en: 'Electronic', fr: 'Electronique' },
  'SharedTypes.hiphop': { en: 'Hip Hop', fr: 'Hip Hop' },
  'SharedTypes.country': { en: 'Country', fr: 'Country' },
  'SharedTypes.traditional': { en: 'Traditional', fr: 'Traditionelle' },
  'SharedTypes.providedonsite': {
    en: 'Provided on site',
    fr: 'Fourni sur place',
  },
  'SharedTypes.providedbymusician': {
    en: 'Provided by musician',
    fr: 'Fourni par le musicien',
  },
  'SharedTypes.backgroundmusic': {
    en: 'Background music',
    fr: "Musique d'ambiance",
  },
  'SharedTypes.concert': { en: 'Concert', fr: 'Concert' },
  'SharedTypes.quebec': { en: 'Quebec', fr: 'Québec' },
  'SharedTypes.alberta': { en: 'Alberta', fr: 'Alberta' },
  'SharedTypes.britishcolumbia': {
    en: 'British Columbia',
    fr: 'Colombie-Britannique',
  },
  'SharedTypes.saskatchewan': { en: 'Saskatchewan', fr: 'Saskatchewan' },
  'SharedTypes.manitoba': { en: 'Manitoba', fr: 'Manitoba' },
  'SharedTypes.ontario': { en: 'Ontario', fr: 'Ontario' },
  'SharedTypes.newbrunswick': { en: 'New Brunswick', fr: 'Nouveau-Brunswick' },
  'SharedTypes.newfoundlandandlabrador': {
    en: 'Newfoundland and Labrador',
    fr: 'Terre-Neuve et Labrador',
  },
  'SharedTypes.novascotia': { en: 'Nova Scotia', fr: 'Nouvelle-Écosse' },
  'SharedTypes.pei': { en: 'PEI', fr: 'Î.-P.-É.' },
  'SharedTypes.yukon': { en: 'Yukon', fr: 'Yukon' },
  'SharedTypes.northwestterritories': {
    en: 'Northwest Territories',
    fr: 'Territoires du Nord-Ouest',
  },
  'SharedTypes.nunavut': { en: 'Nunavut', fr: 'Nunavut' },
  'SharedTypes.guitar': { en: 'Guitar', fr: 'Guitare' },
  'SharedTypes.bass': { en: 'Bass', fr: 'Basse' },
  'SharedTypes.violin': { en: 'Violin', fr: 'Violon' },
  'SharedTypes.cello': { en: 'Cello', fr: 'Violoncelle' },
  'SharedTypes.piano': { en: 'Piano', fr: 'Piano' },
  'SharedTypes.keys': { en: 'Keys', fr: 'Clavier' },
  'SharedTypes.percussion': { en: 'Percussion', fr: 'Percussion' },
  'SharedTypes.drums': { en: 'Drums', fr: 'Tambours' },
  'SharedTypes.harp': { en: 'Harp', fr: 'Harpe' },
  'SharedTypes.saxophone': { en: 'Saxophone', fr: 'Saxophone' },
  'SharedTypes.trumpet': { en: 'Trumpet', fr: 'Trompette' },
  'SharedTypes.viola': { en: 'Viola', fr: 'Alto' },
  'SharedTypes.retirementHome': {
    en: 'Retirement home',
    fr: 'Résidence pour aînés',
  },
  'SharedTypes.hotel': { en: 'Hotel', fr: 'Hôtel' },
  'SharedTypes.barRestaurant': {
    en: 'Bar / Restaurant',
    fr: 'Bar / Restaurant',
  },
  'SharedTypes.forMyself': { en: 'For myself', fr: 'Pour moi-même' },
};
