export default {
  'HowItWorksGetADemoSection.PictureTitle': {
    en: 'Get a demo',
    fr: 'Obtenir une démo',
  },
  'HowItWorksGetADemoSection.description': {
    en: 'Select which option describes you better and try a demo booking',
    fr: 'Sélectionnez l’option qui vous correspond le mieux et nous vous guiderons à travers notre plateforme',
  },
  'HowItWorksGetADemoSection.ButtonMusician': {
    en: 'I play or am involved with music',
    fr: 'Je joue de la musique/je suis impliqué dans la musique',
  },
  'HowItWorksGetADemoSection.ButtonClient': {
    en: "I'm interested in hiring a mucisian",
    fr: 'Je souhaite engager un.e musicien.ne',
  },
};
