import { observer } from 'mobx-react';
import * as React from 'react';
import { MusicianType } from '../../shared';
import MusicianCard from '../common/profile/MusicianCard';
import styled from 'styled-components';
import { MUSICIAN_RECOMMENDATIONS } from '../../constants/musicians';
import ATag from '../common/ATag';

const MusicianRecommendations = observer(() => {
  return (
    <StyledMusiciansContainer>
      {MUSICIAN_RECOMMENDATIONS.map((musician: MusicianType) => (
        <ATag
          noOpacity={true}
          target="_blank"
          key={musician.name}
          href={musician.profileUrl}
        >
          <MusicianCard
            style={{ marginBottom: '2rem' }}
            key={musician.name}
            musician={musician}
          />
        </ATag>
      ))}
    </StyledMusiciansContainer>
  );
});

const StyledMusiciansContainer = styled.div`
  width: 1112px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  @media (max-width: ${(props) => props.theme.breakPoints.mobile}px) {
    margin-bottom: 30px;
    width: 100%;
    place-content: center;
  }
`;

export default MusicianRecommendations;
