import { observer } from 'mobx-react';
import React from 'react';
import styled from 'styled-components';
import { UseUserStore, UserStore } from '../../../stores/user';
import images from '../../../constants/images';
import colors from '../../../constants/colors';

const NavBarLanguage = observer(() => {
  const userStore = UseUserStore();
  const [isLanguageShown, setIsLanguageShown] = React.useState(false);

  const toggleLanguageList = () => {
    setIsLanguageShown(!isLanguageShown);
  };

  const handleLanguageChange = (language: UserStore['browserLanguage']) => {
    userStore.setBrowerLanguage(language);
  };

  return (
    <Main
      onMouseEnter={() => setIsLanguageShown(true)}
      onMouseLeave={() => setIsLanguageShown(false)}
    >
      <LanguageBoxBtn onClick={toggleLanguageList}>
        <ImageLanguage
          src={images.globeImageBlack}
          isLanguageShown={isLanguageShown}
          onClick={toggleLanguageList}
        />
        <GeneralMediumBlack>
          {userStore?.browserLanguage === 'fr' ? 'FR' : 'EN'}
        </GeneralMediumBlack>
      </LanguageBoxBtn>
      {isLanguageShown && (
        <>
          <DropdownList>
            <DropdownItem onClick={() => handleLanguageChange('en')}>
              <GeneralMediumBlack>English</GeneralMediumBlack>
            </DropdownItem>
            <DropdownItem onClick={() => handleLanguageChange('fr')}>
              <GeneralMediumBlack>Français</GeneralMediumBlack>
            </DropdownItem>
          </DropdownList>
        </>
      )}
    </Main>
  );
});

const Main = styled.div`
  position: relative;
  margin: 0 32px;
`;

const LanguageBoxBtn = styled.button`
  width: 57px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-right: 80px;
  border: 0;
  background: none;
  padding: 0;
  padding-top: 20px;
  padding-bottom: 20px;
  &:hover {
    cursor: pointer;
    opacity: 0.8;
  }
`;

const ImageLanguage = styled.img<{ isLanguageShown: boolean }>`
  width: 20px;
  transform: ${({ isLanguageShown }) =>
    isLanguageShown ? 'rotate(180deg)' : 'rotate(0)'};
  transition: transform 0.3s ease;
`;

const DropdownList = styled.ul`
  position: absolute;
  top: 48px;
  right: 17px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 130px;
  background: ${colors.white};
  border-radius: 10px;
  padding: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
  z-index: 999;
`;

const DropdownItem = styled.li`
  list-style-type: none;
  margin: 0 0 5px 20px;
  cursor: pointer;
`;

const GeneralMediumBlack = styled.span`
  text-align: left;
  font-size: 18px;
  font-family: ${(props) => props.theme.fonts.GeneralSans};
  font-weight: 500;
  color: ${(props) => props.theme.colors.black};
  &:hover {
    cursor: pointer;
    opacity: 0.8;
  }
`;

export default NavBarLanguage;
