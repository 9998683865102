import * as React from 'react';
import styled from 'styled-components';
import { useUiStore } from '../../../stores/ui';
import { observer } from 'mobx-react';
import images from '../../../constants/images';
import { CompanyTypeEnums, EventProposalType } from '../../../shared';
import { Row } from '../Layout';
import EventData from './EventData';
import PriceData from './PriceData';
import CardPaymentData from './CardPaymentData';
import i18n from '../../../constants/i18n';
import { TextH7 } from '../Typography';
import colors from '../../../constants/colors';
import CheckPaymentData from './CheckPaymentData';
import { useEventStore } from '../../../stores/event';

const HireMusicianModal = observer(
  ({ proposal }: { proposal: EventProposalType }) => {
    const uiStore = useUiStore();
    const eventStore = useEventStore();
    const { currentEvent } = eventStore;
    const [currentTab, setCurrentTab] = React.useState<string>('card');
    const isEventFromARetirementHome =
      currentEvent?.companyType === CompanyTypeEnums.RetirementHome;

    return (
      <Main width={uiStore.innerWidth - 40}>
        <Row style={{ width: '100%' }}>
          <LeftColumn>
            <EventData proposal={proposal} />
          </LeftColumn>
          <CenterColumn>
            <PriceData proposal={proposal} currentTab={currentTab} />
          </CenterColumn>
          <RightColumn>
            <RightContainer>
              <TextH7 style={{ color: colors.violet, marginBottom: '1rem' }}>
                {i18n('CardPaymentData.paymentMethods', 'Payment methods')}
              </TextH7>
              <TabularContainer>
                {[
                  {
                    id: 'card',
                    text: i18n('HireMusicianModal.card', 'Card'),
                    icon: images.cardIcon,
                    disabled: false,
                  },
                  {
                    id: 'check',
                    text: i18n('HireMusicianModal.check', 'By check'),
                    icon: images.checkIcon,
                    disabled: !isEventFromARetirementHome,
                  },
                ].map((tab) => (
                  <StyledBox
                    key={tab.id}
                    selected={tab.id === currentTab}
                    onClick={() => !tab.disabled && setCurrentTab(tab.id)}
                    disabled={tab.disabled}
                  >
                    <Icon src={tab.icon} />
                    <TabularTitle>{tab.text}</TabularTitle>
                  </StyledBox>
                ))}
              </TabularContainer>
              {currentTab === 'card' && <CardPaymentData proposal={proposal} />}
              {currentTab === 'check' && (
                <CheckPaymentData proposal={proposal} />
              )}
            </RightContainer>
          </RightColumn>
        </Row>
        <Close src={images.closeIconBlack} onClick={uiStore.closePopup} />
      </Main>
    );
  },
);

const Main = styled.div<{ width: number }>`
  display: flex;
  height: 730px;
  width: ${(props) => props.width}px;
  border-radius: 10px;
  background: white;
  border: 1px solid #a59cee;
  @media (max-width: ${(props) => props.theme.breakPoints.mobile}px) {
    width: 100%;
  }
`;
const RightContainer = styled.div`
  border: 1px solid #5247a8;
  padding: 20px;
  border-radius: 20px;
`;

const TabularContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
  @media (max-width: ${(props) => props.theme.breakPoints.mobile}px) {
    width: 100%;
    padding: 0 1rem;
    justify-content: center;
  }
`;

const StyledBox = styled.div<{ selected: boolean; disabled: boolean }>`
  display: flex;
  flex-direction: column;
  cursor: ${(props) => (props.disabled ? 'normal' : 'pointer')};
  width: 46%;
  height: 60px;
  padding: 10px 5px 10px 5px;
  border-radius: 5px;
  border: 1px solid
    ${(props) =>
      props.selected && !props.disabled
        ? colors.buttonTextColor
        : colors.tableTextColor};
  opacity: ${(props) => (props.selected && !props.disabled ? 1 : 0.4)};
`;

const TabularTitle = styled.span`
  font-family: General Sans;
  font-size: 14px;
  font-weight: 500;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: left;
`;

const LeftColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: 33%;
  height: 100%;
  background: radial-gradient(
    100% 130.71% at 100% 9.2%,
    #faaa48 0%,
    #5247a8 64.37%
  );
  border-radius: 10px 0 0 10px;
  color: white;
  padding: 3rem;
`;

const CenterColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: 33%;
  height: 100%;
  color: black;
  padding: 4rem 3rem;
`;

const RightColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: 33%;
  height: 100%;
  color: black;
  padding: 3rem 2rem;
`;

const Icon = styled.img`
  height: 20px;
  width: 20px;
`;

const Close = styled.img`
  cursor: pointer;
  margin-left: auto;
  margin-top: 16px;
  padding-right: 16px;
  object-fit: contain;
  height: 12px;
`;

export default HireMusicianModal;
