import { observer } from 'mobx-react-lite';
import * as React from 'react';
import styled from 'styled-components';
import i18n from '../../constants/i18n';
import { UseUserStore } from '../../stores/user';
import CenteredClipLoader from '../common/CenteredClipLoader';
import PageLayoutFooter from '../common/PageLayoutFooter';
import { TextH6, TextH7 } from '../common/Typography';
import GiglinkedButton from '../common/GiglinkedButton';
import { useNavigate } from 'react-router-dom';
import colors from '../../constants/colors';
import Invoices from './Invoices';
import MusicianRecommendations from './MusicianRecommendations';
import EventsTable from './EventsTable';
import { useUiStore } from '../../stores/ui';
import EventsCard from './EventsCard';
import images from '../../constants/images';
import { useEventStore } from '../../stores/event';
import NotFoundScreen from '../404/NotFoundScreen';

interface TabData {
  id: string;
  text: string;
}
const DashboardLanding = observer(() => {
  const user = UseUserStore();
  const me = user.getMe();
  const navigate = useNavigate();
  const uiStore = useUiStore();
  const [showActions, setShowActions] = React.useState(true);
  const eventStore = useEventStore();
  const { currentEventTab, setCurrentEventTab } = eventStore;

  if (!me) return <CenteredClipLoader />;

  if (me.artist) return <NotFoundScreen />;

  const tabularData: TabData[] = [
    {
      id: 'events',
      text: i18n('DashboardLanding.yourEvents', 'Your events'),
    },
    {
      id: 'recommendations',
      text: i18n('DashboardLanding.recommendations', 'Recommendations'),
    },
    {
      id: 'invoices',
      text: i18n('DashboardLanding.invoices', 'Invoices'),
    },
  ];

  React.useEffect(() => {
    if (currentEventTab !== 'invoices') setCurrentEventTab('events');
  }, []);

  return (
    <Main>
      <TopBanner>
        <Title>
          {i18n('DashboardLanding.title', 'Welcome to your Dashboard')}
          {me!.firstName ? ', ' : '.'}
          {me!.firstName}.
        </Title>
      </TopBanner>

      {showActions && (
        <MidSection>
          <ColorContainer background={colors.primaryColorContainer}>
            <Row>
              <Column>
                <TextH7>
                  {i18n(
                    'DashboardLanding.eventTitle',
                    'Call on qualified musicians',
                  )}
                </TextH7>

                <TextH6 style={{ fontWeight: 400 }}>
                  {i18n(
                    'DashboardLanding.eventSubTitle',
                    "Post your event and you'll start getting proposals. Hire the best match.",
                  )}
                </TextH6>
                <GiglinkedButton
                  style={{ maxWidth: '250px', placeSelf: 'start' }}
                  label={i18n('DashboardLanding.postEvent', 'Post your event')}
                  onClick={() => navigate(`/events/new`)}
                />
              </Column>
              <img
                style={{
                  height: '5rem',
                  paddingLeft: '2rem',
                  alignSelf: 'center',
                }}
                src={images.postEvent}
              />
            </Row>
          </ColorContainer>

          <ColorContainer background={colors.secondaryColorContainer}>
            <Row>
              <Column>
                <TextH7>
                  {i18n(
                    'DashboardLanding.browseTitle',
                    'Search by keyword and location',
                  )}
                </TextH7>

                <TextH6 style={{ fontWeight: 400 }}>
                  {i18n(
                    'DashboardLanding.browseSubTitle',
                    'Find the perfect live music for your event and send personalized requests.',
                  )}
                </TextH6>
                <GiglinkedButton
                  style={{
                    maxWidth: '250px',
                    placeSelf: 'start',
                    width: '70%',
                  }}
                  label={i18n(
                    'DashboardLanding.browseMusicians',
                    'Browse musicians',
                  )}
                  onClick={() => navigate(`/performers`)}
                />
              </Column>
              <img
                style={{
                  height: '5rem',
                  paddingLeft: '2rem',
                  alignSelf: 'center',
                }}
                src={images.browseMusicians}
              />
            </Row>
          </ColorContainer>
          {!uiStore.isMobile && (
            <img
              style={{
                position: 'relative',
                width: '1rem',
                height: '1rem',
                margin: '-10px',
                cursor: 'pointer',
              }}
              onClick={() => setShowActions(false)}
              src={images.closeIconBlack}
            />
          )}
        </MidSection>
      )}
      <TabularContainer>
        {tabularData.map((tab: TabData) => (
          <StyledBox
            key={tab.id}
            selected={currentEventTab === tab.id}
            onClick={() => setCurrentEventTab(tab.id)}
          >
            <TabularH4Title selected={currentEventTab === tab.id}>
              {tab.text}
            </TabularH4Title>
          </StyledBox>
        ))}
      </TabularContainer>
      <SectionTextContainer>
        {currentEventTab === 'events' &&
          (uiStore.isMobile ? <EventsCard /> : <EventsTable />)}
        {currentEventTab === 'recommendations' && <MusicianRecommendations />}
        {currentEventTab === 'invoices' && <Invoices />}
      </SectionTextContainer>

      <PageLayoutFooter />
    </Main>
  );
});

const Main = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-sizing: border-box;
  flex: 1;
`;

const TopBanner = styled.div`
  display: flex;
  align-items: center;
  height: 178px;
  background: radial-gradient(
    77.01% 120% at 100% 50%,
    ${(props) => props.theme.colors.tabOrange} 0%,
    ${(props) => props.theme.colors.buttonTextColor} 82.6%
  );
  @media (max-width: ${(props) => props.theme.breakPoints.mobile}px) {
    padding: 0 1rem;
  }
`;

const MidSection = styled.div`
  display: flex;
  align-self: center;
  margin-top: 2rem;
  @media (max-width: ${(props) => props.theme.breakPoints.mobile}px) {
    flex-direction: column;
    margin: 0 2rem;
  }
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  height: 100%;
  text-align: start;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 70%;
`;

const ColorContainer = styled.div<{ background: string }>`
  max-width: 482px;
  height: 206px;
  width: 100%;
  padding: 1rem;
  background: ${(props) => props.background};
  text-align: center;
  border-radius: 24px;
  padding: 2rem;
  margin: 0 0.25rem;
  @media (max-width: ${(props) => props.theme.breakPoints.mobile}px) {
    margin: 0.25rem 0;
    margin-top: 1rem;
    height: auto;
  }
`;

const Title = styled.div`
  font-size: 32px;
  margin-left: 80px;
  font-weight: 500;
  color: ${(props) => props.theme.colors.white};
  font-family: ${(props) => props.theme.fonts.GeneralSans};
  @media (max-width: ${(props) => props.theme.breakPoints.mobile}px) {
    font-size: 24px;
    margin: 0;
  }
`;

const TabularContainer = styled.div`
  display: flex;
  align-self: center;
  margin-bottom: 1rem;
  @media (max-width: ${(props) => props.theme.breakPoints.mobile}px) {
    width: 100%;
    padding: 0 1rem;
  }
`;

const StyledBox = styled.div<{ selected: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  box-sizing: border-box;
  width: 250px;
  height: 68px;
  border-bottom: ${(props) =>
    props.selected ? `2px solid ${colors.tabOrange}` : `2px solid transparent`};
  justify-content: center;
  &:hover {
    transform: translate(0, -5px);
    -webkit-transform: translate(0, -5px);
    -o-transform: translate(0, -5px);
    -moz-transform: translate(0, -5px);
    transition: transform 0.2s;
  }
  @media (max-width: ${(props) => props.theme.breakPoints.mobile}px) {
    width: 130px;
  }
`;

const TabularH4Title = styled.h4<{ selected: boolean }>`
  cursor: pointer;
  margin-bottom: 0;
  font-size: 18px;
  font-family: ${(props) => props.theme.fonts.GeneralSans};
  font-weight: 500;
  align-item: center;
  color: ${(props) => (props.selected ? colors.violet : colors.tableTextColor)};
  @media (max-width: ${(props) => props.theme.breakPoints.mobile}px) {
    font-size: 14px;
    margin-left: 7px;
  }
`;

const SectionTextContainer = styled.div`
  display: flex;
  align-self: center;
  justify-content: left;
  width: 1000px;
  @media (max-width: ${(props) => props.theme.breakPoints.mobile}px) {
    width: 100%;
    height: 100%;
  }
`;
export default DashboardLanding;
