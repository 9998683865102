import { formatDistanceToNow } from 'date-fns';
import { Conversation } from '../../shared';
import stores from '../../stores';

export const getOtherUserFromConversation = ({
  conversation,
}: {
  conversation: Conversation;
}) => {
  const userStore = stores.user;
  const me = userStore.getMe();

  return conversation.users.filter((user) => user.id !== me?.id)?.[0] ?? me;
};

export const formatDateForConversationCell = (date: string) => {
  const lastMsgDate = new Date(date);
  return formatDistanceToNow(lastMsgDate) === 'less than a minute'
    ? 'now'
    : formatDistanceToNow(lastMsgDate).replace('about', '');
};
