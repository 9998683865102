import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { Box } from 'react-native-kondo';
import styled from 'styled-components';
import colors from '../../../constants/colors';
import i18n from '../../../constants/i18n';
import { Act } from '../../../shared';
import { useUiStore } from '../../../stores/ui';
import ATag from '../../common/ATag';
import GiCollapsableContainer from '../../common/GiCollapsableContainer';
import { GeneralRegularNormal, TextH3 } from '../../common/Typography';
import { replaceProfileImage } from '../../../utils/media';

const ActCell = observer(
  ({
    act,
    isPending,
    userIsOwner,
    onAcceptInvite,
    onLeaveAct,
  }: {
    act: Act;
    isPending?: boolean;
    userIsOwner?: boolean;
    onAcceptInvite: ({ act }: { act: Act }) => void;
    onLeaveAct: ({ act }: { act: Act }) => void;
  }) => {
    const uiStore = useUiStore();

    const formatContent = () => {
      let content = (
        <GeneralRegularNormal
          onClick={() => onLeaveAct({ act })}
          style={{
            textDecoration: 'underline',
            cursor: 'pointer',
            color: colors.red,
          }}
        >
          {i18n('EditArtistActCell.leave', 'Leave')}
        </GeneralRegularNormal>
      );

      if (userIsOwner) {
        content = (
          <ATag href={`/me/acts/${act.id}/overview`}>
            <GeneralRegularNormal
              style={{ textDecoration: 'underline', cursor: 'pointer' }}
            >
              {i18n('EditArtistActCell.manage', 'Manage')}
            </GeneralRegularNormal>
          </ATag>
        );
      }

      if (isPending) {
        content = (
          <Box flexDirection="row">
            <GeneralRegularNormal
              onClick={() => onAcceptInvite({ act })}
              style={{ textDecoration: 'underline', cursor: 'pointer' }}
            >
              {i18n('EditArtistActCell.accept', 'Accept Invitation')}
            </GeneralRegularNormal>
            <GeneralRegularNormal style={{ marginRight: 5, marginLeft: 5 }}>
              {' '}
              |{' '}
            </GeneralRegularNormal>
            <GeneralRegularNormal
              onClick={() => onLeaveAct({ act })}
              style={{
                textDecoration: 'underline',
                cursor: 'pointer',
                color: colors.red,
              }}
            >
              {i18n('EditArtistActCell.decline', 'Decline')}
            </GeneralRegularNormal>
          </Box>
        );
      }

      return content;
    };

    const renderTop = () => {
      const content = formatContent();
      return (
        <MainTop>
          <Box flexDirection="row" alignItems="center">
            <ActIcon src={replaceProfileImage(act.profilePicture)} />
            <Box>
              <TextH3
                style={{
                  color: colors.black,
                  wordBreak: 'break-all',
                  maxWidth: uiStore.isMobile ? 180 : undefined,
                }}
              >
                {act.name}
              </TextH3>
              <ATag href={`/acts/${act.id}`}>
                <GeneralRegularNormal
                  style={{ textDecoration: 'underline', fontSize: 14 }}
                >
                  {i18n('EditArtistActCell.viewProfile', 'View Page')}
                </GeneralRegularNormal>
              </ATag>
            </Box>
          </Box>

          {content}
        </MainTop>
      );
    };

    // We dont have to use GiCollapsableContainer,
    // I was just too lazy to refactor. -Vince

    return (
      <GiCollapsableContainer
        mainStyle={{
          borderColor: '#E0E0E0',
          borderStyle: 'solid',
          borderBottomWidth: 1,
          borderTopWidth: 0,
          borderRightWidth: 0,
          borderLeftWidth: 0,
          paddingBottom: 30,
          marginBottom: 24,
          opacity: isPending ? 0.65 : 1,
        }}
        disableCollaps
        renderTop={renderTop}
        renderContent={() => null}
      />
    );
  },
);

const ActIcon = styled.img`
  height: 56px;
  width: 56px;
  border-radius: 50%;
  margin-right: 24px;
  border: 2px solid #f2f3f4;
  @media (max-width: ${(props) => props.theme.breakPoints.mobile}px) {
  }
`;

const MainTop = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex: 1;
`;

export default ActCell;
