export default {
  'MusicianEventCard.userAlreadyHiredForEvent': {
    en: 'You have already been booked for this event.',
    fr: 'Vous êtes déjà inscrit à cet événement.',
  },
  'MusicianEventCard.withdraw': { en: 'Withdraw', fr: 'Se retirer' },
  'MusicianEventCard.withdrawApplicationSuccess': {
    en: 'You have successfully withdrew your application.',
    fr: 'Vous avez retiré votre demande avec succès.',
  },
  'MusicianEventCard.somethingWrongWithdrawingApplication': {
    en: 'Something went wrong withdrawing your application.',
    fr: "Un problème s'est produit lors du retrait de votre demande.",
  },
  'MusicianEventCard.myQuote': { en: 'My Quote: ', fr: 'Ma citation :' },
  'MusicianEventCard.appliedAs': { en: 'Applied as: ', fr: 'Appliqué comme :' },
  'MusicianEventCard.lessDetails': {
    en: 'Less details',
    fr: 'Moins de détails',
  },
  'MusicianEventCard.moreDetails': {
    en: 'More details',
    fr: 'Plus de détails',
  },
  'MusicianEventCard.created': { en: 'Created:', fr: 'Créé:' },
  'MusicianEventCard.proposals': { en: 'Proposals:', fr: 'Propositions :' },
  'MusicianEventCard.approxBudget': {
    en: 'Approx budget',
    fr: 'Budget approximatif',
  },
  'MusicianEventCard.arrival': { en: 'Arrival ', fr: 'Arrivée ' },
  'MusicianEventCard.equipment': { en: 'Equipment ', fr: 'Equipement ' },
  'MusicianEventCard.outdoor': { en: 'Outdoor ', fr: 'Extérieur ' },
  'MusicianEventCard.covered': { en: 'covered ', fr: 'couvert ' },
  'MusicianEventCard.uncovered': { en: 'uncovered ', fr: 'à découvert' },
  'MusicianEventCard.indoor': { en: 'Indoor ', fr: 'Intérieur ' },
};
