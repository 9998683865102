import * as React from 'react';
import styled from 'styled-components';
import { useUiStore } from '../../stores/ui';
import colors from '../../constants/colors';
import { GeneralMediumLarge, TextH3 } from '../common/Typography';
import images from '../../constants/images';
import { UseUserStore } from '../../stores/user';

const GiAuthContainer = ({
  title,
  secondaryText,
  tertiaryText,
  editableContent,
}: {
  title: () => React.ReactNode;
  secondaryText?: () => React.ReactNode;
  tertiaryText?: React.ReactElement;
  editableContent: React.ReactElement;
}) => {
  const { innerHeight } = useUiStore();
  const userStore = UseUserStore();
  const { browserLanguage, setBrowerLanguage } = userStore;

  return (
    <>
      <Main innerHeight={innerHeight}>
        <TitleBox>
          <LogoBox>
            <GigLinkedLogo src={images.giglinkedLogoWhite} />
            <GeneralMediumLarge
              style={{
                cursor: 'pointer',
              }}
              onClick={() =>
                setBrowerLanguage(browserLanguage === 'fr' ? 'en' : 'fr')
              }
            >
              {browserLanguage === 'fr' ? 'EN' : 'FR'}
            </GeneralMediumLarge>
          </LogoBox>
          <Title>
            <StyledTextH2>{title}</StyledTextH2>

            <TextH3 style={{ fontWeight: 400 }}>{secondaryText}</TextH3>
          </Title>
          <Bottom>{tertiaryText}</Bottom>
        </TitleBox>

        <ContentBox innerHeight={innerHeight}>{editableContent}</ContentBox>
      </Main>
      <BoxShadow innerHeight={innerHeight} />
    </>
  );
};

const Main = styled.div<{ innerHeight: number }>`
  height: ${(props) => props.innerHeight}px;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  background-image: url(${images.backgroundBlue});
  padding: 40px;
  @media (max-width: ${(props) => props.theme.breakPoints.mobile}px) {
    flex-direction: column;
    padding: 20px;
  }
`;

const TitleBox = styled.div`
  width: 50%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: space-between;
  @media (max-width: ${(props) => props.theme.breakPoints.mobile}px) {
    width: 100%;
    height: 20%;
  }
`;

const LogoBox = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const Title = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 2rem;
  max-width: 500px;
  @media (max-width: ${(props) => props.theme.breakPoints.mobile}px) {
    align-items: center;
    padding: 0;
  }
`;

const ContentBox = styled.div<{ innerHeight: number }>`
  height: ${(props) => props.innerHeight - 60}px;
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  justify-content: center;
  border-radius: 30px;
  z-index: 3;
  margin: 30px;
  padding: 30px;
  background: ${colors.white};
  @media (max-width: ${(props) => props.theme.breakPoints.tablet}px) {
    width: 91%;
  }
  @media (max-width: ${(props) => props.theme.breakPoints.mobile}px) {
    height: 80%;
    width: 100%;
  }
`;

const BoxShadow = styled.div<{ innerHeight: number }>`
  height: ${(props) => props.innerHeight - 60}px;
  width: 47%;
  position: absolute;
  z-index: 2;
  right: 80px;
  top: 47px;
  background: ${colors.shadowGradientColor};
  border-radius: 30px;
  @media (max-width: ${(props) => props.theme.breakPoints.tablet}px) {
    width: 55%;
  }
  @media (max-width: ${(props) => props.theme.breakPoints.mobile}px) {
    height: 70%;
    width: 90%;
    left: 12px;
    top: 25%;
  }
`;

const GigLinkedLogo = styled.img`
  width: 200px;
  margin-right: 20px;
  @media (max-width: ${(props) => props.theme.breakPoints.mobile}px) {
    width: 100px;
  }
`;

const Bottom = styled.div`
  display: flex;
  flex-direction: row;
  color: ${colors.white};
  @media (max-width: ${(props) => props.theme.breakPoints.mobile}px) {
    align-self: center;
    font-size: 12px;
  }
`;

const StyledTextH2 = styled.h2`
  font-size: 40px;
  font-family: ${(props) => props.theme.fonts.GeneralSans};
  font-weight: 600;
  text-align: left;
  color: ${(props) => props.theme.colors.white};
  @media (max-width: ${(props) => props.theme.breakPoints.mobile}px) {
    font-size: 24px;
    text-align: center;
    margin-top: 8px;
  }
`;

export default GiAuthContainer;
