export default {
  'LeftNavigationBar.home': { en: 'Home', fr: 'Accueil' },
  'LeftNavigationBar.requests': { en: 'Requests', fr: 'Demandes' },
  'LeftNavigationBar.calendar': { en: 'Calendar', fr: 'Calendrier' },
  'LeftNavigationBar.settings': { en: 'Settings', fr: 'Paramètres' },
  'LeftNavigationBar.support': { en: 'Support', fr: 'Soutien' },
  'LeftNavigationBar.logout': { en: 'Log Out', fr: 'Se déconnecter' },
  'LeftNavigationBar.viewProfile': { en: 'View Profile', fr: 'Voir le profil' },
  'LeftNavigationBar.search': { en: 'Search', fr: 'Recherche' },
  'LeftNavigationBar.messages': { en: 'Messages', fr: 'messages' },
};
