export default {
  'CheckPaymentData.confirmGenerateInvoice': {
    en: 'Confirm & Generate Invoice',
    fr: 'Confirmer et générer une facture',
  },
  'CheckPaymentData.successTitle': { en: 'Thank you!', fr: 'Merci' },
  'CheckPaymentData.secondaryText': {
    en: 'Your invoice has been automatically generated. You can see it in your Dashboard, tab “Invoices”.',
    fr: "Votre facture a été générée automatiquement. Vous pouvez la voir dans votre tableau de bord, onglet 'Factures'.",
  },
  'CheckPaymentData.goToInvoices': {
    en: 'Take me to my Invoices',
    fr: 'Accéder à mes factures',
  },
  'CheckPaymentData.dismiss': { en: 'Dismiss', fr: 'Rejeter' },
};
