import * as React from 'react';

import i18n from '../../../constants/i18n';
import { Artist } from '../../../shared';
import {
  GeneralRegularSmall,
  GeneralSemiBoldNormal,
} from '../../common/Typography';

import { Box } from 'react-native-kondo';
import styled from 'styled-components';
import colors from '../../../constants/colors';
import { replaceProfileImage } from '../../../utils/media';

const EditActMemberCell = ({
  artist,
  isOwner,
  isPending,
  onClickRemove,
}: {
  artist: Artist;
  isOwner?: boolean;
  isPending?: boolean;
  onClickRemove: ({ artist }: { artist: Artist }) => void;
}) => {
  let text = isOwner
    ? i18n('ActMembersListing.owner', 'Owner')
    : i18n('ActMembersListing.owner', 'Member');

  if (isPending) {
    text = i18n('ActMembersListing.inviteSent', 'Invitation sent');
  }

  let rightAction = <div />;

  if (!isOwner) {
    const rightText = isPending
      ? i18n('ActMembersListing.cancelInvite', 'Cancel invitation')
      : i18n('ActMembersListing.remove', 'Remove');

    rightAction = (
      <>
        <GeneralRegularSmall style={{ marginRight: 5, marginLeft: 5 }}>
          {' '}
          |{' '}
        </GeneralRegularSmall>
        <GeneralRegularSmall
          style={{ textDecoration: 'underline', cursor: 'pointer' }}
          onClick={() => onClickRemove({ artist })}
        >
          {rightText}
        </GeneralRegularSmall>
      </>
    );
  }

  return (
    <MemberCellContainer style={{ opacity: isPending ? 0.65 : 1 }}>
      <MemberPicture src={replaceProfileImage(artist.profilePicture)} />
      <Box>
        <GeneralSemiBoldNormal style={{ color: colors.black }}>
          {artist.name}
        </GeneralSemiBoldNormal>

        <Box flexDirection="row">
          <GeneralRegularSmall>{text}</GeneralRegularSmall>
          {rightAction}
        </Box>
      </Box>
    </MemberCellContainer>
  );
};

const MemberCellContainer = styled.div`
  display: flex;
  margin-bottom: 16px;
  align-items: center;
`;

const MemberPicture = styled.img`
  width: 58px;
  height: 58px;
  border-radius: 50%;
  border: 1px solid #e0e0e0;
  margin-right: 16px;
  object-fit: cover;
`;

export default EditActMemberCell;
