import { observer } from 'mobx-react';
import * as React from 'react';

import i18n from '../../../constants/i18n';
import { Act, Artist, YoutubeVideo } from '../../../shared';
import { TextH6 } from '../../common/Typography';
import AddItemTextInput from '../../common/AddItemTextInput';
import EditActsProfileLayout from '../EditActsProfileLayout';

import GiDraggableList from '../../common/GiDraggableList';
import sexpressClient from '../../../utils/sexpress';

import { useActStore } from '../../../stores/act';
import EditYoutubeVideoCell from '../../common/EditYoutubeCell';
import toast from 'react-hot-toast';

const EditActVideos = observer(() => {
  const actStore = useActStore();
  const act = actStore.getCurrentEditAct();
  const [error, setError] = React.useState<string>();
  const [loading, setLoading] = React.useState(false);

  const onSave = async (payload: Partial<Artist>) => {
    try {
      setError('');
      setLoading(true);
      await actStore.editMeAct({ ...payload, actId: act!.id });
    } catch (e) {
      setError('err');
    } finally {
      setLoading(false);
    }
  };

  const handleReorder = async (videos: Act['videos']) => {
    try {
      const updatedAct = await sexpressClient.editMeActReorderVideos({
        videos,
        actId: act!.id,
      });
      actStore.setCurrentEditAct(updatedAct);
    } catch (e) {
      const err = e as Error;
      toast.error(err.message);
    }
  };

  if (!act) {
    return <EditActsProfileLayout />;
  }

  return (
    <EditActsProfileLayout>
      <TextH6 style={{ marginBottom: 24 }}>
        {i18n('EditActVideos.addYoutube', 'Add a YouTube video')}
      </TextH6>
      <AddItemTextInput
        error={error}
        label={i18n('EditActVideos.addVideo', 'Add Video')}
        style={{ marginBottom: 26 }}
        setError={setError}
        loading={loading}
        onSave={(url) =>
          onSave({
            videos: [
              { url, embedUrl: '', thumbnail: '', title: '', videoId: '' },
            ],
          })
        }
      />

      <GiDraggableList<YoutubeVideo>
        data={act!.videos}
        onReorder={handleReorder}
        renderItem={(video) => (
          <EditYoutubeVideoCell
            video={video}
            onClickRemove={() =>
              onSave({
                videos: [
                  {
                    url: video.url,
                    embedUrl: '',
                    thumbnail: '',
                    title: '',
                    videoId: '',
                  },
                ],
              })
            }
          />
        )}
      />
    </EditActsProfileLayout>
  );
});

export default EditActVideos;
