export default {
  'EventDetailsStepTwo.isOutdoor': {
    en: 'Is it outdoor?',
    fr: 'Scène extérieure?',
  },
  'EventDetailsStepTwo.venueType': { en: 'Venue type', fr: 'Type de lieu' },
  'EventDetailsStepTwo.venueName': {
    en: 'Venue name / Company (disclosed at confirmation)',
    fr: 'Nom du lieu / de la société (communiqué lors de la confirmation)',
  },
  'EventDetailsStepTwo.venueAddress': {
    en: 'Venue address',
    fr: 'Adresse du lieu',
  },
  'EventDetailsStepTwo.city': { en: 'City', fr: 'Ville' },
  'EventDetailsStepTwo.province': { en: 'Province', fr: 'Province' },
  'EventDetailsStepTwo.postalCode': { en: 'Postal code' },
  'EventDetailsStepTwo.addressWarning': {
    en: 'The full address will not be shown to the musicians until a booking is confirmed.',
    fr: "L'adresse complète ne sera communiquée aux musiciens qu'une fois la réservation confirmée.",
  },
  'EventDetailsStepTwo.savedAddress': {
    en: 'Saved address',
    fr: 'Adresse enregistrée',
  },
  'EventDetailsStepTwo.noSavedAddress': {
    en: 'You have no saved addresses.',
    fr: "Vous n'avez pas d'adresses enregistrées.",
  },
  'EventDetailsStepTwo.newAddress': {
    en: 'New address',
    fr: 'Nouvelle adresse',
  },
  'EventDetailsStepTwo.contactPhone': {
    en: 'Contact phone',
    fr: 'Téléphone de contact',
  },
};
