import { useNavigate } from 'react-router-dom';
import i18n from '../constants/i18n';
import { UseUserStore } from '../stores/user';
import urls from './urls';

const useGetDropDownsOptions = () => {
  const navigate = useNavigate();
  const userStore = UseUserStore();

  const me = userStore.getMe();

  const getRouterDropDownOptions = () => {
    const isNotLoggedIn = !me;
    const isUser = me && !me.artist;

    const toRequests = {
      label: i18n('useGetDropDownsOptions.requests', 'Requests'),
      onClick: () => navigate('/requests'),
    };
    const toConversations = {
      label: i18n('useGetDropDownsOptions.messages', 'Messages'),
      onClick: () => navigate('/conversations'),
    };
    const toBilling = {
      label: i18n('useGetDropDownsOptions.transactions', 'Transactions'),
      onClick: () => navigate('/transactions'),
    };
    const toSettings = {
      label: i18n('useGetDropDownsOptions.settings', 'Settings'),
      onClick: () => navigate('/settings'),
    };
    const toSupport = {
      label: i18n('useGetDropDownsOptions.support', 'Support'),
      onClick: () => navigate('/support'),
    };
    const logOutOnClick = {
      label: i18n('useGetDropDownsOptions.logOut', 'Log Out'),
      onClick: userStore.logOut,
    };

    if (isNotLoggedIn) {
      return [toSupport];
    } else if (isUser) {
      return [
        toRequests,
        toConversations,
        toSettings,
        toSupport,
        logOutOnClick,
      ];
    } else {
      // isPerformer
      return [
        toRequests,
        toConversations,
        toBilling,
        toSettings,
        toSupport,
        logOutOnClick,
      ];
    }
  };

  const aTagDropDownOptions = [
    {
      label: i18n('useGetDropDownsOptions.forMusicians', 'For musicians'),
      href: urls.musiciansUrl,
    },
    {
      label: i18n('useGetDropDownsOptions.forYourEvent', 'For your event'),
      href: urls.eventsUrl,
    },
  ];

  return {
    routerDropDownOptions: getRouterDropDownOptions(),
    aTagDropDownOptions,
  };
};

export default useGetDropDownsOptions;
