import { observer } from 'mobx-react';
import * as React from 'react';
import styled from 'styled-components';
import images from '../../constants/images';

const HowItWorksOrgSection = observer(() => {
  return (
    <Main>
      <StyledContainer>
        <Image src={images.canadaLogo} />
        <Image src={images.quebecLogo} />
        <Image src={images.tmuLogo} />
        <Image src={images.communautiqueLogo} />
        <Image src={images.chartwellLogo} />
        <Image src={images.collisionLogo} />
        <Image src={images.zuLogo} />
      </StyledContainer>
    </Main>
  );
});

const Main = styled.div`
  min-height: 150px;
  @media (max-width: ${(props) => props.theme.breakPoints.mobile}px) {
    flex-direction: column;
    flex-wrap: no-wrap;
    height: 250px;
  }
`;

const StyledContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
  border-bottom: 1px solid #00000033;
  padding: 45px;
  @media (max-width: ${(props) => props.theme.breakPoints.mobile}px) {
    padding: 25px;
    height: 100%;
  }
`;

const Image = styled.img`
  object-fit: cover;
  max-width: 150px;
  max-height: 200px;
  padding: 5px 15px;
  @media (max-width: ${(props) => props.theme.breakPoints.mobile}px) {
    width: 15vh;
    padding: 5px;
  }
`;

export default HowItWorksOrgSection;
