import { observer } from 'mobx-react';
import * as React from 'react';
import styled from 'styled-components';

import images from '../../constants/images';
import { useUiStore } from '../../stores/ui';

const EditGalleryCell = observer(
  ({
    src,
    isSelected,
    onSelect,
  }: {
    src: string;
    onSelect: (url: string) => void;
    isSelected: boolean;
  }) => {
    const uiStore = useUiStore();
    const ratio = 0.5608;
    const imageWidth = 800 - 20;
    const imageHeight = imageWidth * ratio;

    return (
      <GelleryCellContainer
        style={{
          width: uiStore.isMobile ? '100%' : imageWidth,
          height: uiStore.isMobile ? 144 : imageHeight,
        }}
      >
        <GelleryImage src={src} />
        <DragContainer>
          <DragIcon src={images.hamburgerButton} />
        </DragContainer>
        <SquareBox onClick={() => onSelect(src)}>
          {isSelected ? <CheckMark src={images.checkMark} /> : null}
        </SquareBox>
      </GelleryCellContainer>
    );
  },
);

const CheckMark = styled.img`
  width: 15px;
  height: 15px;
  filter: invert(39%) sepia(100%) saturate(4242%) hue-rotate(223deg)
    brightness(98%) contrast(90%);
  @media (max-width: ${(props) => props.theme.breakPoints.mobile}px) {
  }
`;

const SquareBox = styled.div`
  width: 21px;
  height: 21px;
  border: 1px solid #f2f3f4;
  background-color: white;
  display: flex;
  margin-top: 10px;
  margin-left: 10px;
  justify-content: center;
  align-items: center;
  position: absolute;
  cursor: pointer;
  @media (max-width: ${(props) => props.theme.breakPoints.mobile}px) {
  }
`;

const DragContainer = styled.div`
  width: 35px;
  background-color: #f2f3f4;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: ${(props) => props.theme.breakPoints.mobile}px) {
  }
`;

const GelleryImage = styled.img`
  object-fit: cover;
  width: 100%;
  height: 100%;
  overflow: hidden;
  filter: brightness(0.75);
  @media (max-width: ${(props) => props.theme.breakPoints.mobile}px) {
  }
`;

const GelleryCellContainer = styled.div`
  display: flex;
  position: relative;
  margin-bottom: 24px;
  @media (max-width: ${(props) => props.theme.breakPoints.mobile}px) {
  }
`;

const DragIcon = styled.img`
  height: 18px;
  width: 18px;
  object-fit: contain;
  cursor: move; /* fallback if grab cursor is unsupported */
  cursor: grab;
  filter: invert(39%) sepia(100%) saturate(4242%) hue-rotate(223deg)
    brightness(98%) contrast(90%);
`;

export default EditGalleryCell;
