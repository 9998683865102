import React, { useEffect, useState } from 'react';
import { View } from 'react-native';
import { observer } from 'mobx-react-lite';
import { ThemeProvider as StyledComponentsThemeProvider } from 'styled-components';
import { ThemeProvider as KondoThemeProvider } from 'react-native-kondo';
import { Toaster } from 'react-hot-toast';
import {
  Outlet,
  useLocation,
  useNavigate,
  useSearchParams,
} from 'react-router-dom';
import colors from '../../constants/colors';
import theme from '../../constants/theme';
import { useUiStore } from '../../stores/ui';
import { useNavigationStore } from '../../stores/navigation';
import { UseUserStore } from '../../stores/user';
import CenteredClipLoader from '../common/CenteredClipLoader';
import DynamicDrawer from './DynamicDrawer';
import Popup from '../common/Popup';
import GalleryViewer from '../common/GalleryViewer';
import NavBar from './navBar/NavBar';
import RegistrationController from '../auth/signup/registrationSteps/RegistrationController';
import ForgotPassword from '../auth/signin/ForgotPassword';
import ResetPassword from '../auth/resetPassword/ResetPassword';
import SignInController from '../auth/signin/SigninController';

const Data = observer(() => {
  const uiStore = useUiStore();
  const navigationStore = useNavigationStore();

  const userStore = UseUserStore();
  const { navBarHeight } = useUiStore();
  const location = useLocation();
  const navigate = useNavigate();

  const [isDrawerShown, setIsDrawerShown] = useState(false);
  const [loading, setLoading] = useState(
    typeof window.localStorage.getItem('rft') === 'string',
  );
  const [navBarOffset, setNavBarOffset] = useState(navBarHeight / 3);
  const [searchParams] = useSearchParams();

  const performerLogin = searchParams.get('performerLogin');
  const userLogin = searchParams.get('userLogin');

  useEffect(() => {
    navigationStore.setNavigate(navigate);
  }, [navigate]);

  useEffect(() => {
    const maybeGetMe = async () => {
      if (window.localStorage.getItem('rft')) {
        try {
          setLoading(true);
          await userStore.refreshAuth();
          await userStore.postAuthActions();
        } catch (error) {
          console.log(error);
        }
      }
      setLoading(false);
    };
    maybeGetMe();
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  useEffect(() => {
    uiStore.updateWindowSizes({
      innerHeight: window.innerHeight,
      innerWidth: window.innerWidth,
    });
    window.addEventListener('resize', () => {
      uiStore.updateWindowSizes({
        innerHeight: window.innerHeight,
        innerWidth: window.innerWidth,
      });
    });
  }, []);

  useEffect(() => {
    document.body.style.overflowY = isDrawerShown ? 'hidden' : 'auto';

    return () => {
      document.body.style.overflowY = 'auto';
    };
  }, [isDrawerShown]);

  useEffect(() => {
    window.addEventListener('scroll', () => {
      if (window.pageYOffset !== 0) {
        setNavBarOffset(0);
      } else {
        setNavBarOffset(navBarHeight / 3);
      }
    });
  }, []);

  const getRenderedContent = () => {
    switch (true) {
      case location.pathname === '/signin':
        return (
          <SignInController
            onPerformerSigninRedirectPath={
              performerLogin ? location.pathname : undefined
            }
            onUserSigninRedirectPath={userLogin ? location.pathname : undefined}
          />
        );
      case location.pathname === '/signup':
        return <RegistrationController />;
      case location.pathname === '/forgot-password':
        return <ForgotPassword />;
      case location.pathname.startsWith('/resetPassword'):
        return <ResetPassword />;
      default:
        return (
          <View
            style={{
              minHeight: uiStore.innerHeight,
              width: uiStore.innerWidth,
              backgroundColor: colors.background,
              // @ts-ignore
              overflowX: 'hidden',
            }}
          >
            {loading ? (
              <CenteredClipLoader
                backgroundColor={colors.darkPuple}
                color={colors.whiteText}
              />
            ) : (
              <>
                <View
                  style={{
                    width: '100%',
                    flex: 1,
                    // @ts-ignore
                    overflowX: 'hidden',
                    flexDirection: 'column',
                  }}
                >
                  <NavBar
                    navBarOffset={navBarOffset}
                    setIsDrawerShown={setIsDrawerShown}
                  />

                  <DynamicDrawer
                    isDrawerShown={isDrawerShown}
                    setIsDrawerShown={setIsDrawerShown}
                  />

                  <Outlet />
                </View>

                <GalleryViewer />
              </>
            )}
          </View>
        );
    }
  };

  return (
    <KondoThemeProvider theme={theme}>
      <StyledComponentsThemeProvider theme={theme}>
        {getRenderedContent()}
        <Toaster />
        <Popup />
      </StyledComponentsThemeProvider>
    </KondoThemeProvider>
  );
});

export default Data;
