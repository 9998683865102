import * as React from 'react';
import styled from 'styled-components';
import { ClipLoader } from 'react-spinners';
import colors from '../../constants/colors';

const CenteredClipLoader = ({
  size = 48,
  backgroundColor,
  color,
}: {
  size?: number;
  backgroundColor?: string;
  color?: string;
}) => {
  return (
    <Main style={{ backgroundColor: backgroundColor ?? colors.whiteText }}>
      <ClipLoader color={color ?? colors.purple} size={size} />
    </Main>
  );
};

const Main = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
`;

export default CenteredClipLoader;
