import * as React from 'react';
import { IoMdWarning } from 'react-icons/io';
import styled from 'styled-components';
import colors from '../../constants/colors';
import i18n from '../../constants/i18n';
import { Transaction } from '../../shared';
import {
  GeneralRegularNormal,
  GeneralSemiBoldNormal,
} from '../common/Typography';
import useGetRequestDetailsQuoteFormattedData from './requestDetailsQuoteSection/useGetRequestDetailsQuoteFormattedData';

const SuccessfulRequestRecapSection = ({
  request,
}: {
  request: Transaction;
}) => {
  const { status } = request;
  const { meIsBooker } = useGetRequestDetailsQuoteFormattedData(request);

  if (['expired', 'declined', 'refunded', 'cancelled'].includes(status)) {
    return null;
  }

  const AmountDisplay = ({
    label,
    amount,
    alignRight,
  }: {
    label: string;
    amount: string;
    alignRight?: boolean;
  }) => {
    return (
      <GeneralRegularNormal
        style={{ textAlign: alignRight ? 'right' : 'left' }}
      >
        {label}
        {': '}
        <GeneralSemiBoldNormal
          style={{
            color: colors.black,
          }}
        >
          {amount}
        </GeneralSemiBoldNormal>
      </GeneralRegularNormal>
    );
  };

  if (['quoteSent'].includes(status)) {
    return (
      <>
        <Content>
          <Column>
            <div>
              <IoMdWarning
                size={24}
                style={{ paddingBottom: 5, marginLeft: -3 }}
                color={colors.error}
              />
              <GeneralRegularNormal>
                {meIsBooker
                  ? i18n('SuccessfulRequestRecapSection.required', 'Required')
                  : i18n('SuccessfulRequestRecapSection.unpaid', 'Unpaid')}
              </GeneralRegularNormal>
            </div>
          </Column>

          <AmountDisplay
            label={i18n(
              'SuccessfulRequestRecapSection.requestTotal',
              'Request Total',
            )}
            alignRight
            amount={`${request.priceData.finalTotalPriceFormatted} $`}
          />
        </Content>
        <Separator />
      </>
    );
  }

  if (['quotePending'].includes(status) && meIsBooker) {
    return (
      <>
        <Content>
          <Column>
            <GeneralSemiBoldNormal style={{ color: colors.black }}>
              {i18n(
                'SuccessfulRequestRecapSection.waiting',
                "You'll receive a quote shortly.",
              )}
            </GeneralSemiBoldNormal>
          </Column>
        </Content>
        <Separator />
      </>
    );
  }

  // TODO: Link the text to actual transaction numbers
  if (['quotePending'].includes(status) && !meIsBooker) {
    return (
      <>
        <Content>
          <Column>
            <div>
              <IoMdWarning
                size={24}
                style={{ paddingBottom: 5, marginLeft: -3 }}
                color={colors.error}
              />
              <GeneralRegularNormal>
                {i18n(
                  'SuccessfulRequestRecapSection.quoteNotSent',
                  'Quote not sent',
                )}
              </GeneralRegularNormal>
            </div>
          </Column>
        </Content>
        <Separator />
      </>
    );
  }

  if (['accepted', 'completed'].includes(status)) {
    return (
      <>
        <Content>
          <Column>
            <AmountDisplay
              alignRight
              label={i18n(
                'SuccessfulRequestRecapSection.requestTotal',
                'Request Total',
              )}
              amount={`${request.priceData.finalTotalPriceFormatted} $`}
            />
            {!meIsBooker && (
              <AmountDisplay
                alignRight
                label={i18n(
                  'SuccessfulRequestRecapSection.yourShare',
                  'Your share',
                )}
                amount={`${request.priceData.performancePriceFormatted} $`}
              />
            )}
          </Column>
        </Content>
        <Separator />
      </>
    );
  }

  return null;
};

const Content = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
`;

const Separator = styled.div`
  display: flex;
  width: 100%;
  height: 1px;
  margin-top: 24px;
  margin-bottom: 36px;
  background-color: rgba(0, 0, 0, 0.25);
`;

export default SuccessfulRequestRecapSection;
