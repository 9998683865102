import { observer } from 'mobx-react-lite';
import * as React from 'react';
import styled, { keyframes } from 'styled-components';
import { fadeIn } from 'react-animations';
import metrics from '../../../constants/metrics';
import { useUiStore } from '../../../stores/ui';
import PageLayoutFooter from '../PageLayoutFooter';
import CenteredClipLoader from '../CenteredClipLoader';

const fadeInAnimation = keyframes`${fadeIn}`;

export const pageLayoutPadding = 50;

const ProfilePageLayout = observer(
  ({
    renderHeader = () => null,
    children,
    maxContentWidth,
    contentContainerStyle,
    contentStyle = {},
    stickyContent,
    getRef,
    onScroll,
    extraFooterPadding,
    loading,
  }: {
    renderHeader?: () => React.ReactNode;
    children: any;
    maxContentWidth?: number;
    contentContainerStyle?: React.CSSProperties;
    contentStyle?: React.CSSProperties;
    stickyContent?: React.ReactNode;
    getRef?: (ref: any) => void;
    onScroll?: (scrollTop: number) => void;
    extraFooterPadding?: number;
    loading?: boolean;
  }) => {
    const uiStore = useUiStore();
    const mainRef = React.useRef<any>();

    React.useEffect(() => {
      const getRefMaybe = () => {
        if (getRef) {
          getRef(mainRef);
        }
      };
      setTimeout(getRefMaybe, 100);
    }, []);

    if (loading) {
      return <CenteredClipLoader />;
    }

    return (
      <Main
        ref={mainRef}
        onScroll={() => {
          if (onScroll) {
            onScroll(mainRef.current!.scrollTop);
          }
        }}
      >
        {renderHeader()}
        {stickyContent}
        <ContentContainer
          minHeight={uiStore.innerHeight}
          style={contentContainerStyle}
        >
          <Content style={{ maxWidth: maxContentWidth, ...contentStyle }}>
            {children}
          </Content>
        </ContentContainer>
        <PageLayoutFooter extraFooterPadding={extraFooterPadding} />
      </Main>
    );
  },
);

const Content = styled.div`
  max-width: ${metrics.maxContentWidth}px;
  width: 100%;
  @media (max-width: ${(props) => props.theme.breakPoints.mobile}px) {
    max-width: 100%;
  }
`;

const ContentContainer = styled.div<{ minHeight: number }>`
  min-height: ${(props) => props.minHeight}px;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  padding-left: ${pageLayoutPadding}px;
  padding-right: ${pageLayoutPadding}px;
  padding-bottom: 80px;
  align-items: center;
  @media (max-width: ${(props) => props.theme.breakPoints.mobile}px) {
    padding-left: 25px;
    padding-right: 25px;
  }
`;

const Main = styled.div`
  width: 100%;
  overflow-y: auto;
  animation: 0.5s ${fadeInAnimation};
  background-color: white;
  box-sizing: border-box;
`;

export default ProfilePageLayout;
