export default {
  'EventDetailsStepThree.style': {
    en: 'Style of music',
    fr: 'Style de musique',
  },
  'EventDetailsStepThree.withVocals': {
    en: 'With vocals?',
    fr: 'Avec accompagnement vocal?',
  },
  'EventDetailsStepThree.yes': { en: 'Yes', fr: 'Oui' },
  'EventDetailsStepThree.no': { en: 'No', fr: 'Non' },
  'EventDetailsStepThree.noPreference': {
    en: 'No preference',
    fr: 'Pas de préférence',
  },
  'EventDetailsStepThree.equipment': { en: 'Equipment', fr: 'Equipement' },
  'EventDetailsStepThree.performanceType': {
    en: 'Performance type',
    fr: 'Type de performance',
  },
  'EventDetailsStepThree.providedOnSite': {
    en: 'Provided on site',
    fr: 'Fourni sur place',
  },
  'EventDetailsStepThree.providedByMusician': {
    en: 'Provided by musician',
    fr: 'Fourni par le musicien',
  },
  'EventDetailsStepThree.concert': { en: 'Concert', fr: 'Concert' },
  'EventDetailsStepThree.backgroundMusic': {
    en: 'Background music',
    fr: 'Musique de fond',
  },
};
