export default {
  'StepperRegistrationSoloAbout.title': {
    en: 'Tell us more about you',
    fr: 'Parlez-nous de vous',
  },
  'StepperRegistrationSoloAbout.stageName': {
    en: 'Stage name',
    fr: 'Nom de scène',
  },
  'StepperRegistrationSoloAbout.city': { en: 'City', fr: 'Ville' },
};
