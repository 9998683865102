export default {
  'BookingUserInfo.title': {
    en: 'Payment preferences',
    fr: 'Préférences de paiement',
  },
  'BookingUserInfo.userinfo': {
    en: 'Booker informations',
    fr: 'Booker Informations',
  },
  'BookingUserInfo.firstName': { en: 'First name', fr: 'Prénom' },
  'BookingUserInfo.placeholderfirstName': {
    en: 'Enter your first name',
    fr: 'Entrez votre prénom',
  },
  'BookingUserInfo.lastName': { en: 'Last name', fr: 'Nom de famille' },
  'BookingUserInfo.placeholderLastName': {
    en: 'Enter your last name',
    fr: 'Entrez votre nom de famille',
  },
  'BookingUserInfo.phone': { en: 'Phone number', fr: 'Numéro de téléphone' },
  'BookingUserInfo.placeholderPhone': {
    en: 'Enter your phone number',
    fr: 'Entrez votre numéro de téléphone',
  },
  'BookingUserInfo.billingAddress': {
    en: 'Billing Address',
    fr: 'Adresse de facturation',
  },
  'BookingUserInfo.address': { en: 'Address', fr: 'Adresse' },
  'BookingUserInfo.city': { en: 'City', fr: 'Ville' },
  'BookingUserInfo.postalCode': { en: 'Postal Code', fr: 'code postal' },
  'BookingUserInfo.province': { en: 'Province', fr: 'Province' },
  'BookingUserInfo.country': { en: 'Country', fr: 'Pays' },
  'BookingUserInfo.next': { en: 'Next', fr: 'Suivante' },
};
