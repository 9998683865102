import * as React from 'react';
import styled from 'styled-components';
import i18n from '../../constants/i18n';
import { useUiStore } from '../../stores/ui';
import { EventType } from '../../shared';
import { observer } from 'mobx-react';
import { useEventStore } from '../../stores/event';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import { TextH4, TextH5 } from '../common/Typography';
import GiglinkedButton from '../common/GiglinkedButton';
import colors from '../../constants/colors';
import images from '../../constants/images';

const DeleteEventModal = observer(
  ({ event }: { event: Partial<EventType> }) => {
    const uiStore = useUiStore();
    const eventStore = useEventStore();
    const navigate = useNavigate();

    return (
      <Main>
        <Close src={images.closeIconBlack} onClick={uiStore.closePopup} />
        <TextH4 style={{ color: colors.red, marginBottom: '1rem' }}>
          {i18n(
            'DeleteEventModal.title',
            'Do you really want to delete event?',
          )}
        </TextH4>
        <TextH5 style={{ fontWeight: 400 }}>
          {i18n(
            'DeleteEventModal.subTitle',
            'This action will permanently delete all information and proposals from musicians.',
          )}
        </TextH5>
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <GiglinkedButton
            label={i18n('DeleteEventModal.delete', 'Yes, delete')}
            onClick={async () => {
              try {
                if (event.id) {
                  await eventStore.editMeEvent({
                    status: 'cancelled',
                    // this is necessary to make sure that there is an artist assigned to the event in order to send a cancellation email to them
                    artist: event.artist,
                    eventId: event.id,
                  });
                  uiStore.closePopup();

                  toast.success(
                    i18n(
                      'DeleteEventModal.eventDeleteSucessMessage',
                      'The event has been deleted',
                    ),
                  );
                  navigate(`/dashboard`);
                }
              } catch (e) {
                const err = e as Error;
                toast.error(err.message);
              }
            }}
            style={{ border: 'none' }}
          />

          <GiglinkedButton
            label={i18n('DeleteEventModal.cancel', 'Cancel')}
            onClick={async () => {
              uiStore.closePopup();
            }}
          />
        </div>
      </Main>
    );
  },
);

const Main = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1rem 2rem;
  max-width: 627px;
  border-radius: 10px;
  background-color: white;
  align-items: center;
  text-align: center;
  @media (max-width: ${(props) => props.theme.breakPoints.mobile}px) {
    max-width: 600px;
  }
`;

const Close = styled.img`
  cursor: pointer;
  margin-left: auto;
  object-fit: contain;
  height: 12px;
`;

export default DeleteEventModal;
