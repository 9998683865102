import * as React from 'react';
import styled from 'styled-components';
import images from '../../constants/images';
import i18n from '../../constants/i18n';
import { observer } from 'mobx-react-lite';
import { UseUserStore } from '../../stores/user';
import { useNavigate } from 'react-router-dom';
import GiglinkedButtonSignUpIn from '../common/GiglinkedButtonSignUpIn';
import NavBarContentView from './navBar/NavBarContentView';
import { SignUpStep } from '../../shared';

const LandingDrawer = observer(
  ({ isOpen, onClose }: { isOpen: boolean; onClose: () => void }) => {
    const userStore = UseUserStore();
    const navigate = useNavigate();
    const { setSignUpStep } = userStore;

    return (
      <Main isOpen={isOpen}>
        <TopBox>
          <GigLinkedLogo
            onClick={() => {
              onClose();
              navigate('/');
            }}
            src={images.giglinkedLogoWhite}
          />
          <Close src={images.closeIcon} onClick={onClose} />
        </TopBox>
        <ContentBox>
          <NavBarContentView onClose={onClose} />
        </ContentBox>
        <BottomBox>
          <ButtonsBox>
            <GiglinkedButtonSignUpIn
              inverted
              style={{ marginRight: 20, borderColor: '#fff' }}
              label={i18n('NavBarContent.signup', 'Sign up')}
              onClick={() => {
                setSignUpStep(SignUpStep.RegistrationUser);
                navigate(`/signup`);
              }}
            />
            <GiglinkedButtonSignUpIn
              style={{ borderColor: 'white' }}
              label={i18n('RegistrationController.login', 'Log in')}
              onClick={() => navigate(`/signin`)}
            />
          </ButtonsBox>
          <LanguageBox>
            <ImageLanguage src={images.globeImageWhite} />
            <GeneralMediumLarge
              onClick={() =>
                userStore.setBrowerLanguage(
                  userStore.browserLanguage === 'fr' ? 'en' : 'fr',
                )
              }
            >
              {userStore.browserLanguage === 'fr' ? 'EN' : 'FR'}
            </GeneralMediumLarge>
          </LanguageBox>
        </BottomBox>
      </Main>
    );
  },
);

const Main = styled.div<{ isOpen: boolean | undefined }>`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: linear-gradient(332.33deg, #100029 21.79%, #210154 100%);
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 10;
  transition: transform 0.3s ease;
  transform: ${(props) =>
    props.isOpen ? 'translate(0,0)' : 'translate(100%,0)'};
`;

const TopBox = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin: 40px 45px 0 16px;
`;

const Close = styled.img`
  width: 20px;
  height: 18px;
  cursor: pointer;
`;

const GigLinkedLogo = styled.img`
  width: 147px;
  height: 34px;
`;

const ContentBox = styled.div`
  display: flex;
  height: 50vh;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin: 186px 0 40px 190px;
  @media (max-width: ${(props) => props.theme.breakPoints.mobile}px) {
    margin: 40px 0 20px 40px;
  }
`;

const GeneralMediumLarge = styled.span`
  font-size: 28px;
  font-family: ${(props) => props.theme.fonts.GeneralSans};
  font-weight: 500;
  color: ${(props) => props.theme.colors.white};
  @media (max-width: ${(props) => props.theme.breakPoints.mobile}px) {
    font-size: 24px;
  }
`;

const BottomBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 200px;
  @media (max-width: ${(props) => props.theme.breakPoints.mobile}px) {
    margin-top: 0;
  }
`;

const ButtonsBox = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`;

const LanguageBox = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 40px;
  margin-bottom: 40px;
  cursor: pointer;
  @media (max-width: ${(props) => props.theme.breakPoints.mobile}px) {
    margin-top: 20px;
    margin-bottom: 20px;
  }
`;

const ImageLanguage = styled.img`
  width: 40px;
  margin-right: 20px;
  @media (max-width: ${(props) => props.theme.breakPoints.mobile}px) {
    width: 20px;
  }
`;

export default LandingDrawer;
