import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import i18n from '../../../constants/i18n';
import images from '../../../constants/images';
import { Transaction } from '../../../shared';
import { useMessagingStore } from '../../../stores/messaging';
import { useUiStore } from '../../../stores/ui';
import useGetRequestDetailsQuoteFormattedData from './useGetRequestDetailsQuoteFormattedData';

const SendMessageSection = ({ request }: { request: Transaction }) => {
  const navigate = useNavigate();
  const messagingStore = useMessagingStore();
  const { innerWidth } = useUiStore();

  const isMobilePhone = innerWidth <= 375;
  const { otherUser } = useGetRequestDetailsQuoteFormattedData(request);

  const onClickSendMessage = async () => {
    messagingStore.setCurrentOtherUser(otherUser);
    navigate('/conversations');
  };

  return (
    <Main>
      <PaperPlaneImg src={images.paperPlane} />

      {isMobilePhone ? (
        <FlexColumn onClick={onClickSendMessage}>
          <SendMessageText>
            {i18n('RequestDetailsQuoteSection.send', 'Send')}
          </SendMessageText>
          <SendMessageText>
            {i18n('RequestDetailsQuoteSection.message', 'Message')}
          </SendMessageText>
        </FlexColumn>
      ) : (
        <SendMessageText onClick={onClickSendMessage}>
          {i18n('RequestDetailsQuoteSection.sendMessage', 'Send Message')}
        </SendMessageText>
      )}
    </Main>
  );
};

const Main = styled.div`
  display: flex;
  @media (max-width: 375px) {
    align-self: center;
  }
`;

const PaperPlaneImg = styled.img`
  margin-top: 4px;
  margin-right: 11px;
`;

const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
`;

const SendMessageText = styled.span`
  font-size: 16px;
  font-family: ${(props) => props.theme.fonts.GeneralSans};
  font-weight: 400;
  color: ${(props) => props.theme.colors.sendMessageBlue};
  text-decoration: underline;
  cursor: pointer;
`;

export default SendMessageSection;
