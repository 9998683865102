export default {
  'LandingFooter.contacts': { en: 'Contacts', fr: 'Contacts' },
  'LandingFooter.quickLinks': { en: 'Quick links', fr: 'Liens rapides' },
  'LandingFooter.forMusicians': {
    en: 'For Musicians',
    fr: 'Pour les musiciens',
  },
  'LandingFooter.forEvents': {
    en: 'For Your Events',
    fr: 'Pour vos événements',
  },
  'LandingFooter.aboutUs': { en: 'About Us', fr: 'À propos de nous' },
  'LandingFooter.blog': { en: 'Blog', fr: 'Blogue' },
  'LandingFooter.followUs': { en: 'Follow us', fr: 'Suivez-nous' },
  'LandingFooter.newsLetter': {
    en: 'Sign up for our newsletter',
    fr: 'Inscrivez-vous à notre newsletter',
  },
  'LandingFooter.terms': {
    en: 'Terms and Conditions',
    fr: 'Termes et conditions',
  },
  'LandingFooter.policy': {
    en: 'Privacy Policy',
    fr: 'Politique de confidentialité',
  },
  'LandingFooter.placeholder': { en: 'Email', fr: 'Courriel' },
  'LandingFooter.placeholderSuccess': {
    en: 'Welcome to the GigLinked community!',
    fr: 'Bienvenue à la communauté Giglinked!',
  },
};
