import { action, observable, toJS } from 'mobx';
import { useStores } from '.';
import { Conversation, User } from '../shared';
import sexpressClient from '../utils/sexpress';
import user from './user';

export interface MessagingStore {
  conversations: Conversation[];
  currentConversation: Conversation | null;
  currentOtherUser: User | null;
  getCurrentConversation: () => Conversation | null;
  getConversations: () => Conversation[];
  fetchConversations: (payload?: { limit?: number }) => Promise<void>;
  setCurrentConversation: (newconversation: Conversation | null) => void;
  setCurrentOtherUser: (newCurrentOtherUser: User | null) => void;
  getCurrentOtherUser: () => User | null;
  numberOfUnSeenMessages: number;
}

const FETCH_CONVERSATION_ROUTINE_DELAY = 6000;

class Messaging implements MessagingStore {
  @observable conversations: MessagingStore['conversations'] = [];
  @observable currentConversation: MessagingStore['currentConversation'] = null;
  @observable currentOtherUser: MessagingStore['currentOtherUser'] = null;
  @observable _conversationLimit = 40;
  @observable numberOfUnSeenMessages = 0;

  _conversationsFetchingTimeout: any;

  getConversations: MessagingStore['getConversations'] = () => {
    if (this.conversations && this.conversations.length) {
      return toJS(this.conversations);
    }
    return [];
  };

  @action
  fetchConversations: MessagingStore['fetchConversations'] = async (
    payload = { limit: 40 },
  ) => {
    if (payload && payload.limit) {
      this._conversationLimit = payload.limit;
    }

    // @ts-ignore
    const conversations = await sexpressClient.getConversations(payload);

    let numberOfUnSeenMessages = 0;

    conversations.forEach((conversation) => {
      if (conversation.messages.length) {
        const lastMsg = conversation.messages[0];
        const meId = user?.me?.id;
        // @ts-ignore
        if (meId && !lastMsg?.seenBy?.includes(meId)) {
          numberOfUnSeenMessages += 1;
        }
      }
    });
    this.numberOfUnSeenMessages = numberOfUnSeenMessages;
    this.conversations = conversations;
  };

  @action
  setCurrentConversation: MessagingStore['setCurrentConversation'] = (
    conversation,
  ) => {
    if (conversation) {
      const me = user.getMe()!;
      const otherUser = conversation.users.filter((u) => u.id !== me.id)[0];
      this.currentOtherUser = otherUser;
    } else {
      this.currentOtherUser = null;
    }
    this.currentConversation = conversation;
  };

  getCurrentConversation: MessagingStore['getCurrentConversation'] = () => {
    if (this.currentConversation) {
      return toJS(this.currentConversation);
    }
    return null;
  };

  @action
  setCurrentOtherUser: MessagingStore['setCurrentOtherUser'] = async (
    otherUser,
  ) => {
    this.currentOtherUser = otherUser;

    // we check if we already have an actual conversation with this user
    if (otherUser) {
      await this.fetchConversations();
      const conversations = this.getConversations();
      let found = false;
      conversations.forEach((conversation) => {
        if (!found) {
          if (conversation.users.find((u) => u.id === otherUser.id)) {
            this.currentConversation = conversation;
            found = true;
          }
        }
      });

      if (!found) {
        this.currentConversation = {
          messages: [],
          // @ts-ignore
          id: undefined,
          users: [otherUser, user.getMe()!],
        };
      }
    }
  };

  getCurrentOtherUser: MessagingStore['getCurrentOtherUser'] = () => {
    if (this.currentOtherUser) {
      return toJS(this.currentOtherUser);
    }
    return null;
  };

  _fetchConversationsRoutine = async () => {
    try {
      const me = user.getMe();
      if (me) {
        await this.fetchConversations({
          limit: this._conversationLimit,
        });
      }
    } catch (e) {
      console.warn(e);
    }
    this._conversationsFetchingTimeout = setTimeout(
      this._fetchConversationsRoutine,
      FETCH_CONVERSATION_ROUTINE_DELAY,
    );
  };
}

export function useMessagingStore(): MessagingStore {
  const { messaging } = useStores();
  return messaging as MessagingStore;
}

export default new Messaging();
