import { observer } from 'mobx-react';
import * as React from 'react';
import styled from 'styled-components';
import colors from '../../constants/colors';
import i18n from '../../constants/i18n';
import { FormattedPayout } from '../../shared';
import sexpressClient from '../../utils/sexpress';
import CenteredClipLoader from '../common/CenteredClipLoader';
import { GeneralRegularNormal, TextH1 } from '../common/Typography';
import toast from 'react-hot-toast';
import Table from 'react-bootstrap/Table';
import { isArrayEmpty } from '../../utils/arrays';
import { format } from 'date-fns';
import { useNavigate } from 'react-router-dom';
import { UseUserStore } from '../../stores/user';

const FormattedPayoutsScreen = observer(() => {
  const navigate = useNavigate();
  const [formattedPayouts, setFormattedPayouts] = React.useState<
    FormattedPayout[]
  >([]);
  const [loading, setLoading] = React.useState(true);
  const userStore = UseUserStore();
  const isFrench = userStore?.browserLanguage === 'fr';

  const fetchFormattedPayouts = async () => {
    try {
      const { formattedPayouts } = await sexpressClient.getArtistPayouts();
      setFormattedPayouts(formattedPayouts);
    } catch (e) {
      const err = e as Error;
      toast.error(err.message);
    } finally {
      setLoading(false);
    }
  };

  React.useEffect(() => {
    fetchFormattedPayouts();
  }, []);

  if (loading) {
    return <CenteredClipLoader />;
  }

  return (
    <Main>
      {isArrayEmpty(formattedPayouts) && !loading ? (
        <GeneralRegularNormal>
          {i18n(
            'FormattedPayoutsScreen.onceYouGetGigging',
            'Once you get gigging, your invoices will appear here!',
          )}
        </GeneralRegularNormal>
      ) : (
        <>
          <TextH1 style={{ alignSelf: 'center', marginBottom: 36 }}>
            {i18n('FormattedPayoutsScreen.transactions', 'Transactions')}
          </TextH1>

          <Table hover>
            <thead>
              <tr>
                <HeaderColumn>
                  {i18n('FormattedPayoutScreen.status', 'Status')}
                </HeaderColumn>
                <HeaderColumn>
                  {i18n('FormattedPayoutScreen.number', 'Number')}
                </HeaderColumn>
                <HeaderColumn>
                  {i18n('FormattedPayoutScreen.customer', 'Customer')}
                </HeaderColumn>
                <HeaderColumn>
                  {i18n('FormattedPayoutScreen.date', 'Date')}
                </HeaderColumn>
                <HeaderColumn>
                  {i18n('FormattedPayoutScreen.amount', 'Amount')}
                </HeaderColumn>
                <HeaderColumn></HeaderColumn>
              </tr>
            </thead>
            <tbody>
              {formattedPayouts.map((formattedPayout) => {
                const {
                  amount,
                  customer,
                  status,
                  transactionAcceptedAt,
                  transactionId,
                } = formattedPayout;
                return (
                  <TableRow
                    key={transactionId}
                    onClick={() => navigate(`/requests/${transactionId}`)}
                  >
                    <TableColumn>{status}</TableColumn>
                    <TableColumn>{transactionId.slice(-5)}</TableColumn>
                    <TableColumn>{customer}</TableColumn>
                    <TableColumn>
                      {format(
                        new Date(transactionAcceptedAt),
                        isFrench ? 'y.dd.MM' : 'y.MM.dd',
                      )}
                    </TableColumn>
                    <TableColumn>${amount}</TableColumn>
                    <TableColumn
                      onClick={() => navigate(`/requests/${transactionId}`)}
                      style={{ fontWeight: 600, color: colors.buttonTextColor }}
                    >
                      {i18n('FormattedPayoutScreen.view', 'View')}
                    </TableColumn>
                  </TableRow>
                );
              })}
            </tbody>
          </Table>
        </>
      )}
    </Main>
  );
});

const Main = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-sizing: border-box;
  flex: 1;
  background-color: ${colors.lightBlue};
  justify-content: center;
  align-self: center;
  align-items: center;
  margin: 3rem 0;
  width: 1000px;
  @media (max-width: ${(props) => props.theme.breakPoints.mobile}px) {
    padding-top: 24px;
  }
`;

const HeaderColumn = styled.th`
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 400;
  color: ${(props) => props.theme.colors.tableTextColor};
  font-family: ${(props) => props.theme.fonts.GeneralSans};
`;

const TableRow = styled.tr`
  cursor: pointer;
  height: 63px;
  font-family: ${(props) => props.theme.fonts.GeneralSans};
`;

const TableColumn = styled.td`
  text-transform: capitalize;
  font-size: 16px;
  font-weight: 400;
`;

export default FormattedPayoutsScreen;
