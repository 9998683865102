import images from '../constants/images';
import { uploadMediaToAWS } from './api';
import sexpressClient from './sexpress';

export async function uploadFile({ file }: { file: File }) {
  const uploadUrls = await sexpressClient.generatePresignedUrls();
  if (!uploadUrls || !uploadUrls.length) {
    throw new Error('Something went wrong with the presigned URL');
  }

  const uploadRes = await uploadMediaToAWS(
    decodeURIComponent(uploadUrls[0].uploadUrl),
    file,
  );

  const url = uploadRes.responseURL.substring(
    0,
    uploadRes.responseURL.indexOf('?'),
  );

  return url;
}

export const replaceProfileImage = (profilePicture: string) => {
  if (
    profilePicture.length === 0 ||
    profilePicture.startsWith('https://cdn.discordapp.com')
  )
    return images.musicianCardAvatar;
  else return profilePicture;
};
