import { MobXProviderContext } from 'mobx-react';
import * as React from 'react';

import ui from './ui';
import user from './user';
import artist from './artist';
import act from './act';
import booking from './booking';
import messaging from './messaging';
import navigation from './navigation';
import event from './event';
import eventProposal from './eventProposal';

export default {
  ui,
  navigation,
  user,
  artist,
  act,
  booking,
  messaging,
  event,
  eventProposal,
};

export function useStores() {
  return React.useContext(MobXProviderContext);
}
