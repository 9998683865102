import { format } from 'date-fns';
import { frCA, enUS } from 'date-fns/locale';
import * as React from 'react';
import styled from 'styled-components';
import images from '../../constants/images';
import { Transaction } from '../../shared';
import { UseUserStore } from '../../stores/user';
import { GeneralRegularNormal, TextH1, TextH7 } from '../common/Typography';

const RequestDateLocationSection = ({ request }: { request: Transaction }) => {
  const userStore = UseUserStore();
  const isFrench = userStore?.browserLanguage === 'fr';

  const {
    locationAddress,
    startTime,
    endTime,
    eventDate,
    city,
    province,
    postalCode,
  } = request;

  const getFormattedDate = () => {
    const formatOption = isFrench ? 'EEEE d MMMM y' : 'EEEE MMMM d, y';
    const locale = isFrench ? frCA : enUS;

    const prefix = isFrench ? 'Le ' : '';

    const formattedDate = `${prefix}${format(
      new Date(eventDate),
      formatOption,
      {
        locale,
      },
    )}`;

    return formattedDate;
  };

  const getFormattedHours = () => {
    const formatOption = isFrench ? 'h:mmaaaa' : 'h:mmaaa';

    const startingTime = format(new Date(startTime), formatOption);
    const endingTime = format(new Date(endTime), formatOption);

    return `${startingTime}-${endingTime}`;
  };

  const getFormattedLocation = () => {
    const address = `${locationAddress}, ${city}, ${province} ${postalCode}`;

    return address;
  };

  return (
    <>
      <TextH1>{locationAddress}</TextH1>

      <DateContainer>
        <TextH7>{getFormattedDate()}</TextH7>
        <TextH7 style={{ fontWeight: 400, textAlign: 'right' }}>
          {getFormattedHours()}
        </TextH7>
      </DateContainer>
      <LocationContainer>
        <Pin src={images.mapPin} />

        <GeneralRegularNormal
          style={{
            textDecoration: 'underline',
            textUnderlinePosition: 'under',
            textDecorationColor: 'rgba(0,0,0,0.5)',
            opacity: 0.5,
          }}
        >
          {getFormattedLocation()}
        </GeneralRegularNormal>
      </LocationContainer>
    </>
  );
};

const DateContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 4px;
`;

const LocationContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 24px;
`;

const Pin = styled.img`
  width: 19px;
  height: 19px;
  margin-right: 2px;
  @media (max-width: ${(props) => props.theme.breakPoints.mobile}px) {
  }
`;

export default RequestDateLocationSection;
