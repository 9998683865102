export default {
  'LandingTabularSection.forArtists': {
    en: 'For Artists',
    fr: 'Pour Les Artistes',
  },
  'LandingTabularSection.forEvents': {
    en: 'For Events',
    fr: 'Pour Les Événements',
  },
  'LandingTabularSection.swiftPayments': {
    en: 'Swift Payments',
    fr: 'Paiements rapide',
  },
  'LandingTabularSection.swiftPaymentsDescription': {
    en: 'GigLinked ensures musicians get paid quickly, with payments processed in just five business days, providing financial stability.',
    fr: 'GigLinked veille à ce que les musiciens soient payés rapidement, les paiements étant traités en cinq jours ouvrables seulement, ce qui assure une stabilité financière.',
  },
  'LandingTabularSection.standardizedContracts': {
    en: 'Standardized Contracts',
    fr: 'Contrats standardisés',
  },
  'LandingTabularSection.standardizedContractsDescription': {
    en: 'GigLinked offers standardized contracts, eliminating misunderstandings and ensuring transparency and clear terms for bookings.',
    fr: 'GigLinked propose des contrats standardisés, ce qui élimine les malentendus et garantit de la transparence ainsi que des conditions claires pour les réservations.',
  },
  'LandingTabularSection.vibrantCommunity': {
    en: 'Vibrant Community',
    fr: 'Une communauté dynamique',
  },
  'LandingTabularSection.vibrantCommunityDescription': {
    en: 'GigLinked fosters a community of musicians, event coordinators, and retirement homes, facilitating collaboration, networking, and growth opportunities.',
    fr: "GigLinked cultive une communauté de musiciens, de coordinateurs d'événements et de maisons de retraite, facilitant la collaboration, la mise en réseau et les opportunités de croissance.",
  },
  'LandingTabularSection.effortlessBooking': {
    en: 'Effortless Booking',
    fr: 'Réservation sans effort',
  },
  'LandingTabularSection.effortlessBookingDescription': {
    en: 'GigLinked streamlines the booking process, making it easy for event planners to find and secure talented musicians for all occasions.',
    fr: "GigLinked optimise le processus de réservation, ce qui permet aux planificateurs d'événements de trouver et d'obtenir facilement des musiciens talentueux pour toutes occasions.",
  },
  'LandingTabularSection.timeEfficiency': {
    en: 'Time Efficiency',
    fr: 'Efficacité',
  },
  'LandingTabularSection.timeEfficiencyDescription': {
    en: 'GigLinked saves you time by handling payments and contracts, allowing you to focus on event planning and execution.',
    fr: "GigLinked vous fait gagner du temps en s'occupant des paiements et des contrats, ce qui vous permet de vous concentrer sur la planification et l'exécution de votre événement.",
  },
  'LandingTabularSection.diverseTalent': {
    en: 'Diverse Talent Pool',
    fr: 'Un bassin de talents diversifié',
  },
  'LandingTabularSection.diverseTalentDescription': {
    en: 'GigLinked provides access to a diverse pool of musicians, ensuring that bookers can find the perfect performers to suit their specific event needs',
    fr: "GigLinked fournit un accès à un bassin diversifié de musicien.ne.s, permettant la découverte d'artistes pour répondre aux besoins spécifiques de vos événements.",
  },
  'LandingTabularSection.sugnupForArtistsButton': {
    en: 'Sign up',
    fr: "S'inscrire",
  },
};
