import * as React from 'react';
import styled from 'styled-components';
import images from '../../constants/images';
import { Tag } from '../../shared';
import { GeneralRegularNormal } from './Typography';
import { shorten as short } from '../../utils/string';

const GiTag = ({
  tag,
  style,
  editable,
  onClickDelete = () => null,
  shorten,
}: {
  style?: React.CSSProperties;
  tag: Tag;
  editable?: boolean;
  onClickDelete?: (tag: Tag) => void;
  shorten?: number;
}) => {
  return (
    <Main style={style}>
      <GeneralRegularNormal style={{ color: '#4361EE', fontWeight: 500 }}>
        {shorten ? short(tag.label, shorten) : tag.label}
      </GeneralRegularNormal>
      {editable ? (
        <DeleteIcon src={images.closeBlue} onClick={() => onClickDelete(tag)} />
      ) : null}
    </Main>
  );
};

const DeleteIcon = styled.img`
  cursor: pointer;
  margin-left: 8px;
`;

const Main = styled.div`
  display: flex;
  height: 35px;
  padding-left: 9px;
  padding-right: 9px;
  align-items: center;
  justify-content: center;
  background: rgba(67, 97, 238, 0.1);
  border-radius: 2px;
  @media (max-width: ${(props) => props.theme.breakPoints.mobile}px) {
  }
`;

export default GiTag;
