export default {
  'EditActSongs.editMembers': {
    en: 'Edit Members',
    fr: 'Modifier les membres',
  },
  'EditActSongs.addSpotify': {
    en: 'Add a Spotify song',
    fr: 'Ajouter une chanson Spotify',
  },
  'EditActSongs.delete': { en: 'Remove', fr: 'Éliminer' },
};
