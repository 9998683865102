import { UseUserStore } from '../stores/user';

export const isStringEmptyUndefinedOrNull = (value: any): boolean => {
  return typeof value === 'string'
    ? value.trim() === ''
    : value === undefined || value === null;
};

export function shorten(text: string, maxLength: number) {
  return text && text.length > maxLength
    ? text.substring(0, maxLength) + '...'
    : text;
}

export function addCommaAndSpace(index: number) {
  return index ? ', ' : '';
}

// return a string capitalize the first letter of the given string.
export function capitalize(s: string): string {
  if (!s || !s.length) {
    return '';
  }
  if (s.length === 1) {
    return s.toUpperCase();
  }
  return s.substring(0, 1).toUpperCase() + s.substring(1);
}

export const addDollarSign = (text: string) => {
  const userStore = UseUserStore();
  const isFrench = userStore?.browserLanguage === 'fr';
  const formattedTextNumber = text.replace(/\B(?=(\d{3})+(?!\d))/g, ' ');

  return isFrench ? `${formattedTextNumber}$` : `$${formattedTextNumber}`;
};
