import * as React from 'react';
import { Box } from 'react-native-kondo';
import styled from 'styled-components';
import i18n from '../../constants/i18n';
import images from '../../constants/images';
import { logPerformerAddedProfileTag } from '../../utils/amplitude';
import { fonts, GeneralRegularNormal, GeneralRegularSmall } from './Typography';

const EditTagInput = ({
  style,
  value = '',
  onSave,
  ...rest
}: {
  style?: React.CSSProperties;
  value?: string;
  onSave?: (newValue: string) => void;
} & React.InputHTMLAttributes<any>) => {
  const [isInEditMode, setIsInEditMode] = React.useState(false);
  const [newValue, setNewValue] = React.useState<string>(value);
  const inputRef = React.useRef<any>();
  const [isFocus, setIsFocus] = React.useState(false);

  const onPressSave = () => {
    if (onSave) {
      onSave(newValue);
      logPerformerAddedProfileTag({ tagLabel: newValue });
    }
    setNewValue('');
  };

  const onClickEdit = () => {
    setIsInEditMode(true);
    setTimeout(() => inputRef.current.focus(), 50);
  };

  const onCancel = () => {
    setIsInEditMode(false);
    setNewValue('');
    setIsFocus(false);
  };

  if (!isInEditMode) {
    return (
      <Box flex={1} flexDirection="row">
        <GeneralRegularNormal
          style={{ color: '#4361EE', cursor: 'pointer' }}
          onClick={onClickEdit}
        >
          +{' '}
          <GeneralRegularNormal
            style={{ color: '#4361EE', textDecoration: 'underline' }}
          >
            {i18n('EditArtistOverviewTag.addKeywords', 'Add Keywords')}
          </GeneralRegularNormal>
        </GeneralRegularNormal>
      </Box>
    );
  }

  return (
    <Main style={style} onClick={isInEditMode ? undefined : onClickEdit}>
      <Input
        ref={inputRef}
        disabled={!isInEditMode}
        {...rest}
        value={newValue}
        onKeyDown={(ev: any) => {
          if (isFocus) {
            if (ev.key === 'Enter') {
              onPressSave();
            } else if (ev.key === 'Escape') {
              onCancel();
            }
          }
        }}
        onFocus={() => setIsFocus(true)}
        onBlur={() => setIsFocus(false)}
        style={{
          height: 37,
          overflow: isInEditMode ? undefined : 'hidden',
        }}
        onChange={(e: any) => setNewValue(e.target.value)}
      />
      <GeneralRegularSmall style={{ marginTop: 12 }}>
        {i18n(
          'EditArtistOverviewTagInput.inputTag',
          'To add a tag, type a word or phrase and hit enter.',
        )}
      </GeneralRegularSmall>
      <EditPen src={images.closeBlue} onClick={onCancel} />
    </Main>
  );
};

const EditPen = styled.img`
  position: absolute;
  right: 15px;
  bottom: 40px;
  width: 15px;
  height: 15px;
  cursor: pointer;
  filter: grayscale(100);
  @media (max-width: ${(props) => props.theme.breakPoints.mobile}px) {
  }
`;

const Input = styled.input`
  text-decoration: none;
  outline: none;
  width: 100%;
  font-size: 16px;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.75);
  height: 37px;
  font-family: ${fonts.GeneralSans};
  background-color: transparent;
  border-radius: 2px;
  border: 1px solid #e0e0e0;
  padding-left: 12px;
  padding-right: 60px;
  box-sizing: border-box;
  :focus {
    border: 1px solid #5ec8d6;
  }
  @media (max-width: ${(props) => props.theme.breakPoints.mobile}px) {
  }
`;

const Main = styled.div`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  position: relative;
  @media (max-width: ${(props) => props.theme.breakPoints.mobile}px) {
  }
`;

export default EditTagInput;
