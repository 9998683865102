import { observable, action } from 'mobx';
import React from 'react';
import { useStores } from '.';

import metrics from '../constants/metrics';

const MOBILE_NAVBAR_HEIGHT = 56;
const DESKTOP_NAVBAR_HEIGHT = 56;

const MARGIN_TOP = 10;
const MARGIN_BOTTOM = 34;

export interface PopupPayload {
  renderContent: () => React.ReactNode;
  popupDisableTapOut?: boolean;
}

export interface UIStoreType {
  isMobile: boolean;
  isTablet: boolean;
  innerHeight: number;
  innerWidth: number;
  navBarHeight: number;
  calculatedHeightOfContainer: number;
  updateWindowSizes: ({
    innerHeight,
    innerWidth,
  }: {
    innerWidth: number;
    innerHeight: number;
  }) => void;

  // ---- GalleryViewer
  isGalleryViewerShown: boolean;
  openGalleryViewer: ({ pictures }: { pictures: string[] }) => void;
  closeGalleryViewer: () => void;
  galleryViewerPictures: string[];
  //--------

  // ----- Popup
  openPopup: (payload: PopupPayload) => void;
  closePopup: () => void;
  popupRenderContent: PopupPayload['renderContent'];
  isPopupShown: boolean;
  popupDisableTapOut: PopupPayload['popupDisableTapOut'];
  // ----------
}

class UIStore {
  @observable innerHeight = window.innerHeight;
  @observable innerWidth = window.innerWidth;
  @observable isTablet = false;
  @observable isMobile = false;
  @observable navBarHeight = this.isMobile
    ? MOBILE_NAVBAR_HEIGHT
    : DESKTOP_NAVBAR_HEIGHT;
  @observable calculatedHeightOfContainer =
    window.innerHeight - MARGIN_TOP - MARGIN_BOTTOM;

  // ---- GalleryViewer
  @observable isGalleryViewerShown = false;
  @observable galleryViewerPictures: UIStoreType['galleryViewerPictures'] = [];

  @action openGalleryViewer: UIStoreType['openGalleryViewer'] = (payload) => {
    this.galleryViewerPictures = payload.pictures;
    this.isGalleryViewerShown = true;
  };

  @action closeGalleryViewer: UIStoreType['closeGalleryViewer'] = () => {
    this.isGalleryViewerShown = false;
    this.galleryViewerPictures = [];
  };
  //--------

  // ---- Popup
  @observable popupDisableTapOut: UIStoreType['popupDisableTapOut'] = false;
  @observable isPopupShown: UIStoreType['isPopupShown'] = false;
  @observable popupRenderContent: UIStoreType['popupRenderContent'] = () =>
    null;

  @action openPopup: UIStoreType['openPopup'] = (payload) => {
    this.popupRenderContent = payload.renderContent;
    this.isPopupShown = true;
    this.popupDisableTapOut = payload.popupDisableTapOut
      ? payload.popupDisableTapOut
      : false;
  };

  @action closePopup: UIStoreType['closePopup'] = () => {
    this.isPopupShown = false;
    this.popupDisableTapOut = false;
    this.popupRenderContent = () => null;
  };
  //--------

  @action
  updateWindowSizes({
    innerHeight,
    innerWidth,
  }: {
    innerHeight: number;
    innerWidth: number;
  }) {
    this.innerHeight = innerHeight || this.innerHeight;
    this.innerWidth = innerWidth || this.innerWidth;
    this.isMobile = innerWidth <= metrics.mobileThreshold;
    this.isTablet = innerWidth <= metrics.tabletThredshold;
    this.navBarHeight = this.isMobile
      ? MOBILE_NAVBAR_HEIGHT
      : DESKTOP_NAVBAR_HEIGHT;
  }
}

export default new UIStore();

export function useUiStore(): UIStoreType {
  const { ui } = useStores();
  return ui as UIStoreType;
}
