import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { InputLabel, TextH5 } from '../../common/Typography';
import i18n from '../../../constants/i18n';
import styled from 'styled-components';
import { EventType } from '../../../shared';
import GiglinkedTextInput from '../../common/GiglinkedTextInput';
import { addDollarSign } from '../../../utils/string';
import {
  CANADA_GST,
  GIGLINKED_CUT_PERCENT,
  QUEBEC_QST,
} from '../../../constants/price';

const EventDetailsStepFour = observer(
  ({
    event,
    setEvent,
  }: {
    event: Partial<EventType>;
    setEvent: React.Dispatch<React.SetStateAction<any>>;
  }) => {
    // Calculate the Giglinked fee.
    const giglinkedFee = Number(event.estimatedBudget!) * GIGLINKED_CUT_PERCENT;

    // Calculate the subtotal by adding the Giglinked fee to the proposal quote.
    const subtotal = Number(event.estimatedBudget!) + giglinkedFee;

    // Calculate GST and QST based on the giglinked fee.
    const gst = giglinkedFee * CANADA_GST;
    const qst = giglinkedFee * QUEBEC_QST;

    // Calculate the total tax and total amounts.
    const totalTax = gst + qst;
    const total = subtotal + totalTax;

    return (
      <>
        <TextH5 style={{ fontWeight: 500, marginLeft: '.5rem' }}>
          {i18n(
            'EventDetailsStepFour.budgetForMusician',
            'Budget for musician',
          )}
        </TextH5>
        <InputLabel htmlFor="estimatedBudgetDescription">
          {i18n(
            'EventDetailsStepFour.estimatedBudgetDescription',
            'The amount entered above is what musicians will be paid for this event. The total amount to be charged, (incl. fees and taxes):',
          )}
        </InputLabel>

        <GiglinkedTextInput
          id="estimatedBudget"
          style={{ width: '100%', marginBottom: '1rem' }}
          type="number"
          value={event.estimatedBudget}
          onChangeText={(ev) => setEvent({ ...event, estimatedBudget: ev })}
          placeholder="$"
        />
        {!isNaN(total) && (
          <TextH5 style={{ fontWeight: 500, marginLeft: '.5rem' }}>
            {i18n(
              'EventDetailsStepFour.totalAmount',
              'The total amount to be charged, (incl. fees and taxes):',
            )}{' '}
            {addDollarSign(total.toFixed(2))}
          </TextH5>
        )}
        <InputLabel htmlFor="description">
          {i18n(
            'EventDetailsStepFour.description',
            'Please tell us more about your event, your audience, about the repertoire you want. Would you like to tell us anything else? (Optional).',
          )}
        </InputLabel>
        <MultilineTextArea
          value={event.description}
          onChange={(ev) =>
            setEvent({ ...event, description: ev.target.value })
          }
          id="description"
          cols={40}
          rows={5}
          style={{
            width: '100%',
            marginBottom: '1rem',
          }}
        />
      </>
    );
  },
);

const MultilineTextArea = styled.textarea`
  width: 100%;
  border: 1px solid ${(props) => props.theme.colors.buttonBorder};
  border-radius: 10px;
  padding-left: 10px;
`;

export default EventDetailsStepFour;
