export default {
  'ArtistSpotifySection.title': {
    en: 'Songs / Repertoire',
    fr: 'Chansons / répertoire',
  },
  'ArtistSpotifySection.editSongs': {
    en: 'Edit Songs',
    fr: 'Modifier les chansons',
  },
};
