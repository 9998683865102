import { observer } from 'mobx-react';
import * as React from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { UseUserStore } from '../../../stores/user';
import i18n from '../../../constants/i18n';
import sexpressClient from '../../../utils/sexpress';
import { validateEmail } from '../../../utils/validator';
import GiglinkedTextInput from '../../common/GiglinkedTextInput';
import GiglinkedButton from '../../common/GiglinkedButton';
import GiAuthContainer from '../../common/GiAuthContainer';
import toast from 'react-hot-toast';

const ForgotPassword = observer(() => {
  const userStore = UseUserStore();
  const navigate = useNavigate();

  const [loading, setLoading] = React.useState(false);
  const [email, setEmail] = React.useState('');
  const [emailSent, setEmailSent] = React.useState(false);

  const validations = {
    email: validateEmail(email),
  };

  const me = userStore.getMe();
  const isLoggedIn = !!me;

  const onClickForgetPassword = async () => {
    try {
      if (isLoggedIn) {
        throw new Error(
          i18n(
            'RegistrationUser.userLoggedIn',
            'You are logged in! Log out and try again.',
          ),
        );
      }
      setLoading(true);
      await sexpressClient.sendForgotPasswordEmail({ email });
      setEmailSent(true);
    } catch (e) {
      const err = e as Error;
      toast.error(err.message);
      setEmailSent(false);
    } finally {
      setLoading(false);
    }
  };

  const titleText = emailSent
    ? i18n('ForgotPassword.emailSent!', 'Email sent!')
    : i18n('ForgotPassword.forgotYourPassword?', 'Forgot your password?');

  const subText = emailSent
    ? i18n(
        'ForgotPassword.checkYourInbox',
        'Check your inbox and follow instructions to reset your password',
      )
    : i18n(
        'ForgotPassword.subtitle',
        `Enter your email address and we'll send you instructions on how to reset your password.`,
      );

  return (
    <GiAuthContainer
      title={titleText}
      secondaryText={subText}
      editableContent={
        <>
          <MainBox>
            <GiglinkedTextInput
              placeholder={i18n('ForgotPassword.email', 'Email')}
              style={{ marginBottom: 30 }}
              type="email"
              value={email}
              onChangeText={setEmail}
              showCheckMark={validations.email}
            />
            <GiglinkedButton
              label={i18n(
                'ForgotPassword.instructions',
                'Send Reset Instructions',
              )}
              style={{
                alignSelf: 'center',
                width: '100%',
              }}
              onClick={onClickForgetPassword}
              loading={loading}
              disabled={!validations.email}
            />
          </MainBox>
          <BoxBottom>
            <GiglinkedButton
              style={{ border: 'none', justifyContent: 'left', width: '100%' }}
              onClick={() => navigate(`/signin`)}
              label={i18n('RegistrationStepper.back', 'Back')}
            />
          </BoxBottom>
        </>
      }
    />
  );
});

const MainBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 90%;
  @media (max-width: ${(props) => props.theme.breakPoints.mobile}px) {
    padding-left: 20px;
    padding-right: 20px;
  }
`;

const BoxBottom = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 10%;
  @media (max-width: ${(props) => props.theme.breakPoints.mobile}px) {
    margin-right: 5px;
    margin-top: 0;
  }
`;

export default ForgotPassword;
