import { observer } from 'mobx-react';
import * as React from 'react';
import images from '../../constants/images';
import colors from '../../constants/colors';
import i18n from '../../constants/i18n';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { jsPDF } from 'jspdf';
import html2canvas from 'html2canvas';
import { Transaction } from '../../shared';
import sexpressClient from '../../utils/sexpress';
import toast from 'react-hot-toast';
import { UseUserStore } from '../../stores/user';
import { format, addDays } from 'date-fns';

const InvoicePage = observer(() => {
  const navigate = useNavigate();
  const [transaction, setTransaction] = React.useState<Transaction>();
  const userStore = UseUserStore();
  const isFrench = userStore?.browserLanguage === 'fr';

  const url = window.location.pathname;
  const transactionId = url.substring(
    url.lastIndexOf('/') + 1,
  ) as Transaction['id'];

  const fetchTransaction = async () => {
    try {
      const request = await sexpressClient.getTransaction({
        transactionId,
      });
      setTransaction(request);
    } catch (e) {
      const err = e as Error;
      toast.error(err.message);
    }
  };

  React.useEffect(() => {
    fetchTransaction();
  }, []);

  const html = transaction
    ? `<!DOCTYPE html>
<html>
    <head><meta http-equiv="Content-Type" content="text/html; charset=UTF-8">
        <style>
            @font-face {
                font-family:General Sans;
                src: url('General Sans-Medium.ttf') format('truetype');
                font-weight: 500;
                font-style: normal;
            }

            @font-face {
                font-family: General Sans;
                src: url('General Sans-Bold.ttf') format('truetype');
                font-weight: 700;
                font-style: normal;
            }

            body {
                font-size: 0.75rem;
                font-family: General Sans;
                font-weight: 400;
                color: #000000;
                margin: 0 auto;
                position: relative;
                margin: 60px;
            }

            .header-columns {
                display: flex;
                justify-content: space-between;
                padding-left: 60px;
            }

            .logo {
                height: 5rem;
                width: auto;
                padding-right: 60px;
            }

            .logotype {
                display: flex;
                align-items: center;
                font-weight: 700;
            }

            h2 {
                font-family:General Sans;
                font-size: 1.25rem;
                font-weight: 400;
            }

            h4 {
                font-family:General Sans;
                font-size: 1rem;
                font-weight: 400;
                color: #777;
            }

            h5 {
              font-family:General Sans;
              font-size: 14px;
              font-weight: 400;
            }

            .page {
              padding: 60px;
            }

            .row {
              display: flex;
              flex-direction: row;
              justify-content: space-between;
            }

            .column {
              display: flex;
              flex-direction: column;
              margin: 1rem;
            }

            .table {
                width: 100%;
            }

            .table-header-row {
          	  text-align: left;
              height: 42px;
              width: 100%;
              background: #DDDAF8;
            }

          .table-row {
          	text-align: left;
            height: 42px;
          }
          .table-column {
            padding: 0 16px;
            text-transform: capitalize;
          }
          .divider {
          	height: 1px;
            width: 100%;
            background: #DDDAF8;
          }
          .divider-dashed {
          	height: 1px;
            border-bottom: 1px dashed #777;
            width: 100%;
          }
          .final-amount {
            font-size: 24px;
          	display: flex;
            float: right;
            margin-top: 16px;
            font-weight: 500;
          }
        </style>
    </head>

    <body>
            <div class="header-columns">
                <div>
                  <p>GigLinked</p>
                  <p>4766 Notre-Dame O</p>
                  <p>Montreal QC H4C 1S8</p>
                  <p>+1 5147581990</p>
                  <p>james@giglinked.live</p>
					        <p>www.giglinked.live</p>
                </div>
                <div class="logotype">
                  <img class="logo" src=${images.giglinkedLogoBlack}>
                </div>

            </div>
        </div>

        <div class="page" style="page-break-after: always">
            <div>
                <h2>
                    Invoice
                </h2>
            </div>
            <div class="row">
              <div class="column">
                <h4>Invoice to: </h4>
                <h5>${transaction!.contactFirstName}   ${
        transaction!.contactLastName
      }</h5>
                <h5>${transaction!.locationAddress}</h5>
                <h5>${transaction!.postalCode}</h5>
              </div>
              <div class="column">
                <h5>DATE OF INVOICE: ${format(
                  new Date(transaction!.eventDate),
                  isFrench ? 'y.dd.MM' : 'y.MM.dd',
                )}
                </h5>
                <h5>PAYMENT DUE: ${format(
                  addDays(new Date(transaction!.eventDate), 7),
                  isFrench ? 'y.dd.MM' : 'y.MM.dd',
                )}</h5>
            </div>
          </div>
          <table class="table">
            <thead>
              <tr class="table-header-row">
                <th class="table-column">DATE
                </th>
                <th class="table-column">
                </th>
                <th class="table-column">DESCRIPTION
                </th>
                <th class="table-column">QTY
                </th>
                <th class="table-column">RATE
                </th>
                <th class="table-column">AMOUNT
                </th>
              </tr>
            </thead>
            <tbody>

              <tr class="table-row">
                <td class="table-column">${format(
                  new Date(transaction!.eventDate),
                  isFrench ? 'y.dd.MM' : 'y.MM.dd',
                )}</td>
                <td class="table-column">Artist Fee / Cachet Artiste</td>
                <td class="table-column">${transaction!.artist.name} - ${
        transaction!.eventType
      }
                  ${format(new Date(transaction.startTime), 'hh:mm a')} -
                  ${format(new Date(transaction.endTime), 'hh:mm a')}
                </td>
                <td class="table-column">1</td>
                <td class="table-column"> ${
                  transaction!.priceData.performancePriceFormatted
                }</td>
                <td class="table-column"> ${
                  transaction!.priceData.performancePriceFormatted
                }</td>
              </tr>

              <tr class="table-row">
                <td class="table-column">${format(
                  new Date(transaction!.eventDate),
                  isFrench ? 'y.dd.MM' : 'y.MM.dd',
                )}</td>
                <td class="table-column">GigLinked Fee / Frais GigLinked</td>
                <td class="table-column"/>
                <td class="table-column">1</td>
                <td class="table-column"> ${
                  transaction!.priceData.gigLinkedCutPriceFormatted
                }</td>
                <td class="table-column"> ${
                  transaction!.priceData.gigLinkedCutPriceFormatted
                }</td>
              </tr>

              <tr class="table-row">
              <td class="table-column">${format(
                new Date(transaction!.eventDate),
                isFrench ? 'y.dd.MM' : 'y.MM.dd',
              )}</td>
              <td class="table-column">GST(5%)</td>
              <td class="table-column"/>
              <td class="table-column">1</td>
              <td class="table-column"> ${
                transaction!.priceData.gstPriceFormatted
              }</td>
              <td class="table-column"> ${
                transaction!.priceData.gstPriceFormatted
              }</td>
            </tr>
            <tr class="table-row">
            <td class="table-column">${format(
              new Date(transaction!.eventDate),
              isFrench ? 'y.dd.MM' : 'y.MM.dd',
            )}</td>
            <td class="table-column">QST(9,975%)</td>
            <td class="table-column"/>
            <td class="table-column">1</td>
            <td class="table-column"> ${
              transaction!.priceData.qstPriceFormatted
            }</td>
            <td class="table-column"> ${
              transaction!.priceData.qstPriceFormatted
            }</td>
          </tr>
            </tbody>
          </table>
          <div class="divider-dashed"/>
          <div class="final-amount">
              <div class="column">
              $ ${transaction!.priceData.finalTotalPriceFormatted}
              </div>
          </div>
           
    </body>
</html>
`
    : ``;

  return (
    <Main>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
        }}
      >
        <BookingButton onClick={() => navigate(`/dashboard`)}>
          <img src={images.arrowLeft} style={{ height: '1rem' }} />
          {i18n('InvoicePage.backToBooking', 'Back to bookings')}
        </BookingButton>
        <DownloadButton
          onClick={() => {
            const input = document.getElementById('divToPrint');
            html2canvas(input!).then((canvas) => {
              const imgWidth = 208;
              const imgHeight = (canvas.height * imgWidth) / canvas.width;
              const imgData = canvas.toDataURL('img/png');
              const pdf = new jsPDF('p', 'mm', 'a4');
              pdf.addImage(imgData, 'PNG', 0, 0, imgWidth, imgHeight);
              pdf.save('invoice.pdf');
            });
          }}
        >
          {i18n('InvoicePage.download', 'Download')}
        </DownloadButton>
      </div>
      <div
        id="divToPrint"
        dangerouslySetInnerHTML={{ __html: html }}
        style={{ paddingTop: 40 }}
      />
    </Main>
  );
});

export default InvoicePage;

const Main = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-sizing: border-box;
  flex: 1;
`;

const BookingButton = styled.span`
  font-size: 16px;
  font-weight: 600;
  color: ${colors.buttonTextColor};
  cursor: pointer;
`;

const DownloadButton = styled.span`
  font-size: 16px;
  font-weight: 600;
  color: ${colors.buttonTextColor};
  cursor: pointer;
`;
