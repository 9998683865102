import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { Box } from 'react-native-kondo';
import colors from '../../constants/colors';
import { useMessagingStore } from '../../stores/messaging';
import ConversationTile from './ConversationTile';
import { TextH1 } from '../common/Typography';
import { ScrollView } from 'react-native';
import { useUiStore } from '../../stores/ui';

const ConversationListing = observer(() => {
  const messageStore = useMessagingStore();
  const conversations = messageStore.getConversations();
  const uiStore = useUiStore();

  return (
    <Box
      style={{
        borderRightWidth: 1,
        borderColor: 'rgba(0, 0, 0, 0.25)',
      }}
    >
      <Box
        justifyContent={uiStore.isMobile ? 'center' : 'flex-end'}
        height={uiStore.isMobile ? 60 : 102}
        pb={uiStore.isMobile ? 0 : 20}
        bg={colors.white}
        px={40}
      >
        <TextH1>Messages</TextH1>
      </Box>

      <ScrollView style={{ flex: 1 }} showsVerticalScrollIndicator={false}>
        {conversations.map((conversation, i) => (
          <ConversationTile
            key={conversation.id}
            conversation={conversation}
            index={i}
          />
        ))}
      </ScrollView>
    </Box>
  );
});

export default ConversationListing;
