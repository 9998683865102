import * as React from 'react';
import { Box } from 'react-native-kondo';
import styled from 'styled-components';
import InputMask from 'react-input-mask';

import colors from '../../constants/colors';
import i18n from '../../constants/i18n';
import images from '../../constants/images';
import { fonts, GeneralRegularNormal, TextH6 } from './Typography';
import { addRightToolTip } from './ToolTip';

const EditableTextInput = ({
  label,
  toolTip,
  style,
  value = '',
  textArea,
  onSave,
  icon,
  disabled,
  mask,
  ...rest
}: {
  label?: string;
  toolTip?: string;
  style?: React.CSSProperties;
  value?: string;
  onSave?: (newValue: string) => void;
  textArea?: boolean;
  icon?: string;
  mask?: string;
} & React.InputHTMLAttributes<any>) => {
  const [isInEditMode, setIsInEditMode] = React.useState(false);
  const [newValue, setNewValue] = React.useState<string>(value);
  const inputRef = React.useRef<any>();
  const [isFocus, setIsFocus] = React.useState(false);

  const onPressSave = () => {
    setIsInEditMode(false);
    if (onSave) {
      onSave(newValue);
    }
    setIsFocus(false);
  };

  const onClickEdit = () => {
    setIsInEditMode(true);
    setTimeout(() => inputRef.current.focus(), 50);
  };

  const onCancel = () => {
    setIsInEditMode(false);
    setNewValue(value);
    setIsFocus(false);
  };

  let InputToUse: any = textArea && isInEditMode ? InputArena : Input;

  if (mask) {
    InputToUse = InputMask;
  }

  const LabelSection = () => {
    if (!label) {
      return null;
    }

    if (!toolTip) {
      return <TextH6 style={{ marginBottom: 12 }}>{label}</TextH6>;
    }

    return addRightToolTip({
      leftNode: <TextH6 style={{ marginBottom: 12 }}>{label}</TextH6>,
      toolTip,
    });
  };

  return (
    <Main
      style={style}
      onClick={isInEditMode || disabled ? undefined : onClickEdit}
    >
      <LabelSection />
      <Box flexDirection="row" alignItems="center">
        {icon ? <SocialIcon src={icon} /> : null}
        <InputToUse
          ref={inputRef}
          mask={mask}
          disabled={!isInEditMode || disabled}
          {...rest}
          value={newValue}
          onKeyDown={(ev: any) => {
            if (isFocus) {
              if (ev.key === 'Enter' && !textArea) {
                onPressSave();
              } else if (ev.key === 'Escape') {
                onCancel();
              }
            }
          }}
          onFocus={() => setIsFocus(true)}
          onBlur={() => setIsFocus(false)}
          style={{
            height: textArea && isInEditMode ? 125 : 37,
            overflow: isInEditMode ? undefined : 'hidden',
            backgroundColor: disabled ? 'lightgray' : undefined,
          }}
          onChange={(e: any) => setNewValue(e.target.value)}
        />
      </Box>
      {!isInEditMode ? (
        <EditPen
          src={images.editPen}
          onClick={disabled ? undefined : onClickEdit}
          hide={disabled}
        />
      ) : (
        <ButtonRow>
          <Button
            onClick={onPressSave}
            label={i18n('EditableTextInput.save', 'Save Change')}
            mode="save"
          />
          <Button
            onClick={onCancel}
            label={i18n('EditableTextInput.cancel', 'Cancel')}
            mode="cancel"
          />
        </ButtonRow>
      )}
    </Main>
  );
};

const Button = ({
  onClick,
  label,
  mode,
}: {
  onClick: () => void;
  label: string;
  mode: 'save' | 'cancel';
}) => {
  const backgroundColor = mode === 'save' ? '#4361EE' : 'rgb(240,241,242)';

  return (
    <ButtonContainer style={{ backgroundColor }} onClick={onClick}>
      <GeneralRegularNormal
        style={{
          color: mode === 'save' ? colors.whiteText : colors.black,
          fontWeight: 500,
        }}
      >
        {label}
      </GeneralRegularNormal>
    </ButtonContainer>
  );
};

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  width: 117px;
  height: 32px;
  border-radius: 2px;
  margin-right: 8px;
  &:hover {
    opacity: 0.75;
  }
`;

const SocialIcon = ({ src }: { src: string }) => {
  return (
    <Box
      style={{
        width: 38,
        height: 38,
        borderRadius: 38 / 2,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#4361EE',
        marginRight: 16,
      }}
    >
      <IconImg src={src} />
    </Box>
  );
};

const IconImg = styled.img`
  width: 22px;
  height: 22px;
  object-fit: contain;
  filter: invert(1);
`;

const ButtonRow = styled.div`
  margin-top: 12px;
  display: flex;
`;

const Input = styled.input`
  text-decoration: none;
  outline: none;
  width: 100%;
  font-size: 16px;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.75);
  height: 37px;
  font-family: ${fonts.GeneralSans};
  background-color: transparent;
  border-radius: 2px;
  border: 1px solid #e0e0e0;
  padding-left: 12px;
  padding-right: 60px;
  box-sizing: border-box;
  :focus {
    border: 1px solid #5ec8d6;
  }
`;

const InputArena = styled.textarea`
  text-decoration: none;
  outline: none;
  width: 100%;
  font-size: 16px;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.75);
  height: 37px;
  font-family: ${fonts.GeneralSans};
  background-color: transparent;
  border-radius: 2px;
  border: 1px solid #e0e0e0;
  padding-left: 12px;
  padding-right: 60px;
  resize: none;
  box-sizing: border-box;
  :focus {
    border: 1px solid #5ec8d6;
  }
`;

const EditPen = styled.img<{ hide: boolean | undefined }>`
  position: absolute;
  right: 15px;
  bottom: 35px;
  width: 15px;
  height: 15px;
  cursor: pointer;
  opacity: ${(props) => (props.hide ? 0 : 1)};
`;

const Main = styled.div`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  position: relative;
  padding-bottom: 1rem;
`;

export default EditableTextInput;
