export default {
  'EventsTable.eventName': { en: 'Event name', fr: "Nom de l'événement" },
  'EventsTable.date': { en: 'Date', fr: 'Date' },
  'EventsTable.time': { en: 'Time', fr: "L'heure" },
  'EventsTable.musician': { en: 'Musician', fr: 'Musicien' },
  'EventsTable.proposals': { en: 'Proposals', fr: 'Propositions' },
  'EventsTable.status': { en: 'Status', fr: 'Status' },
  'EventsTable.noEvents': {
    en: "Once you've posted your first event, it will appear here!",
    fr: ' Une fois que vous aurez publié votre premier événement, il apparaîtra ici!',
  },
  'EventsTable.theme': { en: 'Theme', fr: 'Thème' },
  'EventsTable.musicalStyle': { en: 'Musical style', fr: 'Style musical' },
  'EventsTable.budget': { en: 'Budget', fr: 'Budget' },
};
