export default {
  'EventDetailsStepFour.budgetForMusician': {
    en: 'Budget for musician',
    fr: 'Budget pour le musicien',
  },
  'EventDetailsStepFour.description': {
    en: 'Please tell us more about your event, your audience, about the repertoire you want. Would you like to tell us anything else? (Optional)',
    fr: "Veuillez nous en dire plus sur votre événement, sur votre public, sur le répertoire que vous souhaitez. Souhaitez-vous nous dire quelque chose d'autre ? (facultatif)",
  },
  'EventDetailsStepFour.totalBudget': {
    en: 'Total budget paid once concert is completed: ',
    fr: 'Budget total payé une fois le concert terminé: ',
  },
  'EventDetailsStepFour.estimatedBudgetDescription': {
    en: 'The amount entered above is what musicians will be paid for this event. The total amount to be charged, (incl. fees and taxes):',
    fr: 'Le montant indiqué ci-dessus correspond à la rémunération des musiciens pour cet événement. Le montant total à facturer, (frais et taxes inclus):',
  },
};
