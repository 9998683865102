export const colors = {
  whiteText: '#FAFAFA',
  violetText: '#4D00AD',
  darkGreyText: '#393939',
  red: '#DF2647',
  purple: 'rgb(22,0,62)',
  darkPuple: 'rgb(11, 0, 33)',
  lightPurple: '#210154',
  white: '#FFFFFF',
  black: '#000000',
  tabOrange: '#FAAA48',
  sendMessageBlue: '#4361EE',
  lightGray: 'rgba(32,32,32,0.1)',
  shadowGradientColor:
    'linear-gradient(92.68deg, #D4BDF4 1.7%, #F0AE26 105.42%)',
  violet: '#1F156A',
  violetGradient: 'linear-gradient(332.33deg, #100029 21.79%, #210154 100%)',
  yellow: '#F0AE26',
  backgroundYellow: '#FFF7D9',
  bottomline: '#00000033',
  buttonBorder: '#A59CEE',
  buttonTextColor: '#5247A8',
  bookerColorFilter:
    'invert(27%) sepia(40%) saturate(1767%) hue-rotate(219deg) brightness(94%) contrast(91%)',
  musicianColorFilter:
    'invert(62%) sepia(97%) saturate(3583%) hue-rotate(324deg) brightness(104%) contrast(94%)',
  primaryColorContainer: '#FFEAEA',
  secondaryColorContainer: '#F6EAFF',
  tableTextColor: '#777777',
  cardColor: '#f7f1ff',

  background: '#fafafa',
  error: '#f76565',
  borderColor: '#EFEFEF',
  blue: '#3654E3',
  grey: '#F2F3F4',
  darkGrey: '#737373',
  lightBlue: '#F7F9FB',
  darkBlue: '#0E0023',
  greyInput: '#A6A6A6',
  lightGreyPlaceholderIput: ' #ABABAB',
  disabledLightGrey: '#D5D5D5',
  disabledDarkGrey: '#565656',
  successGreen: '#4BB543',
};

export default colors;
