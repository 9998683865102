export default {
  'EditActSearchArtistBox.inviteArtistSuccess': {
    en: 'Artist Invited!',
    fr: 'Artiste invité!',
  },
  'EditActSearchArtistBox.inviteArtistErr2': {
    en: 'User does not exists.',
    fr: "L'utilisateur n'existe pas.",
  },
  'EditActSearchArtistBox.inviteArtistErr': {
    en: 'You already invited this artist.',
    fr: 'Vous avez déjà invité cet artiste.',
  },
  'EditActSearchArtistBox.inviteArtist': { en: 'Invite', fr: 'Inviter' },
  'EditActSearchArtistBox.inviteArtistplaceholder': {
    en: 'Stage name or email address',
    fr: 'Nom de la scène ou adresse e-mail',
  },
};
