import * as React from 'react';
import { Box } from 'react-native-kondo';
import styled from 'styled-components';
import colors from '../../constants/colors';
import i18n from '../../constants/i18n';
import { COUNTRY, PROVINCE } from '../../shared';
import { useBookingStore } from '../../stores/booking';
import GiButton from '../common/GiButton';
import GiDropDown from '../common/GiDropDown';
import GiTextInput from '../common/GiTextInput';
import { addRightToolTip } from '../common/ToolTip';
import { GeneralRegularNormal, TextH2, TextH3 } from '../common/Typography';
import { isStringEmptyUndefinedOrNull } from '../../utils/string';

const BookingLocationSelection = ({
  onPressNext,
}: {
  onPressNext: () => void;
}) => {
  const bookingStore = useBookingStore();

  const [eventPlace, setEventPlace] = React.useState(
    typeof bookingStore.isOutDoor === 'boolean'
      ? bookingStore.isOutDoor
        ? 'outdoor'
        : 'indoor'
      : '',
  );
  const [locationAddress, setLocationAddress] = React.useState<string>(
    bookingStore.locationAddress ?? '',
  );
  const [venueName, setVenueName] = React.useState<string>(
    bookingStore.venueName ?? '',
  );
  const [city, setCity] = React.useState<string>(bookingStore.city ?? '');
  const [postalCode, setPostalCode] = React.useState<string>(
    bookingStore.postalCode ?? '',
  );
  const [province, setProvince] = React.useState<{
    label: string;
    value: string;
  }>(
    // @ts-ignore
    bookingStore.province
      ? PROVINCE.find((s) => s.value === bookingStore.province)
      : PROVINCE[0],
  );
  const [country, setCountry] = React.useState<{
    label: string;
    value: string;
  }>(
    // @ts-ignore
    bookingStore.country
      ? COUNTRY.find((s) => s.value === bookingStore.country)
      : COUNTRY[0],
  );

  const [isStageCovered, setIsStageCovered] = React.useState<
    boolean | undefined
  >(
    typeof bookingStore.isStageCovered === 'boolean'
      ? bookingStore.isStageCovered
      : undefined,
  );
  return (
    <Main>
      <TextH2
        style={{
          color: colors.whiteText,
          marginBottom: 36,
          alignSelf: 'center',
        }}
      >
        {i18n('BookingLocationSelection.title', 'Where is your event?')}
      </TextH2>

      <TextH3 style={{ color: colors.whiteText, marginBottom: 16 }}>
        {i18n('BookingLocationSelection.venueName', 'Venue Name')}
      </TextH3>

      <GiTextInput
        value={venueName}
        placeholder="Metropolis"
        onChangeText={(txt) => {
          setVenueName(txt);
          bookingStore.setBookingData({ venueName: txt });
        }}
        style={{ marginBottom: 48 }}
      />

      <TextH3 style={{ color: colors.whiteText, marginBottom: 16 }}>
        {i18n('BookingLocationSelection.address', 'Address *')}
      </TextH3>
      <GiTextInput
        value={locationAddress}
        placeholder="123 Address Street"
        onChangeText={(txt) => {
          setLocationAddress(txt);
          bookingStore.setBookingData({ locationAddress: txt });
        }}
        style={{ marginBottom: 48 }}
      />

      <Box flexDirection="row">
        <Box flex={1}>
          <TextH3 style={{ color: colors.whiteText, marginBottom: 16 }}>
            {i18n('BookingLocationSelection.city', 'City *')}
          </TextH3>
          <GiTextInput
            value={city}
            placeholder="Montreal"
            onChangeText={(txt) => {
              setCity(txt);
              bookingStore.setBookingData({ city: txt });
            }}
            style={{ marginBottom: 48 }}
          />
        </Box>
        <Box width={20} />
        <Box flex={1}>
          <TextH3 style={{ color: colors.whiteText, marginBottom: 16 }}>
            {i18n('BookingLocationSelection.postalCode', 'Postal Code *')}
          </TextH3>
          <GiTextInput
            value={postalCode}
            placeholder="A1B 2C3"
            mask="a9a 9a9"
            onChangeText={(txt) => {
              setPostalCode(txt);
              bookingStore.setBookingData({ postalCode: txt });
            }}
            style={{ marginBottom: 48 }}
          />
        </Box>
      </Box>

      <Box flexDirection="row">
        <Box flex={1}>
          <GiDropDown
            label={i18n('BookingLocationSelection.province', 'Province *')}
            style={{ marginBottom: 48, width: '100%' }}
            items={PROVINCE}
            value={province.label}
            onChange={(item) => {
              setProvince(item);
              bookingStore.setBookingData({
                province: item.value,
              });
            }}
          />
        </Box>
        <Box width={20} />

        <Box flex={1}>
          <GiDropDown
            label={i18n('BookingLocationSelection.country', 'Country *')}
            style={{ marginBottom: 48, width: '100%' }}
            items={COUNTRY}
            value={country.label}
            onChange={(item) => {
              setCountry(item);
              bookingStore.setBookingData({
                country: item.value,
              });
            }}
          />
        </Box>
      </Box>

      <TextH3 style={{ color: colors.whiteText, marginBottom: 16 }}>
        {i18n(
          'BookingLocationSelection.indoorOrOutdoor',
          'Is your event indoor or outdoor? *',
        )}
      </TextH3>
      <Box flexDirection="row" mb={48}>
        <ToggleButton
          isSelected={eventPlace === 'indoor'}
          label={i18n('BookingLocationSelection.indoor', 'Indoor')}
          onClick={() => {
            setEventPlace('indoor');
            bookingStore.setBookingData({
              isOutDoor: false,
            });
          }}
          style={{ flex: 1 }}
        />
        <Box width={8} />
        <ToggleButton
          isSelected={eventPlace === 'outdoor'}
          label={i18n('BookingLocationSelection.outdoor', 'Outdoor')}
          onClick={() => {
            setEventPlace('outdoor');
            bookingStore.setBookingData({
              isOutDoor: true,
            });
          }}
          style={{ flex: 1 }}
        />
      </Box>

      {eventPlace === 'outdoor' && (
        <>
          {addRightToolTip({
            leftNode: (
              <TextH3
                style={{
                  color: colors.whiteText,
                  marginBottom: 16,
                }}
              >
                {i18n(
                  'BookingLocationSelection.covered',
                  'Will the stage be covered? *',
                )}
              </TextH3>
            ),
            toolTip: i18n(
              'BookingLocationSelection.whyIsThisImportant',
              'Why is this important? Many instruments are sensitive to weather conditions, including rain, excessive heat, and cold. Having a covered outdoor area, although not always necessary, can greatly reduce the risk of damage to instruments and poor sound quality.',
            ),
          })}
          <Box flexDirection="row">
            <ToggleButton
              isSelected={isStageCovered === true}
              label={i18n('BookingLocationSelection.yes', 'Yes')}
              onClick={() => {
                setIsStageCovered(true);
                bookingStore.setBookingData({
                  isStageCovered: true,
                });
              }}
              style={{ flex: 1 }}
            />
            <Box width={8} />
            <ToggleButton
              isSelected={isStageCovered === false}
              label={i18n('BookingLocationSelection.no', 'No')}
              onClick={() => {
                setIsStageCovered(false);
                bookingStore.setBookingData({
                  isStageCovered: false,
                });
              }}
              style={{ flex: 1 }}
            />
          </Box>
        </>
      )}

      <GiButton
        label={i18n('BookingLocationSelection.next', 'Next')}
        style={{ alignSelf: 'center', marginTop: 48 }}
        disabled={
          (eventPlace === 'outdoor'
            ? isStringEmptyUndefinedOrNull(eventPlace) ||
              typeof isStageCovered === 'undefined'
            : isStringEmptyUndefinedOrNull(eventPlace)) ||
          !locationAddress ||
          !city ||
          !postalCode ||
          !province ||
          !country
        }
        onClick={onPressNext}
      />
    </Main>
  );
};

const ToggleButton = ({
  label,
  isSelected,
  onClick,
  style = {},
}: {
  label: string;
  isSelected: boolean;
  onClick: () => void;
  style?: React.CSSProperties;
}) => {
  return (
    <ToggleContainer isSelected={isSelected} onClick={onClick} style={style}>
      <GeneralRegularNormal style={{ color: colors.whiteText }}>
        {label}
      </GeneralRegularNormal>
    </ToggleContainer>
  );
};

const ToggleContainer = styled.div<{ isSelected: boolean }>`
  display: flex;
  box-sizing: border-box;
  justify-content: center;
  align-items: center;
  height: 46px;
  border-radius: 2px;
  background-color: ${(props) => (props.isSelected ? '#4361EE' : '#0E0023')};
  cursor: pointer;
  @media (max-width: ${(props) => props.theme.breakPoints.mobile}px) {
  }
`;

const Main = styled.div`
  display: flex;
  box-sizing: border-box;
  flex-direction: column;
  align-self: center;
  padding-bottom: 158px;
  width: 100%;
  @media (max-width: ${(props) => props.theme.breakPoints.mobile}px) {
    padding: 0px 20px;
  }
`;

export default BookingLocationSelection;
