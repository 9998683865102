export default {
  'SignInController.title': {
    en: 'Welcome back to GigLinked!',
    fr: 'Bienvenue sur GigLinked !',
  },
  'SignInController.underTitle': { en: 'Sign-in', fr: "S'inscrire" },
  'SignInController.linkOnCreateAccount': {
    en: "Don't have an account?",
    fr: "Vous n'avez pas de compte?",
  },
  'SignInController.linkCreateAccount': {
    en: 'Create an account',
    fr: 'Créer un compte',
  },
  'SignInController.email': { en: 'Email', fr: 'Courriel' },
  'SignInController.password': { en: 'Password', fr: 'Mot de passe' },
  'SignInController.forgot': {
    en: 'Forgot password',
    fr: 'Mot de passe oublié',
  },
  'SignInController.go': { en: 'Go!', fr: 'Allez!' },
};
