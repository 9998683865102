import { observer } from 'mobx-react';
import * as React from 'react';
import { Outlet, Navigate, useLocation } from 'react-router-dom';
import { UseUserStore } from '../../stores/user';

const PerformerRouteAuthCheck = observer(() => {
  const location = useLocation();
  const { getMe } = UseUserStore();
  const me = getMe();

  if (!me?.artist) {
    return (
      <Navigate to="/?performerLogin=1" state={{ from: location }} replace />
    );
  }

  return <Outlet />;
});

export default PerformerRouteAuthCheck;
