import { observer } from 'mobx-react-lite';
import React, { Dispatch, SetStateAction } from 'react';
import styled from 'styled-components';
import i18n from '../../../constants/i18n';
import images from '../../../constants/images';
import { useUiStore } from '../../../stores/ui';
import { UseUserStore } from '../../../stores/user';
import GiLinksDropDown from '../../common/GiLinksDropDown';
import useGetDropDownsOptions from '../../../utils/useGetDropDownsOptions';
import { useLocation, useNavigate } from 'react-router-dom';
import GiglinkedButtonSignUpIn from '../../common/GiglinkedButtonSignUpIn';
import NavBarContentView from './NavBarContentView';
import NavBarLanguage from './NavBarLanguage';
import { SignUpStep } from '../../../shared';
import GiglinkedButton from '../../common/GiglinkedButton';
import { replaceProfileImage } from '../../../utils/media';

const NavBarContent = observer(
  ({
    setIsDrawerShown,
  }: {
    setIsDrawerShown: Dispatch<SetStateAction<boolean>>;
  }) => {
    const location = useLocation();
    const navigate = useNavigate();
    const userStore = UseUserStore();
    const { routerDropDownOptions } = useGetDropDownsOptions();
    const me = userStore.getMe();
    const isArtist = me?.artist;
    const { isMobile } = useUiStore();
    const { setSignUpStep } = userStore;
    const isLoggedIn = !!me;

    /* -- Mobile NavBar -- */
    if (isMobile) {
      return (
        <ImgProfileOrHamburgerBox
          onClick={() => {
            setIsDrawerShown(true);
          }}
        >
          {isLoggedIn ? (
            <ProfilePicture
              src={replaceProfileImage(me.profilePicture)}
              referrerPolicy="no-referrer"
            />
          ) : (
            <Hamburger src={images.hamburgerButtonDark} />
          )}
        </ImgProfileOrHamburgerBox>
      );
    }

    /* -- Desktop exceptions -- */
    const showLandingButtons = ['/'].includes(location.pathname);

    if (showLandingButtons) {
      return (
        <Main>
          {isLoggedIn ? (
            <>
              <NavBarBox>
                <NavBarContentView onClose={() => setIsDrawerShown(false)} />
                <GiglinkedButton
                  onClick={
                    isArtist
                      ? () => navigate(`/artists/${userStore.me?.artist?.id}`)
                      : () => navigate(`/dashboard`)
                  }
                  label={
                    isArtist
                      ? i18n('NavBar.buttonProfile', 'My profile')
                      : i18n('NavBar.dashboard', 'Dashboard')
                  }
                  style={{ height: 40 }}
                />
                <NavBarLanguage />
              </NavBarBox>
            </>
          ) : (
            <NavBarBox>
              <NavBarContentView onClose={() => setIsDrawerShown(false)} />

              <ButtonsBox>
                <GiglinkedButtonSignUpIn
                  style={{ marginRight: 5, borderColor: '#fff' }}
                  label={i18n('NavBarContent.signup', 'Sign up')}
                  onClick={() => {
                    navigate(`/signup`);
                    setSignUpStep(SignUpStep.RegistrationUser);
                  }}
                />
                <GiglinkedButtonSignUpIn
                  label={i18n('RegistrationController.login', 'Log in')}
                  onClick={() => navigate(`/signin`)}
                />
              </ButtonsBox>

              <NavBarLanguage />
            </NavBarBox>
          )}
        </Main>
      );
    }

    /* -- Desktop NavBar -- */
    if (isLoggedIn) {
      return (
        <div
          style={{
            placeSelf: 'end',
          }}
        >
          <NavBarBox>
            <NavBarContentView onClose={() => setIsDrawerShown(false)} />

            <GiglinkedButton
              onClick={
                isArtist
                  ? () => navigate(`/artists/${userStore.me?.artist?.id}`)
                  : () => navigate(`/dashboard`)
              }
              label={
                isArtist
                  ? i18n('NavBar.buttonProfile', 'My profile')
                  : i18n('NavBar.dashboard', 'Dashboard')
              }
              style={{ height: 40 }}
            />
            <GiLinksDropDown
              label={
                <ProfilePicture
                  src={replaceProfileImage(me?.profilePicture)}
                  referrerPolicy="no-referrer"
                />
              }
              hideDownArrow={true}
              items={routerDropDownOptions}
            />
          </NavBarBox>
        </div>
      );
    } else {
      return (
        <Main>
          <NavBarBox>
            <NavBarContentView onClose={() => setIsDrawerShown(false)} />
            <ButtonsBox>
              <GiglinkedButtonSignUpIn
                inverted
                style={{ marginRight: 5, borderColor: '#fff' }}
                label={i18n('NavBarContent.signup', 'Sign up')}
                onClick={() => {
                  setSignUpStep(SignUpStep.RegistrationUser);
                  navigate(`/signup`);
                }}
              />
              <GiglinkedButtonSignUpIn
                inverted
                label={i18n('RegistrationController.login', 'Log in')}
                onClick={() => navigate(`/signin`)}
              />
            </ButtonsBox>
            <NavBarLanguage />
          </NavBarBox>
          {isMobile && (
            <GiLinksDropDown
              label={<Hamburger src={images.hamburgerButton} />}
              hideDownArrow={true}
              items={routerDropDownOptions}
            />
          )}
        </Main>
      );
    }
  },
);

const Main = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
`;

const NavBarBox = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const Hamburger = styled.img`
  width: 30px;
  height: 28px;
  cursor: pointer;
  transition: opacity 0.2s ease;
  margin-right: 40px;
  &:hover {
    filter: brightness(115%);
  }
  @media (max-width: ${(props) => props.theme.breakPoints.tablet}px) {
    margin-right: 40px;
  }
  @media (max-width: ${(props) => props.theme.breakPoints.mobile}px) {
    margin-right: 1rem;
  }
`;

const ProfilePicture = styled.img`
  width: 50px;
  height: 50px;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 80px;
  @media (max-width: ${(props) => props.theme.breakPoints.mobile}px) {
    width: 40px;
    height: 40px;
    margin-right: 1rem;
  }
`;

const ImgProfileOrHamburgerBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: end;
  cursor: pointer;
`;

const ButtonsBox = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
`;

export default NavBarContent;
