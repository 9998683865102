import { observer } from 'mobx-react';
import * as React from 'react';
import toast from 'react-hot-toast';
import styled from 'styled-components';
import i18n from '../../../constants/i18n';
import { Artist } from '../../../shared';
import { useArtistStore } from '../../../stores/artist';
import { UseUserStore } from '../../../stores/user';
import GiSeparator from '../../common/GiSeparator';
import GiSwitch from '../../common/GiSwitch';
import { TextH6 } from '../../common/Typography';

const EditArtistProfileOverviewAvailability = observer(() => {
  const artistStore = useArtistStore();
  const userStore = UseUserStore();
  const me = userStore.getMe();

  const setLocalValAFn = React.useRef<any>(null);
  const setLocalValBFn = React.useRef<any>(null);

  const onSave = async (payload: Partial<Artist>) => {
    try {
      if (!me?.artist?.stripe?.isAccountConnected) {
        toast.error(
          i18n(
            'ArtistReminders.rememberToUpdateYourBankingInfo',
            'Remember to update your banking info to receive direct payments (Powered by Stripe)',
          ),
          {
            duration: 6000,
            icon: '❗️',
          },
        );

        // setTimeout(() => {
        //   if (setLocalValAFn.current) {
        //     setLocalValAFn.current(false);
        //   }

        //   if (setLocalValBFn.current) {
        //     setLocalValBFn.current(false);
        //   }
        // }, 700);

        // return;
      }

      await artistStore.editMeArtist(payload);
    } catch (e) {
      const err = e as Error;
      toast.error(err.message);
    }
  };

  return (
    <Main>
      <TextH6 style={{ marginBottom: 12 }}>
        {i18n('EditArtistProfileOverview.availability', 'Availability')}
      </TextH6>

      <GiSwitch
        label={i18n('EditArtistProfileOverview.availabilityGig', 'Gigs')}
        style={{ marginBottom: 13 }}
        value={me!.artist.availability.gigs}
        setLocalValFn={(fn: any) => (setLocalValAFn.current = fn)}
        onClick={(gigs) =>
          onSave({
            // @ts-ignore
            availability: {
              gigs,
            },
          })
        }
      />

      <GiSwitch
        label={i18n(
          'EditArtistProfileOverview.availabilityCollab',
          'Collaborations',
        )}
        style={{ marginBottom: 24 }}
        value={me!.artist.availability.collaborations}
        setLocalValFn={(fn: any) => (setLocalValBFn.current = fn)}
        onClick={(collaborations) =>
          onSave({
            // @ts-ignore
            availability: {
              collaborations,
            },
          })
        }
      />
      <GiSeparator style={{ marginBottom: 24 }} />
    </Main>
  );
});

const Main = styled.div`
  display: flex;
  flex-direction: column;
  @media (max-width: ${(props) => props.theme.breakPoints.mobile}px) {
  }
`;

export default EditArtistProfileOverviewAvailability;
