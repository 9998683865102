import { observer } from 'mobx-react';
import * as React from 'react';
import { CompanyTypeEnums, EventType } from '../../shared';
import styled from 'styled-components';
import i18n from '../../constants/i18n';
import {
  GeneralRegularNormal,
  GeneralSemiBoldLarge,
  TextH4,
  TextH6,
} from '../common/Typography';
import GiglinkedButton from '../common/GiglinkedButton';
import { useUiStore } from '../../stores/ui';
import ApplyToEventModal from './ApplyToEventModal';
import { Column, Row } from '../common/Layout';
import colors from '../../constants/colors';
import { format, formatDistanceToNow, subHours } from 'date-fns';
import images from '../../constants/images';
import { addDollarSign, capitalize } from '../../utils/string';
import ToolTip from '../common/ToolTip';
import { getEventStatus } from '../../utils/eventUtils';

const MusicianNewEventCard = observer(({ event }: { event: EventType }) => {
  const uiStore = useUiStore();
  const [isExpandedId, setIsExpandedId] = React.useState<string | null>(null);

  const currentEventStatus = getEventStatus(event);
  const eventIsDone = currentEventStatus === 'completed';

  return (
    <Container key={event.id}>
      <Row>
        <div style={{ width: uiStore.isMobile ? '100%' : '70%' }}>
          <GeneralSemiBoldLarge
            style={{
              color: colors.black,
              fontWeight: 400,
            }}
          >
            {event?.companyType &&
              event.companyType !== CompanyTypeEnums.ForMyself && (
                <>{capitalize(event.companyType) + ' - '}</>
              )}
            {capitalize(event.name)}
          </GeneralSemiBoldLarge>
        </div>
        <Column
          style={{
            width: uiStore.isMobile ? '100%' : '20%',
            textAlign: 'right',
            marginRight: '1rem',
          }}
        >
          <TextH6
            style={{
              color: colors.tableTextColor,
              fontSize: '10px',
              margin: 0,
            }}
          >
            {i18n('MusicianNewEventCard.approxBudget', 'Approx budget')}
          </TextH6>
          <TextH4
            style={{
              color: colors.black,
              margin: 0,
            }}
          >
            {addDollarSign(String(event.estimatedBudget))}
          </TextH4>
        </Column>
        <Column style={{ width: uiStore.isMobile ? '100%' : '10%' }}>
          {eventIsDone && (
            <ToolTip
              text={i18n(
                'MusicianNewEventCard.eventIsDone',
                'This event is completed. You cannot apply to it.',
              )}
              mainStyle={{
                placeSelf: 'end',
                zIndex: 9,
              }}
            />
          )}
          <GiglinkedButton
            label={i18n('MusicianNewEventCard.apply', 'Apply')}
            style={{
              marginTop: eventIsDone ? '-6px' : 0,
            }}
            inverted
            disabled={eventIsDone}
            onClick={() => {
              uiStore.openPopup({
                popupDisableTapOut: true,
                renderContent: () =>
                  event && <ApplyToEventModal event={event} />,
              });
            }}
          />
        </Column>
      </Row>

      <Divider />

      <StyledRow>
        <GeneralRegularNormal
          style={{ width: uiStore.isMobile ? '100%' : '20%' }}
        >
          <img src={images.locationIcon} /> <SmallBr />
          {capitalize(event.city)},{' '}
          {event.postalCode.substring(0, 3).toUpperCase()}
        </GeneralRegularNormal>
        <GeneralRegularNormal
          style={{ width: uiStore.isMobile ? '100%' : '20%' }}
        >
          <img src={images.dateIcon} /> <SmallBr />
          {format(new Date(event.eventDate), 'PPP')}
        </GeneralRegularNormal>
        <GeneralRegularNormal
          style={{ width: uiStore.isMobile ? '100%' : '30%' }}
        >
          <img src={images.timeIcon} /> <SmallBr />
          {format(new Date(event.startTime), 'hh:mm a')} -{' '}
          {format(new Date(event.endTime), 'hh:mm a')}
        </GeneralRegularNormal>
        <GeneralRegularNormal
          style={{ width: uiStore.isMobile ? '100%' : '20%' }}
        >
          <img src={images.arrivalIcon} /> <SmallBr />
          {i18n('MusicianNewEventCard.arrival', 'Arrival ')}
          {format(new Date(subHours(new Date(event.startTime), 1)), 'hh:mm a')}
        </GeneralRegularNormal>
        <GeneralRegularNormal
          style={{ width: uiStore.isMobile ? '100%' : '10%' }}
        >
          <img src={images.withVocalsIcon} /> <SmallBr />
          {capitalize(event.withVocals)}
        </GeneralRegularNormal>
      </StyledRow>

      <StyledRow>
        <GeneralRegularNormal
          style={{
            width: uiStore.isMobile ? '100%' : '20%',
            textTransform: 'capitalize',
          }}
        >
          <img src={images.companyIcon} /> <SmallBr />
          {event.companyType}
        </GeneralRegularNormal>
        <GeneralRegularNormal
          style={{
            width: uiStore.isMobile ? '100%' : '20%',
            textTransform: 'capitalize',
          }}
        >
          <img src={images.styleIcon} /> <SmallBr />
          {event.style}
        </GeneralRegularNormal>

        <GeneralRegularNormal
          style={{ width: uiStore.isMobile ? '100%' : '30%' }}
        >
          <img src={images.equipmentIcon} /> <SmallBr />
          {i18n('MusicianNewEventCard.equipment', 'Equipment ')}
          {event.equipment}
        </GeneralRegularNormal>

        <GeneralRegularNormal style={{ width: '20%' }}>
          <img src={images.outdoorIcon} /> <SmallBr />
          {event.isOutdoor ? (
            <>
              {i18n('MusicianNewEventCard.outdoor', 'Outdoor ')}
              {event.isStageCovered
                ? i18n('MusicianNewEventCard.covered', 'covered ')
                : i18n('MusicianNewEventCard.uncovered', 'uncovered ')}
            </>
          ) : (
            i18n('MusicianNewEventCard.indoor', 'Indoor ')
          )}
        </GeneralRegularNormal>
      </StyledRow>

      {event.description && isExpandedId === event.id && (
        <DescriptionContainer>
          <img
            src={images.detailsIcon}
            style={{ marginRight: '1rem' }}
            alt="comment image"
          />
          {event.description}
        </DescriptionContainer>
      )}

      <div
        style={{
          display: 'flex',
          flexDirection: uiStore.isMobile ? 'column' : 'row',
          justifyContent: 'space-between',
        }}
      >
        <div>
          {event.description &&
            (isExpandedId === event.id ? (
              <GeneralRegularNormal
                onClick={() => setIsExpandedId(null)}
                style={{
                  color: colors.buttonTextColor,
                  textDecoration: 'underline',
                  cursor: 'pointer',
                  marginRight: '1rem',
                }}
              >
                {i18n('MusicianNewEventCard.lessDetails', 'Less details')}
              </GeneralRegularNormal>
            ) : (
              <GeneralRegularNormal
                onClick={() => setIsExpandedId(event.id)}
                style={{
                  color: colors.buttonTextColor,
                  textDecoration: 'underline',
                  cursor: 'pointer',
                  marginRight: '1rem',
                }}
              >
                {i18n('MusicianNewEventCard.moreDetails', 'More details')}
              </GeneralRegularNormal>
            ))}
        </div>
        <div>
          <GeneralRegularNormal
            style={{ color: colors.tableTextColor, fontSize: '14px' }}
          >
            {i18n('MusicianNewEventCard.created', 'Created:')}
            <SmallBr />
            {formatDistanceToNow(new Date(event.createdAt), {
              addSuffix: true,
            })}

            <Br />
            {i18n('MusicianNewEventCard.proposals', 'Proposals:')}
            <SmallBr />
            {event.proposals?.length}
          </GeneralRegularNormal>
        </div>
      </div>
    </Container>
  );
});

const Container = styled.div`
  align-self: center;
  border-radius: 30px;
  padding: 30px;
  width: 900px;
  background: ${(props) => props.theme.colors.cardColor};
  margin-top: 30px;
  @media (max-width: ${(props) => props.theme.breakPoints.mobile}px) {
    width: 100%;
    height: 100%;
    padding: 1rem;
  }
`;

const DescriptionContainer = styled.div`
  width: 100%;
  padding: 1rem;
  border-radius: 25px;
  border: 1px;
  border: 1px solid #a59cee;
  font-family: ${(props) => props.theme.fonts.GeneralSans};
`;

const Br = styled.span`
  padding-right: 2rem;
`;

const SmallBr = styled.span`
  padding-right: 0.25rem;
`;

const StyledRow = styled.div`
  display: flex;
  flex-direction: row;
  margin: 1rem 0;
  @media (max-width: ${(props) => props.theme.breakPoints.mobile}px) {
    flex-direction: column;
    min-width: 200px;
  }
`;

const Divider = styled.div`
  height: 1px;
  background-color: #a59cee;
  width: 100%;
  margin: 0.5rem 0;
`;

export default MusicianNewEventCard;
