import { observer } from 'mobx-react';
import * as React from 'react';
import styled from 'styled-components';
import { useNavigate, useLocation } from 'react-router-dom';
import { UseUserStore } from '../../../stores/user';
import i18n from '../../../constants/i18n';
import sexpressClient from '../../../utils/sexpress';
import GiglinkedTextInput from '../../common/GiglinkedTextInput';
import GiglinkedButton from '../../common/GiglinkedButton';
import GiAuthContainer from '../../common/GiAuthContainer';
import toast from 'react-hot-toast';

const ResetPassword = observer(() => {
  const { shapeUser, postAuthActions } = UseUserStore();
  const navigate = useNavigate();
  const location = useLocation();

  const [loading, setLoading] = React.useState(false);
  const [newPassword, setNewPassword] = React.useState('');
  const [newPasswordConfirmation, setNewPasswordConfirmation] =
    React.useState('');

  const validNewPassword =
    !!newPassword && newPassword === newPasswordConfirmation;

  const verificationKey = location.pathname.split(/[/]+/).pop();

  const onClickSubmit = async () => {
    try {
      setLoading(true);
      const res = await sexpressClient.resetPassword({
        verificationKey,
        newPassword,
        newPasswordConfirmation,
      });

      shapeUser(res);
      await postAuthActions();

      navigate('/performers');
    } catch (e) {
      const err = e as Error;
      toast.error(err.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <GiAuthContainer
      title={i18n('ResetPassword.resetYourPassword', 'Reset your password')}
      secondaryText={i18n(
        'ResetPassword.resetYourPasswordDescription',
        'Enter your new password and confirm it',
      )}
      editableContent={
        <>
          <MainBox>
            <GiglinkedTextInput
              placeholder={i18n('ResetPassword.newPassword', 'New password')}
              style={{ marginBottom: 30 }}
              type="password"
              value={newPassword}
              onChangeText={setNewPassword}
              showCheckMark={validNewPassword}
            />
            <GiglinkedTextInput
              placeholder={i18n(
                'ResetPassword.confirmNewPassword',
                'Confirm new password',
              )}
              style={{
                marginBottom: 30,
              }}
              type="password"
              value={newPasswordConfirmation}
              onChangeText={setNewPasswordConfirmation}
              showCheckMark={validNewPassword}
            />
            <GiglinkedButton
              label={i18n('SupportScreen.submit', 'Submit')}
              style={{
                alignSelf: 'center',
                width: '100%',
              }}
              onClick={onClickSubmit}
              loading={loading}
              disabled={!validNewPassword}
            />
          </MainBox>
        </>
      }
    />
  );
});

const MainBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 90%;
  @media (max-width: ${(props) => props.theme.breakPoints.mobile}px) {
    padding-left: 20px;
    padding-right: 20px;
  }
`;

export default ResetPassword;
