import React from 'react';
import i18n from '../../../../../constants/i18n';
import StepperRegistrationGroupTypeSelect from './StepperComponents/StepperRegistrationGroupTypeSelect';
import { useNavigate } from 'react-router-dom';
import StepperRegistrationInstruments from './StepperComponents/StepperRegistrationInstruments';
import StepperRegistrationEventTypes from './StepperComponents/StepperRegistrationEventTypes';
import StepperRegistrationPlayStyles from './StepperComponents/StepperRegistrationPlayStyles';
import RegistrationMusicianUploadPicture from './StepperComponents/StepperRegistrationMusicianUploadPicture';
import StepperRegistrationSoloAbout from './StepperComponents/StepperRegistrationSoloAbout';
import { observer } from 'mobx-react';
import StepperRegistrationGroupAbout from './StepperComponents/StepperRegistrationGroupAbout';
import { UseUserStore } from '../../../../../stores/user';
import { useArtistStore } from '../../../../../stores/artist';
import { useActStore } from '../../../../../stores/act';
import { logPerformerAddedProfileTag } from '../../../../../utils/amplitude';
import { SignUpStep } from '../../../../../shared';
import RegistrationUser from '../../RegistrationUser';
import toast from 'react-hot-toast';

const RegistrationStepper = observer(() => {
  const userStore = UseUserStore();
  const navigate = useNavigate();
  const artistStore = useArtistStore();
  const actStore = useActStore();
  const { signUpStep, setSignUpStep } = userStore;
  const [loading, setLoading] = React.useState<boolean>(false);
  const [location, setLocation] = React.useState<string>('');

  const me = userStore.getMe();

  const [stageName, setStageName] = React.useState(
    me && me!.firstName && me!.lastName
      ? `${me!.firstName} ${me!.lastName}`
      : '',
  );
  const [firstName, setFirstName] = React.useState<string>('');
  const [lastName, setLastName] = React.useState<string>('');
  const [tags, setTags] = React.useState<string[]>([]);
  const [actName, setActName] = React.useState(
    me && me!.firstName && me!.lastName
      ? `${me!.firstName} ${me!.lastName} Band`
      : '',
  );

  const clearInputs = () => {
    setLocation('');
    setActName('');
    setStageName('');
    setFirstName('');
    setLastName('');
  };

  const nonStepperStep = {
    component: <RegistrationUser />,
    progress: 0,
    // eslint-disable-next-line
    next: () => {},
  };

  const validations = () => {
    if (typeof firstName === 'string' && !firstName.length) {
      throw new Error(
        i18n(
          'SettingsScreen.errorFirstName',
          'Your first name cannot be blank.',
        ),
      );
    }

    if (typeof lastName === 'string' && !lastName.length) {
      throw new Error(
        i18n('SettingsScreen.errorLastName', 'Your last name cannot be blank.'),
      );
    }

    if (typeof stageName === 'string' && !stageName.length) {
      throw new Error(
        i18n(
          'SettingsScreen.errorStageName',
          'Your stage name cannot be blank. But it can be your first and last name.',
        ),
      );
    }

    if (typeof location === 'string' && !location.length) {
      throw new Error(
        i18n('SettingsScreen.errorLocation', 'Your location cannot be blank.'),
      );
    }
  };

  const handleSoloArtist = async () => {
    if (loading) return;
    try {
      validations();
      setLoading(true);
      setSignUpStep(SignUpStep.StepperRegistrationGroupTypeSelect);
      await artistStore.editMeArtist({
        name: stageName ?? me?.firstName + ' ' + me?.lastName,
        location,
      });

      await userStore.editMe({ firstName, lastName, location });
      await userStore.fetchMe();
      clearInputs();
    } catch (e) {
      const err = e as Error;
      toast.error(err.message);
    } finally {
      setLoading(false);
    }
  };

  const handleGroup = async () => {
    if (loading) return;
    try {
      validations();
      setLoading(true);
      setSignUpStep(SignUpStep.StepperRegistrationGroupTypeSelect);
      await userStore.editMe({ firstName, lastName, location });
      await artistStore.editMeArtist({
        location,
      });
      const act = await actStore.initAct();
      await actStore.editMeAct({
        name: actName,
        actId: act.id,
      });
      await userStore.fetchMe();
      clearInputs();
    } catch (e) {
      const err = e as Error;
      toast.error(err.message);
    } finally {
      setLoading(false);
    }
  };

  const handleTagsStep = async (nextStep: SignUpStep) => {
    if (loading) return;
    try {
      setSignUpStep(nextStep);
      await Promise.all(
        tags.map(async (tagLabel) => {
          await artistStore.editTags({ label: tagLabel });
          logPerformerAddedProfileTag({ tagLabel });
        }),
      );
      setTags([]);
    } catch (e) {
      const err = e as Error;
      toast.error(err.message);
    } finally {
      setLoading(false);
    }
  };

  const stepsMapping = {
    [SignUpStep.StepperRegistrationSoloAbout]: {
      component: (
        <StepperRegistrationSoloAbout
          setFirstName={setFirstName}
          setLastName={setLastName}
          setStageName={setStageName}
          location={location}
          setLocation={setLocation}
          progress={1}
          next={handleSoloArtist}
        />
      ),
    },
    [SignUpStep.StepperRegistrationGroupAbout]: {
      component: (
        <StepperRegistrationGroupAbout
          setFirstName={setFirstName}
          setLastName={setLastName}
          setActName={setActName}
          location={location}
          setLocation={setLocation}
          progress={1}
          next={handleGroup}
        />
      ),
    },
    [SignUpStep.StepperRegistrationGroupTypeSelect]: {
      component: (
        <StepperRegistrationGroupTypeSelect
          tags={tags}
          setTags={setTags}
          progress={2}
          next={() => handleTagsStep(SignUpStep.StepperRegistrationInstruments)}
        />
      ),
    },
    [SignUpStep.StepperRegistrationInstruments]: {
      component: (
        <StepperRegistrationInstruments
          tags={tags}
          setTags={setTags}
          progress={3}
          next={() => handleTagsStep(SignUpStep.StepperRegistrationPlayStyles)}
        />
      ),
    },
    [SignUpStep.StepperRegistrationPlayStyles]: {
      component: (
        <StepperRegistrationPlayStyles
          tags={tags}
          setTags={setTags}
          progress={4}
          next={() => handleTagsStep(SignUpStep.StepperRegistrationEventTypes)}
        />
      ),
    },
    [SignUpStep.StepperRegistrationEventTypes]: {
      component: (
        <StepperRegistrationEventTypes
          tags={tags}
          setTags={setTags}
          progress={5}
          next={() =>
            handleTagsStep(SignUpStep.StepperRegistrationMusicianUploadPicture)
          }
        />
      ),
    },
    [SignUpStep.StepperRegistrationMusicianUploadPicture]: {
      component: (
        <RegistrationMusicianUploadPicture
          progress={6}
          next={() => {
            navigate(`/artists/${userStore.me?.artist.id}`);
            setSignUpStep(SignUpStep.RegistrationUser);
          }}
        />
      ),
    },
    [SignUpStep.RegistrationUser]: nonStepperStep,
    [SignUpStep.RegistrationMusicianOrClient]: nonStepperStep,
    [SignUpStep.RegistrationMusicianSoloOrGroup]: nonStepperStep,
    [SignUpStep.RegistrationBookerTellAboutYourself]: nonStepperStep,
    [SignUpStep.RegistrationBookerLocation]: nonStepperStep,
    [SignUpStep.RegistrationBookerWaysToBook]: nonStepperStep,
  };

  return stepsMapping[signUpStep].component;
});

export default RegistrationStepper;
