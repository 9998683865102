export default {
  'RegistrationBookerTellAboutYourself.title': {
    en: 'Tell us about yourself',
    fr: 'Parlez-nous de vous',
  },
  'RegistrationBookerTellAboutYourself.optional': {
    en: 'This is optional, but will allow us to help you connect with the right musicians.',
    fr: 'Cette information est facultative, mais elle nous permettra de vous aider à entrer en contact avec les bons musiciens.',
  },
  'RegistrationBookerTellAboutYourself.company': {
    en: 'Company (Optional)',
    fr: 'Société (Facultatif)',
  },
  'RegistrationBookerTellAboutYourself.role': {
    en: 'Role (Optional)',
    fr: 'Role (Facultatif)',
  },
  'RegistrationBookerTellAboutYourself.city': { en: 'City', fr: 'Ville' },
  'RegistrationBookerTellAboutYourself.next': { en: 'Next', fr: 'Suivant' },
  'RegistrationBookerTellAboutYourself.errorOrganizationBookingDetails': {
    en: "Please, let us know who you're booking for.",
    fr: 'Veuillez nous indiquer pour qui vous réservez.',
  },
  'RegistrationBookerTellAboutYourself.errorOrganizationBookingName': {
    en: 'Please, let us know the name of your company.',
    fr: 'Veuillez nous indiquer le nom de votre entreprise.',
  },
};
