import { observer } from 'mobx-react';
import * as React from 'react';
import styled from 'styled-components';
import i18n from '../../constants/i18n';
import images from '../../constants/images';
import { useUiStore } from '../../stores/ui';
import { TextH2 } from '../common/Typography';
import EditNavbar from '../common/EditNavbar';
import { useActStore } from '../../stores/act';
import { useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';

const getActId = () => {
  const url = window.location.pathname;
  const cleaned = url.replace('/me/acts/', '');
  const actId = cleaned.substring(0, cleaned.lastIndexOf('/'));
  return actId;
};

const EditActsProfileLayout = observer(
  ({
    children,
    title = i18n('EditActsProfileLayout.title', 'Manage Act'),
  }: {
    children?: any;
    title?: string;
  }) => {
    const uiStore = useUiStore();
    const navigate = useNavigate();
    const actStore = useActStore();
    const act = actStore.getCurrentEditAct();

    const fetchActData = React.useCallback(async () => {
      await actStore.fetchAct({ actId: getActId() });
    }, []);

    React.useEffect(() => {
      const fetchAct = async () => {
        if (!act || (act && act.id !== getActId())) {
          try {
            await fetchActData();
          } catch (e) {
            const err = e as Error;
            toast.error(err.message);
          }
        }
      };
      fetchAct();
    }, []);

    if (!act || (act && act.id !== getActId())) {
      return <div />;
    }

    return (
      <Main>
        <Row>
          <EditNavbar
            navBarItems={[
              {
                label: i18n('EditActsProfileLayout.overview', 'OVERVIEW'),
                route: `/me/acts/${act.id}/overview`,
              },
              {
                label: i18n('EditActsProfileLayout.audio', 'AUDIO'),
                route: `/me/acts/${act.id}/songs`,
              },
              {
                label: i18n('EditActsProfileLayout.videos', 'VIDEOS'),
                route: `/me/acts/${act.id}/videos`,
              },
              {
                label: i18n('EditActsProfileLayout.gallery', 'GALLERY'),
                route: `/me/acts/${act.id}/gallery`,
              },
              {
                label: i18n('EditActsProfileLayout.members', 'MEMBERS'),
                route: `/me/acts/${act.id}/members`,
              },
              {
                label: i18n('EditActsProfileLayout.pricing', 'PRICING'),
                route: `/me/acts/${act.id}/pricing`,
              },
            ]}
          />
          <Scroller
            style={{
              height: uiStore.isMobile ? undefined : uiStore.innerHeight,
            }}
          >
            <Content>
              <CloseButtonContainer>
                <CloseButton
                  src={images.closeIcon}
                  onClick={() => navigate(`/acts/${act.id}`)}
                />
              </CloseButtonContainer>
              <TextH2
                style={{
                  marginBottom: 24,
                  alignSelf: 'center',
                  width: '100%',
                  maxWidth: 800,
                }}
              >
                {title}
              </TextH2>
              <MainContent>{children}</MainContent>
            </Content>
          </Scroller>
        </Row>
      </Main>
    );
  },
);

const CloseButtonContainer = styled.div`
  display: flex;
  position: absolute;
  top: 140px;
  right: 40px;
  cursor: pointer;
  @media (max-width: ${(props) => props.theme.breakPoints.mobile}px) {
    top: 0px;
    position: relative;
    align-self: flex-end;
    right: 0%;
  }
`;

const CloseButton = styled.img`
  width: 31.61px;
  height: 20px;
  object-fit: contain;
  filter: invert(1);
`;

const Main = styled.div`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  flex: 1;
  background-color: white;
  @media (max-width: ${(props) => props.theme.breakPoints.mobile}px) {
    position: relative;
  }
`;

const Scroller = styled.div`
  overflow-y: auto;
  width: 100%;
  background-color: white;
  @media (max-width: ${(props) => props.theme.breakPoints.mobile}px) {
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: white;
  padding-top: 64px;
  padding-bottom: 64px;
  padding-left: 21px;
  padding-right: 21px;
  box-sizing: border-box;
  @media (max-width: ${(props) => props.theme.breakPoints.mobile}px) {
    padding-top: 24px;
    padding-bottom: 120px;
  }
`;

const Row = styled.div`
  display: flex;
  box-sizing: border-box;
  @media (max-width: ${(props) => props.theme.breakPoints.mobile}px) {
    overflow-y: hidden;
    flex-direction: column;
  }
`;

const MainContent = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 800px;
  width: 100%;
  align-self: center;
  padding-bottom: 100px;
  @media (max-width: ${(props) => props.theme.breakPoints.mobile}px) {
  }
`;

export default EditActsProfileLayout;
