export default {
  'DeclinePopup.decliningThePerformer': {
    en: "Declining the performer's quote",
    fr: "Décliner la citation de l'interprète",
  },
  'DeclinePopup.youCanAddAMessage': {
    en: "You can add a message explaining why you're declining the quote",
    fr: 'Vous pouvez ajouter un message expliquant pourquoi vous refusez le devis',
  },
  'DeclinePopup.declineQuote': {
    en: 'Decline Quote',
    fr: 'Citation de déclin',
  },
  'DeclinePopup.quoteDeclined!': {
    en: 'Quote Declined!',
    fr: 'La citation a refusé!',
  },
  'DeclinePopup.declineRequest': {
    en: 'Decline Request',
    fr: 'Déclin de la demande',
  },
  'DeclinePopup.cancel': { en: 'Cancel', fr: 'Annuler' },
};
