import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { Box } from 'react-native-kondo';
import styled from 'styled-components';
import colors from '../../constants/colors';
import { Act, Artist } from '../../shared';
import { useUiStore } from '../../stores/ui';
import ATag from '../common/ATag';
import { GeneralRegularNormal } from '../common/Typography';
import PerformerCell from './PerformerCell';

const PerformerListing = observer(
  ({
    performers,
    currentPage,
    totalPages,
    onChangeSelectedPage,
  }: {
    performers: (Artist & Act)[];
    currentPage: number;
    totalPages: number;
    onChangeSelectedPage: (newPage: number) => void;
  }) => {
    const uiStore = useUiStore();

    const checkForExtraSpacing = () => {
      if (uiStore.isMobile) {
        return;
      }
      let length = performers.length;
      const spacingComponents: React.ReactNode[] = [];
      while (length % 3 !== 0) {
        spacingComponents.push(<Box width={300} mr={28} />);
        length += 1;
      }
      return <>{spacingComponents}</>;
    };

    return (
      <Box style={{ width: '100%' }}>
        <Main>
          {performers.map((performer) => (
            <ATag
              noOpacity={true}
              key={performer.id}
              target="_blank"
              href={
                performer.model === 'act'
                  ? `/acts/${performer.id}`
                  : `/artists/${performer.id}`
              }
            >
              <PerformerCell key={performer.id} performer={performer} />
            </ATag>
          ))}
          {checkForExtraSpacing()}
        </Main>
        <PageSelector
          currentPage={currentPage}
          totalPages={totalPages}
          onClickPage={onChangeSelectedPage}
        />
      </Box>
    );
  },
);

const Main = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: flex-start;
  @media (max-width: ${(props) => props.theme.breakPoints.tablet}px) {
    padding: 0 0 30px;
    justify-content: space-evenly;
  }
  @media (max-width: ${(props) => props.theme.breakPoints.mobile}px) {
    margin-bottom: 30px;
  }
`;

const PageSelector = ({
  totalPages,
  currentPage,
  onClickPage,
}: {
  totalPages: number;
  currentPage: number;
  onClickPage: (page: number) => void;
}) => {
  if (totalPages === 1) {
    return null;
  }

  const maxDisplay = 10;
  let startPage: number, endPage: number;

  if (totalPages <= maxDisplay) {
    startPage = 1;
    endPage = totalPages;
  } else if (currentPage <= Math.floor(maxDisplay / 2)) {
    startPage = 1;
    endPage = maxDisplay;
  } else if (currentPage >= totalPages - Math.floor(maxDisplay / 2)) {
    startPage = totalPages - maxDisplay + 1;
    endPage = totalPages;
  } else {
    startPage = currentPage - Math.floor(maxDisplay / 2);
    endPage = currentPage + Math.floor(maxDisplay / 2);
  }

  return (
    <Box flexDirection="row" alignSelf="center">
      {Array.from(
        { length: endPage - startPage + 1 },
        (_, i) => startPage + i,
      ).map((page) => (
        <Box
          // @ts-ignore
          onClick={() => onClickPage(page)}
          key={`${page}-key-pager`}
          pb={3}
          style={{
            // @ts-ignore
            cursor: 'pointer',
            borderBottomWidth: 1,
            borderColor: currentPage === page ? '#4361EE' : 'transparent',
          }}
        >
          <GeneralRegularNormal
            style={{
              textAlign: 'center',
              fontSize: 14,
              color: currentPage === page ? '#4361EE' : colors.black,
              marginRight: 6,
              marginLeft: 6,
            }}
          >
            {page}
          </GeneralRegularNormal>
        </Box>
      ))}
    </Box>
  );
};

export default PerformerListing;
