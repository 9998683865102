import * as React from 'react';
import Switch from 'react-switch';
import styled from 'styled-components';
import { GeneralRegularNormal } from './Typography';

const GiSwitch = ({
  value = false,
  onClick = () => null,
  label,
  disabled,
  style,
  setLocalValFn,
}: {
  value?: boolean;
  onClick?: (value: boolean) => void;
  label?: string;
  disabled?: boolean;
  style?: React.CSSProperties;
  setLocalValFn?: any;
}) => {
  const [localVal, setLocalVal] = React.useState(value);

  React.useEffect(() => {
    if (typeof setLocalValFn !== 'undefined') {
      setLocalValFn(setLocalVal);
    }
  }, []);

  return (
    <Main style={style}>
      {label ? <GeneralRegularNormal>{label}</GeneralRegularNormal> : null}
      <Switch
        onChange={(checked) => {
          setLocalVal(checked);
          onClick(checked);
        }}
        checked={localVal}
        disabled={disabled}
        uncheckedIcon={false}
        checkedIcon={false}
        onColor="#5ED678"
        offColor="#C4C4C4"
        activeBoxShadow={''}
      />
    </Main>
  );
};

const Main = styled.div`
  display: flex;
  box-sizing: border-box;
  align-items: center;
  justify-content: space-between;
  @media (max-width: ${(props) => props.theme.breakPoints.mobile}px) {
  }
`;

export default GiSwitch;
