export default {
  'BookingSummaryFees.transportLabel': { en: 'Transport', fr: 'Le transport' },
  'BookingSummaryFees.transportMessageFirstPart': {
    en: '/hour of transport for the driver',
    fr: '/ heure de transport pour le conducteur',
  },
  'BookingSummaryFees.transportMessageSecondPart': {
    en: '/hour of transport for each passager',
    fr: '/ heure de transport pour chaque passager',
  },
  'BookingSummaryFees.adminLabel': {
    en: 'Administrative Fees',
    fr: 'Taxes administratives',
  },
  'BookingSummaryFees.adminMessage': {
    en: '$${priceAdmin}/hour',
    fr: '$$ {priceadmin} / heure',
  },
  'BookingSummaryFees.meetingLabel': {
    en: 'Meeting Fees',
    fr: 'Frais de réunion',
  },
  'BookingSummaryFees.meetingMessage': {
    en: '$${priceMeetingFees}/hour',
    fr: '$$ {priceMeettingFees} / heure',
  },
  'BookingSummaryFees.partitionsAndArrangementsLabel': {
    en: 'Purchasing Partitions and Musical Arragements',
    fr: "Achat de partitions et d'arrangements musicaux",
  },
  'BookingSummaryFees.partitionsAndArrangementsMessage': {
    en: 'Any purchase of partitions or creations of musical arragements that are not available for free online will be covered by the client.',
    fr: "Tout achat de partitions ou créations d'arangements musicaux qui ne sont pas disponibles gratuitement en ligne seront couverts par le client.",
  },
  'BookingSummaryFees.equipmentRentalLabel': {
    en: 'Equipment Rental',
    fr: "Location d'équipement",
  },
  'BookingSummaryFees.equipmentRentalMessage': {
    en: 'All necessary rental equipment will be covered by the client.',
    fr: 'Tous les équipements de location nécessaires seront couverts par le client.',
  },
  'BookingSummaryFees.thisIsPurelyForInformational': {
    en: 'This is purely for informational purposes and for added transparency regarding pricing. The price quoted by musicians varies depending on many factors, not all of which may be shown below',
    fr: "Ceci est uniquement à des fins d'information et pour une transparence supplémentaire concernant les prix. Le prix indiqué par les musiciens varie en fonction de nombreux facteurs, qui ne peuvent pas être montrés ci-dessous",
  },
  'BookingSummaryFees.perHour': { en: '/hour', fr: '/heure' },
};
