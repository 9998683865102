import { format } from 'date-fns';
import { frCA, enUS } from 'date-fns/locale';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { Box } from 'react-native-kondo';
import styled from 'styled-components';
import colors from '../../constants/colors';
import { Message } from '../../shared';
import { UseUserStore } from '../../stores/user';
import {
  GeneralRegularNormal,
  GeneralRegularSmall,
} from '../common/Typography';

interface MessageProps {
  message: Message;
}

const MessageCell = observer((props: MessageProps) => {
  const userStore = UseUserStore();

  const isFrench = userStore?.browserLanguage === 'fr';
  const meId = userStore.me?.id;
  const isMe = props.message.user.id === meId;

  const timeSection = (
    <GeneralRegularSmall
      style={{
        color: 'rgba(0,0,0,0.68)',
        marginLeft: !isMe ? 12 : 0,
        marginRight: !isMe ? 0 : 12,
      }}
    >
      {format(
        new Date(props.message.createdAt),
        isFrench ? "dd LLL yyyy 'à' HH:m" : "LLL dd yyyy 'at' p",
        {
          locale: isFrench ? frCA : enUS,
        },
      )}
    </GeneralRegularSmall>
  );

  const messageSection = (
    <Box
      flexDirection="row"
      py={9}
      px={27}
      style={{
        borderRadius: 10,
        // eyeballed 40% but could be different
        maxWidth: '40%',
      }}
      bg={isMe ? colors.blue : colors.grey}
    >
      <GeneralRegularNormal style={{ color: isMe ? 'white' : 'black' }}>
        {props.message.text}
      </GeneralRegularNormal>
    </Box>
  );

  const content = !isMe
    ? [messageSection, timeSection]
    : [timeSection, messageSection];

  return (
    <Main style={{ justifyContent: !isMe ? 'flex-start' : 'flex-end' }}>
      {content}
    </Main>
  );
});

const Main = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 13px;
`;

export default MessageCell;
