import { observer } from 'mobx-react';
import * as React from 'react';
import styled from 'styled-components';
import HowItWorksAccordionItem from './HowItWorksAccordionItem';
import { HowItWorksAccordionData } from './HowItWorksAccordionTypes';

const HowItWorksWhichAreYouAccordion = observer(
  ({ items }: { items: Array<HowItWorksAccordionData> }) => {
    return (
      <AccordionUl>
        {items.map((item, idx) => (
          <HowItWorksAccordionItem key={idx} data={item} />
        ))}
      </AccordionUl>
    );
  },
);

const AccordionUl = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
  padding-left: 0;
`;

export default HowItWorksWhichAreYouAccordion;
