import { format } from 'date-fns';
import { frCA } from 'date-fns/locale';
import * as React from 'react';
import { Box } from 'react-native-kondo';

import colors from '../../constants/colors';
import i18n from '../../constants/i18n';
import { useBookingStore } from '../../stores/booking';
import { UseUserStore } from '../../stores/user';
import { fonts, TextH3 } from '../common/Typography';
import {
  formatTransactionDresscode,
  formatTransactionEventType,
} from '../../utils/text';
import styled from 'styled-components';

const BookingSummaryEventInfo = () => {
  const bookingStore = useBookingStore();
  const userStore = UseUserStore();

  const formatOptions = {
    locale: userStore?.browserLanguage === 'fr' ? frCA : undefined,
  };

  return (
    <Box>
      <InfoBlock
        label={i18n('BookingSummary.eventType', 'Event Type')}
        text={formatTransactionEventType(bookingStore.eventType)}
      />
      <InfoBlock
        label={i18n('BookingSummary.dateAndTime', 'Date and Time')}
        text={`${format(bookingStore.date!, 'PPP', formatOptions)} | ${format(
          bookingStore.startTime!,
          'p',
          formatOptions,
        )} - ${format(bookingStore.endTime!, 'p', formatOptions)}${
          bookingStore.setupTime
            ? `\n+${bookingStore.setupTime} ${i18n(
                'BookingSummary.setup',
                'hour(s) time for set up',
              )}`
            : ''
        }`}
      />
      <InfoBlock
        label={i18n('BookingSummary.location', 'Location')}
        text={`${bookingStore.venueName}\n${bookingStore.locationAddress}\n${
          bookingStore.city
        } ${bookingStore.province} ${bookingStore.country} ${
          bookingStore.postalCode
        }\n${
          bookingStore.isOutDoor
            ? `${i18n('BookingSummary.outdoor', 'Outdoors')}, ${
                bookingStore.isStageCovered
                  ? i18n('BookingSummary.covered', 'covered')
                  : i18n('BookingSummary.notcovered', 'uncovered')
              }`
            : i18n('BookingSummary.indoor', 'Indoors')
        }`}
      />
      <InfoBlock
        label={i18n('BookingSummary.audience', 'Audience')}
        text={`${i18n(
          'BookingSummary.estimated',
          'Estimated Audience Size',
        )}: ${bookingStore.estimatedAudience}\n${i18n(
          'BookingSummary.dessCode',
          'Dresscode',
        )}: ${formatTransactionDresscode(bookingStore.dresscode)}`}
      />

      <InfoBlockTextArea
        label={i18n(
          'BookingSummary.additionalInformation',
          'Additional Information',
        )}
        text={bookingStore.message}
      />

      <InfoBlock
        label={i18n('BookingSummary.contact', 'Contact Information')}
        text={`${bookingStore.firstName} ${bookingStore.lastName}\n${bookingStore.phoneNumber}`}
      />
    </Box>
  );
};

const InfoBlock = ({ label, text }: { label: string; text: string }) => {
  return (
    <Box style={{ alignSelf: 'center' }} mb={48}>
      <TextH3
        style={{
          color: colors.whiteText,
          alignSelf: 'center',
          textAlign: 'center',
          marginBottom: 4,
        }}
      >
        {label}
      </TextH3>
      <pre
        style={{
          color: colors.whiteText,
          alignSelf: 'center',
          textAlign: 'center',
          fontSize: 16,
          fontFamily: fonts.GeneralSans,
          fontWeight: 400,
        }}
      >
        {text}
      </pre>
    </Box>
  );
};

const InfoBlockTextArea = ({
  label,
  text,
}: {
  label: string;
  text: string;
}) => {
  return (
    <Box style={{ alignSelf: 'center', width: '100%' }} mb={48}>
      <TextH3
        style={{
          color: colors.whiteText,
          alignSelf: 'center',
          textAlign: 'center',
          marginBottom: 4,
        }}
      >
        {label}
      </TextH3>
      <TextArea value={text} readOnly />
    </Box>
  );
};

const TextArea = styled.textarea`
  padding: 12px 12px;
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  box-shadow: 4px 4px 10px rgba(131, 123, 119, 0.25);
  border-radius: 2px;
  height: 100px;
  resize: none;
`;

export default BookingSummaryEventInfo;
