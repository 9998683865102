import { observer } from 'mobx-react';
import * as React from 'react';
import styled from 'styled-components';
import colors from '../../../constants/colors';
import i18n from '../../../constants/i18n';
import { GoogleAuthButton } from '../../common/SocialButton';
import { useNavigate } from 'react-router-dom';
import { Box } from 'react-native-kondo';
import { InputLabel } from '../../common/Typography';
import { validateEmail, validatePassword } from '../../../utils/validator';
import { UseUserStore } from '../../../stores/user';
import GiglinkedTextInput from '../../common/GiglinkedTextInput';
import GiglinkedButton from '../../common/GiglinkedButton';
import { SignUpStep } from '../../../shared';
import GiSeparator from '../../common/GiSeparator';
import GiAuthContainer from '../../common/GiAuthContainer';
import toast from 'react-hot-toast';

const SignInController = observer(
  ({
    onPerformerSigninRedirectPath,
    onUserSigninRedirectPath,
  }: {
    onPerformerSigninRedirectPath?: string;
    onUserSigninRedirectPath?: string;
  }) => {
    const navigate = useNavigate();
    const userStore = UseUserStore();
    const { setSignUpStep } = userStore;
    const [email, setEmail] = React.useState('');
    const [password, setPassword] = React.useState('');
    const [loading, setLoading] = React.useState(false);

    const validations = {
      email: validateEmail(email),
      password: validatePassword(password),
    };

    const me = userStore.getMe();
    const isLoggedIn = !!me;

    const onClickSignIn = async () => {
      try {
        if (isLoggedIn) {
          throw new Error(
            i18n(
              'RegistrationUser.userLoggedIn',
              'You are logged in! Log out and try again.',
            ),
          );
        }
        setLoading(true);
        await userStore.signin({
          email,
          password,
        });
        await onSigninCompleted();
      } catch (e) {
        const err = e as Error;
        toast.error(err.message);
      } finally {
        setLoading(false);
      }
    };

    const onSigninCompleted = async () => {
      if (userStore.me?.artist?.id) {
        navigate(
          onPerformerSigninRedirectPath ??
            `/artists/${userStore.me!.artist.id}`,
        );
      } else {
        navigate(onUserSigninRedirectPath ?? `/dashboard`);
      }
    };

    return (
      <GiAuthContainer
        title={i18n('SignInController.title', 'Welcome back to GigLinked!')}
        secondaryText={i18n('SignInController.underTitle', 'Sign-in')}
        tertiaryText={
          <>
            {i18n(
              'SignInController.linkOnCreateAccount',
              "Don't have an account? ",
            )}
            <StyledATag
              style={{ marginLeft: '1rem' }}
              href={'/signup'}
              onClick={() => setSignUpStep(SignUpStep.RegistrationUser)}
            >
              {i18n('SignInController.linkCreateAccount', 'Create an account')}
            </StyledATag>
          </>
        }
        editableContent={
          <>
            <Box style={{ width: '100%' }}>
              <InputLabel htmlFor="email">
                {i18n('SignInController.email', 'Email')}
              </InputLabel>

              <GiglinkedTextInput
                style={{ marginBottom: 18 }}
                type="email"
                value={email}
                onChangeText={setEmail}
                showCheckMark={validations.email}
              />

              <InputLabel htmlFor="password">
                {i18n('SignInController.password', 'Password')}
              </InputLabel>

              <GiglinkedTextInput
                style={{ marginBottom: 5 }}
                type="password"
                value={password}
                onChangeText={setPassword}
                showCheckMark={validations.password}
              />

              <StyledATag
                href="/forgot-password"
                style={{ color: colors.black, placeSelf: 'end' }}
              >
                {i18n('SignInController.forgot', 'Forgot password')}
              </StyledATag>
            </Box>
            <Box style={{ marginTop: '1rem', width: '100%' }}>
              <GiglinkedButton
                style={{ width: '100%' }}
                label={i18n('SignInController.go', 'Go!')}
                onClick={onClickSignIn}
                loading={loading}
                disabled={!validations.email || !validations.password}
              />
              <GiSeparator style={{ margin: '20px 0' }} />
              <GoogleAuthButton onLogin={onSigninCompleted} />
            </Box>
          </>
        }
      />
    );
  },
);

const StyledATag = styled.a`
  display: flex;
  justify-content: flex-start;
  text-decoration: underline;
  color: ${colors.white};
  @media (max-width: ${(props) => props.theme.breakPoints.mobile}px) {
    font-size: 12px;
    align-items: center;
  }
  &:hover {
    cursor: pointer;
  }
`;

export default SignInController;
