export default {
  'NavigationDrawer.browseMusicians': {
    en: 'Browse musicians',
    fr: 'Parcourir les musiciens',
  },
  'NavigationDrawer.requests': { en: 'Requests', fr: 'Demandes' },
  'NavigationDrawer.messages': { en: 'Messages', fr: 'Messages' },
  'NavigationDrawer.settings': { en: 'Settings', fr: 'Réglages' },
  'NavigationDrawer.support': { en: 'Support', fr: 'Soutien' },
  'NavigationDrawer.createEvent': { en: 'Create event', fr: 'Créer événement' },
  'NavigationDrawer.logout': { en: 'Log Out', fr: 'Se déconnecter' },
  'NavigationDrawer.gigs': { en: 'Gigs', fr: 'Gigs' },
  'NavigationDrawer.transactions': { en: 'Transactions', fr: 'Transactions' },
  'NavigationDrawer.dashboard': { en: 'Dashboard', fr: 'Tableau de bord' },
};
