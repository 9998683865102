export default {
  'EditArtistActCell.leave': { en: 'Leave', fr: 'Quitter' },
  'EditArtistActCell.manage': { en: 'Manage', fr: 'Faire en sorte' },
  'EditArtistActCell.accept': {
    en: 'Accept Invitation',
    fr: "Accepter l'invitation",
  },
  'EditArtistActCell.decline': { en: 'Decline', fr: 'Déclin' },
  'EditArtistActCell.viewProfile': { en: 'View Page', fr: 'Voir page' },
};
