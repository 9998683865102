import * as React from 'react';
import styled from 'styled-components';
import { observer } from 'mobx-react';
import {
  GeneralMediumLarge,
  GeneralSemiBoldSmall,
  TextH4,
  TextH6,
} from '../Typography';
import images from '../../../constants/images';
import colors from '../../../constants/colors';
import i18n from '../../../constants/i18n';
import { useEventStore } from '../../../stores/event';
import { CompanyTypeEnums, EventProposalType } from '../../../shared';
import { useNavigate } from 'react-router-dom';
import { Column, Row } from '../Layout';
import { addCommaAndSpace, capitalize } from '../../../utils/string';
import { getShortenTagsToShow } from '../../performer/PerformerCell';
import { format } from 'date-fns';
import { replaceProfileImage } from '../../../utils/media';

const EventData = observer(({ proposal }: { proposal: EventProposalType }) => {
  const eventStore = useEventStore();
  const navigate = useNavigate();
  const { currentEvent } = eventStore;

  return (
    <>
      <TextH4 style={{ fontWeight: 400 }}>
        {i18n('EventData.confirmHiring', 'Confirm hiring')}
      </TextH4>

      <GeneralSemiBoldSmall
        style={{ color: colors.buttonBorder, marginBottom: '0.5rem' }}
      >
        {i18n('EventData.musician', 'Musician')}
      </GeneralSemiBoldSmall>

      <Row
        style={{ cursor: 'pointer' }}
        onClick={() => navigate(`/artists/${proposal.artist.id}`)}
      >
        <img
          style={{
            height: '74px',
            width: '74px',
            borderRadius: '50%',
          }}
          src={replaceProfileImage(proposal.artist.profilePicture)}
          alt="artist profile picture"
        />
        <Column
          style={{
            marginLeft: '1rem',
            cursor: 'pointer',
          }}
          onClick={() => navigate(`/artists/${proposal.artist.id}`)}
        >
          <GeneralMediumLarge style={{ marginBottom: '0.5rem' }}>
            {capitalize(proposal.artist.name)}
          </GeneralMediumLarge>

          <Row>
            {proposal.artist.tags.slice(0, 3).map((tag, index) => (
              <TextH6
                style={{ fontWeight: 400, color: colors.white }}
                key={tag.id}
              >
                {addCommaAndSpace(index)}
                {capitalize(getShortenTagsToShow(tag, 10))}
              </TextH6>
            ))}
          </Row>
        </Column>
      </Row>

      <Divider />

      <GeneralSemiBoldSmall
        style={{ color: colors.buttonBorder, marginBottom: '0.5rem' }}
      >
        {i18n('EventData.yourEvent', 'Your Event')}
      </GeneralSemiBoldSmall>

      <Row>
        <Column>
          <Icon src={images.retirementIconWhite} />
        </Column>

        <Column
          style={{
            marginLeft: '1rem',
            cursor: 'pointer',
          }}
        >
          <GeneralMediumLarge style={{ marginBottom: '0.5rem' }}>
            {currentEvent?.companyType &&
              currentEvent!.companyType !== CompanyTypeEnums.ForMyself && (
                <>{capitalize(currentEvent!.companyType) + ' - '}</>
              )}
            {capitalize(currentEvent!.name)}
          </GeneralMediumLarge>

          <TextH6 style={{ fontWeight: 400, color: colors.white }}>
            {format(new Date(currentEvent!.eventDate), 'PPP')}
          </TextH6>

          <TextH6 style={{ fontWeight: 400, color: colors.white }}>
            {format(new Date(currentEvent!.startTime), 'hh:mm a')} -{' '}
            {format(new Date(currentEvent!.endTime), 'hh:mm a')}
          </TextH6>

          <TextH6 style={{ fontWeight: 400, color: colors.white }}>
            {currentEvent!.contactAddress}
          </TextH6>

          <TextH6 style={{ fontWeight: 400, color: colors.white }}>
            {currentEvent!.city},{' '}
            {currentEvent!.postalCode.substring(0, 3).toUpperCase()}
          </TextH6>
        </Column>
      </Row>

      <Divider />
    </>
  );
});

const Divider = styled.div`
  height: 1px;
  width: 100%;
  background: ${colors.buttonBorder};
  margin: 3rem 0;
`;

const Icon = styled.img`
  height: 74px;
  width: 74px;
  @media (max-width: ${(props) => props.theme.breakPoints.mobile}px) {
    height: 40px;
  }
`;

export default EventData;
