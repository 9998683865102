import * as React from 'react';
import { Box } from 'react-native-kondo';
import styled from 'styled-components';
import { TextH3, GeneralSemiBoldSmall } from '../../common/Typography';
import colors from '../../../constants/colors';

const NavButton = ({
  label,
  notificationCount = 0,
  style,
  hide,
  onClick,
}: {
  label: string;
  notificationCount?: number;
  style?: React.CSSProperties;
  hide?: boolean;
  onClick?: () => void;
}) => {
  if (hide) {
    return null;
  }
  return (
    <NavButtonContainer style={style} onClick={onClick}>
      <TextH3>{label}</TextH3>
      {notificationCount !== 0 ? (
        <Box
          justifyContent="center"
          alignItems="center"
          style={{
            width: 27,
            height: 27,
            borderRadius: 27 / 2,
            backgroundColor: colors.red,
          }}
        >
          <GeneralSemiBoldSmall>
            {String(notificationCount)}
          </GeneralSemiBoldSmall>
        </Box>
      ) : null}
    </NavButtonContainer>
  );
};

const NavButtonContainer = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  height: 72px;
  justify-content: space-between;
  border-style: solid;
  border-width: 0px;
  border-bottom-width: 1px;
  border-color: #5b6283;
  @media (max-width: ${(props) => props.theme.breakPoints.mobile}px) {
    height: 50px;
  }
`;

export default NavButton;
