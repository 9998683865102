import { observer } from 'mobx-react';
import * as React from 'react';
import Table from 'react-bootstrap/Table';
import styled from 'styled-components';
import i18n from '../../constants/i18n';
import colors from '../../constants/colors';
import { useNavigate } from 'react-router-dom';
import { Transaction } from '../../shared';
import { UseUserStore } from '../../stores/user';
import { format } from 'date-fns';

const InvoicesTable = observer(
  ({ transactions }: { transactions: Array<Transaction> }) => {
    const navigate = useNavigate();
    const userStore = UseUserStore();
    const isFrench = userStore?.browserLanguage === 'fr';

    return (
      <Table hover>
        <thead>
          <tr>
            <HeaderColumn>
              {i18n('InvoicesTable.events', 'Events')}
            </HeaderColumn>
            <HeaderColumn>{i18n('InvoicesTable.date', 'Date')}</HeaderColumn>
            <HeaderColumn>
              {i18n('InvoicesTable.amount', 'Amount')}
            </HeaderColumn>
            <HeaderColumn></HeaderColumn>
          </tr>
        </thead>
        <tbody>
          {transactions.map((transaction) => (
            <TableRow
              key={transaction.id}
              // onClick={() => navigate(`/invoice/${transaction.id}`)}
              onClick={
                transaction.stripe?.receiptUrl
                  ? () => window.open(transaction.stripe.receiptUrl, '_blank')
                  : () => navigate(`/invoice/${transaction.id}`)
              }
            >
              <TableColumn>{transaction.eventType}</TableColumn>
              <TableColumn>
                {format(
                  new Date(transaction!.eventDate),
                  isFrench ? 'y.dd.MM' : 'y.MM.dd',
                )}
              </TableColumn>
              <TableColumn>
                ${transaction.priceData.subTotalPriceFormatted}
              </TableColumn>
              <TableColumn
                // onClick={
                //   transaction.stripe?.receiptUrl
                //     ? () => window.open(transaction.stripe.receiptUrl, '_blank')
                //     : () => navigate(`/invoice/${transaction.id}`)
                // }
                style={{ fontWeight: 600, color: colors.buttonTextColor }}
              >
                {i18n('InvoicesTable.invoice', 'Invoice')}
              </TableColumn>
            </TableRow>
          ))}
        </tbody>
      </Table>
    );
  },
);

const HeaderColumn = styled.th`
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 400;
  color: ${(props) => props.theme.colors.tableTextColor};
  font-family: ${(props) => props.theme.fonts.GeneralSans};
`;

const TableRow = styled.tr`
  cursor: pointer;
  height: 63px;
  font-family: ${(props) => props.theme.fonts.GeneralSans};
`;

const TableColumn = styled.td`
  text-transform: capitalize;
  font-size: 16px;
  font-weight: 400;
`;

export default InvoicesTable;
