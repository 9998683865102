export default {
  'SignInController.title': {
    en: 'Welcome back to GigLinked!',
    fr: 'Bienvenue à GigLinked!',
  },
  'SignInController.underTitle': { en: 'Sign-in', fr: 'Se connecter' },
  'SignInController.email': { en: 'Email', fr: 'Courriel' },
  'SignInController.password': { en: 'Password', fr: 'Mot de passe' },
  'SignInController.SignInOr': { en: 'Or', fr: 'Ou' },
  'SignInController.orSignInWith': { en: 'Sign-in with', fr: 'Connexion avec' },
  'SignInController.forgot': {
    en: 'Forgot password',
    fr: 'Mot de passe oublié',
  },
  'SignInController.linkOnCreateAccount': {
    en: "Don't have an account? ",
    fr: "Vous n'avez pas de compte?",
  },
  'SignInController.linkCreateAccount': {
    en: 'Create an account',
    fr: 'Créer un compte',
  },
  'SignInController.go': { en: 'Go!', fr: 'Aller!' },
};
