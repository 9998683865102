export default {
  'EditArtistActScreen.error': {
    en: 'Something went wrong',
    fr: "Quelque chose s'est mal passé",
  },
  'EditArtistActScreen.success': { en: 'Act joined!', fr: "Act s'est joint!" },
  'EditArtistActScreen.leave': { en: 'Act Left!', fr: 'Agir à gauche!' },
  'EditArtistActScreen.title': { en: 'Your Acts', fr: 'Vos actes' },
  'EditArtistActScreen.createActTitle': {
    en: 'Create a new Act',
    fr: 'Créer un nouvel acte',
  },
  'EditArtistActScreen.toCreateANewAct': {
    en: 'To create a new act, simply enter the name of youy band, or ensemble.',
    fr: 'Pour créer un nouvel acte, entrez simplement le nom de Youy Band ou Ensemble.',
  },
  'EditArtistActScreen.placeholderCreate': { en: 'Act Name', fr: "Nom d'acte" },
  'EditArtistActScreen.createAct': { en: 'Create', fr: 'Créer' },
};
