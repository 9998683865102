export default {
  'RegistrationMusicianOrClient.areYou': { en: 'Are you:', fr: 'Vous êtes:' },
  'RegistrationMusicianOrClient.musician': {
    en: 'a musician',
    fr: 'musicien.ne',
  },
  'RegistrationMusicianOrClient.artist': { en: '/ artist', fr: '/ artiste' },
  'RegistrationMusicianOrClient.lookingFor': {
    en: 'looking for',
    fr: 'à la recherche',
  },
  'RegistrationMusicianOrClient.musicians': {
    en: 'musicians',
    fr: 'de musiciens',
  },
  'RegistrationMusicianOrClient.next': { en: 'Next', fr: 'Suivant' },
};
