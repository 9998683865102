export default {
  'CancelEventModal.title': {
    en: 'Are you sure you want to cancel this booking?',
    fr: 'Êtes-vous sûr de vouloir annuler cette réservation ?',
  },
  'CancelEventModal.subTitle': {
    en: 'Cancelling this booking will alert the musician about the cancelation and you will need to find another musician for your event.',
    fr: "L'annulation de cette réservation alertera le musicien de l'annulation et vous devrez trouver un autre musicien pour votre événement.",
  },
  'CancelEventModal.noKeep': { en: 'No, keep it', fr: 'Non, gardez-le' },
  'CancelEventModal.cancelBooking': {
    en: 'Cancel booking',
    fr: 'Annuler la réservation',
  },
};
