export default {
  maxLayoutWidth: 1000,
  mobileThreshold: 786,
  tabletThredshold: 1200,
  maxContentWidth: 1800,
};

export const breakPoints = {
  tablet: 1200,
  mobile: 768,
};
