import { observer } from 'mobx-react';
import * as React from 'react';
import { TextInput } from 'react-native';
import { Box } from 'react-native-kondo';
import colors from '../../constants/colors';
import { useUiStore } from '../../stores/ui';
import { logUserClickedSendMessage } from '../../utils/amplitude';

const MessageComposer = observer(
  ({ onPressSend }: { onPressSend: (text: string) => void }) => {
    const [currentMessageText, setCurrentMessageText] = React.useState('');
    const uiStore = useUiStore();
    return (
      <Box
        bg={colors.lightBlue}
        p={uiStore.isMobile ? 15 : 42}
        style={{
          borderTopWidth: 1,
          borderColor: 'rgba(0, 0, 0, 0.25)',
        }}
      >
        <TextInput
          value={currentMessageText}
          onChangeText={setCurrentMessageText}
          placeholder="Write something"
          onSubmitEditing={() => {
            logUserClickedSendMessage();
            onPressSend(currentMessageText);
            setCurrentMessageText('');
          }}
          returnKeyLabel="send"
          style={{
            backgroundColor: 'white',
            paddingLeft: 20,
            paddingVertical: 10,
            borderColor: 'rgba(0, 0, 0, 0.25)',
            borderWidth: 1,
            fontSize: 17,
          }}
        />
      </Box>
    );
  },
);

export default MessageComposer;
