export default {
  'RegistrationBookerLocation.title': {
    en: 'Tell us about yourself',
    fr: 'Parlez-nous de vous',
  },
  'RegistrationBookerLocation.name': { en: 'Name of', fr: 'Nom de votre' },
  'RegistrationBookerLocation.city': {
    en: 'City of the',
    fr: 'Ville de votre',
  },
  'RegistrationBookerLocation.province': {
    en: 'Province of the',
    fr: 'Province de votre',
  },
  'RegistrationBookerLocation.postalCode': {
    en: 'Postal code of the',
    fr: 'Code postal de votre',
  },
  'RegistrationBookerLocation.next': { en: 'Next', fr: 'Suivant' },
  'RegistrationBookerLocation.errorContactAddress': {
    en: 'Your contact address cannot be blank.',
    fr: 'Votre adresse ne peut pas être vide.',
  },
  'RegistrationBookerLocation.errorCity': {
    en: 'Your city cannot be blank.',
    fr: 'Votre ville ne peut pas être vide',
  },
  'RegistrationBookerLocation.errorPostalCode': {
    en: 'Your postal code cannot be blank.',
    fr: 'Votre code postal ne peut pas être vide.',
  },
  'RegistrationBookerLocation.errorContactPhone': {
    en: 'Your phone number cannot be blank.',
    fr: 'Votre numéro de téléphone ne peut pas être vide.',
  },
  'RegistrationBookerLocation.address': {
    en: 'Address of the',
    fr: 'Adresse de votre',
  },
  'RegistrationBookerLocation.contactPhone': {
    en: 'Contact phone number',
    fr: 'Numero de téléphone de contact',
  },
  'RegistrationBookerLocation.contactAddress': {
    en: 'Contact address',
    fr: 'Adresse de contact',
  },
};
