import React from 'react';
import MaskedInput from 'react-text-mask';
import styled from 'styled-components';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';

interface CurrencyInputMaskOptions {
  inputmode: string;
  maskOptions: {
    prefix: string;
    suffix: string;
    includeThousandsSeparator: boolean;
    thousandsSeparatorSymbol: string;
    allowDecimal: boolean;
    decimalSymbol: string;
    decimalLimit: string;
    requireDecimal: boolean;
    allowNegative: boolean;
    allowLeadingZeroes: boolean;
    integerLimit: number;
  };
}

type InputMode =
  | 'numeric'
  | 'none'
  | 'text'
  | 'tel'
  | 'url'
  | 'email'
  | 'decimal'
  | 'search';

const defaultMaskOptions = {
  prefix: '$',
  suffix: '',
  includeThousandsSeparator: true,
  thousandsSeparatorSymbol: ',',
  allowDecimal: true,
  decimalSymbol: '.',
  decimalLimit: 2, // how many digits allowed after the decimal
  integerLimit: 7, // limit length of integer numbers
  requireDecimal: false,
  allowNegative: false,
  allowLeadingZeroes: false,
};

const CurrencyInput = ({
  maskOptions,
  inputMode = 'numeric',
  style = {},
  placeholder,
  onChangeText,
  ...inputProps
}: React.InputHTMLAttributes<any> & {
  maskOptions?: CurrencyInputMaskOptions;
  inputMode?: InputMode;
  style?: React.CSSProperties;
  placeholder?: string;
  onChangeText: (value: string) => void;
}) => {
  const currencyMask = createNumberMask({
    ...defaultMaskOptions,
    ...maskOptions,
  });

  return (
    <MaskedInput
      mask={currencyMask}
      inputMode={inputMode}
      placeholder={placeholder}
      style={style}
      onChange={(event) => onChangeText(event.target.value)}
      render={(ref, props) => (
        <StyledInput
          //@ts-ignore
          ref={ref}
          {...props}
        />
      )}
      {...inputProps}
    />
  );
};

const StyledInput = styled.input`
  &:disabled {
    background-color: ${({ theme }) => theme.colors.white};
  }
`;

export default CurrencyInput;
