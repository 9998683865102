import * as React from 'react';
import colors from '../../../constants/colors';
import i18n from '../../../constants/i18n';
import { Transaction } from '../../../shared';
import {
  GeneralRegularNormal,
  GeneralSemiBoldNormal,
} from '../../common/Typography';
import useGetRequestDetailsQuoteFormattedData from './useGetRequestDetailsQuoteFormattedData';

const OtherUserText = ({ request }: { request: Transaction }) => {
  const { artist, act, booker, serviceType } = request;
  const { meIsAct, meIsArtist } =
    useGetRequestDetailsQuoteFormattedData(request);

  const otherUserText = {
    firstText: `${
      meIsAct || meIsArtist
        ? i18n('RequestDetailsQuoteSection.requestedBy', 'Requested By')
        : i18n('RequestDetailsQuoteSection.musician', 'Musician')
    }: `,
    secondText:
      meIsAct || meIsArtist
        ? `${booker.firstName} ${booker.lastName}`
        : serviceType === 'act'
        ? act.name
        : artist.name,
  };

  return (
    <GeneralRegularNormal>
      {otherUserText.firstText}
      <GeneralSemiBoldNormal style={{ color: colors.black }}>
        {otherUserText.secondText}
      </GeneralSemiBoldNormal>
    </GeneralRegularNormal>
  );
};

export default OtherUserText;
