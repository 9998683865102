import * as React from 'react';
import styled from 'styled-components';
import { Collapse } from 'react-collapse';
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io';
import colors from '../../constants/colors';

const GiCollapsableContainer = ({
  renderTop,
  renderContent,
  withArrow,
  mainStyle,
  disableCollaps,
}: {
  renderTop: () => React.ReactNode;
  renderContent: () => React.ReactNode;
  withArrow?: boolean;
  mainStyle?: React.CSSProperties;
  disableCollaps?: boolean;
}) => {
  const [isOpen, setIsOpen] = React.useState(false);

  const renderArrow = () => {
    if (withArrow) {
      return isOpen ? (
        <IoIosArrowUp
          color={colors.purple}
          size={24}
          style={{ marginLeft: 10 }}
        />
      ) : (
        <IoIosArrowDown
          color={colors.purple}
          size={24}
          style={{ marginLeft: 10 }}
        />
      );
    }
  };

  return (
    <Main style={mainStyle}>
      <ClickContent
        style={{
          cursor: disableCollaps ? '' : 'pointer',
        }}
        onClick={() => {
          if (disableCollaps) {
            return;
          }
          setIsOpen(!isOpen);
        }}
      >
        {renderTop()}
        {renderArrow()}
      </ClickContent>

      <Collapse isOpened={isOpen}>{renderContent()}</Collapse>
    </Main>
  );
};

const ClickContent = styled.div`
  display: flex;
`;

const Main = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: white;
`;

export default GiCollapsableContainer;
