export default {
  'SettingsScreen.errorFirstName': {
    en: 'Your first name cannot be blank.',
    fr: 'Votre prénom ne peut pas être vide.',
  },
  'SettingsScreen.errorLastName': {
    en: 'Your last name cannot be blank.',
    fr: 'Votre nom de familie ne peut pas être vide.',
  },
  'SettingsScreen.saved': { en: 'Saved', fr: 'Enregistrée' },
  'SettingsScreen.emailSent!': { en: 'Email sent!', fr: 'Courriel envoyé!' },
  'SettingsScreen.title': { en: 'Settings', fr: 'Réglages' },
  'SettingsScreen.firstName': { en: 'FIRST NAME', fr: 'PRÉNOM' },
  'SettingsScreen.lastName': { en: 'LAST NAME', fr: 'NOM DE FAMILLE' },
  'SettingsScreen.email': { en: 'EMAIL', fr: 'COURRIEL' },
  'SettingsScreen.password': { en: 'PASSWORD', fr: 'LE MOT DE PASSE' },
  'SettingsScreen.errorLocation': {
    en: 'Your location cannot be blank.',
    fr: 'Votre emplacement ne peut pas être vide.',
  },
  'SettingsScreen.billingPayments': {
    en: 'BILLING & PAYMENTS',
    fr: 'FACTURATION ET PAIEMENTS',
  },
  'SettingsScreen.billingPaymentsDesc': {
    en: 'Your primary billing method is used for all payments.',
    fr: 'Votre méthode de facturation principale est utilisée pour tous les paiements.',
  },
  'SettingsScreen.creditCardEnds': {
    en: 'Credit card ends in ... ',
    fr: 'La carte de crédit se termine en ...',
  },
  'SettingsScreen.cardRemoved': {
    en: 'You successfully removed a card',
    fr: 'Vous avez réussi à retirer une carte',
  },
  'SettingsScreen.addPayment': {
    en: 'Add a payment method',
    fr: 'Ajouter une méthode de paiement',
  },
  'SettingsScreen.address': { en: 'ADDRESS', fr: 'ADDRESSE' },
  'SettingsScreen.saveChanges': {
    en: 'Save changes',
    fr: 'Enregistrer les modifications',
  },
  'SettingsScreen.bankAccountEnds': {
    en: 'Direct to local bank - Account ending in ... ',
    fr: 'Directement à la banque locale - Compte se terminant par ...',
  },
  'SettingsScreen.accountRemoved': {
    en: 'You successfully removed a bank account',
    fr: "Vous avez réussi à supprimer un compte bancaire'",
  },
  'SettingsScreen.resetPassword': {
    en: 'Reset password',
    fr: 'Réinitialiser le mot de passe',
  },
  'SettingsScreen.addBanking': {
    en: 'Add banking information',
    fr: 'Ajouter des informations bancaires',
  },
  'SettingsScreen.artistRemoveBankingData': {
    en: 'Contact us if you would like to remove your banking payment data.',
    fr: 'Contactez-nous si vous souhaitez supprimer vos données de paiement bancaire.',
  },
  'SettingsScreen.errorStageName': {
    en: 'Your stage name cannot be blank. If you have no stage name, make it simply your name.',
    fr: "Votre nom de scène ne peut pas être vide. Si vous n'avez pas de nom de scène, indiquez simplement votre nom.",
  },
};
