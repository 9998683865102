import { action, observable } from 'mobx';
import { useStores } from '.';
import sexpressClient from '../utils/sexpress';
import { EventType } from '../shared';

export interface EventStoreType {
  events: Array<EventType> | undefined;
  currentEvent?: EventType;
  currentEventTab: string | null;
  setEvents: (events: Array<EventType>) => void;
  setCurrentEvent: (event?: EventType) => void;
  setCurrentEventTab: (currentEventTab: string) => void;
  initMeEvent: (payload: Partial<EventType>) => Promise<EventType>;
  fetchMeEvents: (payload?: {
    page?: number;
    limit?: number;
  }) => Promise<{ events: EventType[]; totalPages: number }>;
  fetchEvents: (payload?: {
    page?: number;
    limit?: number;
  }) => Promise<{ events: EventType[]; totalPages: number }>;
  fetchMeEvent: () => Promise<EventType>;
  editMeEvent: (
    payload: Partial<EventType> & { eventId: EventType['id'] },
  ) => Promise<EventType>;
}

class EventStore {
  @observable events: EventStoreType['events'] = undefined;

  @observable currentEvent: EventStoreType['currentEvent'] = undefined;

  @observable currentEventTab: EventStoreType['currentEventTab'] = null;

  @action
  setEvents: EventStoreType['setEvents'] = (events) => {
    this.events = events;
  };

  @action
  setCurrentEvent: EventStoreType['setCurrentEvent'] = (event) => {
    this.currentEvent = event;
  };

  @action
  setCurrentEventTab: EventStoreType['setCurrentEventTab'] = (eventTab) => {
    this.currentEventTab = eventTab;
  };

  @action
  initMeEvent: EventStoreType['initMeEvent'] = async (payload) => {
    return await sexpressClient.initMeEvent(payload);
  };

  @action
  fetchMeEvents: EventStoreType['fetchMeEvents'] = async (
    payload = { page: 1, limit: 18 },
  ) => {
    // @ts-ignore
    return await sexpressClient.fetchMeEvents(payload);
  };

  @action
  fetchEvents: EventStoreType['fetchEvents'] = async (
    payload = { page: 1, limit: 18 },
  ) => {
    // @ts-ignore
    return await sexpressClient.fetchEvents(payload);
  };

  @action
  editMeEvent: EventStoreType['editMeEvent'] = async (payload) => {
    // @ts-ignore
    return await sexpressClient.editMeEvent(payload);
  };
}

export default new EventStore();

export function useEventStore(): EventStoreType {
  const { event } = useStores();
  return event as EventStoreType;
}
