import * as React from 'react';
import { useUiStore } from '../../../stores/ui';
import { observer } from 'mobx-react';
import GiglinkedButton from '../GiglinkedButton';
import sexpressClient from '../../../utils/sexpress';
import toast from 'react-hot-toast';
import i18n from '../../../constants/i18n';
import { useEventStore } from '../../../stores/event';
import { EventProposalType } from '../../../shared';
import SuccessModal from '../SuccessModal';
import { useNavigate } from 'react-router-dom';
import { Row } from '../Layout';

const CheckPaymentData = observer(
  ({ proposal }: { proposal: EventProposalType }) => {
    const uiStore = useUiStore();
    const eventStore = useEventStore();
    const navigate = useNavigate();
    const { currentEvent, setCurrentEventTab } = eventStore;

    const eventBookingTransactionData = {
      eventDate: currentEvent!.eventDate,
      isOutDoor: currentEvent!.isOutdoor,
      isStageCovered: currentEvent!.isStageCovered!,
      eventType: currentEvent!.companyType,
      message: currentEvent!.description,
      setupTime: 0,
      startTime: currentEvent!.startTime,
      endTime: currentEvent!.endTime,
      artistId: proposal.artist.id,
      locationAddress: currentEvent!.contactAddress,
      estimatedAudience: currentEvent!.estimatedAudience,
      venueName: currentEvent!.companyName,
      city: currentEvent!.city,
      postalCode: currentEvent!.postalCode,
      province: currentEvent!.province,
      country: 'Canada',
      contactFirstName: currentEvent!.contactFirstName,
      contactLastName: currentEvent!.contactLastName,
      contactPhone: currentEvent!.contactPhone,
      quote: String(proposal.proposalQuote),
      eventId: proposal.event,
    };

    return (
      <>
        <GiglinkedButton
          label={i18n(
            'CheckPaymentData.confirmGenerateInvoice',
            'Confirm & Generate Invoice',
          )}
          onClick={async () => {
            uiStore.closePopup();
            try {
              const res = await sexpressClient.createEventBookingTransaction(
                eventBookingTransactionData,
              );
              eventStore.setCurrentEvent(res);

              uiStore.openPopup({
                popupDisableTapOut: true,
                renderContent: () => (
                  <SuccessModal
                    title={i18n('CheckPaymentData.successTitle', 'Thank you!')}
                    secondaryText={i18n(
                      'CheckPaymentData.secondaryText',
                      'Your invoice has been automatically generated. You can see it in your Dashboard, tab “Invoices”.',
                    )}
                    navigate={
                      <GiglinkedButton
                        label={i18n(
                          'CheckPaymentData.goToInvoices',
                          'Take me to my Invoices',
                        )}
                        onClick={() => {
                          navigate('/dashboard');
                          setCurrentEventTab('invoices');
                          uiStore.closePopup();
                        }}
                      />
                    }
                  />
                ),
              });
            } catch (e) {
              const err = e as Error;
              toast.error((t) => (
                <Row>
                  {err.message}
                  <button onClick={() => toast.dismiss(t.id)}>
                    {i18n('CheckPaymentData.dismiss', 'Dismiss')}
                  </button>
                </Row>
              ));
            }
          }}
        />
      </>
    );
  },
);

export default CheckPaymentData;
