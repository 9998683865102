import * as React from 'react';
import { ClipLoader } from 'react-spinners';
import styled from 'styled-components';
import { colors } from '../../constants/colors';
import ATag from './ATag';
import { GeneralRegularNormal } from './Typography';

const GiSimpleButton = ({
  icon,
  label,
  loading,
  disabled,
  backgroundColor = '#f2f3f4',
  textColor = colors.black,
  route,
  onClick,
  style,
}: {
  icon?: string;
  label?: string;
  loading?: boolean;
  disabled?: boolean;
  backgroundColor?: string;
  textColor?: string;
  route?: string;
  onClick?: () => void;
  style?: React.CSSProperties;
}) => {
  let Container: any = React.Fragment;
  let containerProps = {};
  if (route) {
    Container = ATag;
    containerProps = { href: route };
  }

  return (
    <Container {...containerProps}>
      <GiSimpleButtonButtonContainer
        backgroundColor={backgroundColor}
        onClick={disabled || loading ? undefined : onClick}
        disabled={disabled}
        style={style}
      >
        {loading ? (
          <ClipLoader color={textColor} size={18} />
        ) : (
          <>
            {icon && <GiSimpleButtonButtonPen src={icon} />}
            <GeneralRegularNormal style={{ color: textColor }}>
              {label}
            </GeneralRegularNormal>
          </>
        )}
      </GiSimpleButtonButtonContainer>
    </Container>
  );
};

const GiSimpleButtonButtonPen = styled.img`
  margin-right: 9px;
  @media (max-width: ${(props) => props.theme.breakPoints.mobile}px) {
  }
`;

const GiSimpleButtonButtonContainer = styled.div<{
  backgroundColor: string;
  disabled?: boolean;
}>`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 37px;
  padding-right: 9px;
  padding-left: 9px;
  background-color: ${(props) => props.backgroundColor};
  border-radius: 2px;
  min-width: 67px;
  cursor: pointer;
  &:hover {
    opacity: ${(props) => (props.disabled ? 1 : 0.6)};
  }
  @media (max-width: ${(props) => props.theme.breakPoints.mobile}px) {
  }
`;

export default GiSimpleButton;
