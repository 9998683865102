export default {
  'RemoveMethodModal.title': {
    en: 'Are you sure you want to delete your payment method?',
    fr: 'Êtes-vous sûr de vouloir supprimer votre mode de paiement ?',
  },
  'RemoveMethodModal.yesDelete': { en: 'Yes, delete', fr: 'Oui, supprimer' },
  'RemoveMethodModal.noTakeBack': {
    en: 'No, take me back',
    fr: 'Non, ramenez-moi',
  },
};
