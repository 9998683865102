import * as React from 'react';
import styled from 'styled-components';
import i18n from '../../../constants/i18n';
import { Transaction } from '../../../shared';
import GiButton from '../../common/GiButton';
import { TextH2, GeneralRegularNormal } from '../../common/Typography';
import StarsSection from './StarsSection';
import sexpressClient from '../../../utils/sexpress';
import toast from 'react-hot-toast';

const RequestDetailsReviewSection = ({
  request,
  fetchTransaction,
}: {
  request: Transaction;
  fetchTransaction: () => void;
}) => {
  const [selectedRating, setSelectedRating] = React.useState(
    request.review?.rating ?? 1,
  );
  const [message, setMessage] = React.useState(request.review?.text ?? '');
  const [loading, setLoading] = React.useState(false);

  const { id, status, review } = request;

  if (!['completed'].includes(status)) {
    return null;
  }

  const createReview = async () => {
    try {
      setLoading(true);
      await sexpressClient.initReview({
        transactionId: id,
        rating: selectedRating,
        text: message,
      });
      fetchTransaction();
      toast.success(
        i18n('RequestDetailsReviewSection.reviewSent!', 'Review sent!'),
      );
    } catch (e) {
      const err = e as Error;
      toast.error(err.message);
    } finally {
      setLoading(false);
    }
  };

  const editReview = async () => {
    try {
      setLoading(true);
      await sexpressClient.editReview({
        reviewId: review!.id,
        rating: selectedRating,
        text: message,
      });
      fetchTransaction();
      toast.success(
        i18n('RequestDetailsReviewSection.reviewUpdated!', 'Review updated!'),
      );
    } catch (e) {
      const err = e as Error;
      toast.error(err.message);
    } finally {
      setLoading(false);
    }
  };

  const onClickSend = async () => {
    if (review) {
      editReview();
    } else {
      createReview();
    }
  };

  const disableButton =
    review && selectedRating === review.rating && message === review.text;

  return (
    <Main>
      <Content>
        <TextH2 style={{ marginBottom: 24 }}>
          {i18n(
            'RequestDetailsReviewSection.letUsKnow',
            'Let us know how it went!',
          )}
        </TextH2>

        <GeneralRegularNormal style={{ marginBottom: 8 }}>
          {i18n(
            'RequestDetailsReviewSection.howWouldYouRateYourGig',
            'How would you rate your gig?',
          )}
        </GeneralRegularNormal>

        <StarsSection
          selectedRating={selectedRating}
          setSelectedRating={setSelectedRating}
        />

        <TextAreaReview
          placeholder={i18n(
            'RequestDetailsReviewSection.leaveAReview',
            'Leave a review',
          )}
          value={message}
          onChange={(ev) => setMessage(ev.target.value)}
        />

        <GiButton
          label={
            review
              ? i18n('RequestDetailsReviewSection.edit', 'Edit')
              : i18n('RequestDetailsReviewSection.send', 'Send')
          }
          onClick={onClickSend}
          loading={loading}
          disabled={disableButton}
          grayDisabled
        />
      </Content>
    </Main>
  );
};

const Main = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  box-sizing: border-box;
  flex: 1;
  background-color: ${({ theme }) => theme.colors.white};

  @media (max-width: ${(props) => props.theme.breakPoints.mobile}px) {
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-self: center;
  box-sizing: border-box;
  width: 100%;
  max-width: 1300px;
  padding: 66px 20px 66px 156px;

  @media (max-width: ${(props) => props.theme.breakPoints.mobile}px) {
    padding-left: 20px;
  }
`;

const TextAreaReview = styled.textarea`
  padding: 10px 21px;
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.1);
  height: 165px;
  box-sizing: border-box;
  box-shadow: 4px 4px 10px rgba(131, 123, 119, 0.25);
  border-radius: 2px;
  margin-bottom: 24px;
  resize: none;
`;

export default RequestDetailsReviewSection;
