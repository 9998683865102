import { observer } from 'mobx-react';
import * as React from 'react';
import styled from 'styled-components';
import i18n from '../../../../../constants/i18n';
import { InputLabel } from '../../../../common/Typography';
import GiglinkedButton from '../../../../common/GiglinkedButton';
import { UseUserStore } from '../../../../../stores/user';
import {
  COMPANY_TYPES,
  PROVINCES_TERRITORIES,
  SignUpStep,
  User,
} from '../../../../../shared';
import GiglinkedLocationAutocomplete from '../../../../common/GiglinkedLocationAutocomplete';
import GiglinkedTextInput from '../../../../common/GiglinkedTextInput';
import GiAuthContainer from '../../../../common/GiAuthContainer';
import toast from 'react-hot-toast';
import GiEventsDropDown from '../../../../common/GiEventsDropDown';

const RegistrationBookerLocation = observer(() => {
  const userStore = UseUserStore();
  const me = userStore.getMe();

  const { setSignUpStep } = userStore;
  const [contactAddress, setContactAddress] = React.useState('');
  const [location, setLocation] = React.useState('');
  const [province, setProvince] = React.useState('QC');
  const [postalCode, setPostalCode] = React.useState('');
  const [contactPhone, setContactPhone] = React.useState('');

  const onSave = async (payload: Partial<User>) => {
    try {
      if (
        typeof payload.contactAddress === 'string' &&
        !payload.contactAddress
      ) {
        throw new Error(
          i18n(
            'RegistrationBookerLocation.errorContactAddress',
            'Your contact address cannot be blank.',
          ),
        );
      }
      if (typeof payload.location === 'string' && !payload.location) {
        throw new Error(
          i18n(
            'RegistrationBookerLocation.errorCity',
            'Your city cannot be blank.',
          ),
        );
      }
      if (typeof payload.postalCode === 'string' && !payload.postalCode) {
        throw new Error(
          i18n(
            'RegistrationBookerLocation.errorPostalCode',
            'Your postal code cannot be blank.',
          ),
        );
      }
      if (typeof payload.contactPhone === 'string' && !payload.contactPhone) {
        throw new Error(
          i18n(
            'RegistrationBookerLocation.errorContactPhone',
            'Your phone number cannot be blank.',
          ),
        );
      }
      await userStore.editMe(payload);
      setSignUpStep(SignUpStep.RegistrationBookerWaysToBook);
    } catch (e) {
      const err = e as Error;
      toast.error(err.message);
    }
  };
  const companyTypeTranslated = COMPANY_TYPES().find(
    (company) => company.value === me?.companyType,
  )?.label;

  return (
    <GiAuthContainer
      title={i18n('RegistrationBookerLocation.title', 'Tell us about yourself')}
      editableContent={
        <>
          <MainBox>
            <InputLabel htmlFor="contactAddress">
              {i18n('RegistrationBookerLocation.address', 'address')}
              {` ${companyTypeTranslated}`.toLowerCase()}
            </InputLabel>
            <GiglinkedTextInput
              id="contactAddress"
              value={contactAddress}
              onChange={(e: any) => setContactAddress(e.target.value)}
              style={{ marginBottom: '1rem' }}
            />
            <InputLabel htmlFor="location">
              {i18n('RegistrationBookerLocation.city', 'City')}
              {` ${companyTypeTranslated}`.toLowerCase()}
            </InputLabel>
            <GiglinkedLocationAutocomplete
              type="text"
              id="location"
              location={location!}
              setLocation={(ev) => setLocation(ev)}
              style={{ zIndex: 9 }}
            />
            <InputLabel htmlFor="province">
              {i18n('RegistrationBookerLocation.province', 'Province')}
              {` ${companyTypeTranslated}`.toLowerCase()}
            </InputLabel>
            <GiEventsDropDown
              items={PROVINCES_TERRITORIES()}
              value={province}
              onChange={(ev) => setProvince(ev.value)}
              style={{
                width: '100%',
                marginBottom: '1rem',
              }}
              active
            />
            <InputLabel htmlFor="postalCode">
              {i18n('RegistrationBookerLocation.postalCode', 'Postal code')}
              {` ${companyTypeTranslated}`.toLowerCase()}
            </InputLabel>
            <GiglinkedTextInput
              id="postalCode"
              placeholder="A1B 2C3"
              mask="a9a 9a9"
              value={postalCode}
              onChangeText={(ev) => setPostalCode(ev)}
              style={{ marginBottom: '1rem' }}
            />
            <InputLabel htmlFor="contactPhone">
              {i18n(
                'RegistrationBookerLocation.contactPhone',
                'Contact phone number',
              )}
            </InputLabel>
            <GiglinkedTextInput
              id="contactPhone"
              placeholder="(555) 555-5555"
              mask="(999) 999-9999"
              value={contactPhone}
              onChangeText={(ev) => setContactPhone(ev)}
            />
          </MainBox>
          <BoxBottom>
            <GiglinkedButton
              style={{ border: 'none' }}
              label={i18n('RegistrationBookerLocation.next', 'Next')}
              onClick={() =>
                onSave({
                  contactAddress,
                  location,
                  province,
                  postalCode,
                  contactPhone,
                })
              }
            />
          </BoxBottom>
        </>
      }
    />
  );
});

const MainBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 90%;
  @media (max-width: ${(props) => props.theme.breakPoints.mobile}px) {
    padding-left: 20px;
    padding-right: 20px;
  }
`;

const BoxBottom = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  height: 10%;
  @media (max-width: ${(props) => props.theme.breakPoints.mobile}px) {
    margin-right: 5px;
    margin-top: 0;
  }
`;

export default RegistrationBookerLocation;
