import { observer } from 'mobx-react';
import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import i18n from '../../constants/i18n';
import { useUiStore } from '../../stores/ui';
import { GeneralSemiBoldMainTitle, TextH4 } from '../common/Typography';
import GiButtonLanding from '../common/GiButtonLanding';
import ATag from '../common/ATag';
import urls from '../../utils/urls';
import images from '../../constants/images';
import { SignUpStep } from '../../shared';
import { UseUserStore } from '../../stores/user';
import { Carousel } from 'react-bootstrap';

const LandingTopSection = observer(() => {
  const uiStore = useUiStore();
  const navigate = useNavigate();
  const calculatedHeightOfContainer =
    uiStore.innerHeight - uiStore.navBarHeight;
  const userStore = UseUserStore();
  const { setSignUpStep } = userStore;
  const me = userStore.getMe();
  const isLoggedIn = !!me;

  return (
    <Main calculatedHeightOfContainer={calculatedHeightOfContainer}>
      <PurpleRectangle>
        <LeftSection>
          <GeneralSemiBoldMainTitle style={{ marginBottom: 20 }}>
            {i18n(
              'LandingTopSection.mainTagLine',
              'The Musicians Marketplace.',
            )}
          </GeneralSemiBoldMainTitle>
          <TextH4>
            {i18n(
              'LandingTopSection.secondaryTagLine',
              'Unforgettable music for corporate gatherings, private events, and weddings. Hire skilled and diverse musicians in just a few clicks.',
            )}
          </TextH4>

          <ButtonsRow isMobile={uiStore.isMobile}>
            {!isLoggedIn && (
              <GiButtonLanding
                style={{
                  marginBottom: 10,
                }}
                label={i18n(
                  'LandingTopSection.signupForFreeButton',
                  'Sign up for free',
                )}
                onClick={() => {
                  setSignUpStep(SignUpStep.RegistrationUser);
                  navigate(`/signup`);
                }}
              />
            )}
            <GiButtonLanding
              inverted
              style={{
                marginBottom: 10,
              }}
              label={i18n('LandingTopSection.getDemo', 'Get a demo')}
              onClick={() => {
                const e = document.getElementById('GET_DEMO_ID');
                e?.scrollIntoView({
                  block: 'start',
                  behavior: 'smooth',
                  inline: 'start',
                });
              }}
            />
          </ButtonsRow>
          <SocialLinks>
            <ATag target="_blank" href={urls.facebook}>
              <SocialLogo src={images.socialFb} />
            </ATag>
            <ATag target="_blank" href={urls.instagram}>
              <SocialLogo src={images.socialInsta} />
            </ATag>
          </SocialLinks>
        </LeftSection>
        {!uiStore.isMobile && (
          <LandingRightSection>
            <Carousel>
              {[
                { img: images.heroA },
                { img: images.heroB },
                { img: images.heroC },
                { img: images.heroD },
              ].map((image) => (
                <Carousel.Item key={image.img}>
                  <CarouselHeroImage img={image.img} />
                </Carousel.Item>
              ))}
            </Carousel>
          </LandingRightSection>
        )}
      </PurpleRectangle>
    </Main>
  );
});

const Main = styled.div<{ calculatedHeightOfContainer: number }>`
  width: 100%;
  max-width: 1440px;
  height: ${(props) => props.calculatedHeightOfContainer}px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  position: relative;
  padding: 22px 22px 34px 34px;
  @media (max-width: ${(props) => props.theme.breakPoints.tablet}px) {
    height: 100%;
  }
  @media (max-width: ${(props) => props.theme.breakPoints.mobile}px) {
    padding: 20px 25px 30px 30px;
    width: 100%;
  }
`;

const PurpleRectangle = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
  border-radius: 30px;
  position: relative;
  background-image: url(${images.backgroundBlue});
  box-shadow: -5px -5px linear-gradient(92.68deg, #D4BDF4 1.7%, #F0AE26 105.42%);
  &::before {
    content: "";
    position: absolute;
    top: 15px;
    right: 15px;
    width: 100%;
    height: 100%;
    border-radius: 30px;
    background: linear-gradient(92.68deg, #D4BDF4 1.7%, #F0AE26 105.42%);
    z-index: -3;
  }
  @media (max-width: ${(props) => props.theme.breakPoints.tablet}px) {
    padding: 4rem 1rem;
    justify-content: space-around;
    align-items: center;
  }
  @media (max-width: ${(props) => props.theme.breakPoints.mobile}px) {
    width: 100%;
`;

const LeftSection = styled.div`
  display: flex;
  height: 50%;
  width: 581px;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-evenly;
  margin-left: auto;
  margin-right: 50px;
  @media (max-width: ${(props) => props.theme.breakPoints.tablet}px) {
    width: 581px;
    height: 500px;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin-left: 1rem;
    margin-right: 10px;
  }
  @media (max-width: ${(props) => props.theme.breakPoints.mobile}px) {
    margin: 1rem;
    width: 100%;
    height: 70%;
  }
`;

const ButtonsRow = styled.div<{ isMobile: boolean }>`
  width: 434px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-content: center;
  margin-top: 25px;
  @media (max-width: ${(props) => props.theme.breakPoints.tablet}px) {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-top: 50px;
    margin-left: 0;
  }
  @media (max-width: ${(props) => props.theme.breakPoints.mobile}px) {
    width: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
  }
`;

const SocialLinks = styled.div`
  display: flex;
  flex-direction: row;
  width: 50px;
  margin-top: 20px;
  justify-content: space-between;
  align-self: flex-start;
`;

const SocialLogo = styled.img`
  width: 20px;
  height: 20px;
  margin-right: 10px;
  filter: invert(99%) sepia(0%) saturate(2%) hue-rotate(38deg) brightness(116%)
    contrast(100%);
`;

const LandingRightSection = styled.div`
  width: 380px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-right: auto;
  @media (max-width: ${(props) => props.theme.breakPoints.tablet}px) {
    margin-right: 0;
  }
`;

const CarouselHeroImage = styled.div<{ img: string }>`
  width: 380px;
  height: 430px;
  background-image: url(${(props) => props.img});
  background-size: cover;
`;

export default LandingTopSection;
