import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { ClipLoader } from 'react-spinners';
import styled from 'styled-components';
import colors from '../../constants/colors';
import i18n from '../../constants/i18n';
import { UseUserStore } from '../../stores/user';
import sexpressClient from '../../utils/sexpress';
import { TextH5 } from '../common/Typography';
import GiglinkedButton from '../common/GiglinkedButton';

const BankingStripeReturn = () => {
  const userStore = UseUserStore();
  const navigate = useNavigate();

  const [loading, setLoading] = React.useState(true);
  const [isErrored, setIsErrored] = React.useState(false);

  const checkIfAccountIsValid = async () => {
    try {
      setLoading(true);
      const res = await sexpressClient.checkIfStripeAccountCreationIsValid();
      if (res.error) setIsErrored(true);
    } catch (e) {
      console.error(e);
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  React.useEffect(() => {
    checkIfAccountIsValid();
  }, []);

  const onClickProfileButton = () => {
    if (userStore?.me?.artist) {
      navigate(`/artists/${userStore.me!.artist.id}`);
    }
  };

  const loadingComponents = (
    <Main>
      <TextH5 style={{ fontWeight: 500, marginBottom: 16 }}>
        {i18n(
          'SupportScreen.savingYourStripeAccount...',
          'Saving your Stripe account...',
        )}
      </TextH5>
      <ClipLoader color={colors.black} size={36} />
    </Main>
  );

  const successComponents = (
    <Main>
      <TextH5 style={{ fontWeight: 500, marginBottom: 16 }}>
        {i18n(
          'SupportScreen.yourAccountIsNowLinkedWithYourBank!',
          'Your account is now linked with your bank!',
        )}
      </TextH5>
      <GiglinkedButton
        onClick={onClickProfileButton}
        label={i18n('SupportScreen.goToProfile', 'Go to profile')}
      />
    </Main>
  );

  const errorComponents = (
    <Main>
      <TextH5 style={{ fontWeight: 500, marginBottom: 16 }}>
        {i18n(
          'SupportScreen.yourAccountIsNotFinishedConfiguring!',
          'Your bank account is not entirely finished configuring, go to profile and finalize the configuration!',
        )}
      </TextH5>
      <GiglinkedButton
        onClick={onClickProfileButton}
        label={i18n('SupportScreen.goToProfile', 'Go to profile')}
      />
    </Main>
  );

  if (loading) return loadingComponents;
  return isErrored ? errorComponents : successComponents;
};

const Main = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: center;
  flex: 1;
  width: 1000px;
`;

export default BankingStripeReturn;
