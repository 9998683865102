import { observer } from 'mobx-react';
import React, { Dispatch, SetStateAction } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import colors from '../../../constants/colors';
import images from '../../../constants/images';
import { useUiStore } from '../../../stores/ui';

import NavBarContent from './NavBarContent';

const NavBar = observer(
  ({
    navBarOffset,
    setIsDrawerShown,
  }: {
    navBarOffset: number;
    setIsDrawerShown: Dispatch<SetStateAction<boolean>>;
  }) => {
    const navigate = useNavigate();
    const { isMobile, isTablet, isPopupShown, navBarHeight } = useUiStore();

    if (isPopupShown && (isMobile || isTablet)) {
      return null;
    }

    return (
      <Main navBarHeight={navBarHeight} isMobile={isMobile}>
        <StyledRowContainer
          navBarHeight={navBarHeight}
          navBarOffset={navBarOffset}
          isMobile={isMobile}
        >
          <GigLinkedLogo
            src={images.giglinkedLogoBlack}
            onClick={() => navigate('/')}
          />
          <NavBarContentContainer>
            <NavBarContent setIsDrawerShown={setIsDrawerShown} />
          </NavBarContentContainer>
        </StyledRowContainer>
      </Main>
    );
  },
);

const Main = styled.div<{
  navBarHeight: number;
  isMobile: boolean;
}>`
  display: flex;
  height: 56px;
  width: 100%;
  background: ${colors.white};
  z-index: 10;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const StyledRowContainer = styled.div<{
  navBarHeight: number;
  navBarOffset: number;
  isMobile: boolean;
}>`
  display: flex;
  height: 56px;
  width: 100%;
  align-items: center;
  position: fixed;
  background: ${colors.white};
  justify-content: space-between;
`;

const GigLinkedLogo = styled.img`
  width: 147px;
  height: 34px;
  margin-left: 80px;
  :hover {
    cursor: pointer;
  }
  @media (max-width: ${(props) => props.theme.breakPoints.tablet}px) {
    margin-left: 40px;
  }
  @media (max-width: ${(props) => props.theme.breakPoints.mobile}px) {
    margin-left: 1rem;
  }
`;

const NavBarContentContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;

export default NavBar;
