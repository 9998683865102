import * as React from 'react';
import { Box } from 'react-native-kondo';
import { ClipLoader } from 'react-spinners';
import colors from '../../constants/colors';
import i18n from '../../constants/i18n';
import { ActionType, Transaction, TransactionStatus } from '../../shared';
import { useUiStore } from '../../stores/ui';
import sexpressClient from '../../utils/sexpress';
import { TextH3 } from '../common/Typography';
import DeclinePopup from '../requestDetailsScreen/requestDetailsQuoteSection/DeclinePopup';
import SupportSubjectDropDown from '../support/SupportSubjectDropDown';
import RequestCell from './RequestCell';
import RequestStatusBarSelector from './RequestStatusBarSelector';
import toast from 'react-hot-toast';
import { isArrayEmpty } from '../../utils/arrays';

const RequestListing = ({
  status,
  setCurrentStatus,
}: {
  status: TransactionStatus;
  setCurrentStatus: (newStatus: TransactionStatus) => void;
}) => {
  const { openPopup } = useUiStore();
  const [transactions, setTransactions] = React.useState<Transaction[]>([]);
  const [isLoading, setIsLoading] = React.useState(false);
  const [currentPage, setCurrentPage] = React.useState(1);
  const [totalPages, setTotalPages] = React.useState(1);

  const fetchTransactions = React.useCallback(async () => {
    try {
      let resTransactions: Transaction[] = [];
      let resTotalPages = 1;

      setTransactions([]);
      setIsLoading(true);

      if (status === 'quotePending' || status === 'quoteSent') {
        const promiseRes = await Promise.all([
          sexpressClient.getTransactions({
            status: 'quotePending',
            page: currentPage,
          }),
          sexpressClient.getTransactions({
            status: 'quoteSent',
            page: currentPage,
          }),
          sexpressClient.getTransactions({
            status: 'quoteDeclined',
            page: currentPage,
          }),
        ]);
        promiseRes.forEach((res) => {
          if (res.totalPages > resTotalPages) {
            resTotalPages = res.totalPages;
          }
          resTransactions = [
            ...resTransactions,
            ...res.userTransactions,
            ...res.artistTransactions,
            ...res.actTransactions,
          ];
        });
      } else if (status === 'accepted') {
        const res = await sexpressClient.getTransactions({
          status,
          page: currentPage,
        });
        resTotalPages = res.totalPages || 1;
        resTransactions = [
          ...res.userTransactions,
          ...res.artistTransactions,
          ...res.actTransactions,
        ];
      } else {
        const promiseRes = await Promise.all([
          sexpressClient.getTransactions({
            status: 'cancelled',
            page: currentPage,
          }),
          sexpressClient.getTransactions({
            status: 'declined',
            page: currentPage,
          }),
          sexpressClient.getTransactions({
            status: 'expired',
            page: currentPage,
          }),
          sexpressClient.getTransactions({
            status: 'refunded',
            page: currentPage,
          }),
          sexpressClient.getTransactions({
            status: 'completed',
            page: currentPage,
          }),
        ]);
        promiseRes.forEach((res) => {
          if (res.totalPages > resTotalPages) {
            resTotalPages = res.totalPages;
          }
          resTransactions = [
            ...resTransactions,
            ...res.userTransactions,
            ...res.artistTransactions,
            ...res.actTransactions,
          ];
        });
      }

      resTransactions = resTransactions.sort(
        (a: Transaction, b: Transaction) =>
          new Date(a.eventDate).getTime() - new Date(b.eventDate).getTime(),
      );
      setTotalPages(resTotalPages);
      setTransactions(resTransactions);
    } catch (e) {
      const err = e as Error;
      toast.error(err.message);
    } finally {
      setIsLoading(false);
    }
  }, [status, currentPage]);

  const renderEmpty = () => {
    let content = (
      <TextH3 style={{ color: colors.black }}>
        {i18n('RequestListing.noNew', 'You have no past requests')}
      </TextH3>
    );

    if (status === 'accepted') {
      content = (
        <TextH3 style={{ color: colors.black }}>
          {i18n('RequestListing.noNew', 'You have no accepted request')}
        </TextH3>
      );
    }

    if (status === 'quoteSent' || status === 'quotePending') {
      content = (
        <TextH3 style={{ color: colors.black }}>
          {i18n('RequestListing.noNew', 'You have no pending request')}
        </TextH3>
      );
    }

    return (
      <Box alignSelf="center" mt={40}>
        {content}
      </Box>
    );
  };

  React.useEffect(() => {
    if (isLoading) {
      return;
    }
    setCurrentPage(1);
    fetchTransactions();
  }, [status]);

  React.useEffect(() => {
    if (isLoading) {
      return;
    }

    fetchTransactions();
  }, [currentPage]);

  const onAction = async ({
    transaction,
    action,
  }: {
    transaction: Transaction;
    action: ActionType;
  }) => {
    if (['decline'].includes(action)) {
      openPopup({
        popupDisableTapOut: true,
        renderContent: () => (
          <DeclinePopup
            request={transaction}
            fetchTransaction={fetchTransactions}
          />
        ),
      });
    } else {
      try {
        setTransactions([]);
        setIsLoading(true);
        await sexpressClient.updateTransactionState({
          action,
          transactionId: transaction.id,
        });
        fetchTransactions();
      } catch (e) {
        const err = e as Error;
        toast.error(err.message);
      }
    }
  };

  return (
    <Box>
      <RequestStatusBarSelector
        status={status}
        onChangeStatus={setCurrentStatus}
      />
      <SupportSubjectDropDown
        style={{
          width: '100%',
          maxWidth: 200,
          alignSelf: 'flex-end',
          marginBottom: 24,
        }}
        placeholder="Page"
        items={Array.from({ length: totalPages }, (_, i) => ({
          label: `Page ${i + 1}`,
          value: i + 1,
        }))}
        value={`Page ${currentPage}`}
        onChange={(item) => {
          if (item.value !== currentPage) {
            setCurrentPage(item.value);
          }
        }}
      />
      {transactions.map((transaction, index) => (
        <RequestCell
          key={`${transaction.id}-${index}`}
          transaction={transaction}
          onAction={onAction}
        />
      ))}
      {isLoading ? (
        <Box style={{ alignSelf: 'center', marginTop: 40 }}>
          <ClipLoader color={colors.purple} size={48} />
        </Box>
      ) : null}
      {isArrayEmpty(transactions) && !isLoading ? renderEmpty() : null}
    </Box>
  );
};

export default RequestListing;
