export default {
  'Validator.invalidEmail': {
    en: 'Invalid email format.',
    fr: "Format d'email invalide.",
  },
  'Validator.invalidPassword': {
    en: 'Invalid password.',
    fr: 'Mot de passe incorrect.',
  },
  'Validator.invalidPasswordLength': {
    en: 'Password must have at least 8 characters.',
    fr: 'Le mot de passe doit avoir au moins 8 caractères.',
  },
};
