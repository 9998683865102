import { observer } from 'mobx-react';
import * as React from 'react';
import styled from 'styled-components';
import colors from '../../../../../constants/colors';
import i18n from '../../../../../constants/i18n';
import images from '../../../../../constants/images';
import { GeneralMediumLarge } from '../../../../common/Typography';
import { UseUserStore } from '../../../../../stores/user';
import { SignUpStep } from '../../../../../shared';
import GiAuthContainer from '../../../../common/GiAuthContainer';

const RegistrationMusicianSoloOrGroup = observer(() => {
  const userStore = UseUserStore();
  const { setSignUpStep } = userStore;

  return (
    <GiAuthContainer
      title={i18n(
        'RegistrationMusicianSoloOrGroup.profileFor',
        'Who is this profile for',
      )}
      secondaryText={i18n(
        'RegistrationMusicianSoloOrGroup.underTitle',
        'If you select "a group," an individual profile will be created by default. You can edit this later.',
      )}
      editableContent={
        <BoxTwoButtons>
          <BoxButton
            onClick={() =>
              setSignUpStep(SignUpStep.StepperRegistrationSoloAbout)
            }
          >
            <TextBox>
              <GeneralMediumLarge style={{ color: colors.black }}>
                {i18n('RegistrationMusicianSoloOrGroup.meButton', 'Just me')}
              </GeneralMediumLarge>
            </TextBox>
            <Icon src={images.registrationMusicianSoloImage} />
          </BoxButton>
          <BoxButton
            onClick={() =>
              setSignUpStep(SignUpStep.StepperRegistrationGroupAbout)
            }
          >
            <TextBox>
              <GeneralMediumLarge style={{ color: colors.black }}>
                {i18n('RegistrationMusicianSoloOrGroup.groupButton', 'A group')}
              </GeneralMediumLarge>
            </TextBox>
            <Icon src={images.registrationMusicianGroupImage} />
          </BoxButton>
        </BoxTwoButtons>
      }
    />
  );
});

const BoxTwoButtons = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-evenly;
  position: relative;
  @media (max-width: ${(props) => props.theme.breakPoints.mobile}px) {
    width: 300px;
    height: 300px;
    align-items: center;
  }
`;

const BoxButton = styled.div`
  display: flex;
  position: relative;
  flex-direction: row;
  width: 190px;
  height: 125px;
  align-items: center;
  background: ${colors.white};
  justify-content: space-evenly;
  border: 2px solid ${colors.buttonBorder};
  border-radius: 10px;
  padding: 20px;
  margin: 10px;
  transition: 0.3s;
  z-index: 2;
  &:hover {
    transform: scale(1.02);
    transition: all 0.4s ease-out;
    cursor: pointer;
    text-decoration: none;
    border: 2px solid ${colors.red};
    box-shadow: -5px 5px ${colors.red};
  }
`;

const TextBox = styled.div`
  align-self: center;
  text-align: start;
`;

const Icon = styled.img`
  width: 70px;
  height: 70px;
`;

export default RegistrationMusicianSoloOrGroup;
