export default {
  'MusicianNewEventCard.apply': { en: 'Apply', fr: 'Appliquer' },
  'MusicianNewEventCard.lessDetails': {
    en: 'Less details',
    fr: 'Moins de détails',
  },
  'MusicianNewEventCard.moreDetails': {
    en: 'More details',
    fr: 'Plus de détails',
  },
  'MusicianNewEventCard.created': { en: 'Created:', fr: 'Créé:' },
  'MusicianNewEventCard.proposals': { en: 'Proposals:', fr: 'Propositions :' },
  'MusicianNewEventCard.approxBudget': {
    en: 'Approx budget',
    fr: 'Budget approximatif',
  },
  'MusicianNewEventCard.arrival': { en: 'Arrival ', fr: 'Arrivée ' },
  'MusicianNewEventCard.equipment': { en: 'Equipment ', fr: 'Equipement ' },
  'MusicianNewEventCard.outdoor': { en: 'Outdoor ', fr: 'Extérieur ' },
  'MusicianNewEventCard.covered': { en: 'covered ', fr: 'couvert ' },
  'MusicianNewEventCard.uncovered': { en: 'uncovered ', fr: 'à découvert' },
  'MusicianNewEventCard.indoor': { en: 'Indoor ', fr: 'Intérieur ' },
  'MusicianNewEventCard.eventIsDone': {
    en: 'This event is completed. You cannot apply to it.',
    fr: 'Cet événement est terminé. Vous ne pouvez pas vous y inscrire.',
  },
};
