export default {
  'RequestDetailsReviewSection.reviewSent!': {
    en: 'Review sent!',
    fr: 'Commentaire envoyé!',
  },
  'RequestDetailsReviewSection.reviewUpdated!': {
    en: 'Review updated!',
    fr: 'Réviser mis à jour!',
  },
  'RequestDetailsReviewSection.letUsKnow': {
    en: 'Let us know how it went!',
    fr: "Faites-nous savoir comment ça s'est passé!",
  },
  'RequestDetailsReviewSection.howWouldYouRateYourGig': {
    en: 'How would you rate your gig?',
    fr: 'Comment évalueriez-vous votre concert?',
  },
  'RequestDetailsReviewSection.leaveAReview': {
    en: 'Leave a review',
    fr: 'Laisser un examen',
  },
  'RequestDetailsReviewSection.edit': { en: 'Edit', fr: 'Modifier' },
  'RequestDetailsReviewSection.send': { en: 'Send', fr: 'Envoyer' },
};
