/* eslint-disable no-useless-escape */

import { observer } from 'mobx-react';
import * as React from 'react';
import { useUiStore } from '../../stores/ui';

const TermsAndConditionPage = observer(() => {
  const uiStore = useUiStore();
  return (
    <div
      dangerouslySetInnerHTML={{ __html: html }}
      style={{ padding: 20, paddingTop: uiStore.isMobile ? 130 : 20 }}
    />
  );
});

export default TermsAndConditionPage;

const html = `<html>

<head>
<meta http-equiv=Content-Type content="text/html; charset=utf-8">
<meta name=Generator content="Microsoft Word 15 (filtered)">
<style>
<!--
 /* Font Definitions */
 @font-face
	{font-family:Wingdings;
	panose-1:5 0 0 0 0 0 0 0 0 0;}
@font-face
	{font-family:SimSun;
	panose-1:2 1 6 0 3 1 1 1 1 1;}
@font-face
	{font-family:"Cambria Math";
	panose-1:2 4 5 3 5 4 6 3 2 4;}
@font-face
	{font-family:Calibri;
	panose-1:2 15 5 2 2 2 4 3 2 4;}
@font-face
	{font-family:Times;
	panose-1:2 2 6 3 5 4 5 2 3 4;}
@font-face
	{font-family:"Segoe UI";
	panose-1:2 11 5 2 4 2 4 2 2 3;}
@font-face
	{font-family:Consolas;
	panose-1:2 11 6 9 2 2 4 3 2 4;}
@font-face
	{font-family:Tahoma;
	panose-1:2 11 6 4 3 5 4 4 2 4;}
@font-face
	{font-family:"\@SimSun";
	panose-1:2 1 6 0 3 1 1 1 1 1;}
 /* Style Definitions */
 p.MsoNormal, li.MsoNormal, div.MsoNormal
	{margin-top:0in;
	margin-right:0in;
	margin-bottom:12.0pt;
	margin-left:0in;
	text-align:justify;
	font-size:12.0pt;
	font-family:"Times New Roman",serif;
	color:#1F2F3C;}
h1
	{mso-style-link:"Heading 1 Char";
	margin-top:12.0pt;
	margin-right:0in;
	margin-bottom:0in;
	margin-left:0in;
	text-align:justify;
	page-break-after:avoid;
	font-size:16.0pt;
	font-family:"Calibri Light",sans-serif;
	color:#2F5496;
	font-weight:normal;}
h2
	{mso-style-link:"Heading 2 Char";
	margin-top:2.0pt;
	margin-right:0in;
	margin-bottom:0in;
	margin-left:0in;
	text-align:justify;
	page-break-after:avoid;
	font-size:13.0pt;
	font-family:"Calibri Light",sans-serif;
	color:#2F5496;
	font-weight:normal;}
h3
	{mso-style-link:"Heading 3 Char";
	margin-top:2.0pt;
	margin-right:0in;
	margin-bottom:0in;
	margin-left:0in;
	text-align:justify;
	page-break-after:avoid;
	font-size:12.0pt;
	font-family:"Calibri Light",sans-serif;
	color:#1F3763;
	font-weight:normal;}
h4
	{mso-style-link:"Heading 4 Char";
	margin-top:10.0pt;
	margin-right:0in;
	margin-bottom:0in;
	margin-left:0in;
	text-align:justify;
	page-break-after:avoid;
	font-size:12.0pt;
	font-family:"Calibri Light",sans-serif;
	color:#4472C4;
	font-weight:bold;
	font-style:italic;}
h5
	{mso-style-link:"Heading 5 Char";
	margin-top:10.0pt;
	margin-right:0in;
	margin-bottom:0in;
	margin-left:0in;
	text-align:justify;
	page-break-after:avoid;
	font-size:12.0pt;
	font-family:"Calibri Light",sans-serif;
	color:#1F3763;
	font-weight:normal;}
h6
	{mso-style-link:"Heading 6 Char";
	margin-top:10.0pt;
	margin-right:0in;
	margin-bottom:0in;
	margin-left:0in;
	text-align:justify;
	page-break-after:avoid;
	font-size:12.0pt;
	font-family:"Calibri Light",sans-serif;
	color:#1F3763;
	font-weight:normal;
	font-style:italic;}
p.MsoHeading7, li.MsoHeading7, div.MsoHeading7
	{mso-style-link:"Heading 7 Char";
	margin-top:10.0pt;
	margin-right:0in;
	margin-bottom:0in;
	margin-left:0in;
	text-align:justify;
	page-break-after:avoid;
	font-size:12.0pt;
	font-family:"Calibri Light",sans-serif;
	color:#404040;
	font-style:italic;}
p.MsoHeading8, li.MsoHeading8, div.MsoHeading8
	{mso-style-link:"Heading 8 Char";
	margin-top:10.0pt;
	margin-right:0in;
	margin-bottom:0in;
	margin-left:0in;
	text-align:justify;
	page-break-after:avoid;
	font-size:10.0pt;
	font-family:"Calibri Light",sans-serif;
	color:#404040;}
p.MsoHeading9, li.MsoHeading9, div.MsoHeading9
	{mso-style-link:"Heading 9 Char";
	margin-top:10.0pt;
	margin-right:0in;
	margin-bottom:0in;
	margin-left:0in;
	text-align:justify;
	page-break-after:avoid;
	font-size:10.0pt;
	font-family:"Calibri Light",sans-serif;
	color:#404040;
	font-style:italic;}
p.MsoIndex1, li.MsoIndex1, div.MsoIndex1
	{margin-top:0in;
	margin-right:0in;
	margin-bottom:0in;
	margin-left:11.0pt;
	text-align:justify;
	text-indent:-11.0pt;
	font-size:12.0pt;
	font-family:"Times New Roman",serif;
	color:#1F2F3C;}
p.MsoIndex2, li.MsoIndex2, div.MsoIndex2
	{margin-top:0in;
	margin-right:0in;
	margin-bottom:0in;
	margin-left:22.0pt;
	text-align:justify;
	text-indent:-11.0pt;
	font-size:12.0pt;
	font-family:"Times New Roman",serif;
	color:#1F2F3C;}
p.MsoIndex3, li.MsoIndex3, div.MsoIndex3
	{margin-top:0in;
	margin-right:0in;
	margin-bottom:0in;
	margin-left:33.0pt;
	text-align:justify;
	text-indent:-11.0pt;
	font-size:12.0pt;
	font-family:"Times New Roman",serif;
	color:#1F2F3C;}
p.MsoIndex4, li.MsoIndex4, div.MsoIndex4
	{margin-top:0in;
	margin-right:0in;
	margin-bottom:0in;
	margin-left:44.0pt;
	text-align:justify;
	text-indent:-11.0pt;
	font-size:12.0pt;
	font-family:"Times New Roman",serif;
	color:#1F2F3C;}
p.MsoIndex5, li.MsoIndex5, div.MsoIndex5
	{margin-top:0in;
	margin-right:0in;
	margin-bottom:0in;
	margin-left:55.0pt;
	text-align:justify;
	text-indent:-11.0pt;
	font-size:12.0pt;
	font-family:"Times New Roman",serif;
	color:#1F2F3C;}
p.MsoIndex6, li.MsoIndex6, div.MsoIndex6
	{margin-top:0in;
	margin-right:0in;
	margin-bottom:0in;
	margin-left:66.0pt;
	text-align:justify;
	text-indent:-11.0pt;
	font-size:12.0pt;
	font-family:"Times New Roman",serif;
	color:#1F2F3C;}
p.MsoIndex7, li.MsoIndex7, div.MsoIndex7
	{margin-top:0in;
	margin-right:0in;
	margin-bottom:0in;
	margin-left:77.0pt;
	text-align:justify;
	text-indent:-11.0pt;
	font-size:12.0pt;
	font-family:"Times New Roman",serif;
	color:#1F2F3C;}
p.MsoIndex8, li.MsoIndex8, div.MsoIndex8
	{margin-top:0in;
	margin-right:0in;
	margin-bottom:0in;
	margin-left:88.0pt;
	text-align:justify;
	text-indent:-11.0pt;
	font-size:12.0pt;
	font-family:"Times New Roman",serif;
	color:#1F2F3C;}
p.MsoIndex9, li.MsoIndex9, div.MsoIndex9
	{margin-top:0in;
	margin-right:0in;
	margin-bottom:0in;
	margin-left:99.0pt;
	text-align:justify;
	text-indent:-11.0pt;
	font-size:12.0pt;
	font-family:"Times New Roman",serif;
	color:#1F2F3C;}
p.MsoToc1, li.MsoToc1, div.MsoToc1
	{margin-top:0in;
	margin-right:0in;
	margin-bottom:5.0pt;
	margin-left:0in;
	text-align:justify;
	font-size:12.0pt;
	font-family:"Times New Roman",serif;
	color:#1F2F3C;}
p.MsoToc2, li.MsoToc2, div.MsoToc2
	{margin-top:0in;
	margin-right:0in;
	margin-bottom:5.0pt;
	margin-left:11.0pt;
	text-align:justify;
	font-size:12.0pt;
	font-family:"Times New Roman",serif;
	color:#1F2F3C;}
p.MsoToc3, li.MsoToc3, div.MsoToc3
	{margin-top:0in;
	margin-right:0in;
	margin-bottom:5.0pt;
	margin-left:22.0pt;
	text-align:justify;
	font-size:12.0pt;
	font-family:"Times New Roman",serif;
	color:#1F2F3C;}
p.MsoToc4, li.MsoToc4, div.MsoToc4
	{margin-top:0in;
	margin-right:0in;
	margin-bottom:5.0pt;
	margin-left:33.0pt;
	text-align:justify;
	font-size:12.0pt;
	font-family:"Times New Roman",serif;
	color:#1F2F3C;}
p.MsoToc5, li.MsoToc5, div.MsoToc5
	{margin-top:0in;
	margin-right:0in;
	margin-bottom:5.0pt;
	margin-left:44.0pt;
	text-align:justify;
	font-size:12.0pt;
	font-family:"Times New Roman",serif;
	color:#1F2F3C;}
p.MsoToc6, li.MsoToc6, div.MsoToc6
	{margin-top:0in;
	margin-right:0in;
	margin-bottom:5.0pt;
	margin-left:55.0pt;
	text-align:justify;
	font-size:12.0pt;
	font-family:"Times New Roman",serif;
	color:#1F2F3C;}
p.MsoToc7, li.MsoToc7, div.MsoToc7
	{margin-top:0in;
	margin-right:0in;
	margin-bottom:5.0pt;
	margin-left:66.0pt;
	text-align:justify;
	font-size:12.0pt;
	font-family:"Times New Roman",serif;
	color:#1F2F3C;}
p.MsoToc8, li.MsoToc8, div.MsoToc8
	{margin-top:0in;
	margin-right:0in;
	margin-bottom:5.0pt;
	margin-left:77.0pt;
	text-align:justify;
	font-size:12.0pt;
	font-family:"Times New Roman",serif;
	color:#1F2F3C;}
p.MsoToc9, li.MsoToc9, div.MsoToc9
	{margin-top:0in;
	margin-right:0in;
	margin-bottom:5.0pt;
	margin-left:88.0pt;
	text-align:justify;
	font-size:12.0pt;
	font-family:"Times New Roman",serif;
	color:#1F2F3C;}
p.MsoNormalIndent, li.MsoNormalIndent, div.MsoNormalIndent
	{margin-top:0in;
	margin-right:0in;
	margin-bottom:12.0pt;
	margin-left:.5in;
	text-align:justify;
	font-size:12.0pt;
	font-family:"Times New Roman",serif;
	color:#1F2F3C;}
p.MsoFootnoteText, li.MsoFootnoteText, div.MsoFootnoteText
	{mso-style-link:"Footnote Text Char";
	margin:0in;
	text-align:justify;
	font-size:10.0pt;
	font-family:"Times New Roman",serif;
	color:#1F2F3C;}
p.MsoCommentText, li.MsoCommentText, div.MsoCommentText
	{mso-style-link:"Comment Text Char";
	margin-top:0in;
	margin-right:0in;
	margin-bottom:12.0pt;
	margin-left:0in;
	text-align:justify;
	font-size:10.0pt;
	font-family:"Times New Roman",serif;
	color:#1F2F3C;}
p.MsoHeader, li.MsoHeader, div.MsoHeader
	{mso-style-link:"Header Char";
	margin:0in;
	text-align:justify;
	font-size:12.0pt;
	font-family:"Times New Roman",serif;
	color:#1F2F3C;}
p.MsoFooter, li.MsoFooter, div.MsoFooter
	{mso-style-link:"Footer Char";
	margin:0in;
	text-align:justify;
	font-size:12.0pt;
	font-family:"Times New Roman",serif;
	color:#1F2F3C;}
p.MsoIndexHeading, li.MsoIndexHeading, div.MsoIndexHeading
	{margin-top:0in;
	margin-right:0in;
	margin-bottom:12.0pt;
	margin-left:0in;
	text-align:justify;
	font-size:12.0pt;
	font-family:"Calibri Light",sans-serif;
	color:#1F2F3C;
	font-weight:bold;}
p.MsoCaption, li.MsoCaption, div.MsoCaption
	{margin-top:0in;
	margin-right:0in;
	margin-bottom:10.0pt;
	margin-left:0in;
	text-align:justify;
	font-size:9.0pt;
	font-family:"Times New Roman",serif;
	color:#4472C4;
	font-weight:bold;}
p.MsoTof, li.MsoTof, div.MsoTof
	{margin:0in;
	text-align:justify;
	font-size:12.0pt;
	font-family:"Times New Roman",serif;
	color:#1F2F3C;}
p.MsoEnvelopeAddress, li.MsoEnvelopeAddress, div.MsoEnvelopeAddress
	{margin-top:0in;
	margin-right:0in;
	margin-bottom:0in;
	margin-left:2.0in;
	text-align:justify;
	font-size:12.0pt;
	font-family:"Calibri Light",sans-serif;
	color:#1F2F3C;}
p.MsoEnvelopeReturn, li.MsoEnvelopeReturn, div.MsoEnvelopeReturn
	{margin:0in;
	text-align:justify;
	font-size:10.0pt;
	font-family:"Calibri Light",sans-serif;
	color:#1F2F3C;}
span.MsoFootnoteReference
	{vertical-align:super;}
span.MsoEndnoteReference
	{vertical-align:super;}
p.MsoEndnoteText, li.MsoEndnoteText, div.MsoEndnoteText
	{mso-style-link:"Endnote Text Char";
	margin:0in;
	text-align:justify;
	font-size:10.0pt;
	font-family:"Times New Roman",serif;
	color:#1F2F3C;}
p.MsoToa, li.MsoToa, div.MsoToa
	{margin-top:0in;
	margin-right:0in;
	margin-bottom:0in;
	margin-left:11.0pt;
	text-align:justify;
	text-indent:-11.0pt;
	font-size:12.0pt;
	font-family:"Times New Roman",serif;
	color:#1F2F3C;}
p.MsoMacroText, li.MsoMacroText, div.MsoMacroText
	{mso-style-link:"Macro Text Char";
	margin:0in;
	text-align:left;
	line-height:107%;
	font-size:10.0pt;
	font-family:Consolas;
	color:windowtext;}
p.MsoToaHeading, li.MsoToaHeading, div.MsoToaHeading
	{margin-top:6.0pt;
	margin-right:0in;
	margin-bottom:12.0pt;
	margin-left:0in;
	text-align:justify;
	font-size:12.0pt;
	font-family:"Calibri Light",sans-serif;
	color:#1F2F3C;
	font-weight:bold;}
p.MsoList, li.MsoList, div.MsoList
	{margin-top:0in;
	margin-right:0in;
	margin-bottom:12.0pt;
	margin-left:14.15pt;
	text-align:justify;
	text-indent:-14.15pt;
	font-size:12.0pt;
	font-family:"Times New Roman",serif;
	color:#1F2F3C;}
p.MsoListCxSpFirst, li.MsoListCxSpFirst, div.MsoListCxSpFirst
	{margin-top:0in;
	margin-right:0in;
	margin-bottom:0in;
	margin-left:14.15pt;
	text-align:justify;
	text-indent:-14.15pt;
	font-size:12.0pt;
	font-family:"Times New Roman",serif;
	color:#1F2F3C;}
p.MsoListCxSpMiddle, li.MsoListCxSpMiddle, div.MsoListCxSpMiddle
	{margin-top:0in;
	margin-right:0in;
	margin-bottom:0in;
	margin-left:14.15pt;
	text-align:justify;
	text-indent:-14.15pt;
	font-size:12.0pt;
	font-family:"Times New Roman",serif;
	color:#1F2F3C;}
p.MsoListCxSpLast, li.MsoListCxSpLast, div.MsoListCxSpLast
	{margin-top:0in;
	margin-right:0in;
	margin-bottom:12.0pt;
	margin-left:14.15pt;
	text-align:justify;
	text-indent:-14.15pt;
	font-size:12.0pt;
	font-family:"Times New Roman",serif;
	color:#1F2F3C;}
p.MsoListBullet, li.MsoListBullet, div.MsoListBullet
	{margin-top:0in;
	margin-right:0in;
	margin-bottom:12.0pt;
	margin-left:.25in;
	text-align:justify;
	text-indent:-.25in;
	font-size:12.0pt;
	font-family:"Times New Roman",serif;
	color:#1F2F3C;}
p.MsoListBulletCxSpFirst, li.MsoListBulletCxSpFirst, div.MsoListBulletCxSpFirst
	{margin-top:0in;
	margin-right:0in;
	margin-bottom:0in;
	margin-left:.25in;
	text-align:justify;
	text-indent:-.25in;
	font-size:12.0pt;
	font-family:"Times New Roman",serif;
	color:#1F2F3C;}
p.MsoListBulletCxSpMiddle, li.MsoListBulletCxSpMiddle, div.MsoListBulletCxSpMiddle
	{margin-top:0in;
	margin-right:0in;
	margin-bottom:0in;
	margin-left:.25in;
	text-align:justify;
	text-indent:-.25in;
	font-size:12.0pt;
	font-family:"Times New Roman",serif;
	color:#1F2F3C;}
p.MsoListBulletCxSpLast, li.MsoListBulletCxSpLast, div.MsoListBulletCxSpLast
	{margin-top:0in;
	margin-right:0in;
	margin-bottom:12.0pt;
	margin-left:.25in;
	text-align:justify;
	text-indent:-.25in;
	font-size:12.0pt;
	font-family:"Times New Roman",serif;
	color:#1F2F3C;}
p.MsoListNumber, li.MsoListNumber, div.MsoListNumber
	{margin-top:0in;
	margin-right:0in;
	margin-bottom:12.0pt;
	margin-left:.25in;
	text-align:justify;
	text-indent:-.25in;
	font-size:12.0pt;
	font-family:"Times New Roman",serif;
	color:#1F2F3C;}
p.MsoListNumberCxSpFirst, li.MsoListNumberCxSpFirst, div.MsoListNumberCxSpFirst
	{margin-top:0in;
	margin-right:0in;
	margin-bottom:0in;
	margin-left:.25in;
	text-align:justify;
	text-indent:-.25in;
	font-size:12.0pt;
	font-family:"Times New Roman",serif;
	color:#1F2F3C;}
p.MsoListNumberCxSpMiddle, li.MsoListNumberCxSpMiddle, div.MsoListNumberCxSpMiddle
	{margin-top:0in;
	margin-right:0in;
	margin-bottom:0in;
	margin-left:.25in;
	text-align:justify;
	text-indent:-.25in;
	font-size:12.0pt;
	font-family:"Times New Roman",serif;
	color:#1F2F3C;}
p.MsoListNumberCxSpLast, li.MsoListNumberCxSpLast, div.MsoListNumberCxSpLast
	{margin-top:0in;
	margin-right:0in;
	margin-bottom:12.0pt;
	margin-left:.25in;
	text-align:justify;
	text-indent:-.25in;
	font-size:12.0pt;
	font-family:"Times New Roman",serif;
	color:#1F2F3C;}
p.MsoList2, li.MsoList2, div.MsoList2
	{margin-top:0in;
	margin-right:0in;
	margin-bottom:12.0pt;
	margin-left:28.3pt;
	text-align:justify;
	text-indent:-14.15pt;
	font-size:12.0pt;
	font-family:"Times New Roman",serif;
	color:#1F2F3C;}
p.MsoList2CxSpFirst, li.MsoList2CxSpFirst, div.MsoList2CxSpFirst
	{margin-top:0in;
	margin-right:0in;
	margin-bottom:0in;
	margin-left:28.3pt;
	text-align:justify;
	text-indent:-14.15pt;
	font-size:12.0pt;
	font-family:"Times New Roman",serif;
	color:#1F2F3C;}
p.MsoList2CxSpMiddle, li.MsoList2CxSpMiddle, div.MsoList2CxSpMiddle
	{margin-top:0in;
	margin-right:0in;
	margin-bottom:0in;
	margin-left:28.3pt;
	text-align:justify;
	text-indent:-14.15pt;
	font-size:12.0pt;
	font-family:"Times New Roman",serif;
	color:#1F2F3C;}
p.MsoList2CxSpLast, li.MsoList2CxSpLast, div.MsoList2CxSpLast
	{margin-top:0in;
	margin-right:0in;
	margin-bottom:12.0pt;
	margin-left:28.3pt;
	text-align:justify;
	text-indent:-14.15pt;
	font-size:12.0pt;
	font-family:"Times New Roman",serif;
	color:#1F2F3C;}
p.MsoList3, li.MsoList3, div.MsoList3
	{margin-top:0in;
	margin-right:0in;
	margin-bottom:12.0pt;
	margin-left:42.45pt;
	text-align:justify;
	text-indent:-14.15pt;
	font-size:12.0pt;
	font-family:"Times New Roman",serif;
	color:#1F2F3C;}
p.MsoList3CxSpFirst, li.MsoList3CxSpFirst, div.MsoList3CxSpFirst
	{margin-top:0in;
	margin-right:0in;
	margin-bottom:0in;
	margin-left:42.45pt;
	text-align:justify;
	text-indent:-14.15pt;
	font-size:12.0pt;
	font-family:"Times New Roman",serif;
	color:#1F2F3C;}
p.MsoList3CxSpMiddle, li.MsoList3CxSpMiddle, div.MsoList3CxSpMiddle
	{margin-top:0in;
	margin-right:0in;
	margin-bottom:0in;
	margin-left:42.45pt;
	text-align:justify;
	text-indent:-14.15pt;
	font-size:12.0pt;
	font-family:"Times New Roman",serif;
	color:#1F2F3C;}
p.MsoList3CxSpLast, li.MsoList3CxSpLast, div.MsoList3CxSpLast
	{margin-top:0in;
	margin-right:0in;
	margin-bottom:12.0pt;
	margin-left:42.45pt;
	text-align:justify;
	text-indent:-14.15pt;
	font-size:12.0pt;
	font-family:"Times New Roman",serif;
	color:#1F2F3C;}
p.MsoList4, li.MsoList4, div.MsoList4
	{margin-top:0in;
	margin-right:0in;
	margin-bottom:12.0pt;
	margin-left:56.6pt;
	text-align:justify;
	text-indent:-14.15pt;
	font-size:12.0pt;
	font-family:"Times New Roman",serif;
	color:#1F2F3C;}
p.MsoList4CxSpFirst, li.MsoList4CxSpFirst, div.MsoList4CxSpFirst
	{margin-top:0in;
	margin-right:0in;
	margin-bottom:0in;
	margin-left:56.6pt;
	text-align:justify;
	text-indent:-14.15pt;
	font-size:12.0pt;
	font-family:"Times New Roman",serif;
	color:#1F2F3C;}
p.MsoList4CxSpMiddle, li.MsoList4CxSpMiddle, div.MsoList4CxSpMiddle
	{margin-top:0in;
	margin-right:0in;
	margin-bottom:0in;
	margin-left:56.6pt;
	text-align:justify;
	text-indent:-14.15pt;
	font-size:12.0pt;
	font-family:"Times New Roman",serif;
	color:#1F2F3C;}
p.MsoList4CxSpLast, li.MsoList4CxSpLast, div.MsoList4CxSpLast
	{margin-top:0in;
	margin-right:0in;
	margin-bottom:12.0pt;
	margin-left:56.6pt;
	text-align:justify;
	text-indent:-14.15pt;
	font-size:12.0pt;
	font-family:"Times New Roman",serif;
	color:#1F2F3C;}
p.MsoList5, li.MsoList5, div.MsoList5
	{margin-top:0in;
	margin-right:0in;
	margin-bottom:12.0pt;
	margin-left:70.75pt;
	text-align:justify;
	text-indent:-14.15pt;
	font-size:12.0pt;
	font-family:"Times New Roman",serif;
	color:#1F2F3C;}
p.MsoList5CxSpFirst, li.MsoList5CxSpFirst, div.MsoList5CxSpFirst
	{margin-top:0in;
	margin-right:0in;
	margin-bottom:0in;
	margin-left:70.75pt;
	text-align:justify;
	text-indent:-14.15pt;
	font-size:12.0pt;
	font-family:"Times New Roman",serif;
	color:#1F2F3C;}
p.MsoList5CxSpMiddle, li.MsoList5CxSpMiddle, div.MsoList5CxSpMiddle
	{margin-top:0in;
	margin-right:0in;
	margin-bottom:0in;
	margin-left:70.75pt;
	text-align:justify;
	text-indent:-14.15pt;
	font-size:12.0pt;
	font-family:"Times New Roman",serif;
	color:#1F2F3C;}
p.MsoList5CxSpLast, li.MsoList5CxSpLast, div.MsoList5CxSpLast
	{margin-top:0in;
	margin-right:0in;
	margin-bottom:12.0pt;
	margin-left:70.75pt;
	text-align:justify;
	text-indent:-14.15pt;
	font-size:12.0pt;
	font-family:"Times New Roman",serif;
	color:#1F2F3C;}
p.MsoListBullet2, li.MsoListBullet2, div.MsoListBullet2
	{margin-top:0in;
	margin-right:0in;
	margin-bottom:12.0pt;
	margin-left:32.15pt;
	text-align:justify;
	text-indent:-.25in;
	font-size:12.0pt;
	font-family:"Times New Roman",serif;
	color:#1F2F3C;}
p.MsoListBullet2CxSpFirst, li.MsoListBullet2CxSpFirst, div.MsoListBullet2CxSpFirst
	{margin-top:0in;
	margin-right:0in;
	margin-bottom:0in;
	margin-left:32.15pt;
	text-align:justify;
	text-indent:-.25in;
	font-size:12.0pt;
	font-family:"Times New Roman",serif;
	color:#1F2F3C;}
p.MsoListBullet2CxSpMiddle, li.MsoListBullet2CxSpMiddle, div.MsoListBullet2CxSpMiddle
	{margin-top:0in;
	margin-right:0in;
	margin-bottom:0in;
	margin-left:32.15pt;
	text-align:justify;
	text-indent:-.25in;
	font-size:12.0pt;
	font-family:"Times New Roman",serif;
	color:#1F2F3C;}
p.MsoListBullet2CxSpLast, li.MsoListBullet2CxSpLast, div.MsoListBullet2CxSpLast
	{margin-top:0in;
	margin-right:0in;
	margin-bottom:12.0pt;
	margin-left:32.15pt;
	text-align:justify;
	text-indent:-.25in;
	font-size:12.0pt;
	font-family:"Times New Roman",serif;
	color:#1F2F3C;}
p.MsoListBullet3, li.MsoListBullet3, div.MsoListBullet3
	{margin-top:0in;
	margin-right:0in;
	margin-bottom:12.0pt;
	margin-left:46.3pt;
	text-align:justify;
	text-indent:-.25in;
	font-size:12.0pt;
	font-family:"Times New Roman",serif;
	color:#1F2F3C;}
p.MsoListBullet3CxSpFirst, li.MsoListBullet3CxSpFirst, div.MsoListBullet3CxSpFirst
	{margin-top:0in;
	margin-right:0in;
	margin-bottom:0in;
	margin-left:46.3pt;
	text-align:justify;
	text-indent:-.25in;
	font-size:12.0pt;
	font-family:"Times New Roman",serif;
	color:#1F2F3C;}
p.MsoListBullet3CxSpMiddle, li.MsoListBullet3CxSpMiddle, div.MsoListBullet3CxSpMiddle
	{margin-top:0in;
	margin-right:0in;
	margin-bottom:0in;
	margin-left:46.3pt;
	text-align:justify;
	text-indent:-.25in;
	font-size:12.0pt;
	font-family:"Times New Roman",serif;
	color:#1F2F3C;}
p.MsoListBullet3CxSpLast, li.MsoListBullet3CxSpLast, div.MsoListBullet3CxSpLast
	{margin-top:0in;
	margin-right:0in;
	margin-bottom:12.0pt;
	margin-left:46.3pt;
	text-align:justify;
	text-indent:-.25in;
	font-size:12.0pt;
	font-family:"Times New Roman",serif;
	color:#1F2F3C;}
p.MsoListBullet4, li.MsoListBullet4, div.MsoListBullet4
	{margin-top:0in;
	margin-right:0in;
	margin-bottom:12.0pt;
	margin-left:60.45pt;
	text-align:justify;
	text-indent:-.25in;
	font-size:12.0pt;
	font-family:"Times New Roman",serif;
	color:#1F2F3C;}
p.MsoListBullet4CxSpFirst, li.MsoListBullet4CxSpFirst, div.MsoListBullet4CxSpFirst
	{margin-top:0in;
	margin-right:0in;
	margin-bottom:0in;
	margin-left:60.45pt;
	text-align:justify;
	text-indent:-.25in;
	font-size:12.0pt;
	font-family:"Times New Roman",serif;
	color:#1F2F3C;}
p.MsoListBullet4CxSpMiddle, li.MsoListBullet4CxSpMiddle, div.MsoListBullet4CxSpMiddle
	{margin-top:0in;
	margin-right:0in;
	margin-bottom:0in;
	margin-left:60.45pt;
	text-align:justify;
	text-indent:-.25in;
	font-size:12.0pt;
	font-family:"Times New Roman",serif;
	color:#1F2F3C;}
p.MsoListBullet4CxSpLast, li.MsoListBullet4CxSpLast, div.MsoListBullet4CxSpLast
	{margin-top:0in;
	margin-right:0in;
	margin-bottom:12.0pt;
	margin-left:60.45pt;
	text-align:justify;
	text-indent:-.25in;
	font-size:12.0pt;
	font-family:"Times New Roman",serif;
	color:#1F2F3C;}
p.MsoListBullet5, li.MsoListBullet5, div.MsoListBullet5
	{margin-top:0in;
	margin-right:0in;
	margin-bottom:12.0pt;
	margin-left:74.6pt;
	text-align:justify;
	text-indent:-.25in;
	font-size:12.0pt;
	font-family:"Times New Roman",serif;
	color:#1F2F3C;}
p.MsoListBullet5CxSpFirst, li.MsoListBullet5CxSpFirst, div.MsoListBullet5CxSpFirst
	{margin-top:0in;
	margin-right:0in;
	margin-bottom:0in;
	margin-left:74.6pt;
	text-align:justify;
	text-indent:-.25in;
	font-size:12.0pt;
	font-family:"Times New Roman",serif;
	color:#1F2F3C;}
p.MsoListBullet5CxSpMiddle, li.MsoListBullet5CxSpMiddle, div.MsoListBullet5CxSpMiddle
	{margin-top:0in;
	margin-right:0in;
	margin-bottom:0in;
	margin-left:74.6pt;
	text-align:justify;
	text-indent:-.25in;
	font-size:12.0pt;
	font-family:"Times New Roman",serif;
	color:#1F2F3C;}
p.MsoListBullet5CxSpLast, li.MsoListBullet5CxSpLast, div.MsoListBullet5CxSpLast
	{margin-top:0in;
	margin-right:0in;
	margin-bottom:12.0pt;
	margin-left:74.6pt;
	text-align:justify;
	text-indent:-.25in;
	font-size:12.0pt;
	font-family:"Times New Roman",serif;
	color:#1F2F3C;}
p.MsoListNumber2, li.MsoListNumber2, div.MsoListNumber2
	{margin-top:0in;
	margin-right:0in;
	margin-bottom:12.0pt;
	margin-left:32.15pt;
	text-align:justify;
	text-indent:-.25in;
	font-size:12.0pt;
	font-family:"Times New Roman",serif;
	color:#1F2F3C;}
p.MsoListNumber2CxSpFirst, li.MsoListNumber2CxSpFirst, div.MsoListNumber2CxSpFirst
	{margin-top:0in;
	margin-right:0in;
	margin-bottom:0in;
	margin-left:32.15pt;
	text-align:justify;
	text-indent:-.25in;
	font-size:12.0pt;
	font-family:"Times New Roman",serif;
	color:#1F2F3C;}
p.MsoListNumber2CxSpMiddle, li.MsoListNumber2CxSpMiddle, div.MsoListNumber2CxSpMiddle
	{margin-top:0in;
	margin-right:0in;
	margin-bottom:0in;
	margin-left:32.15pt;
	text-align:justify;
	text-indent:-.25in;
	font-size:12.0pt;
	font-family:"Times New Roman",serif;
	color:#1F2F3C;}
p.MsoListNumber2CxSpLast, li.MsoListNumber2CxSpLast, div.MsoListNumber2CxSpLast
	{margin-top:0in;
	margin-right:0in;
	margin-bottom:12.0pt;
	margin-left:32.15pt;
	text-align:justify;
	text-indent:-.25in;
	font-size:12.0pt;
	font-family:"Times New Roman",serif;
	color:#1F2F3C;}
p.MsoListNumber3, li.MsoListNumber3, div.MsoListNumber3
	{margin-top:0in;
	margin-right:0in;
	margin-bottom:12.0pt;
	margin-left:46.3pt;
	text-align:justify;
	text-indent:-.25in;
	font-size:12.0pt;
	font-family:"Times New Roman",serif;
	color:#1F2F3C;}
p.MsoListNumber3CxSpFirst, li.MsoListNumber3CxSpFirst, div.MsoListNumber3CxSpFirst
	{margin-top:0in;
	margin-right:0in;
	margin-bottom:0in;
	margin-left:46.3pt;
	text-align:justify;
	text-indent:-.25in;
	font-size:12.0pt;
	font-family:"Times New Roman",serif;
	color:#1F2F3C;}
p.MsoListNumber3CxSpMiddle, li.MsoListNumber3CxSpMiddle, div.MsoListNumber3CxSpMiddle
	{margin-top:0in;
	margin-right:0in;
	margin-bottom:0in;
	margin-left:46.3pt;
	text-align:justify;
	text-indent:-.25in;
	font-size:12.0pt;
	font-family:"Times New Roman",serif;
	color:#1F2F3C;}
p.MsoListNumber3CxSpLast, li.MsoListNumber3CxSpLast, div.MsoListNumber3CxSpLast
	{margin-top:0in;
	margin-right:0in;
	margin-bottom:12.0pt;
	margin-left:46.3pt;
	text-align:justify;
	text-indent:-.25in;
	font-size:12.0pt;
	font-family:"Times New Roman",serif;
	color:#1F2F3C;}
p.MsoListNumber4, li.MsoListNumber4, div.MsoListNumber4
	{margin-top:0in;
	margin-right:0in;
	margin-bottom:12.0pt;
	margin-left:60.45pt;
	text-align:justify;
	text-indent:-.25in;
	font-size:12.0pt;
	font-family:"Times New Roman",serif;
	color:#1F2F3C;}
p.MsoListNumber4CxSpFirst, li.MsoListNumber4CxSpFirst, div.MsoListNumber4CxSpFirst
	{margin-top:0in;
	margin-right:0in;
	margin-bottom:0in;
	margin-left:60.45pt;
	text-align:justify;
	text-indent:-.25in;
	font-size:12.0pt;
	font-family:"Times New Roman",serif;
	color:#1F2F3C;}
p.MsoListNumber4CxSpMiddle, li.MsoListNumber4CxSpMiddle, div.MsoListNumber4CxSpMiddle
	{margin-top:0in;
	margin-right:0in;
	margin-bottom:0in;
	margin-left:60.45pt;
	text-align:justify;
	text-indent:-.25in;
	font-size:12.0pt;
	font-family:"Times New Roman",serif;
	color:#1F2F3C;}
p.MsoListNumber4CxSpLast, li.MsoListNumber4CxSpLast, div.MsoListNumber4CxSpLast
	{margin-top:0in;
	margin-right:0in;
	margin-bottom:12.0pt;
	margin-left:60.45pt;
	text-align:justify;
	text-indent:-.25in;
	font-size:12.0pt;
	font-family:"Times New Roman",serif;
	color:#1F2F3C;}
p.MsoListNumber5, li.MsoListNumber5, div.MsoListNumber5
	{margin-top:0in;
	margin-right:0in;
	margin-bottom:12.0pt;
	margin-left:74.6pt;
	text-align:justify;
	text-indent:-.25in;
	font-size:12.0pt;
	font-family:"Times New Roman",serif;
	color:#1F2F3C;}
p.MsoListNumber5CxSpFirst, li.MsoListNumber5CxSpFirst, div.MsoListNumber5CxSpFirst
	{margin-top:0in;
	margin-right:0in;
	margin-bottom:0in;
	margin-left:74.6pt;
	text-align:justify;
	text-indent:-.25in;
	font-size:12.0pt;
	font-family:"Times New Roman",serif;
	color:#1F2F3C;}
p.MsoListNumber5CxSpMiddle, li.MsoListNumber5CxSpMiddle, div.MsoListNumber5CxSpMiddle
	{margin-top:0in;
	margin-right:0in;
	margin-bottom:0in;
	margin-left:74.6pt;
	text-align:justify;
	text-indent:-.25in;
	font-size:12.0pt;
	font-family:"Times New Roman",serif;
	color:#1F2F3C;}
p.MsoListNumber5CxSpLast, li.MsoListNumber5CxSpLast, div.MsoListNumber5CxSpLast
	{margin-top:0in;
	margin-right:0in;
	margin-bottom:12.0pt;
	margin-left:74.6pt;
	text-align:justify;
	text-indent:-.25in;
	font-size:12.0pt;
	font-family:"Times New Roman",serif;
	color:#1F2F3C;}
p.MsoTitle, li.MsoTitle, div.MsoTitle
	{mso-style-link:"Title Char";
	margin-top:0in;
	margin-right:0in;
	margin-bottom:15.0pt;
	margin-left:0in;
	text-align:justify;
	border:none;
	padding:0in;
	font-size:26.0pt;
	font-family:"Calibri Light",sans-serif;
	color:#323E4F;
	letter-spacing:.25pt;}
p.MsoTitleCxSpFirst, li.MsoTitleCxSpFirst, div.MsoTitleCxSpFirst
	{mso-style-link:"Title Char";
	margin:0in;
	text-align:justify;
	border:none;
	padding:0in;
	font-size:26.0pt;
	font-family:"Calibri Light",sans-serif;
	color:#323E4F;
	letter-spacing:.25pt;}
p.MsoTitleCxSpMiddle, li.MsoTitleCxSpMiddle, div.MsoTitleCxSpMiddle
	{mso-style-link:"Title Char";
	margin:0in;
	text-align:justify;
	border:none;
	padding:0in;
	font-size:26.0pt;
	font-family:"Calibri Light",sans-serif;
	color:#323E4F;
	letter-spacing:.25pt;}
p.MsoTitleCxSpLast, li.MsoTitleCxSpLast, div.MsoTitleCxSpLast
	{mso-style-link:"Title Char";
	margin-top:0in;
	margin-right:0in;
	margin-bottom:15.0pt;
	margin-left:0in;
	text-align:justify;
	border:none;
	padding:0in;
	font-size:26.0pt;
	font-family:"Calibri Light",sans-serif;
	color:#323E4F;
	letter-spacing:.25pt;}
p.MsoClosing, li.MsoClosing, div.MsoClosing
	{mso-style-link:"Closing Char";
	margin-top:0in;
	margin-right:0in;
	margin-bottom:0in;
	margin-left:212.6pt;
	text-align:justify;
	font-size:12.0pt;
	font-family:"Times New Roman",serif;
	color:#1F2F3C;}
p.MsoSignature, li.MsoSignature, div.MsoSignature
	{mso-style-link:"Signature Char";
	margin-top:0in;
	margin-right:0in;
	margin-bottom:0in;
	margin-left:212.6pt;
	text-align:justify;
	font-size:12.0pt;
	font-family:"Times New Roman",serif;
	color:#1F2F3C;}
p.MsoBodyText, li.MsoBodyText, div.MsoBodyText
	{mso-style-link:"Body Text Char";
	margin-top:0in;
	margin-right:0in;
	margin-bottom:6.0pt;
	margin-left:0in;
	text-align:justify;
	font-size:12.0pt;
	font-family:"Times New Roman",serif;
	color:#1F2F3C;}
p.MsoBodyTextIndent, li.MsoBodyTextIndent, div.MsoBodyTextIndent
	{mso-style-link:"Body Text Indent Char";
	margin-top:0in;
	margin-right:0in;
	margin-bottom:6.0pt;
	margin-left:14.15pt;
	text-align:justify;
	font-size:12.0pt;
	font-family:"Times New Roman",serif;
	color:#1F2F3C;}
p.MsoListContinue, li.MsoListContinue, div.MsoListContinue
	{margin-top:0in;
	margin-right:0in;
	margin-bottom:6.0pt;
	margin-left:14.15pt;
	text-align:justify;
	font-size:12.0pt;
	font-family:"Times New Roman",serif;
	color:#1F2F3C;}
p.MsoListContinueCxSpFirst, li.MsoListContinueCxSpFirst, div.MsoListContinueCxSpFirst
	{margin-top:0in;
	margin-right:0in;
	margin-bottom:0in;
	margin-left:14.15pt;
	text-align:justify;
	font-size:12.0pt;
	font-family:"Times New Roman",serif;
	color:#1F2F3C;}
p.MsoListContinueCxSpMiddle, li.MsoListContinueCxSpMiddle, div.MsoListContinueCxSpMiddle
	{margin-top:0in;
	margin-right:0in;
	margin-bottom:0in;
	margin-left:14.15pt;
	text-align:justify;
	font-size:12.0pt;
	font-family:"Times New Roman",serif;
	color:#1F2F3C;}
p.MsoListContinueCxSpLast, li.MsoListContinueCxSpLast, div.MsoListContinueCxSpLast
	{margin-top:0in;
	margin-right:0in;
	margin-bottom:6.0pt;
	margin-left:14.15pt;
	text-align:justify;
	font-size:12.0pt;
	font-family:"Times New Roman",serif;
	color:#1F2F3C;}
p.MsoListContinue2, li.MsoListContinue2, div.MsoListContinue2
	{margin-top:0in;
	margin-right:0in;
	margin-bottom:6.0pt;
	margin-left:28.3pt;
	text-align:justify;
	font-size:12.0pt;
	font-family:"Times New Roman",serif;
	color:#1F2F3C;}
p.MsoListContinue2CxSpFirst, li.MsoListContinue2CxSpFirst, div.MsoListContinue2CxSpFirst
	{margin-top:0in;
	margin-right:0in;
	margin-bottom:0in;
	margin-left:28.3pt;
	text-align:justify;
	font-size:12.0pt;
	font-family:"Times New Roman",serif;
	color:#1F2F3C;}
p.MsoListContinue2CxSpMiddle, li.MsoListContinue2CxSpMiddle, div.MsoListContinue2CxSpMiddle
	{margin-top:0in;
	margin-right:0in;
	margin-bottom:0in;
	margin-left:28.3pt;
	text-align:justify;
	font-size:12.0pt;
	font-family:"Times New Roman",serif;
	color:#1F2F3C;}
p.MsoListContinue2CxSpLast, li.MsoListContinue2CxSpLast, div.MsoListContinue2CxSpLast
	{margin-top:0in;
	margin-right:0in;
	margin-bottom:6.0pt;
	margin-left:28.3pt;
	text-align:justify;
	font-size:12.0pt;
	font-family:"Times New Roman",serif;
	color:#1F2F3C;}
p.MsoListContinue3, li.MsoListContinue3, div.MsoListContinue3
	{margin-top:0in;
	margin-right:0in;
	margin-bottom:6.0pt;
	margin-left:42.45pt;
	text-align:justify;
	font-size:12.0pt;
	font-family:"Times New Roman",serif;
	color:#1F2F3C;}
p.MsoListContinue3CxSpFirst, li.MsoListContinue3CxSpFirst, div.MsoListContinue3CxSpFirst
	{margin-top:0in;
	margin-right:0in;
	margin-bottom:0in;
	margin-left:42.45pt;
	text-align:justify;
	font-size:12.0pt;
	font-family:"Times New Roman",serif;
	color:#1F2F3C;}
p.MsoListContinue3CxSpMiddle, li.MsoListContinue3CxSpMiddle, div.MsoListContinue3CxSpMiddle
	{margin-top:0in;
	margin-right:0in;
	margin-bottom:0in;
	margin-left:42.45pt;
	text-align:justify;
	font-size:12.0pt;
	font-family:"Times New Roman",serif;
	color:#1F2F3C;}
p.MsoListContinue3CxSpLast, li.MsoListContinue3CxSpLast, div.MsoListContinue3CxSpLast
	{margin-top:0in;
	margin-right:0in;
	margin-bottom:6.0pt;
	margin-left:42.45pt;
	text-align:justify;
	font-size:12.0pt;
	font-family:"Times New Roman",serif;
	color:#1F2F3C;}
p.MsoListContinue4, li.MsoListContinue4, div.MsoListContinue4
	{margin-top:0in;
	margin-right:0in;
	margin-bottom:6.0pt;
	margin-left:56.6pt;
	text-align:justify;
	font-size:12.0pt;
	font-family:"Times New Roman",serif;
	color:#1F2F3C;}
p.MsoListContinue4CxSpFirst, li.MsoListContinue4CxSpFirst, div.MsoListContinue4CxSpFirst
	{margin-top:0in;
	margin-right:0in;
	margin-bottom:0in;
	margin-left:56.6pt;
	text-align:justify;
	font-size:12.0pt;
	font-family:"Times New Roman",serif;
	color:#1F2F3C;}
p.MsoListContinue4CxSpMiddle, li.MsoListContinue4CxSpMiddle, div.MsoListContinue4CxSpMiddle
	{margin-top:0in;
	margin-right:0in;
	margin-bottom:0in;
	margin-left:56.6pt;
	text-align:justify;
	font-size:12.0pt;
	font-family:"Times New Roman",serif;
	color:#1F2F3C;}
p.MsoListContinue4CxSpLast, li.MsoListContinue4CxSpLast, div.MsoListContinue4CxSpLast
	{margin-top:0in;
	margin-right:0in;
	margin-bottom:6.0pt;
	margin-left:56.6pt;
	text-align:justify;
	font-size:12.0pt;
	font-family:"Times New Roman",serif;
	color:#1F2F3C;}
p.MsoListContinue5, li.MsoListContinue5, div.MsoListContinue5
	{margin-top:0in;
	margin-right:0in;
	margin-bottom:6.0pt;
	margin-left:70.75pt;
	text-align:justify;
	font-size:12.0pt;
	font-family:"Times New Roman",serif;
	color:#1F2F3C;}
p.MsoListContinue5CxSpFirst, li.MsoListContinue5CxSpFirst, div.MsoListContinue5CxSpFirst
	{margin-top:0in;
	margin-right:0in;
	margin-bottom:0in;
	margin-left:70.75pt;
	text-align:justify;
	font-size:12.0pt;
	font-family:"Times New Roman",serif;
	color:#1F2F3C;}
p.MsoListContinue5CxSpMiddle, li.MsoListContinue5CxSpMiddle, div.MsoListContinue5CxSpMiddle
	{margin-top:0in;
	margin-right:0in;
	margin-bottom:0in;
	margin-left:70.75pt;
	text-align:justify;
	font-size:12.0pt;
	font-family:"Times New Roman",serif;
	color:#1F2F3C;}
p.MsoListContinue5CxSpLast, li.MsoListContinue5CxSpLast, div.MsoListContinue5CxSpLast
	{margin-top:0in;
	margin-right:0in;
	margin-bottom:6.0pt;
	margin-left:70.75pt;
	text-align:justify;
	font-size:12.0pt;
	font-family:"Times New Roman",serif;
	color:#1F2F3C;}
p.MsoMessageHeader, li.MsoMessageHeader, div.MsoMessageHeader
	{mso-style-link:"Message Header Char";
	margin-top:0in;
	margin-right:0in;
	margin-bottom:0in;
	margin-left:56.7pt;
	text-align:justify;
	text-indent:-56.7pt;
	background:#CCCCCC;
	border:none;
	padding:0in;
	font-size:12.0pt;
	font-family:"Calibri Light",sans-serif;
	color:#1F2F3C;}
p.MsoSubtitle, li.MsoSubtitle, div.MsoSubtitle
	{mso-style-link:"Subtitle Char";
	margin-top:0in;
	margin-right:0in;
	margin-bottom:12.0pt;
	margin-left:0in;
	text-align:justify;
	font-size:12.0pt;
	font-family:"Calibri Light",sans-serif;
	color:#4472C4;
	letter-spacing:.75pt;
	font-style:italic;}
p.MsoSalutation, li.MsoSalutation, div.MsoSalutation
	{mso-style-link:"Salutation Char";
	margin-top:0in;
	margin-right:0in;
	margin-bottom:12.0pt;
	margin-left:0in;
	text-align:justify;
	font-size:12.0pt;
	font-family:"Times New Roman",serif;
	color:#1F2F3C;}
p.MsoDate, li.MsoDate, div.MsoDate
	{mso-style-link:"Date Char";
	margin-top:0in;
	margin-right:0in;
	margin-bottom:12.0pt;
	margin-left:0in;
	text-align:justify;
	font-size:12.0pt;
	font-family:"Times New Roman",serif;
	color:#1F2F3C;}
p.MsoBodyTextFirstIndent, li.MsoBodyTextFirstIndent, div.MsoBodyTextFirstIndent
	{mso-style-link:"Body Text First Indent Char";
	margin-top:0in;
	margin-right:0in;
	margin-bottom:8.0pt;
	margin-left:0in;
	text-align:justify;
	text-indent:.25in;
	font-size:12.0pt;
	font-family:"Times New Roman",serif;
	color:#1F2F3C;}
p.MsoBodyTextFirstIndent2, li.MsoBodyTextFirstIndent2, div.MsoBodyTextFirstIndent2
	{mso-style-link:"Body Text First Indent 2 Char";
	margin-top:0in;
	margin-right:0in;
	margin-bottom:8.0pt;
	margin-left:.25in;
	text-align:justify;
	text-indent:.25in;
	font-size:12.0pt;
	font-family:"Times New Roman",serif;
	color:#1F2F3C;}
p.MsoNoteHeading, li.MsoNoteHeading, div.MsoNoteHeading
	{mso-style-link:"Note Heading Char";
	margin:0in;
	text-align:justify;
	font-size:12.0pt;
	font-family:"Times New Roman",serif;
	color:#1F2F3C;}
p.MsoBodyText2, li.MsoBodyText2, div.MsoBodyText2
	{mso-style-link:"Body Text 2 Char";
	margin-top:0in;
	margin-right:0in;
	margin-bottom:6.0pt;
	margin-left:0in;
	text-align:justify;
	line-height:200%;
	font-size:12.0pt;
	font-family:"Times New Roman",serif;
	color:#1F2F3C;}
p.MsoBodyText3, li.MsoBodyText3, div.MsoBodyText3
	{mso-style-link:"Body Text 3 Char";
	margin-top:0in;
	margin-right:0in;
	margin-bottom:6.0pt;
	margin-left:0in;
	text-align:justify;
	font-size:8.0pt;
	font-family:"Times New Roman",serif;
	color:#1F2F3C;}
p.MsoBodyTextIndent2, li.MsoBodyTextIndent2, div.MsoBodyTextIndent2
	{mso-style-link:"Body Text Indent 2 Char";
	margin-top:0in;
	margin-right:0in;
	margin-bottom:6.0pt;
	margin-left:14.15pt;
	text-align:justify;
	line-height:200%;
	font-size:12.0pt;
	font-family:"Times New Roman",serif;
	color:#1F2F3C;}
p.MsoBodyTextIndent3, li.MsoBodyTextIndent3, div.MsoBodyTextIndent3
	{mso-style-link:"Body Text Indent 3 Char";
	margin-top:0in;
	margin-right:0in;
	margin-bottom:6.0pt;
	margin-left:14.15pt;
	text-align:justify;
	font-size:8.0pt;
	font-family:"Times New Roman",serif;
	color:#1F2F3C;}
p.MsoBlockText, li.MsoBlockText, div.MsoBlockText
	{margin-top:0in;
	margin-right:.8in;
	margin-bottom:12.0pt;
	margin-left:.8in;
	text-align:justify;
	border:none;
	padding:0in;
	font-size:12.0pt;
	font-family:"Times New Roman",serif;
	color:#4472C4;
	font-style:italic;}
a:link, span.MsoHyperlink
	{color:#0563C1;
	text-decoration:underline;}
a:visited, span.MsoHyperlinkFollowed
	{color:#954F72;
	text-decoration:underline;}
p.MsoDocumentMap, li.MsoDocumentMap, div.MsoDocumentMap
	{mso-style-link:"Document Map Char";
	margin:0in;
	text-align:justify;
	font-size:8.0pt;
	font-family:"Tahoma",sans-serif;
	color:#1F2F3C;}
p.MsoPlainText, li.MsoPlainText, div.MsoPlainText
	{mso-style-link:"Plain Text Char";
	margin:0in;
	text-align:justify;
	font-size:10.5pt;
	font-family:Consolas;
	color:#1F2F3C;}
p.MsoAutoSig, li.MsoAutoSig, div.MsoAutoSig
	{mso-style-link:"E-mail Signature Char";
	margin:0in;
	text-align:justify;
	font-size:12.0pt;
	font-family:"Times New Roman",serif;
	color:#1F2F3C;}
p
	{margin-top:0in;
	margin-right:0in;
	margin-bottom:12.0pt;
	margin-left:0in;
	text-align:justify;
	font-size:12.0pt;
	font-family:"Times New Roman",serif;
	color:#1F2F3C;}
address
	{mso-style-link:"HTML Address Char";
	margin:0in;
	text-align:justify;
	font-size:12.0pt;
	font-family:"Times New Roman",serif;
	color:#1F2F3C;
	font-style:italic;}
code
	{font-family:Consolas;}
kbd
	{font-family:Consolas;}
pre
	{mso-style-link:"HTML Preformatted Char";
	margin:0in;
	text-align:justify;
	font-size:10.0pt;
	font-family:Consolas;
	color:#1F2F3C;}
samp
	{font-family:Consolas;}
tt
	{font-family:Consolas;}
p.MsoCommentSubject, li.MsoCommentSubject, div.MsoCommentSubject
	{mso-style-link:"Comment Subject Char";
	margin-top:0in;
	margin-right:0in;
	margin-bottom:12.0pt;
	margin-left:0in;
	text-align:justify;
	font-size:10.0pt;
	font-family:"Times New Roman",serif;
	color:#1F2F3C;
	font-weight:bold;}
p.MsoAcetate, li.MsoAcetate, div.MsoAcetate
	{mso-style-link:"Balloon Text Char";
	margin:0in;
	text-align:justify;
	font-size:9.0pt;
	font-family:"Segoe UI",sans-serif;
	color:#1F2F3C;}
span.MsoPlaceholderText
	{color:gray;}
p.MsoNoSpacing, li.MsoNoSpacing, div.MsoNoSpacing
	{margin:0in;
	text-align:left;
	font-size:11.0pt;
	font-family:"Calibri",sans-serif;
	color:windowtext;}
p.MsoRMPane, li.MsoRMPane, div.MsoRMPane
	{margin:0in;
	text-align:left;
	font-size:11.0pt;
	font-family:"Calibri",sans-serif;
	color:windowtext;}
p.MsoListParagraph, li.MsoListParagraph, div.MsoListParagraph
	{margin-top:0in;
	margin-right:0in;
	margin-bottom:12.0pt;
	margin-left:.5in;
	text-align:justify;
	font-size:12.0pt;
	font-family:"Times New Roman",serif;
	color:#1F2F3C;}
p.MsoListParagraphCxSpFirst, li.MsoListParagraphCxSpFirst, div.MsoListParagraphCxSpFirst
	{margin-top:0in;
	margin-right:0in;
	margin-bottom:0in;
	margin-left:.5in;
	text-align:justify;
	font-size:12.0pt;
	font-family:"Times New Roman",serif;
	color:#1F2F3C;}
p.MsoListParagraphCxSpMiddle, li.MsoListParagraphCxSpMiddle, div.MsoListParagraphCxSpMiddle
	{margin-top:0in;
	margin-right:0in;
	margin-bottom:0in;
	margin-left:.5in;
	text-align:justify;
	font-size:12.0pt;
	font-family:"Times New Roman",serif;
	color:#1F2F3C;}
p.MsoListParagraphCxSpLast, li.MsoListParagraphCxSpLast, div.MsoListParagraphCxSpLast
	{margin-top:0in;
	margin-right:0in;
	margin-bottom:12.0pt;
	margin-left:.5in;
	text-align:justify;
	font-size:12.0pt;
	font-family:"Times New Roman",serif;
	color:#1F2F3C;}
p.MsoQuote, li.MsoQuote, div.MsoQuote
	{mso-style-link:"Quote Char";
	margin-top:0in;
	margin-right:0in;
	margin-bottom:12.0pt;
	margin-left:0in;
	text-align:justify;
	font-size:12.0pt;
	font-family:"Times New Roman",serif;
	color:black;
	font-style:italic;}
p.MsoIntenseQuote, li.MsoIntenseQuote, div.MsoIntenseQuote
	{mso-style-link:"Intense Quote Char";
	margin-top:10.0pt;
	margin-right:.65in;
	margin-bottom:14.0pt;
	margin-left:.65in;
	text-align:justify;
	border:none;
	padding:0in;
	font-size:12.0pt;
	font-family:"Times New Roman",serif;
	color:#4472C4;
	font-weight:bold;
	font-style:italic;}
span.MsoSubtleEmphasis
	{color:gray;
	font-style:italic;}
span.MsoIntenseEmphasis
	{color:#4472C4;
	font-weight:bold;
	font-style:italic;}
span.MsoSubtleReference
	{font-variant:small-caps;
	color:#ED7D31;
	text-decoration:underline;}
span.MsoIntenseReference
	{font-variant:small-caps;
	color:#ED7D31;
	letter-spacing:.25pt;
	font-weight:bold;
	text-decoration:underline;}
span.MsoBookTitle
	{font-variant:small-caps;
	letter-spacing:.25pt;
	font-weight:bold;}
p.MsoBibliography, li.MsoBibliography, div.MsoBibliography
	{margin-top:0in;
	margin-right:0in;
	margin-bottom:12.0pt;
	margin-left:0in;
	text-align:justify;
	font-size:12.0pt;
	font-family:"Times New Roman",serif;
	color:#1F2F3C;}
p.MsoTocHeading, li.MsoTocHeading, div.MsoTocHeading
	{margin-top:24.0pt;
	margin-right:0in;
	margin-bottom:0in;
	margin-left:0in;
	text-align:justify;
	page-break-after:avoid;
	font-size:14.0pt;
	font-family:"Calibri Light",sans-serif;
	color:#2F5496;
	font-weight:bold;}
span.Heading1Char
	{mso-style-name:"Heading 1 Char";
	mso-style-link:"Heading 1";
	font-family:"Calibri Light",sans-serif;
	color:#2F5496;}
span.Heading2Char
	{mso-style-name:"Heading 2 Char";
	mso-style-link:"Heading 2";
	font-family:"Calibri Light",sans-serif;
	color:#2F5496;}
span.Heading3Char
	{mso-style-name:"Heading 3 Char";
	mso-style-link:"Heading 3";
	font-family:"Calibri Light",sans-serif;
	color:#1F3763;}
span.CommentTextChar
	{mso-style-name:"Comment Text Char";
	mso-style-link:"Comment Text";}
span.BalloonTextChar
	{mso-style-name:"Balloon Text Char";
	mso-style-link:"Balloon Text";
	font-family:"Segoe UI",sans-serif;}
span.CommentSubjectChar
	{mso-style-name:"Comment Subject Char";
	mso-style-link:"Comment Subject";
	font-weight:bold;}
span.HeaderChar
	{mso-style-name:"Header Char";
	mso-style-link:Header;}
span.FooterChar
	{mso-style-name:"Footer Char";
	mso-style-link:Footer;}
span.HTMLAddressChar
	{mso-style-name:"HTML Address Char";
	mso-style-link:"HTML Address";
	font-style:italic;}
span.QuoteChar
	{mso-style-name:"Quote Char";
	mso-style-link:Quote;
	color:black;
	font-style:italic;}
span.IntenseQuoteChar
	{mso-style-name:"Intense Quote Char";
	mso-style-link:"Intense Quote";
	color:#4472C4;
	font-weight:bold;
	font-style:italic;}
span.BodyTextChar
	{mso-style-name:"Body Text Char";
	mso-style-link:"Body Text";}
span.BodyText2Char
	{mso-style-name:"Body Text 2 Char";
	mso-style-link:"Body Text 2";}
span.BodyText3Char
	{mso-style-name:"Body Text 3 Char";
	mso-style-link:"Body Text 3";}
span.DateChar
	{mso-style-name:"Date Char";
	mso-style-link:Date;}
span.MessageHeaderChar
	{mso-style-name:"Message Header Char";
	mso-style-link:"Message Header";
	font-family:"Calibri Light",sans-serif;
	background:#CCCCCC;}
span.DocumentMapChar
	{mso-style-name:"Document Map Char";
	mso-style-link:"Document Map";
	font-family:"Tahoma",sans-serif;}
span.ClosingChar
	{mso-style-name:"Closing Char";
	mso-style-link:Closing;}
span.FootnoteTextChar
	{mso-style-name:"Footnote Text Char";
	mso-style-link:"Footnote Text";}
span.EndnoteTextChar
	{mso-style-name:"Endnote Text Char";
	mso-style-link:"Endnote Text";}
span.HTMLPreformattedChar
	{mso-style-name:"HTML Preformatted Char";
	mso-style-link:"HTML Preformatted";
	font-family:Consolas;}
span.BodyTextFirstIndentChar
	{mso-style-name:"Body Text First Indent Char";
	mso-style-link:"Body Text First Indent";}
span.BodyTextIndentChar
	{mso-style-name:"Body Text Indent Char";
	mso-style-link:"Body Text Indent";}
span.BodyTextIndent2Char
	{mso-style-name:"Body Text Indent 2 Char";
	mso-style-link:"Body Text Indent 2";}
span.BodyTextIndent3Char
	{mso-style-name:"Body Text Indent 3 Char";
	mso-style-link:"Body Text Indent 3";}
span.BodyTextFirstIndent2Char
	{mso-style-name:"Body Text First Indent 2 Char";
	mso-style-link:"Body Text First Indent 2";}
span.SalutationChar
	{mso-style-name:"Salutation Char";
	mso-style-link:Salutation;}
span.SignatureChar
	{mso-style-name:"Signature Char";
	mso-style-link:Signature;}
span.E-mailSignatureChar
	{mso-style-name:"E-mail Signature Char";
	mso-style-link:"E-mail Signature";}
span.SubtitleChar
	{mso-style-name:"Subtitle Char";
	mso-style-link:Subtitle;
	font-family:"Calibri Light",sans-serif;
	color:#4472C4;
	letter-spacing:.75pt;
	font-style:italic;}
span.PlainTextChar
	{mso-style-name:"Plain Text Char";
	mso-style-link:"Plain Text";
	font-family:Consolas;}
span.MacroTextChar
	{mso-style-name:"Macro Text Char";
	mso-style-link:"Macro Text";
	font-family:Consolas;}
span.TitleChar
	{mso-style-name:"Title Char";
	mso-style-link:Title;
	font-family:"Calibri Light",sans-serif;
	color:#323E4F;
	letter-spacing:.25pt;}
span.Heading4Char
	{mso-style-name:"Heading 4 Char";
	mso-style-link:"Heading 4";
	font-family:"Calibri Light",sans-serif;
	color:#4472C4;
	font-weight:bold;
	font-style:italic;}
span.Heading5Char
	{mso-style-name:"Heading 5 Char";
	mso-style-link:"Heading 5";
	font-family:"Calibri Light",sans-serif;
	color:#1F3763;}
span.Heading6Char
	{mso-style-name:"Heading 6 Char";
	mso-style-link:"Heading 6";
	font-family:"Calibri Light",sans-serif;
	color:#1F3763;
	font-style:italic;}
span.Heading7Char
	{mso-style-name:"Heading 7 Char";
	mso-style-link:"Heading 7";
	font-family:"Calibri Light",sans-serif;
	color:#404040;
	font-style:italic;}
span.Heading8Char
	{mso-style-name:"Heading 8 Char";
	mso-style-link:"Heading 8";
	font-family:"Calibri Light",sans-serif;
	color:#404040;}
span.Heading9Char
	{mso-style-name:"Heading 9 Char";
	mso-style-link:"Heading 9";
	font-family:"Calibri Light",sans-serif;
	color:#404040;
	font-style:italic;}
span.NoteHeadingChar
	{mso-style-name:"Note Heading Char";
	mso-style-link:"Note Heading";}
p.DocsID, li.DocsID, div.DocsID
	{mso-style-name:DocsID;
	margin-top:1.0pt;
	margin-right:0in;
	margin-bottom:0in;
	margin-left:0in;
	text-align:justify;
	line-height:8.0pt;
	font-size:8.0pt;
	font-family:"Arial",sans-serif;
	color:windowtext;}
span.subsection
	{mso-style-name:subsection;}
span.label-l
	{mso-style-name:label-l;}
span.Normal1
	{mso-style-name:Normal1;}
span.widthfixforlabel
	{mso-style-name:widthfixforlabel;}
span.paragraph
	{mso-style-name:paragraph;}
span.italic
	{mso-style-name:italic;}
span.label-section
	{mso-style-name:label-section;}
p.Body2, li.Body2, div.Body2
	{mso-style-name:Body2;
	mso-style-link:"Body2 Char";
	margin-top:11.0pt;
	margin-right:0in;
	margin-bottom:0in;
	margin-left:0in;
	text-align:justify;
	line-height:14.0pt;
	punctuation-wrap:simple;
	text-autospace:none;
	font-size:12.0pt;
	font-family:"Arial",sans-serif;
	color:#1F2F3C;}
span.Body2Char
	{mso-style-name:"Body2 Char";
	mso-style-link:Body2;
	font-family:"Arial",sans-serif;}
span.Prompt
	{mso-style-name:Prompt;
	font-family:"Times New Roman",serif;
	color:blue;}
span.Mentionnonrsolue1
	{mso-style-name:"Mention non résolue1";
	color:#605E5C;
	background:#E1DFDD;}
p.SimpleL1, li.SimpleL1, div.SimpleL1
	{mso-style-name:Simple_L1;
	margin-top:0in;
	margin-right:0in;
	margin-bottom:12.0pt;
	margin-left:.5in;
	text-align:justify;
	text-indent:-.5in;
	font-size:12.0pt;
	font-family:"Times New Roman",serif;
	color:black;
	font-weight:bold;}
p.SimpleL2, li.SimpleL2, div.SimpleL2
	{mso-style-name:Simple_L2;
	margin-top:0in;
	margin-right:0in;
	margin-bottom:12.0pt;
	margin-left:0in;
	text-align:justify;
	text-indent:0in;
	font-size:12.0pt;
	font-family:"Times New Roman",serif;
	color:black;
	text-decoration:underline;}
p.SimpleL3, li.SimpleL3, div.SimpleL3
	{mso-style-name:Simple_L3;
	margin-top:0in;
	margin-right:0in;
	margin-bottom:12.0pt;
	margin-left:1.5in;
	text-align:justify;
	text-indent:-1.0in;
	font-size:12.0pt;
	font-family:"Times New Roman",serif;
	color:black;}
p.SimpleL4, li.SimpleL4, div.SimpleL4
	{mso-style-name:Simple_L4;
	margin-top:0in;
	margin-right:0in;
	margin-bottom:12.0pt;
	margin-left:1.5in;
	text-align:justify;
	text-indent:-1.0in;
	font-size:12.0pt;
	font-family:"Times New Roman",serif;
	color:black;}
p.SimpleL5, li.SimpleL5, div.SimpleL5
	{mso-style-name:Simple_L5;
	margin-top:0in;
	margin-right:0in;
	margin-bottom:12.0pt;
	margin-left:2.5in;
	text-align:justify;
	text-indent:-.5in;
	font-size:12.0pt;
	font-family:"Calibri",sans-serif;
	color:#1F2F3C;}
p.SimpleNoL1, li.SimpleNoL1, div.SimpleNoL1
	{mso-style-name:"Simple_No\#L1";
	margin-top:0in;
	margin-right:0in;
	margin-bottom:12.0pt;
	margin-left:.5in;
	text-align:justify;
	font-size:12.0pt;
	font-family:"Times New Roman",serif;
	color:#1F2F3C;}
p.SimpleNoL2, li.SimpleNoL2, div.SimpleNoL2
	{mso-style-name:"Simple_No\#L2";
	margin-top:0in;
	margin-right:0in;
	margin-bottom:12.0pt;
	margin-left:1.0in;
	text-align:justify;
	font-size:12.0pt;
	font-family:"Times New Roman",serif;
	color:#1F2F3C;}
p.SimpleNoL3, li.SimpleNoL3, div.SimpleNoL3
	{mso-style-name:"Simple_No\#L3";
	margin-top:0in;
	margin-right:0in;
	margin-bottom:12.0pt;
	margin-left:1.5in;
	text-align:justify;
	font-size:12.0pt;
	font-family:"Times New Roman",serif;
	color:#1F2F3C;}
p.SimpleNoL4, li.SimpleNoL4, div.SimpleNoL4
	{mso-style-name:"Simple_No\#L4";
	margin-top:0in;
	margin-right:0in;
	margin-bottom:12.0pt;
	margin-left:2.0in;
	text-align:justify;
	font-size:12.0pt;
	font-family:"Times New Roman",serif;
	color:#1F2F3C;}
p.SimpleNoL5, li.SimpleNoL5, div.SimpleNoL5
	{mso-style-name:"Simple_No\#L5";
	margin-top:0in;
	margin-right:0in;
	margin-bottom:12.0pt;
	margin-left:2.5in;
	text-align:justify;
	font-size:12.0pt;
	font-family:"Times New Roman",serif;
	color:#1F2F3C;}
.MsoChpDefault
	{font-family:"Calibri",sans-serif;}
.MsoPapDefault
	{margin-bottom:8.0pt;
	line-height:107%;}
 /* Page Definitions */
 @page WordSection1
	{size:8.5in 11.0in;
	margin:1.0in 1.0in 1.0in 1.0in;}
div.WordSection1
	{page:WordSection1;}
 /* List Definitions */
 ol
	{margin-bottom:0in;}
ul
	{margin-bottom:0in;}
-->
</style>

</head>

<body lang=EN-US link="#0563C1" vlink="#954F72" style='word-wrap:break-word'>

<div class=WordSection1>

<p class=MsoNormal align=center style='text-align:center'><b>ONLINE PLATFORM
TERMS OF USE</b></p>

<p class=MsoNormal><b><i>Last updated: [October 25<sup>th</sup>, 2021]</i></b></p>

<p class=MsoNormal><b>Introduction</b></p>

<p class=MsoNormal>These Terms of Use (“<b>Terms of Use</b>”) constitutes a
legal agreement between you (“<b>you</b>,” “<b>your</b>,” or “<b>User</b>”) and
13363902 Canada Inc.(doing business as GigLinked)<span lang=EN-CA>, a Quebec
corporation having a head office at 1126 Island Street, Montreal, Québec, H3K
2M8</span> (“<b>GigLinked</b>” or “<b>us</b>”). These Terms of Use governs your
use of our platform that facilitates communications between Users (as defined
below) offered through the website available at <a href="http://www.clefly.me">www.GigLinked.me</a>
(the “<b>Website</b>”) and the mobile applications offered by us for mobile
devices such as smartphones and tablets (the “<b>Apps</b>”) and the related
services offered by us. The Apps and the related services offered by us are
collectively referred to as the “<b>GigLinked Platform</b>”. All users of the GigLinked
Platform, namely the Requestors and the Musicians, in each case as described
below, are collectively called “<b>Users</b>”. These Terms of Use shall be read
together with the Privacy Policy available at <span lang=EN-CA>[www.GigLinked.Live]</span><b><span
lang=EN-CA> </span></b>(<span lang=EN-CA>as may be modified from time to time
in accordance with its terms, the </span>“<b>Privacy Policy</b>”).</p>

<p class=MsoNormal>PLEASE READ THE FOLLOWING TERMS AND CONDITIONS AND THE
PRIVACY POLICY CAREFULLY BEFORE USING THE GIGLINKED PLATFORM.</p>

<p class=MsoNormal>By clicking the “I Agree to the Online Platform Terms of Use”
button or otherwise registering with the GigLinked Platform, downloading,
installing, logging-in or using the Website or any of GigLinked’s software, you
are expressly acknowledging that you are entering into a legally binding
contract with us and, as a result, you thereby agree to accept the terms of,
and agree to be bound by these Terms of Use, and any future amendments and
additions as we may publish from time to time. IF YOU DO NOT AGREE TO THESE
TERMS OF USE AND TO THE TERMS SET OUT IN THE PRIVACY POLICY, DO NOT CLICK “I
AGREE TO THE ONLINE PLATFORM TERMS OF USE” AND DO NOT USE THE GIGLINKED PLATFORM.
If you do not agree to be bound by these Terms of Use, you will not have any
right to use the GigLinked Platform. GigLinked’s acceptance is expressly
conditioned upon your assent to these Terms of Use in its entirety. If these
Terms of Use is considered to be an offer by us, acceptance is expressly
limited to these Terms of Use.</p>

<p class=MsoNormal>Subject to applicable law,&nbsp;we reserve the right, at our
sole and absolute discretion, to alter, change, modify, add to, supplement or
delete any of the terms and conditions of these Terms of Use, including these
Terms of Use, including, but not limited to, the terms, conditions and
obligations contained in each section of these Online Platform Terms of Use as
well as each and every additional term, feature and benefit of the GigLinked
Platform.</p>

<p class=MsoNormal>If we intend to proceed to such a modification, we will send
you a notice to the email address associated with your account thirty (30) days
prior the entry into force of the proposed change indicating the specific
element of these Terms of Use that will be altered, added or deleted, as the
case may be from time to time. Your continued use of the GigLinked Platform
after such modifications have entered into force will constitute your
acknowledgement these Terms of Use as modified. </p>

<p class=MsoNormal>&nbsp;</p>

<p class=SimpleL1><span style='color:#1F2F3C'>1.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span>Eligibility to Use and License</p>

<p class=SimpleL2 style='margin-left:0in;text-indent:0in'><span
style='color:#1F2F3C;text-decoration:none'>(a)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span>Eligibility for Use of the GigLinked Platform. </p>

<p class=MsoNormal>By using the GigLinked Platform, you represent and warrant
that: </p>

<p class=SimpleL3><span style='font-family:"Times",serif;color:#1F2F3C'>(i)<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span>all registrations and other information you submit, if any, is
truthful and accurate; </p>

<p class=SimpleL3 style='margin-left:70.9pt;text-indent:-34.9pt'><span
style='font-family:"Times",serif;color:#1F2F3C'>(ii)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span>you will maintain the accuracy of any information you provide; </p>

<p class=SimpleL3 style='margin-left:70.9pt;text-indent:-34.9pt'><span
style='font-family:"Times",serif;color:#1F2F3C'>(iii)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span>you are at least 18 years old, are at least of the legally
required age in the jurisdiction in which you reside, and are otherwise capable
of entering into binding contracts; </p>

<p class=SimpleL3 style='margin-left:70.9pt;text-indent:-34.9pt'><span
style='font-family:"Times",serif;color:#1F2F3C'>(iv)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span>you have the right, authority and capacity to enter into these
Terms of Use and to abide by the terms and conditions of these Terms of Use,
and that you will so abide;</p>

<p class=SimpleL3 style='margin-left:70.9pt;text-indent:-34.9pt'><span
style='font-family:"Times",serif;color:#1F2F3C'>(v)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-GB>these Terms of Use constitutes legal, valid and
binding obligations of the User, enforceable against it in accordance with its
terms;</span></p>

<p class=SimpleL3 style='margin-left:70.9pt;text-indent:-34.9pt'><span
style='font-family:"Times",serif;color:#1F2F3C'>(vi)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span>you are the sole and exclusive owner of the User Content (as
defined below) that you make available on or through the GigLinked Platform and
that you have all rights, title and interest, licenses and consents necessary
to grant GigLinked the rights in such User Content as contemplated herein, and neither
the User Content nor your posting, downloading, submission or transmission of
any User Content or the use by GigLinked of the User Content or any part of
User Content as contemplated herein will infringe any third party intellectual
property rights such as copyrights, trademarks, trade secrets, moral rights or
other intellectual property rights nor will result in the violation of any
applicable law or regulation; and </p>

<p class=SimpleL3 style='margin-left:70.9pt;text-indent:-34.9pt'><span
style='font-family:"Times",serif;color:#1F2F3C'>(vii)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span>your use of any aspect of the GigLinked Platform does not violate
any applicable law or regulation.</p>

<p class=MsoNormal>Musicians may only render Performances (as described below)
to Requestors in the province or State in which they reside. Eligibility based
on territorial requirements will be processed by the GigLinked Platform<span
lang=EN-CA> through verification of the User’s postal code provided during the
account opening process.</span></p>

<p class=MsoNormal>Registration of Musicians on the GigLinked Platform can be
done by natural persons, sole proprietorships or for any other legal entity
(through a duly authorized representative of such legal entity). <span lang=EN>In
the case where a </span>Musician’s <span lang=EN>account is created for a legal
entity, you represent and warrant that (i) such Musician </span><span
lang=EN-GB>is duly incorporated or formed, organized and subsisting under the
laws of its jurisdiction or incorporation or formation, and is in good standing
under the laws of its jurisdiction of incorporation or formation, (ii) </span><span
lang=EN>such Musician</span><span lang=EN-GB> has good and sufficient power,
authority and right to enter into and perform its obligations under these Terms
of Use and the transactions contemplated by these Terms of Use, and (iii) the
representative of such </span><span lang=EN>Musician who has accepted these
Terms of Use for such Musician has the legal authority to bind such Musician and
to cause such Musician to grant all permissions and licenses to </span><span
lang=EN-CA>GigLinked</span><span lang=EN> under these Terms of Use and
otherwise to perform all of its obligations under these Terms of Use.</span></p>

<p class=MsoNormal>Registration of a Requestor on the GigLinked Platform can be
done only by natural persons for his/her own account. </p>

<p class=SimpleL2 style='margin-left:0in;text-indent:0in'><span
style='color:#1F2F3C;text-decoration:none'>(b)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span>User’s Acknowledgment and Acceptance of Terms.</p>

<p class=MsoNormal>GigLinked provides access to the GigLinked Platform subject
to your compliance with these Terms of Use.</p>

<p class=MsoNormal>As used in these Terms of Use, references to our “affiliates”
includes our owners, subsidiaries, employees, subcontractors, affiliated
companies, officers, directors, suppliers, partners, payment services
providers, sponsors, advertisers, and includes (without limitation) all parties
involved in creating, producing, and/or delivering the GigLinked Platform
and/or its content.</p>

<p class=MsoNormal>BY COMPLETING THE REGISTRATION PROCESS, LOGGING IN AND/OR
USING THE GIGLINKED PLATFORM, YOU AGREE TO BE LEGALLY BOUND BY THESE TERMS OF
USE. PLEASE READ THEM CAREFULLY. IF YOU DO NOT WISH TO BE BOUND BY THE TERMS OF
USE, PLEASE EXIT THE GIGLINKED PLATFORM NOW. YOUR REMEDY FOR DISSATISFACTION
WITH THIS GIGLINKED PLATFORM, OR ANY PRODUCTS, SERVICES, CONTENT, OR OTHER
INFORMATION AVAILABLE ON OR THROUGH THE GIGLINKED PLATFORM, IS TO STOP USING
THE GIGLINKED PLATFORM AND/OR THOSE PARTICULAR SERVICES. YOUR AGREEMENT WITH US
REGARDING COMPLIANCE WITH THESE TERMS OF USE BECOMES EFFECTIVE IMMEDIATELY UPON
CLICKING THE “I AGREE TO THE ONLINE PLATFORM TERMS OF USE” BUTTON.</p>

<p class=SimpleL2 style='margin-left:0in;text-indent:0in'><span
style='color:#1F2F3C;text-decoration:none'>(c)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span>Limited License Granted. </p>

<p class=MsoNormal><span lang=EN>Subject to your compliance with these Terms of
Use, we grant you a limited, non-exclusive, non-sublicensable,
non-transferable, non-assignable license to use and access the GigLinked Platform,
but only to the extent permitted in these Terms of Use and the other terms and
conditions constituting these Terms of Use. You will not sell, transfer, or
sublicense the GigLinked Platform to anyone. Except as expressly licensed
herein, you will not use, access, integrate with, modify, translate, create
derivative works of, reverse engineer, or otherwise exploit the GigLinked
Platform or any component or aspect thereof.</span></p>

<p class=SimpleL1><span style='color:#1F2F3C'>2.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span>The GigLinked Platform </p>

<p class=SimpleL2 style='margin-left:0in;text-indent:0in'><span
style='color:#1F2F3C;text-decoration:none'>(a)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span>The GigLinked Platform is Solely a Venue for Communications.</p>

<p class=MsoNormal style='page-break-after:avoid'><span style='text-transform:
uppercase'>the GigLinked Platform is Solely a Venue for Communications</span>. It
enables Users who are seeking the performance of live musical acts (“<b>Requestors</b>”)
to be provided by independent third party MUSICIANS that are also Users (the “<b>Musicians</b>”)
and who accept to perform live musical acts for private or public events (the “<b>Performances</b>”).
The Performances are completed at the Requestors’ own place of residence or any
other location for which the Requestor has obtained all necessary rights and
permissions to hold the Performance at (in each case, an “<b>Authorized Location</b>”).
</p>

<p class=MsoNormal>Each such Performances will be provided pursuant to an
individual contract, to be entered into each time Performances are to be
provided, between the Musician providing such Performances and the Requestor
having requested such Performances through the GigLinked Platform (each such
contract, a “<b>Service Contract</b>”) in the from requested by a Musician at
his discretion. GigLinked is not a party to, and has no obligation under, any
such Service Contract, and the terms and conditions thereof are not part of
this Agreement. GigLinked does not endorse or guarantee that any such Service
Contract shall be complete, lawful or exempt of material defects or adequate
protection for Requestors and the execution of any such Service Contract or any
amendment thereto shall be at the Users’ entire discretion.</p>

<p class=SimpleL2 style='margin-left:0in;text-indent:0in'><span
style='color:#1F2F3C;text-decoration:none'>(b)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span>GigLinked does not perform the Performances</p>

<p class=MsoNormal><span style='text-transform:uppercase'>The GigLinked Platform
is a communication tool which allows for Musicians and Requestors to arrange
for Performances to be performed by a Musician for the benefit of a RequestOr
in accordance with a Service Contract.</span> GIGLINKED, THROUGH THE GIGLINKED PLATFORM,
OFFERS INFORMATION AND A METHOD FOR REQUESTORS TO COMMUNICATE IN ORDER TO
OBTAIN PERFORMANCES FROM THE MUSICIANS, BUT DOES NOT AND DOES NOT INTEND TO ACT
IN ANY WAY AS PERFORMER OF SERVICES (INCLUDING, WITHOUT LIMITATION, PERFORMANCES).
<span style='text-transform:uppercase'>RequestOrs will contract directly with
the selected Musicians and independently from GigLinked PURSUANT TO A SERVICE
CONTRACT. We are not responsible for the DELIVERY of THE Performances, nor do
we have control over the quality, timing, provision or failure to provide THE PERFORMANCES.</span></p>

<p class=MsoNormal>BY USING THE GIGLINKED PLATFORM, THE REQUESTOR AGREES TO
HOLD GIGLINKED HARMLESS FROM THE RESPONSIBILITY FOR ANY LIABILITY OR DAMAGE
THAT MIGHT ARISE OUT OF THE PERFORMANCES. GIGLINKED IS NOT RESPONSIBLE FOR THE
CONDUCT, WHETHER ONLINE OR OFFLINE, OF ANY USER, AND TO THE EXTENT PERMITTED BY
APPLICABLE LAW, WILL NOT BE LIABLE FOR ANY CLAIM ARISING IN CONNECTION WITH ANY
PERFORMANCES, EXCEPT AS EXPRESSLY STATED IN THESE TERMS OF USE.</p>

<p class=SimpleL1><a name="_Ref64991593"><span style='color:#1F2F3C'>3.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span>User Accounts; Personal Information and Privacy Policy</a></p>

<p class=SimpleL2 style='margin-left:0in;text-indent:0in'><span
style='color:#1F2F3C;text-decoration:none'>(a)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span>Enrollment for Use of the GigLinked Platform.</p>

<p class=MsoNormal>Some of the materials available on the GigLinked Platform
may require prior registration to access them. If you decide to access such
materials you will be required to have a valid account and password, which you
will be able to obtain by signing up and registering on the Website. As part of
the registration, you will need to select an acceptable user name in order to
use the GigLinked Platform. We may refuse to grant you, and you may not use, a
username, email address or screen name that is already being used by someone
else; that may be construed as impersonating another person; that belongs to
another person; that violates the intellectual property or other rights of any
person; that is offensive; or that we reject for any other reason in our sole
discretion. </p>

<p class=MsoNormal>In exchange for registering, and subject to the limitations
set forth in these Terms of Use, you will be granted the limited right to use
the following features of the GigLinked Platform:</p>

<p class=MsoListParagraphCxSpFirst style='margin-left:42.55pt;text-indent:-42.55pt'><span
style='font-family:Symbol'>·<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span>Musicians will have the ability to propose the availability of Performances
through, and Requestors will have the ability to book such Performances
through, the GigLinked Platform;</p>

<p class=MsoListParagraphCxSpMiddle style='margin-left:42.55pt;text-indent:
-42.55pt'><span style='font-family:Symbol'>·<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span>Requestors will have access to information about Musicians
including, without limitation, a description and media examples of Performances
by the Musicians;</p>

<p class=MsoListParagraphCxSpMiddle style='margin-left:42.55pt;text-indent:
-42.55pt'><span style='font-family:Symbol'>·<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span>Musicians will be able to decide the Performance Rate (as defined
below) and to invoice the Performances through the GigLinked Platform; and</p>

<p class=MsoListParagraphCxSpLast style='margin-left:42.55pt;text-indent:-42.55pt'><span
style='font-family:Symbol'>·<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span>Requestors will have the ability to provide comments and feedback
(e.g., ratings) on a Musician’s performance of the Performances, and Musicians
will have the ability to provide comments and feedback (e.g., ratings) on a
Requestor’s behavior and collaboration in respect of the Performance requested
by such Requestor, as the case may be, the whole as more fully described in
Section&nbsp;4 below.</p>

<p class=SimpleL2 style='margin-left:0in;text-indent:0in'><span
style='color:#1F2F3C;text-decoration:none'>(b)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span>Collection and Sharing of your Personal Information: Privacy
Policy. </p>

<p class=MsoNormal>When you complete a registration with us, you will be
required to provide certain personal information. Such information may be
visible to other Users of the GigLinked Platform and you agree that such
information will be true, accurate and complete, and that you will update this
information promptly when it changes. If you provide any information that is untrue
or inaccurate, not current, or incomplete, or if we suspect that your
information is untrue or inaccurate, not current, or incomplete, we may, in our
sole discretion, suspend or terminate your right to access any material for
which registration is required. </p>

<p class=MsoNormal>You grant us the right to disclose to third parties certain
information we obtain through your use of the GigLinked Platform, including
your registration data, and any such disclosure or sharing of any personally
identifiable information supplied hereunder will be subject to the terms of our
Privacy Policy, which is incorporated by specific reference into these Terms of
Use.</p>

<p class=MsoNormal>As further described in our Privacy Policy, GigLinked may
disclose personal information that we collect or you provide to our affiliates.
Please read our Privacy Policy for more detailed information on our practices
with respect thereof.</p>

<p class=SimpleL2 style='margin-left:0in;text-indent:0in'><span
style='color:#1F2F3C;text-decoration:none'>(c)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span>Account, Password and Security. </p>

<p class=MsoNormal>You are the sole authorized user of your account. You are
responsible for maintaining the confidentiality of any username, password and
account provided by you or us for accessing the GigLinked Platform. </p>

<p class=MsoNormal>You are solely and fully responsible for all activities that
occur under your account, except that GigLinked may, in certain circumstances,
access your account to make changes that you request, such as rescheduling a Performances
appointment. You agree not to choose a password that may be easily discovered
(e.g. date of birth, phone number, address, postal code, social insurance
number, health insurance or driver’s license number) or to make it available on
any document or storage platform that makes it readily accessible to others, in
which case GigLinked shall be entitled to presume that a User has contributed to
the unauthorized access and activity on your account, if applicable.</p>

<p class=MsoNormal>GigLinked has no control over the use of any User’s account
by the User or third parties and expressly disclaims any liability derived
therefrom. Should you suspect that any unauthorized party may be using your
password or account or that such password may be known by an unauthorized
third-party, or you suspect any other breach of security, you must contact us
immediately at <a href="mailto:info@proassistance.ca"><span lang=EN-CA>info@GigLinked.me</span></a><span
lang=EN-CA>.</span> Nothing in this Section shall affect GigLinked’s rights to
limit or terminate the use of the GigLinked Platform, as provided below in Section&nbsp;6(b).</p>

<p class=SimpleL2 style='margin-left:0in;text-indent:0in'><span
style='color:#1F2F3C;text-decoration:none'>(d)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span>Text Messages and Phone Calls. </p>

<p class=MsoNormal>By providing your phone number and using the GigLinked
Platform, you are agreeing that we may, to the extent permitted by applicable
law, use your mobile phone number for calls and, if such phone number is a
mobile number, for text (SMS) messages, in order to assist with facilitating
the requested Performance. Standard call or message charges or other charges
from your phone carrier may apply to calls or text (SMS) messages we send you.
You may opt-out of receiving text (SMS) messages from us by replying with the
word “STOP” to a text message from us. You acknowledge that opting out of text
(SMS) messages may impact your ability to use the GigLinked Platform.</p>

<p class=SimpleL2 style='margin-left:0in;text-indent:0in'><span
style='color:#1F2F3C;text-decoration:none'>(e)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span>Emails. </p>

<p class=MsoNormal>You agree that GigLinked may send you confirmation and other
communications regarding the Performances. GigLinked may also send you emails
about services that we think might interest you (“<b>Promotional Emails</b>”)
if you have provided your consent to receive the same. You can unsubscribe from
Promotional Emails at any time by clicking unsubscribe in our email
communications or contacting us. </p>

<p class=MsoNormal><span lang=EN-CA>Our form of communication with you about
your account, your use of </span>the GigLinked Platform and Performances
related thereto<span lang=EN-CA> will be by email. By using the </span>GigLinked
Platform to solicit any Performances<span lang=EN-CA>, you agree that all
communications related to your use of the </span>GigLinked Platform, the Performances
and any other related information<span lang=EN-CA> may be provided to you
electronically and, unless we choose otherwise, electronically only.</span></p>

<p class=SimpleL1><a name="_Ref64991278"><span style='color:#1F2F3C'>4.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span>Ratings, Reviews and Comments</a></p>

<p class=SimpleL2 style='margin-left:0in;text-indent:0in'><span
style='color:#1F2F3C;text-decoration:none'>(a)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span>Rating and Review Process </p>

<p class=MsoNormal><span lang=EN>Within a certain period following the
completion of the Performances, Musicians and Requestors may leave a public
review (“<b>Review</b>”) and complete and submit a star rating (“<b>Rating</b>”)
about each other. Ratings and Reviews reflect the individual opinion of Users
and do not reflect the opinion of </span>GigLinked <span lang=EN>in any way.
Ratings and Reviews prepared by Musicians and Requestors must be accurate and
may not contain offensive or defamatory language. GigLinked reserves the right
to remove any Review that does not comply with these guidelines and remove or
correct any Rating or Review which was indicated by a User as being made by
inadvertence or mistake.</span></p>

<p class=SimpleL2 style='margin-left:0in;text-indent:0in'><span
style='color:#1F2F3C;text-decoration:none'>(b)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span>Ratings and Review Limitations </p>

<p class=MsoNormal><span lang=EN>Users are prohibited from manipulating </span>GigLinked’s
<span lang=EN>Ratings and Reviews system in any way such as, but not limited
to, asking a third party to write positive or negative Reviews on another User.
</span></p>

<p class=SimpleL2 style='margin-left:0in;text-indent:0in'><span
style='color:#1F2F3C;text-decoration:none'>(c)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span>Termination for Breach </p>

<p class=MsoNormal>GigLinked <span lang=EN>may, in its sole and absolute
discretion and without notice, terminate access to a User’s account to a User
who has a Rating deemed non-exemplary by </span>GigLinked<span lang=EN>. The
exemplary nature of a User’s Rating is left to the sole and absolute discretion
of </span>GigLinked<span lang=EN>.</span></p>

<p class=SimpleL1><a name="_Ref65162095"><span style='color:#1F2F3C'>5.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span>Payments</a> </p>

<p class=SimpleL2 style='margin-left:0in;text-indent:0in'><span
style='color:#1F2F3C;text-decoration:none'>(a)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span>Payments</p>

<p class=MsoNormal>Each time Performances are requested through the GigLinked
Platform, the applicable Requestor and the applicable Musician will enter into
a Service Contract to obtain such Performances. </p>

<p class=MsoNormal>GigLinked will request from Musicians to provide to Requestors,
upon completion of the Performances, a final invoice for the price and cost of
all Performances ordered. The payment timing and terms of such invoice for each
Performance shall be at the discretion of a Musician and are to be agreed-upon
by the Users independently from GigLinked’s involvement.</p>

<p class=SimpleL2 style='margin-left:0in;text-indent:0in'><span
style='color:#1F2F3C;text-decoration:none'>(b)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span>GigLinked Commission</p>

<p class=MsoNormal>As a fee for facilitating the matching between Requestors
and Musicians, GigLinked will keep a fifteen (15) percent gross commission fee
on the Performance Rate.</p>

<p class=SimpleL1><span style='color:#1F2F3C'>6.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span>Term and Termination and Cancellation of Performances</p>

<p class=SimpleL2 style='margin-left:0in;text-indent:0in'><span
style='color:#1F2F3C;text-decoration:none'>(a)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span>Term. </p>

<p class=MsoNormal>These Terms of Use shall continue in full force and effect
until such time as it is terminated by you or by us.</p>

<p class=SimpleL2 style='margin-left:0in;text-indent:0in'><a name="_Ref64991311"><span
style='color:#1F2F3C;text-decoration:none'>(b)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span>Termination by GigLinked.</a> </p>

<p class=MsoNormal>We may terminate these Terms of Use or terminate or suspend
your right to use the GigLinked Platform at any time for any or no reason
(including, without limitation, in the event that we believe that you have
breached these Terms of Use, or if we otherwise find that you have engaged in
inappropriate and/or offensive behavior (collectively, “<b>Prohibited Conduct</b>”))
by providing you with email notice of such termination to the physical or email
address you have provided us, and termination will be effective immediately
upon such notice. </p>

<p class=MsoNormal>We also reserve the right to withdraw or temporarily change
or suspend all or part of the services associated with the GigLinked Platform
in any way, in the event of a material error, omission, technical problem,
computer virus or bugs, tampering, unauthorized intervention, fraud, technical
failure or any other cause beyond our reasonable control that interferes with
the proper conduct of any aspect of the GigLinked Platform and the related
services provided to you as set out in these Terms of Use. Any attempt to
deliberately damage or to undermine the legitimate operation of the GigLinked
Platform or the related services in any way (as GigLinked may determine in its sole
discretion) could be a violation of criminal or civil laws and should such an
attempt be made, we reserve the right to seek remedies and damages to the
fullest extent permitted by law.</p>

<p class=MsoNormal>If we terminate or suspend your account for any reason, you
are prohibited from registering and creating a new account under your name, a
fake or borrowed name, or the name of any third party, even if you may be
acting on behalf of the third party. In addition to terminating or suspending
your account, we reserve the right to take appropriate legal action, including
without limitation pursuing civil, criminal, and injunctive redress. When
terminating these Terms of Use, GigLinked may delete the account and all the
information in it. You have no ownership rights to your account.</p>

<p class=SimpleL2 style='margin-left:0in;text-indent:0in'><span
style='color:#1F2F3C;text-decoration:none'>(c)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span>Termination by Requestors or Musicians. </p>

<p class=MsoNormal>All Users may terminate these Terms of Use by completely and
permanently ceasing to use the GigLinked Platform, provided that there are no
outstanding Performances ordered under your password or account (for Requestors)
or outstanding accepted Performance requests (for Musicians) and by closing any
account you have opened on the GigLinked Platform. If you attempt to terminate these
Terms of Use while there are still outstanding Performances ordered or accepted,
as applicable, these Terms of Use shall not terminate until such Performances
have been performed and paid for, or otherwise canceled as permitted by GigLinked,
or as provided for in these Terms of Use.</p>

<p class=SimpleL2 style='margin-left:0in;text-indent:0in'><span
style='color:#1F2F3C;text-decoration:none'>(d)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span>Cancellation of a Performance. </p>

<p class=MsoNormal>When a Musician cancels a scheduled Performances
appointment, the GigLinked Platform notifies the Requestor and cancels the
Performance. If a Musician has repeatedly canceled or arrived late at scheduled
Performances appointments, whether such breaches are reflected in Musician’s
Rating or not, GigLinked reserves the right to unilaterally suspend or
terminate access to the GigLinked Platform.</p>

<p class=SimpleL1 style='page-break-after:avoid'><span style='color:#1F2F3C'>7.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span>Links to and Plug-Ins from Other Web Sites or Media</p>

<p class=MsoNormal style='page-break-after:avoid'>Use of the GigLinked Platform
requires Internet access and may require you to accept additional third-party
terms of service. The GigLinked Platform may use links (such as hyperlinks)
from the GigLinked Platform and plug-ins from sites or applications owned,
operated or controlled by third parties (collectively, “<b>Third Party Sites</b>”),
and such links do not constitute the endorsement by GigLinked of the
Third-Party Sites or their content. Such links and plug-ins are provided as an
information service, for reference and convenience only. GigLinked does not
control any Third-Party Sites and is not responsible for their content. These
Third-Party Sites may contain information and material that some people may
find inappropriate or offensive. It is your responsibility to evaluate the
content and usefulness of the information obtained from Third Party Sites. The
use of any Third-Party Site is governed by the terms and conditions of use and
privacy policy of that Third-Party Site. YOU ACCESS THIRD PARTY SITES AT YOUR
OWN RISK. <span style='text-transform:uppercase'>GigLinked </span>EXPRESSLY
DISCLAIMS ANY LIABILITY ARISING IN CONNECTION WITH YOUR USE AND/OR VIEWING OF
ANY THIRD-PARTY SITES, AND YOU HEREBY AGREE TO HOLD <span style='text-transform:
uppercase'>GigLinked </span>HARMLESS FROM ANY LIABILITY THAT MAY RESULT FROM
THIRD PARTY SITES.</p>

<p class=SimpleL1><span style='color:#1F2F3C'>8.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span>Community Areas </p>

<p class=MsoNormal>The GigLinked Platform may contain blogs, message boards,
applications, reviews, talent calls from venues, news groups, forums,
communities and/or other message or communication facilities that allow Users
to communicate with other Users (collectively, “<b>Community Areas</b>”). All
submissions made to Community Areas within the GigLinked Platform will be
public, and we will not be responsible for any information or materials posted
in Community Areas. You may only use such Community Areas to send and receive
messages and material that are relevant and proper to the applicable forum and
that comply with these Terms of Use. “<b>Your Information</b>” is defined as
any information and materials you provide to us or other Users in connection
with your registration for and use of the GigLinked Platform, including without
limitation, information and materials that are posted or transmitted for use in
Community Areas. You are solely responsible for Your Information, and we are
merely a passive conduit for your online distribution and publication of Your
Information. You hereby represent and warrant that Your Information: (a) will
comply at all times with these Terms of Use and (b) will not create liability
for us or cause us to lose (in whole or in part) the services of our Internet
Service Providers (ISPs), customers, or other partners or suppliers. </p>

<p class=MsoNormal>You hereby grant us a non-exclusive, worldwide, perpetual,
irrevocable, royalty-free, sub-licensable (through multiple tiers) right to
use, host, store, reproduce, modify, create derivative works, communicate,
publish, publicly perform, publicly display and distribute all of Your
Information that is posted to Community Areas from or through your account on
the GigLinked Platform, including but not limited to all images, videos,
musical works and text included in such postings. The rights you grant in this
license are for the limited purpose of operating, promoting, and improving the GigLinked
Platform. </p>

<p class=MsoNormal>We neither endorse nor assume any liability for the contents
of any content uploaded or submitted by Users on the GigLinked Platform,
including photos, texts, videos, or other materials or information (“<b>User
Content</b>”). We generally do not pre-screen, monitor, or edit the content
posted by Users of our communications services, or other interactive services
that may be available on or through the GigLinked Platform. However, we have
the right at our sole discretion to remove any content that, in our judgment,
does not comply with these Terms of Use and any other terms and conditions of these
Terms of Use, or is otherwise harmful, objectionable, inaccurate, or
restricted. We are not responsible for any failure or delay in removing such
content. You hereby consent to such removal and waive any claim against us
arising out of such removal of content. </p>

<p class=MsoNormal><span lang=EN>You acknowledge that GigLinked has no
obligation to monitor the access or use of the GigLinked Platform by any User
or to review or block access to anyone or to modify any User Content but has
the right to do so (i) to operate, secure and improve the GigLinked Platform, (ii)
to ensure compliance and compliance with these Terms of Use by Users, (iii) to comply
with any applicable law, order or requirement of a Court or any other
governmental organization, (iv) to respond to any User Content that GigLinked
determines, in its sole and absolute discretion, to be harmful or unacceptable,
or (v) otherwise as more fully set out in these Terms of Use. Users agree to
cooperate and assist GigLinked in good faith and to provide GigLinked with any
information and take any action that may be reasonably required by GigLinked to
comply with any investigation undertaken by GigLinked or a representative of GigLinked
in relation to the use or abuse of the GigLinked Platform.</span></p>

<p class=MsoNormal>You may not use your account to breach the security of
another account or attempt to gain unauthorized access to another network or
server. You shall not interfere with anyone else’s use and enjoyment of the GigLinked
Platform. Users who violate systems or network security may incur criminal or
civil liability. You shall not exploit the GigLinked Platform, including but
not limited to, by trespass or burdening network capacity.</p>

<p class=SimpleL1><a name="_Ref64991573"><span style='color:#1F2F3C'>9.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span>Rules for Use of the GigLinked Platform</a></p>

<p class=SimpleL4>(d)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span>During the term of these Terms of Use:</p>

<p class=SimpleL3 style='text-indent:-37.1pt'><span style='font-family:"Times",serif;
color:#1F2F3C'>(i)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span>Requestors may use the GigLinked Platform to book Performances
solely with respect to a location where the Requestor is legally authorized to
have Performances performed and to an Authorized Location;</p>

<p class=SimpleL3 style='margin-left:99.25pt;text-indent:-28.35pt'><span
style='font-family:"Times",serif;color:#1F2F3C'>(ii)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span>Users may not use the GigLinked Platform for any other purposes
than those expressly authorized pursuant to these Terms of Use or as may
otherwise be authorized by GigLinked in writing, in its sole discretion;</p>

<p class=SimpleL3 style='margin-left:99.25pt;text-indent:-28.35pt'><span
style='font-family:"Times",serif;color:#1F2F3C'>(iii)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span>Requestors agree that an order for Performances is an offer,
which is only accepted when the Requestor receives a confirmation of the order
from the Musician through the GigLinked Platform;</p>

<p class=SimpleL3 style='margin-left:99.25pt;text-indent:-28.35pt'><span
style='font-family:"Times",serif;color:#1F2F3C'>(iv)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span>Users agree to treat the other Users courteously and lawfully;</p>

<p class=SimpleL3 style='margin-left:99.25pt;text-indent:-28.35pt'><span
style='font-family:"Times",serif;color:#1F2F3C'>(v)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span>Requestors agree to provide a safe and appropriate working
environment for Musicians that is in compliance with all applicable laws and regulations,
and to provide reasonable co-operation to Musicians to enable them to provide Performances;</p>

<p class=SimpleL3 style='margin-left:99.25pt;text-indent:-28.35pt'><span
style='font-family:"Times",serif;color:#1F2F3C'>(vi)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span>Requestors acknowledge that their selected Musician may be
unavailable from time to time, e.g. due to illness, vacation or leaving the GigLinked
Platform.</p>

<p class=SimpleL4 style='margin-left:70.9pt;text-indent:-34.9pt'>(b)<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span>You shall NOT use the GigLinked Platform (including but not limited to
any Community Areas) to do, or attempt to do, or assist any third party’s
attempt to do any of the following:</p>

<p class=SimpleL3 style='text-indent:-37.1pt'><span style='font-family:"Times",serif;
color:#1F2F3C'>(i)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span>upload files that contain software or other material that
violates the intellectual property rights or rights of privacy or publicity of
any third party or otherwise use the GigLinked Platform to reproduce, without
permission, any copyrighted or trademarked materials;</p>

<p class=SimpleL3 style='text-indent:-37.1pt'><span style='font-family:"Times",serif;
color:#1F2F3C'>(ii)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span>download any file posted by another User that you know, or
reasonably should know, cannot be legally distributed through the GigLinked
Platform, or post or upload any content to which you have not obtained any
necessary rights or permissions to use accordingly;</p>

<p class=SimpleL3 style='text-indent:-37.1pt'><span style='font-family:"Times",serif;
color:#1F2F3C'>(iii)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span>defame, abuse, harass, victimize, degrade, intimidate, stalk,
threaten or otherwise violate the legal rights (such as, but not limited to,
rights of privacy and publicity) of others, including but not limited to our
staff and other Users;</p>

<p class=SimpleL3 style='text-indent:-37.1pt'><span style='font-family:"Times",serif;
color:#1F2F3C'>(iv)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span>post a review or rating unless such review or rating contains
your independent, honest, genuine opinion offered in good faith;</p>

<p class=SimpleL3 style='text-indent:-37.1pt'><span style='font-family:"Times",serif;
color:#1F2F3C'>(v)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span>any purpose or in any manner that is in violation of local, provincial,
national, or international law;</p>

<p class=SimpleL3 style='text-indent:-37.1pt'><span style='font-family:"Times",serif;
color:#1F2F3C'>(vi)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span>publish, post, upload, distribute or disseminate any profane,
defamatory, false, misleading, deceiving, fraudulent, threatening or unlawful
topics, names, materials or information, or any materials, information or
content that involve the sale or distribution of counterfeit or stolen items;</p>

<p class=SimpleL3 style='text-indent:-37.1pt'><span style='font-family:"Times",serif;
color:#1F2F3C'>(vii)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span>advertise or offer to sell any goods or services for any
commercial purpose or solicit employment or contract work which is not relevant
to services offered through the GigLinked Platform, that is, live musical
performances;</p>

<p class=SimpleL3 style='text-indent:-37.1pt'><span style='font-family:"Times",serif;
color:#1F2F3C'>(viii)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span>use the GigLinked Platform to collect usernames and/or email
addresses by electronic or other means without our express prior written
consent;</p>

<p class=SimpleL3 style='text-indent:-37.1pt'><span style='font-family:"Times",serif;
color:#1F2F3C'>(ix)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span>conduct or forward surveys, contests, pyramid schemes, or chain
letters;</p>

<p class=SimpleL3 style='text-indent:-37.1pt'><span style='font-family:"Times",serif;
color:#1F2F3C'>(x)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span>impersonate or attempt to impersonate another person (including,
without limitation, by using e-mail addresses associated with any other
person), or allow any other person or entity to use your identification to post
or view comments or otherwise use your account (and you also shall not provide
your password to any other person);</p>

<p class=SimpleL3 style='text-indent:-37.1pt'><span style='font-family:"Times",serif;
color:#1F2F3C'>(xi)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span>register to use the GigLinked Platform under different usernames
or identities, after your account has been suspended or terminated;</p>

<p class=SimpleL3 style='text-indent:-37.1pt'><span style='font-family:"Times",serif;
color:#1F2F3C'>(xii)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span>restrict or inhibit any other User from using and enjoying the GigLinked
Platform;</p>

<p class=SimpleL3 style='text-indent:-37.1pt'><span style='font-family:"Times",serif;
color:#1F2F3C'>(xiii)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span>imply or state that any statements you make are endorsed by us,
without our prior written consent;</p>

<p class=SimpleL3 style='text-indent:-37.1pt'><span style='font-family:"Times",serif;
color:#1F2F3C'>(xiv)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span>reverse engineer, disassemble, decompile, translate, modify,
adapt, license, sublicense, alter, copy, distribute, hack or interfere with the
GigLinked Platform, its servers or any connected networks, use a bot, spider,
manual and/or automatic processes or devices to data-mine, data-crawl, scrape
or index the GigLinked Platform in any manner, or attempt to do any of the
foregoing;</p>

<p class=SimpleL3 style='text-indent:-37.1pt'><span style='font-family:"Times",serif;
color:#1F2F3C'>(xv)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span>remove or alter, visually or otherwise, any copyrights,
trademarks or proprietary marks and rights owned or licensed by us;</p>

<p class=SimpleL3 style='text-indent:-37.1pt'><span style='font-family:"Times",serif;
color:#1F2F3C'>(xvi)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span>upload content that is offensive and/or harmful, including, but
not limited to, content that advocates, endorses, condones or promotes racism,
bigotry, hatred or physical harm of any kind against any individual or group of
individuals;</p>

<p class=SimpleL3 style='text-indent:-37.1pt'><span style='font-family:"Times",serif;
color:#1F2F3C'>(xvii)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span>upload content that provides materials or access to materials
that are obscene, adult or sexual or that exploit anyone, and in particular
people under the age of 18, in an abusive, violent or sexual manner; or</p>

<p class=SimpleL3 style='text-indent:-37.1pt'><span style='font-family:"Times",serif;
color:#1F2F3C'>(xviii)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;
</span></span>mirror, frame or archive any part of the GigLinked Platform or
any content or material contained on the GigLinked Platform without GigLinked’s
written permission.</p>

<p class=SimpleL4>(c)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span>You also agree not to use the GigLinked Platform:</p>

<p class=SimpleL3 style='text-indent:-30.0pt'><span style='font-family:"Times",serif;
color:#1F2F3C'>(i)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span>in any way that violates any applicable federal, provincial,
local or international law or regulation (including, without limitation, any
laws regarding the export of data or software to and from Canada or other
countries);</p>

<p class=SimpleL3 style='text-indent:-30.0pt'><span style='font-family:"Times",serif;
color:#1F2F3C'>(ii)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span>for the purpose of exploiting, harming or attempting to exploit
or harm minors in any way by exposing them to inappropriate content, asking for
personally identifiable information or otherwise;</p>

<p class=SimpleL3 style='text-indent:-30.0pt'><span style='font-family:"Times",serif;
color:#1F2F3C'>(iii)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span>to transmit, or procure the sending of, any advertising or
promotional material, including any “junk mail”, “chain letter” or “spam” or
any other similar solicitation; or</p>

<p class=SimpleL3 style='text-indent:-30.0pt'><span style='font-family:"Times",serif;
color:#1F2F3C'>(iv)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span>to engage in any other conduct that restricts or inhibits
anyone’s use or enjoyment of the site, or which, as determined by us, may harm
the GigLinked Platform’s owner or Users or expose them to liability.</p>

<p class=SimpleL1><span style='color:#1F2F3C'>10.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span>No Employment</p>

<p class=MsoNormal>GigLinked provides the GigLinked Platform which allows Requestors
to connect with independent Musicians to obtain and provide Performances. GigLinked
is not the employer of any Musicians. Requestors acknowledge that such Musician
provides services to Requestors as an independent contractor within the meaning
of any applicable law and has full responsibility for complying with all
requirements of any law, regulation or order, and is not an employee, joint
venturer, partner, agent, or franchisee of GigLinked for any purpose whatsoever.</p>

<p class=SimpleL1><span style='color:#1F2F3C'>11.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span>Special Promotions </p>

<p class=MsoNormal>From time to time, GigLinked may offer to Requestors at its
sole discretion special promotional rates and other incentives (“<b>Promotions</b>”).
Such Promotions will be offered through the form of promotional codes that can
be applied on the invoiced fees for Performances <u>only</u> (“<b>Promotional
Codes</b>”). In compliance with applicable laws, the Promotional Codes (i) can
be limited with time limits for their redemption or use or be offered through
the form of a percentage or fixed lump sum, (ii) are subject to change, will be
run at our sole discretion, and can be activated, modified or removed at any
time by us without advance notification, and (iii) will be subject to such
terms and conditions as we may determine<span lang=EN-CA>. Promotional Codes
can also be limited to a credit on past Performances or as compensation for any
prejudice incurred by a Requestor, the whole at GigLinked’s entire discretion
and subject to any additional applicable terms and conditions. </span></p>

<p class=SimpleL1><span style='color:#1F2F3C'>12.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span>Intellectual Property Rights</p>

<p class=MsoNormal>The GigLinked Platform, and the information, data, content
and materials, which it contains (“<b>GigLinked Materials</b>”), are the
property of GigLinked and/or its affiliates and licensors, excluding
User-generated content, which GigLinked has a right to use as described below.
The GigLinked Materials are protected from unauthorized use, copying and
dissemination by Canadian and United States copyright law, trademark law,
international conventions, and other intellectual property laws. GigLinked
and/or its affiliates and licensors are and shall continue to be the sole and
exclusive owner of all right, title and interest in and to all intellectual
property rights associated with the GigLinked Materials. Any use of GigLinked
Materials, other than as expressly permitted herein, is prohibited without the
prior permission of GigLinked and/or the relevant right holder. The logos and
trademarks of GigLinked, including without limitation “GIGLINKED”<b> </b>and
the GigLinked logo are trademarks owned by GigLinked. Any other trademarks,
service marks, logos and/or trade names appearing on the GigLinked Platform are
the property of their respective owners. You may not copy or use any of the
marks, logos or trade names appearing on the GigLinked Platform without the express
prior written consent of the owner.</p>

<p class=SimpleL1><span style='color:#1F2F3C'>13.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span>The Apps / Mobile Devices</p>

<p class=SimpleL4 style='margin-left:70.9pt;text-indent:-34.9pt'>(a)<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span>The GigLinked Platform may allow you to access our services, download
our Apps, upload content to the GigLinked Platform, and receive messages on
your mobile device (collectively “<b>Mobile Features</b>”). Your mobile device
carrier may prohibit or restrict certain Mobile Features and certain Mobile
Features may be incompatible with your mobile device carrier or mobile device.
In addition, your mobile device carrier may charge you for standard messaging,
data, and other fees to participate in Mobile Features. We have no
responsibility or liability for any fees or charges you incur when using the
Mobile Features. You should check with your mobile device carrier to find out
whether any fees or charges will apply, what plans are available and how much
they cost. You should also contact your mobile device carrier with any other
questions regarding these issues.</p>

<p class=SimpleL4 style='margin-left:70.9pt;text-indent:-35.45pt'>(b)<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span>You acknowledge that your use of the Apps is subject to any terms set
forth in the terms of service of the third party providing the mobile device on
which each App operates (e.g., Apple iOS or Android). <span lang=EN>If you
access or download the Apps from the Apple Appstore or the Google Play Store,
you agree to all the terms and conditions of the Appstore license or the Google
Play Store license.</span></p>

<p class=SimpleL4 style='margin-left:70.9pt;text-indent:-35.45pt'>(c)<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span>GigLinked shall not be liable if you do not have a compatible mobile
device or if you download the wrong version of an App for your mobile device. GigLinked
reserves the right to terminate the use of the Apps or any other aspect of the GigLinked
Platform should you be using the Apps or the GigLinked Platform with an
incompatible or unauthorized device. GigLinked is not responsible for your
internet connection or for any consequences of intermittent or unavailable connectivity.</p>

<p class=SimpleL1><span style='color:#1F2F3C'>14.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span>Modifications to the GigLinked Platform and Interruption of Performances</p>

<p class=MsoNormal>We reserve the right in our sole discretion to review,
improve, modify or discontinue, temporarily or permanently, the GigLinked
Platform or any content or information on the GigLinked Platform or the Apps,
including any features therein, at any time with or without notice to you. We
shall not be liable to you or any party should we exercise such rights under
this Section. Modifications may include, but are not limited to, the addition
of new fee-based services. Any new features that augment or enhance the
then-current services on the GigLinked Platform shall also be subject to these
Terms of Use. You understand and agree that temporary interruptions of the
services available through this Platform may occur as normal events. </p>

<p class=MsoNormal>You understand and agree that the services available on the GigLinked
Platform are provided “AS IS” and that we assume no responsibility for the
timeliness, deletion, failed delivery or failure to store any user
communications or personalization settings.</p>

<p class=SimpleL1><span style='color:#1F2F3C'>15.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span>Confidentiality</p>

<p class=MsoNormal>The term “<b>Confidential Information</b>” shall mean any
and all of GigLinked’s trade secrets, confidential and proprietary information,
personal information and all other information and data of GigLinked and its
affiliates that is not generally known to the public or other third parties.
The GigLinked Platform contains secured components that are accessible only to
those who have been granted a user name and password by GigLinked. Information
contained within the secure components of the GigLinked Platform is
confidential and proprietary and part of GigLinked’s Confidential Information.
You acknowledge that Confidential Information is a valuable, special and unique
asset of GigLinked and agree that you will not disclose or use Confidential
Information other than as necessary for you to make use of the GigLinked
Platform as expressly permitted by these Terms of Use and only during the term
of these Terms of Use.</p>

<p class=SimpleL1><a name="_Ref64991621"><span style='color:#1F2F3C'>16.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span>Disclaimer of Warranties; Limitation on Liability</a></p>

<p class=SimpleL2 style='margin-left:0in;text-indent:0in'><span
style='color:#1F2F3C;text-decoration:none'>(a)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span>Disclaimer of Warranty</p>

<p class=MsoNormal>USE OF THE GIGLINKED PLATFORM IS ENTIRELY AT YOUR OWN RISKS.
TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW, THE GIGLINKED PLATFORM AND ALL
MATERIALS AND PERFORMANCES ARE PROVIDED ON AN “AS IS” AND “AS AVAILABLE” BASIS
WITHOUT WARRANTIES OF ANY KIND, EITHER EXPRESS OR IMPLIED, LEGAL OR
CONTRACTUAL, INCLUDING, BUT NOT LIMITED TO, IMPLIED WARRANTIES OF
MERCHANTABILITY OR FITNESS FOR A PARTICULAR PURPOSE, OR ANY WARRANTY OF TITLE
OR NON-INFRINGEMENT. WITHOUT LIMITING THE FOREGOING, NEITHER GIGLINKED NOR ITS
AFFILIATES OR LICENSORS WARRANT THAT THE GIGLINKED PLATFORM WILL BE
UNINTERRUPTED OR ERROR-FREE; NOR DO THEY MAKE ANY WARRANTY AS TO ANY CONTENT
AVAILABLE IN OR THROUGH THE GIGLINKED PLATFORM; NOR DO THEY MAKE ANY WARRANTY
AS TO ANY MUSICIAN’S REGISTRATION, PROFESSIONAL ACCREDITATION OR LICENSE; NOR
DO THEY MAKE ANY WARRANTY AS TO THE RESULTS THAT MAY BE OBTAINED FROM THE USE
OF THE GIGLINKED PLATFORM, OR AS TO THE TIMELINESS, ACCURACY, RELIABILITY,
QUALITY, SUITABILITY, SAFETY, COMPLETENESS OR CONTENT OF THE SERVICES OR ANY
PRODUCTS, CONTENT, INFORMATION OR MATERIALS PROVIDED THROUGH OR IN CONNECTION
WITH THE USE OF THE GIGLINKED PLATFORM OR THESE TERMS OF USE. </p>

<p class=MsoNormal>ACCESS TO THE GIGLINKED PLATFORM IS PROVIDED FREE OF CHARGE
AS A COURTESY. NEITHER GIGLINKED NOR ITS AFFILIATES OR LICENSORS ARE
RESPONSIBLE FOR THE CONDUCT, WHETHER ONLINE OR OFFLINE, OF ANY USER OF THE GIGLINKED
PLATFORM (INCLUDING BUT NOT LIMITED TO THE CONDUCT OF ANY REQUESTORS OR MUSICIAN).
NEITHER GIGLINKED NOR ITS AFFILIATES OR LICENSORS WARRANT THAT THE GIGLINKED
PLATFORM IS FREE FROM VIRUSES, WORMS, TROJAN HORSES, OR OTHER HARMFUL
COMPONENTS. GIGLINKED AND ITS AFFILIATES AND LICENSORS CANNOT AND DO NOT
GUARANTEE THAT ANY INFORMATION, PERSONAL OR OTHERWISE, SUPPLIED BY YOU WILL NOT
BE MISAPPROPRIATED, INTERCEPTED, DELETED, DESTROYED OR USED BY OTHERS.</p>

<p class=SimpleL2 style='margin-left:0in;text-indent:0in'><span
style='color:#1F2F3C;text-decoration:none'>(b)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span>No Guarantee</p>

<p class=MsoNormal>Without limitation to foregoing or any other terms in these
Terms of Use, GigLinked has no control over and does not guarantee in any way
the following contractual elements between Users: </p>

<p class=SimpleL3><span style='font-family:"Times",serif;color:#1F2F3C'>(i)<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span>the legality, feasibility or relevance of any Performances;</p>

<p class=SimpleL3 style='margin-left:70.9pt;text-indent:-34.9pt'><span
style='font-family:"Times",serif;color:#1F2F3C'>(ii)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span>the veracity of any Rating, Review or other User Content, the
accuracy, precision of any calculation or measurement as well as, in general,
any description or explanation concerning any Performances;</p>

<p class=SimpleL3 style='margin-left:70.9pt;text-indent:-34.9pt'><span
style='font-family:"Times",serif;color:#1F2F3C'>(iii)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span>the accuracy, conformity or sufficiency of any plan, sketch or
drawing (if applicable); </p>

<p class=SimpleL3 style='margin-left:70.9pt;text-indent:-34.9pt'><span
style='font-family:"Times",serif;color:#1F2F3C'>(iv)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span>any declaration, affirmation, representation, abstention and more
generally, any behavior of Users of the GigLinked Platform; </p>

<p class=SimpleL3 style='margin-left:70.9pt;text-indent:-34.9pt'><span
style='font-family:"Times",serif;color:#1F2F3C'>(v)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span>the value of the content of any Performances requested by the Requestor
as well as the value of the content of any submission from the Musician in
response to this request;</p>

<p class=SimpleL3 style='margin-left:70.9pt;text-indent:-34.9pt'><span
style='font-family:"Times",serif;color:#1F2F3C'>(vi)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span>the conformity of any document exchanged between Users or the
authenticity or provenance of such document; and</p>

<p class=SimpleL3 style='margin-left:70.9pt;text-indent:-34.9pt'><span
style='font-family:"Times",serif;color:#1F2F3C'>(vii)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span>the integrity, security or compliance of any Performances.</p>

<p class=SimpleL2 style='margin-left:0in;text-indent:0in'><span
style='color:#1F2F3C;text-decoration:none'>(c)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span>Limitation of Liability</p>

<p class=MsoNormal>UNDER NO CIRCUMSTANCES WILL <span style='text-transform:
uppercase'>GigLinked </span>OR ITS AFFILIATES BE LIABLE FOR ANY DAMAGES RESULTING
FROM <span lang=EN-CA>ANY LOST REVENUES, PROFITS OR LOSS OF CLIENTS OR EXPECTED
CLIENTS, DAMAGES FOR BUSINESS INTERRUPTION OR FAILURE TO REALIZE EXPECTED
SAVINGS, LOSS OF USE, LOSS OF GOODWILL, LOSS OF DATA AND ANY OTHER INCIDENTAL
COMMERCIAL OR FINANCIAL LOSSES OF ANY KIND OR DAMAGES THAT ARE NOT THE DIRECT AND
IMMEDIATE CONSEQUENCE OF GIGLINKED’S OR SUCH AFFILIATE’S ACTIONS, </span>YOUR
USE OF OR INABILITY TO USE THE GIGLINKED PLATFORM, EVEN IF YOU WERE OR SHOULD
HAVE BEEN ADVISED OF THE POSSIBILITY OF THE SAME. <span style='text-transform:
uppercase'>GigLinked </span>AND ITS AFFILIATES DO NOT ACCEPT ANY LIABILITY WITH
RESPECT TO THE QUALITY OR FITNESS OF ANY PERFORMANCES. </p>

<p class=SimpleL2 style='margin-left:0in;text-indent:0in'><span
style='color:#1F2F3C;text-decoration:none'>(d)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span>Release </p>

<p class=MsoNormal>GIGLINKED AND ITS AFFILIATES EXPRESSLY DISCLAIM ANY
LIABILITY THAT MAY ARISE BETWEEN USERS OF THE GIGLINKED PLATFORM (INCLUDING,
WITHOUT LIMITATION, BETWEEN A REQUESTOR AND A MUSICIAN). THE GIGLINKED PLATFORM
IS ONLY A VENUE FOR CONNECTING USERS. TO THE EXTENT THAT THE GIGLINKED PLATFORM
CONNECTS A USER TO A MUSICIAN FOR THE PURPOSES OF PROVIDING PERFORMANCES, AS
APPLICABLE, GIGLINKED WILL NOT BE RESPONSIBLE FOR ASSESSING THE SUITABILITY,
LEGALITY OR ABILITY OF ANY MUSICIAN AND EACH REQUESTER EXPRESSLY WAIVE AND
RELEASE GIGLINKED FROM ANY AND ALL LIABILITY, CLAIMS OR DAMAGES ARISING FROM OR
IN ANY WAY RELATED TO THE MUSICIAN.</p>

<p class=SimpleL1><span style='color:#1F2F3C'>17.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span>Indemnification</p>

<p class=MsoNormal>You hereby agree to indemnify, defend, and hold harmless GigLinked,
its affiliates, its licensors and their respective directors, officers, parent
organizations, subsidiaries, affiliates, members, employees, agents, attorneys,
independent contractors and vendors from and against any and all claims,
losses, expenses, liabilities, damages or demands (including, without
limitation, attorneys’ fees and costs incurred), in connection with or
resulting from, directly or indirectly: (a) your use or misuse of, or inability
to use, the GigLinked Platform, (b) your violation of these Terms of Use or any
Service Contract, (c) your violation of any applicable law or regulation; (d)
your violation of the rights of another, and (e) Your Information and content
that you submit or transmit through the GigLinked Platform. GigLinked reserves
the right, at its own expense, to assume the exclusive defense and control of
any matter otherwise subject to your indemnification. You will not, in any
event, settle any such claim or matter without the prior written consent of GigLinked.</p>

<p class=SimpleL1 style='page-break-after:avoid'><span style='color:#1F2F3C'>18.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span>Survival</p>

<p class=MsoNormal style='page-break-after:avoid'>All provisions that should by
their nature survive the expiration or termination of these Terms of Use, shall
so survive.</p>

<p class=SimpleL1><span style='color:#1F2F3C'>19.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span>Governing Law; Jurisdiction</p>

<p class=MsoNormal><span lang=EN-CA>These Terms of Use and any disputes arising
hereunder or related to such will be governed by the laws in effect in the
Province of Quebec, without reference to its conflicts of law principles. To
the extent permitted by applicable law, any legal actions, suits or proceedings
arising out of these Terms of Use will be brought exclusively in the courts of
the Province of Quebec, located in the District of Montreal, and the User and GigLinked
hereby accept and submit to the personal jurisdiction of these courts with
respect to any legal actions, suits or proceedings arising out of these Terms
of Use</span>. </p>

<p class=SimpleL1><span style='color:#1F2F3C'>20.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span>Assignment</p>

<p class=MsoNormal style='margin-bottom:0in'><span lang=EN-CA>You may not
assign these Terms of Use without the prior written approval of GigLinked. Any
purported assignment in violation of this section shall be void. </span></p>

<p class=MsoNormal style='margin-bottom:0in'><span lang=EN-CA>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0in'><span lang=EN-CA>GigLinked shall
have the right, without your consent and in its sole discretion, to assign the
Agreement and/or to transfer all or any of its obligations and rights hereunder
provided that the assignee of GigLinked's obligations under such assignment is,
in GigLinked's reasonable judgment, able to perform GigLinked's obligations
under these Terms of Use. Upon such assignment and/or transfer, GigLinked shall
have no further liability to the Musician for the obligations transferred.</span></p>

<p class=MsoNormal>&nbsp;</p>

<p class=SimpleL1><span style='color:#1F2F3C'>21.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span>General Provisions</p>

<p class=SimpleL4 style='margin-left:70.9pt;text-indent:-34.9pt'><span
lang=EN-CA>(a)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-CA>These Terms of Use, including any modifications
and supplements to these Terms of Use, and the Privacy Policy, constitutes the
entire agreement and understanding of the parties hereto with respect to its
subject matter and replaces and supersedes all prior or contemporaneous
negotiations, discussions, agreements, arrangements, offers, undertakings or
statements, whether verbal, electronic, or in writing, regarding such subject
matter. </span></p>

<p class=SimpleL4 style='margin-left:1.0in;text-indent:-.5in'>(b)<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span>No agency, partnership, joint venture, employer-employee or
franchiser-franchisee relationship is intended or created by these Terms of Use.
A failure by us to enforce any provision(s) of these Terms of Use will not be
construed as a waiver of any provision or right. </p>

<p class=SimpleL4 style='margin-left:1.0in;text-indent:-.5in'><span lang=EN-CA>(c)<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-CA>If any provision of these Terms of Use is held
to be invalid or unenforceable, such provision shall be struck and the
remaining provisions shall be enforced to the fullest extent under law. The
failure of GigLinked to enforce any right or provision in these Terms of Use
shall not constitute a waiver of such right or provision unless acknowledged
and agreed to by GigLinked in writing. </span></p>

<p class=SimpleL4 style='margin-left:1.0in;text-indent:-.5in'>(d)<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span>These Terms of Use shall inure to the benefit of, and shall be binding
on, to the parties to these Terms of Use and their respective permitted
successors and assigns. <span lang=EN-CA>Except as explicitly set forth in these
Terms of Use, nothing contained in this provision or these Terms of Use is
intended to or shall be interpreted to create any third‐party beneficiary
claims.</span></p>

<p class=SimpleL4 style='margin-left:1.0in;text-indent:-.5in'>(e)<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span>The captions in these Terms of Use are solely for convenience and shall
not affect the interpretation of these Terms of Use. </p>

<p class=SimpleL4 style='margin-left:1.0in;text-indent:-.5in'>(f)<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span>Any attempt to alter, amend, change or supplement these Terms of Use
shall be null and void, unless otherwise agreed to in a written agreement
between you and GigLinked, or as contemplated by these Terms of Use.</p>

<p class=SimpleL4 style='margin-left:1.0in;text-indent:-.5in'>(g)<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span>GigLinked shall have no liability to you for any failure or delay in
performing its obligations in these Terms of Use where such failure or delay is
caused by an event or circumstance beyond GigLinked’s reasonable control
including, without limitation, national health emergencies, pandemics
(including, without limitation COVID-19), any restrictions imposed by local
health authorities with respect to such pandemics, breakdown of systems or
network access, failure of Musicians to perform, acts of terrorism or accident.
To the extent that anything in or associated with the GigLinked Platform is in
conflict or inconsistent with these Terms of Use, these Terms of Use shall take
precedence and shall control. In any action to enforce these Terms of Use, the
prevailing party will be entitled to costs and reasonable attorney’s fees.</p>

<p class=SimpleL1><span style='color:#1F2F3C'>22.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span>Language</p>

<p class=MsoNormal>The parties acknowledge expressly requiring these Terms of
Use and all related documents to be drawn up in the English language.</p>

<p class=SimpleL1 style='page-break-after:avoid'><span style='color:#1F2F3C'>23.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span>Notices and Contact Information</p>

<p class=MsoNormal style='margin-bottom:0in'><span lang=EN-CA>GigLinked may
give notice by means of a general notice to you through the GigLinked Platform
or through electronic mail to your email address on record in GigLinked's
account information. Such notice shall be deemed to have been given upon the
expiration of 48 hours after posting on the Platform or 12 hours after sending
the email. You may give notice to GigLinked (such notice shall be deemed given
when received by GigLinked) at any time by letter sent by email to </span>info@giglinked.live.</p>

</div>

</body>

</html>
`;
