export default {
  'EventProposal.quote': { en: 'Quote', fr: 'Devis' },
  'EventProposal.cancelBooking': {
    en: 'Cancel booking',
    fr: 'Annuler la réservation',
  },
  'EventProposal.hire': { en: 'Hire me', fr: 'Engagez-moi' },
  'EventProposal.goToDashboard': {
    en: 'Go to Dashboard',
    fr: 'Accéder au tableau de bord',
  },
  'EventProposal.hired': { en: 'Hired!', fr: 'Embauché!' },
  'EventProposal.message': { en: 'Message', fr: 'Message' },
};
