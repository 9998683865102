// This file was generated by S-express.
// You should not edit it manually.

import * as querystring from 'querystring';

import * as ALL_TYPES from '../../shared';

class SexpressClass {
  constructor(
    config: {
      baseURL?: string;
      getHeaders?: () => any;
      onError?: (
        status: number,
        error: Error,
        retry: () => Promise<any>,
      ) => Promise<any>;
    } = {
      baseURL: '',
      getHeaders: () => ({}),
      onError: async () => null,
    },
  ) {
    this.baseURL = config.baseURL || '';
    this.headerFn = config.getHeaders;
    if (config.onError) {
      this.onError = config.onError;
    }
  }

  getHeaders = () => {
    return { ...this.BASE_HEADERS, ...this.headerFn() };
  };

  BASE_HEADERS = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'Cache-Control': 'no-cache',
  };

  baseURL = '';
  headerFn: any = () => ({});
  onError?: (
    status: number,
    error: Error,
    retry: () => Promise<any>,
  ) => Promise<any>;

  put = (url: string, data: any = {}) => {
    return this._fetch(this.baseURL + url, {
      method: 'PUT',
      headers: this.getHeaders(),
      body: JSON.stringify(data),
    });
  };

  post = (url: string, data: any = {}) => {
    return this._fetch(this.baseURL + url, {
      method: 'POST',
      headers: this.getHeaders(),
      body: JSON.stringify(data),
    });
  };

  patch = (url: string, data: any = {}) => {
    return this._fetch(this.baseURL + url, {
      method: 'PATCH',
      headers: this.getHeaders(),
      body: JSON.stringify(data),
    });
  };

  get = (url: string, params: any = {}) => {
    const queryString = '?' + querystring.stringify(params);
    return this._fetch(this.baseURL + url + queryString, {
      method: 'GET',
      headers: this.getHeaders(),
    });
  };

  delete = (url: string, params: any = {}) => {
    const queryString = '?' + querystring.stringify(params);
    return this._fetch(this.baseURL + url + queryString, {
      method: 'DELETE',
      headers: this.getHeaders(),
    });
  };

  // @ts-ignore
  _fetch = async (url: string, options: any) => {
    const res = await fetch(url, options);

    if (res.status >= 400) {
      let jsonError: any;
      try {
        const errJson = await res.json();
        if (errJson.error) {
          jsonError = new Error(errJson.error);
        }
      } catch (e) {
        const err: any = e;
        jsonError = new Error(err);
      } finally {
        if (this.onError) {
          // eslint-disable-next-line
          return this.onError(res.status, jsonError, async () => {
            const updatedOptions = {
              ...options,
              headers: this.getHeaders(),
            };
            return this._fetch(url, updatedOptions);
          });
        } else {
          // eslint-disable-next-line
          throw jsonError;
        }
      }
    } else {
      return res.json();
    }
  };

  getAct = (payload: {
    actId: ALL_TYPES.Act['id'];
  }): Promise<ALL_TYPES.Act> => {
    return this.get('/act', payload);
  };
  initAct = (payload: Partial<ALL_TYPES.Act>): Promise<ALL_TYPES.Act> => {
    return this.post('/me/artist/act', payload);
  };
  editMeAct = (
    payload: Partial<ALL_TYPES.Act>,
  ): Promise<ALL_TYPES.Act | undefined> => {
    return this.put('/me/artist/act', payload);
  };
  getMeAct = (payload: {
    actId: ALL_TYPES.Act['id'];
  }): Promise<ALL_TYPES.Act> => {
    return this.get('/me/artist/act', payload);
  };
  editActTags = (payload: Partial<ALL_TYPES.Tag>): Promise<ALL_TYPES.Tag[]> => {
    return this.put('/me/artist/act/tags', payload);
  };
  editMeActReorderSongs = (payload: {
    songs: ALL_TYPES.Act['songs'];
    actId: ALL_TYPES.Act['id'];
  }): Promise<ALL_TYPES.Act> => {
    return this.put('/me/artist/act/songs/reorder', payload);
  };
  editMeActReorderVideos = (payload: {
    videos: ALL_TYPES.Act['videos'];
    actId: ALL_TYPES.Act['id'];
  }): Promise<ALL_TYPES.Act> => {
    return this.put('/me/artist/act/videos/reorder', payload);
  };
  editMeActReorderGallery = (payload: {
    gallery: ALL_TYPES.Act['gallery'];
    actId: ALL_TYPES.Act['id'];
  }): Promise<ALL_TYPES.Act> => {
    return this.put('/me/artist/act/gallery/reorder', payload);
  };
  inviteArtistToAct = (payload: {
    actId: ALL_TYPES.Act['id'];
    invitedArtistId?: ALL_TYPES.Artist['id'];
    invitedEmail?: string;
  }): Promise<{
    members: ALL_TYPES.Act['members'];
    pendingMembers: ALL_TYPES.Act['pendingMembers'];
  }> => {
    return this.post('/me/artist/act/members/invite', payload);
  };
  removeArtistFromAct = (payload: {
    actId: ALL_TYPES.Act['id'];
    artistId: ALL_TYPES.Artist['id'];
  }): Promise<{
    members: ALL_TYPES.Act['members'];
    pendingMembers: ALL_TYPES.Act['pendingMembers'];
  }> => {
    return this.delete('/me/artist/act/members/remove', payload);
  };
  acceptActInvite = (payload: {
    actId: ALL_TYPES.Act['id'];
  }): Promise<ALL_TYPES.Artist> => {
    return this.post('/me/artist/acts/invite', payload);
  };
  leaveAct = (payload: {
    actId: ALL_TYPES.Act['id'];
  }): Promise<ALL_TYPES.Artist> => {
    return this.delete('/me/artist/acts/leave', payload);
  };
  deleteAct = (payload: {
    actId: ALL_TYPES.Act['id'];
  }): Promise<ALL_TYPES.Artist> => {
    return this.delete('/me/artist/act', payload);
  };

  initArtist = (): Promise<ALL_TYPES.Artist> => {
    return this.post('/me/artist');
  };
  editMeArtist = (
    payload: Partial<ALL_TYPES.Artist>,
  ): Promise<ALL_TYPES.Artist | undefined> => {
    return this.put('/me/artist', payload);
  };
  editMeArtistReorderSongs = (payload: {
    songs: ALL_TYPES.Artist['songs'];
  }): Promise<ALL_TYPES.Artist> => {
    return this.put('/me/artist/songs/reorder', payload);
  };
  editMeArtistReorderVideos = (payload: {
    videos: ALL_TYPES.Artist['videos'];
  }): Promise<ALL_TYPES.Artist> => {
    return this.put('/me/artist/videos/reorder', payload);
  };
  editMeArtistReorderGallery = (payload: {
    gallery: ALL_TYPES.Artist['gallery'];
  }): Promise<ALL_TYPES.Artist> => {
    return this.put('/me/artist/gallery/reorder', payload);
  };
  getMeArtist = (): Promise<ALL_TYPES.Artist> => {
    return this.get('/me/artist');
  };
  editArtistTags = (
    payload: Partial<ALL_TYPES.Tag>,
  ): Promise<ALL_TYPES.Tag[]> => {
    return this.put('/me/artist/tags', payload);
  };
  getArtist = (payload: {
    artistId: ALL_TYPES.Artist['id'];
  }): Promise<ALL_TYPES.Artist> => {
    return this.get('/artist', payload);
  };
  getArtists = (payload: {
    searchTerm?: string;
    page?: number;
    limit?: number;
  }): Promise<{
    totalDocs: number;
    artists: ALL_TYPES.Artist[];
    totalPages: number;
  }> => {
    return this.get('/artists', payload);
  };
  getArtistPayouts = (): Promise<{
    page: number;
    formattedPayouts: ALL_TYPES.FormattedPayout[];
  }> => {
    return this.get('/me/artist/payouts');
  };

  authRefresh = (payload: {
    refreshToken: string;
  }): Promise<{
    tokenType: string;
    accessToken: string;
    refreshToken: string;
    expires: number;
  }> => {
    return this.post('/authRefresh', payload);
  };
  signup = (payload: {
    email: string;
    password: string;
    confirmPassword: string;
  }): Promise<{
    tokenType: string;
    accessToken: string;
    refreshToken: string;
    expires: number;
  }> => {
    return this.post('/signup', payload);
  };
  loginLocal = (payload: {
    email: string;
    password: string;
  }): Promise<{
    tokenType: string;
    accessToken: string;
    refreshToken: string;
    expires: number;
  }> => {
    return this.post('/login', payload);
  };
  loginWithGoogle = (payload: {
    accessToken: string;
  }): Promise<{
    tokenType: string;
    accessToken: string;
    refreshToken: string;
    expires: number;
  }> => {
    return this.post('/authGoogle', payload);
  };
  loginAdmin = (payload: {
    email: string;
    password: string;
  }): Promise<{
    tokenType: string;
    accessToken: string;
    refreshToken: string;
    expires: number;
  }> => {
    return this.post('/admin/login', payload);
  };
  sendForgotPasswordEmail = (payload: { email: string }): Promise<void> => {
    return this.post('/sendForgotPasswordEmail', payload);
  };

  subToNewsLetter = (payload: { email: string }): Promise<void> => {
    return this.post('/mailchimp', payload);
  };

  generatePresignedUrls = (): Promise<
    {
      uploadUrl: any;
      fileName: string;
      filePath: string;
    }[]
  > => {
    return this.get('/presignedurl');
  };

  userSendMessage = (payload: {
    text: string;
    conversationId: string;
    otherUserId: string;
  }): Promise<ALL_TYPES.Conversation | undefined> => {
    return this.post('/sendMessage', payload);
  };

  getConversation = (payload: {
    conversationId: string;
  }): Promise<ALL_TYPES.Conversation> => {
    return this.get('/conversation', payload);
  };

  markMessageAsRead = (payload: { messageId: string }): Promise<void> => {
    return this.post('/markMessageAsRead', payload);
  };
  getConversations = (payload: {
    limit: number;
  }): Promise<ALL_TYPES.Conversation[]> => {
    return this.get('/conversations', payload);
  };

  getPerformers = (payload: {
    keywords?: string;
    location?: string;
    tagIds?: ALL_TYPES.Tag['id'][];
    limit?: number;
    page?: number;
  }): Promise<{
    performers: (ALL_TYPES.Artist & ALL_TYPES.Act)[];
    totalPages: number;
  }> => {
    return this.get('/performers', payload);
  };

  initReview = (payload: {
    transactionId: ALL_TYPES.Transaction['id'];
    rating: ALL_TYPES.Review['rating'];
    text: ALL_TYPES.Review['text'];
  }): Promise<ALL_TYPES.Review> => {
    return this.post('/review', payload);
  };
  editReview = (payload: {
    reviewId: ALL_TYPES.Review['id'];
    rating: ALL_TYPES.Review['rating'];
    text: ALL_TYPES.Review['text'];
  }): Promise<ALL_TYPES.Review> => {
    return this.put('/review', payload);
  };

  retrieveStripeAccountDetails = async (payload: {
    userId: string;
  }): Promise<ALL_TYPES.PaymentMethod | undefined> => {
    return await this.get('/me/stripeAccount', payload);
  };

  createCustomerAndPaymentMethod = (payload: {
    stripeBankAccountToken: string;
  }): Promise<void> => {
    return this.post('/me/paymentMethod', payload);
  };

  detachPaymentMethod = (): Promise<void> => {
    return this.put('/me/paymentMethod/detach');
  };

  generateStripeAccountOnboardingURL = (): Promise<{
    url: string;
  }> => {
    return this.get('/stripeAccountOnboardingURL');
  };

  updateOrCreateStripeCustomerAndAccount = (payload: {
    stripeBankAccountToken: string;
  }): Promise<void> => {
    return this.put('/me/banking', payload);
  };
  checkIfStripeAccountCreationIsValid = (): Promise<any> => {
    return this.get('/me/banking/stripeIsValid');
  };

  getPopularTags = (): Promise<ALL_TYPES.Tag[]> => {
    return this.get('/popularTags');
  };

  calculatePrices = (payload: {
    actId?: string;
    artistId?: string;
    quotePrice: string;
  }): Promise<{
    gstRate: number;
    qstRate: number;
    taxesRate: number;
    gigLinkedCutRate: number;
    performancePriceInCents: number;
    performancePriceFormatted: string;
    gigLinkedCutPriceInCents: number;
    gigLinkedCutPriceFormatted: string;
    subTotalPriceInCents: number;
    subTotalPriceFormatted: string;
    qstPriceInCents: number;
    qstPriceFormatted: string;
    gstPriceInCents: number;
    gstPriceFormatted: string;
    taxesPriceInCents: number;
    taxesPriceFormatted: string;
    finalTotalPriceInCents: number;
    finalTotalPriceFormatted: string;
    requiredDepositIncents: number;
    requiredDepositFormatted: string;
  }> => {
    return this.post('/prices', payload);
  };
  createBookingTransaction = (
    payload: ALL_TYPES.BookingRequestPayload,
  ): Promise<void> => {
    return this.post('/book', payload);
  };
  updateTransactionState = (payload: {
    action: ALL_TYPES.ActionType;
    transactionId: string;
    payload?: {
      quote?: string;
      stripeCardToken?: string;
      firstName?: string;
      lastName?: string;
      phoneNumber?: string;
      locationAddress?: string;
      city?: string;
      postalCode?: string;
      province?: string;
      country?: string;
      bookerQuoteDeclineMessage?: string;
      performerDeclineMessage?: string;
    };
  }): Promise<void> => {
    return this.post('/updateTransactionState', payload);
  };
  getTransactions = (payload: {
    status: ALL_TYPES.TransactionStatus;
    page?: number;
    month?: ALL_TYPES.MonthIndex;
    year?: number;
  }): Promise<{
    userTransactions: ALL_TYPES.Transaction[];
    artistTransactions: ALL_TYPES.Transaction[];
    actTransactions: ALL_TYPES.Transaction[];
    totalPages: number;
  }> => {
    return this.get('/transactions', payload);
  };
  getTransaction = (payload: {
    transactionId: ALL_TYPES.Transaction['id'];
  }): Promise<ALL_TYPES.Transaction> => {
    return this.get('/transaction', payload);
  };

  getMe = (): Promise<ALL_TYPES.User> => {
    return this.get('/me');
  };
  editMeUser = (payload: Partial<ALL_TYPES.User>): Promise<ALL_TYPES.User> => {
    return this.put('/me', payload);
  };
  resetPassword = (payload: {
    verificationKey: string;
    newPassword: string;
    newPasswordConfirmation: string;
  }): Promise<{
    tokenType: string;
    accessToken: string;
    refreshToken: string;
    expires: number;
  }> => {
    return this.post('/resetPassword', payload);
  };

  initMeEvent = (
    payload: Partial<ALL_TYPES.EventType>,
  ): Promise<ALL_TYPES.EventType> => {
    return this.post('/me/events', payload);
  };

  fetchMeEvents = (payload: {
    page: number;
    limit: number;
  }): Promise<{ events: ALL_TYPES.EventType[]; totalPages: number }> => {
    return this.get('/me/events', payload);
  };

  fetchEvents = (payload: {
    page: number;
    limit: number;
  }): Promise<{ events: ALL_TYPES.EventType[]; totalPages: number }> => {
    return this.get('/events', payload);
  };

  fetchMeEvent = (payload: {
    eventId: ALL_TYPES.EventType['id'];
  }): Promise<ALL_TYPES.EventType> => {
    return this.get('/me/event', payload);
  };

  editMeEvent = (
    payload: Partial<ALL_TYPES.EventType> & {
      eventId: ALL_TYPES.EventType['id'];
    },
  ): Promise<ALL_TYPES.EventType> => {
    return this.put('/me/event', payload);
  };

  initEventProposal = (
    payload: Partial<ALL_TYPES.EventProposalType>,
  ): Promise<ALL_TYPES.EventProposalType> => {
    return this.post('/eventproposals', payload);
  };

  fetchEventProposals = (payload: {
    page: number;
    limit: number;
    eventId: ALL_TYPES.EventType['id'];
  }): Promise<{
    eventProposals: ALL_TYPES.EventProposalType[];
    totalPages: number;
  }> => {
    return this.get('/eventproposals', payload);
  };
  createEventBookingTransaction = (
    payload: ALL_TYPES.BookingRequestPayload & {
      eventId: ALL_TYPES.EventType['id'];
      quote: string;
    },
  ): Promise<ALL_TYPES.EventType> => {
    return this.post('/bookevent', payload);
  };
  cancelEventBookingTransaction = (payload: {
    eventId: ALL_TYPES.EventType['id'];
    transactionId: ALL_TYPES.Transaction['id'];
  }): Promise<ALL_TYPES.EventType> => {
    return this.put('/bookevent', payload);
  };
  withdrawEventProposal = (payload: {
    eventId: ALL_TYPES.EventType['id'];
    eventProposalId: ALL_TYPES.EventProposalType['id'];
  }): Promise<{
    eventId: ALL_TYPES.EventType['id'];
    eventProposals: ALL_TYPES.EventProposalType[];
  }> => {
    return this.put('/eventproposals', payload);
  };
}

export default SexpressClass;
