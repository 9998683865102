import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { InputLabel } from '../../common/Typography';
import GiglinkedTextInput from '../../common/GiglinkedTextInput';
import i18n from '../../../constants/i18n';
import { ESTIMATED_AUDIENCE, EventType } from '../../../shared';
import GiEventsDropDown from '../../common/GiEventsDropDown';
import { Column, Row } from '../../common/Layout';
import { addDays, format, formatISO, isBefore } from 'date-fns';
import GiglinkedTimeInput from '../../common/GiglinkedTimeInput';
import styled from 'styled-components';
import Form from 'react-bootstrap/Form';
import toast from 'react-hot-toast';
import { useUiStore } from '../../../stores/ui';

const EventDetailsStepOne = observer(
  ({
    event,
    setEvent,
  }: {
    event: Partial<EventType>;
    setEvent: React.Dispatch<React.SetStateAction<any>>;
  }) => {
    const uiStore = useUiStore();

    return (
      <>
        <InputLabel htmlFor="name">
          {i18n('EventDetailsStepOne.eventName', 'Event name')}
        </InputLabel>

        <GiglinkedTextInput
          id="name"
          style={{ width: '100%', marginBottom: '1rem' }}
          placeholder={i18n(
            'EventDetailsStepOne.eventNameplaceholder',
            "Ex. Valentine's Day Lunch",
          )}
          type="text"
          value={event.name}
          onChangeText={(ev) => setEvent({ ...event, name: ev })}
        />
        <InputLabel htmlFor="eventDate">
          {i18n('EventDetailsStepOne.eventDate', 'Date of event')}
        </InputLabel>
        <GiglinkedTextInput
          style={{ width: '100%', marginBottom: '1rem' }}
          type="date"
          value={
            event.eventDate
              ? format(new Date(event.eventDate!), 'y-MM-dd')
              : undefined
          }
          onChangeText={(date: string) => {
            if (isBefore(addDays(new Date(date), 1), new Date())) {
              toast.error(
                i18n(
                  'EventDetailsStepOne.dateInPastError',
                  'You cannot select a date in the past.',
                ),
              );
              setEvent({ ...event, eventDate: undefined });
            } else {
              const dateArray = date.split('-');
              if (dateArray.length === 3) {
                const eventDate = formatISO(
                  new Date(
                    Number(dateArray[0]),
                    Number(dateArray[1]) - 1,
                    Number(dateArray[2]),
                    0,
                    0,
                    0,
                  ),
                );
                setEvent({ ...event, eventDate });
              }
            }
          }}
          id="eventDate"
        />
        <Row
          style={{
            width: '100%',
            flexDirection: uiStore.isMobile ? 'column' : 'row',
          }}
        >
          <Column
            style={{
              width: uiStore.isMobile || uiStore.isTablet ? '100%' : '50%',
              marginRight: '1rem',
            }}
          >
            <InputLabel htmlFor="startTime">
              {i18n('EventDetailsStepOne.startTime', 'Time of event')}
            </InputLabel>
            <GiglinkedTimeInput
              value={
                event.startTime && format(new Date(event.startTime), 'hh:mma')
              }
              onChangeText={(time: string) => {
                if (event.eventDate) {
                  const meridium = time.slice(-2);
                  const timeArray = time.slice(0, -2).split(':');

                  if (
                    !isNaN(Number(timeArray[0])) &&
                    !isNaN(Number(timeArray[1]))
                  ) {
                    const startTime = formatISO(
                      new Date(
                        Number(format(new Date(event.eventDate), 'y')),
                        Number(format(new Date(event.eventDate), 'MM')) - 1,
                        Number(format(new Date(event.eventDate), 'dd')),
                        meridium === 'AM'
                          ? Number(timeArray[0])
                          : Number(timeArray[0]) + 12,
                        Number(timeArray[1]),
                        0,
                      ),
                    );
                    setEvent({ ...event, startTime });
                  }
                }
              }}
              id="startTime"
            />
          </Column>
          <Column
            style={{
              width: uiStore.isMobile || uiStore.isTablet ? '100%' : '50%',
              marginTop: uiStore.isMobile ? '1rem' : 0,
            }}
          >
            <InputLabel htmlFor="endTime">
              {i18n('EventDetailsStepOne.endTime', 'End time')}
            </InputLabel>
            <GiglinkedTimeInput
              style={{ marginBottom: '1rem' }}
              value={event.endTime && format(new Date(event.endTime), 'hh:mma')}
              onChangeText={(time: string) => {
                if (event.eventDate) {
                  const meridium = time.slice(-2);
                  const timeArray = time.slice(0, -2).split(':');
                  if (
                    !isNaN(Number(timeArray[0])) &&
                    !isNaN(Number(timeArray[1]))
                  ) {
                    const endTime = formatISO(
                      new Date(
                        Number(format(new Date(event.eventDate), 'y')),
                        Number(format(new Date(event.eventDate), 'MM')) - 1,
                        Number(format(new Date(event.eventDate), 'dd')),
                        meridium === 'AM'
                          ? Number(timeArray[0])
                          : Number(timeArray[0]) + 12,
                        Number(timeArray[1]),
                        0,
                      ),
                    );
                    setEvent({ ...event, endTime });
                  }
                }
              }}
              id="endTime"
            />
          </Column>
        </Row>
        <InputLabel htmlFor="theme">
          {i18n('EventDetailsStepOne.theme', 'Theme')}
        </InputLabel>
        <GiglinkedTextInput
          style={{ width: '100%', marginBottom: '1rem' }}
          type="text"
          value={event.theme}
          onChangeText={(ev) => setEvent({ ...event, theme: ev })}
          id="theme"
        />
        <InputLabel htmlFor="isOutdoor">
          {i18n('EventDetailsStepOne.stageLocation', 'Stage location')}
        </InputLabel>
        <CheckStyleContainer>
          <Form>
            <Form.Check
              checked={event.isOutdoor}
              inline
              type="switch"
              id="isOutdoor"
              label={i18n('EventDetailsStepTwo.isOutdoor', 'Is it outdoor?')}
              onClick={() =>
                setEvent({
                  ...event,
                  isOutdoor: !event.isOutdoor,
                })
              }
            />
            {event.isOutdoor && (
              <Form.Check
                checked={event.isStageCovered}
                inline
                type="switch"
                id="isStageCovered"
                label={i18n(
                  'EventDetailsStepOne.stageCovered',
                  'Is the stage covered?',
                )}
                onClick={() =>
                  setEvent({
                    ...event,
                    isStageCovered: !event.isStageCovered,
                  })
                }
              />
            )}
          </Form>
        </CheckStyleContainer>
        <InputLabel htmlFor="estimatedAudience">
          {i18n('EventDetailsStepOne.audienceSize', 'Audience size')}
        </InputLabel>
        <GiEventsDropDown
          items={ESTIMATED_AUDIENCE()}
          value={event.estimatedAudience}
          onChange={(ev) => setEvent({ ...event, estimatedAudience: ev.value })}
          style={{
            width: '100%',
            marginBottom: '1rem',
          }}
          active
        />
        <InputLabel htmlFor="contactPhone">
          {i18n('EventDetailsStepTwo.contactPhone', 'Contact phone')}
        </InputLabel>
        <GiglinkedTextInput
          id="contactPhone"
          placeholder="(555)555-5555"
          mask="(999)999-9999"
          value={event.contactPhone}
          onChangeText={(ev) => setEvent({ ...event, contactPhone: ev })}
          style={{
            width: '100%',
          }}
        />
      </>
    );
  },
);

const CheckStyleContainer = styled.div`
  display: inline-flex;
  margin-bottom: 1rem;
  font-size: 16px;
  margin: 0 0.5rem 1rem;
  font-family: ${(props) => props.theme.fonts.GeneralSans};
`;

export default EventDetailsStepOne;
