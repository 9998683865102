export default {
  'EditActsOverviewTag.tag': { en: 'Tags', fr: 'Mots clés' },
  'EditActsOverviewTag.yourTagsAreHowOtherUsers': {
    en: 'Your tags are how other users will find you. We recommend using tags related to musical genres, instruments, and event types you want to be booked for!',
    fr: "Vos balises sont la façon dont les autres utilisateurs vous trouveront. Nous vous recommandons d'utiliser des balises liées aux genres musicaux, aux instruments et aux types d'événements pour lesquels vous souhaitez être réservé!",
  },
  'EditActsOverviewTag.searchKeywords': {
    en: 'Search Keywords',
    fr: 'Rechercher des mots-clés',
  },
};
