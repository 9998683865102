import { observer } from 'mobx-react';
import * as React from 'react';
import styled from 'styled-components';
import i18n from '../../../constants/i18n';
import { Tag } from '../../../shared';
import { useActStore } from '../../../stores/act';
import GiSeparator from '../../common/GiSeparator';
import GiTag from '../../common/GiTag';
import { TextH6 } from '../../common/Typography';
import EditTagInput from '../../common/EditTagInput';
import { addRightToolTip } from '../../common/ToolTip';
import toast from 'react-hot-toast';

const EditActsOverviewTag = observer(() => {
  const actStore = useActStore();
  const act = actStore.getCurrentEditAct();
  const [tags, setTags] = React.useState<Tag[]>(act!.tags);

  const onSave = async (payload: Partial<Tag>) => {
    try {
      // optimistic response:
      const index = tags.findIndex((t) => t.label === payload.label);
      const tempstags = [...tags];
      if (index !== -1) {
        tempstags.slice(index, 1);
      } else {
        tempstags.push(payload as Tag);
      }
      setTags(tempstags);
      // -----

      const updatedTags = await actStore.editTags({
        ...payload,
        actId: act!.id,
      });
      setTags(updatedTags);
    } catch (e) {
      const err = e as Error;
      toast.error(err.message);
    }
  };

  return (
    <Main>
      {addRightToolTip({
        leftNode: (
          <TextH6 style={{ marginBottom: 6 }}>
            {i18n('EditActsOverviewTag.searchKeywords', 'Search Keywords')}
          </TextH6>
        ),
        toolTip: i18n(
          'EditActsOverviewTag.yourTagsAreHowOtherUsers',
          'Your tags are how other users will find you. We recommend using tags related to musical genres, instruments, and event types you want to be booked for!',
        ),
      })}

      <TagContainer>
        {tags.slice().map((tag) => (
          <GiTag
            key={tag.id}
            tag={tag}
            editable
            style={{ marginTop: 5, marginRight: 5 }}
            onClickDelete={onSave}
          />
        ))}
      </TagContainer>

      <EditTagInput onSave={(label) => onSave({ label })} />

      <GiSeparator style={{ marginBottom: 24, marginTop: 24 }} />
    </Main>
  );
});

const TagContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 12px;
  @media (max-width: ${(props) => props.theme.breakPoints.mobile}px) {
  }
`;

const Main = styled.div`
  display: flex;
  flex-direction: column;
  @media (max-width: ${(props) => props.theme.breakPoints.mobile}px) {
  }
`;

export default EditActsOverviewTag;
