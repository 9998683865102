export default {
  'HowItWorksStepsSection.TopSectionTitle': {
    en: 'Make an offer to the perfect musicians for your event.',
    fr: 'Faites une offre à les musiciens parfaits pour votre événement.',
  },
  'HowItWorksStepsSection.TopSectionDescription': {
    en: 'Browse our talented musicians by keyword or location. GigLinked profiles let you know exactly who you are booking so you can make your event an unforgettable one.',
    fr: 'Parcourez nos musiciens talentueux par mot-clé ou par lieu. Les profils de GigLinked vous permettent de savoir exactement qui vous engagez afin de faire de votre événement un moment inoubliable.',
  },
  'HowItWorksStepsSection.SecondSectionTitle': {
    en: 'Fill out the Offer Sheet.',
    fr: 'Remplir la fiche d`offre.',
  },
  'HowItWorksStepsSection.SecondSectionDescription': {
    en: 'Transparency is paramount to a well organized event. Our Offer Sheets ensure you and your musicians have all the information needed for smooth sailing.',
    fr: "La transparence est essentielle à la bonne organisation d'un événement. Nos fiches d'offre vous permettent, ainsi qu'à vos musiciens, d’avoir toutes les informations nécessaires pour une organisation optimale.",
  },
  'HowItWorksStepsSection.ThirdSectionTitle': {
    en: 'Agree on a quote.',
    fr: "Se mettre d'accord sur un devis.",
  },
  'HowItWorksStepsSection.ThirdSectionDescription': {
    en: 'Once the musician receives your Offer Sheet, they will send you a quote. Still need to iron out minor details? Send them a private message to clear things up!',
    fr: "Une fois que le musicien aura reçu votre offre, elle vous enverra un devis. Vous avez encore des détails à régler ? Envoyez-lui un message privé pour clarifier les choses! Ce n'est qu'une fois que vous aurez approuvé le devis que vos informations de carte de crédit vous seront demandées. Vous souhaitez utiliser un autre mode de paiement ? Veuillez vous référer à la section Foire aux questions",
  },
};
