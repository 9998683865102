import { observer } from 'mobx-react';
import * as React from 'react';
import styled from 'styled-components';
import colors from '../../constants/colors';
import i18n from '../../constants/i18n';
import images from '../../constants/images';
import { TextH2, TextH4 } from '../common/Typography';

const HowItWorksStepsSection = observer(() => {
  return (
    <Main>
      <StyledContainer>
        <Box>
          <TextH2
            style={{
              color: `${colors.red}`,
            }}
          >
            1
          </TextH2>
          <StyledBox>
            <TextH2
              style={{
                paddingRight: '1rem',
                paddingBottom: '1rem',
                textAlign: 'justify',
              }}
            >
              {i18n(
                'HowItWorksStepsSection.TopSectionTitle',
                'Make an offer to the perfect musicians for your event.',
              )}
            </TextH2>
            <TextH4 style={{ color: colors.black, textAlign: 'justify' }}>
              {i18n(
                'HowItWorksStepsSection.TopSectionDescription',
                'Browse our talented musicians by keyword or location. GigLinked profiles let you know exactly who you are booking so you can make your event an unforgettable one.',
              )}
            </TextH4>
          </StyledBox>
        </Box>
        <Image src={images.howItWorksStepsFirstImage} />
      </StyledContainer>
      <Line />
      <StyledContainer>
        <Box>
          <TextH2 style={{ color: `${colors.red}` }}>2</TextH2>
          <StyledBox>
            <TextH2 style={{ paddingBottom: '1rem', textAlign: 'justify' }}>
              {i18n(
                'HowItWorksStepsSection.SecondSectionTitle',
                'Fill out the Offer Sheet.',
              )}
            </TextH2>
            <TextH4 style={{ color: colors.black, textAlign: 'justify' }}>
              {i18n('HowItWorksStepsSection.SecondSectionDescription', '')}
            </TextH4>
          </StyledBox>
        </Box>
        <Image src={images.howItWorksStepsSecondImage} />
      </StyledContainer>
      <Line />
      <StyledContainer>
        <Box>
          <TextH2 style={{ color: `${colors.red}` }}>3</TextH2>
          <StyledBox>
            <TextH2 style={{ paddingBottom: '1rem', textAlign: 'justify' }}>
              {i18n(
                'HowItWorksStepsSection.ThirdSectionTitle',
                'Agree on a quote.',
              )}
            </TextH2>
            <TextH4 style={{ color: colors.black, textAlign: 'justify' }}>
              {i18n(
                'HowItWorksStepsSection.ThirdSectionDescription',
                'Once the musician receives your Offer Sheet, they will send you a quote. Still need to iron out minor details? Send them a private message to clear things up!',
              )}
            </TextH4>
          </StyledBox>
        </Box>
        <Image src={images.howItWorksStepsThirdImage} />
      </StyledContainer>
    </Main>
  );
});

const Main = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  align-item: center;
  justify-content: center;
  background: ${colors.white};
  @media (max-width: ${(props) => props.theme.breakPoints.mobile}px) {
    flex-direction: row;
    flex-wrap: wrap;
`;

const StyledContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  padding: 0 48px;
  align-items: center;
  justify-content: center;
  &:first-child {
    height: 500px;
}
  @media (max-width: ${(props) => props.theme.breakPoints.tablet}px) {
    padding: 20px 48px;
    &:first-child {
      padding: 0 48px;
  }
  @media (max-width: ${(props) => props.theme.breakPoints.mobile}px) {
    &:first-child {
      padding: 15px 1rem;
  }
    padding: 15px 1rem;
    flex-direction: row;
    flex-wrap: wrap;
    height: 100%;
    width: 100%;
  }
`;
const Box = styled.div`
  width: 700px;
  display: flex;
  flex-direction: row;
  flex-wrap: no-wrap;
  margin: auto 20px;

  @media (max-width: ${(props) => props.theme.breakPoints.tablet}px) {
    width: 50%;
    align-item: flex-start;
  }
  @media (max-width: ${(props) => props.theme.breakPoints.mobile}px) {
    display: flex;
    align-item: center;
    flex-direction: row;
    width: 90%;
  }
`;

const StyledBox = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: center;
  padding-left: 2rem;
  max-width: 100%;
  @media (max-width: ${(props) => props.theme.breakPoints.tablet}px) {
    padding-left: 1rem;
  }
  @media (max-width: ${(props) => props.theme.breakPoints.mobile}px) {
    display: flex;
    align-item: center;
    text-align: justify;
  }
`;

const Image = styled.img`
  width: 500px;
  height: 600px;
  object-fit: contain;

  @media (max-width: ${(props) => props.theme.breakPoints.tablet}px) {
    width: 50%;
    height: 50%;
  }
  @media (max-width: ${(props) => props.theme.breakPoints.mobile}px) {
    padding-top: 20px;
    padding-bottom: 20px;
    width: 60%;
  }
`;

const Line = styled.div`
  border-bottom: 1px solid #00000033;
  width: 88%;
  margin: 0 auto;
`;
export default HowItWorksStepsSection;
