import * as React from 'react';
import { Box } from 'react-native-kondo';
import styled from 'styled-components';
import stripe from 'stripe-client';
import colors from '../../constants/colors';
import i18n from '../../constants/i18n';
import GiButton from '../common/GiButton';
import GiTextInput from '../common/GiTextInput';
import { GeneralRegularNormal, TextH2, TextH3 } from '../common/Typography';
import config from '../../config';
import { EditDataTransactionPayload } from './CompleteTransactionController';
import toast from 'react-hot-toast';

const stripeClient = stripe(config.stripePublishableKey);

const BookingPayment = ({
  onPressNext,
  editData,
}: {
  editData: (payload: Partial<EditDataTransactionPayload>) => void;
  onPressNext: ({
    creditCardData,
  }: {
    creditCardData: {
      card: {
        brand: string;
        country: string;
        cvc_check: string;
        exp_month: number;
        exp_year: number;
        funding: 'credit';
        id: string;
        last4: string;
        name: string;
        object: 'card';
        tokenization_method: null;
      };
      client_ip: string;
      created: string;
      id: string;
      livemode: boolean;
      object: 'token';
      type: 'card';
      used: false;
    };
  }) => void;
}) => {
  const [loading, setLoading] = React.useState(false);
  const [cardExpirationDate, setCardExpirationDate] = React.useState('');
  const [cardNumber, setCardNumber] = React.useState('');
  const [cardCVC, setCardCVC] = React.useState('');
  const [cardName, setCardName] = React.useState('');

  const validateAndGenerateStripeToken = async () => {
    setLoading(true);
    const exps = cardExpirationDate.split('/');
    const information = {
      card: {
        number: cardNumber.replaceAll(' ', ''),
        exp_month: exps[0],
        exp_year: exps[1],
        cvc: cardCVC,
        name: cardName,
      },
    };

    try {
      const data = await stripeClient
        .createToken(information)
        .then((j: any) => j.json());

      if (data.error) {
        throw data.error.code;
      }

      editData({
        stripeCardToken: data.id as string,
      });
      onPressNext({ creditCardData: data });
    } catch (e) {
      let errorMsg = '';

      if (e === 'invalid_expiry_year') {
        errorMsg = i18n(
          'PaymentPref.errorExpiryYear',
          "Your card's expiration year is invalid.",
        );
      } else if (e === 'invalid_expiry_month') {
        errorMsg = i18n(
          'PaymentPref.errorExpiryMonth',
          "Your card's expiration month is invalid.",
        );
      } else if (e === 'invalid_cvc') {
        errorMsg = i18n(
          'PaymentPref.errorCVC',
          "Your card's security code is invalid.",
        );
      } else if (e === 'incorrect_number') {
        errorMsg = i18n(
          'PaymentPref.errorCardNumber',
          'Your card number is incorrect.',
        );
      } else {
        errorMsg = i18n('PaymentPref.errorGeneral', 'Invalid credit card.');
      }
      setLoading(false);
      toast.error(errorMsg);
    }
  };

  return (
    <Main>
      <TextH2
        style={{
          color: colors.whiteText,
          marginBottom: 36,
          alignSelf: 'center',
          textAlign: 'center',
        }}
      >
        {i18n('BookingPayment.paymentpref', 'Payment preferences')}
      </TextH2>

      <TextH3 style={{ color: colors.whiteText, marginBottom: 24 }}>
        {i18n('BookingPayment.creditCard', 'Credit Card Informations')}
      </TextH3>

      <GeneralRegularNormal
        style={{
          color: colors.whiteText,
          marginBottom: 14,
        }}
      >
        {i18n('BookingPayment.cardNumber', 'Card number')}
      </GeneralRegularNormal>
      <GiTextInput
        placeholder="0000 0000 0000 0000"
        style={{ marginBottom: 20 }}
        value={cardNumber}
        onChangeText={setCardNumber}
        mask="9999 9999 9999 9999"
      />
      <GeneralRegularNormal
        style={{
          color: colors.whiteText,
          marginBottom: 14,
        }}
      >
        {i18n('BookingPayment.nameOnTheCard', 'Name on the card')}
      </GeneralRegularNormal>
      <GiTextInput
        placeholder={i18n(
          'BookingPayment.nameOnTheCardPlaceholder',
          'Enter your name',
        )}
        style={{ marginBottom: 20 }}
        value={cardName}
        onChangeText={setCardName}
      />

      <Box flexDirection="row">
        <Box flex={1}>
          <GeneralRegularNormal
            style={{
              color: colors.whiteText,
              marginBottom: 14,
            }}
          >
            {i18n('BookingPayment.EXP', 'EXP')}
          </GeneralRegularNormal>
          <GiTextInput
            placeholder={i18n('BookingPayment.expPlaceholder', 'MM/YY')}
            value={cardExpirationDate}
            onChangeText={setCardExpirationDate}
            mask="99/99"
          />
        </Box>
        <Box width={20} />
        <Box flex={1}>
          <GeneralRegularNormal
            style={{
              color: colors.whiteText,
              marginBottom: 14,
            }}
          >
            {i18n('BookingPayment.CVC', 'CVC')}
          </GeneralRegularNormal>
          <GiTextInput
            placeholder={'000'}
            mask="999"
            value={cardCVC}
            onChangeText={setCardCVC}
          />
        </Box>
      </Box>

      <GiButton
        label={i18n('BookingPayment.next', 'Next')}
        style={{ alignSelf: 'center', marginTop: 48 }}
        disabled={!cardName || !cardNumber || !cardExpirationDate || !cardCVC}
        onClick={validateAndGenerateStripeToken}
        loading={loading}
      />
    </Main>
  );
};

const Main = styled.div`
  display: flex;
  box-sizing: border-box;
  flex-direction: column;
  align-self: center;
  padding-bottom: 63px;
  @media (max-width: ${(props) => props.theme.breakPoints.mobile}px) {
    padding: 0px 20px 40px 20px;
  }
`;

export default BookingPayment;
