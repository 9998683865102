import * as React from 'react';
import styled from 'styled-components';
import { observer } from 'mobx-react';
import { GeneralRegularNormal, TextH3 } from './Typography';
import images from '../../constants/images';
import colors from '../../constants/colors';

const SuccessModal = observer(
  ({
    title,
    secondaryText,
    navigate,
  }: {
    title: string;
    secondaryText?: string;
    navigate: React.ReactNode;
  }) => {
    return (
      <Main>
        <img src={images.success} />
        <div style={{ maxWidth: '300px' }}>
          <TextH3 style={{ color: colors.black }}>{title}</TextH3>
          <GeneralRegularNormal style={{ fontSize: '14px' }}>
            {secondaryText}
          </GeneralRegularNormal>
        </div>
        {navigate}
      </Main>
    );
  },
);

const Main = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 2rem 4rem;
  max-width: 627px;
  height: 338px;
  border-radius: 10px;
  background-color: white;
  align-items: center;
  text-align: center;
  border: 1px solid #a59cee;
  @media (max-width: ${(props) => props.theme.breakPoints.mobile}px) {
    max-width: 600px;
  }
`;

export default SuccessModal;
