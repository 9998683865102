import * as React from 'react';
import styled from 'styled-components';
import { IoMdAddCircle } from 'react-icons/io';
import { GeneralRegularNormal } from '../Typography';
import { LeftRow } from './ProfilePictureSection';
import i18n from '../../../constants/i18n';
import { Box } from 'react-native-kondo';
import ATag from '../ATag';
import { observer } from 'mobx-react';
import { useUiStore } from '../../../stores/ui';
import { isEntityOwner } from './profileUtils';
import { Entity, EntityType } from '../../../shared';
import { replaceProfileImage } from '../../../utils/media';

const ProfileActsOrMemberSection = observer(
  ({ entity, entityType }: { entity: Entity; entityType: EntityType }) => {
    const uiStore = useUiStore();
    let text = i18n('ProfileActsOrMemberSection.associated', 'Associated Acts');

    if (entityType === 'act') {
      text = i18n('ProfileActsOrMemberSection.members', 'Members');
    }

    let data: any[] = [];

    const isOwner = isEntityOwner({ entity, entityType });

    // @ts-ignore
    if (
      !entity ||
      // @ts-ignore
      (!entity.acts?.length && !entity.members?.length && !isOwner)
    ) {
      return null;
    }
    // @ts-ignore
    data = entity.acts || entity.members || [];

    const href = `/me/artist/acts`;

    return (
      <Main>
        <LeftRow>
          <GeneralRegularNormal
            style={{ color: '#707070', marginTop: uiStore.isMobile ? 0 : -20 }}
          >
            {text}
          </GeneralRegularNormal>
        </LeftRow>

        <Box
          style={{
            flexWrap: 'wrap',
            flexDirection: 'row',
            flex: 1,
          }}
        >
          {data.map((entity) => (
            <ActCell entity={entity} key={entity.id} entityType={entityType} />
          ))}
          {isOwner && entityType === 'artist' ? (
            <ATag href={href}>
              <Box flexDirection="row" alignItems="center" mr={20} mb={20}>
                <IoMdAddCircle size={58} color="#4361EE" />
                <GeneralRegularNormal
                  style={{
                    textDecoration: 'underline',
                    color: '#4361EE',
                    marginLeft: 6,
                  }}
                >
                  {i18n('ProfileActsOrMemberSection.create Act', 'Create Act')}
                </GeneralRegularNormal>
              </Box>
            </ATag>
          ) : undefined}
        </Box>
      </Main>
    );
  },
);

const ActCell = ({
  entity,
  entityType,
}: {
  entity: Entity;
  entityType: EntityType;
}) => {
  const url =
    entityType === 'artist' ? `/acts/${entity.id}` : `/artists/${entity.id}`;

  return (
    <ATag href={url}>
      <ActContainer>
        <ActPicture src={replaceProfileImage(entity.profilePicture)} />
        <GeneralRegularNormal
          style={{ textDecoration: 'underline', wordBreak: 'break-all' }}
        >
          {entity.name}
        </GeneralRegularNormal>
      </ActContainer>
    </ATag>
  );
};

const ActPicture = styled.img`
  width: 58px;
  height: 58px;
  border-radius: 30px;
  border: 2px solid #f2f3f4;
  margin-right: 12px;
  object-fit: cover;
  @media (max-width: ${(props) => props.theme.breakPoints.mobile}px) {
  }
`;

const ActContainer = styled.div`
  display: flex;
  align-items: center;
  margin-right: 20px;
  margin-bottom: 20px;
  min-width: 182px;
  @media (max-width: ${(props) => props.theme.breakPoints.mobile}px) {
    min-width: 182px;
  }
`;

const Main = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 30px;
  @media (max-width: ${(props) => props.theme.breakPoints.mobile}px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

export default ProfileActsOrMemberSection;
