import * as React from 'react';
import styled from 'styled-components';

import { GeneralSemiBoldNormal } from './Typography';

const GiButtonLanding = ({
  label,
  style,
  inverted,
  onClick,
  disabled,
}: {
  label: string;
  style?: React.CSSProperties;
  inverted?: boolean;
  disabled?: boolean;
  onClick?: () => void;
}) => {
  return (
    <Container
      style={style}
      inverted={inverted}
      onClick={disabled ? undefined : onClick}
      disabled={disabled}
      tabIndex={0}
    >
      <GeneralSemiBoldNormal style={{ textAlign: 'center' }}>
        {label}
      </GeneralSemiBoldNormal>
    </Container>
  );
};

const Container = styled.div<{
  inverted?: boolean;
  disabled?: boolean;
}>`
  width: 200px;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${(props) =>
    props.inverted ? 'white' : props.theme.colors.red};
  border-radius: 6px;
  cursor: ${(props) => (props.disabled ? undefined : 'pointer')};
  background-color: ${(props) =>
    props.disabled ? 'rgba(67, 97, 238,0.3)' : undefined};
  transition: opacity 0.2s ease;
  border: 2px solid
    ${(props) =>
      props.disabled ? 'rgba(67, 97, 238,0.3)' : props.theme.colors.red};
  span {
    color: ${(props) =>
      props.inverted
        ? props.theme.colors.red
        : props.disabled
        ? 'rgba(255,255,255,0.3)'
        : props.theme.colors.white};
  }
  &:hover {
    filter: ${(props) => (props.disabled ? undefined : 'brightness(115%)')};
    background-color: ${(props) =>
      props.disabled ? undefined : props.theme.colors.red};
    span {
      color: ${(props) =>
        props.disabled ? 'rgba(255,255,255,0.3)' : props.theme.colors.white};
    }
  }
`;

export default GiButtonLanding;
