import * as React from 'react';
import styled from 'styled-components';
import GiEventsDropDown from './GiEventsDropDown';

const GiglinkedTimeInput = ({
  style,
  value,
  onChangeText = (str: string) => str,
  disabled,
}: React.InputHTMLAttributes<any> & {
  style?: React.CSSProperties;
  value?: any;
  disabled?: boolean;
  onChangeText?: (str: string) => void;
}) => {
  const [hour, setHour] = React.useState(String(value)?.split(':')[0]);
  const [minute, setMinute] = React.useState(
    value
      ? String(value)
          ?.substring(0, String(value).length - 2)
          .split(':')[1]
      : '00',
  );
  const [meridium, setMeridium] = React.useState(String(value)?.slice(-2));

  const HOURS = Array.from({ length: 12 }, (_, index) => {
    const hour = (index + 1).toString().padStart(2, '0');
    return {
      label: hour,
      value: hour,
    };
  });
  const MINUTES = Array.from({ length: 4 }, (_, index) => {
    const value = index * 15;
    return {
      label: value.toString().padStart(2, '0'),
      value: value.toString().padStart(2, '0'),
    };
  });

  return (
    <Main style={style}>
      <GiEventsDropDown
        disabled={disabled}
        items={HOURS}
        value={hour}
        onChange={(ev) => {
          setHour(ev.value);
          onChangeText(`${ev.value}:${minute}${meridium}`);
        }}
        style={{
          width: '35%',
        }}
        active
      />
      <span style={{ alignSelf: 'center', padding: '0 0.5rem' }}>:</span>
      <GiEventsDropDown
        disabled={disabled}
        items={MINUTES}
        value={minute}
        onChange={(ev) => {
          setMinute(ev.value);
          onChangeText(`${hour}:${ev.value}${meridium}`);
        }}
        style={{
          width: '35%',
        }}
        active
      />
      <AmPmButton
        style={{
          borderRadius: '6px 0 0 6px',
          marginLeft: '1rem',
        }}
        selected={meridium === 'AM'}
        value={meridium}
        onClick={() => {
          setMeridium('AM');
          onChangeText(`${hour}:${minute}AM`);
        }}
      >
        AM
      </AmPmButton>
      <AmPmButton
        style={{
          borderRadius: '0 6px 6px 0',
        }}
        selected={meridium === 'PM'}
        onClick={() => {
          setMeridium('PM');
          onChangeText(`${hour}:${minute}PM`);
        }}
      >
        PM
      </AmPmButton>
    </Main>
  );
};
const Main = styled.div`
  display: flex;
  position: relative;
  background-color: ${(props) => props.theme.colors.white};
  border-radius: 10px;
`;

const AmPmButton = styled.button<{ selected: boolean }>`
  width: 32px;
  border: 1px solid ${(props) => props.theme.colors.buttonBorder};
  font-family: General Sans;
  font-size: 12px;
  font-weight: 500;
  line-height: 14px;
  letter-spacing: 0em;
  text-align: center;
  background: ${(props) =>
    props.selected
      ? props.theme.colors.buttonBorder
      : props.theme.colors.white};
  color: ${(props) =>
    props.selected ? props.theme.colors.white : props.theme.colors.black};
`;

export default GiglinkedTimeInput;
