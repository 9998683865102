export default {
  'EditArtistProfileSongs.addSpotify': {
    en: 'Add a Spotify song',
    fr: 'Ajouter une chanson Spotify',
  },
  'EditArtistProfileSongs.copyAndPasteShareLink': {
    en: 'Copy and paste share link from Spotify, the more the better! This can be original music, or music in your repertoire!',
    fr: "Copiez et collez le lien partageant de Spotify, plus il y a, mieux c'est! Cela peut être de la musique originale ou de la musique dans votre répertoire!",
  },
  'EditArtistProfileSongs.delete': { en: 'Remove', fr: 'Éliminer' },
};
