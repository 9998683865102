import * as React from 'react';
import styled from 'styled-components';
import { fonts, GeneralRegularNormal } from '../Typography';
import { LeftRow } from './ProfilePictureSection';
import i18n from '../../../constants/i18n';
import { Box } from 'react-native-kondo';
import { shorten } from '../../../utils/string';
import { Text } from 'react-native';
import { colors } from '../../../constants/colors';
import { Entity } from '../../../shared';

const ProfileBioSection = ({ entity }: { entity: Entity }) => {
  const [isExpended, setIsExpended] = React.useState(false);

  if (!entity.bio) {
    return null;
  }

  return (
    <Main>
      <LeftRow>
        <GeneralRegularNormal style={{ color: '#707070' }}>
          {i18n('ArtistBioSection.about', 'About')}
        </GeneralRegularNormal>
      </LeftRow>

      <Box flex={1}>
        <Box style={{ flexDirection: 'row', flex: 1 }}>
          <Text
            style={{
              fontSize: 16,
              fontFamily: fonts.GeneralSans,
              fontWeight: '400',
              color: colors.black,
            }}
          >
            {isExpended ? entity.bio : shorten(entity.bio, 144)}
          </Text>
        </Box>
        {isExpended ? (
          <GeneralRegularNormal
            onClick={() => setIsExpended(false)}
            style={{
              color: '#777777',
              textDecoration: 'underline',
              marginTop: 3,
              cursor: 'pointer',
            }}
          >
            {i18n('ArtistBioSection.viewless', 'View less')}
          </GeneralRegularNormal>
        ) : entity.bio.length > 144 ? (
          <GeneralRegularNormal
            onClick={() => setIsExpended(true)}
            style={{
              color: '#777777',
              textDecoration: 'underline',
              marginTop: 3,
              cursor: 'pointer',
            }}
          >
            {i18n('ArtistBioSection.continue', 'Continue reading.')}
          </GeneralRegularNormal>
        ) : null}
      </Box>
    </Main>
  );
};

const Main = styled.div`
  display: flex;
  margin-bottom: 25px;
  @media (max-width: ${(props) => props.theme.breakPoints.mobile}px) {
    flex-direction: column;
  }
`;

// Special case as we wanted to respect the text format.
// We use a <pre> instead a <span>
export const BioGeneralRegularNormal = styled.div`
  font-size: 16px;
  font-family: ${(props) => props.theme.fonts.GeneralSans};
  font-weight: 400;
  color: ${(props) => props.theme.colors.black};
`;

export default ProfileBioSection;
