import { format } from 'date-fns';
import { enCA, frCA } from 'date-fns/locale';
import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { Box } from 'react-native-kondo';
import colors from '../../constants/colors';
import i18n from '../../constants/i18n';
import { ActionType, MonthIndex, Transaction } from '../../shared';
import { UseUserStore } from '../../stores/user';
import { isSameCalendarDay } from '../../utils/dateUtils';
import sexpressClient from '../../utils/sexpress';
import { TextH3, TextH4 } from '../common/Typography';
import RequestCalendar from './RequestCalendar';
import RequestCell from './RequestCell';
import toast from 'react-hot-toast';

const CalendarScreen = observer(() => {
  const userStore = UseUserStore();
  const [loading, setLoading] = React.useState(true);

  const [transactions, setTransactions] = React.useState<Transaction[]>([]);
  const [month, setMonth] = React.useState<MonthIndex>(
    (new Date().getMonth() + 1) as any,
  );
  const [year, setYear] = React.useState<number>(
    new Date().getFullYear() as any,
  );
  const [selectedDate, setSelectedDate] = React.useState<Date>();
  const [displayedTransactions, setDisplayedTransactions] = React.useState<
    Transaction[]
  >([]);

  const fetchTransactions = React.useCallback(async () => {
    try {
      setLoading(true);
      let resTransactions: Transaction[] = [];
      let resTotalPages = 1;

      setTransactions([]);

      const promiseRes = await Promise.all([
        sexpressClient.getTransactions({
          status: 'quotePending',
          month,
        }),
        sexpressClient.getTransactions({
          status: 'quoteSent',
          month,
        }),
        sexpressClient.getTransactions({
          status: 'accepted',
          month,
        }),
        sexpressClient.getTransactions({
          status: 'cancelled',
          month,
        }),
        sexpressClient.getTransactions({
          status: 'declined',
          month,
        }),
        sexpressClient.getTransactions({
          status: 'expired',
          month,
        }),
        sexpressClient.getTransactions({
          status: 'refunded',
          month,
        }),
        sexpressClient.getTransactions({
          status: 'completed',
          month,
        }),
      ]);
      promiseRes.forEach((res) => {
        if (res.totalPages > resTotalPages) {
          resTotalPages = res.totalPages;
        }
        resTransactions = [
          ...resTransactions,
          ...res.userTransactions,
          ...res.artistTransactions,
          ...res.actTransactions,
        ];
      });

      resTransactions = resTransactions.sort(
        (a: Transaction, b: Transaction) =>
          new Date(a.eventDate).getTime() - new Date(b.eventDate).getTime(),
      );

      setTransactions(resTransactions);
    } catch (e) {
      const err = e as Error;
      toast.error(err.message);
    } finally {
      setLoading(false);
    }
  }, [month, year]);

  React.useEffect(() => {
    fetchTransactions();
  }, [month, year]);

  const onAction = async ({
    transaction,
    action,
  }: {
    transaction: Transaction;
    action: ActionType;
  }) => {
    try {
      setTransactions([]);

      await sexpressClient.updateTransactionState({
        action,
        transactionId: transaction.id,
      });
      fetchTransactions();
    } catch (e) {
      const err = e as Error;
      toast.error(err.message);
    }
  };

  return (
    <Box style={{ opacity: loading ? 0.6 : 1 }}>
      <RequestCalendar
        transactions={transactions}
        onChangeSelectedMonth={(newMonth, newYear) => {
          setMonth(newMonth);
          setYear(newYear);
        }}
        canSelectedEveryDate
        onChangeSelectedDates={([date]) => {
          const relatedTransactions = transactions.filter((transaction) =>
            isSameCalendarDay(date, new Date(transaction.eventDate)),
          );
          setDisplayedTransactions(relatedTransactions);
          setSelectedDate(date);
        }}
      />
      {selectedDate && (
        <Box my={24}>
          <TextH3 style={{ color: 'black', marginBottom: 24 }}>
            {format(new Date(selectedDate), 'PPPP', {
              locale: userStore?.browserLanguage === 'fr' ? frCA : enCA,
            })}
          </TextH3>
          {displayedTransactions.length ? (
            displayedTransactions.map((transaction, index) => (
              <RequestCell
                key={`${transaction.id}-${index}`}
                transaction={transaction}
                onAction={onAction}
              />
            ))
          ) : (
            <TextH4 style={{ color: colors.black }}>
              {i18n(
                'CalendarScreen.noEvent',
                'No scheduled event for this date.',
              )}
            </TextH4>
          )}
        </Box>
      )}
    </Box>
  );
});

export default CalendarScreen;
