export default {
  'EditArtistProfileVideos.addYoutube': {
    en: 'Add a YouTube video',
    fr: 'Ajouter une vidéo YouTube',
  },
  'EditArtistProfileVideos.copyAndPasteShareLink': {
    en: 'Copy and paste share link from Youtube, the more the better! This can be original music, or music in your repertoire!',
    fr: "Copiez et collez le lien partageant de YouTube, plus vous pourriez, mieux c'est! Cela peut être de la musique originale ou de la musique dans votre répertoire!",
  },
  'EditArtistProfileVideos.addVideo': {
    en: 'Add Video',
    fr: 'Ajouter une vidéo',
  },
};
