import * as React from 'react';
import { observer } from 'mobx-react';
import styled from 'styled-components';
import colors from '../../constants/colors';
import { useBookingStore } from '../../stores/booking';
import { useUiStore } from '../../stores/ui';
import BookingAbout from './BookingAbout';
import BookingDateSelection from './BookingDateSelection';
import BookingEventSelction from './BookingEventSelection';
import BookingFinalMessage from './BookingFinalMessage';
import BookingLocationSelection from './BookingLocationSelection';
import BookingProgressBar from './BookingProgressBar';
import BookingAudience from './BookingAudience';
import BookingContactInfo from './BookingContactInfo';
import BookingSummary from './BookingSummary';

const BookingController = observer(() => {
  const bookingStore = useBookingStore();
  const [index, setIndex] = React.useState(0);
  const uiStore = useUiStore();
  const width = uiStore.isMobile
    ? uiStore.innerWidth
    : uiStore.innerWidth * 0.61;

  const onPressNext = () => {
    setIndex(index + 1);
  };

  const onPressBack = () => {
    if (index >= 1) {
      setIndex(index - 1);
    }
  };

  React.useEffect(() => {
    return bookingStore.resetBookingData;
  }, []);

  const renderContent = () => {
    return [
      <BookingEventSelction
        key="BookingEventSelction"
        onPressNext={onPressNext}
      />,
      <BookingDateSelection
        key="BookingDateSelection"
        onPressNext={onPressNext}
      />,
      <BookingLocationSelection
        key={'BookingLocationSelection'}
        onPressNext={onPressNext}
      />,
      <BookingAudience key={'BookingAudience'} onPressNext={onPressNext} />,
      <BookingAbout key={'BookingAbout'} onPressNext={onPressNext} />,
      <BookingContactInfo
        key={'BookingContactInfo'}
        onPressNext={onPressNext}
      />,
      <BookingSummary key={'BookingSummary'} onPressNext={onPressNext} />,
      <BookingFinalMessage key={'BookingFinalMessage'} />,
    ][index];
  };

  return (
    <Main width={width} height={uiStore.innerHeight}>
      <BookingProgressBar
        currentIndex={index}
        numberOfScreens={7}
        onPressBack={index === 0 || index === 7 ? undefined : onPressBack}
      />
      <ContentContainer>{renderContent()}</ContentContainer>
    </Main>
  );
});

const Main = styled.div<{ width: number; height: number }>`
  display: flex;
  flex-direction: column;
  width: ${(props) => props.width}px;
  align-items: center;
  border-radius: 5px;
  background-color: ${colors.lightPurple};
  @media (max-width: ${(props) => props.theme.breakPoints.mobile}px) {
    min-height: ${(props) => props.height}px;
  }
`;

const ContentContainer = styled.div`
  width: 100%;
  max-width: 600px;
  box-sizing: border-box;

  @media (max-width: ${(props) => props.theme.breakPoints.mobile}px) {
    padding-bottom: 20px;
  }
`;

export default BookingController;
