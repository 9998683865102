export default {
  'StepperRegistrationPlayStyles.dj': { en: 'DJ', fr: 'DJ' },
  'StepperRegistrationPlayStyles.rock': { en: 'Rock', fr: 'Rock' },
  'StepperRegistrationPlayStyles.classical': {
    en: 'Classical',
    fr: 'Classique',
  },
  'StepperRegistrationPlayStyles.jazz': { en: 'Jazz', fr: 'Jazz' },
  'StepperRegistrationPlayStyles.lounge': { en: 'Lounge', fr: 'Lounge' },
  'StepperRegistrationPlayStyles.originalCompositions': {
    en: 'Original compositions',
    fr: 'Compositions originales',
  },
  'StepperRegistrationPlayStyles.worldMusic': {
    en: 'World Music',
    fr: 'Musique du monde',
  },
  'StepperRegistrationPlayStyles.pop': { en: 'Pop', fr: 'Pop' },
  'StepperRegistrationPlayStyles.dance': { en: 'Dance', fr: 'Dance' },
  'StepperRegistrationPlayStyles.electronic': {
    en: 'Electronic',
    fr: 'Electronique',
  },
  'StepperRegistrationPlayStyles.hipHop': { en: 'Hip Hop', fr: 'Hip Hop' },
  'StepperRegistrationPlayStyles.country': { en: 'Country', fr: 'Country' },
  'StepperRegistrationPlayStyles.traditional': {
    en: 'Traditional',
    fr: 'Traditionelle',
  },
  'StepperRegistrationPlayStyles.title': {
    en: 'Select the genres of music you play:',
    fr: 'Sélectionnez les genres de musique que vous jouez:',
  },
  'StepperRegistrationPlayStyles.description': {
    en: 'You can add more later',
    fr: 'Vous pouvez en ajouter plus tard',
  },
  'StepperRegistrationPlayStyles.other': { en: 'Other', fr: 'Autre' },
};
