import * as React from 'react';
import { Box } from 'react-native-kondo';
import { ScrollView } from 'react-native';

import MessageCell from './MessageCell';
import { Conversation } from '../../shared';
import socket from '../../utils/socket';
import { observer } from 'mobx-react';
import { MessagingStore, useMessagingStore } from '../../stores/messaging';
import MessageComposer from './MessageComposer';
import MessageScreenHeader from './MessageScreenHeader';
import { useUiStore } from '../../stores/ui';
import sexpressClient from '../../utils/sexpress';

const handleNewMessages = (
  conversation: Conversation,
  messagingStore: MessagingStore,
  setConversation: any,
  scrollToEnd?: any,
) => {
  const currentConversation = messagingStore.getCurrentConversation();

  setConversation({
    ...conversation,
    messages: conversation.messages,
  });

  if (scrollToEnd) {
    scrollToEnd();
  }

  if (!currentConversation || !currentConversation.id) {
    messagingStore.setCurrentConversation(conversation);
  }
};

const MessageScreen = observer(() => {
  const scrollViewRef = React.useRef<ScrollView>();
  const messagingStore = useMessagingStore();
  const uiStore = useUiStore();

  const [conversation, setConversation] = React.useState<Conversation | null>(
    messagingStore.getCurrentConversation(),
  );

  React.useEffect(() => {
    const initSocket = () => {
      socket.connect(() => {
        socket.getData('message', (payload: any) => {
          handleNewMessages(
            payload,
            messagingStore,
            setConversation,
            scrollViewRef.current?.scrollToEnd,
          );
        });
        setTimeout(requestUpdate, 150);
      });
    };
    initSocket();
    return socket.disconnect;
  }, []);

  const requestUpdate = () => {
    const currentConversation = messagingStore.getCurrentConversation();

    if (currentConversation && currentConversation.id) {
      socket.sendData('messageUpdate', {
        conversationId: currentConversation.id,
        limit: 1000,
      });
    }
  };

  const onPressSend = (text: string) => {
    const currentConversation = messagingStore.getCurrentConversation();
    const currentOtherUser = messagingStore.getCurrentOtherUser();

    const msg = text;
    const payload: any = {
      text: msg,
      otherUserId: currentOtherUser!.id,
    };

    if (currentConversation && currentConversation.id) {
      payload.conversationId = currentConversation.id;
    }
    sexpressClient.userSendMessage(payload);
    socket.sendData('message', payload);
  };

  return (
    <Box flex={1} justifyContent="space-between">
      <MessageScreenHeader />

      <ScrollView
        // @ts-ignore
        ref={scrollViewRef}
        style={{ backgroundColor: 'white', flex: 1 }}
        contentContainerStyle={{
          paddingLeft: uiStore.isMobile ? 15 : 70,
          paddingRight: uiStore.isMobile ? 15 : 50,
          paddingVertical: 25,
        }}
      >
        {[...conversation!.messages].reverse().map((item) => (
          <MessageCell key={item.id} message={item} />
        ))}
      </ScrollView>

      <MessageComposer onPressSend={onPressSend} />
    </Box>
  );
});

export default MessageScreen;
