import * as React from 'react';
import i18n from '../../../constants/i18n';
import { Transaction } from '../../../shared';
import { useUiStore } from '../../../stores/ui';
import GiButton from '../../common/GiButton';
import useGetRequestDetailsQuoteFormattedData from './useGetRequestDetailsQuoteFormattedData';
import DeclinePopup from './DeclinePopup';

const SecondButton = ({
  request,
  fetchTransaction,
}: {
  request: Transaction;
  fetchTransaction: () => void;
}) => {
  const uiStore = useUiStore();
  const isMobilePhone = uiStore.innerWidth <= 375;
  const { status } = request;

  const { meIsAct, meIsArtist, meIsBooker } =
    useGetRequestDetailsQuoteFormattedData(request);

  // TODO: Add all combinations for the list of ( (Entity|Booker) ⊗ TransactionStatus)
  const hideButton =
    ['expired', 'declined', 'refunded', 'cancelled', 'completed'].includes(
      status,
    ) ||
    (meIsBooker && ['quotePending', 'quoteDeclined'].includes(status));

  if (hideButton) {
    return null;
  }

  const getButtonContent = () => {
    if (
      ((meIsAct || meIsArtist) &&
        ['quotePending', 'quoteSent', 'quoteDeclined'].includes(status)) ||
      (meIsBooker && ['quoteSent'].includes(status))
    )
      return {
        label: i18n('RequestDetailsQuoteSection.decline', 'Decline'),
        onClick: () => {
          uiStore.openPopup({
            popupDisableTapOut: true,
            renderContent: () => (
              <DeclinePopup
                request={request}
                fetchTransaction={fetchTransaction}
              />
            ),
          });
        },
      };

    return {
      label: 'Default',
      loading: false,
      onClick: () => {
        // TODO: Maybe add a default if needed
      },
    };
  };

  const { label, loading, onClick } = getButtonContent();

  return (
    <GiButton
      label={label}
      style={{
        height: 26,
        width: isMobilePhone ? 116 : 136,
        marginBottom: 22,
      }}
      onClick={onClick}
      loading={loading}
      // disabled={}
    />
  );
};

export default SecondButton;
