const blogUrl = 'https://blog.giglinked.live/';
const eventsUrl = 'https://blog.giglinked.live/foryourevents/';
const musiciansUrl = 'https://blog.giglinked.live/formusicians/';
const aboutUrl = 'https://blog.giglinked.live/about/';
const contactUrl = 'https://blog.giglinked.live/contact/';
const betaUrl =
  ' https://docs.google.com/forms/d/e/1FAIpQLSfpLNQZ22-PTHKrBM0malqpW8DtdbCayLbRhCh_10qdmXBbkg/viewform';
const demoMusician = 'https://meetings.hubspot.com/giglinked/demo-music';
const demoRequestBooking =
  'https://meetings.hubspot.com/giglinked/demo-request-booking';
const facebook = 'https://www.facebook.com/GigLinked/';
const instagram = 'https://www.instagram.com/giglinked/';
const twitter = 'https://twitter.com/giglinked';
const linkedin = 'https://www.linkedin.com/company/giglinked';
const phoneNumber = '514-758-1990';
const email = 'info@giglinked.live';

export default {
  blogUrl,
  eventsUrl,
  musiciansUrl,
  aboutUrl,
  betaUrl,
  facebook,
  instagram,
  twitter,
  linkedin,
  contactUrl,
  phoneNumber,
  email,
  demoMusician,
  demoRequestBooking,
};
