export default {
  'BookingLocationSelection.title': {
    en: 'Where is your event?',
    fr: 'Où est votre événement?',
  },
  'BookingLocationSelection.venueName': {
    en: 'Venue Name',
    fr: 'nom de la place',
  },
  'BookingLocationSelection.address': { en: 'Address *', fr: 'Adresse *' },
  'BookingLocationSelection.city': { en: 'City *', fr: 'Ville *' },
  'BookingLocationSelection.postalCode': {
    en: 'Postal Code *',
    fr: 'Code postal *',
  },
  'BookingLocationSelection.province': { en: 'Province *', fr: 'Province *' },
  'BookingLocationSelection.country': { en: 'Country *', fr: 'Pays *' },
  'BookingLocationSelection.indoorOrOutdoor': {
    en: 'Is your event indoor or outdoor? *',
    fr: 'Votre événement est-il intérieur ou extérieur? *',
  },
  'BookingLocationSelection.indoor': { en: 'Indoor', fr: 'Intérieure' },
  'BookingLocationSelection.outdoor': { en: 'Outdoor', fr: 'Extérieure' },
  'BookingLocationSelection.covered': {
    en: 'Will the stage be covered? *',
    fr: 'La scène sera-t-elle couverte? *',
  },
  'BookingLocationSelection.whyIsThisImportant': {
    en: 'Why is this important? Many instruments are sensitive to weather conditions, including rain, excessive heat, and cold. Having a covered outdoor area, although not always necessary, can greatly reduce the risk of damage to instruments and poor sound quality.',
    fr: 'Pourquoi est-ce important? De nombreux instruments sont sensibles aux conditions météorologiques, notamment la pluie, la chaleur excessive et le froid. Avoir une zone extérieure couverte, bien que pas toujours nécessaire, peut réduire considérablement le risque de dommages aux instruments et une mauvaise qualité sonore.',
  },
  'BookingLocationSelection.yes': { en: 'Yes', fr: 'Oui' },
  'BookingLocationSelection.no': { en: 'No', fr: 'Non' },
  'BookingLocationSelection.next': { en: 'Next', fr: 'Suivante' },
};
