import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { useParams, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import metrics from '../../constants/metrics';
import { Act } from '../../shared';
import { useUiStore } from '../../stores/ui';
import sexpressClient from '../../utils/sexpress';
import ProfileActsOrMemberSection from '../common/profile/ProfileActsOrMemberSection';
import ProfileBannerSection from '../common/profile/ProfileBannerSection';
import ProfileBioSection from '../common/profile/ProfileBioSection';
import ProfileGallerySection from '../common/profile/ProfileGallerySection';
import PageLayout from '../common/profile/ProfilePageLayout';
import ProfilePictureSection from '../common/profile/ProfilePictureSection';
import ProfilePriceSection from '../common/profile/ProfilePriceSection';
import ProfileReviewSection from '../common/profile/ProfileReviewSection';
import ProfileSocialLinksSection from '../common/profile/ProfileSocialLinksSection';
import ProfileSpotifySection from '../common/profile/ProfileSpotifySection';
import ProfileTagSection from '../common/profile/ProfileTagSection';
import ProfileVideoSection from '../common/profile/ProfileVideoSection';
import toast from 'react-hot-toast';

const ActProfileScreen = observer(() => {
  const { id } = useParams();
  const location = useLocation();

  const [contentWidth, setContentWidth] = React.useState(0);
  const [contentHeight, setContentHeight] = React.useState(1900);
  const [scrollTop, setScrollTop] = React.useState(0);
  const [act, setAct] = React.useState<Act | undefined>();
  const [initLoading, setInitLoading] = React.useState(true);
  const [bannerLoading, setBannerLoading] = React.useState(false);
  const [forceRefetch, setForceRefetch] = React.useState(1);
  const uiStore = useUiStore();
  const contentRef = React.useRef<any>();

  const renderHeader = () => (
    <ProfileBannerSection
      entity={act!}
      entityType="act"
      setBannerLoading={setBannerLoading}
    />
  );

  const fetchActData = React.useCallback(async () => {
    const act = await sexpressClient.getAct({
      actId: id,
    });
    return act;
  }, []);

  React.useEffect(() => {
    const fetchAct = async () => {
      if (bannerLoading) {
        return;
      }
      try {
        setAct(undefined);
        const res = await fetchActData();
        setAct(res);
        setInitLoading(false);
      } catch (e) {
        //
      }
    };
    fetchAct();
  }, [location.pathname]);

  React.useEffect(() => {
    const fetchAct = async () => {
      if (bannerLoading || initLoading) {
        return;
      }

      try {
        const res = await fetchActData();
        setAct(res);
      } catch (e) {
        const err = e as Error;
        toast.error(err.message);
      }
    };
    fetchAct();
  }, [bannerLoading, forceRefetch]);

  React.useEffect(() => {
    if (act) {
      setContentWidth(contentRef.current!.clientWidth);
      setContentHeight(contentRef.current!.clientHeight);
    }
  }, [uiStore.innerWidth, uiStore.innerHeight, act]);

  return (
    <PageLayout
      renderHeader={renderHeader}
      onScroll={setScrollTop}
      extraFooterPadding={uiStore.isMobile ? 170 : undefined}
      loading={typeof act === 'undefined'}
      contentStyle={{ zIndex: 2 }}
    >
      <Content ref={contentRef}>
        <ProfilePictureSection
          entity={act!}
          entityType="act"
          forceRefetch={() => setForceRefetch(new Date().getTime())}
        />

        <ProfileTagSection entity={act!} />
        <ProfileBioSection entity={act!} />
        <ProfileActsOrMemberSection entity={act!} entityType="act" />
        <ProfileSocialLinksSection entity={act!} />

        <ProfileVideoSection entity={act!} entityType="act" />

        <ProfileSpotifySection entity={act!} entityType="act" />

        <ProfileGallerySection
          entity={act!}
          entityType="act"
          contentWidth={contentWidth}
        />

        <ProfileReviewSection entity={act!} />

        <ProfilePriceSection
          entity={act!}
          entityType="act"
          contentWidth={contentWidth}
          scrollTop={scrollTop}
          contentHeight={contentHeight}
        />
      </Content>
    </PageLayout>
  );
});

const Content = styled.div`
  display: flex;
  max-width: ${metrics.maxContentWidth}px;
  flex-direction: column;
  width: 75%;
  @media (max-width: 1400px) {
    max-width: 600px;
  }
  @media (max-width: ${(props) => props.theme.breakPoints.mobile}px) {
    width: 100%;
    max-width: 100%;
  }
`;

export default ActProfileScreen;
