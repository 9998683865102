import { observer } from 'mobx-react';
import * as React from 'react';

import i18n from '../../../constants/i18n';
import { Artist, YoutubeVideo } from '../../../shared';
import { useArtistStore } from '../../../stores/artist';
import { UseUserStore } from '../../../stores/user';
import { TextH6 } from '../../common/Typography';
import AddItemTextInput from '../../common/AddItemTextInput';
import EditArtistProfileLayout from '../EditArtistProfileLayout';
import GiDraggableList from '../../common/GiDraggableList';
import sexpressClient from '../../../utils/sexpress';
import EditYoutubeVideoCell from '../../common/EditYoutubeCell';
import { addRightToolTip } from '../../common/ToolTip';
import toast from 'react-hot-toast';

const EditArtistProfileVideos = observer(() => {
  const artistStore = useArtistStore();
  const userStore = UseUserStore();
  const me = userStore.getMe();
  const [error, setError] = React.useState<string>();
  const [loading, setLoading] = React.useState(false);

  const onSave = async (payload: Partial<Artist>) => {
    try {
      setError('');
      setLoading(true);
      await artistStore.editMeArtist(payload);
    } catch (e) {
      setError('err');
    } finally {
      setLoading(false);
    }
  };

  const handleReorder = async (videos: Artist['videos']) => {
    try {
      await sexpressClient.editMeArtistReorderVideos({
        videos,
      });
      await artistStore.fetchMeArtist();
    } catch (e) {
      const err = e as Error;
      toast.error(err.message);
    }
  };

  if (!me || !me.artist) {
    return <div />;
  }

  return (
    <EditArtistProfileLayout>
      {addRightToolTip({
        leftNode: (
          <TextH6 style={{ marginBottom: 24 }}>
            {i18n('EditArtistProfileVideos.addYoutube', 'Add a YouTube video')}
          </TextH6>
        ),
        toolTip: i18n(
          'EditArtistProfileVideos.copyAndPasteShareLink',
          'Copy and paste share link from Youtube, the more the better! This can be original music, or music in your repertoire!',
        ),
      })}
      <AddItemTextInput
        error={error}
        label={i18n('EditArtistProfileVideos.addVideo', 'Add Video')}
        style={{ marginBottom: 26 }}
        setError={setError}
        loading={loading}
        onSave={(url) =>
          onSave({
            videos: [
              { url, embedUrl: '', thumbnail: '', title: '', videoId: '' },
            ],
          })
        }
      />

      <GiDraggableList<YoutubeVideo>
        data={me.artist!.videos}
        onReorder={handleReorder}
        renderItem={(video) => (
          <EditYoutubeVideoCell
            video={video}
            onClickRemove={() =>
              onSave({
                videos: [
                  {
                    url: video.url,
                    embedUrl: '',
                    thumbnail: '',
                    title: '',
                    videoId: '',
                  },
                ],
              })
            }
          />
        )}
      />
    </EditArtistProfileLayout>
  );
});

export default EditArtistProfileVideos;
