export default {
  'RegistrationController.title': {
    en: 'Welcome to GigLinked!',
    fr: 'Bienvenue chez GigLinked!',
  },
  'RegistrationController.createYourAccount': {
    en: 'Create your account',
    fr: 'Créer votre compte',
  },
  'RegistrationController.firstName': { en: 'First name', fr: 'Prénom' },
  'RegistrationController.lastName': { en: 'Last name', fr: 'Nom' },
  'RegistrationController.email': { en: 'Email', fr: 'Courriel' },
  'RegistrationController.password': { en: 'Password', fr: 'Mot de passe' },
  'RegistrationController.age': {
    en: 'I am over 18 years of age',
    fr: "J'ai plus de 18 ans",
  },
  'RegistrationController.termsAndConditionsDesc': {
    en: "By clicking Create account, you agree to GigLinked's Terms and Conditions and confirm you have read our Privacy Policy. You may receive offers, news and updates from us.",
    fr: 'En cliquant sur Créer un compte, vous acceptez les Conditions générales de GigLinked et confirmez que vous avez lu notre Politique de Confidentialité.  Vous pouvez recevoir des offres, des nouvelles et des mises à jour de notre part.',
  },
  'RegistrationController.orCreateAccountG': {
    en: 'Create account with',
    fr: 'Créer un compte avec',
  },
  'RegistrationController.buttonCreateAccount': {
    en: 'Create account',
    fr: 'Créer un compte',
  },
  'RegistrationController.haveAccount': {
    en: 'Have an account? ',
    fr: 'Vous avez un compte? ',
  },
  'RegistrationController.login': { en: 'Log in', fr: 'Connecter' },
  'RegistrationController.confirmPassword': {
    en: 'Confirm Password',
    fr: 'Confirmez le mot de passe',
  },
  'RegistrationController.whoBooking': {
    en: 'Who are you booking for?',
    fr: 'Pour qui réservez-vous?',
  },
  'RegistrationController.role': {
    en: 'Role (Optional)',
    fr: 'Rôle (facultatif)',
  },
};
