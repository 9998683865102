export default {
  'EventDetailsStepOne.eventName': {
    en: 'Event name',
    fr: "Nom de l'événement",
  },
  'EventDetailsStepOne.eventNameplaceholder': {
    en: "Ex. Valentine's Day Lunch",
    fr: 'Ex. Déjeuner de la Saint-Valentin',
  },
  'EventDetailsStepOne.eventDate': {
    en: 'Date of event',
    fr: "Date de l'événement",
  },
  'EventDetailsStepOne.startTime': {
    en: 'Time of event',
    fr: "Heure de l'événement",
  },
  'EventDetailsStepOne.endTime': { en: 'End time', fr: 'Heure de fin' },
  'EventDetailsStepOne.theme': { en: 'Theme', fr: 'Thème' },
  'EventDetailsStepOne.stageLocation': {
    en: 'Stage location',
    fr: 'Emplacement de la gare',
  },
  'EventDetailsStepOne.stageCovered': {
    en: 'Is the stage covered?',
    fr: 'La scène est-elle couverte ?',
  },
  'EventDetailsStepOne.audienceSize': {
    en: 'Audience size',
    fr: "Taille de l'audience",
  },
  'EventDetailsStepOne.dateInPastError': {
    en: 'You cannot select a date in the past.',
    fr: 'Vous ne pouvez pas sélectionner une date dans le passé.',
  },
};
