import { observer } from 'mobx-react';
import * as React from 'react';
import styled from 'styled-components';
import colors from '../../constants/colors';
import i18n from '../../constants/i18n';
import { GeneralMediumTitle, TextH4 } from '../common/Typography';

const HowItWorksTopSection = observer(() => {
  return (
    <Main>
      <PurpleRectangle>
        <StyledBox>
          <GeneralMediumTitle style={{ color: colors.white }}>
            {i18n('HowItWorksTopSection.Title', 'Frictionless Booking')}
          </GeneralMediumTitle>
        </StyledBox>
        <StyledBox>
          <TextH4>
            {i18n(
              'HowItWorksTopSection.TitleDesctiption',
              'We make it easy to book live musicians from discovery to direct deposit.',
            )}
          </TextH4>
        </StyledBox>
      </PurpleRectangle>
    </Main>
  );
});

const Main = styled.div`
  width: 100%;
  max-width: 1440px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position relative;
`;

const PurpleRectangle = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 291px;
  border-radius: 30px;
  margin: 22px 22px 34px 34px;
  position: relative;
  background: linear-gradient(332.33deg, #100029 21.79%, #210154 100%);
  box-shadow: -5px -5px linear-gradient(92.68deg, #D4BDF4 1.7%, #F0AE26 105.42%);
  &::before {
    content: "";
    position: absolute;
    top: 15px;
    right: 15px;
    width: 100%;
    height: 100%;
    border-radius: 30px;
    background: linear-gradient(92.68deg, #D4BDF4 1.7%, #F0AE26 105.42%);
    z-index: -3;
  }
  @media (max-width: ${(props) => props.theme.breakPoints.tablet}px) {
    padding: 0 1rem;
  }
  @media (max-width: ${(props) => props.theme.breakPoints.mobile}px) {
    margin: 1rem 1rem 2rem 2rem;
    flex-direction: column;
    justify-content: center;
    flex-wrap: wrap;
    align-items: center;
    width: 100%;
`;

const StyledBox = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  text-align: start;
  flex: 1;
  max-width: 500px;
  padding-left: 20px;
  @media (max-width: ${(props) => props.theme.breakPoints.tablet}px) {
    padding-left: 15px;
    width: 100%;
    &:first-child {
      padding-left: 30px;
    }
  }
  @media (max-width: ${(props) => props.theme.breakPoints.mobile}px) {
    &:first-child {
      padding-left: 0;
    }
    padding-left: 0;
    width: 100%;
    height: 100%;
    text-align: center;
  }
`;
export default HowItWorksTopSection;
