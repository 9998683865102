import * as React from 'react';
import styled from 'styled-components';
import colors from '../../constants/colors';
import { Transaction } from '../../shared';
import RequestCaracteristicsSection from './RequestCaracteristicsSection';
import RequestDateLocationSection from './RequestDateLocationSection';
import ColoredTab from '../common/ColoredTab';
import GoBackLabel from './GoBackLabel';
import i18n from '../../constants/i18n';
import PageLayoutFooter from '../common/PageLayoutFooter';
import RequestDetailsQuoteSection from './requestDetailsQuoteSection';
import sexpressClient from '../../utils/sexpress';
import CenteredClipLoader from '../common/CenteredClipLoader';
import SuccessfulRequestRecapSection from './SuccessfulRequestRecapSection';
import RequestCancelButtonSection from './RequestCancelButtonSection';
import RequestDetailsReviewSection from './requestDetailsReviewSection';
import toast from 'react-hot-toast';

const RequestDetailsScreen = () => {
  const [request, setRequest] = React.useState<Transaction>();
  const [loading, setLoading] = React.useState(true);

  const url = window.location.pathname;
  const requestId = url.substring(
    url.lastIndexOf('/') + 1,
  ) as Transaction['id'];

  const fetchTransaction = async () => {
    try {
      const request = await sexpressClient.getTransaction({
        transactionId: requestId,
      });
      setRequest(request);
    } catch (e) {
      const err = e as Error;
      toast.error(err.message);
    } finally {
      setLoading(false);
    }
  };

  React.useEffect(() => {
    fetchTransaction();
  }, []);

  if (loading || !request) {
    return <CenteredClipLoader />;
  }

  const getTabColor = () => {
    const { status } = request;
    let tabColor = colors.tabOrange;

    if (['accepted'].includes(status)) {
      tabColor = '#4361ee';
    }

    if (
      ['expired', 'declined', 'refunded', 'cancelled', 'completed'].includes(
        status,
      )
    ) {
      // TODO: There were no gray in the design. Find out which one to use exactly
      tabColor = 'rgba(32,32,32,0.3)';
    }

    return tabColor;
  };

  return (
    <Main>
      <Content>
        <GoBackLabel />

        <DetailsSection>
          <ColoredTab
            mainStyle={{ marginBottom: 8 }}
            containerStyle={{
              backgroundColor: getTabColor(),
              padding: '0px 16px',
            }}
            label={i18n('RequestDetailsScreen.request', 'REQUEST')}
          />

          <RequestDateLocationSection request={request} />

          <RequestDetailsQuoteSection
            request={request}
            fetchTransaction={fetchTransaction}
          />

          <SuccessfulRequestRecapSection request={request} />

          <RequestCaracteristicsSection request={request} />

          <RequestCancelButtonSection
            request={request}
            fetchTransaction={fetchTransaction}
          />
        </DetailsSection>
      </Content>
      <RequestDetailsReviewSection
        request={request}
        fetchTransaction={fetchTransaction}
      />
      <PageLayoutFooter />
    </Main>
  );
};

const DetailsSection = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 1000px;
`;

const Main = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  box-sizing: border-box;
  padding-top: 64px;
  flex: 1;
  // In the design we have this color
  // background-color: #e5e5e5;
  background-color: ${({ theme }) => theme.colors.background};

  @media (max-width: ${(props) => props.theme.breakPoints.mobile}px) {
    padding-top: 24px;
    flex-direction: column;
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: row;
  align-self: center;
  box-sizing: border-box;
  width: 100%;
  max-width: 1300px;
  padding-left: 20px;
  padding-right: 20px;

  @media (max-width: ${(props) => props.theme.breakPoints.mobile}px) {
    flex-direction: column;
  }
`;

export default RequestDetailsScreen;
