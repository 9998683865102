import * as React from 'react';
import { ClipLoader } from 'react-spinners';
import styled from 'styled-components';

import colors from '../../constants/colors';
import i18n from '../../constants/i18n';
import { useBookingStore } from '../../stores/booking';
import sexpressClient from '../../utils/sexpress';
import { addDollarSign } from '../../utils/string';
import ToolTip from '../common/ToolTip';
import {
  GeneralRegularNormal,
  GeneralSemiBoldNormal,
} from '../common/Typography';
import toast from 'react-hot-toast';
import { Entity } from '../../shared';

const BookingSummaryFees = () => {
  const { artistId, actId } = useBookingStore();

  const [entity, setEntity] = React.useState<Entity | undefined>();
  const [loadingEntity, setLoadingEntity] = React.useState(true);

  React.useEffect(() => {
    const fetchEntity = async () => {
      try {
        setLoadingEntity(true);

        const res = artistId
          ? await sexpressClient.getArtist({
              artistId,
            })
          : await sexpressClient.getAct({
              actId: actId!,
            });

        setEntity(res);
      } catch (e) {
        const err = e as Error;
        toast.error(err.message);
      } finally {
        setLoadingEntity(false);
      }
    };
    fetchEntity();
  }, []);

  if (!entity || loadingEntity) {
    return (
      <LoadingContainer>
        <ClipLoader color={colors.whiteText} size={24} />
      </LoadingContainer>
    );
  }

  const {
    priceTransportDriver,
    priceTransportPassenger,
    priceAdmin,
    priceMeetingFees,
  } = entity;

  const transportFeeBlock = (
    <FeeBlock
      label={i18n('BookingSummaryFees.transportLabel', 'Transport')}
      message={`$${priceTransportDriver} ${i18n(
        'BookingSummaryFees.transportMessageFirstPart',
        '/hour of transport for the driver',
      )}\n$${priceTransportPassenger}${i18n(
        'BookingSummaryFees.transportMessageSecondPart',
        '/hour of transport for each passager',
      )}`}
    />
  );

  const administrativeFeeBlock = (
    <FeeBlock
      label={i18n('BookingSummaryFees.adminLabel', 'Administrative Fees')}
      message={`${addDollarSign(priceAdmin)}${i18n(
        'BookingSummaryFees.perHour',
        `/hour`,
      )}`}
    />
  );

  const meetingFeeBlock = (
    <FeeBlock
      label={i18n('BookingSummaryFees.meetingLabel', 'Meeting Fees')}
      message={`${addDollarSign(priceMeetingFees)}${i18n(
        'BookingSummaryFees.perHour',
        `/hour`,
      )}`}
    />
  );
  const partitionsAndArrangementsFeeBlock = (
    <FeeBlock
      label={i18n(
        'BookingSummaryFees.partitionsAndArrangementsLabel',
        'Purchasing Partitions and Musical Arragements',
      )}
      message={i18n(
        'BookingSummaryFees.partitionsAndArrangementsMessage',
        'Any purchase of partitions or creations of musical arragements that are not available for free online will be covered by the client.',
      )}
    />
  );

  const equipmentFeeBlock = (
    <FeeBlock
      label={i18n(
        'BookingSummaryFees.equipmentRentalLabel',
        'Equipment Rental',
      )}
      message={i18n(
        'BookingSummaryFees.equipmentRentalMessage',
        'All necessary rental equipment will be covered by the client.',
      )}
    />
  );

  const renderContent = () => [
    transportFeeBlock,
    administrativeFeeBlock,
    meetingFeeBlock,
    partitionsAndArrangementsFeeBlock,
    equipmentFeeBlock,
  ];

  return (
    <>
      <ToolTip
        text={i18n(
          'BookingSummaryFees.thisIsPurelyForInformational',
          'This is purely for informational purposes and for added transparency regarding pricing. The price quoted by musicians varies depending on many factors, not all of which may be shown below',
        )}
        mainStyle={{ marginBottom: 12 }}
      />

      <Main>{renderContent()}</Main>
    </>
  );
};

const FeeBlock = ({ label, message }: { label: string; message: string }) => {
  return (
    <>
      <Content>
        <GeneralSemiBoldNormal
          style={{
            width: '40%',
            marginRight: 15,
          }}
        >
          {label}
        </GeneralSemiBoldNormal>
        <GeneralRegularNormal
          style={{
            width: '60%',
            color: colors.whiteText,
            whiteSpace: 'pre-line',
          }}
        >
          {message}
        </GeneralRegularNormal>
      </Content>

      <Separator />
    </>
  );
};

const Main = styled.div`
  background-color: ${({ theme }) => theme.colors.darkBlue};
  padding: 30px 25px;
`;

const Content = styled.div`
  display: flex;
  flex-direction: row;
`;

const Separator = styled.div`
  display: flex;
  width: 100%;
  height: 1px;
  margin-top: 22px;
  margin-bottom: 22px;
  background-color: rgba(255, 255, 255, 0.25);

  :last-of-type {
    margin-top: 0px;
    margin-bottom: 0px;
    background-color: ${({ theme }) => theme.colors.darkBlue};
  }
`;

const LoadingContainer = styled.div`
  display: flex;
  flex-direction: row;
  height: 200px;
  align-items: center;
  justify-content: center;
`;

export default BookingSummaryFees;
