import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import images from '../../constants/images';
import { GeneralRegularNormal } from '../common/Typography';

const GoBackLabel = () => {
  const navigate = useNavigate();

  return (
    <Main>
      <Container onClick={() => navigate(-1)}>
        <BlueTriangle src={images.leftBlueTriangle} />
        <GeneralRegularNormal
          style={{
            textDecoration: 'underline',
            textUnderlinePosition: 'under',
            whiteSpace: 'nowrap',
          }}
        >
          Go back
        </GeneralRegularNormal>
      </Container>
    </Main>
  );
};

const Main = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 64px;
  margin-bottom: 0px;

  @media (max-width: ${(props) => props.theme.breakPoints.mobile}px) {
    margin-right: 0px;
    margin-bottom: 18px;
  }
`;

const Container = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

const BlueTriangle = styled.img`
  height: 8px;
  width: 5px;
  object-fit: contain;
  margin-right: 8px;
`;

export default GoBackLabel;
