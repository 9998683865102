export default {
  'AboutTopSection.header': {
    en: 'About GigLinked',
    fr: 'Apropos de GigLinked',
  },
  'AboutTopSection.secondaryText': {
    en: 'GigLinked connects artists and humans for the sake of spreading joy through live music.',
    fr: 'GigLinked met en relation des artistes et des humains dans le but de répandre la joie par la musique en direct.',
  },
  'AboutTopSection.about.left': {
    en: "GigLinked is a community of musicians, singers and lovers of live music, a community that was born in 2021 when a musician's plea for better working conditions was overheard by their best friend. This sparked the idea to build a platform that would facilitate live music bookings for both artists and bookers alike, through secure and timely transactions, transparent communication, and the automation of administrative workflows.",
    fr: "GigLinked est une communauté de musiciens, de chanteurs et d'amateurs de musique live, une communauté qui est née en 2021 lorsque le plaidoyer d'un musicien pour de meilleures conditions de travail a été entendu par son meilleur ami. C'est ainsi qu'est née l'idée de construire une plateforme qui faciliterait les réservations de musique live, tant pour les artistes que pour les bookers, grâce à des transactions sécurisées et rapides, une communication transparente et l'automatisation des procédés administratifs.",
  },
  'AboutTopSection.about.right': {
    en: 'Artists on GigLinked come from every walk of life and provide their bookers with experiences as diverse and beautiful as they are. GigLinked believes that artists, bookers, and their encircling communities are all stakeholders and that GigLinked, through innovation, has the responsibility of modernizing archaic booking practices for the benefit of the entire live music ecosystem.',
    fr: "Les artistes de GigLinked viennent de tous les horizons et offrent à leurs clients des expériences aussi diverses et belles qu'eux. GigLinked est convaincu que les artistes, les bookers et les communautés qui les entourent sont tous des parties prenantes et que GigLinked, grâce à l'innovation, a la responsabilité de moderniser les pratiques archaïques de réservation au profit de l'ensemble de l'écosystème de la musique live.",
  },
};
