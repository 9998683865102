export default {
  'RegistrationMusicianUploadPicture.finish': {
    en: 'Finish set-up',
    fr: 'Terminer!',
  },
  'RegistrationMusicianUploadPicture.title': {
    en: 'Upload profile picture',
    fr: 'Télécharger une photo de profil',
  },
  'RegistrationMusicianUploadPicture.description': {
    en: 'Profiles with profile pictures appear earlier on the search page',
    fr: 'Les profils avec photo apparaissent plus tôt sur la page de recherche',
  },
};
