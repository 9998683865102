import { MusicianType } from '../shared';

export const JAMBASSADORS = [
  {
    profilePicture:
      'https://d3ls406vnrgcfa.cloudfront.net/dev-uploadedFiles%2Fw3fF97xza.jpg',
    name: 'Alex Ambroise',
    instrument: 'Jazz, Funk, Sax',
    location: 'Montreal, QC',
    profileUrl: 'https://www.giglinked.live/artists/6272db79c5afb15b23c70040',
  },
  {
    profilePicture:
      'https://d3ls406vnrgcfa.cloudfront.net/dev-uploadedFiles%2FM6-W2V9hw.jpg',
    name: 'Amanda Gibeau',
    instrument: 'Violinist, Fiddler, Composer',
    location: 'Montreal, QC',
    profileUrl: 'https://www.giglinked.live/artists/6272db76c5afb15b23c6ff90',
  },
  {
    profilePicture:
      'https://d3ls406vnrgcfa.cloudfront.net/dev-uploadedFiles%2FoFTwUe2goH.jpg',
    name: 'Clemens Grassman',
    instrument: 'Drums, Big band',
    location: 'New York City',
    profileUrl: 'https://www.giglinked.live/artists/641086bef9b1bf02a6759703',
  },
] as Array<MusicianType>;

export const POPULAR_MUSICIANS = [
  {
    profilePicture:
      'https://d3ls406vnrgcfa.cloudfront.net/production-uploadedFiles%2FHK7qsqNQ3E.jpg',
    name: 'Lamia Yared',
    instrument: 'Classical, World, Middle-Eastern',
    location: 'Montreal, QC',
    profileUrl: 'https://www.giglinked.live/artists/63f8f970f9b1bf02a673db61',
  },
  {
    profilePicture:
      'https://d3ls406vnrgcfa.cloudfront.net/dev-uploadedFiles%2Fw3fF97xza.jpg',
    name: 'Alex Ambroise',
    instrument: 'Jazz, Sax',
    location: 'Montreal, Quebec',
    profileUrl: 'https://www.giglinked.live/artists/6272db79c5afb15b23c70040',
  },
  {
    profilePicture:
      'https://d3ls406vnrgcfa.cloudfront.net/dev-uploadedFiles%2FM6-W2V9hw.jpg',
    name: 'Amanda Gibeau',
    instrument: 'Violin, Violon, Strings',
    location: 'Montreal, Quebec',
    profileUrl: 'https://www.giglinked.live/artists/6272db76c5afb15b23c6ff90',
  },
  {
    profilePicture:
      'https://d3ls406vnrgcfa.cloudfront.net/production-uploadedFiles%2FR2zjda7sj.jpg',
    name: 'Paul Tarussov',
    instrument: 'Jazz, Trombone, Quintet',
    location: 'Toronto, Ontario',
    profileUrl: 'https://www.giglinked.live/artists/6398aaacab9cee89dc07e578',
  },
  {
    profilePicture:
      'https://d3ls406vnrgcfa.cloudfront.net/production-uploadedFiles%2Fxsc_9S2H1L.jpg',
    name: 'Kyra Charlton',
    instrument: 'Harpist, Duo de harpes',
    location: 'Montreal, Quebec',
    profileUrl: 'https://www.giglinked.live/artists/63c71bf4f46794dd79cc1b4b',
  },
  {
    profilePicture:
      'https://d3ls406vnrgcfa.cloudfront.net/production-uploadedFiles%2FcyHrXRRJN.jpg',
    name: 'Shy Shy Schullie',
    instrument: 'Jazz singer, retro',
    location: 'Montreal, Quebec',
    profileUrl: 'https://www.giglinked.live/artists/642a32409f876f9c14de5f27',
  },
] as Array<MusicianType>;

export const MUSICIAN_RECOMMENDATIONS = [
  {
    profilePicture:
      'https://d3ls406vnrgcfa.cloudfront.net/production-uploadedFiles%2FWg8UhG8Ir.jpg',
    name: 'Gabrielle Beaulieu-Brossard',
    instrument: 'Violoniste, pianiste, chanteuse',
    location: 'Montreal, QC',
    profileUrl: 'https://www.giglinked.live/artists/6499fd86b5534919836f6962',
  },
  {
    profilePicture:
      'https://d3ls406vnrgcfa.cloudfront.net/production-uploadedFiles%2Fj6JacoOsY.jpg',
    name: 'Andréanne Martin',
    instrument: 'Soul, reggae, chanson, funk',
    location: 'Montreal, QC',
    profileUrl: 'https://www.giglinked.live/artists/63fbddcbf9b1bf02a673e620',
  },
  {
    profilePicture:
      'https://d3ls406vnrgcfa.cloudfront.net/production-uploadedFiles%2Fxsc_9S2H1L.jpg',
    name: 'Kyra Charlton',
    instrument: 'Harpist, duo flûte et harpe, duo violon et harpe',
    location: 'Montreal, QC',
    profileUrl: 'https://www.giglinked.live/artists/63c71bf4f46794dd79cc1b4b',
  },
  {
    profilePicture:
      'https://d3ls406vnrgcfa.cloudfront.net/production-uploadedFiles%2FCeOtZpCdQ.jpg',
    name: 'Duo Marguerite',
    instrument: 'String Duo, Classical Music, Violin',
    location: 'Montreal, QC',
    profileUrl: 'https://www.giglinked.live/artists/6431c9839f876f9c14dfe9df',
  },
  {
    profilePicture:
      'https://d3ls406vnrgcfa.cloudfront.net/production-uploadedFiles%2FcyHrXRRJN.jpg',
    name: 'Shy Shy Schullie',
    instrument: 'Jazz singer, retro',
    location: 'Montreal, Quebec',
    profileUrl: 'https://www.giglinked.live/artists/642a32409f876f9c14de5f27',
  },
  {
    profilePicture:
      'https://d3ls406vnrgcfa.cloudfront.net/production-uploadedFiles%2FDgvYpJuQU.jpg',
    name: 'David Marino',
    instrument: 'Duo, trio, quartet',
    location: 'Montreal, Quebec',
    profileUrl: 'https://www.giglinked.live/artists/654e3f10f0d52cc06484ae79',
  },
  {
    profilePicture:
      'https://d3ls406vnrgcfa.cloudfront.net/dev-uploadedFiles%2Fw3fF97xza.jpg',
    name: 'Alex Ambroise',
    instrument: 'Jazz, Sax',
    location: 'Montreal, Quebec',
    profileUrl: 'https://www.giglinked.live/artists/6272db79c5afb15b23c70040',
  },
  {
    profilePicture:
      'https://d3ls406vnrgcfa.cloudfront.net/production-uploadedFiles%2Fjv2Alf6KR.jpg',
    name: 'Qi Shen',
    instrument: 'Erhu, Chinese, Traditional',
    location: 'Montreal, QC',
    profileUrl: 'https://www.giglinked.live/artists/64d91136b5534919837241d3',
  },
  {
    profilePicture:
      'https://d3ls406vnrgcfa.cloudfront.net/production-uploadedFiles%2ForMOcVuPd.jpg',
    name: 'Sandrine Hébert',
    instrument: 'Rock, Classical, Country',
    location: 'Montreal, QC',
    profileUrl: 'https://www.giglinked.live/artists/652813fd7841666e6946b20b',
  },
  {
    profilePicture:
      'https://d3ls406vnrgcfa.cloudfront.net/production-uploadedFiles%2FiCa8C2Kro.jpg',
    name: 'Charles-Auguste',
    instrument: 'Guitar, Piano',
    location: 'Quebec, QC',
    profileUrl: 'https://www.giglinked.live/artists/6527df627841666e6946a6e4',
  },
  {
    profilePicture:
      'https://d3ls406vnrgcfa.cloudfront.net/production-uploadedFiles%2FcqVNAGD1B.jpg',
    name: 'Bruce Gaulin',
    instrument: 'Piano, Musique, Concerts',
    location: 'Quebec, QC',
    profileUrl: 'https://www.giglinked.live/artists/64d50af5b553491983720d2f',
  },
  {
    profilePicture:
      'https://d3ls406vnrgcfa.cloudfront.net/production-uploadedFiles%2FsCOQUBSIn5.jpg',
    name: 'Simon Desbiens',
    instrument: 'Violoncelle, Composition',
    location: 'Quebec, QC',
    profileUrl: 'https://www.giglinked.live/artists/64d788c1b553491983722a62',
  },
] as Array<MusicianType>;
