import { action, observable, toJS } from 'mobx';
import { useStores } from '.';
import { Act, Tag } from '../shared';
import { editMeAct } from '../utils/api';
import sexpressClient from '../utils/sexpress';
import artist from './artist';

export interface ActStoreType {
  initAct: (payload?: { name: Act['name'] }) => Promise<Act>;
  editMeAct: (
    payload: Partial<Act> & { actId: Act['id'] } & {
      croppingOptions?: {
        width?: number;
        height?: number;
        left?: number;
        top?: number;
        bannerImageB64?: string;
        profileImageB64?: string;
      };
    },
  ) => Promise<void>;
  currentEditAct?: Act;
  fetchAct: (payload: { actId: Act['id'] }) => Promise<Act>;
  getCurrentEditAct: () => ActStoreType['currentEditAct'];
  editTags: (payload: Partial<Tag> & { actId: Act['id'] }) => Promise<Tag[]>;
  setCurrentEditAct: (act?: Act) => void;
}

class ActStore {
  @observable currentEditAct: ActStoreType['currentEditAct'] = undefined;

  @action
  setCurrentEditAct: ActStoreType['setCurrentEditAct'] = (act) => {
    this.currentEditAct = act;
  };

  @action
  initAct: ActStoreType['initAct'] = async (payload = { name: '' }) => {
    const newAct = await sexpressClient.initAct(payload);
    await artist.fetchMeArtist();
    return newAct;
  };

  @action
  editMeAct: ActStoreType['editMeAct'] = async (payload) => {
    this.currentEditAct = await editMeAct(payload);
  };

  @action
  fetchAct: ActStoreType['fetchAct'] = async (payload) => {
    this.currentEditAct = await sexpressClient.getMeAct(payload);
    return this.currentEditAct;
  };

  @action
  editTags: ActStoreType['editTags'] = async (payload) => {
    const updatedTags = await sexpressClient.editActTags(payload);
    this.currentEditAct!.tags = updatedTags;
    return updatedTags;
  };

  getCurrentEditAct = () => {
    if (this.currentEditAct) {
      return toJS(this.currentEditAct);
    }
  };
}

export default new ActStore();

export function useActStore(): ActStoreType {
  const { act } = useStores();
  return act as ActStoreType;
}
