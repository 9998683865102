import { observer } from 'mobx-react-lite';
import * as React from 'react';
import styled from 'styled-components';
import i18n from '../../../constants/i18n';
import images from '../../../constants/images';
import { useActStore } from '../../../stores/act';
import { useArtistStore } from '../../../stores/artist';
import { useUiStore } from '../../../stores/ui';
import ATag from '../ATag';
import ImagePickerHandler from '../ImagePickerHandler';
import ModalImageCropper from '../ModalImageCropper';
import { GeneralRegularNormal } from '../Typography';
import { isEntityOwner } from './profileUtils';
import toast from 'react-hot-toast';
import { Entity, EntityType } from '../../../shared';

const ProfileBannerSection = observer(
  ({
    entity,
    entityType,
    setBannerLoading,
  }: {
    entity: Entity;
    entityType: EntityType;
    setBannerLoading: (value: boolean) => void;
  }) => {
    const [bannerLoading, _setBannerLoading] = React.useState(false);

    const isOwner = isEntityOwner({
      entity,
      entityType,
    });

    return (
      <div style={{ position: 'relative' }}>
        {isOwner ? (
          <>
            <EditProfileButton entityType={entityType} entity={entity} />
            <EditBannerPictureButton
              setBannerLoading={(value) => {
                setBannerLoading(value);
                _setBannerLoading(value);
              }}
              entityType={entityType}
              entity={entity}
            />
          </>
        ) : null}
        <BannerImage
          url={entity!.bannerPicture}
          style={{ opacity: bannerLoading ? 0.8 : 1 }}
        />
      </div>
    );
  },
);

const EditProfileButton = observer(
  ({ entityType, entity }: { entityType: EntityType; entity: Entity }) => {
    let href = '/me/artist/overview';
    let text = i18n('ActProfileScreen.editProfileButton', 'Edit Profile');
    if (entityType === 'act') {
      href = `/me/acts/${entity.id}/overview`;
      text = i18n('ActProfileScreen.editActButton', 'Edit Act');
    }

    return (
      <ATag href={href} noOpacity={true}>
        <EditProfileButtonContainer>
          <EditProfileButtonPen src={images.editPen} />
          <GeneralRegularNormal>{text}</GeneralRegularNormal>
        </EditProfileButtonContainer>
      </ATag>
    );
  },
);

const EditBannerPictureButton = ({
  setBannerLoading,
  entityType,
  entity,
}: {
  setBannerLoading: (isLoading: boolean) => void;
  entityType: EntityType;
  entity: Entity;
}) => {
  const artistStore = useArtistStore();
  const actStore = useActStore();
  const uiStore = useUiStore();
  const onChangePicture = async (picture: string) => {
    try {
      uiStore.openPopup({
        popupDisableTapOut: true,
        renderContent: () => (
          <ModalImageCropper
            aspect={2.25}
            imageUrl={picture}
            onPressSave={async (imageB64: string) => {
              try {
                if (entityType === 'act') {
                  await actStore.editMeAct({
                    actId: entity.id,
                    croppingOptions: { bannerImageB64: imageB64 },
                  });
                } else if (entityType === 'artist') {
                  await artistStore.editMeArtist({
                    croppingOptions: { bannerImageB64: imageB64 },
                  });
                }
                setBannerLoading(false);
              } catch (e) {
                console.log(e);
              }
            }}
          />
        ),
      });
    } catch (e) {
      const err = e as Error;
      toast.error(err.message);
      setBannerLoading(false);
    }
  };

  return (
    <EditBannerPictureContainer>
      <ImagePickerHandler
        onChangePicture={onChangePicture}
        onLoadingStateChange={() => setBannerLoading(true)}
      >
        <EditBannerPictureIcon src={images.cameraIcon} />
      </ImagePickerHandler>
    </EditBannerPictureContainer>
  );
};

const EditProfileButtonPen = styled.img`
  margin-right: 9px;
  @media (max-width: ${(props) => props.theme.breakPoints.mobile}px) {
  }
`;

const EditBannerPictureIcon = styled.img`
  height: 22px;
  width: 22px;

  @media (max-width: ${(props) => props.theme.breakPoints.mobile}px) {
    width: 18px;
    height: 18px;
    border-radius: 9px;
  }
`;

const EditBannerPictureContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 37px;
  width: 37px;
  border-radius: 18.5px;
  position: absolute;
  top: 50px;
  right: 55px;
  cursor: pointer;
  background: linear-gradient(0deg, #f2f3f4, #f2f3f4), #000000;
  &:hover {
    filter: brightness(115%);
  }
  @media (max-width: ${(props) => props.theme.breakPoints.mobile}px) {
    right: 10px;
    width: 28px;
    top: 10px;
    height: 28px;
    border-radius: 14px;
  }
`;

const EditProfileButtonContainer = styled.div`
  display: flex;
  align-items: center;
  height: 37px;
  padding-right: 9px;
  padding-left: 9px;
  background: linear-gradient(0deg, #f2f3f4, #f2f3f4), #000000;
  border-radius: 2px;
  position: absolute;
  top: 50px;
  right: 105px;
  cursor: pointer;
  &:hover {
    filter: brightness(105%);
  }
  @media (max-width: ${(props) => props.theme.breakPoints.mobile}px) {
    right: 50px;
    top: 10px;
    height: 28px;
  }
`;

const BannerImage = styled.div<{ url: string }>`
  height: min(20vw, 200px);
  background: radial-gradient(
    77.01% 120% at 100% 50%,
    ${(props) => props.theme.colors.tabOrange} 0%,
    ${(props) => props.theme.colors.buttonTextColor} 82.6%
  );
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  @media (max-width: ${(props) => props.theme.breakPoints.mobile}px) {
    height: max(250px, 50vw);
    max-height: 500px;
  }
`;

export default ProfileBannerSection;
