import translations from './translations';
import user from '../stores/user';

export function getDeviceLangage() {
  return user?.browserLanguage;
}

// i know this code is ugly.
// -Vince
export default function (
  translation: keyof typeof translations | typeof translations | string,
  defaultString = '',
) {
  const langKey = getDeviceLangage();

  if (typeof translation === 'object') {
    // @ts-ignore
    return translation[langKey] || defaultString;
  } else {
    // @ts-ignore
    return translations[translation] && translations[translation][langKey]
      ? // @ts-ignore
        // @ts-ignore
        translations[translation][langKey]
      : // @ts-ignore
      // @ts-ignore
      translations[translation] && translations[translation]['en']
      ? // @ts-ignore
        // @ts-ignore
        translations[translation]['en']
      : defaultString;
  }
}
