export default {
  'BookingRecap.title': { en: 'Summary', fr: 'Résumé' },
  'BookingRecap.deposit': {
    en: 'Deposit Amount (tx in.)',
    fr: 'Montant de dépôt (tx in.)',
  },
  'BookingRecap.quote': {
    en: 'Quote (deposit in.)',
    fr: 'Citation (dépôt dans.)',
  },
  'BookingRecap.Giglinkedfees': {
    en: 'GigLinked Fees',
    fr: 'Frais de liaison',
  },
  'BookingRecap.subtotal': { en: 'Subtotal', fr: 'Total' },
  'BookingRecap.gst': { en: 'GST', fr: 'TPS' },
  'BookingRecap.qst': { en: 'QST', fr: 'QST' },
  'BookingRecap.total': { en: 'Total', fr: 'Totale' },
  'BookingRecap.recapText': {
    en: 'The deposit amount will be charge right now and the remaining amount will be charge after the event completion.',
    fr: "Le montant du dépôt sera facturé dès maintenant et le montant restant sera facturé après l'achèvement de l'événement.",
  },
  'BookingRecap.pay': { en: 'Confirm and Pay', fr: 'Confirmer et payer' },
};
