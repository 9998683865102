import { observer } from 'mobx-react';
import * as React from 'react';
import { Box } from 'react-native-kondo';
import styled from 'styled-components';
import i18n from '../../../constants/i18n';
import { useUiStore } from '../../../stores/ui';
import BookingController from '../../booking/BookingController';
import { useBookingStore } from '../../../stores/booking';
import { useMessagingStore } from '../../../stores/messaging';
import toast from 'react-hot-toast';
import { UseUserStore } from '../../../stores/user';
import { logUserClickMakeAnOffer } from '../../../utils/amplitude';
import { useNavigate } from 'react-router-dom';
import { Entity, EntityType } from '../../../shared';
import GiglinkedButton from '../GiglinkedButton';

const ProfilePriceSection = observer(
  ({
    entity,
    contentWidth,
    scrollTop,
    contentHeight,
    entityType,
  }: {
    entity: Entity;
    contentWidth: number;
    scrollTop: number;
    contentHeight: number;
    entityType: EntityType;
  }) => {
    const messagingStore = useMessagingStore();
    const uiStore = useUiStore();
    const bookingStore = useBookingStore();
    const navigate = useNavigate();
    const userStore = UseUserStore();
    const me = userStore.getMe();
    const innerHeight = uiStore.innerHeight;

    const footerHeight = 180;

    const maxScrollForSmallDevice = contentHeight - footerHeight; // TODO this value will change depending on the contentHeight

    let maxBottom =
      uiStore.innerHeight <= 650 && scrollTop >= maxScrollForSmallDevice
        ? -140
        : 0;

    if (uiStore.isMobile) {
      maxBottom = 0;
    }

    const cantBeBook = !entity.canBeBooked;

    const onClickMakeAnOffer = () => {
      if (me) {
        logUserClickMakeAnOffer();
        bookingStore.setBookingData({
          actId: entityType === 'act' ? entity.id : undefined,
          artistId: entityType === 'artist' ? entity.id : undefined,
        });
        uiStore.openPopup({
          popupDisableTapOut: true,
          renderContent: () => <BookingController />,
        });
      } else {
        toast.error(
          i18n(
            'ArtistPriceSection.pleaseLogInToMakeAnOffer',
            'Please log in to make an offer',
          ),
        );
      }
    };

    const onClickMessage = async () => {
      if (me) {
        // @ts-ignore
        await messagingStore.setCurrentOtherUser(
          // @ts-ignore
          entityType === 'act' ? entity.owner.user : entity.user,
        );
        navigate('/conversations');
      } else {
        toast.error(
          i18n(
            'ArtistPriceSection.pleaseLogInToEnableMessages',
            'Please log in to enable messages',
          ),
        );
      }
    };

    return (
      <Main
        contentWidth={contentWidth}
        innerHeight={innerHeight}
        bottom={maxBottom}
        isMobile={uiStore.isMobile}
      >
        <Box
          style={{
            flexDirection: uiStore.isMobile ? 'row' : 'column',
            alignItems: uiStore.isMobile ? 'center' : 'flex-start',
          }}
        >
          {cantBeBook ? null : (
            <GiglinkedButton
              label={i18n('ArtistPriceSection.makeOffer', 'Make an Offer')}
              style={{
                marginBottom: uiStore.isMobile ? 0 : 10,
                flex: uiStore.isMobile ? 1 : undefined,
                width: '100%',
              }}
              onClick={onClickMakeAnOffer}
            />
          )}
          {uiStore.isMobile ? <Box width={8} /> : null}
          {cantBeBook ? null : (
            <GiglinkedButton
              inverted
              style={{
                flex: uiStore.isMobile ? 1 : undefined,
                width: '100%',
              }}
              onClick={onClickMessage}
              label={i18n('ArtistPriceSection.Message', 'Message')}
            />
          )}
        </Box>
      </Main>
    );
  },
);

const Main = styled.div<{
  contentWidth: number;
  innerHeight: number;
  bottom?: number;
  isMobile?: boolean;
}>`
  display: flex;
  flex-direction: column;
  position: fixed;
  ${({ isMobile }) =>
    isMobile
      ? ``
      : `max-width: 201px;
  width: 100%;
  `}

  ${({ isMobile, innerHeight }) =>
    isMobile
      ? ''
      : innerHeight < 838
      ? `bottom: 32px;`
      : `top: 600px;
  margin-bottom: 100px;`}
  margin-left: ${(props) => props.contentWidth + 76}px;
  margin-top: 25px;
  transition: transform 0.1s ease;
  transform: ${(props) => `translateY(${props.bottom}px)`};
  @media (max-width: 1400px) {
    margin-left: 627px;
  }
  @media (max-width: ${(props) => props.theme.breakPoints.mobile}px) {
    box-sizing: border-box;
    padding: 11px 23px 24px 23px;
    margin-left: 0px;
    margin-bottom: 0px;
    margin-top: 0px;
    bottom: 25px;
    left: 25px;
    right: 25px;
    background: #ffffff;
    box-shadow: 0px 0px 15px rgba(131, 123, 119, 0.5);
    border-radius: 5px;
  }
`;

export default ProfilePriceSection;
