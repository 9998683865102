export default {
  'StepperRegistrationInstruments.guitar': { en: 'Guitar', fr: 'Guitare' },
  'StepperRegistrationInstruments.bass': { en: 'Bass', fr: 'Basse' },
  'StepperRegistrationInstruments.violin': { en: 'Violin', fr: 'Violon' },
  'StepperRegistrationInstruments.cello': { en: 'Cello', fr: 'Violoncelle' },
  'StepperRegistrationInstruments.piano': { en: 'Piano', fr: 'Piano' },
  'StepperRegistrationInstruments.keys': { en: 'Keys', fr: 'Clavier' },
  'StepperRegistrationInstruments.percussion': {
    en: 'Percussion',
    fr: 'Percussion',
  },
  'StepperRegistrationInstruments.drums': { en: 'Drums', fr: 'Tambours' },
  'StepperRegistrationInstruments.harp': { en: 'Harp', fr: 'Harpe' },
  'StepperRegistrationInstruments.saxophone': {
    en: 'Saxophone',
    fr: 'Saxophone',
  },
  'StepperRegistrationInstruments.trumpet': { en: 'Trumpet', fr: 'Trompette' },
  'StepperRegistrationInstruments.viola': { en: 'Viola', fr: 'Alto' },
  'StepperRegistrationInstruments.title': {
    en: 'Select your instrument(s)',
    fr: 'Sélectionner votre instrument(s):',
  },
  'StepperRegistrationInstruments.description': {
    en: "Can't find your instrument? Add yours later in 'edit profile'",
    fr: 'Vous ne trouvez pas votre instrument ? Ajoutez le vôtre plus tard dans "modifier le profil',
  },
};
