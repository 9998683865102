export default {
  'DashboardLanding.yourEvents': { en: 'Your events', fr: 'Vos événements' },
  'DashboardLanding.recommendations': {
    en: 'Recommendations',
    fr: 'Recommandations',
  },
  'DashboardLanding.invoices': { en: 'Invoices', fr: 'Factures' },
  'DashboardLanding.title': {
    en: 'Welcome to your Dashboard',
    fr: 'Bienvenue dans votre tableau de bord',
  },
  'DashboardLanding.eventTitle': {
    en: 'Call on qualified musicians',
    fr: 'Faites appel aux musiciens',
  },
  'DashboardLanding.eventSubTitle': {
    en: "Post your event and you'll start getting proposals. Hire the best match.",
    fr: 'Publier votre événement et vous recevrez des propositions. Engagez la personne qui vous convient le mieux.',
  },
  'DashboardLanding.postEvent': {
    en: 'Post your event',
    fr: 'Publier votre événement',
  },
  'DashboardLanding.browseTitle': {
    en: 'Search by keyword and location',
    fr: 'Recherche par lieu ou mot-clé',
  },
  'DashboardLanding.browseSubTitle': {
    en: 'Find the perfect live music for your event and send personalized requests.',
    fr: 'Trouvez la musique parfaite pour votre événement et envoyez des demandes personnalisées.',
  },
  'DashboardLanding.browseMusicians': {
    en: 'Browse musicians',
    fr: 'Parcourir les musiciens',
  },
  'DashboardLanding.applications': { en: 'Applications', fr: 'Applications' },
};
