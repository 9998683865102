import * as React from 'react';
import i18n from '../../../constants/i18n';
import { Transaction } from '../../../shared';
import { useUiStore } from '../../../stores/ui';
import GiButton from '../../common/GiButton';
import useGetRequestDetailsQuoteFormattedData from './useGetRequestDetailsQuoteFormattedData';
import sexpressClient from '../../../utils/sexpress';
import CompleteTransactionController from '../../transaction/CompleteTransactionController';
import toast from 'react-hot-toast';

const FirstButton = ({
  request,
  price,
  fetchTransaction,
}: {
  request: Transaction;
  price: string;
  fetchTransaction: () => void;
}) => {
  const { innerWidth, openPopup } = useUiStore();

  const [isLoading, setIsLoading] = React.useState(false);

  const isMobilePhone = innerWidth <= 375;
  const { id, status } = request;
  const { meIsAct, meIsArtist, meIsBooker } =
    useGetRequestDetailsQuoteFormattedData(request);

  // TODO: Add all combinations for the list of ( (Entity|Booker) ⊗ TransactionStatus)
  let hideButton = [
    'expired',
    'declined',
    'refunded',
    'cancelled',
    'completed',
  ].includes(status);

  if (status === 'quotePending' && meIsBooker) {
    hideButton = true;
  }

  if (hideButton) {
    return null;
  }

  const getButtonContent = () => {
    // TODO: Add all combinations for the list of ( (Entity|Booker) ⊗ TransactionStatus)
    if (
      (meIsAct || meIsArtist) &&
      ['quotePending', 'quoteSent', 'quoteDeclined'].includes(status)
    ) {
      return {
        label: ['quoteSent', 'quoteDeclined'].includes(status)
          ? i18n('RequestDetailsQuoteSection.updateQuote', 'Update Quote')
          : i18n('RequestDetailsQuoteSection.sendQuote', 'Send Quote'),
        loading: isLoading,
        onClick: async () => {
          try {
            setIsLoading(true);
            await sexpressClient.updateTransactionState({
              action: 'sendQuote',
              transactionId: id,
              payload: { quote: price },
            });

            fetchTransaction();
            toast.success(
              ['quoteSent', 'quoteDeclined'].includes(status)
                ? i18n(
                    'RequestDetailsQuoteSection.quoteUpdated!',
                    'Quote Updated!',
                  )
                : i18n('RequestDetailsQuoteSection.quoteSent!', 'Quote Sent!'),
            );
          } catch (e) {
            console.log(e);
          } finally {
            setIsLoading(false);
          }
        },
      };
    }

    // Use one as a default one
    return {
      label: i18n('RequestDetailsQuoteSection.accept', 'Accept'),
      loading: false,
      onClick: () => {
        openPopup({
          popupDisableTapOut: true,
          renderContent: () => (
            <CompleteTransactionController
              transaction={request}
              onComplete={fetchTransaction}
            />
          ),
        });
      },
    };
  };

  const { label, loading, onClick } = getButtonContent();

  return (
    <GiButton
      label={label}
      style={{
        height: 26,
        width: isMobilePhone ? 116 : 136,
        marginBottom: 8,
      }}
      onClick={onClick}
      loading={loading}
      disabled={!price}
      grayDisabled={true}
    />
  );
};

export default FirstButton;
