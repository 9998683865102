import { observer } from 'mobx-react';
import * as React from 'react';
import styled from 'styled-components';
import i18n from '../../../constants/i18n';
import { Artist } from '../../../shared';
import { useActStore } from '../../../stores/act';
import GiSeparator from '../../common/GiSeparator';
import GiSwitch from '../../common/GiSwitch';
import { TextH6 } from '../../common/Typography';
import toast from 'react-hot-toast';

const EditActProfileOverviewAvailability = observer(() => {
  const actStore = useActStore();
  const act = actStore.getCurrentEditAct();

  const onSave = async (payload: Partial<Artist>) => {
    try {
      await actStore.editMeAct({ ...payload, actId: act!.id });
    } catch (e) {
      const err = e as Error;
      toast.error(err.message);
    }
  };

  return (
    <Main>
      <TextH6 style={{ marginBottom: 12 }}>
        {i18n('EditArtistProfileOverview.availability', 'Availability')}
      </TextH6>

      <GiSwitch
        label={i18n('EditArtistProfileOverview.availabilityGig', 'Gigs')}
        style={{ marginBottom: 13 }}
        value={act!.availability.gigs}
        onClick={(gigs) =>
          onSave({
            // @ts-ignore
            availability: {
              gigs,
            },
          })
        }
      />

      <GiSwitch
        label={i18n(
          'EditArtistProfileOverview.availabilityCollab',
          'Collaborations',
        )}
        style={{ marginBottom: 24 }}
        value={act!.availability.collaborations}
        onClick={(collaborations) =>
          onSave({
            // @ts-ignore
            availability: {
              collaborations,
            },
          })
        }
      />
      <GiSeparator style={{ marginBottom: 24 }} />
    </Main>
  );
});

const Main = styled.div`
  display: flex;
  flex-direction: column;
  @media (max-width: ${(props) => props.theme.breakPoints.mobile}px) {
  }
`;

export default EditActProfileOverviewAvailability;
