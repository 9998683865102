export default {
  'SocialButton.googleSignin': {
    en: 'Sign in with Google',
    fr: 'Connectez-vous avec Google',
  },
  'SocialButton.googleSignup': {
    en: 'Create account with Google',
    fr: 'Inscrivez-vous avec Google',
  },
};
