export default {
  'NavBar.ForMusicians': { en: 'For Musicians', fr: 'Pour les musiciens' },
  'NavBar.ForYourEvents': { en: 'For Your Events', fr: 'Pour vos événements' },
  'NavBar.aboutUs': { en: 'About', fr: 'À propos' },
  'NavBar.contact': { en: 'Contact Us', fr: 'Nous contacter' },
  'NavBar.button': {
    en: 'Become a beta-tester',
    fr: 'Devenir un bêta-testeur',
  },
  'NavBar.buttonProfile': { en: 'My profile', fr: 'Mon profil' },
  'NavBar.explore': { en: 'Explore', fr: 'Explorer' },
  'NavBar.dashboard': { en: 'Dashboard', fr: 'Tableau de bord' },
  'NavBar.buttonSignup': { en: 'Sign-up', fr: 'Inscrivez-vous' },
  'NavBar.buttonSignin': { en: 'Log-in', fr: 'Connexion' },
  'NavBar.signIn': { en: 'Sign In', fr: "S'identifier" },
  'NavBar.about': { en: 'About', fr: 'À propos' },
  'NavBar.browseMusicians': {
    en: 'Browse Musicians',
    fr: 'Parcourir les musiciens',
  },
  'NavBar.howItWorks': { en: 'How it works', fr: 'Comment ça marche' },
  'NavBar.getStarted': { en: 'Get started', fr: 'Faites vos premier pas' },
  'NavBar.getDemo': { en: 'Get a demo', fr: 'Obtenir une démo' },
  'NavBar.createEvent': { en: 'Create an event', fr: 'Créer un événement' },
};
