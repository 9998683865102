export default {
  'ArtistProfilePictureSection.availableFor': {
    en: 'Available for',
    fr: 'Disponible pour',
  },
  'ArtistProfilePictureSection.and': { en: 'and', fr: 'et' },
  'ArtistProfilePictureSection.Notavailable': {
    en: 'Currently not Available',
    fr: 'Actuellement indisponible',
  },
  'ArtistProfilePictureSection.Reviews': { en: 'Reviews', fr: 'Commentaires' },
};
