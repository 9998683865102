/* eslint-disable react/prop-types */
import * as React from 'react';
import styled from 'styled-components';

import { GeneralRegularNormal, TextH3 } from '../common/Typography';
import Dropdown from 'react-bootstrap/Dropdown';
import images from '../../constants/images';
import i18n from '../../constants/i18n';
import colors from '../../constants/colors';

// The forwardRef is important!!
// Dropdown needs access to the DOM node in order to position the Menu
// @ts-ignore
const CustomToggle = React.forwardRef(
  // @ts-ignore
  ({ children, onClick, placeholder }, ref) => (
    <ToggleContainer
      // bootstrap stuff
      // @ts-ignore
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
    >
      <GeneralRegularNormal
        style={{
          color: children ? colors.black : 'rgb(155,155,155)',
          fontWeight: 400,
        }}
      >
        {children
          ? children
          : placeholder ||
            i18n('SupportSubjectDropDown.subject', 'Select a Subject')}
      </GeneralRegularNormal>
      <DownArrow src={images.whiteDownArrow} />
    </ToggleContainer>
  ),
);

// The forwardRef is important!!
// Dropdown needs access to the DOM node in order to position the Menu

// @ts-ignore
const CustomDropDownItem = React.forwardRef(({ children, onClick }, ref) => (
  <DropDownItemContainer
    // bootstrap stuff
    // @ts-ignore
    ref={ref}
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}
    style={{ width: '100%' }}
  >
    <GeneralRegularNormal
      style={{
        color: 'rgb(155,155,155)',
      }}
    >
      {children}
    </GeneralRegularNormal>
  </DropDownItemContainer>
));

export interface SupportSubjectDropDownItem {
  label: string;
  value: any;
}

const SupportSubjectDropDown = ({
  value,
  items,
  onChange,
  style,
  label,
  placeholder,
}: {
  label?: string;
  value?: string;
  items?: SupportSubjectDropDownItem[];
  onChange?: (item: SupportSubjectDropDownItem, index: number) => void;
  style?: React.CSSProperties;
  placeholder?: string;
}) => {
  return (
    <Column style={style}>
      {label ? <TextH3 style={{ marginBottom: 9 }}>{label}</TextH3> : null}
      <Dropdown role="menu">
        <Dropdown.Toggle
          variant="success"
          id="dropdown-basic"
          as={CustomToggle}
          placeholder={placeholder}
        >
          {value}
        </Dropdown.Toggle>

        <Dropdown.Menu
          style={{
            backgroundColor: 'white',
            marginTop: -5,
            padding: 0,
            width: '100%',
            overflowY: 'auto',

            maxHeight: 300,
          }}
        >
          {items &&
            items.map((item, index) => (
              <Dropdown.Item
                key={item.label}
                onSelect={onChange ? () => onChange(item, index) : undefined}
                as={CustomDropDownItem}
              >
                {item.label}
              </Dropdown.Item>
            ))}
        </Dropdown.Menu>
      </Dropdown>
    </Column>
  );
};

const Column = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
`;

const ToggleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 2px;
  box-sizing: border-box;
  padding: 10px 20px;
  cursor: pointer;
  background: white;
  height: 46px;
  font-size: 16px;
  box-shadow: 4px 4px 10px rgba(131, 123, 119, 0.25);
`;

const DropDownItemContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 46px;
  box-sizing: border-box;
  padding: 10px 20px;
  background-color: white;
  cursor: pointer;
  border-bottom: 1px solid rgb(211, 211, 211);
  &:hover {
    background-color: rgb(231, 234, 250);
  }
  &:hover > span {
    color: white;
  }
  &:active {
    background-color: rgb(231, 234, 250);
  }
`;

const DownArrow = styled.img`
  width: 15px;
  height: 15px;
  object-fit: contain;
  filter: invert(100%);
`;

export default SupportSubjectDropDown;
