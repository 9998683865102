import { observer } from 'mobx-react';
import * as React from 'react';
import styled from 'styled-components';
import colors from '../../constants/colors';
import i18n from '../../constants/i18n';
import images from '../../constants/images';
import { useUiStore } from '../../stores/ui';
import { useNavigate } from 'react-router-dom';
import { GeneralRegularNormal, TextH4Title } from '../common/Typography';
import GiButtonLanding from '../common/GiButtonLanding';
import { SignUpStep } from '../../shared';
import { UseUserStore } from '../../stores/user';

interface TabData {
  id: string;
  text: string;
  img: string;
  component: React.ReactNode;
}

const LandingTabularSection = observer(() => {
  const tabularData: TabData[] = [
    {
      id: 'corporate',
      text: 'LandingTabularSection.forEvents',
      img: images.bookerLogo,
      component: <Events />,
    },
    {
      id: 'artists',
      text: 'LandingTabularSection.forArtists',
      img: images.musicianLogo,
      component: <Artists />,
    },
  ];
  const [currentTab, setCurrentTab] = React.useState(tabularData[0]);

  return (
    <Main>
      <LeftTextSection>
        <TabularContainer>
          {tabularData.map((tab) => (
            <StyledBox
              key={tab.id}
              tab={tab}
              currentTab={currentTab}
              onClick={() => setCurrentTab(tab)}
            >
              <Icon
                style={{
                  filter:
                    tab.id === currentTab.id
                      ? colors.bookerColorFilter
                      : 'none',
                }}
                src={tab.img}
              />
              <TabularH4Title tab={tab} currentTab={currentTab}>
                {i18n(tab.text)}
              </TabularH4Title>
            </StyledBox>
          ))}
        </TabularContainer>
        <SectionTextContainer>{currentTab.component}</SectionTextContainer>
      </LeftTextSection>
      <SectionImgContainer>
        <StyledImg src={images.landingTabularPageImage} />
      </SectionImgContainer>
    </Main>
  );
});

const Events = observer(() => {
  return (
    <EventContainer>
      <SectionTextContainer>
        <TextH4Title>
          {i18n(
            'LandingTabularSection.effortlessBooking',
            'Effortless Booking',
          )}
        </TextH4Title>
        <GeneralRegularNormal>
          {i18n(
            'LandingTabularSection.effortlessBookingDescription',
            'GigLinked streamlines the booking process, making it easy for event planners to find and secure talented musicians for all occasions.',
          )}
        </GeneralRegularNormal>
        <TextH4Title>
          {i18n('LandingTabularSection.timeEfficiency', 'Time Efficiency')}
        </TextH4Title>
        <GeneralRegularNormal>
          {i18n(
            'LandingTabularSection.timeEfficiencyDescription',
            'GigLinked saves you time by handling payments and contracts, allowing you to focus on event planning and execution.',
          )}
        </GeneralRegularNormal>
        <TextH4Title>
          {i18n('LandingTabularSection.diverseTalent', 'Diverse Talent Pool')}
        </TextH4Title>
        <GeneralRegularNormal>
          {i18n(
            'LandingTabularSection.diverseTalentDescription',
            'GigLinked provides access to a diverse pool of musicians, ensuring that bookers can find the perfect performers to suit their specific event needs',
          )}
        </GeneralRegularNormal>
      </SectionTextContainer>
      <ButtonsRow>
        <GiButtonLanding
          inverted
          label={i18n('NavBar.getDemo', 'Get a demo')}
          onClick={() => {
            const e = document.getElementById('GET_DEMO_ID');
            e?.scrollIntoView({
              block: 'start',
              behavior: 'smooth',
              inline: 'start',
            });
          }}
        />
      </ButtonsRow>
    </EventContainer>
  );
});

const Artists = observer(() => {
  const uiStore = useUiStore();
  const navigate = useNavigate();
  const userStore = UseUserStore();
  const { setSignUpStep } = userStore;

  return (
    <EventContainer>
      <SectionTextContainer>
        <TextH4Title>
          {i18n('LandingTabularSection.swiftPayments', 'Swift Payments')}
        </TextH4Title>
        <GeneralRegularNormal>
          {i18n(
            'LandingTabularSection.swiftPaymentsDescription',
            'GigLinked ensures musicians get paid quickly, with payments processed in just five business days, providing financial stability.',
          )}
        </GeneralRegularNormal>
        <TextH4Title>
          {i18n(
            'LandingTabularSection.standardizedContracts',
            'Standardized Contracts',
          )}
        </TextH4Title>
        <GeneralRegularNormal>
          {i18n(
            'LandingTabularSection.standardizedContractsDescription',
            'GigLinked offers standardized contracts, eliminating misunderstandings and ensuring transparency and clear terms for bookings.',
          )}
        </GeneralRegularNormal>
        <TextH4Title>
          {i18n('LandingTabularSection.vibrantCommunity', 'Vibrant Community')}
        </TextH4Title>
        <GeneralRegularNormal>
          {i18n(
            'LandingTabularSection.vibrantCommunityDescription',
            'GigLinked fosters a community of musicians, event coordinators, and retirement homes, facilitating collaboration, networking, and growth opportunities.',
          )}
        </GeneralRegularNormal>
      </SectionTextContainer>
      <ButtonsRow>
        <GiButtonLanding
          style={{ marginRight: uiStore.isMobile ? '0' : '20px' }}
          label={i18n(
            'LandingTabularSection.sugnupForArtistsButton',
            'Sign up',
          )}
          onClick={() => {
            setSignUpStep(SignUpStep.RegistrationUser);
            navigate(`/signup`);
          }}
        />
        <GiButtonLanding
          inverted
          label={i18n('NavBar.getDemo', 'Get a demo')}
          onClick={() => {
            const e = document.getElementById('GET_DEMO_ID');
            e?.scrollIntoView({
              block: 'start',
              behavior: 'smooth',
              inline: 'start',
            });
          }}
        />
      </ButtonsRow>
    </EventContainer>
  );
});

const Main = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  background-color: ${colors.white};
  padding-top: 100px;
  padding-bottom: 120px;
  @media (max-width: ${(props) => props.theme.breakPoints.tablet}px) {
    flex-direction: column;
    padding: 48px;
  }
  @media (max-width: ${(props) => props.theme.breakPoints.mobile}px) {
    padding: 50px 1rem;
  }
`;

const LeftTextSection = styled.div`
  display: flex;
  width: 540px;
  height: 612px;
  flex-direction: column;
  justify-content: flex-start;
  @media (max-width: ${(props) => props.theme.breakPoints.tablet}px) {
    width: 540px;
  }
  @media (max-width: ${(props) => props.theme.breakPoints.mobile}px) {
    width: 90%;
    height: 100%;
  }
`;

const StyledBox = styled.div<{ tab: TabData; currentTab: TabData }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  box-sizing: border-box;
  width: 250px;
  height: 78px;
  border-bottom: ${(props) =>
    props.tab.id === props.currentTab.id
      ? `2px solid ${colors.tabOrange}`
      : `2px solid transparent`};
  &:hover {
    transform: translate(0, -5px);
    -webkit-transform: translate(0, -5px);
    -o-transform: translate(0, -5px);
    -moz-transform: translate(0, -5px);
    transition: transform 0.2s;
  }
  @media (max-width: ${(props) => props.theme.breakPoints.tablet}px) {
    width: 250px;
  }
  @media (max-width: ${(props) => props.theme.breakPoints.mobile}px) {
    width: 130px;
  }
`;

const ButtonsRow = styled.div`
  display: flex;
  width: 540px;
  height: 100%;
  flex-direction: row;
  margin: 50px 0 0;
  @media (max-width: ${(props) => props.theme.breakPoints.tablet}px) {
    margin-top: 30px;
    margin-bottom: 100px;
  }
  @media (max-width: ${(props) => props.theme.breakPoints.mobile}px) {
    position: relative;
    height: 150px;
    width: 300px;
    flex-direction: column;
    margin-right: 0;
    justify-content: space-evenly;
    align-items: center;
    margin-bottom: 50px;
  }
`;

const Icon = styled.img`
  height: 53px;
  cursor: move; /* fallback if grab cursor is unsupported */
  cursor: grab;
`;

const EventContainer = styled.div``;

const SectionTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 540px;
  height: 422px;
  @media (max-width: ${(props) => props.theme.breakPoints.tablet}px) {
    width: 100%;
  }
  @media (max-width: ${(props) => props.theme.breakPoints.mobile}px) {
    width: 100%;
    height: 100%;
  }
`;

const SectionImgContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-top: 0;
  align-items: center;
  @media (max-width: ${(props) => props.theme.breakPoints.tablet}px) {
    width: 100%;
    height: 100%;
    align-content: center;
  }
  @media (max-width: ${(props) => props.theme.breakPoints.mobile}px) {
    width: 100%;
    align-content: center;
    margin-top: 30px;
    padding-top: 0;
  }
`;

const TabularContainer = styled.div`
  width: 540px;
  height: 78px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-bottom: 2px solid ${colors.borderColor};
  margin-bottom: 20px;
  @media (max-width: ${(props) => props.theme.breakPoints.tablet}px) {
    width: 100%;
  }
  @media (max-width: ${(props) => props.theme.breakPoints.mobile}px) {
    width: 100%;
    align-items: center;
  }
`;

const StyledImg = styled.img`
  margin-left: 130px;
  width: 500px;
  height: 612px;
  @media (max-width: ${(props) => props.theme.breakPoints.tablet}px) {
    margin-left: 0;
    width: 50%;
    height: 50%;
  }
  @media (max-width: ${(props) => props.theme.breakPoints.mobile}px) {
    width: 100%;
    height: 60%;
  }
`;

const TabularH4Title = styled.h4<{ tab: TabData; currentTab: TabData }>`
  cursor: pointer;
  margin-bottom: 0;
  margin-left: 17px;
  font-size: 18px;
  font-family: ${(props) => props.theme.fonts.GeneralSans};
  font-weight: 500;
  align-item: center;
  color: ${(props) =>
    props.tab.id === props.currentTab.id ? `#1F156A` : `#777777`};
  @media (max-width: ${(props) => props.theme.breakPoints.tablet}px) {
    font-size: 14px;
  }
  @media (max-width: ${(props) => props.theme.breakPoints.mobile}px) {
    font-size: 12px;
    margin-left: 7px;
  }
`;

export default LandingTabularSection;
