import * as React from 'react';
import styled from 'styled-components';
import { useUiStore } from '../../stores/ui';
import toast from 'react-hot-toast';
import i18n from '../../constants/i18n';
import { InputLabel } from './Typography';
import sexpressClient from '../../utils/sexpress';
import GiMusicianDropDown from './GiMusicianDropDown';
import { Entity } from '../../shared';
import { useEventStore } from '../../stores/event';
import { getEventStatus } from '../../utils/eventUtils';

const GiglinkedMusicianAutocomplete = () => {
  const [autocompleteSuggestions, setAutocompleteSuggestions] = React.useState(
    [],
  );
  const [query, setQuery] = React.useState<string | null>(null);
  const [selectedMusician, setSelectedMusician] = React.useState<
    Partial<Entity>
  >({ id: '', name: '' });

  const uiStore = useUiStore();
  const eventStore = useEventStore();

  const { currentEvent } = eventStore;
  const currentEventStatus = getEventStatus(currentEvent);
  const eventIsDone = currentEventStatus === 'completed';

  return (
    <Main>
      <InputLabel>
        {i18n(
          'GiglinkedMusicianAutocomplete.inviteMusicians',
          'Invite the musician',
        )}
      </InputLabel>
      <EmailForm>
        <FormInput
          type="text"
          onChange={async (ev) => {
            try {
              const query = ev.target.value;
              setQuery(query);
              const response = await sexpressClient.get(
                `/performers?page=${encodeURIComponent(
                  1,
                )}&keywords=${encodeURIComponent(query)}`,
              );
              setAutocompleteSuggestions(response.performers);
            } catch (e) {
              const err = e as Error;
              toast.error(err.message);
            }
          }}
          value={query ?? selectedMusician.name}
        />
        <TagButton
          type="button"
          disabled={eventIsDone}
          onClick={async () => {
            if (eventIsDone) return;
            try {
              if (
                selectedMusician?.id &&
                currentEvent?.invitedArtists?.includes(selectedMusician.id)
              ) {
                setQuery('');
                toast.error(`You already invited ${selectedMusician.name}`);
              } else if (selectedMusician?.id) {
                const res = await eventStore.editMeEvent({
                  invitedArtists: [
                    ...currentEvent!.invitedArtists!,
                    selectedMusician.id,
                  ],
                  eventId: currentEvent!.id,
                });
                eventStore.setCurrentEvent(res);

                setQuery('');
                toast.success(`You invited ${selectedMusician.name}`);
              }
            } catch (e) {
              const err = e as Error;
              toast.error(err.message);
            }
          }}
        >
          {i18n('GiglinkedMusicianAutocomplete.invite', 'Invite')}
        </TagButton>
      </EmailForm>
      {autocompleteSuggestions?.length > 0 && (
        <GiMusicianDropDown
          items={autocompleteSuggestions}
          onChange={(ev: any) => {
            setQuery(null);
            setAutocompleteSuggestions([]);
            setSelectedMusician(ev);
          }}
          isMobile={uiStore.isMobile}
        />
      )}
    </Main>
  );
};

const Main = styled.div`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  margin-bottom: 18px;
  @media (max-width: ${(props) => props.theme.breakPoints.mobile}px) {
    height: 35px;
    margin-bottom: 10px;
  }
`;

const EmailForm = styled.form`
  display: block;
  height: 44px;
`;

const FormInput = styled.input`
  text-decoration: none;
  height: 44px;
  font-size: 16px;
  padding-left: 10px;
  box-sizing: border-box;
  width: 50%;
  background-color: transparent;
  border-radius: 10px 0 0 10px;
  font-family: ${(props) => props.theme.fonts.GeneralSans};
  font-weight: normal;
  border: 1px solid ${(props) => props.theme.colors.buttonBorder};
`;

const TagButton = styled.button<{
  disabled: boolean;
}>`
  display: inline-flex;
  align-items: center;
  vertical-align: middle;
  justify-content: center;
  width: 80px;
  height: 44px;
  margin-bottom: 3px;
  border-radius: 0 10px 10px 0;
  cursor: ${(props) => (props.disabled ? 'auto' : 'pointer')};
  background-color: ${(props) => props.theme.colors.buttonTextColor};
  align-self: center;
  color: ${(props) => props.theme.colors.white};
  font-size: 16px;
  font-weight: 600;
  opacity: ${(props) => (props.disabled ? 0.6 : 1)};
  &:hover {
    opacity: 0.6;
  }
`;

export default GiglinkedMusicianAutocomplete;
