export default {
  'StepperRegistrationGroupTypeSelect.title': {
    en: 'Bookable as:',
    fr: 'Réservable en tant que:',
  },
  'StepperRegistrationGroupTypeSelect.description': {
    en: 'Choose all that apply. You can add more later.',
    fr: "Choisissez tous ceux qui s'appliquent. Vous pourrez en ajouter plus tard.",
  },
  'StepperRegistrationGroupTypeSelect.quintet': {
    en: 'Quintet',
    fr: 'Quintet',
  },
  'StepperRegistrationGroupTypeSelect.band': { en: 'Band', fr: 'Band' },
  'StepperRegistrationGroupTypeSelect.duo': { en: 'Duo', fr: 'Duo' },
  'StepperRegistrationGroupTypeSelect.bigBand': {
    en: 'Big band',
    fr: 'Big band',
  },
  'StepperRegistrationGroupTypeSelect.brass': {
    en: 'Brass band',
    fr: 'Orchestre de cuivres',
  },
  'StepperRegistrationGroupTypeSelect.trio': { en: 'Trio', fr: 'Trio' },
  'StepperRegistrationGroupTypeSelect.mariachi': {
    en: 'Mariachi',
    fr: 'Mariachi',
  },
  'StepperRegistrationGroupTypeSelect.strings': {
    en: 'String ensemble',
    fr: 'Ensemble à cordes',
  },
  'StepperRegistrationGroupTypeSelect.ensemble': {
    en: 'Ensemble',
    fr: 'Ensemble',
  },
  'StepperRegistrationGroupTypeSelect.quartet': {
    en: 'Quartet',
    fr: 'Quatuor',
  },
  'StepperRegistrationGroupTypeSelect.woodwind': {
    en: 'Woodwind ensemble',
    fr: 'Ensemble de bois',
  },
  'StepperRegistrationGroupTypeSelect.collective': {
    en: 'Collective',
    fr: 'Collectif',
  },
  'StepperRegistrationGroupTypeSelect.other': { en: 'Other', fr: 'Autre' },
  'StepperRegistrationGroupTypeSelect.unsure': { en: 'Unsure', fr: 'Pas sûr' },
};
