import * as React from 'react';
import styled from 'styled-components';
import { useUiStore } from '../../../stores/ui';
import { observer } from 'mobx-react';
import { GeneralRegularSmall, InputLabel, TextH6 } from '../Typography';
import GiglinkedButton from '../GiglinkedButton';
import images from '../../../constants/images';
import sexpressClient from '../../../utils/sexpress';
import toast from 'react-hot-toast';
import i18n from '../../../constants/i18n';
import { useEventStore } from '../../../stores/event';
import {
  EventProposalType,
  PROVINCES_TERRITORIES,
  PaymentMethod,
} from '../../../shared';
import SuccessModal from '../SuccessModal';
import { useNavigate } from 'react-router-dom';
import { Column, Row } from '../Layout';
import { UseUserStore } from '../../../stores/user';
import GiglinkedTextInput from '../GiglinkedTextInput';
import GiEventsDropDown from '../GiEventsDropDown';
import GiglinkedLocationAutocomplete from '../GiglinkedLocationAutocomplete';
import CenteredClipLoader from '../CenteredClipLoader';
import StripeSecurePayments from '../../settings/StripeSecurePayments';
import stripe from 'stripe-client';
import config from '../../../config';

const stripeClient = stripe(config.stripePublishableKey);

const CardPaymentData = observer(
  ({ proposal }: { proposal: EventProposalType }) => {
    const uiStore = useUiStore();
    const eventStore = useEventStore();
    const navigate = useNavigate();
    const user = UseUserStore();
    const me = user.getMe();
    const { currentEvent } = eventStore;

    const [paymentInfo, setPaymentInfo] = React.useState<PaymentMethod | null>(
      null,
    );

    const [cardExpirationDate, setCardExpirationDate] = React.useState('');
    const [cardNumber, setCardNumber] = React.useState('');
    const [cardCVC, setCardCVC] = React.useState('');
    // const [cardName, setCardName] = React.useState(
    //   `${me?.firstName} ${me?.lastName}`,
    // );
    const [contactAddress, setContactAddress] = React.useState(
      me?.contactAddress,
    );
    const [location, setLocation] = React.useState(me?.location);
    const [province, setProvince] = React.useState(me?.province ?? 'QC');
    const [postalCode, setPostalCode] = React.useState(me?.postalCode);
    const [loading, setLoading] = React.useState(false);

    const retrieveStripeAccountDetails = async () => {
      try {
        setLoading(true);
        const response = await sexpressClient.retrieveStripeAccountDetails({
          userId: me!.id,
        });
        if (response?.brand && response?.last4) setPaymentInfo(response);
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    };

    React.useEffect(() => {
      retrieveStripeAccountDetails();
    }, []);

    const eventBookingTransactionData = {
      eventDate: currentEvent!.eventDate,
      isOutDoor: currentEvent!.isOutdoor,
      isStageCovered: currentEvent!.isStageCovered!,
      eventType: currentEvent!.companyType,
      message: currentEvent!.description,
      setupTime: 0,
      startTime: currentEvent!.startTime,
      endTime: currentEvent!.endTime,
      artistId: proposal.artist.id,
      locationAddress: currentEvent!.contactAddress,
      estimatedAudience: currentEvent!.estimatedAudience,
      venueName: currentEvent!.companyName,
      city: currentEvent!.city,
      postalCode: currentEvent!.postalCode,
      province: currentEvent!.province,
      country: 'Canada',
      contactFirstName: currentEvent!.contactFirstName,
      contactLastName: currentEvent!.contactLastName,
      contactPhone: currentEvent!.contactPhone,
      quote: String(proposal.proposalQuote),
      eventId: proposal.event,
    };

    if (loading) {
      return <CenteredClipLoader />;
    }

    const validateAndGenerateStripeToken = async () => {
      const exps = cardExpirationDate.split('/');

      const information = {
        card: {
          currency: 'cad',
          number: cardNumber.replaceAll(' ', ''),
          exp_month: exps[0],
          exp_year: exps[1],
          cvc: cardCVC,
          name: `${me?.firstName} ${me?.lastName}`,
          object: 'card',
        },
      };

      try {
        const data = await stripeClient
          .createToken(information)
          .then((j: any) => j.json());

        if (data.error) {
          throw data.error.code;
        }

        await sexpressClient.createCustomerAndPaymentMethod({
          stripeBankAccountToken: data.id,
        });

        toast.success(
          i18n('AddBillingMethodModal.cardAdded', 'You added a new card'),
        );
      } catch (e) {
        let errorMsg = '';

        if (e === 'invalid_expiry_year') {
          errorMsg = i18n(
            'PaymentPref.errorExpiryYear',
            "Your card's expiration year is invalid.",
          );
        } else if (e === 'invalid_expiry_month') {
          errorMsg = i18n(
            'PaymentPref.errorExpiryMonth',
            "Your card's expiration month is invalid.",
          );
        } else if (e === 'invalid_cvc') {
          errorMsg = i18n(
            'PaymentPref.errorCVC',
            "Your card's security code is invalid.",
          );
        } else if (e === 'incorrect_number') {
          errorMsg = i18n(
            'PaymentPref.errorCardNumber',
            'Your card number is incorrect.',
          );
        } else {
          if (e) errorMsg = String(e);
          else
            errorMsg = i18n('PaymentPref.errorGeneral', 'Invalid credit card.');
        }
        toast.error(errorMsg);
      }
    };

    return (
      <>
        {paymentInfo ? (
          <Row style={{ margin: '1rem 0' }}>
            <Icon src={images.visaPaymentIcon} alt="visa payment icon" />
            <TextH6 style={{ fontWeight: 400, margin: '0 1rem' }}>
              {i18n(
                'SettingsScreen.creditCardEnds',
                'Credit card ends in ... ',
              )}
              {paymentInfo.last4}
            </TextH6>
          </Row>
        ) : (
          <>
            <Column>
              <InputLabel htmlFor="cardNumber">
                {i18n('CardPaymentData.cardInformation', 'Card Information')}
              </InputLabel>
              <GiglinkedTextInput
                placeholder="0000 0000 0000 0000"
                value={cardNumber}
                onChangeText={setCardNumber}
                mask="9999 9999 9999 9999"
              />
            </Column>
            <Row>
              <Column style={{ width: '50%', marginRight: '1rem' }}>
                <InputLabel htmlFor="cardExpirationDate">
                  {i18n('CardPaymentData.EXP', 'EXP')}
                </InputLabel>
                <GiglinkedTextInput
                  placeholder={i18n('CardPaymentData.expPlaceholder', 'MM/YY')}
                  value={cardExpirationDate}
                  onChangeText={setCardExpirationDate}
                  mask="99/99"
                />
              </Column>
              <Column style={{ width: '50%', marginLeft: '1rem' }}>
                <InputLabel htmlFor="cardCVC">
                  {i18n('CardPaymentData.CVC', 'CVC')}
                </InputLabel>
                <GiglinkedTextInput
                  placeholder={'000'}
                  mask="999"
                  value={cardCVC}
                  onChangeText={setCardCVC}
                />
              </Column>
            </Row>
            {/* 
            <InputLabel htmlFor="cardName">
              {i18n('CardPaymentData.nameOnTheCard', 'Name on the card')}
            </InputLabel>
            <GiglinkedTextInput
              placeholder={i18n(
                'CardPaymentData.nameOnTheCardPlaceholder',
                'Enter your name',
              )}
              value={cardName}
              onChangeText={setCardName}
            /> */}
            <InputLabel htmlFor="contactAddress">
              {i18n('CardPaymentData.billingAddress', 'Billing address')}
            </InputLabel>
            <GiglinkedTextInput
              id="contactAddress"
              value={contactAddress}
              onChange={(e: any) => setContactAddress(e.target.value)}
            />

            <InputLabel htmlFor="location">
              {i18n('CardPaymentData.city', 'City')}
            </InputLabel>
            <GiglinkedLocationAutocomplete
              type="text"
              id="location"
              location={location!}
              setLocation={(ev) => setLocation(ev)}
              style={{ marginBottom: 0 }}
            />
            <Row>
              <Column style={{ width: '50%', marginRight: '1rem' }}>
                <InputLabel htmlFor="province">
                  {i18n('CardPaymentData.province', 'Province')}
                </InputLabel>
                <GiEventsDropDown
                  items={PROVINCES_TERRITORIES()}
                  value={province}
                  onChange={(ev) => setProvince(ev.value)}
                  style={{
                    width: '100%',
                  }}
                  active
                />
              </Column>
              <Column style={{ width: '50%', marginLeft: '1rem' }}>
                <InputLabel htmlFor="postalCode">
                  {i18n('CardPaymentData.postalCode', 'Postal code')}
                </InputLabel>
                <GiglinkedTextInput
                  id="postalCode"
                  placeholder="A1B 2C3"
                  mask="a9a 9a9"
                  value={postalCode}
                  onChangeText={(ev) => setPostalCode(ev)}
                />
              </Column>
            </Row>

            <GeneralRegularSmall>
              {i18n(
                'CardPaymentData.saveBillingMethod',
                'This method will be saved as your default payment method.',
              )}
            </GeneralRegularSmall>
          </>
        )}

        <GiglinkedButton
          style={{ marginTop: '1rem' }}
          label={i18n('CardPaymentData.confirmPay', 'Confirm & Pay')}
          disabled={
            !(paymentInfo && paymentInfo.last4) &&
            !(cardNumber && cardExpirationDate && cardCVC)
          }
          onClick={async () => {
            uiStore.closePopup();
            try {
              if (!paymentInfo) await validateAndGenerateStripeToken();

              const res = await sexpressClient.createEventBookingTransaction(
                eventBookingTransactionData,
              );
              eventStore.setCurrentEvent(res);

              const displayName =
                proposal.artist.name ??
                proposal.artist.user.firstName + proposal.artist.user.lastName;

              uiStore.openPopup({
                popupDisableTapOut: true,
                renderContent: () => (
                  <SuccessModal
                    title={
                      i18n(
                        'CardPaymentData.successTitle',
                        "Congrats! You've booked for your event: ",
                      ) + ` ${displayName}!`
                    }
                    secondaryText={i18n(
                      'CardPaymentData.successTitleDesc',
                      'View and download your invoice by clicking on the “Invoices” tab in your dashboard.',
                    )}
                    navigate={
                      <GiglinkedButton
                        label={i18n(
                          'CardPaymentData.goToDashboard',
                          'Go to Dashboard',
                        )}
                        onClick={() => {
                          navigate('/dashboard');
                          uiStore.closePopup();
                        }}
                      />
                    }
                  />
                ),
              });
            } catch (e) {
              const err = e as Error;
              toast.error(err.message);
            }
          }}
        />
        <StripeSecurePayments
          style={{ justifyContent: 'center', marginTop: '1rem' }}
        />
      </>
    );
  },
);

const Icon = styled.img`
  width: 30px;
`;

export default CardPaymentData;
