export default {
  'AboutTeamSection.Team': { en: 'Meet the band', fr: 'Rencontrer le groupe' },
  'AboutTeamSection.Founder': {
    en: 'Founder, President',
    fr: 'Fondateur, Président',
  },
  'AboutTeamSection.Operations': { en: 'Operations', fr: 'Opérations' },
  'AboutTeamSection.FounderDesciption': {
    en: "Growing up on the South Shore of Montreal, James started playing guitar and writing songs when he rediscovered The Beatles in his early 20's. Although he began his university studies in English Literature, he eventually graduated from the John Molson School of business with a major in Finance and worked in Wealth Management and in Fintech thereafter. He founded GigLinked during the pandemic because he wanted to solve the problems that so many of his friends, all professional musicians, were facing.",
    fr: "Ayant grandi sur la Rive-Sud de Montréal, James a commencé à jouer de la guitare et à écrire des chansons lorsqu'il a redécouvert les Beatles au début de la vingtaine. Bien qu'il ait commencé ses études universitaires en littérature anglaise, il est finalement diplômé de l'école de commerce John Molson avec une majeure en finance et a travaillé en gestion de patrimoine et en technologie financière par la suite. Il a fondé GigLinked pendant la pandémie parce qu'il voulait résoudre les problèmes auxquels tant de ses amis, tous musiciens professionnels, étaient confrontés.",
  },
  'AboutTeamSection.OperationsDescription': {
    en: 'Majd has kept a strong passion for the arts, and has been playing music for over 20 years. As a performer and music instructor, she takes great pride in her efforts to co-found the Youth for Music Association. The organization captures her passion for the arts by tackling barriers to the continuity and growth of young musicians in Jordan. She currently serves on the board of directors for Toronto Arab Film to continue her passion for bringing Arab art to international audiences. These arts endeavours are enhanced by her MBA degree and her decade of experience in project management roles. She became a part of GigLinked to help in creating a community that musicians can rely on.',
    fr: "Majd a gardé une forte passion pour les arts, et joue de la musique depuis plus de 20 ans. En tant qu'interprète et professeur de musique, elle est très fière de ses efforts pour cofonder l'association Youth for Music. Cette organisation traduit sa passion pour les arts en s'attaquant aux obstacles à la continuité et à la croissance des jeunes musiciens en Jordanie. Elle siège actuellement au conseil d'administration du Toronto Arab Film, poursuivant ainsi sa passion pour la diffusion de l'art arabe auprès du public international. Ces efforts artistiques sont renforcés par son diplôme de MBA et sa décennie d'expérience dans des rôles de gestion de projet. Elle a rejoint GigLinked pour contribuer à la création d'une communauté sur laquelle les musiciens peuvent compter.",
  },
};
