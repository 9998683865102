export default {
  'BookingAbout.title': {
    en: 'Tell us a bit more about your event',
    fr: 'Dites-nous un peu plus sur votre événement',
  },
  'BookingAbout.beAsSpecificAsPossible': {
    en: 'Be as specific as possible, this increases the likelihood of a successful booking. What we recommend you include: Introduce yourself! Tell them why you are hosting this event, Equipment needed (if applicable), Specific song requests (if applicable), what your expectations are for length of sets, are you offering food or drink? Don’t worry if you forget some details, once your request is sent, you’ll be able to message the artist to add further detail.',
    fr: "Soyez aussi précis que possible, cela augmente la probabilité d'une réservation réussie. Ce que nous vous recommandons inclut: Présentez-vous! Dites-leur pourquoi vous organisez cet événement, l'équipement nécessaire (le cas échéant), des demandes de chansons spécifiques (le cas échéant), quelles sont vos attentes pour la durée des ensembles, offrez-vous de la nourriture ou des boissons? Ne vous inquiétez pas si vous oubliez certains détails, une fois votre demande envoyée, vous pourrez envoyer un message à l'artiste pour ajouter plus de détails.",
  },
  'BookingAbout.subTitle': {
    en: 'Be as specific as possible. Artists are more likely to accept offers when they have more information',
    fr: "Soyez aussi spécifique que possible. Les artistes sont plus susceptibles d'accepter les offres lorsqu'ils ont plus d'informations",
  },
  'BookingAbout.next': { en: 'Next', fr: 'Suivante' },
  'BookingAbout.dresscode': { en: 'Dresscode', fr: 'Code vestimentaire' },
  'BookingAbout.requestDeclined!': {
    en: 'Request Declined!',
    fr: 'Demande refusée!',
  },
};
