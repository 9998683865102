import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { GeneralRegularNormal, InputLabel } from '../../common/Typography';
import GiglinkedTextInput from '../../common/GiglinkedTextInput';
import i18n from '../../../constants/i18n';
import {
  COMPANY_TYPES,
  CompanyTypeEnums,
  EventType,
  PROVINCES_TERRITORIES,
} from '../../../shared';
import GiEventsDropDown from '../../common/GiEventsDropDown';
import { Column, Row } from '../../common/Layout';
import GiglinkedLocationAutocomplete from '../../common/GiglinkedLocationAutocomplete';
import { useUiStore } from '../../../stores/ui';
import Form from 'react-bootstrap/Form';
import styled from 'styled-components';
import { isStringEmptyUndefinedOrNull } from '../../../utils/string';

const EventDetailsStepTwo = observer(
  ({
    event,
    setEvent,
  }: {
    event: Partial<EventType>;
    setEvent: React.Dispatch<React.SetStateAction<any>>;
  }) => {
    const uiStore = useUiStore();
    const checkThatAllRequiredAddressFieldsExist = [
      event.contactAddress,
      event.city,
      event.province,
      event.postalCode,
    ].every((item) => !isStringEmptyUndefinedOrNull(item));

    const [savedAddress, setSavedAddress] = React.useState(
      checkThatAllRequiredAddressFieldsExist,
    );

    return (
      <>
        <GeneralRegularNormal>
          {i18n(
            'EventDetailsStepTwo.addressWarning',
            'The full address will not be shown to the musicians until a booking is confirmed.',
          )}
        </GeneralRegularNormal>

        <CheckStyleContainer>
          <Form
            style={{
              display: 'flex',
              flexDirection: 'column',
              fontSize: '16px',
            }}
          >
            <Form.Check
              inline
              checked={savedAddress}
              disabled={!checkThatAllRequiredAddressFieldsExist}
              label={i18n('EventDetailsStepTwo.savedAddress', 'Saved address')}
              name="group1"
              type={'radio'}
              onClick={() => setSavedAddress(true)}
              style={{ marginTop: '1rem' }}
            />
            {savedAddress && (
              <Column style={{ margin: '0.5rem 1rem 0' }}>
                {event.companyType !== CompanyTypeEnums.ForMyself && (
                  <GeneralRegularNormal
                    style={{ textTransform: 'capitalize', fontWeight: 500 }}
                  >
                    {event.companyType}
                  </GeneralRegularNormal>
                )}
                <GeneralRegularNormal>{event.companyName}</GeneralRegularNormal>
                <GeneralRegularNormal>
                  {event.contactAddress}
                </GeneralRegularNormal>
                <Row>
                  <GeneralRegularNormal>
                    {event.city} {', '}
                    {event.province}
                  </GeneralRegularNormal>
                </Row>
                <GeneralRegularNormal>{event.postalCode}</GeneralRegularNormal>
              </Column>
            )}
            {!checkThatAllRequiredAddressFieldsExist && (
              <GeneralRegularNormal style={{ marginLeft: '1rem' }}>
                {i18n(
                  'EventDetailsStepTwo.noSavedAddress',
                  'You have no saved addresses.',
                )}
              </GeneralRegularNormal>
            )}
            <Form.Check
              inline
              checked={!savedAddress}
              label={i18n('EventDetailsStepTwo.newAddress', 'New address')}
              name="group1"
              type={'radio'}
              onClick={() => setSavedAddress(false)}
              style={{ marginTop: '16px' }}
            />
          </Form>
        </CheckStyleContainer>

        {!savedAddress && (
          <>
            <InputLabel htmlFor="companyType">
              {i18n('EventDetailsStepTwo.venueType', 'Venue type')}
            </InputLabel>
            <GiEventsDropDown
              items={COMPANY_TYPES()}
              value={event.companyType}
              onChange={(ev) => setEvent({ ...event, companyType: ev.value })}
              active
              style={{
                marginBottom: '1rem',
                width: '100%',
              }}
            />
            <InputLabel htmlFor="companyName">
              {i18n(
                'EventDetailsStepTwo.venueName',
                'Venue name / Company (disclosed at confirmation)',
              )}
            </InputLabel>
            <GiglinkedTextInput
              id="companyName"
              type="text"
              value={event.companyName}
              onChange={(ev) =>
                setEvent({ ...event, companyName: ev.target.value })
              }
              style={{
                marginBottom: '1rem',
                width: '100%',
              }}
            />
            <Row
              style={{
                width: '100%',
                justifyContent: 'space-between',
              }}
            >
              <Column style={{ width: uiStore.isMobile ? '100%' : '45%' }}>
                <InputLabel htmlFor="contactAddress">
                  {i18n('EventDetailsStepTwo.venueAddress', 'Venue address')}
                </InputLabel>
                <GiglinkedTextInput
                  id="contactAddress"
                  placeholder="123 Adress Street"
                  value={event.contactAddress}
                  onChangeText={(ev) =>
                    setEvent({ ...event, contactAddress: ev })
                  }
                />
              </Column>
              <Column style={{ width: uiStore.isMobile ? '100%' : '45%' }}>
                <InputLabel htmlFor="city">
                  {i18n('EventDetailsStepTwo.city', 'City')}
                </InputLabel>
                <GiglinkedLocationAutocomplete
                  type="text"
                  id="city"
                  location={event.city!}
                  setLocation={(ev) => setEvent({ ...event, city: ev })}
                  style={{ zIndex: 9 }}
                />
              </Column>
            </Row>
            <Row
              style={{
                width: '100%',
                justifyContent: 'space-between',
                marginBottom: '1rem',
              }}
            >
              <Column style={{ width: uiStore.isMobile ? '100%' : '45%' }}>
                <InputLabel htmlFor="province">
                  {i18n('EventDetailsStepTwo.province', 'Province')}
                </InputLabel>
                <GiEventsDropDown
                  items={PROVINCES_TERRITORIES()}
                  value={event.province}
                  onChange={(ev) => setEvent({ ...event, province: ev.value })}
                  active
                />
              </Column>
              <Column style={{ width: uiStore.isMobile ? '100%' : '45%' }}>
                <InputLabel htmlFor="postalCode">
                  {i18n('EventDetailsStepTwo.postalCode', 'Postal code')}
                </InputLabel>
                <GiglinkedTextInput
                  id="postalCode"
                  placeholder="A1B 2C3"
                  mask="a9a 9a9"
                  value={event.postalCode}
                  onChangeText={(ev) => setEvent({ ...event, postalCode: ev })}
                />
              </Column>
            </Row>
          </>
        )}
      </>
    );
  },
);

const CheckStyleContainer = styled.div`
  display: inline-flex;
  margin-bottom: 1rem;
  font-size: 14px;
  font-family: ${(props) => props.theme.fonts.GeneralSans};
`;
export default EventDetailsStepTwo;
