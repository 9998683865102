export default {
  'BookingPayment.paymentpref': {
    en: 'Payment preferences',
    fr: 'Préférences de paiement',
  },
  'BookingPayment.creditCard': {
    en: 'Credit Card Informations',
    fr: 'Informations sur la carte de crédit',
  },
  'BookingPayment.cardNumber': { en: 'Card number', fr: 'Numéro de carte' },
  'BookingPayment.nameOnTheCard': {
    en: 'Name on the card',
    fr: 'Nom sur la Carte',
  },
  'BookingPayment.nameOnTheCardPlaceholder': {
    en: 'Enter your name',
    fr: 'Entrez votre nom',
  },
  'BookingPayment.EXP': { en: 'EXP', fr: 'Exp' },
  'BookingPayment.expPlaceholder': { en: 'MM/YY', fr: 'Mm / yy' },
  'BookingPayment.CVC': { en: 'CVC', fr: 'CVC' },
  'BookingPayment.next': { en: 'Next', fr: 'Suivante' },
};
