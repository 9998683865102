import * as React from 'react';
import { observer } from 'mobx-react-lite';
import { Artist } from '../../../shared';
import { useArtistStore } from '../../../stores/artist';
import { UseUserStore } from '../../../stores/user';
import styled from 'styled-components';
import { TextH6 } from '../../common/Typography';
import i18n from '../../../constants/i18n';
import EditableTextInput from '../../common/EditableTextInput';
import images from '../../../constants/images';
import toast from 'react-hot-toast';

const EditArtistOverviewSocial = observer(() => {
  const userStore = UseUserStore();
  const me = userStore.getMe();
  const artistStore = useArtistStore();

  const onSave = async (payload: Partial<Artist>) => {
    try {
      await artistStore.editMeArtist(payload);
      toast.success(i18n('EditArtistOverviewSocial.saved', 'Saved'));
    } catch (e) {
      const err = e as Error;
      toast.error(err.message);
    }
  };

  return (
    <Main>
      <TextH6 style={{ marginBottom: 12 }}>
        {i18n('EditArtistOverviewSocial.title', 'Social')}
      </TextH6>

      {Object.keys(me!.artist.socialLinks!).map((key) => (
        <EditableTextInput
          key={key}
          // @ts-ignore
          value={me!.artist.socialLinks[key]}
          placeholder="https://"
          label={key[0].toUpperCase() + key.substring(1) + ' URL'}
          // @ts-ignore
          onSave={(text) => onSave({ socialLinks: { [key]: text } })}
          // @ts-ignore
          icon={images[key]}
          style={{ borderWidth: 0 }}
        />
      ))}
    </Main>
  );
});

const Main = styled.div`
  display: flex;
  flex-direction: column;
  @media (max-width: ${(props) => props.theme.breakPoints.mobile}px) {
  }
`;

export default EditArtistOverviewSocial;
