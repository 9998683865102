export default {
  'CardPaymentData.cardInformation': {
    en: 'Card Information',
    fr: 'Informations sur la carte',
  },
  'CardPaymentData.EXP': { en: 'EXP', fr: 'EXP' },
  'CardPaymentData.expPlaceholder': { en: 'MM/YY', fr: 'MM/AA' },
  'CardPaymentData.CVC': { en: 'CVC', fr: 'CVC' },
  'CardPaymentData.nameOnTheCard': {
    en: 'Name on the card',
    fr: 'Nom sur la carte',
  },
  'CardPaymentData.nameOnTheCardPlaceholder': {
    en: 'Enter your name',
    fr: 'Entrez votre nom',
  },
  'CardPaymentData.billingAddress': {
    en: 'Billing address',
    fr: 'Adresse de facturation',
  },
  'CardPaymentData.city': { en: 'City', fr: 'Ville' },
  'CardPaymentData.province': { en: 'Province', fr: 'Province' },
  'CardPaymentData.postalCode': { en: 'Postal code', fr: 'Code postal' },
  'CardPaymentData.saveBillingMethod': {
    en: 'This method will be saved as your default payment method.',
    fr: 'Cette méthode sera enregistrée comme méthode de paiement par défaut.',
  },
  'CardPaymentData.confirmPay': {
    en: 'Confirm & Pay',
    fr: 'Confirmer et payer',
  },
  'CardPaymentData.successTitle': {
    en: "Congrats! You've booked for your event: ",
    fr: 'Félicitations ! Vous avez réservé pour votre événement: ',
  },
  'CardPaymentData.successTitleDesc': {
    en: 'View and download your invoice by clicking on the “Invoices” tab in your dashboard.',
    fr: "Consultez et téléchargez votre facture en cliquant sur l'onglet 'Factures' dans votre tableau de bord.",
  },
  'CardPaymentData.goToDashboard': {
    en: 'Go to Dashboard',
    fr: 'Accéder au tableau de bord',
  },
  'CardPaymentData.paymentMethods': {
    en: 'Payment methods',
    fr: 'Modes de paiement',
  },
};
