import { observer } from 'mobx-react';
import * as React from 'react';

import i18n from '../../../constants/i18n';
import { Artist } from '../../../shared';
import { TextH3 } from '../../common/Typography';

import sexpressClient from '../../../utils/sexpress';

import { useActStore } from '../../../stores/act';
import styled from 'styled-components';
import colors from '../../../constants/colors';
import toast from 'react-hot-toast';
import { UseUserStore } from '../../../stores/user';
import EditActMemberCell from './EditActMemberCell';

const EditActMembersListing = observer(() => {
  const actStore = useActStore();
  const act = actStore.getCurrentEditAct();
  const userStore = UseUserStore();
  const me = userStore.getMe();

  const onClickRemove = async ({ artist }: { artist: Artist }) => {
    try {
      const updatedAct = await sexpressClient.removeArtistFromAct({
        actId: act!.id,
        artistId: artist.id,
      });
      // @ts-ignore
      actStore.setCurrentEditAct({
        ...act,
        ...updatedAct,
      });
      toast.success(
        i18n('EditActMembersListing.removeArtist', 'Artist Removed!'),
      );
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <MemberMain>
      <TextH3 style={{ color: colors.black, marginBottom: 16 }}>
        {act?.name + "'s"} {i18n('EditActMembersListing.title', 'members')}
      </TextH3>
      {act!.members.map((member) => (
        <EditActMemberCell
          key={member.id}
          artist={member}
          isOwner={me?.artist?.id === member.id}
          onClickRemove={onClickRemove}
        />
      ))}
      {act!.pendingMembers.map((member) => (
        <EditActMemberCell
          key={`pending${member.id}`}
          artist={member}
          isPending={true}
          onClickRemove={onClickRemove}
        />
      ))}
    </MemberMain>
  );
});

const MemberMain = styled.div`
  display: flex;
  flex-direction: column;
`;

export default EditActMembersListing;
