import { observer } from 'mobx-react';
import * as React from 'react';
import { Box } from 'react-native-kondo';
import styled from 'styled-components';
import i18n from '../../../constants/i18n';
import images from '../../../constants/images';
import { Act, Artist, Entity, EntityType } from '../../../shared';
import { useArtistStore } from '../../../stores/artist';
import { useUiStore } from '../../../stores/ui';
import ImagePickerHandler from '../ImagePickerHandler';
import { isEntityOwner } from './profileUtils';
import { TextH1, GeneralRegularNormal } from '../Typography';
import { useActStore } from '../../../stores/act';
import ModalImageCropper from '../ModalImageCropper';
import toast from 'react-hot-toast';
import { capitalize } from '../../../utils/string';
import { replaceProfileImage } from '../../../utils/media';

const topDecal = 50;

const ProfilePictureSection = observer(
  ({
    entity,
    entityType,
    forceRefetch,
  }: {
    entity: Artist | Act;
    forceRefetch: () => void;
    entityType: EntityType;
  }) => {
    const uiStore = useUiStore();

    const [loadingProfilePicture, setLoadingProfilePicture] =
      React.useState(false);

    let strTitleLocation = '';
    let displayName = '';

    if (entityType === 'artist') {
      const item = entity as Artist;
      displayName = item.name?.length
        ? item.name
        : item.user.firstName + ' ' + item.user.lastName;

      if (item.title) {
        strTitleLocation += item.title;
      }
      if (item.title && item.location) {
        strTitleLocation += ' | ';
      }
    }

    if (entity.location) {
      strTitleLocation += entity.location;
    }

    let strAvailability = '';
    let hasAvailability = false;

    if (entity.availability) {
      strAvailability = i18n(
        'ArtistProfilePictureSection.availableFor',
        'Available for',
      );
      if (entity.availability.gigs) {
        hasAvailability = true;
        strAvailability += ' Gigs';
      }
      if (entity.availability.gigs && entity.availability.collaborations) {
        strAvailability += ' ' + i18n('ArtistProfilePictureSection.and', 'and');
      }
      if (entity.availability.collaborations) {
        hasAvailability = true;
        strAvailability += ' Collaborations';
      }
    }

    if (!hasAvailability) {
      strAvailability = i18n(
        'ArtistProfilePictureSection.Notavailable',
        'Currently not Available',
      );
    }

    const isOwner = isEntityOwner({
      entity,
      entityType,
    });

    return (
      <Main>
        <TopMain>
          <Box>
            <ProfilePicture
              src={replaceProfileImage(entity.profilePicture)}
              style={{ opacity: loadingProfilePicture ? 0.8 : 1 }}
            />
            {isOwner ? (
              <EditProfilePictureButton
                entity={entity}
                entityType={entityType}
                setLoading={setLoadingProfilePicture}
                onLoadingEnd={forceRefetch}
              />
            ) : null}
          </Box>
          <div
            style={{
              marginTop: uiStore.isMobile ? 16 : topDecal + 30,
              marginLeft: uiStore.isMobile ? 0 : 20,
              display: 'flex',
              flexDirection: 'column',
              wordBreak: 'break-word',
            }}
          >
            <TextH1
              style={{
                marginBottom: 6,
                textAlign: uiStore.isMobile ? 'center' : undefined,
              }}
            >
              {capitalize(displayName)}
            </TextH1>

            <GeneralRegularNormal style={{ color: '#777777' }}>
              {strTitleLocation}
            </GeneralRegularNormal>
          </div>
        </TopMain>

        <Row>
          <LeftRow
            style={{
              justifyContent: uiStore.isMobile ? 'center' : 'flex-start',
            }}
          >
            {entity.nbOfReviews && entity.nbOfReviews > 0 ? (
              <GeneralRegularNormal style={{ color: '#707070' }}>
                {entity.nbOfReviews}{' '}
                {i18n('ArtistProfilePictureSection.Reviews', 'Reviews')}
              </GeneralRegularNormal>
            ) : null}
          </LeftRow>

          <Box style={{ flexDirection: 'row', alignItems: 'center' }}>
            <Box
              style={{
                width: 15,
                height: 15,
                backgroundColor: hasAvailability ? '#5ED678' : 'gray',
                borderRadius: 15 / 2,
                marginRight: 10,
              }}
            />
            <GeneralRegularNormal>{strAvailability}</GeneralRegularNormal>
          </Box>
        </Row>
      </Main>
    );
  },
);

const EditProfilePictureButton = ({
  setLoading,
  onLoadingEnd,
  entityType,
  entity,
}: {
  setLoading: (isLoading: boolean) => void;
  onLoadingEnd: () => void;
  entityType: EntityType;
  entity: Entity;
}) => {
  const uiStore = useUiStore();
  const artistStore = useArtistStore();
  const actStore = useActStore();
  const onChangePicture = async (picture: string) => {
    try {
      uiStore.openPopup({
        popupDisableTapOut: true,
        renderContent: () => (
          <ModalImageCropper
            aspect={1}
            imageUrl={picture}
            onPressSave={async (imageB64: any) => {
              try {
                if (entityType === 'act') {
                  await actStore.editMeAct({
                    actId: entity.id,
                    croppingOptions: { profileImageB64: imageB64 },
                  });
                } else if (entityType === 'artist') {
                  await artistStore.editMeArtist({
                    croppingOptions: { profileImageB64: imageB64 },
                  });
                }
                onLoadingEnd();
              } catch (e) {
                console.log(e);
              } finally {
                setLoading(false);
              }
            }}
          />
        ),
      });
    } catch (e) {
      const err = e as Error;
      toast.error(err.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <EditProfilePictureContainer>
      <ImagePickerHandler
        onChangePicture={onChangePicture}
        onLoadingStateChange={() => setLoading(true)}
        outputType={'base64'}
      >
        <EditProfilePictureIcon src={images.cameraIcon} />
      </ImagePickerHandler>
    </EditProfilePictureContainer>
  );
};

const EditProfilePictureIcon = styled.img`
  height: 22px;
  width: 22px;

  @media (max-width: ${(props) => props.theme.breakPoints.mobile}px) {
    width: 18px;
    height: 18px;
    border-radius: 9px;
  }
`;

const EditProfilePictureContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 37px;
  width: 37px;
  border-radius: 18.5px;
  position: absolute;
  left: 130px;
  top: 125px;
  cursor: pointer;
  background: linear-gradient(0deg, #f2f3f4, #f2f3f4), #000000;
  &:hover {
    filter: brightness(115%);
  }
  @media (max-width: ${(props) => props.theme.breakPoints.mobile}px) {
    width: 28px;
    height: 28px;
    left: 85px;
    top: 75px;
    border-radius: 14px;
  }
`;

const Row = styled.div`
  display: flex;
  margin-top: 17px;
  @media (max-width: ${(props) => props.theme.breakPoints.mobile}px) {
    flex-direction: column;
    margin-top: 8px;
    align-items: center;
  }
`;

export const LeftRow = styled.div`
  width: 100%;
  max-width: 175px;
  margin-right: 20px;
  display: flex;
  @media (max-width: ${(props) => props.theme.breakPoints.mobile}px) {
    margin-right: 0px;
    margin-bottom: 8px;
  }
`;

const Main = styled.div`
  display: flex;
  margin-top: -${topDecal}px;
  flex-direction: column;
  margin-bottom: 25px;
  @media (max-width: ${(props) => props.theme.breakPoints.mobile}px) {
  }
`;

const TopMain = styled.div`
  display: flex;
  position: relative;
  @media (max-width: ${(props) => props.theme.breakPoints.mobile}px) {
    flex-direction: column;
    align-items: center;
  }
`;

const ProfilePicture = styled.img`
  width: 175px;
  height: 175px;
  border-radius: 87.5px;
  border: 1px solid #ffffff;
  box-sizing: border-box;
  object-fit: cover;
  @media (max-width: ${(props) => props.theme.breakPoints.mobile}px) {
    width: 112px;
    height: 112px;
    border-radius: 56px;
  }
`;

export default ProfilePictureSection;
