export default {
  'EditEventModal.delete': { en: 'Delete event', fr: 'Supprimer un événement' },
  'EditEventModal.cancel': { en: 'Cancel', fr: 'Annuler' },
  'EditEventModal.save': { en: 'Save', fr: 'Enregistrer' },
  'EditEventModal.eventSaveSuccessMessage': {
    en: 'The event has been saved',
    fr: "L'événement a été enregistré",
  },
  'EditEventModal.title': { en: 'Edit event', fr: "Modifier l'événement" },
};
