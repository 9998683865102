import { observer } from 'mobx-react';
import * as React from 'react';
import { EventType } from '../../shared';
import styled from 'styled-components';
import i18n from '../../constants/i18n';
import { GeneralRegularNormal } from '../common/Typography';
import colors from '../../constants/colors';
import { useEventStore } from '../../stores/event';
import toast from 'react-hot-toast';
import { getEventColor, getEventStatus } from '../../utils/eventUtils';
import { UseUserStore } from '../../stores/user';
import { format } from 'date-fns';
import { useNavigate } from 'react-router-dom';
import { capitalize } from '../../utils/string';

const EventsCard = observer(() => {
  const eventStore = useEventStore();
  const userStore = UseUserStore();
  const isFrench = userStore?.browserLanguage === 'fr';
  const navigate = useNavigate();

  const [events, setEvents] = React.useState<Array<EventType>>([]);

  React.useEffect(() => {
    const fetchMeEvents = async () => {
      try {
        const res = await eventStore.fetchMeEvents();
        const { events } = res;

        setEvents(events);
      } catch (e) {
        const err = e as Error;
        toast.error(err.message);
      }
    };
    fetchMeEvents();
  }, [location.pathname]);

  return (
    <Main>
      {events?.map((event: EventType) => (
        <>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              margin: '0.5rem 0',
            }}
          >
            <Title>{capitalize(event?.name)}</Title>
            <GeneralRegularNormal
              style={{
                fontWeight: 600,
                color: colors.buttonTextColor,
                cursor: 'pointer',
                width: '50%',
                textAlign: 'end',
              }}
              onClick={() => navigate(`/events/${event.id}`)}
            >
              {i18n('EventsCard.view', 'View')}
            </GeneralRegularNormal>
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              margin: '0.5rem 0',
            }}
          >
            <Header>{i18n('EventsCard.date', 'Date')}</Header>
            <Fields>
              {' '}
              {format(
                new Date(event.eventDate),
                isFrench ? 'y.dd.MM' : 'y.MM.dd',
              )}
            </Fields>
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              margin: '0.5rem 0',
            }}
          >
            <Header>{i18n('EventsCard.proposals', 'Proposals')}</Header>
            <Fields>{event.proposals?.length}</Fields>
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              margin: '0.5rem 0',
            }}
          >
            <Header>{i18n('EventsCard.status', 'Status')}</Header>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
              }}
            >
              <Fields>{getEventStatus(event)}</Fields>
              <div
                style={{
                  height: '12px',
                  width: '12px',
                  borderRadius: '50%',
                  background: getEventColor(event),
                }}
              />
            </div>
          </div>
          <div
            style={{ height: '1px', width: '100%', background: '#D9D9D9' }}
          />
        </>
      ))}
    </Main>
  );
});

const Main = styled.div`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  width: 100%;
  padding: 1rem;
`;

const Title = styled.div`
  font-size: 18px;
  font-weight: 500;
  font-family: ${(props) => props.theme.fonts.GeneralSans};
  width: 50%;
`;

const Fields = styled.span`
  text-transform: capitalize;
  font-size: 16px;
  font-weight: 400;
  font-family: ${(props) => props.theme.fonts.GeneralSans};
  width: 75%;
  align-self: center;
  margin-right: 0.5rem;
`;

const Header = styled.span`
  font-size: 16px;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: 0em;
  color: ${(props) => props.theme.colors.tableTextColor};
  font-family: ${(props) => props.theme.fonts.GeneralSans};
  width: 30%;
`;

export default EventsCard;
