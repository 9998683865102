export default {
  'ActMembersListing.owner': { en: 'Owner', fr: 'Propriétaire' },
  'ActMembersListing.inviteSent': {
    en: 'Invitation sent',
    fr: 'Invitation envoyée',
  },
  'ActMembersListing.cancelInvite': {
    en: 'Cancel invitation',
    fr: "Annuler l'invitation",
  },
  'ActMembersListing.remove': { en: 'Remove', fr: 'Éliminer' },
};
