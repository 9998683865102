import colors from './colors';
import { fonts } from '../components/common/Typography';
import { breakPoints } from './metrics';

const theme = {
  colors,
  fonts,
  borders: [],
  fontSizes: [],
  letterSpacings: [],
  lineHeights: [],
  shadows: [],
  space: [],
  breakPoints,
};

export default theme;
