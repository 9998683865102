export default {
  'HowItWorksAccordionSection.AccordionPaymentsTitle': {
    en: 'How do payments work?',
    fr: 'Comment fonctionnent les paiements?',
  },
  'HowItWorksAccordionSection.AccordionPaymentsDesciptionFirstParagraph': {
    en: 'At the moment of reservation, a deposit may be withdrawn from your credit card. The deposit percentage, if any, is established by the musician and therefore may vary.',
    fr: 'Au moment de la réservation, un dépôt peut être prélevé sur votre carte de crédit. Le pourcentage du dépôt, le cas échéant, est fixé par le musicien et peut donc varier.',
  },
  'HowItWorksAccordionSection.AccordionPaymentsDesciptionSecondParagraph': {
    en: '48 hours after the concert has been performed by the booked musician, we withdraw the remainder of the amount owed for the booking. This is to ensure that the request has been completed to both the satisfaction of the musician and the client.',
    fr: "48 heures après que le concert a été donné par le musicien réservé, nous prélevons le reste du montant dû pour la réservation. Cela permet de s'assurer que la demande a été satisfaite à la fois par le musicien et par le client.",
  },
  'HowItWorksAccordionSection.AccordionRefundsTitle': {
    en: 'How do refunds work?',
    fr: 'Comment fonctionnent les remboursements?',
  },
  'HowItWorksAccordionSection.AccordionRefundsDesciptionFirstParagraph': {
    en: 'If a cancellation occurs 30 days before the reservation date by either the musician or the client, a full refund will be disbursed. In the case where the musician cancels, GigLinked will find a replacement at no extra charge.',
    fr: 'Si une annulation a lieu 30 jours avant la date de réservation par le musicien ou le client, un remboursement complet sera effectué. Dans le cas où le musicien annule, GigLinked trouvera un remplaçant sans frais supplémentaires.',
  },
  'HowItWorksAccordionSection.AccordionRefundsDesciptionSecondParagraph': {
    en: 'If the client cancels inside the 30 days of the reservation date, the deposit amount will be withheld and deposited directly into the musicians bank account. If a cancellation is requested less than 7 days before the reservation date, the total amount owed will be withdrawn.',
    fr: "Si le client annule moins de 30 jours avant la date de performance, le montant du dépôt sera retenu et versé directement dans le compte bancaire du musicien. Si l'annulation est demandée moins de 14 jours avant la date de réservation, le montant total dû sera prélevé.",
  },
  'HowItWorksAccordionSection.AccordionTransactionsTitle': {
    en: 'How do you process transactions?',
    fr: 'Comment traitez-vous les transactions?',
  },
  'HowItWorksAccordionSection.AccordionTransactionsDesciptionFirstParagraph': {
    en: 'We use Stripe, a third-party software to process transactions. This ensures security, privacy and efficient transactions.',
    fr: "Nous utilisons Stripe, un logiciel tiers pour traiter les transactions. Cela garantit la sécurité, la confidentialité et l'efficacité des transactions.",
  },
  'HowItWorksAccordionSection.AccordionTransactionsDesciptionSecondParagraph': {
    en: "No data or information related to our users' bank accounts is stored within GigLinked, and all related data is inaccessible and confidential even to the administrators of the platform.",
    fr: "Aucune donnée ou information relative aux comptes bancaires de nos utilisateurs n'est stockée au sein de GigLinked, et toutes les données connexes sont inaccessibles et confidentielles, même pour les administrateurs de la plateforme.",
  },
  'HowItWorksAccordionSection.AccordionRegistrationTitle': {
    en: 'Are there any obligations to signing up?',
    fr: "Y a-t-il des obligations à s'inscrire?",
  },
  'HowItWorksAccordionSection.AccordionRegistrationDesciption': {
    en: 'As a musician, there are no obligations or constraints to signing-up to GigLinked. We do not impose the exclusive use of our platform in any shape or form related to bookings, transactions.',
    fr: "En tant que musicien, il n'y a aucune obligation ou contrainte à s'inscrire sur GigLinked. Nous n'imposons pas l'utilisation exclusive de notre plateforme sous quelque forme que ce soit en ce qui concerne les réservations ou les transactions.",
  },
  'HowItWorksAccordionSection.AccordionPaymentMethodsTitle': {
    en: 'What payment methods are accepted?',
    fr: 'Quels sont les moyens de paiement acceptés?',
  },
  'HowItWorksAccordionSection.AccordionPaymentMethodsDesciption': {
    en: 'As of March 2023, we only accept payment via credit card. If you wish to proceed with a different method of payment, you can contact us at booking@giglinked.live.',
    fr: "A partir de mars 2023, nous n'acceptons plus que les paiements par carte de crédit. Si vous souhaitez utiliser un autre mode de paiement, vous pouvez nous contacter à l'adresse booking@giglinked.live.",
  },
  'HowItWorksAccordionSection.AccordionTitle': {
    en: 'Frequently asked questions',
    fr: 'Foire aux questions',
  },
};
